import { Box } from "@mui/material";
import React from "react";
// import { useNavigate } from "react-router-dom";
import { chartData } from "../../utils/largedata";
import Dashboard from "../dashboard/Dashboard";


function Home() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   dispatch(getDashboardInfo())
  // }, [])
  return (
    <Box sx={{}}>

      <Dashboard data={chartData} />
    </Box>
  );
}

export default Home;
