import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import {
  createandEditRole,
  getPermissionCategoryListService,
  getRoleAndPermissionMapping,
  getRolePermissionDetails,
} from "../../service/roleService";
import { formContainerCardStyle } from "../../utils/Style";
import { TriggerAlert } from "../../utils/UIUtil";
import { permissionSomeData } from "../../utils/largedata";
import PermissionList, { BpCheckbox } from "./permissionList";
import "./roleandpermission.css";
import { allpageFontStyle } from "../../utils/FontStyle";

function RoleAndPermissionMapping() {
  const [render, rerender] = useState(false);
  const [funcList, setFuncList] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const userPermission =
    useSelector((state) => state.presist.userprofile?.filteredPermission) || [];
  const userPermissionObj =
    useSelector((state) => state.presist.userprofile?.filteredPermissionObj) ||
    [];
  const isSuperAdmin = useSelector(
    (state) => state.presist.userprofile?.userAccess?.superUser
  );
  const [commenData, setCommenData] = useState({
    selectRoleValue: "",
    rolePermissionList: [],
    allPermissionList: [],
    roleList: [],
  });
  const getRoleList = async () => {
    try {
      const res = await createandEditRole("get", {});
      // const res1 = await getPermissionList("get")
      if (res) {
        const role = res?.data?.data;
        setCommenData({
          ...commenData,
          roleList: role,
          rolePermissionList:
            permissionSomeData.length > 0 ? permissionSomeData : [],
        });
      }
    } catch (error) {}
  };
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const onSelectCategory = (e) => {
    console.log(e, "drytfyuyh");
    const index = e.findIndex((i) => i.model === "All");
    if (index >= 0) setSelectedCategory(categoryList);
    else setSelectedCategory(e);
  };
  useEffect(() => {
    getRoleList();
    getPermissionCategoryListService().then((res) => {
      const t = res?.data?.data;
      console.log(t, "asdcasjhcda");
      setCategoryList(t);
    });
  }, []);
  const addFunction = (func) => {
    const f = funcList;
    if (func) {
      f.push(func);
      setFuncList(f);
    }
    rerender(!render);
  };
  const removeFunction = (func) => {
    let f = funcList;
    if (func) {
      f = f?.filter((item) => item?.id !== func?.id);
      setFuncList(f);
    }
    rerender(!render);
  };

  const checkFunctions = (f) => {
    const funcs = funcList;
    const res =
      funcs &&
      funcs?.length > 0 &&
      funcs?.find((fs) => fs?.codename === f?.codename);
    if (res) return true;
    else return false;
  };
  const handelSelect = async (e) => {
    setSelectedPermission([]);

    if (e) {
      setCommenData({ ...commenData, selectRoleValue: e });

      try {
        const res = await getRolePermissionDetails("post", { groupId: e?.id });
        if (res) {
          // const mappedPemission = res?.data?.data
          // const permissionConstructData = mappedPermissionConsrtucdata(mappedPemission)
          // setFuncList(permissionConstructData || [])
          const prevData = [];
          categoryList?.map((i) => {
            const isFind = res.data?.data?.find(
              (j) => j?.content_type?.model === i.model
            );
            if (isFind) {
              prevData.push(i);
            }
          });
          // console.log(res.data?.data,'prevDataprevData')
          const t = res.data?.data?.map((i) => i?.permissionId);
          console.log(t, "prevDataprevData");
          setSelectedCategory(prevData);
          setSelectedPermission(t);
        }
      } catch (error) {}
    } else {
      handelClose();
    }
  };
  const handelSubmit = async () => {
    try {
      const payload = {
        groupId: commenData.selectRoleValue?.id,
        permissions: selectedPermission,
      };
      const permissionSubmitAPI = await getRoleAndPermissionMapping(
        "post",
        payload
      );
      if (permissionSubmitAPI) {
        TriggerAlert("success", permissionSubmitAPI.data.message);
        handelClose();
      }
    } catch (error) {}
  };
  const handelClose = () => {
    setCommenData({ ...commenData, selectRoleValue: "" });
    setFuncList([]);
    setSelectedCategory([]);
  };
  const customStyles = {
    chip: {
      fontSize: "12px", // Adjust the font size as needed
      height: "24px", // Adjust the height as needed
      marginRight: "4px", // Adjust the spacing as needed
    },
  };
  const handleCheck = (data, userPermission) => {
    const t = data?.details
      ?.map((per) => per?.permissions?.map((i) => i?.id))
      ?.flat()
      .every((i) => selectedPermission.includes(i));
    console.log(t, "wecfwefcwefewf");
    return t;
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // const Accordion = styled((props) => (
  //   <MuiAccordion disableGutters elevation={0} square {...props} />
  // ))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   '&:not(:last-child)': {
  //     borderBottom: 10,
  //     padding:0
  //   },
  //   '&:before': {
  //     display: 'none',
  //   },
  // }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  return (
    <Box flex={1} sx={{ height: "100%", p: 2, background: grey[100] }}>
      <Card sx={{ ...formContainerCardStyle, p: 3, minHeight: 700 }}>
        <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
          <Box sx={{ width: "20%", pl: 2 }}>
            <SelectComponent
              DataOptions={commenData?.roleList}
              labelName={(option) => option.name}
              value={commenData?.selectRoleValue}
              sx={{ pr: 1 }}
              // heightSmall
              label={"Role"}
              handelChange={(e) => handelSelect(e)}
              renderOption={(e, option) => (
                <Typography {...e} variant="subtitle2" px={1} pb={0.5}>
                  {option.name}
                </Typography>
              )}
            />
          </Box>
          <Box sx={{ width: "40%", pl: 2 }}>
            {/* <SelectComponent
              DataOptions={categoryList}
              labelName={(option) => option.model}
              value={selectedCategory}
              sx={{ pr: 1 }}
              label={"Page"}
              handelChange={onSelectCategory}
            /> */}
            <Autocomplete
              filterSelectedOptions
              multiple
              fullWidth
              size="small"
              id="combo-box-demo"
              sx={{
                padding: 0,
                background: "white",
                "& .MuiChip-root": {
                  fontSize: "11px", // Adjust the font size as needed
                  // backgroundColor: 'lightgray', // Adjust the background color as needed
                  color: "black", // Adjust the text color as needed
                  // borderRadius: '5px', // Adjust the border radius as needed
                  marginRight: "4px", // Adjust the spacing as needed
                  padding: 0,
                  height: 18,
                },
              }}
              options={[{ model: "All", details: [] }, ...categoryList]}
              getOptionLabel={(option) => option.model}
              value={selectedCategory}
              onChange={(e, v) => onSelectCategory(v)}
              renderOption={(e, option) => (
                <Typography
                  {...e}
                  variant="subtitle2"
                  sx={allpageFontStyle.typography1}
                  px={1}
                  pb={0.5}
                >
                  {option?.model}
                </Typography>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Menu"
                  sx={{
                    ".MuiInputBase-input": {
                      // height: 13,
                      ...allpageFontStyle.typography1,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{}}>
            <Button
              size="small"
              variant="contained"
              sx={{
                borderRadius: 5,
                fontSize: 12,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
              }}
              disabled={!commenData?.selectRoleValue}
              onClick={handelSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center" }}
          className="permissionContainer"
        >
          <div style={{ width: "70%" }}>
            {/* <Box sx={{ display: 'flex', px: 9 }}>
              <Box sx={{ width: 350 }}><Typography sx={{ fontFamily: 'Lato', fontSize: '0.9rem', fontWeight: 'bold' }}>Page Name</Typography></Box>
              <Box sx={{ width: 350 }}><Typography sx={{ fontFamily: 'Lato', fontSize: '0.9rem', fontWeight: 'bold' }}>Permissions</Typography></Box>
            </Box> */}
            {selectedCategory?.map((data, index) => (
              <>
                <Accordion
                  sx={{
                    p: 0,
                    m: 0,
                    minHeight: "28px",
                  }}
                  disableGutters
                  expanded={expanded === index + 1}
                  onChange={handleChange(index + 1)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box
                      sx={{
                        // background: blue[100],
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: 23,
                        mb: 1,
                        px: 1.6,
                        zIndex: 1000,
                      }}
                      // onClick={(e) => {

                      //   if (!(!data?.details?.every(per => per?.permissions?.every(i => userPermission.includes(i?.id))) && !isSuperAdmin)) {

                      //     if (!handleCheck(data, userPermission)) {
                      //       const t = data?.details?.map(per => per?.permissions?.map(i => (i?.id)))
                      //       setSelectedPermission(prev => {
                      //         const t1 = [...prev, ...t.flat()]
                      //         return [...new Set(t1)]
                      //       })
                      //       console.log(t.flat())
                      //     } else {
                      //       const t1 = data?.details?.map(per => per?.permissions?.map(i => (i?.id)))?.flat()
                      //       setSelectedPermission(prev => {
                      //         const t = [...prev].filter(i => !t1.includes(i))
                      //         return [...new Set(t)]
                      //       })
                      //     }
                      //   }
                      // }}
                    >
                      <BpCheckbox
                        size="small"
                        checked={handleCheck(data, userPermission)}
                        disabled={
                          !data?.details?.every((per) =>
                            per?.permissions?.every((i) =>
                              userPermissionObj.find(
                                (permission) =>
                                  permission?.page === per?.appLabel &&
                                  permission?.userAccess?.includes(i?.name)
                              )
                            )
                          ) && !isSuperAdmin
                        }
                        checkboxColor="green"
                        onChange={(e) => {
                          if (e.target.checked) {
                            const t = data?.details?.map((per) =>
                              per?.permissions?.map((i) => i?.id)
                            );
                            setSelectedPermission((prev) => {
                              const t1 = [...prev, ...t.flat()];
                              return [...new Set(t1)];
                            });
                            console.log(t.flat());
                          } else {
                            const t1 = data?.details
                              ?.map((per) =>
                                per?.permissions?.map((i) => i?.id)
                              )
                              ?.flat();
                            setSelectedPermission((prev) => {
                              const t = [...prev].filter(
                                (i) => !t1.includes(i)
                              );
                              return [...new Set(t)];
                            });
                          }
                        }}
                      />
                      {/* <span style={{
                      fontFamily: 'Lato',
                      fontSize: '0.8rem',
                      fontWeight: 'bold'
                    }}>All</span> */}

                      <Typography
                        m={0}
                        mb={1}
                        pl={0}
                        mt={1.5}
                        sx={{
                          ...allpageFontStyle.typography1,
                          flex: 1,
                        }}
                        color={"black"}
                        variant="subtitle2"
                        textAlign={"center"}
                        fontWeight={"bold"}
                      >
                        {data?.model}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data?.details?.map((permission) => (
                      <Box mb={2}>
                        <PermissionList
                          isSuperAdmin={isSuperAdmin}
                          userPermission={userPermission}
                          userPermissionObj={userPermissionObj}
                          name={permission?.appLabel?.replace(/Module_/, "")}
                          permissionList={permission}
                          setSelectedPermission={setSelectedPermission}
                          selectedPermission={selectedPermission}
                        />
                      </Box>
                    ))}
                    {/* <Divider /> */}
                  </AccordionDetails>
                </Accordion>
              </>
            ))}

            {permissionList.map((f, i) => (
              <div className="role_response" key={f.id}>
                {checkFunctions(f) ? (
                  <div
                    className="role_response_chip_remove mb-2 ml-1 rounded"
                    onClick={() => removeFunction(f)}
                  >
                    <div className="role_response_chip-content">
                      {f?.codename}
                    </div>
                  </div>
                ) : (
                  <div
                    className="role_response_chip_add mb-2 ml-1 rounded"
                    onClick={() => addFunction(f)}
                  >
                    <div className="role_response_chip-content">
                      {" "}
                      {f?.codename}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* <div className='permissionList'>
            {commenData.allPermissionList && commenData.allPermissionList?.length > 0 &&
              commenData.allPermissionList.map((f, i) => (
                <div className="role_response" key={i}>
                  {checkFunctions(f) ? (
                    <div className='role_response_chip_remove mb-2 ml-1 rounded'
                      onClick={() => removeFunction(f)}
                    >
                      <div className="role_response_chip-content">

                        {f?.codename.replace(/_/g, ' ')
                          .toLowerCase()
                          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())}
                      </div>
                    </div>
                  ) : (
                    <div className="role_response_chip_add mb-2 ml-1 rounded" onClick={() => addFunction(f)}>
                      <div className="role_response_chip-content">
                        {' '}

                        {f?.codename.replace(/_/g, ' ')
                          .toLowerCase()
                          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div> */}
        </Box>
      </Card>
    </Box>
  );
}

export default RoleAndPermissionMapping;
