/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
// import { checkAndEndRunningGeneralTaskAction } from "../../service/action/action";
import {
  ChangeUserPassword,
  LoginUser,
  userLogout,
} from "../../service/authService";
import {
  createEmployeeServices,
  dashboardInfo,
  editEmployeeService,
  getDashboardData,
  getDashboardTask,
  getDepartmentByTeam,
  getDepartmentTeamByUsers,
  getEmployeeService,
  getGradeByDesig,
  getNotification,
  getTaskSplit,
  profileInfo,
  teamMapping,
} from "../../service/employeeService";
import { fileAttachmentDownload } from "../../service/fileService";
import { createLocation } from "../../service/hallService";
import {
  createAnnouncement,
  createQuery,
  getAnnouncementList,
  getForumData,
  getOverallAnnouncements,
  getOverallQueries,
  getRequest,
  getSelfQueries,
  postReply,
} from "../../service/helpdeskService";
import {
  createDepartmentMaster,
  createDesignationMaster,
  createGradeMaster,
  createProjectMaster,
  createTeamMaster,
  editProjectDetails,
  getDashBoarddCount,
  getJobByProject,
  getJobData,
  getJobList,
  getPojectReleatedList,
  getProjectAndJobInfo,
  getProjectCodeList,
  getProjectDashboard,
  getProjectFilter,
  getProjectList,
  getTimeline,
  getUserDataBydepartement,
  getjobcode,
  passswordService,
} from "../../service/masterService";
import { getNotificationList } from "../../service/notificationService";
import {
  AssignTask,
  AssignTaskDownload,
  CreateJobService,
  CreateJobServiceValid,
  SplitTaskData,
  TaskAssignExcelUpload,
  UpdateTask,
  UpdatejobTask,
  generalTaskHistory,
  getAssignedTask,
  getExcel,
  getRevertTaskList,
  getRevertTaskListAdmin,
  getSplitTaskGet,
  getTask,
  getTaskList,
  getUserTask,
  giveCount,
  projectTreeAPI,
  requestRevert,
  revertTaskPageProjectGETAPI,
  revertTaskapproveandReject,
} from "../../service/taskService";
import { orgData, orgDetails } from "../../service/workBoxService";
import {
  TASK_PAGE_ITEMS_PER_PAGE,
  TriggerAlert,
  loading,
} from "../../utils/UIUtil";
import { updatePresistStates } from "../reducers/PresistReducer";
import { updateCommonStates } from "../reducers/commonReducer";
import { updateMasterStates } from "../reducers/masterReducer";
import { updateProfileStates } from "../reducers/profileReducer";
import store from "../store";
// Masters API
const dispatch = store.dispatch;
export const getGradeByDesigAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getGradeByDesig(data);
    if (response?.data) {
      dispatch(
        updateMasterStates({
          name: "grades",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "grades",
          value: [],
        })
      );
    }
    dispatch(loading(false));
    return response.data;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const ProjectMaster =
  (data, method, path, isEdit) => async (dispatch) => {
    try {
      dispatch(loading(true));

      const response = await createProjectMaster(data, method, path, isEdit);
      if (response?.data) {
        if (method === "post") {
          if (isEdit) {
            TriggerAlert("success", response?.data?.message);
          } else TriggerAlert("success", response?.data?.message);
        }
        if (method === "patch") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "delete") {
          TriggerAlert("success", response?.data?.message);
        }

        if (response?.data && response?.data?.length > 0) {
          if (method === "get") {
            dispatch(
              updateMasterStates({
                name: "projectList",
                value: response?.data,
              })
            );
          }
        } else {
          dispatch(updateMasterStates({ name: "projectList", value: [] }));
        }
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const departmentMasterAction =
  (data, method, path) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createDepartmentMaster(data, method, path);
      if (response) {
        if (method === "post") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "patch") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "delete") {
          TriggerAlert("success", response?.data?.message);
        }
        if (response?.data && response?.data?.data?.length > 0) {
          if (method === "get") {
            dispatch(
              updateMasterStates({
                name: "departmentList",
                value: response?.data?.data,
              })
            );
          }
        } else {
          dispatch(updateMasterStates({ name: "departmentList", value: [] }));
        }
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const designationMasterAction =
  (data, method, path) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createDesignationMaster(data, method, path);
      if (response) {
        if (method === "post") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "patch") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "delete") {
          TriggerAlert("success", response?.data?.message);
        }
        if (response?.data && response?.data?.data?.length > 0) {
          if (method === "get") {
            dispatch(
              updateMasterStates({
                name: "designationList",
                value: response?.data?.data,
              })
            );
          }
        } else {
          dispatch(updateMasterStates({ name: "designationList", value: [] }));
        }
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const gradeMasterAction = (data, method, path) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await createGradeMaster(data, method, path);
    if (response) {
      if (method === "post") {
        TriggerAlert("success", response?.data?.message);
      }
      if (method === "patch") {
        TriggerAlert("success", response?.data?.message);
      }
      if (method === "delete") {
        TriggerAlert("success", response?.data?.message);
      }
      if (response?.data && response?.data?.data?.length > 0) {
        if (method === "get") {
          dispatch(
            updateMasterStates({
              name: "gradeList",
              value: response?.data?.data,
            })
          );
        }
      } else {
        dispatch(updateMasterStates({ name: "gradeList", value: [] }));
      }
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const TeamMasterAction = (data, method, path) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await createGradeMaster(data, method, path);
    if (response) {
      if (method === "post") {
        TriggerAlert("success", "Created");
      }
      if (method === "patch") {
        TriggerAlert("success", "Updated");
      }
      if (method === "delete") {
        TriggerAlert("success", "Deleted");
      }
      if (response?.data && response?.data?.data?.length > 0) {
        if (method === "get") {
          dispatch(
            updateMasterStates({
              name: "teamList",
              value: response?.data?.data,
            })
          );
        }
      } else {
        dispatch(updateMasterStates({ name: "teamList", value: [] }));
      }
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getProjectFilterAction =
  (id, state, pageNumber, itemPerPage, active) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await getProjectFilter(
        id,
        state,
        pageNumber,
        itemPerPage,
        active
      );
      if (response?.data && response?.data) {
        dispatch(
          updateMasterStates({
            name: "projectFilter",
            value: response?.data,
          })
        );
      } else {
        dispatch(
          updateMasterStates({
            name: "projectFilter",
            value: [],
          })
        );
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getProjectCodeListAction = (id, projCode) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getProjectCodeList(id, projCode);
    dispatch(
      updateMasterStates({
        name: "projectCodeDta",
        value: response?.data,
      })
    );
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getProjectCodeBYUserAction =
  (id, projCode) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await revertTaskPageProjectGETAPI(id, projCode);
      dispatch(
        updateMasterStates({
          name: "projectCodeDta",
          value: response?.data,
        })
      );
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getProjectTreeByUserAction =
  (method, path, data) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await projectTreeAPI(method, path, data);
      dispatch(
        updateMasterStates({
          name: "projectTree",
          value: response?.data?.data,
        })
      );
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const editProjectDetailsAction =
  (data, method, path) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await editProjectDetails(data, method, path);

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const EmployeeServiceAction =
  (data, method, path, callBack) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createEmployeeServices(
        data,
        method,
        path,
        callBack
      );
      if (response?.response?.status < 400 || response?.status < 400) {
        if (callBack) {
          callBack();
        }
        if (method === "post") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "patch") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "delete") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "put") {
          TriggerAlert("success", response?.data?.message);
        }
        if (response?.data && response?.data?.length > 0) {
          if (method === "get") {
            dispatch(
              updateMasterStates({
                name: "employeeList",
                value: response?.data,
              })
            );
          }
        } else {
          dispatch(updateMasterStates({ name: "employeeList", value: [] }));
        }
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getEmployeeServiceAction =
  (data, pageSize, pageNumber, active) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await getEmployeeService(
        data,
        pageSize,
        pageNumber,
        active
      );
      if (response?.data && response?.data?.userDetails.length > 0) {
        dispatch(
          updateProfileStates({
            name: "employeeList",
            value: response?.data,
          })
        );
      } else {
        dispatch(
          updateProfileStates({
            name: "employeeList",
            value: [],
          })
        );
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const editEmployeeServiceAction = (data, id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await editEmployeeService(data, id);
    TriggerAlert("success", response?.data?.message);
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const teamMappingAction = (data, id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await teamMapping(data, id);
    if (response?.data) {
      TriggerAlert("success", response?.data?.message);
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getDepartmentByTeamAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getDepartmentByTeam(id);
    if (response?.data?.Teams && response?.data?.Teams.length > 0) {
      dispatch(
        updateProfileStates({
          name: "departmentTeamList",
          value: response?.data.Teams,
        })
      );
    } else {
      dispatch(
        updateProfileStates({
          name: "departmentTeamList",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getDepartmentTeamByUsersAction =
  (id, id2, orgid) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await getDepartmentTeamByUsers(id, id2, orgid);
      if (response?.data && response?.data) {
        dispatch(
          updateProfileStates({
            name: "teamUserData",
            value: response?.data?.data,
          })
        );
      } else {
        dispatch(
          updateProfileStates({
            name: "teamUserData",
            value: [],
          })
        );
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const orgDataAction = (active) => async (dispatch) => {
  const { globapOrganization, userprofile } = store.getState().presist;

  try {
    dispatch(loading(true));
    const response = await orgData(active);
    if (response?.data && response?.data?.data?.length > 0) {
      dispatch(
        updateMasterStates({
          name: "organizationList",
          value: response?.data.data,
        })
      );
      if (!globapOrganization?.orgId && userprofile?.userAccess?.superUser) {
        dispatch(
          updatePresistStates({
            name: "globapOrganization",
            value: response?.data.data[0]?.organizationInfo,
          })
        );
      }
    } else {
      dispatch(updateMasterStates({ name: "organizationList", value: [] }));
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getUserDataBydepartementAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getUserDataBydepartement(data);
    if (response?.data && response?.data?.length > 0) {
      dispatch(
        updateMasterStates({
          name: "departmentUserList",
          value: response?.data,
        })
      );
    } else {
      dispatch(updateMasterStates({ name: "departmentUserList", value: [] }));
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const orgDetailsAction = (data, method) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await orgDetails(data, method);
    if (response) {
      if (method === "post") {
        TriggerAlert("success", response?.data?.message);
      }
      if (method === "patch") {
        TriggerAlert("success", response?.data?.message);
      }
      if (method === "delete") {
        TriggerAlert("success", response?.data?.message);
      }
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const createTeamMasterAction =
  (data, method, path) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createTeamMaster(data, method, path);
      if (response) {
        if (method === "post") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "patch") {
          TriggerAlert("success", response?.data?.message);
        }
        if (method === "delete") {
          TriggerAlert("success", response?.data?.message);
        }
        if (response?.data && response?.data?.data?.teamInfo?.length > 0) {
          if (method === "get") {
            dispatch(
              updateMasterStates({
                name: "teamList",
                value: response?.data?.data,
              })
            );
          }
        } else {
          dispatch(updateMasterStates({ name: "teamList", value: [] }));
        }
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };

// Job API

export const getJobListAction = (projCode) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getJobList(projCode);

    if (response?.data && response?.data?.data?.length > 0) {
      dispatch(
        updateMasterStates({
          name: "projectJoblist",
          value: response?.data?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "projectJoblist",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getProjectListAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getProjectList(id);

    dispatch(
      updateMasterStates({
        name: "projectList",
        value: response?.data,
      })
    );
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getJobDataAction =
  (id, projCode, pageSize, pageNumber, active) => async (dispatch) => {
    try {
      dispatch(
        updateMasterStates({
          name: "JobList",
          value: [],
        })
      );
      dispatch(loading(true));
      const response = await getJobData(
        id,
        projCode,
        pageSize,
        pageNumber,
        active
      );

      dispatch(
        updateMasterStates({
          name: "JobList",
          value: response?.data,
        })
      );

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getjobcodeAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getjobcode(id);

    if (response?.data && response?.data) {
      dispatch(
        updateMasterStates({
          name: "projectByJobCode",
          value: response?.data,
        })
      );
      dispatch(loading(false));
      return response.data;
    } else {
      dispatch(
        updateMasterStates({
          name: "projectByJobCode",
          value: [],
        })
      );
      dispatch(loading(false));
    }

    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getJobByProjectAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getJobByProject(id);

    if (response?.data?.jobList && response?.data?.jobList?.length > 0) {
      dispatch(
        updateMasterStates({
          name: "jobList",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "jobList",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const CreateJobServiceAction =
  (method, headerdata, data, setLoading) => async (dispatch) => {
    // dispatch(loading(true));
    setLoading(true);
    try {
      const response = await CreateJobService(method, headerdata, data);
      setLoading(false);
      // dispatch(loading(false));
      return response;
    } catch (error) {
      setLoading(false);
      dispatch(loading(false));
    }
  };
export const CreateJobServiceValidAction =
  (method, headerdata, data) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await CreateJobServiceValid(method, headerdata, data);
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };

// Auth API

export const passswordServiceAction =
  (data, path, page) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await passswordService(data, path, page);

      if (response?.data) {
        dispatch(
          updateMasterStates({
            name: page,
            value: response?.data,
          })
        );
      } else {
        dispatch(
          updateMasterStates({
            name: page,
            value: "",
          })
        );
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const LoginUserAction = (data, nav) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await LoginUser(data, nav);
    if (response) {
      response?.data?.userInfo?.orgInfo &&
        dispatch(
          updatePresistStates({
            name: "globapOrganization",
            value: response?.data?.userInfo?.orgInfo,
          })
        );
      if (response?.data) {
        const t = { ...response.data };
        const filteredPermission = [];
        const filteredPermissionObj = [];
        const permission = t.userAccess?.permissions?.map((i) => {
          if (i.page?.includes("Module_")) {
            console.log(i, "qwedyuvgwehde");
            let t = i.userAccess;
            if (t?.length && t[0] === "Tasks_Page_Access")
              t = "Task_Page_Access";
            filteredPermission.push(t);
            filteredPermissionObj.push(i);
          }
        });
        t.filteredPermission = filteredPermission.flat();
        t.filteredPermissionObj = filteredPermissionObj.flat();
        // const re = createUserPermissionData(t);
        // t.permissionObject = {...re};
        // console.log(re,'hgjedfjwevfjhe')
        dispatch(updatePresistStates({ name: "userprofile", value: t }));
      }
      if (response?.data?.userAccess?.firstLogin) {
        dispatch(updatePresistStates({ name: "changePass", value: true }));
        dispatch(
          updateCommonStates({ name: "userProfile", value: response?.data })
        );
        // dispatch(updatePresistStates({ name: "userprofile", value: response?.data }));
      } else {
        dispatch(updatePresistStates({ name: "changePass", value: false }));
        // dispatch(updatePresistStates({ name: "userprofile", value: response?.data }));
        dispatch(
          updateCommonStates({ name: "userProfile", value: response?.data })
        );
        if (!response?.data?.userAccess.superUser) {
          nav("/dashboard");
        } else {
          nav("/");
        }
      }
      sessionStorage.setItem(
        "authToken",
        response?.data?.userAccess?.accessToken
      );
      dispatch(
        updatePresistStates({
          name: "authToken",
          value: response?.data?.userAccess?.accessToken,
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const ChangeUserPasswordAction =
  (data, nav) => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await ChangeUserPassword(data, nav);
      if (res) {
        TriggerAlert("success", "Password changed successfully", () => {
          dispatch(updatePresistStates({ name: "changePass", value: false }));
          if (!store.getState().common?.userProfile?.userAccess?.superUser) {
            nav("/dashboard");
          } else {
            nav("/");
          }
        });
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const logoutAction = (nav) => async (dispatch) => {
  try {
    dispatch(loading(true));
    await userLogout();
    // TriggerAlert("success", "Logout");
    sessionStorage.clear();
    dispatch(updatePresistStates({ name: "authToken", value: "" }));
    // nav("/");
    // window.location.reload()
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};

// Dashboard API

export const getProjectListinDashboardAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getProjectDashboard(id);
    dispatch(
      updateMasterStates({
        name: "projectListDashboard",
        value: response?.data?.project_details,
      })
    );
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getDashboardGraphdataAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = [];
    //  await getDashboardGraphdata(data);
    // dispatch(
    //     updateMasterStates({
    //         name: "projectListDashboard",
    //         value: response?.data,
    //     })
    // );
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getDashBoarddCountAction = (data, id) => async (dispatch) => {
  try {
    // dispatch(loading(true));
    const response = await getDashBoarddCount(data, id);

    dispatch(
      updateMasterStates({
        name: "dashboardData",
        value: response?.data.data,
      })
    );
    // dispatch(loading(false));
  } catch (error) {
    // dispatch(loading(false));
  }
};
export const getDashboardInfo = () => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await dashboardInfo();
    if (response?.data && response?.data) {
      dispatch(
        updateProfileStates({
          name: "dashboard",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateProfileStates({
          name: "dashboard",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getDashboardAction = (method, id, data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getDashboardData(method, id, data);
    if (response?.data && response?.data) {
      // if (method === 'get') {
      dispatch(
        updateProfileStates({
          name: "dashboardData",
          value: response?.data?.data,
        })
      );
      // }
      // else {
      //     dispatch(
      //         updateProfileStates({
      //             name: "dashboardDetails",
      //             value: response?.data?.data,
      //         })
      //     );
      // }
    } else {
      dispatch(
        updateProfileStates({
          name: "dashboardData",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};

//Project API

export const getPojectReleatedListAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getPojectReleatedList(id);
    if (response?.data?.jobList && response?.data?.jobList?.length > 0) {
      dispatch(
        updateMasterStates({
          name: "projectReleatedList",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "projectReleatedList",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getProjectAndJobInfoAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getProjectAndJobInfo(id);

    if (response?.data && response?.data) {
      dispatch(
        updateMasterStates({
          name: "selecteTaskProjectAndJobInfo",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "selecteTaskProjectAndJobInfo",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getTimelineAction = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getTimeline(id);

    if (response?.data && response?.data) {
      dispatch(
        updateMasterStates({
          name: "timeLineData",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "timeLineData",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};

// Profile API

export const profileInfoAction = () => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await profileInfo();
    if (response?.data && response?.data) {
      dispatch(
        updateProfileStates({
          name: "profileData",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateProfileStates({
          name: "profileData",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};

// Notification API

export const notificationAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getNotification(data);
    if (response?.data && response?.data) {
      dispatch(
        updateProfileStates({
          name: "messages",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateProfileStates({
          name: "messages",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getNotificationListAction =
  (pageSize, pageNumber, fromDate, toDate, callback) =>
  async (dispatch, getState) => {
    try {
      const response = await getNotificationList(
        pageSize,
        pageNumber,
        fromDate,
        toDate
      );

      if (response?.data) {
        if (callback)
          return callback(response.data?.data?.notificationData?.length);
        const list =
          getState().master?.notificationList?.notificationData || [];
        console.log(list, "lisg");
        if (pageNumber !== 1) {
          const temp = {
            ...response.data.data,
            notificationData: [
              ...list,
              ...response.data?.data?.notificationData,
            ],
          };
          dispatch(
            updateMasterStates({
              name: "notificationList",
              value: temp,
            })
          );
          dispatch(
            updateMasterStates({
              name: "notificationCount",
              value: response?.data?.data?.pageInfo?.total_items,
            })
          );
        } else {
          dispatch(
            updateMasterStates({
              name: "notificationList",
              value: response.data.data,
            })
          );
          dispatch(
            updateMasterStates({
              name: "notificationCount",
              value: response?.data?.data?.pageInfo?.total_items,
            })
          );
        }
      } else {
        dispatch(
          updateMasterStates({
            name: "notificationList",
            value: {},
          })
        );
      }
    } catch (error) {}
  };

// Task API

export const TaskAssignExcelUploadAction =
  (method, headerdata, data, setLoading1, path) => async (dispatch) => {
    // dispatch(loading(true));
    setLoading1 && setLoading1(true);
    try {
      const response = await TaskAssignExcelUpload(
        method,
        headerdata,
        data,
        path
      );
      setLoading1 && setLoading1(false);
      // if (response) TriggerAlert("success", response?.message);
      // dispatch(loading(false));
      return response;
    } catch (error) {
      setLoading1 && setLoading1(false);
      dispatch(loading(false));
      return error;
    }
  };
export const getTaskAction =
  (method, data, setLoading, path, callback) => async (dispatch) => {
    try {
      // dispatch(loading(true));
      setLoading(true);
      const response = await getTask(method, data, path);
      if (
        response?.data?.data &&
        response?.data?.data[0]?.taskList?.length > 0
      ) {
        if (callback) {
          callback();
        }
        dispatch(
          updateMasterStates({
            name: "tasklistData",
            value: response?.data?.data,
          })
        );
      } else {
        dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
      }
      // dispatch(loading(false));
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      dispatch(loading(false));
    }
  };
export const getAssignedTaskAction = (method, data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getUserTask(method, data);
    if (
      response?.data?.data.assignedTaskList &&
      response?.data?.data.assignedTaskList.length > 0
    ) {
      dispatch(
        updateMasterStates({
          name: "assignedtaskData",
          value: response?.data?.data?.assignedTaskList,
        })
      );
    } else {
      dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getAssignedTaskActionGet =
  (itemPerPage, pageNumber, setPageInfo) => async (dispatch) => {
    try {
      dispatch(loading(true));
      dispatch(
        updateMasterStates({
          name: "assignedtaskData",
          value: [],
        })
      );
      const response = await getAssignedTask(
        itemPerPage || TASK_PAGE_ITEMS_PER_PAGE,
        pageNumber || 1
      );
      if (
        (response?.data?.data.assignedTaskList?.pausedTaskList &&
          response?.data?.data.assignedTaskList?.pausedTaskList?.length > 0) ||
        (response?.data?.data.assignedTaskList?.taskInfo &&
          response?.data?.data.assignedTaskList?.taskInfo?.length > 0)
      ) {
        const u = response?.data?.data?.assignedTaskList?.taskInfo.map(
          (list) => {
            return {
              projectName: list?.jobInfo?.projectName,
              ...list?.taskDetail,
            };
          }
        );
        const up = response?.data?.data?.assignedTaskList?.pausedTaskList.map(
          (list) => {
            return {
              projectName: list?.jobInfo?.projectName,
              ...list?.taskDetail,
            };
          }
        );
        setPageInfo &&
          setPageInfo(response?.data?.data.assignedTaskList?.pageInfo);
        dispatch(
          updateMasterStates({
            name: "assignedtaskData",
            value: u,
          })
        );
        dispatch(
          updateMasterStates({
            name: "pausetaskData",
            value: up,
          })
        );
        const v = response;
        v.data.data.assignedTaskList.taskInfo = u;
        dispatch(loading(false));
        return v;
      } else {
        dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
        dispatch(updateMasterStates({ name: "pausetaskData", value: [] }));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getDahboardTaskAction =
  (itemPerPage, pageNumber, setPageInfo) => async (dispatch) => {
    try {
      // dispatch(loading(true));
      dispatch(
        updateMasterStates({
          name: "dashboardTask",
          value: [],
        })
      );
      const response = await getDashboardTask(
        itemPerPage,
        pageNumber || 1,
        setPageInfo
      );
      if (
        response?.data?.data.assignedTaskList?.taskInfo &&
        response?.data?.data.assignedTaskList?.taskInfo?.length > 0
      ) {
        const u = response?.data?.data?.assignedTaskList?.taskInfo.map(
          (list) => {
            return {
              projectName: list?.jobInfo?.projectName,
              ...list?.taskDetail,
            };
          }
        );
        setPageInfo &&
          setPageInfo(response?.data?.data.assignedTaskList?.pageInfo);
        dispatch(
          updateMasterStates({
            name: "dashboardTask",
            value: u,
          })
        );
        const v = response;
        v.data.data.assignedTaskList.taskInfo = u;
        dispatch(loading(false));
        return v;
      } else {
        dispatch(updateMasterStates({ name: "dashboardTask", value: [] }));
      }
      // dispatch(loading(false));

      return response;
    } catch (error) {
      // dispatch(loading(false));
    }
  };
export const getSplitTaskGetAction = (data, path) => async (dispatch) => {
  try {
    dispatch(
      updateMasterStates({
        name: "assignedtaskDataSplit",
        value: null,
      })
    );
    dispatch(loading(true));
    const response = await getSplitTaskGet(data, path);
    if (
      response?.data?.data &&
      response.data.data.unassignedTaskList.length > 0
    ) {
      dispatch(
        updateMasterStates({
          name: "assignedtaskDataSplit",
          value: response.data.data.unassignedTaskList,
        })
      );
    } else {
      dispatch(
        updateMasterStates({ name: "assignedtaskDataSplit", value: [] })
      );
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const AssignTaskAction = (method, data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await AssignTask(method, data);

    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const DownloadAssignedTask =
  (method, data, path, jobCode) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await AssignTaskDownload(method, data, path);
      fileAttachmentDownload(
        response.data,
        "xlsx",
        `Task_Assignment_${data?.jobCode || jobCode}`
      );
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const DownloadUserList =
  (method, data, path, orgId) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await AssignTaskDownload(method, data, path);
      fileAttachmentDownload(response.data, "xlsx", `User_List_${orgId}.xlsx`);
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const UpdateTaskAction = (data, call, callback) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await UpdateTask(data, call, callback);

    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const UpdateJobTaskAction =
  (data, call, callback) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await UpdatejobTask(data, call, callback);

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const SplitTaskDataAction = (data, te) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await SplitTaskData(data, te);
    response?.data?.message && TriggerAlert("success", response?.data?.message);
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getTaskListAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getTaskList(data);
    dispatch(
      updateMasterStates({
        name: "taskList",
        value: response?.data?.data,
      })
    );
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getUserTaskAction =
  (method, path, refreshId, itemperPage, pageNumber, setPageInfo) =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await getUserTask(method, path, itemperPage, pageNumber);
      if (
        (response?.data?.data &&
          response?.data?.data?.assignedTaskList?.pausedTaskList?.length > 0) ||
        response?.data?.data?.assignedTaskList?.taskList?.length > 0
      ) {
        setPageInfo &&
          setPageInfo(response?.data?.data.assignedTaskList?.pageInfo);
        if (refreshId) {
          const res = response?.data?.data.assignedTaskList?.taskList;
          const res2 = response?.data?.data.assignedTaskList?.pausedTaskList;
          const filterthedata = res.find((val) => val.id === refreshId);
          const filterthedata2 = res2.find((val) => val.id === refreshId);
          dispatch(
            updatePresistStates({
              name: "runingTask",
              value: filterthedata || filterthedata2,
            })
          );
        }
        const u = response?.data?.data?.assignedTaskList?.taskList.map(
          (list) => {
            return {
              projectName:
                response?.data?.data?.assignedTaskList?.jobInfo?.projectName,
              ...list,
            };
          }
        );
        const up = response?.data?.data?.assignedTaskList?.pausedTaskList.map(
          (list) => {
            return {
              projectName:
                response?.data?.data?.assignedTaskList?.jobInfo?.projectName,
              ...list,
            };
          }
        );
        dispatch(
          updateMasterStates({
            name: "assignedtaskData",
            value: u,
          })
        );
        dispatch(
          updateMasterStates({
            name: "pausetaskData",
            value: up,
          })
        );
      } else {
        dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
        dispatch(updateMasterStates({ name: "pausetaskData", value: [] }));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getUserTaskAction2 =
  (jobCode, taskId, itemperPage, pageNumber, setPageInfo) =>
  async (dispatch) => {
    try {
      const load = {
        // projectCode: payload.projectCode ? payload.projectCode : "",
        jobCode: jobCode?.jobCode,
      };
      dispatch(loading(true));
      const response = await getUserTask(
        "post",
        load,
        itemperPage || TASK_PAGE_ITEMS_PER_PAGE,
        pageNumber || 1
      );
      if (
        response?.data?.data &&
        response?.data?.data?.assignedTaskList?.pausetaskData?.length > 0
      ) {
        setPageInfo &&
          setPageInfo(response?.data?.data.assignedTaskList?.pageInfo);
        if (taskId) {
          const res = response?.data?.data.assignedTaskList?.taskList;
          const res2 = response?.data?.data.assignedTaskList?.pausedTaskList;
          const filterthedata = res.find((val) => val.id === taskId);
          const filterthedata2 = res2.find((val) => val.id === taskId);
          dispatch(
            updatePresistStates({
              name: "runingTask",
              value: filterthedata || filterthedata2,
            })
          );
        }
        dispatch(
          updateMasterStates({
            name: "assignedtaskData",
            value: response?.data?.data?.assignedTaskList?.taskList,
          })
        );
        dispatch(
          updateMasterStates({
            name: "pausetaskData",
            value: response?.data?.data?.assignedTaskList?.pausedTaskList,
          })
        );
      } else {
        dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
        dispatch(updateMasterStates({ name: "pausetaskData", value: [] }));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const getTaskSpiltAction = (code, id) => async (dispatch) => {
  let result = {};
  try {
    dispatch(loading(true));
    const response = await getTaskSplit(code, id);
    if (response?.data && response?.data) {
      result = response?.data;
      dispatch(
        updateProfileStates({
          name: "splitData",
          value: response?.data,
        })
      );
    } else {
      result = [];
      dispatch(
        updateProfileStates({
          name: "splitData",
          value: null,
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
  return result;
};
export const getExcelAction = (data, setLoading1) => async (dispatch) => {
  try {
    // dispatch(loading(true));
    setLoading1(true);
    const response = await getExcel(data);
    fileAttachmentDownload(response.data, "xlsx", `Job_${data?.jobCode}`);
    dispatch(
      updateMasterStates({
        name: "excel",
        value: response?.data?.data,
      })
    );
    setLoading1(false);
    dispatch(loading(false));
  } catch (error) {
    setLoading1(false);
    dispatch(loading(false));
  }
};
export const requestRevertAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));

    const response = await requestRevert(data);
    // dispatch(
    //     updateMasterStates({
    //         name: "revert",
    //         value: response?.data?.data,
    //     })
    // );

    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const giveCountAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));

    const response = await giveCount(data);
    // dispatch(
    //     updateMasterStates({
    //         name: "revert",
    //         value: response?.data?.data,
    //     })
    // );

    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const generalTaskHistoryAction = (data) => async (dispatch) => {
  try {
    dispatch(loading(true));

    const response = await generalTaskHistory(data);
    if (response) {
      dispatch(
        updateMasterStates({
          name: "generalTaskHistory",
          value: response?.data?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "generalTaskHistory",
          value: {},
        })
      );
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getRevertTaskListAction = (method, path) => async (dispatch) => {
  try {
    dispatch(loading(true));

    const response = await getRevertTaskList(method, path);
    if (response) {
      dispatch(
        updateMasterStates({
          name: "revertTask",
          value: response?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "revertTask",
          value: [],
        })
      );
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getRevertTaskListAdminAction =
  (method, data, path) => async (dispatch) => {
    try {
      dispatch(loading(true));

      const response = await getRevertTaskListAdmin(method, data, path);

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
export const revertTaskapproveandRejectAction =
  (method, data, path) => async (dispatch) => {
    try {
      dispatch(loading(true));

      const response = await revertTaskapproveandReject(method, data, path);
      // dispatch(
      //     updateMasterStates({
      //         name: "revertTask",
      //         value: response?.data?.data,
      //     })
      // );
      if (response) {
        TriggerAlert("success", response?.data?.message);
        dispatch(loading(false));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };

// Meeting API

export const createLocationAction =
  (data, method, path) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createLocation(data, method, path);

      if (response?.data) {
        if (method === "post") {
          TriggerAlert("success", "Hall Created Successfully");
        }
        if (method === "patch") {
          TriggerAlert("success", "Hall Updated Successfully");
        }
        if (method === "delete") {
          TriggerAlert("success", "Hall Deleted Successfully");
        }
        if (method === "get") {
          dispatch(
            updateMasterStates({
              name: "hallList",
              value: response?.data,
            })
          );
        }
      } else if (method === "get") {
        dispatch(updateMasterStates({ name: "hallList", value: [] }));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };

//Helpdesk

export const createAnnouncementAction =
  (method, id, data) => async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createAnnouncement(method, id, data);

      if (response?.data) {
        if (method === "post") {
          TriggerAlert("success", response.data.message);
        }
        // if (method === "patch") {
        //     TriggerAlert("success", "Hall Updated Successfully");
        // }
        // if (method === "delete") {
        //     TriggerAlert("success", "Hall Deleted Successfully");
        // }
        if (method === "get") {
          dispatch(
            updateMasterStates({
              name: "announcementList",
              value: response?.data,
            })
          );
        }
      } else if (method === "get") {
        dispatch(updateMasterStates({ name: "announcementList", value: [] }));
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };

export const getAnnouncementListAction =
  (pageSize, pageNumber, fromDate, toDate, callback, projectCode, jobCode) =>
  async (dispatch, getState) => {
    console.log(pageSize, pageNumber, fromDate, toDate, callback, "lvgisg");
    try {
      const response = await getAnnouncementList(
        pageSize,
        pageNumber,
        fromDate,
        toDate,
        projectCode,
        jobCode
      );

      if (response?.data) {
        if (callback) callback(response?.data?.data?.pageInfo);
        const list = getState().master?.announcementList || [];
        if (pageNumber > 1) {
          console.log("yg7777yug");
          const temp = [...list, ...response.data?.data?.notificationData];

          dispatch(
            updateMasterStates({
              name: "announcementList",
              value: temp,
            })
          );
          // dispatch(
          //     updateMasterStates({
          //         name: "announcementList",
          //         value: response?.data?.data?.pageInfo?.total_items,
          //     })
          // );
        } else {
          console.log(response.data, "uyhgug");
          dispatch(
            updateMasterStates({
              name: "announcementList",
              value: response.data.data?.notificationData,
            })
          );
          // dispatch(
          //     updateMasterStates({
          //         name: "announcementList",
          //         value: response?.data?.data?.pageInfo?.total_items,
          //     }))
        }
      } else {
        dispatch(
          updateMasterStates({
            name: "announcementList",
            value: [],
          })
        );
      }
    } catch (error) {}
  };

export const getSelfQueriesAction =
  (id, pageSize, pageNum, data) => async (dispatch, getState) => {
    try {
      // dispatch(loading(true));
      const response = await getSelfQueries(id, pageSize, pageNum, data);
      if (response.data) {
        console.log("page before res", response, pageNum);
        const list = getState().master?.selfQueries?.queryDetail || [];
        if (pageNum > 1) {
          console.log("page > 1");
          const temp = [...list, ...response?.data?.data?.queryDetail];
          dispatch(
            updateMasterStates({
              name: "selfQueries",
              value: { ...response?.data?.data, queryDetail: temp },
            })
          );
        } else {
          console.log("page = 1");
          dispatch(
            updateMasterStates({
              name: "selfQueries",
              value: response?.data?.data,
            })
          );
        }
      } else {
        console.log("page empty");
        dispatch(
          updateMasterStates({
            name: "selfQueries",
            value: {},
          })
        );
      }
      // dispatch(loading(false));
    } catch (error) {
      // dispatch(loading(false));
    }
  };
export const getOverallQueriesAction =
  (id, pageSize, pageNum, data, setLoading, SetScrollLoader) =>
  async (dispatch, getState) => {
    if (pageNum === 1) {
      dispatch(
        updateMasterStates({
          name: "overallQueries",
          value: {},
        })
      );
    }
    try {
      // dispatch(loading(true));
      if (pageNum > 1 && SetScrollLoader) {
        SetScrollLoader(true);
      }
      if (pageNum === 1 && setLoading) {
        setLoading(true);
      }
      // setLoading && setLoading(true)
      const response = await getOverallQueries(id, pageSize, pageNum, data);
      if (response.data) {
        console.log("page before res", response, pageNum);
        const list = getState().master?.overallQueries?.queryDetail || [];
        if (pageNum > 1) {
          console.log("page > 1");
          const temp = [...list, ...response?.data?.data?.queryDetail];
          dispatch(
            updateMasterStates({
              name: "overallQueries",
              value: { ...response?.data?.data, queryDetail: temp },
            })
          );
          SetScrollLoader && SetScrollLoader(false);
        } else {
          // setLoading(true)
          console.log("page = 1");
          dispatch(
            updateMasterStates({
              name: "overallQueries",
              value: response?.data?.data,
            })
          );
          // setLoading && setLoading(false)
        }
      } else {
        // setLoading(true)
        console.log("page empty");
        dispatch(
          updateMasterStates({
            name: "overallQueries",
            value: {},
          })
        );
        // setLoading && setLoading(false)
      }
      // dispatch(loading(false));
      // setLoading && setLoading(false)
      if (pageNum === 1 && setLoading) {
        setLoading(false);
      }
    } catch (error) {
      // dispatch(loading(false));
      SetScrollLoader && SetScrollLoader(false);
      // setLoading && setLoading(false)
    }
  };
export const getRequestAction =
  (id, pageSize, pageNum, data, setLoading, SetScrollLoader) =>
  async (dispatch, getState) => {
    if (pageNum === 1) {
      dispatch(
        updateMasterStates({
          name: "requestQueries",
          value: {},
        })
      );
    }
    try {
      if (pageNum > 1 && SetScrollLoader) {
        SetScrollLoader(true);
      }
      if (pageNum === 1 && setLoading) {
        setLoading(true);
      }
      // setLoading && setLoading(true)
      const response = await getRequest(id, pageSize, pageNum, data);
      if (response.data) {
        console.log("page before res", response, pageNum);
        const list = getState().master?.requestQueries?.queryDetail || [];
        if (pageNum > 1) {
          console.log("page > 1");
          const temp = [...list, ...response?.data?.queryDetail];
          dispatch(
            updateMasterStates({
              name: "requestQueries",
              value: { ...response?.data, queryDetail: temp },
            })
          );
          SetScrollLoader && SetScrollLoader(false);
        } else {
          // setLoading && setLoading(true)
          console.log("page = 1");
          dispatch(
            updateMasterStates({
              name: "requestQueries",
              value: response?.data,
            })
          );
          setLoading && setLoading(false);
        }
      } else {
        // setLoading && setLoading(true)
        console.log("page empty");
        dispatch(
          updateMasterStates({
            name: "requestQueries",
            value: {},
          })
        );
        // setLoading && setLoading(false)
      }
      // dispatch(loading(false));

      if (pageNum === 1 && setLoading) {
        setLoading(false);
      }
    } catch (error) {
      // dispatch(loading(false));
      setLoading && setLoading(false);
      SetScrollLoader && SetScrollLoader(false);
    }
  };

export const createQueryAction = (method, id, data) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await createQuery(method, id, data);

    if (response?.data) {
      if (method === "post") {
        console.log("successs");
        TriggerAlert("success", response.data.message);
      }
      // if (method === "patch") {
      //     TriggerAlert("success", "Hall Updated Successfully");
      // }
      // if (method === "delete") {
      //     TriggerAlert("success", "Hall Deleted Successfully");
      // }
      if (method === "get") {
        dispatch(
          updateMasterStates({
            name: "queryList",
            value: response?.data,
          })
        );
      }
    } else if (method === "get") {
      dispatch(updateMasterStates({ name: "queryList", value: [] }));
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};

export const getoverallAnnouncementsAction =
  (id, pageSize, pageNum, data, setLoading, SetScrollLoader) =>
  async (dispatch, getState) => {
    if (pageNum === 1) {
      dispatch(
        updateMasterStates({
          name: "OverallAnnouncements",
          value: {},
        })
      );
    }
    try {
      // dispatch(loading(true));
      console.log("inside func");
      if (pageNum > 1 && SetScrollLoader) {
        SetScrollLoader(true);
      }
      if (pageNum === 1 && setLoading) {
        setLoading(true);
      }
      const response = await getOverallAnnouncements(
        id,
        pageSize,
        pageNum,
        data
      );
      // setLoading && setLoading(true)
      if (response.data) {
        console.log("page before res", response, pageNum);
        const list =
          getState().master?.OverallAnnouncements?.announcementDetail || [];
        if (pageNum > 1) {
          console.log("page > 1");
          const temp = [...list, ...response?.data?.data?.announcementDetail];
          dispatch(
            updateMasterStates({
              name: "OverallAnnouncements",
              value: { ...response?.data?.data, announcementDetail: temp },
            })
          );
          SetScrollLoader && SetScrollLoader(false);
        } else {
          // setLoading(true)
          console.log("page = 1");
          dispatch(
            updateMasterStates({
              name: "OverallAnnouncements",
              value: response?.data?.data,
            })
          );
          // setLoading && setLoading(false)
        }
      } else {
        // setLoading && setLoading(true)
        console.log("page empty");
        dispatch(
          updateMasterStates({
            name: "OverallAnnouncements",
            value: {},
          })
        );
      }
      // setLoading && setLoading(false)
      if (pageNum === 1 && setLoading) {
        setLoading(false);
      }
      // dispatch(loading(false));
    } catch (error) {
      // dispatch(loading(false));
      SetScrollLoader && SetScrollLoader(false);
      setLoading && setLoading(false);
    }
  };

export const getForumDataAction = (id, code) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await getForumData(id, code);

    if (response?.data && response?.data) {
      dispatch(
        updateMasterStates({
          name: "forumData",
          value: response?.data?.data,
        })
      );
    } else {
      dispatch(
        updateMasterStates({
          name: "forumData",
          value: [],
        })
      );
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
  }
};

export const postReplyAction = async (method, id, data) => {
  try {
    console.log(method, id, data, "hghghgyu");
    dispatch(loading(true));
    const response = await postReply(method, id, data);

    if (response?.data) {
      if (method === "post" && data?.targetType !== "ANSWER") {
        TriggerAlert("success", "Posted Successfully");
      }
    }
    dispatch(loading(false));
    return response;
  } catch (error) {
    dispatch(loading(false));
  }
};
