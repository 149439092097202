import { createSlice } from "@reduxjs/toolkit";
const reducers = {
  authToken: "",
  userProfile: {},
  navbarHeight: 0,
  unreadCount: 0,
  navbar2Height: 0,
  isSnackOpen: false,
  snackMsg: {
    type: "",
    message: "",
    function: "",
  },
  selectedContact: null,
  drawerState: null,
  runningGeneralTask: null,
  endGeneralTask: null,
};
export const commonSlice = createSlice({
  name: "common",
  initialState: reducers,
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updateCommonStates: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    addAndUpdateCommonStates: (state, action) => {
      state[action.payload.name] += action.payload.value;
    },
    resetCommonReducer: (state, action) => {
      return { ...reducers };
    },
    UpdateUnreadCount: (state, action) => {
      state.unreadCount = action.payload.value;
    },
    updateRunningGeneralTask: (state, action) => {
      state.runningGeneralTask = action.payload;
    },
    endGeneralTask: (state, action) => {
      state.endGeneralTask = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCommonReducer,
  updateCommonStates,
  addAndUpdateCommonStates,
  updateRunningGeneralTask,
  endGeneralTask,
  UpdateUnreadCount,
} = commonSlice.actions;

export default commonSlice.reducer;
