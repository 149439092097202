import axios from "./axiosService";
const baseURL = process.env.REACT_APP_BASE_URL;


export const createLocation = async (data, method, path) => {
    try {
      return await axios({
        method: method,
        url: `${baseURL}${path}`,
        data: data,
      }).then((response) => {
        return response
      });
    } catch (error) {
    }
  };