import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
// import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../pics/caret-down.svg";
import logo from "../../pics/workboxLogo.png";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

// import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Attractions,
  CreateNewFolder,
  CrisisAlert,
  ExpandLess,
  ExpandMore,
  FeedOutlined,
  KeyboardDoubleArrowRightOutlined,
  Lan,
  ListAlt,
  LockOpen,
  SummarizeOutlined,
} from "@mui/icons-material";
import AddToQueueOutlinedIcon from "@mui/icons-material/AddToQueueOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CameraFrontOutlinedIcon from "@mui/icons-material/CameraFrontOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";
import MergeOutlinedIcon from "@mui/icons-material/MergeOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import SafetyDividerOutlinedIcon from "@mui/icons-material/SafetyDividerOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import WorkIcon from "@mui/icons-material/Work";
import {
  Avatar,
  Badge,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import menuIcon from "../../images/logo/logo.png";
import {
  // resetPersistReducer,
  updatePresistStates,
} from "../../redux/reducers/PresistReducer";
import {
  // resetmasterReducer,
  updateMasterStates,
} from "../../redux/reducers/masterReducer";
import GeneralTaskTimer from "../generalTask/generalTaskTimer";
import "./navbar.css";
// import { resetCommonReducer } from "../../redux/reducers/commonReducer";
import TaskPageCommen from "../../pages/showTask/TaskPageCommen";
import {
  // getNotificationListAction,
  // logoutAction,
  orgDataAction,
  profileInfoAction,
} from "../../redux/action/action";
// import { async } from "q";
import LowPriorityOutlinedIcon from "@mui/icons-material/LowPriorityOutlined";
import {
  checkAndEndRunningGeneralTaskAction,
  getNotificationCountAction,
  onLogoutAction,
} from "../../service/action/action";
// import { grey } from "@mui/material/colors";
import { hideRoleScreens } from "../../App";
import { UpdateTask } from "../../service/taskService";
import { allowedPages } from "../../utils/permissions";
import TemporaryDrawer from "./notification";
import { TriggerAlert } from "../../utils/UIUtil";
import ChatAppIcon from "./chat";
import { listPopup } from "./PopupList";
import SidebarList from "./SidebarList";
import { allpageFontStyle } from "../../utils/FontStyle";
import { getUnreadNotificationCount } from "../../service/notificationService";
import { UpdateUnreadCount } from "../../redux/reducers/commonReducer";
// import { ROUTES } from "../../config/constants";
// import { Badge, Button } from "@mui/material";
// import { Notifications } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import { updateCommonStates } from "../../redux/reducers/commonReducer";
// import { employeeProfile, logout } from "../../service/authService";
// import { getFolloweUpService } from "../../service/taskService";
const theme = createTheme({
  components: {
    MuiPopper: {
      styleOverrides: {
        root: {
          "&.MuiPickersPopper-root": {
            zIndex: 999999,
          },
        },
      },
    },
  },
});

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ tooltip: className }}
    PopperProps={{ style: { zIndex: 999999999 } }}
  />
);
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: "#f5f5f9",
  color: "rgba(0, 0, 0, 0.87)",
  width: 600,
  border: "1px solid #dadde9",
}));
const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRadius: "0px 20px 20px 0px",
  backgroundColor: "#e0e0e0",
  // background:'linear-gradient(0deg, rgba(75,75,75,1) 0%, rgba(132,132,132,1) 38%, rgba(241,241,241,1) 100%)',

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const title = { ...allpageFontStyle.mainHeading, pb: 1 };

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  gap: "10px",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//     ...(!open && {
//       marginLeft: 0,
//     }),
//   })
// );

export default function MiniDrawer({
  children,
  increment,
  handleStart,
  handlePause,
  handleReset,
  handleResume,
  jobCode1,
}) {
  // const { drawerState, userProfile } = useSelector((state) => state.common);
  // const { followUp } = useSelector((state) => state.master);
  // const profile = employeeProfile();
  const scrollRef = React.useRef();
  const location = useLocation();
  React.useEffect(() => {
    // scroll to top when routes change
    if (scrollRef.current)
      scrollRef.current.scrollTo({
        top: 0,
      });
    getNotificationCountAction();
  }, [location.pathname]);
  console.log("wechvhce");
  const [open2, setOpen2] = React.useState(false);
  const matches = useMediaQuery("(max-width:1300px)");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [userMangementopen, setUserMangementopen] = React.useState(false);
  const [manageTeam, setMangeTeam] = React.useState(false);
  const [projectOpen, setProjectOpen] = React.useState(false);
  const [meetingOpen, setMeetingOpen] = React.useState(false);
  const [helpdeskOpen, sethelpdeskOpen] = React.useState(false);
  const [announcementOpen, setAnnounceOpen] = React.useState(false);
  const [queriesOpen, setQueriesOpen] = React.useState(false);
  const [jobOpen, setjobOpen] = React.useState(false);
  const [rolesOpen, setRolesOpen] = React.useState(false);
  const [workStationOpen, setWorkStationOpen] = React.useState(false);
  const [reportOpen, setReportOpen] = React.useState(false);
  const [masterOpen, setmasterOpen] = React.useState(false);
  const [generealTaskOpen, setGeneralTaskOpen] = React.useState(false);
  const [dashboardOpen, setDashboardOpen] = React.useState(false);
  const [orgValue, setOrgValue] = React.useState({});
  const { organizationList } = useSelector((state) => state.master);
  const { globapOrganization, runingTask, timerData, userprofile } =
    useSelector((state) => state.presist);
  const { assignedtaskData } = useSelector((state) => state.master);
  const { unreadCount } = useSelector((state) => state.common);
  console.log(unreadCount, "unread");
  // const [badgeCount, setBadgeCount] = React.useState(0);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  // console.log(userprofile, "userprofile")
  React.useEffect(() => {
    dispatch(orgDataAction(true));
  }, []);
  React.useEffect(() => {
    if (matches) setOpen(false);
  }, [matches]);

  const formatTimeData = () => {
    const getSeconds = `0${timerData?.timestate % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData?.timestate / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData?.timestate / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };
  const resumeTask = async () => {
    const temp = {
      jobCode: runingTask?.jobCode,
      remarks: "Task paused after logged out",
      state: "Paused",
      status: runingTask?.status,
      taskId: runingTask?.taskId,
      taskId: runingTask?.taskId,
      workHours: formatTimeData()?.H,
      workMinutes: formatTimeData()?.M,
      workSeconds: formatTimeData()?.S,
    };
    await UpdateTask(temp);
  };
  const handelChangeOrg = (e) => {
    const res = organizationList.filter(
      (val) => val?.organizationInfo?.orgId === e.target.value
    );
    setOrgValue(e);
    dispatch(
      updatePresistStates({
        name: "globapOrganization",
        value: res && res[0]?.organizationInfo,
      })
    );
  };
  const goBack = () => {
    navigate(-1);
  };
  const images = {
    height: 25,
    width: 25,
  };
  const { profileData } = useSelector((state) => state.profile);
  // const { unreadCount } = useSelector((state) => state.commen);
  // console.log(unreadCount, "unreadCount")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open === false) {
      setGeneralTaskOpen(false);
      setWorkStationOpen(false);
      setProjectOpen(false);
      setMeetingOpen(false);
      setmasterOpen(false);
      setDashboardOpen(false);
      setMangeTeam(false);
      setjobOpen(false);
      setUserMangementopen(false);
      setReportOpen(false);
      setRolesOpen(false);
      sethelpdeskOpen(false);
      setAnnounceOpen(false);
      setQueriesOpen(false);
    }
  }, [open]);

  const handleClickOpen = async () => {
    await checkAndEndRunningGeneralTaskAction(
      { logout: true },
      () => setOpen2(true)
      // TriggerAlert("warning",
      //   runingTask?.state === "Resumed" || runingTask?.state === "Started" ? 'Running task will be paused after logged out!' : "Are you sure want to log out?", () => {
      //     if ((runingTask?.state === "Resumed" || runingTask?.state === "Started")) {
      //       resumeTask()
      //       onLogoutAction();
      //     }
      //     else {
      //       onLogoutAction();
      //     }
      //   })
    );
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  React.useEffect(() => {
    dispatch(profileInfoAction());
    // getUnreadCount();
  }, []);

  const data = profileData?.userDetails?.userImage?.userBase64Image;
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", height: "100%" }}
    >
      <CssBaseline />
      <Box>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#e0e0e0",
            // borderRadius: "20px 20px 20px 20px",
            // backgroundColor: "white",
            width: open ? "calc(100% - 250px)" : "100%",
            // zIndex: -1,
          }}
          open={open}
        >
          <Toolbar sx={{}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                p: 0,
                color: "black",
                //   ...(open && { display: 'none' }),
              }}
            >
              {/* <MenuIcon /> */}
              {!open ? (
                <img
                  title="Menu"
                  alt="menu"
                  style={{ height: 40, width: 40 }}
                  src={menuIcon}
                />
              ) : null}
            </IconButton>

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "start",
              }}
            >
              {/* <Autocomplete
                id="size-small-outlined"
                size="small"
                options={organizationList}
                getOptionLabel={(option) => option.orgName}
                value={organizationList.find(
                  (val) => val.orgName === globapOrganization?.orgName
                )}
                sx={{ width: 200 }}
                // defaultValue={organizationList[1]}
                onChange={(e, value) => {
                  handelChangeOrg(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Organization"
                    // placeholder="Select Organization"
                  />
                )}
              /> */}
              {allowedPages().settings.organization?.showPage() ? (
                <FormControl>
                  <InputLabel
                    sx={{
                      ...allpageFontStyle.formFontStyle,
                      fontSize: "0.9rem",
                    }}
                    size="small"
                    color="success"
                  >
                    Organization
                  </InputLabel>
                  <Select
                    onChange={handelChangeOrg}
                    size="small"
                    color="success"
                    // autoFocus
                    sx={{
                      width: 300,
                      borderRadius: 20,
                      borderColor: "green",
                      // '& .MuiOutlinedInput-notchedOutline': {
                      //   border: 'none', // Remove the border
                      // },
                      // '&:hover .MuiOutlinedInput-notchedOutline': {
                      //   border: 'none', // Remove the border on hover
                      // },
                      // '& .MuiSelect-select': {
                      //   paddingTop: '10px', // Example: Adjusting the select text position
                      // },
                      fontSize: "0.9rem",
                      // fontWeight: "bold",
                    }}
                    value={
                      globapOrganization?.orgId
                        ? globapOrganization?.orgId
                        : organizationList && organizationList.length > 0
                        ? organizationList[0]?.organizationInfo?.orgId
                        : ""
                    }
                    // inputRef={ref}
                    label="Organization"
                    variant="outlined"
                  >
                    {/* <MenuItem
                      sx={{
                        borderBottom: "1px solid white",
                        fontFamily: "Lato",
                        fontSize: 13,
                        color: 'green'
                      }}
                      value={""}
                    >
                      Select Organization
                    </MenuItem> */}
                    {organizationList &&
                      organizationList.length > 0 &&
                      organizationList.map((val) => (
                        <MenuItem
                          sx={{
                            borderBottom: "1px solid white",
                            fontFamily: "Lato",
                            fontSize: 13,
                            // color:'green'
                          }}
                          value={val?.organizationInfo?.orgId}
                        >
                          {val?.organizationInfo?.orgName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography
                  variant="subtitle2"
                  sx={{ color: "black", ...allpageFontStyle.typography1 }}
                >
                  Organization Name :{" "}
                  <b style={allpageFontStyle.typography1}>
                    {userprofile?.userInfo?.orgInfo?.orgName}
                  </b>
                </Typography>
              )}
            </Box>

            {/* {!matches1 ? (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "end",
                  mr: 3,
                }}
              >
                <Stopwatch />
              </Box>
            ) : null} */}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                m: 2,
              }}
            >
              <Typography
                variant="h7"
                sx={{ textTransform: "uppercase", fontSize: "18px" }}
                noWrap
                component="div"
              >
                Task
              </Typography>{" "}
            </Box> */}
            {/* {userprofile?.userInfo?.userInfo ? (
              <Box>
                <Box
                  sx={{
                    color: "black",
                    fontSize: 18,
                    fontWeight: "bold",
                    pr: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "green", height: 15, width: 15 }}
                  />
                  {userprofile?.userInfo?.userInfo?.firstname +
                    " " +
                    userprofile?.userInfo?.userInfo?.lastname}
                </Box>
              </Box>
            ) : null}
             

            {/* <Button
            sx={{display:'flex', justifyContent:'center', alignItems:'center'}}
              id="basic-button"
              aria-controls={opens ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={opens ? "true" : undefined}
              onClick={handleClick}
            >
              <Tooltip title="Profile">

                <img alt="" src={avatar} style={{ height: 50, width: 50 }} />
              </Tooltip>
            </Button> */}
            {userprofile?.userInfo ? (
              Object.keys(userprofile?.userInfo)?.length ? (
                <ChatAppIcon />
              ) : null
            ) : null}

            <ThemeProvider theme={theme}>
              <TemporaryDrawer />
            </ThemeProvider>
            <Box
              onClick={handleClick}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: 'grey',
                // boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.5 )',
                px: 1,
                // borderRadius: 50,
                // border: "2px solid rgba(46, 204, 97, 0.28)",
                // ":hover": {
                //   boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                //   border: "2px solid rgba(46, 204, 97, 0.28)",
                // },
              }}
            >
              {userprofile?.userInfo?.userInfo ? (
                <Box sx={{}}>
                  <Box
                    sx={{
                      color: "#358c4e",
                      ...allpageFontStyle.title,
                      pr: 3,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      // alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {/* <StyledBadge sx={{ mr: 1 }} overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot">
                      </StyledBadge> */}
                      <Avatar
                        sx={{ height: 40, width: 40 }}
                        alt={userprofile?.userInfo?.userInfo?.firstname}
                        src={`data:image/jpeg;base64,${data}`}
                      />
                      <Box>
                        <Box sx={{ ...allpageFontStyle.title1 }}>
                          {" "}
                          {userprofile?.userInfo?.userInfo?.firstname +
                            " " +
                            userprofile?.userInfo?.userInfo?.lastname}
                        </Box>
                        <Box sx={{ fontSize: 10 }}>
                          {userprofile?.userAccess?.groups.map((role) => role)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : null}
              <img alt="" src={arrow} style={{ height: 20, width: 20 }} />
            </Box>
            <Menu
              sx={{}}
              id="basic-menu"
              anchorEl={anchorEl}
              open={opens}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  ...allpageFontStyle.title1,
                  ":hover": { color: "#358c4e" },
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  navigate("/profile");
                  handleClose();
                }}
              >
                <PersonIcon fontSize="small" sx={{ color: "#358c4e", mr: 1 }} />{" "}
                Profile
              </MenuItem>
              <MenuItem
                sx={{
                  ...allpageFontStyle.title1,
                  ":hover": { color: "#358c4e" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  navigate("/changePassword");
                  handleClose();
                }}
              >
                <LockResetIcon
                  fontSize="small"
                  sx={{ color: "#358c4e", mr: 1 }}
                />{" "}
                Change Password
              </MenuItem>
              <MenuItem
                sx={{
                  ...allpageFontStyle.title1,
                  ":hover": { color: "#358c4e" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  handleClose();
                  handleClickOpen();
                  // dispatch(logoutAction(navigate));
                  // dispatch(resetmasterReducer());
                  // dispatch(resetCommonReducer());
                  // dispatch(resetPersistReducer());
                  // dispatch(resetProfileReducer());
                }}
              >
                <LogoutIcon fontSize="small" sx={{ color: "#358c4e", mr: 1 }} />{" "}
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
      {/* <Dialog
        maxWidth={"xs"}
        // fullWidth
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "center" }}
          id="alert-dialog-title"
        >
          {"Are you sure want to log out?"}
        </DialogTitle>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ mx: 3, borderRadius: 5 }}
            variant="contained"
            color="success"
            onClick={() => {
              resumeTask()
              onLogoutAction();
            }}
          >
            Yes
          </Button>
          <Button
            sx={{ mx: 3, borderRadius: 5 }}
            variant="contained"
            color="error"
            onClick={handleClose2}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        maxWidth={"xs"}
        // fullWidth
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Lato",
            fontSize: 16,
          }}
        >
          {runingTask?.state === "Resumed" || runingTask?.state === "Started"
            ? "Running task will be paused after logged out!"
            : "Are you sure want to log out?"}
        </DialogTitle>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              height: 23,
              // m: { xs: 1, md: 1, lg: 0 },
              fontSize: 11,
              fontWeight: "bold",
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
              borderRadius: 5,
            }}
            variant="contained"
            color="success"
            onClick={() => {
              if (
                runingTask?.state === "Resumed" ||
                runingTask?.state === "Started"
              ) {
                resumeTask();
                onLogoutAction(navigate);
              } else {
                onLogoutAction(navigate);
              }
            }}
          >
            Yes
          </Button>
          <Button
            sx={{
              height: 23,
              // m: { xs: 1, md: 1, lg: 0 },
              fontSize: 11,
              fontWeight: "bold",
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
              borderRadius: 5,
            }}
            variant="contained"
            color="error"
            onClick={handleClose2}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ position: "relative", background: "#f5f5f5" }}>
        {" "}
        {open ? (
          <Box
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: -15,
              top: 40,
              zIndex: 9999999,
              border: "1px solid black",
              borderRadius: "50px 50px 50px 50px",
              p: 0,
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <KeyboardDoubleArrowLeftOutlinedIcon
              sx={{ color: "white" }}
              fontSize="medium"
            />
          </Box>
        ) : (
          // <Button sx={{position:'absolute', top:10, right:0}}>^^^</Button>
          <Box
            onClick={() => setOpen(true)}
            sx={{
              position: "absolute",
              right: -15,
              top: 50,
              zIndex: 9999999,
              border: "1px solid black",
              borderRadius: "50px 50px 50px 50px",
              p: 0,
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <KeyboardDoubleArrowRightOutlined
              sx={{ color: "white" }}
              fontSize="medium"
            />
          </Box>
        )}
        <Drawer variant="permanent" sx={{ pr: 4 }} open={open}>
          <DrawerHeader
            sx={{
              backgroundColor: "#e0e0e0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={logo}
              onClick={() => {
                navigate("/");
              }}
              alt="logo"
              style={{
                height: "60%",
                color: "white",
                cursor: "pointer",
                width: "70%",
              }}
            />
          </DrawerHeader>
          <Divider />
          {/* {open && <><Box className="d-flex flex-column align-items-center justify-center mt-2 mb-1">
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{ width: 66, height: 66 }} />
                    <Box className="mt-2">

                        <Typography>Cindy</Typography>
                    </Box>
                </Box><Divider /></>} */}
          <List>
            {/* 
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                // selected={pathname === "/"}
                onClick={() => {
                  setDashboardOpen(!dashboardOpen);
                  setOpen(true);
                  // navigate("/");
                }}
              >
                {" "}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img alt="Dashboard" style={images} src={dashboard} />
                </ListItemIcon>{" "}
                <ListItemText
                  primaryTypographyProps={{ fontFamily: "Lato" }}
                  primary={"Dashboard"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {!open ? null : (
                  <>{dashboardOpen ? <ExpandLess /> : <ExpandMore />}</>
                )}
              </ListItemButton>
            </ListItem> */}
            {}
            {allowedPages().adminDashboard.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor: pathname === "/" ? "black" : null,
                  color: pathname === "/" ? "white" : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    navigate("/");
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title={"Admin Dashboard"} placement="right">
                      {/* <img alt="Dashboard" style={images} src={dashboard} /> */}
                      <DashboardIcon
                        className="colors"
                        sx={{ color: pathname === "/" ? "#258d4d" : "black" }}
                      />
                    </Tooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight: pathname === "/" ? "bold" : null,
                        }}
                      >
                        Admin Dashboard
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {/* {allowedPages().userDashboard.showMenu() && ( */}
            <ListItem
              disablePadding
              className="HoverMenu"
              sx={{
                display: "block",
                backgroundColor: pathname === "/dashboard" ? "black" : null,
                color: pathname === "/dashboard" ? "white" : null,
                borderRadius: "0px 30px 30px 0px",
              }}
            >
              <ListItemButton
                sx={{
                  // minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  // px: 2.5,
                  p: 1,
                  pl: 2,
                }}
                // selected={pathname === "/dashboard"}
                onClick={() => {
                  navigate("/dashboard");

                  if (masterOpen) {
                    setmasterOpen(false);
                  } else if (userMangementopen) {
                    setUserMangementopen(false);
                  } else if (manageTeam) {
                    setMangeTeam(false);
                  } else if (projectOpen) {
                    setProjectOpen(false);
                  } else if (jobOpen) {
                    setjobOpen(false);
                  } else if (workStationOpen) {
                    setWorkStationOpen(false);
                  } else if (reportOpen) {
                    setReportOpen(false);
                  } else if (meetingOpen) {
                    setMeetingOpen(false);
                  } else if (generealTaskOpen) {
                    setGeneralTaskOpen(false);
                  } else if (rolesOpen) {
                    setRolesOpen(false);
                  }

                  // navigate("/");
                }}
              >
                {" "}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    sx={{ background: "red" }}
                    title="Dashboard"
                    placement="right"
                  >
                    {/* <img alt="Dashboard" style={images} src={dashboard} /> */}
                    <SpaceDashboardOutlinedIcon
                      className="colors"
                      sx={{
                        color: pathname === "/dashboard" ? "#258d4d" : "black",
                      }}
                    />
                  </Tooltip>
                </ListItemIcon>{" "}
                <ListItemText
                  primaryTypographyProps={{ fontFamily: "Lato" }}
                  primary={
                    <Box
                      className="colors"
                      sx={{
                        ...allpageFontStyle.sideBartypography,
                        fontWeight: pathname === "/" ? "bold" : null,
                      }}
                    >
                      Dashboard
                    </Box>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            {/* )} */}
            <Collapse in={dashboardOpen} timeout={"auto"}>
              <List>
                <Divider />
              </List>
            </Collapse>
            {allowedPages().settings.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/org"
                      ? "black"
                      : pathname === "/branch"
                      ? "black"
                      : pathname === "/department"
                      ? "black"
                      : pathname === "/designation"
                      ? "black"
                      : pathname === "/grade"
                      ? "black"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                  color:
                    pathname === "/org"
                      ? "white"
                      : pathname === "/branch"
                      ? "white"
                      : pathname === "/department"
                      ? "white"
                      : pathname === "/designation"
                      ? "white"
                      : pathname === "/grade"
                      ? "white"
                      : null,
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setmasterOpen(!masterOpen);
                    }
                    if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }
                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.setting}
                            nav={navigate}
                            headerLabel={"Settings"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Organization" style={images} src={org} /> */}
                      <SettingsIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/org"
                              ? "#258d4d"
                              : pathname === "/branch"
                              ? "#258d4d"
                              : pathname === "/department"
                              ? "#258d4d"
                              : pathname === "/designation"
                              ? "#258d4d"
                              : pathname === "/grade"
                              ? "#258d4d"
                              : pathname === "/projectMasterList" ||
                                pathname === "/CreateMaster"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/org"
                              ? "bold"
                              : pathname === "/branch"
                              ? "bold"
                              : pathname === "/department"
                              ? "bold"
                              : pathname === "/designation"
                              ? "bold"
                              : pathname === "/grade"
                              ? "bold"
                              : pathname === "/projectMasterList" ||
                                pathname === "/CreateMaster"
                              ? "bold"
                              : null,
                        }}
                      >
                        Settings
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{masterOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={masterOpen} timeout={"auto"}>
              <List>
                {allowedPages().settings.organization?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      color: pathname === "/org" ? "#258d4d" : "black",

                      // backgroundColor:'#cfd1d4'
                      // color: pathname === "/org" ? "white" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        p: 0.5,
                        pl: 7,
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        alignItems: "center",
                        // px: 2.5,
                      }}
                      // selected={pathname === "/org"}
                      onClick={() => navigate("/org")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <BusinessOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/org" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight: pathname === "/org" ? "bold" : null,
                            }}
                          >
                            Organization
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().settings.branch?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/branch" ? "#3f9df4" : null,
                      color: pathname === "/branch" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        alignItems: "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/branch"}
                      onClick={() => navigate("/branch")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <MergeOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/branch" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/branch" ? "bold" : null,
                            }}
                          >
                            Branch
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().settings.department?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/department" ? "#3f9df4" : null,
                      color: pathname === "/department" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        p: 0.5,
                        pl: 7,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                      }}
                      // selected={pathname === "/department"}
                      onClick={() => navigate("/department")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SafetyDividerOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/department" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/department" ? "bold" : null,
                            }}
                          >
                            Department
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().settings.designation?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/designation" ? "#3f9df4" : null,
                      color: pathname === "/designation" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/designation"}
                      onClick={() => navigate("/designation")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <EngineeringOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/designation" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/designation" ? "bold" : null,
                            }}
                          >
                            Designation
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().settings.grade?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/grade" ? "#3f9df4" : null,
                      color: pathname === "/grade" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/grade"}
                      onClick={() => navigate("/grade")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <MilitaryTechOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/grade" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight: pathname === "/grade" ? "bold" : null,
                            }}
                          >
                            Grade
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().settings.unitType?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/createProject" ? "#3f9df4" : null,
                      color:
                        pathname === "/projectMasterList" ||
                        pathname === "/CreateMaster"
                          ? "#258d4d"
                          : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/createProject"}
                      onClick={() => navigate("/projectMasterList")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <CrisisAlert
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/projectMasterList" ||
                              pathname === "/CreateMaster"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/projectMasterList" ||
                                pathname === "/CreateMaster"
                                  ? "bold"
                                  : null,
                            }}
                          >
                            Unit Type
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().UserManagement.showMenu() && (
              <ListItem
                disablePadding
                className="HoverMenu"
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/createUser"
                      ? "black"
                      : pathname === "/user"
                      ? "black"
                      : null,
                  color:
                    pathname === "/createUser"
                      ? "white"
                      : pathname === "/user"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setUserMangementopen(!userMangementopen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.userManagement}
                            nav={navigate}
                            headerLabel={"User Management"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Create User" style={images} src={user} /> */}
                      <PersonAddIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createUser"
                              ? "#258d4d"
                              : pathname === "/user"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/createUser"
                              ? "bold"
                              : pathname === "/user"
                              ? "bold"
                              : null,
                        }}
                      >
                        User Management
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{userMangementopen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={userMangementopen} timeout={"auto"}>
              <List>
                {allowedPages().UserManagement.createUser?.showPage() && (
                  <ListItem
                    disablePadding
                    className="HoverMenu"
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/createUser" ? "#3f9df4" : null,
                      color: pathname === "/createUser" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/createUser"}
                      onClick={() => navigate("/createUser")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <CameraFrontOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/createUser" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/createUser" ? "bold" : null,
                            }}
                          >
                            Create User
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().UserManagement.userList?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/user" ? "#3f9df4" : null,
                      color: pathname === "/user" ? "#258d4d" : null,
                      // ":hover":{backgroundColor:'#e0e0e0'}
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/user"}
                      onClick={() => navigate("/user")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PhotoCameraFrontOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/user" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight: pathname === "/user" ? "bold" : null,
                            }}
                          >
                            User List
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {/* <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    selected={pathname === "/groups"}
                    onClick={() => navigate("/groups")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    ></ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={"Groups"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem> */}
                {/* <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    selected={pathname === "/permission"}
                    onClick={() => navigate("/permission")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    ></ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={"Permissions"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem> */}
                <Divider />
              </List>
            </Collapse>
            {/* <Divider /> */}
            {allowedPages().TeamManagement.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/createTeam"
                      ? "black"
                      : pathname === "/team"
                      ? "black"
                      : pathname === "/team/mapping"
                      ? "black"
                      : null,
                  color:
                    pathname === "/createTeam"
                      ? "white"
                      : pathname === "/team"
                      ? "white"
                      : pathname === "/team/mapping"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setMangeTeam(!manageTeam);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.teamManagement}
                            nav={navigate}
                            headerLabel={"Team Management"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Team" style={images} src={team} /> */}
                      <Diversity1Icon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createTeam"
                              ? "#258d4d"
                              : pathname === "/team"
                              ? "#258d4d"
                              : pathname === "/team/mapping"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/createTeam"
                              ? "bold"
                              : pathname === "/team"
                              ? "bold"
                              : pathname === "/team/mapping"
                              ? "bold"
                              : null,
                        }}
                      >
                        Team Management
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{manageTeam ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={manageTeam} timeout={"auto"}>
              <List>
                {allowedPages().TeamManagement.creatTeam?.showPage() && (
                  <ListItem
                    disablePadding
                    className="HoverMenu"
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/createTeam" ? "#3f9df4" : null,
                      color: pathname === "/createTeam" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/createTeam"}
                      onClick={() => navigate("/createTeam")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <GroupAddOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/createTeam" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/createTeam" ? "bold" : null,
                            }}
                          >
                            Create Team
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().TeamManagement.teamList?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/team" ? "#3f9df4" : null,
                      color: pathname === "/team" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        p: 0.5,
                        pl: 7,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                      }}
                      // selected={pathname === "/team"}
                      onClick={() => navigate("/team")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Groups3OutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/team" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight: pathname === "/team" ? "bold" : null,
                            }}
                          >
                            Team List
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().TeamManagement.teamAllocation?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      // pathname === "/team/mapping" ? "#3f9df4" : null,
                      color: pathname === "/team/mapping" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/team/mapping"}
                      onClick={() => navigate("/team/mapping")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <ElevatorOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/team/mapping"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/team/mapping" ? "bold" : null,
                            }}
                          >
                            User Allocation
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().role.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/CreateRole"
                      ? "black"
                      : pathname === "/RoleList" || pathname === "/EditRole"
                      ? "black"
                      : pathname === "/Role/userMapping"
                      ? "black"
                      : pathname === "/Role/Permission"
                      ? "black"
                      : null,
                  color:
                    pathname === "/CreateRole"
                      ? "white"
                      : pathname === "/RoleList" || pathname === "/EditRole"
                      ? "white"
                      : pathname === "/Role/userMapping"
                      ? "white"
                      : pathname === "/Role/Permission"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setRolesOpen(!rolesOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.role}
                            nav={navigate}
                            headerLabel={"Role"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Team" style={images} src={team} /> */}
                      <Attractions
                        sx={{
                          color:
                            pathname === "/CreateRole"
                              ? "#258d4d"
                              : pathname === "/RoleList" ||
                                pathname === "/EditRole"
                              ? "#258d4d"
                              : pathname === "/Role/Permission"
                              ? "#258d4d"
                              : pathname === "/Role/userMapping"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/CreateRole"
                              ? "bold"
                              : pathname === "/RoleList" ||
                                pathname === "/EditRole"
                              ? "bold"
                              : pathname === "/Role/Permission"
                              ? "bold"
                              : pathname === "/Role/userMapping"
                              ? "bold"
                              : null,
                        }}
                      >
                        Role
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{rolesOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={rolesOpen} timeout={"auto"}>
              <List>
                {allowedPages().role.creatRole?.showPage() &&
                  hideRoleScreens && (
                    <ListItem
                      disablePadding
                      className="HoverMenu"
                      sx={{
                        display: "block",
                        // backgroundColor:
                        //   pathname === "/createTeam" ? "#3f9df4" : null,
                        color: pathname === "/CreateRole" ? "#258d4d" : null,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          // minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          // px: 2.5,
                          p: 0.5,
                          pl: 7,
                        }}
                        // selected={pathname === "/createTeam"}
                        onClick={() => navigate("/CreateRole")}
                      >
                        {" "}
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <CreateNewFolder
                            className="colors"
                            fontSize="small"
                            sx={{
                              color:
                                pathname === "/CreateRole"
                                  ? "#258d4d"
                                  : "black",
                              mr: 1,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontFamily: "Lato" }}
                          primary={
                            <Box
                              className="colors"
                              sx={{
                                ...allpageFontStyle.sideBartypography,
                                fontWeight:
                                  pathname === "/CreateRole" ? "bold" : null,
                              }}
                            >
                              Create Role
                            </Box>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                {allowedPages().role.roleList?.showPage() &&
                  hideRoleScreens && (
                    <ListItem
                      className="HoverMenu"
                      disablePadding
                      sx={{
                        display: "block",
                        // backgroundColor: pathname === "/team" ? "#3f9df4" : null,
                        color: pathname === "/RoleList" ? "#258d4d" : null,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          // minHeight: 48,
                          p: 0.5,
                          pl: 7,
                          justifyContent: open ? "initial" : "center",
                          // px: 2.5,
                        }}
                        // selected={pathname === "/team"}
                        onClick={() => navigate("/RoleList")}
                      >
                        {" "}
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <ListAlt
                            className="colors"
                            fontSize="small"
                            sx={{
                              color:
                                pathname === "/RoleList" ? "#258d4d" : "black",
                              mr: 1,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontFamily: "Lato" }}
                          primary={
                            <Box
                              className="colors"
                              sx={{
                                ...allpageFontStyle.sideBartypography,
                                fontWeight:
                                  pathname === "/RoleList" ? "bold" : null,
                              }}
                            >
                              Role List
                            </Box>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                {allowedPages().role.roleAllocation?.showPage() &&
                  hideRoleScreens && (
                    <ListItem
                      className="HoverMenu"
                      disablePadding
                      sx={{
                        display: "block",
                        // backgroundColor:
                        // pathname === "/team/mapping" ? "#3f9df4" : null,
                        color:
                          pathname === "/Role/userMapping" ? "#258d4d" : null,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          // minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          // px: 2.5,
                          p: 0.5,
                          pl: 7,
                        }}
                        // selected={pathname ==="/Role/userMapping"}
                        onClick={() => navigate("/Role/userMapping")}
                      >
                        {" "}
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Lan
                            className="colors"
                            fontSize="small"
                            sx={{
                              color:
                                pathname === "/Role/userMapping"
                                  ? "#258d4d"
                                  : "black",
                              mr: 1,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontFamily: "Lato" }}
                          primary={
                            <Box
                              className="colors"
                              sx={{
                                ...allpageFontStyle.sideBartypography,
                                fontWeight:
                                  pathname === "/Role/userMapping"
                                    ? "bold"
                                    : null,
                              }}
                            >
                              Role Mapping
                            </Box>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                {allowedPages().role.rolePermission?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      // pathname === "/team/mapping" ? "#3f9df4" : null,
                      color: pathname === "/Role/Permission" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname ==="/Role/Permission"}
                      onClick={() => navigate("/Role/Permission")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <LockOpen
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/Role/Permission"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/Role/Permission" ? "bold" : null,
                            }}
                          >
                            Role Permission
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().ProjectManagement.showMenu() && (
              <ListItem
                disablePadding
                className="HoverMenu"
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/createProject"
                      ? "black"
                      : pathname === "/project"
                      ? "black"
                      : pathname === "/project_joblist"
                      ? "black"
                      : null,
                  color:
                    pathname === "/createProject"
                      ? "white"
                      : pathname === "/project"
                      ? "white"
                      : pathname === "/project_joblist"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setProjectOpen(!projectOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.projectManagement}
                            nav={navigate}
                            headerLabel={"Project Management"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Create Project" style={images} src={project} /> */}
                      <FolderSpecialIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createProject"
                              ? "#258d4d"
                              : pathname === "/project"
                              ? "#258d4d"
                              : pathname === "/project_joblist"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/createProject"
                              ? "bold"
                              : pathname === "/project"
                              ? "bold"
                              : pathname === "/project_joblist"
                              ? "bold"
                              : null,
                        }}
                      >
                        Project Management
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{projectOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={projectOpen} timeout={"auto"}>
              <List>
                {allowedPages().ProjectManagement.createProject?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/createProject" ? "#3f9df4" : null,
                      color: pathname === "/createProject" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/createProject"}
                      onClick={() => navigate("/createProject")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PostAddOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/createProject"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/createProject" ? "bold" : null,
                            }}
                          >
                            Create Project
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().ProjectManagement?.ProjectList?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/project" ? "#3f9df4" : null,
                      color: pathname === "/project" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/project"}
                      onClick={() => navigate("/project")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SummarizeOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/project" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/project" ? "bold" : null,
                            }}
                          >
                            Project List
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {/* {allowedPages().ProjectManagement.jobList?.showPage() && <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    // backgroundColor:
                    // pathname === "/project_joblist" ? "#3f9df4" : null,
                    color: pathname === "/project_joblist" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                    // selected={pathname === "/project_joblist"}
                    onClick={() => navigate("/project_joblist")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <FactCheckOutlinedIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/project_joblist"
                              ? "#258d4d"
                              : "black",
                          mr: 1,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/project_joblist" ? "bold" : null,
                          }}
                        >
                          Job List
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>} */}
                <Divider />
              </List>
            </Collapse>

            {allowedPages().Jobs.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/createjob"
                      ? "black"
                      : pathname === "/jobList"
                      ? "black"
                      : pathname === "/taskList"
                      ? "black"
                      : null,
                  color:
                    pathname === "/createjob"
                      ? "white"
                      : pathname === "/jobList"
                      ? "white"
                      : pathname === "/taskList"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setjobOpen(!jobOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.jobs}
                            nav={navigate}
                            headerLabel={"Jobs"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Create Job" style={images} src={job} /> */}
                      <WorkIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createjob"
                              ? "#258d4d"
                              : pathname === "/jobList"
                              ? "#258d4d"
                              : pathname === "/taskList"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/jobList"
                              ? "bold"
                              : pathname === "/taskList"
                              ? "bold"
                              : pathname === "/createjob"
                              ? "bold"
                              : null,
                        }}
                      >
                        Jobs
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{jobOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={jobOpen} timeout={"auto"}>
              <List>
                {allowedPages().Jobs.createJob?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/createjob" ? "#3f9df4" : null,
                      color: pathname === "/createjob" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/createjob"}
                      onClick={() => navigate("/createjob")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AddToQueueOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/createjob" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/createjob" ? "bold" : null,
                            }}
                          >
                            Create Job
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().Jobs.jobList?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/jobList" ? "#3f9df4" : null,
                      color: pathname === "/jobList" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",

                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/jobList"}
                      onClick={() => navigate("/jobList")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <BallotOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/jobList" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/jobList" ? "bold" : null,
                            }}
                          >
                            Job List
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {/* {allowedPages().Jobs.taskList?.showPage() && <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    // backgroundColor:
                    // pathname === "/taskList" ? "#3f9df4" : null,
                    color: pathname === "/taskList" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      p: 0.5,
                      pl: 7,
                    }}
                    // selected={pathname === "/taskList"}
                    onClick={() => navigate("/taskList")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <FeaturedPlayListOutlinedIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/taskList" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/taskList" ? "bold" : null,
                          }}
                        >
                          Task List
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>} */}
                {/* {allowedPages().Jobs.taskRevert?.showPage() && <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    // backgroundColor:
                    //   pathname === "/taskRevert" ? "#3f9df4" : null,
                    color: pathname === "/taskRevert" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      p: 0.5,
                      pl: 7,
                    }}
                    // selected={pathname === "/taskRevert"}
                    onClick={() => navigate("/taskRevert")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <UndoOutlinedIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/taskRevert" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/taskRevert" ? "bold" : null,
                          }}
                        >
                          Task Revert
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>} */}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().Generaltask.showMenu() && (
              <ListItem
                disablePadding
                className="HoverMenu"
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/generalTask/create" ||
                    pathname === "/generalTask/edit"
                      ? "black"
                      : pathname === "/generalTask/map"
                      ? "black"
                      : pathname === "/generalTask/list"
                      ? "black"
                      : null,
                  color:
                    pathname === "/generalTask/create" ||
                    pathname === "/generalTask/edit"
                      ? "white"
                      : pathname === "/generalTask/map"
                      ? "white"
                      : pathname === "/generalTask/list"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    // pl:1
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setGeneralTaskOpen(!generealTaskOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      ml: 1,
                    }}
                  >
                    {" "}
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.generalTask}
                            nav={navigate}
                            headerLabel={"General Task"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Create Project" style={images} src={project} /> */}
                      <PendingActionsOutlinedIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/generalTask/create" ||
                            pathname === "/generalTask/edit"
                              ? "#258d4d"
                              : pathname === "/generalTask/map"
                              ? "#258d4d"
                              : pathname === "/generalTask/list"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        className="colors"
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/generalTask/create"
                              ? "bold"
                              : pathname === "/generalTask/edit"
                              ? "bold"
                              : pathname === "/generalTask/map"
                              ? "bold"
                              : pathname === "/generalTask/list"
                              ? "bold"
                              : null,
                        }}
                      >
                        General Task
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{generealTaskOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={generealTaskOpen} timeout={"auto"}>
              <List>
                {allowedPages().Generaltask.createGeneralTask?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/generalTask/create" ? "#3f9df4" : null,
                      color:
                        pathname === "/generalTask/create" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/generalTask/create"}
                      onClick={() => navigate("/generalTask/create")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <EditNoteOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/generalTask/create"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/generalTask/create"
                                  ? "bold"
                                  : null,
                            }}
                          >
                            Create General Task
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().Generaltask.GeneralTaskList?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   (pathname === "/generalTask/list" || pathname === "/generalTask/edit") ? "#3f9df4" : null,
                      color:
                        pathname === "/generalTask/list" ||
                        pathname === "/generalTask/edit"
                          ? "#258d4d"
                          : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={(pathname === "/generalTask/list" || pathname === "/generalTask/edit")}
                      onClick={() => navigate("/generalTask/list")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FormatListBulletedOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/project_joblist"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/generalTask/list"
                                  ? "bold"
                                  : null,
                            }}
                          >
                            General Task List
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().Generaltask.generalTaskAllocation?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/generalTask/map" ? "#3f9df4" : null,
                      color: pathname === "/generalTask/map" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/generalTask/map"}
                      onClick={() => navigate("/generalTask/map")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <LowPriorityOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/project_joblist"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/generalTask/map" ? "bold" : null,
                            }}
                          >
                            Task Allocation
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().WorkStation.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/taskMapping"
                      ? "black"
                      : pathname === "/taskreassign"
                      ? "black"
                      : pathname === "/tasks/split"
                      ? "black"
                      : pathname === "/tasks/revert"
                      ? "black"
                      : pathname === "/generalTask/user"
                      ? "black"
                      : pathname === "/tasks"
                      ? "black"
                      : pathname === "/completedTask"
                      ? "black"
                      : pathname === "/taskRevert"
                      ? "black"
                      : pathname === "/generalTask/history"
                      ? "black"
                      : null,
                  color:
                    pathname === "/taskMapping"
                      ? "white"
                      : pathname === "/taskreassign"
                      ? "white"
                      : pathname === "/tasks/split"
                      ? "white"
                      : pathname === "/tasks/revert"
                      ? "white"
                      : pathname === "/generalTask/user"
                      ? "white"
                      : pathname === "/tasks"
                      ? "white"
                      : pathname === "/completedTask"
                      ? "white"
                      : pathname === "/taskRevert"
                      ? "white"
                      : pathname === "/generalTask/history"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setWorkStationOpen(!workStationOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.taskManagement}
                            nav={navigate}
                            headerLabel={"Task Management"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img
                    alt="Task Assignment"
                    style={images}
                    src={taskassign}
                  /> */}
                      <AssignmentIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/taskMapping"
                              ? "#258d4d"
                              : pathname === "/taskreassign"
                              ? "#258d4d"
                              : pathname === "/tasks/split"
                              ? "#258d4d"
                              : pathname === "/tasks/revert"
                              ? "#258d4d"
                              : pathname === "/generalTask/user"
                              ? "#258d4d"
                              : pathname === "/tasks"
                              ? "#258d4d"
                              : pathname === "/completedTask"
                              ? "#258d4d"
                              : pathname === "/taskRevert"
                              ? "#258d4d"
                              : pathname === "/generalTask/history"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    className="colors"
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/taskMapping"
                              ? "bold"
                              : pathname === "/taskreassign"
                              ? "bold"
                              : pathname === "/tasks/split"
                              ? "bold"
                              : pathname === "/tasks/revert"
                              ? "bold"
                              : pathname === "/generalTask/user"
                              ? "bold"
                              : pathname === "/tasks"
                              ? "bold"
                              : pathname === "/completedTask"
                              ? "bold"
                              : pathname === "/taskRevert"
                              ? "bold"
                              : pathname === "/generalTask/history"
                              ? "bold"
                              : null,
                        }}
                      >
                        Task Management
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{workStationOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={workStationOpen} timeout={"auto"}>
              <List>
                {allowedPages().WorkStation.taskAssignment?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      // pathname === "/taskMapping" ? "#3f9df4" : null,
                      color: pathname === "/taskMapping" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/taskMapping"}
                      onClick={() => {
                        navigate("/taskMapping");
                        dispatch(
                          updateMasterStates({
                            name: "tasklistData",
                            value: [],
                          })
                        );
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <NoteAltOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/taskMapping" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/taskMapping" ? "bold" : null,
                            }}
                          >
                            Task Assignment
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().WorkStation.taskReassignment?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/taskreassign" ? "#3f9df4" : null,
                      color: pathname === "/taskreassign" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/taskreassign"}
                      onClick={() => {
                        dispatch(
                          updateMasterStates({
                            name: "tasklistData",
                            value: [],
                          })
                        );
                        navigate("/taskreassign");
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <RestorePageOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/taskreassign"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/taskreassign" ? "bold" : null,
                            }}
                          >
                            Task Reassignment
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {allowedPages().WorkStation.taskSplit?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/tasks/split" ? "#3f9df4" : null,
                      color: pathname === "/tasks/split" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/tasks/split"}
                      onClick={() => navigate("/tasks/split")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SplitscreenOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/tasks/split" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/tasks/split" ? "bold" : null,
                            }}
                          >
                            Task Split
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {allowedPages().WorkStation.tasks?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/tasks" ? "#3f9df4" : null,
                      color: pathname === "/tasks" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/tasks"}
                      onClick={() => navigate("/tasks")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AssignmentOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color: pathname === "/tasks" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight: pathname === "/tasks" ? "bold" : null,
                            }}
                          >
                            Tasks
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().WorkStation.tasks?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/tasks" ? "#3f9df4" : null,
                      color: pathname === "/completedTask" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/tasks"}
                      onClick={() => navigate("/completedTask")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AssignmentOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/completedTask"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/completedTask" ? "bold" : null,
                            }}
                          >
                            Task Completed
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().WorkStation.tasks.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor: pathname === "/tasks" ? "#3f9df4" : null,
                      color:
                        pathname === "/generalTask/history" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/generalTask/history"}
                      onClick={() => navigate("/generalTask/history")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FeedOutlined
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/generalTask/history"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/generalTask/history"
                                  ? "bold"
                                  : null,
                            }}
                          >
                            General Task Details
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {allowedPages().WorkStation.taskRevert?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/taskRevert" ? "#3f9df4" : null,
                      color: pathname === "/taskRevert" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/taskRevert"}
                      onClick={() => navigate("/taskRevert")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <UndoOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/taskRevert" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/taskRevert" ? "bold" : null,
                            }}
                          >
                            Task Revert
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().WorkStation.revertedTask?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/tasks/revert" ? "#3f9df4" : null,
                      color: pathname === "/tasks/revert" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/tasks/revert"}
                      onClick={() => navigate("/tasks/revert")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AssignmentReturnOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/tasks/revert"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/tasks/revert" ? "bold" : null,
                            }}
                          >
                            Reverted Task
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {/* {allowedPages().WorkStation.generalTask && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/tasks/revert" ? "#3f9df4" : null,
                      color: pathname === "/generalTask/user" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/tasks/revert"}
                      onClick={() => navigate("/generalTask/user")}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Task
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/generalTask/user"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                                 ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/generalTask/user" ? "bold" : null,
                            }}
                          >
                            General Task
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )} */}
                <Divider />
              </List>
            </Collapse>
            {allowedPages().report.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/taskReport"
                      ? "black"
                      : pathname === "/project_report"
                      ? "black"
                      : pathname === "/job_report"
                      ? "black"
                      : pathname === "/taskStatusReport"
                      ? "black"
                      : null,
                  color:
                    pathname === "/taskReport"
                      ? "white"
                      : pathname === "/project_report"
                      ? "white"
                      : pathname === "/job_report"
                      ? "white"
                      : pathname === "/taskStatusReport"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    if (open) {
                      setReportOpen(!reportOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <StyledTooltip
                      title={
                        !open && (
                          <SidebarList
                            arr={listPopup.report}
                            nav={navigate}
                            headerLabel={"Report"}
                            open={open}
                            path={pathname}
                          />
                        )
                      }
                      arrow
                      placement="right"
                    >
                      {/* <img alt="Report" style={images} src={document} /> */}
                      <DescriptionIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/taskReport"
                              ? "#258d4d"
                              : pathname === "/project_report"
                              ? "#258d4d"
                              : pathname === "/job_report"
                              ? "#258d4d"
                              : pathname === "/taskStatusReport"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    className="colors"
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/taskReport"
                              ? "bold"
                              : pathname === "/project_report"
                              ? "bold"
                              : pathname === "/job_report"
                              ? "bold"
                              : pathname === "/taskStatusReport"
                              ? "bold"
                              : null,
                        }}
                      >
                        Report
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{reportOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={reportOpen} timeout={"auto"}>
              <List>
                {allowedPages().report.taskReport?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/taskReport" ? "#3f9df4" : null,
                      color: pathname === "/taskReport" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/taskReport"}
                      onClick={() => {
                        navigate("/taskReport");
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AssessmentOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/taskReport" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/taskReport" ? "bold" : null,
                            }}
                          >
                            Task Report
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().report.taskStatusReport?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/taskReport" ? "#3f9df4" : null,
                      color:
                        pathname === "/taskStatusReport" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/taskReport"}
                      onClick={() => {
                        navigate("/taskStatusReport");
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SummarizeOutlined
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/taskStatusReport"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/taskStatusReport"
                                  ? "bold"
                                  : null,
                            }}
                          >
                            Task Status Report
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().report.projectReport?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/project_report" ? "#3f9df4" : null,
                      color: pathname === "/project_report" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/project_report"}
                      onClick={() => {
                        navigate("/project_report");
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <DocumentScannerOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/project_report"
                                ? "#258d4d"
                                : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/project_report" ? "bold" : null,
                            }}
                          >
                            Project Report
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {allowedPages().report.jobReport?.showPage() && (
                  <ListItem
                    className="HoverMenu"
                    disablePadding
                    sx={{
                      display: "block",
                      // backgroundColor:
                      //   pathname === "/job_report" ? "#3f9df4" : null,
                      color: pathname === "/job_report" ? "#258d4d" : null,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        // px: 2.5,
                        p: 0.5,
                        pl: 7,
                      }}
                      // selected={pathname === "/job_report"}
                      onClick={() => {
                        navigate("/job_report");
                      }}
                    >
                      {" "}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          // mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <TableChartOutlinedIcon
                          className="colors"
                          fontSize="small"
                          sx={{
                            color:
                              pathname === "/job_report" ? "#258d4d" : "black",
                            mr: 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: "Lato" }}
                        primary={
                          <Box
                            className="colors"
                            sx={{
                              ...allpageFontStyle.sideBartypography,
                              fontWeight:
                                pathname === "/job_report" ? "bold" : null,
                            }}
                          >
                            Job Report
                          </Box>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <Divider />
              </List>
            </Collapse>
            {/* {(allowedPages().meeting.showMenu()) && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/request"
                      ? "black"
                      : pathname === "/createHall"
                        ? "black"
                        : null,
                  color:
                    pathname === "/request"
                      ? "white"
                      : pathname === "/createHall"
                        ? "white"
                        : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    
                    justifyContent: open ? "initial" : "center",
                    
                    p: 1,
                    pl: 2,
                  }}
                  
                  onClick={() => {
                    if (open) {
                      setMeetingOpen(!meetingOpen);
                    }
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    }

                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StyledTooltip title={!open &&
                      <SidebarList
                        arr={listPopup.meeting}
                        nav={navigate}
                        headerLabel={"Meeting"}
                        open={open} path={pathname} />
                    } placement="right">
                    
                      <Diversity2Icon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createHall"
                              ? "#258d4d"
                              : pathname === "/request"
                                ? "#258d4d"
                                : "black",
                        }}
                      />
                    </StyledTooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    className="colors"
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        sx={{
                             ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/createHall"
                              ? "bold"
                              : pathname === "/request"
                                ? "bold"
                                : null,
                        }}
                      >
                        Meeting
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {!open ? null : (
                    <>{meetingOpen ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
              </ListItem>
            )}
            <Collapse in={meetingOpen} timeout={"auto"}>
              <List>
                {allowedPages().meeting.schedule?.showPage() && <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    // backgroundColor: pathname === "/request" ? "#3f9df4" : null,
                    // opacity:0.7,
                    color: pathname === "/request" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                    // selected={pathname === "/request"}
                    onClick={() => navigate("/request")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <EditCalendarOutlinedIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/request" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                    
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight: pathname === "/request" ? "bold" : null,
                          }}
                        >
                          Schedule Meeting
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>}
                {allowedPages().meeting.createHall?.showPage() && <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    
                    color: pathname === "/createHall" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                     
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      p: 0.5,
                      pl: 7,
                    }}
                    
                    onClick={() => navigate("/createHall")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <RoomPreferencesOutlinedIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/createHall" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                     
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/createHall" ? "bold" : null,
                          }}
                        >
                          Create Hall
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>}
              </List>
              <Divider />
            </Collapse> */}

            {allowedPages().support.showMenu() && (
              <ListItem
                className="HoverMenu"
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === "/createAnnouncement"
                      ? "black"
                      : pathname === "/announcementList"
                      ? "black"
                      : pathname === "/queryList"
                      ? "black"
                      : pathname === "/createQuery"
                      ? "black"
                      : pathname === "/allAnnouncements"
                      ? "black"
                      : pathname === "/myQueryList"
                      ? "black"
                      : pathname === "/editQuery"
                      ? "black"
                      : pathname === "/helpdesk"
                      ? "black"
                      : null,
                  color:
                    pathname === "/createAnnouncement"
                      ? "white"
                      : pathname === "/announcementList"
                      ? "white"
                      : pathname === "/queryList"
                      ? "white"
                      : pathname === "/createQuery"
                      ? "white"
                      : pathname === "/allAnnouncements"
                      ? "white"
                      : pathname === "/myQueryList"
                      ? "white"
                      : pathname === "/editQuery"
                      ? "white"
                      : pathname === "/helpdesk"
                      ? "white"
                      : null,
                  borderRadius: "0px 30px 30px 0px",
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                    p: 1,
                    pl: 2,
                  }}
                  // selected={pathname === "/"}
                  onClick={() => {
                    // sethelpdeskOpen(!helpdeskOpen);
                    if (masterOpen) {
                      setmasterOpen(false);
                    } else if (manageTeam) {
                      setMangeTeam(false);
                    } else if (userMangementopen) {
                      setUserMangementopen(false);
                    } else if (jobOpen) {
                      setjobOpen(false);
                    } else if (workStationOpen) {
                      setWorkStationOpen(false);
                    } else if (reportOpen) {
                      setReportOpen(false);
                    } else if (projectOpen) {
                      setProjectOpen(false);
                    } else if (generealTaskOpen) {
                      setGeneralTaskOpen(false);
                    } else if (rolesOpen) {
                      setRolesOpen(false);
                    } else if (meetingOpen) {
                      setMeetingOpen(false);
                    }
                    navigate("/helpdesk");
                    // setOpen(true);
                    // navigate("/");
                  }}
                >
                  {" "}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Tooltip title="Support" placement="right">
                      {/* <img alt="Create Project" style={images} src={project} /> */}
                      <HeadsetMicIcon
                        className="colors"
                        sx={{
                          color:
                            pathname === "/createAnnouncement"
                              ? "#258d4d"
                              : pathname === "/announcementList"
                              ? "#258d4d"
                              : pathname === "/createQuery"
                              ? "#258d4d"
                              : pathname === "/queryList"
                              ? "#258d4d"
                              : pathname === "/allAnnouncements"
                              ? "#258d4d"
                              : pathname === "/myQueryList"
                              ? "#258d4d"
                              : pathname === "/editQuery"
                              ? "#258d4d"
                              : pathname === "/helpdesk"
                              ? "#258d4d"
                              : "black",
                        }}
                      />
                    </Tooltip>
                  </ListItemIcon>{" "}
                  <ListItemText
                    className="colors"
                    primaryTypographyProps={{ fontFamily: "Lato" }}
                    primary={
                      <Box
                        sx={{
                          ...allpageFontStyle.sideBartypography,
                          fontWeight:
                            pathname === "/createAnnouncement"
                              ? "bold"
                              : pathname === "/announcementList"
                              ? "bold"
                              : pathname === "/createQuery"
                              ? "bold"
                              : pathname === "/queryList"
                              ? "bold"
                              : pathname === "/allAnnouncements"
                              ? "bold"
                              : pathname === "/myQueryList"
                              ? "bold"
                              : pathname === "/editQuery"
                              ? "bold"
                              : pathname === "/helpdesk"
                              ? "bold"
                              : null,
                        }}
                      >
                        Support
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {/* {!open ? null : (
                  <>{helpdeskOpen ? <ExpandLess /> : <ExpandMore />}</>
                )} */}
                </ListItemButton>
              </ListItem>
            )}

            {/* Announcement */}
            {/* <Collapse in={helpdeskOpen} timeout={"auto"}>
              <List>
                <ListItem
                 
                  disablePadding
                  sx={{
                    display: "block",
                    pl: 3,
                    // opacity:0.7,
                    backgroundColor:
                      pathname === "/createAnnouncement"
                        ? "#68a57a"
                        : pathname === "/announcementList"
                          ? "#68a57a"
                          : pathname === "/allAnnouncements"
                            ? "#68a57a"
                            
                            //   ? "black"
                            
                            //     ? "black"
                            : null,
                    color:
                      pathname === "/createAnnouncement"
                        ? "white"
                        : pathname === "/announcementList"
                          ? "white"
                          : pathname === "/allAnnouncements"
                            ? "white"
                            
                            //   ? "white"
                         
                            //     ? "white"
                            : null,
                    borderRadius: "0px 30px 30px 0px",
                  }}
                >
                  <ListItemButton
                    sx={{
                    
                      justifyContent: open ? "initial" : "center",
                     
                      p: 1,
                      pl: 2,
                    }}
                   
                    onClick={() => {
                      setAnnounceOpen(!announcementOpen);
                      if (masterOpen) {
                        setmasterOpen(false);
                      } else if (manageTeam) {
                        setMangeTeam(false);
                      } else if (userMangementopen) {
                        setUserMangementopen(false);
                      } else if (jobOpen) {
                        setjobOpen(false);
                      } else if (workStationOpen) {
                        setWorkStationOpen(false);
                      } else if (reportOpen) {
                        setReportOpen(false);
                      } else if (projectOpen) {
                        setProjectOpen(false);
                      } else if (generealTaskOpen) {
                        setGeneralTaskOpen(false);
                      } else if (rolesOpen) {
                        setRolesOpen(false);

                      } else if (meetingOpen) {
                        setMeetingOpen(false);

                      } else if (queriesOpen) {
                        setQueriesOpen(false);
                      }

                  setOpen(true);
                 
                }}
              >
                {" "}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Tooltip title="Announcement" placement="right">
                    
                    <CampaignIcon 
                      className="colors"
                      sx={{
                        color:
                          pathname === "/createAnnouncement"
                            ? "white"
                            : pathname === "/announcementList"
                              ? "white"
                            : pathname === "/allAnnouncements"
                              ? "white"
                             
                                  : "black",
                      }}
                    />
                  </Tooltip>
                </ListItemIcon>{" "}
                <ListItemText
                  className="colors"
                  primaryTypographyProps={{ fontFamily: "Lato" }}
                  primary={
                    <Box
                      sx={{
                           ...allpageFontStyle.sideBartypography,
                        
                        fontWeight:
                          pathname === "/createAnnouncement"
                            ? "bold"
                            : pathname === "/announcementList"
                              ? "bold"
                            : pathname === "/allAnnouncements"
                              ? "bold"
                             
                                  : null,
                      }}
                    >
                      Announcement
                    </Box>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {!open ? null : (
                  <>{announcementOpen ? <ExpandLess /> : <ExpandMore />}</>
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={announcementOpen} timeout={"auto"}>
            <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                   
                    color: pathname === "/createAnnouncement" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                   
                    }}
                    
                    onClick={() => navigate("/createAnnouncement")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <RateReviewIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/createAnnouncement" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                    
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                            fontSize: 13,
                            fontWeight: pathname === "/createAnnouncement" ? "bold" : null,
                          }}
                        >
                          Create Announcement
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
               
                <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                   
                    color: pathname === "/allAnnouncements" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                     
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                     
                    }}
                   
                    onClick={() => navigate("/allAnnouncements")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                     
                        justifyContent: "center",
                      }}
                    >
                      <InterpreterModeIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/allAnnouncements" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                     
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight: pathname === "/allAnnouncements" ? "bold" : null,
                          }}
                        >
                         All Announcements
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                   
                    color: pathname === "/announcementList" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                    
                    onClick={() => navigate("/announcementList")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                       
                        justifyContent: "center",
                      }}
                    >
                      <MicIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/announcementList" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                      
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight: pathname === "/announcementList" ? "bold" : null,
                          }}
                        >
                          My Announcements
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Collapse>
             

                {/* Queries */}
            {/* <ListItem
                 
                  disablePadding
                  sx={{
                    display: "block",
                    pl: 3,
                    backgroundColor:
                      
                     
                      pathname === "/queryList"
                        ? "#68a57a"
                        : pathname === "/createQuery"
                          ? "#68a57a"
                        : pathname === "/myQueryList"
                          ? "#68a57a"
                        : pathname === "/editQuery"
                          ? "#68a57a"
                          : null,
                color:
                 
                       pathname === "/queryList"
                        ? "white"
                        : pathname === "/createQuery"
                          ? "white"
                        : pathname === "/myQueryList"
                          ? "white"
                        : pathname === "/editQuery"
                          ? "white"
                          : null,
                borderRadius: "0px 30px 30px 0px",
              }}
            >
              <ListItemButton
                sx={{
                 
                  justifyContent: open ? "initial" : "center",
                  
                  p: 1,
                  pl: 2,
                }}
                
                onClick={() => {
                  setQueriesOpen(!queriesOpen);
                  if (masterOpen) {
                    setmasterOpen(false);
                  } else if (manageTeam) {
                    setMangeTeam(false);
                  } else if (userMangementopen) {
                    setUserMangementopen(false);
                  } else if (jobOpen) {
                    setjobOpen(false);
                  } else if (workStationOpen) {
                    setWorkStationOpen(false);
                  } else if (reportOpen) {
                    setReportOpen(false);
                  } else if (projectOpen) {
                    setProjectOpen(false);
                  } else if (generealTaskOpen) {
                    setGeneralTaskOpen(false);
                  } else if (rolesOpen) {
                    setRolesOpen(false);

                      } else if (meetingOpen) {
                        setMeetingOpen(false);
                      } else if (announcementOpen) {
                        setAnnounceOpen(false);
                      }

                  setOpen(true);
                  
                }}
              >
                {" "}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Tooltip title="Queries" placement="right">
                  
                    <TextsmsIcon
                      
                      sx={{
                        color:
                        
                               pathname === "/createQuery"
                                ? "white"
                                : pathname === "/queryList"
                                  ? "white"
                                : pathname === "/myQueryList"
                                  ? "white"
                                : pathname === "/editQuery"
                                  ? "white"
                                  : "black",
                      }}
                    />
                  </Tooltip>
                </ListItemIcon>{" "}
                <ListItemText
                  className="colors"
                  primaryTypographyProps={{ fontFamily: "Lato" }}
                  primary={
                    <Box
                      sx={{
                           ...allpageFontStyle.sideBartypography,
                        fontWeight:
                         
                               pathname === "/createQuery"
                                ? "bold"
                                : pathname === "/queryList"
                                  ? "bold"
                                : pathname === "/myQueryList"
                                  ? "bold"
                                : pathname === "/editQuery"
                                  ? "bold"
                                  : null,
                      }}
                    >
                      Queries
                    </Box>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {!open ? null : (
                  <>{queriesOpen ? <ExpandLess /> : <ExpandMore />}</>
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={queriesOpen} timeout={"auto"}>
            <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                   
                    color: pathname === "/createQuery" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                     
                      p: 0.5,
                      pl: 7,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                 
                    onClick={() => navigate("/createQuery")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AddCommentIcon 
                        className="colors"
                        fontSize="small"
                        sx={{
                          color: pathname === "/createQuery" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                   
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight: pathname === "/createQuery" ? "bold" : null,
                          }}
                        >
                          Create Query
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                   
                    color: pathname === "/queryList" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                    
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      p: 0.5,
                      pl: 7,
                    }}
                   
                    onClick={() => navigate("/queryList")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                       
                        justifyContent: "center",
                      }}
                    >
                      <ForumIcon 
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/queryList" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                     
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/queryList" ? "bold" : null,
                          }}
                        >
                         All Queries
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    
                    color: pathname === "/myQueryList" || pathname === '/editQuery' ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                     
                      justifyContent: open ? "initial" : "center",
                     
                      p: 0.5,
                      pl: 7,
                    }}
                   
                    onClick={() => navigate("/myQueryList")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ThreePIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/myQueryList" || pathname === '/editQuery' ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                      
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/myQueryList" || pathname === '/editQuery'  ? "bold" : null,
                          }}
                        >
                          My Queries
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                {/* <ListItem
                  className="HoverMenu"
                  disablePadding
                  sx={{
                    display: "block",
                    // backgroundColor: pathname === "/createHall" ? "#3f9df4" : null,
                    // opacity:0.7,
                    color: pathname === "/createSLA" ? "#258d4d" : null,
                  }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      p: 0.5,
                      pl: 7,
                    }}
                    // selected={pathname === "/createHall"}
                    onClick={() => navigate("/createSLA")}
                  >
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ThreePIcon
                        className="colors"
                        fontSize="small"
                        sx={{
                          color:
                            pathname === "/createSLA" ? "#258d4d" : "black",
                          mr: 1,
                        }}
                      />
                      
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "Lato" }}
                      primary={
                        <Box
                          className="colors"
                          sx={{
                               ...allpageFontStyle.sideBartypography,
                            fontWeight:
                              pathname === "/createSLA" ? "bold" : null,
                          }}
                        >
                         SLA 
                        </Box>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem> */}
            {/* </Collapse>
              </List>
            </Collapse> */}

            <ListItem
              className="HoverMenu"
              disablePadding
              sx={{ display: "block", flex: 1 }}
            >
              <ListItemButton
                onClick={() => {
                  handleClickOpen();
                  // dispatch(logoutAction(navigate));
                  // dispatch(resetmasterReducer());
                  // dispatch(resetCommonReducer());
                  // dispatch(resetPersistReducer());
                  // dispatch(resetProfileReducer());
                }}
                sx={{
                  // minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  // px: 2.5,
                  p: 1,
                  pl: 2,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Logout" placement="right">
                    {/* <img style={{ height: 30, width: 30 }} src={exit} /> */}
                    <LogoutIcon className="colors" sx={{ color: "black" }} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      className="colors"
                      sx={{ ...allpageFontStyle.sideBartypography }}
                    >
                      Logout
                    </Box>
                  }
                  primaryTypographyProps={{ fontFamily: "Lato" }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>

      <Box
        // component="main"
        flex={1}
        ref={scrollRef}
        sx={{
          flexGrow: 1,
          // height: "100vh",
          overflowY: "auto",
          width: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DrawerHeader />
        <Box
          sx={{
            px: 2,
            pt: 2,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            pb: 1,
            justifyContent: "center",
            //  background:'#358c4e',
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute", left: 20 }}>
            {/* <Tooltip title="Back" placement="bottom"> */}
            <Button
              sx={{
                color: "#258d4d",
                ...allpageFontStyle.buttonFontStyle,
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "#358c4e",
                  color: "white",
                  borderColor: "#358c4e",
                },
              }}
              onClick={goBack}
              variant="text"
            >
              <ArrowBackIosOutlinedIcon fontSize="inherit" sx={{ mr: 0.5 }} />{" "}
              Back{" "}
            </Button>
            {/* <ArrowBackRoundedIcon
                sx={{
                  border: "1px solid black",
                  borderRadius: 2,
                  height: 30,
                  width: 30,
                  backgroundColor: "#d9d9d9",
                }}
                onClick={}
              /> */}
            {/* </Tooltip> */}
          </Box>
          {/* <Box
            sx={{
              width: "95%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {pathname === "/" ? (
              <Box sx={title}>Dashboard</Box>
            ) : pathname === "/dashboard" ? (
              <Box sx={title}>Dashboard</Box>
            ) : pathname === "/org" ? (
              <Box sx={title}>Organization</Box>
            ) : pathname === "/branch" ? (
              <Box sx={title}>Branch</Box>
            ) : pathname === "/department" ? (
              <Box sx={title}>Department</Box>
            ) : pathname === "/designation" ? (
              <Box sx={title}>Designation</Box>
            ) : pathname === "/grade" ? (
              <Box sx={title}>Grade</Box>
            ) : pathname === "/createUser" ? (
              <Box sx={title}> User</Box>
            ) : pathname === "/user" ? (
              <Box sx={title}>User List</Box>
            ) : pathname === "/team" || pathname === "/createTeam" ? (
              <Box sx={title}>Team</Box>
            ) : pathname === "/team/mapping" ? (
              <Box sx={title}>User Allocation</Box>
            ) : pathname === "/project" || pathname === "/createProject" ? (
              <Box sx={title}>Project</Box>
            ) : pathname === "/project_joblist" ? (
              <Box sx={title}>Job List</Box>
            ) : pathname === "/createjob" || pathname === "/jobList" ? (
              <Box sx={title}>Job</Box>
            ) : pathname === "/taskMapping" ? (
              <Box sx={title}>Task Assignment</Box>
            ) : pathname === "/taskreassign" ? (
              <Box sx={title}>Task Reassignment</Box>
            ) : pathname === "/tasks" ? (
              <Box sx={title}>Tasks</Box>
            ) : pathname === "/tasks/split" ? (
              <Box sx={title}>Task Split</Box>
            ) : pathname === "/tasks/revert" ? (
              <Box sx={title}>Reverted Task</Box>
            ) : pathname === "/taskList" ? (
              <Box sx={title}>Task List</Box>
            ) : pathname === "/taskReport" ? (
              <Box sx={title}>Task Report</Box>
            ) : pathname === "/CreateRole" ? (
              <Box sx={title}>Create Role</Box>
            ) : pathname === "/EditRole" ? (
              <Box sx={title}>Edit Role</Box>
            ) : pathname === "/RoleList" ? (
              <Box sx={title}>Role List</Box>
            ) : pathname === "/Role/userMapping" ? (
              <Box sx={title}>Role User Allocation</Box>
            ) : pathname === "/Role/Permission" ? (
              <Box sx={title}>Role Permission</Box>
            ) : pathname === "/taskReport" ? (
              <Box sx={title}>Task Report</Box>
            ) : pathname === "/project_report" ? (
              <Box sx={title}>Project Report</Box>
            ) : pathname === "/taskStatusReport" ? (
              <Box sx={title}>Task Status Report</Box>
            ) : pathname === "/job_report" ? (
              <Box sx={title}>Job Report</Box>
            ) : pathname === "/taskRevert" ? (
              <Box sx={title}>Task Revert</Box>
            ) : pathname === "/request" ? (
              <Box sx={title}>Calendar</Box>
            ) : pathname === "/completedTask" ? (
              <Box sx={title}>Task Completed List</Box>
            ) : pathname === "/generalTask/create" ? (
              <Box sx={title}>Create General Task</Box>
            ) : pathname === "/generalTask/edit" ? (
              <Box sx={title}>Edit General Task</Box>
            ) : pathname === "/generalTask/list" ? (
              <Box sx={title}>General Task List</Box>
            ) : pathname === "/generalTask/map" ? (
              <Box sx={title}>Task Allocation</Box>
            ) : pathname === "/projectMasterList" ||
              pathname === "/CreateMaster" ? (
              <Box sx={title}>Unit Type</Box>
            ) : pathname === "/createHall" ? (
              <Box sx={title}>Conference Location</Box>
            ) : pathname === "/generalTask/user" ? (
              <Box sx={title}>General Task</Box>
            ) : pathname === "/generalTask/history" ? (
              <Box sx={title}>General Task Details</Box>
            ) : pathname === "/profile" ? (
              <Box sx={title}>Profile</Box>
            ) : pathname === "/createAnnouncement" ? (
              <Box sx={title}>Announcements</Box>
            ) : pathname === "/announcementList" ? (
              <Box sx={title}>My Announcements</Box>
            ) : pathname === "/allAnnouncements" ? (
              <Box sx={title}>Announcements</Box>
            ) : pathname === "/generalTaskHistory" ? (
              <Box sx={title}>General Task History</Box>
            ) : pathname === "/createQuery" ? (
              <Box sx={title}>Query</Box>
            ) : pathname === "/queryList" ? (
              <Box sx={title}>Query</Box>
            ) : pathname === "/myQueryList" ? (
              <Box sx={title}>My Queries</Box>
            ) : pathname === "/createSLA" ? (
              <Box sx={title}>Service-Level Agreement</Box>
            ) : pathname === "/helpdesk" ? (
              <Box sx={title}>Help-Desk</Box>
            ) : pathname.includes("/forum/") ? (
              <Box sx={title}>Queries</Box>
            ) : null}
          </Box>

          {/* <Box
            sx={{
              position: 'absolute', right: 23, display: 'flex', alignItems: 'center'
            }}
          >
            <Box sx={{ fontFamily: "lato", fontSize: 15, pr: 1 }}>
              Organization Name:
            </Box>
            {userprofile?.userAccess?.superUser === true ? (
              <Box
                sx={{ fontFamily: "lato", fontSize: 15, fontWeight: "bold" }}
              >
                {globapOrganization?.orgId
                  ? globapOrganization?.orgName
                  : "Ogai"}
              </Box>
            ) : (
              <Box
                sx={{ fontFamily: "lato", fontSize: 15, fontWeight: "bold" }}
              >
                {userprofile?.userInfo?.orgInfo?.orgName}
              </Box>
            )}
          </Box> */}
          {/* </Box> */}
        </Box>
        {/* {runingTask?.state === "Resumed" || runingTask?.state === "Started" ?
          <Box><TaskPageCommen increment={increment}
            handleStart={handleStart}
            handlePause={handlePause}
            handleReset={handleReset}
            handleResume={handleResume}
            timerData={timerData} /></Box> : null} */}
        {/* <Main open={open}>
         
          <Card
            sx={{
              width: "100%",
              display: "flex",
              boxShadow: "0px 1px 5px #000000",
            }}
          >
            <ArrowBackIosNewOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
            <AddOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
            <CreateOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
            <DeleteOutlineOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
            <SaveOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
            <CloseOutlined
              sx={{ m: 2, color: "#1273de", height: 15, width: 15 }}
            />
          </Card>
        </Main> */}
        {pathname !== "/tasks" ? (
          <GeneralTaskTimer open={open} drawerWidth={drawerWidth} />
        ) : null}
        {(runingTask?.state === "Resumed" || runingTask?.state === "Started") &&
        (pathname !== "/tasks" ||
          (assignedtaskData?.length &&
            assignedtaskData[0].jobCode !== runingTask.jobCode) ||
          assignedtaskData?.length === 0) ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: 0,
              backgroundColor: "white",
              left: 0,
              // opacity:0.6,
              zIndex: 100,
              p: 1,
              px: 10,
              // color: "black",
              width: "100%",
            }}
          >
            <TaskPageCommen
              increment={increment}
              handleStart={handleStart}
              handlePause={handlePause}
              handleReset={handleReset}
              handleResume={handleResume}
              timerData={timerData}
              jobCode1={jobCode1}
            />
          </Box>
        ) : null}
        {children}
      </Box>
    </Box>
  );
}
