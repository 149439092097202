import { Box } from "@mui/material";
import { svgImages } from "../../../utils/Imageimport";
import { useEffect, useState } from "react";

export const COLOR_CODE = {
  Assigned: "#028df0",
  Open: "#028df0",
  "In-Progress": "#ff8f6c",
  "Revert Requested": "#7d2ca6",
  End: "#c7c6c6",
  Completed: "#10aa58",
  Pending: "#ff7e7e",
};
export const TASK_STATUS_LIST = {
  Assigned: "Assigned",
  Open: "Open",
  "In-Progress": "In-Progress",
  "Revert Requested": "Revert Requested",
  End: "End",
  completed: "Completed",
  pending: "Pending",
};
export const NameWithStatusColor = ({ status, name, isColorCode, empId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...(!isColorCode && {
          flex: "60%",
          maxWidth: "100%",
          overflow: "hidden",
          // minWidth: "100%",

        }),
      }}
    >
      {status === TASK_STATUS_LIST.completed ? (
        svgImages.completed
      ) : status === TASK_STATUS_LIST.pending ? (
        svgImages.pending
      ) : (
        <Box
          sx={{
            width: 7,
            minWidth: 7,
            height: 7,
            borderRadius: "50%",
            background: COLOR_CODE[status],
          }}
        ></Box>
      )}
      &nbsp;
      <Box
        sx={{
          color: COLOR_CODE[status],
          fontSize: "0.75rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        title={name}
      >
        {isColorCode ? name :empId}
      </Box>
    </Box>
  );
};
export const NameWithStatusColorAndCount = ({
  name,
  status,
  completed,
  pending,
}) => {
  const [empId,setEmpId] = useState('')
  const [empName,setEmpName]=useState('')
  useEffect(()=>{
    if(name){
      const str = name;
      const match = str.match(/\(([^)]+)\)/);
      
      if (match) {
        const substring = match[1];
        setEmpId(substring)
        console.log(substring); // Output: substring
        console.log(substring,"No parentheses found.");
      } else {
        console.log("No parentheses found.");
      }
  }
},[name])
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        justifyContent: "space-between",
        width: "100%",

      }}
    >
      <NameWithStatusColor status={status} name={name} empId={empId} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 1,
          flex: '50%',
          // flex:1,
          justifyContent:'space-between'

        }}
      >
        <Box sx={{ position: "relative",display:'flex', alignItems:'center' }}>
          {svgImages.completed}
          <Box
            sx={{
              // position: "absolute",
              // top: 7,
              // right: -7,
              fontSize: "0.65rem",
              color: "#10aa58",
              fontWeight:'bold',
              ml:-3.2,
              mt:-0.7
            }}
          >
            {completed}
          </Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          {svgImages.pending}
          <Box
            sx={{
              position: "absolute",
              top: 9,
              // right: pending?.toString() ? -(pending?.toString()?.length * 2) : -2 ,
              right:13,
              fontSize: "0.65rem",
              color: "#ff7e7e",
              fontWeight:'bold'
            }}
          >
            {pending}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const StatusView = ({ data, status }) => {
  console.log(data, "sdqwdwdwdew");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {data?.assignedTo !== "Unassigned" ? (
        <NameWithStatusColorAndCount
          name={data?.assignedTo}
          status={status}
          completed={data?.completedCount || 0}
          pending={(data?.measureCount || 0) - (data?.completedCount || 0)}
        />
      ) : (
        <Box
          sx={{
            color: "#bec3c6",
            background: "#e1f2ff",
            p: 0.3,
            px: 1,
            width: "100%",
          }}
        >
          {data?.assignedTo}
        </Box>
      )}
    </Box>
  );
};

export default StatusView;
