import { Circle, Rectangle } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
// import { grey } from "@mui/material/colors";
// import { useState } from "react";
import { useLocation } from "react-router-dom";
import DataGride from "../../components/datagride/DataGride";
import { taskStatusReportColor } from "../../utils/CommonColors";
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
// import TaskStatusReport from "./TaskStatusReport/TaskStatusReport";
import { useReport } from "./customHook";
import TaskStatusReport from "./TaskStatusReport/TaskStatusReport";
import { useState } from "react";
import { allpageFontStyle } from "../../utils/FontStyle";
import moment from "moment";
import DateComponet from "../../components/commonComponents/DateComponet";

const ReportPage = () => {
  const location = useLocation();
  const {
    filters,
    selectedData,
    onLoad,
    onDownload,
    columns,
    apiloading,
    tableData,
    columnsRealData,
    commonValueHeaders,
  } = useReport(location.pathname);
  const emFun = () => { };
  function searchArray(array, searchValues) {
    const arr = array?.filter((item) => {
      return Object.keys(item).some((key) => {
        const res = item[key].toString().toLowerCase();
        return res?.includes(searchValues?.toString().toLowerCase());
      });
    });
    return arr;
  }

  const [filterData, setfilterData] = useState([]);
  const handelFilter = (e) => {
    if (e) {
      const res = searchArray(tableData, e.target.value);
      console.log(res, "res");
      setfilterData(res);
    } else {
      setfilterData([]);
    }
  };
  const colortboxLabel = { pt: 0.3, pl: 0.5 };
  return (
    <Box sx={formContainerStyle}>
      <Box sx={formContainerCardStyle}>
        <Box sx={{ px: 0, pt: 0, height:'100%' , display:'flex', flexDirection:'column'}}>
          <Grid container spacing={1} sx={{ display: "flex",pt:0 ,mt:-3, pl:2}}>
            {filters?.map((filterData) => (
              <Grid
                item
                lg={filterData?.type === "multiSelect" ? 2 : 2}
                md={3}
                sm={12}
                xs={6}
                sx={{ display: "flex", flexDirection: "column", mb: 0 }}
              >
                {filterData?.type === "date" ? (
                  <>
                    {/* <span
                      for={filterData.name}
                      style={{
                        fontSize: 12,
                        paddingLeft: 5,
                        fontFamily: "Lato",
                      }}
                    >
                      {filterData.name}
                    </span> */}
                    <Box sx={{ pt: 1.5, width: '100%' }}>

                      <DateComponet
                        value={filterData.valueGetter(selectedData) ? new Date(moment(filterData.valueGetter(selectedData) || "").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")) : null}
                        label={filterData.name}
                        handelDate={filterData?.onChange || emFun}
                        minDate={filterData?.minDate && new Date(filterData?.minDate(selectedData))}
                      />
                    </Box>
                    {/* <input
                      id={filterData.name}
                      type="date"
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        borderRadius: 5,
                        padding: 5,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#c4c4c4",
                        ...allpageFontStyle.dateField,
                      }}
                      onChange={filterData?.onChange || emFun}
                      value={filterData.valueGetter(selectedData) || ""}
                    /> */}
                  </>
                ) : filterData?.type === "multiSelect" ? (
                  <>
                    <span
                      for={filterData.name}
                      style={{
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "Lato",
                      }}
                    >
                      {filterData.name}
                    </span>
                    <Autocomplete
                      size="small"
                      disablePortal
                      multiple
                      id={filterData.name}
                      options={filterData?.options}
                      value={selectedData[filterData?.field]}
                      getOptionLabel={(option) =>
                        filterData?.labelGetter(option)
                      }
                      sx={{
                        padding: 0,
                        background: "white",
                      }}
                      onChange={filterData?.onChange}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            background: "white",
                            ".MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                              padding: 0,
                            },
                            "&::placeholder": {
                              color: "red",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: 100,
                              height: 100,
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </>
                ) : (
                  // <FormControl fullWidth>
                  //   <span for={filterData.name} style={{ fontSize: 10, paddingLeft: 5, fontWeight: 'bold' }}>{filterData.name}</span>
                  //   <Select
                  //     labelId="demo-simple-select-label"
                  //     id={filterData.name}
                  //     // multiple
                  //     sx={{
                  //       height: 27.33,
                  //       background: '#fff',
                  //       border: 'none',
                  //       outline: 'none'
                  //     }}
                  //     onChange={filterData?.onChange || emFun}
                  //     value={selectedData[filterData?.field]}
                  //   >
                  //     {
                  //       filterData?.options?.map(i => (
                  //         <MenuItem value={filterData?.value ? i[filterData?.value] : i}>{filterData?.label ? i[filterData?.label] : i}</MenuItem>
                  //       ))
                  //     }
                  //   </Select>
                  // </FormControl>
                  <>
                    <span
                      for={filterData.name}
                      style={{
                        ...allpageFontStyle.typography1,
                        paddingLeft: 5,
                      }}
                    >
                      {filterData.name}
                    </span>
                    <select
                      value={selectedData[filterData?.field]}
                      id={filterData.name}
                      onChange={filterData?.onChange || emFun}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        outline: "none",
                        borderRadius: 5,
                        padding: 5,
                      }}
                    >
                      {filterData.showSelect ? (
                        <option
                          disabled
                          selected
                          style={{ display: "none" }}
                          value={""}
                        >
                          {/* {filterData.showSelect} */}
                        </option>
                      ) : null}
                      {filterData?.options?.map((i) => (
                        <option
                          value={filterData?.value ? i[filterData?.value] : i}
                        >
                          {filterData?.label ? i[filterData?.label] : i}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </Grid>
            ))}
            {location.pathname === "/taskStatusReport" ?
              <Grid item
                lg={2}
                md={3}
                sm={12}
                xs={6}>
                <Box sx={{ pl: 5, pt: 2.5, }}>
                  {" "}
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "#ff8f6c",
                      ...allpageFontStyle.buttonFontStyle2,
                      borderRadius: 5,
                      ":hover": {
                        backgroundColor: "#ff8f6c",
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px", 
                      },
                      ":disabled": {
                        background: '#f5c2b2',
                      },
                      fontSize: '0.6rem',
                      p: 0.5,
                      px: 4,
                      minWidth: 100
                    }}
                    size="small"
                    variant="contained"
                    onClick={onLoad}
                  >
                    Load
                  </Button>
                </Box>
              </Grid> : null}
          </Grid>
          {location.pathname === "/taskStatusReport" ? null :
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  pl: 5,
                  mt: { xs: 3, sm: 3, md: 3, lg: 3 },
                }}
              >
                <Box sx={{ pr: 0.5 }}>
                  {" "}
                  <Button
                    sx={{
                      borderRadius: 5,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                      ...allpageFontStyle.buttonFontStyle2,
                    }}
                    size="small"
                    variant="contained"
                    onClick={onLoad}
                  >
                    Load
                  </Button>
                </Box>
                {((location.pathname === "/taskReport" &&
                  allowedPages().report.taskReport.download) ||
                  (location.pathname === "/project_report" &&
                    allowedPages().report.projectReport.download) ||
                  (location.pathname === "/job_report" &&
                    allowedPages().report.jobReport.download)) && (
                    <Box>
                      <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={onDownload}
                        sx={{
                          borderRadius: 5,
                          ...allpageFontStyle.buttonFontStyle2,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Box>
            </Box>}
          <Box flex={1} mt={1} sx={{   display:'flex',flexDirection:'column' }}>
            <Box
              sx={{
                display: "flex",
                pl:2
              }}
            >
              {commonValueHeaders?.length && tableData?.length
                ? commonValueHeaders.map((i, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: index !== 0 ? 3 : 0,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      fontSize={"0.7rem"}
                      variant="body2"
                    >
                      {i.name} :
                    </Typography>
                    <Typography
                      color={"#f9d668"}
                      fontSize={"0.7rem"}
                      variant="body2"
                    >
                      &nbsp;&nbsp;{tableData[0][i.field]}
                    </Typography>
                  </Box>
                ))
                : null}
                   <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml:3
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      fontSize={"0.7rem"}
                      variant="body2"
                    >
                      Total Task :
                    </Typography>
                    <Typography
                      color={"#f9d668"}
                      fontSize={"0.7rem"}
                      variant="body2"
                    >
                      &nbsp;&nbsp;{tableData?.length || ''}
                    </Typography>
                  </Box>
            </Box>
            {/* <CustomTable /> */}
            <Box sx={{ my: 1, flex:1 }}>
              {/* <Box sx={{ my: 2 }}>
                {location.pathname === "/taskStatusReport" ? (
                  <Box sx={{ display: "flex" }}>
                    <Box ml={0} sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        fontSize="small"
                        sx={{ color: taskStatusReportColor.Open }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="GrayText"
                        sx={colortboxLabel}
                        fontSize={11}
                      >
                        Open
                      </Typography>
                    </Box>
                    <Box ml={2} sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        fontSize="small"
                        sx={{ color: taskStatusReportColor.Assigned }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="GrayText"
                        sx={colortboxLabel}
                        fontSize={11}
                      >
                        Assigned
                      </Typography>
                    </Box>
                    <Box ml={2} sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        fontSize="small"
                        sx={{ color: taskStatusReportColor.Progress }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="GrayText"
                        sx={colortboxLabel}
                        fontSize={11}
                      >
                        In-Progress
                      </Typography>
                    </Box>
                    <Box ml={2} sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        fontSize="small"
                        sx={{ color: taskStatusReportColor.Revert }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="GrayText"
                        sx={colortboxLabel}
                        fontSize={11}
                      >
                        Revert Requested
                      </Typography>
                    </Box>
                    <Box ml={2} sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        fontSize="small"
                        sx={{ color: taskStatusReportColor.End }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="GrayText"
                        sx={colortboxLabel}
                        fontSize={11}
                      >
                        End
                      </Typography>
                    </Box>
                    <Box ml={4}>
                      <Box
                        sx={{
                          backgroundColor: taskStatusReportColor.total,
                          p: 1.2,
                          borderRadius: 100,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "white",
                            ...allpageFontStyle.typography1,
                            width: 65,
                            height: 65,
                            borderRadius: 100,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <b>Total Task</b>
                          <b>{tableData?.length || 0}</b>
                          <Box sx={{}}></Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box> */}
              {location.pathname === "/taskStatusReport" ? (
                <TaskStatusReport
                  columns={columns || []}
                  columnsRealData={columnsRealData || []}
                  loading={apiloading}
                  tableData={
                    filterData?.length > 0 ? filterData : tableData || []
                  }
                />
              ) : (
                <DataGride
                  customHeight={450}
                  columns={columns}
                  key={location.key}
                  tableData={tableData || []}
                  getRowId={(data) => data?.taskId || data?.SN}
                  hideDeletion
                  disableCheckbox
                  rowSelection={true}
                // getRowHeight={() => "auto"}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportPage;
