import FlagIcon from "@mui/icons-material/Flag";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  getProjectAndJobInfoAction,
  getTimelineAction,
} from "../../redux/action/action";

export default function TaskHistory({
  increment,
  handleStart,
  handlePause,
  handleReset,
  handleResume,
}) {
  const { taskDetails, selecteTaskProjectAndJobInfo, timeLineData } =
    useSelector((state) => state.master);
   
  const [keyValue, setkeyValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const id = useParams();
console.log(id, "iiddddd")
  useEffect(() => {
  }, [id]);

  const handleClickOpen = () => {
    setOpen(true);
    const key = Object.keys(taskDetails?.taskInfo);
    setkeyValue(key);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const payload = {
    taskId: taskDetails?.taskId,
    jobCode: taskDetails?.jobCode,
  };
  // React.useEffect(() => {
  //     dispatch(getTimeline(payload));

  //   });
  React.useEffect(() => {
    dispatch(getTimelineAction(payload));
    dispatch(getProjectAndJobInfoAction(taskDetails?.jobCode));
  }, []);

  const projectDetails = {
    fontFamily: "lato",
    fontWeight: "bold",
    fontSize: 14,
    pl: 1,
  };
  const taskcontent = {
    fontFamily: "lato",
    fontSize: 14,
    pl: 1,
  };

  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} : ${minutes} : ${Math.round(seconds)}`;
  }
  // useEffect(() => {
  //   if (timeLineData?.length) {
  //     let cut = [...timeLineData];
  //     cut.splice(-2, 2);
  //     setTimeData(cut);
  //   }
  // }, [timeLineData]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ width: "90%" }}>
        {/* {runingTask?.state === "Not Started" || runingTask?.state === "Resumed" ?
          <Box><TaskPageCommen increment={increment}
            handleStart={handleStart}
            handlePause={handlePause}
            handleReset={handleReset}
            handleResume={handleResume}
            timerData={timerData} /></Box>
          : null} */}
        {/* <Box sx={{ width: "70%", display: "flex" }}>
          <Box sx={{ p: 1 }}>
            <Button
              sx={{ backgroundColor: colors.green[300], ...cardbuttons }}
              variant="contained"
            >
              <PlayCircleOutlineIcon sx={{ mr: 0.3 }} />
              Start
            </Button>
          </Box>

          <Box sx={{ p: 1 }}>
            <Button
              sx={{ backgroundColor: colors.deepOrange[300], ...cardbuttons }}
              variant="contained"
            >
              <PauseCircleOutlineIcon sx={{ mr: 0.3 }} />
              Pause
            </Button>
          </Box>
          <Box sx={{ p: 1 }}>
            <Button
              sx={{ backgroundColor: colors.orange[200], ...cardbuttons }}
              variant="contained"
            >
              <PlayArrowIcon sx={{}} />
              Resume
            </Button>
          </Box>
          <Box sx={{ p: 1 }}>
            <Button
              sx={{ backgroundColor: colors.grey[700], ...cardbuttons }}
              variant="contained"
            >
              <StopIcon sx={{}} />
              End
            </Button>
          </Box>
        </Box> */}
      </Box>
      <Grid container spacing={1} sx={{ pl: 5, pt: 3, width: "100%" }}>
        <Grid xs={6} md={6} lg={6} sx={{ p: 2 }}>
          <Card
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                pt: 3,
                pl: 5,
                textDecoration: "underline",
              }}
              variant="h5"
            >
              Project Details
            </Typography>
            <Grid container spacing={2} sx={{ p: 5 }}>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>
                  Project Name:
                </Typography>
                <Typography sx={{ ...taskcontent }}>
                  {selecteTaskProjectAndJobInfo?.projectInfo?.projectName}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>
                  Project Code:
                </Typography>
                <Typography sx={{ ...taskcontent }}>
                  {selecteTaskProjectAndJobInfo?.projectInfo?.projectCode}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>
                  Project Scope:
                </Typography>

                <Paper
                  elevation={0}
                  sx={{
                    width: "80%",
                    ml: 1,
                    p: 1,
                    fontSize: 14,
                    height: 50,
                    backgroundColor: "whitesmoke",
                  }}
                >
                  {selecteTaskProjectAndJobInfo?.projectInfo?.scopeOfProject}
                </Paper>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Notes:</Typography>
                <Paper
                  elevation={0}
                  sx={{
                    width: "80%",
                    ml: 1,
                    p: 1,
                    fontSize: 14,
                    height: 50,
                    backgroundColor: "whitesmoke",
                  }}
                >
                  {selecteTaskProjectAndJobInfo?.projectInfo?.notes}
                </Paper>
              </Grid>
            </Grid>
            <Divider variant="middle" />

            <Typography
              sx={{
                display: "flex",
                pt: 3,
                pl: 5,
                textDecoration: "underline",
              }}
              variant="h5"
            >
              Job Details
            </Typography>
            <Grid container spacing={2} sx={{ p: 5 }}>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Job Code:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {selecteTaskProjectAndJobInfo?.jobInfo?.jobCode}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>
                  Received Date:
                </Typography>
                <Typography sx={{ ...taskcontent }}>
                  {moment(
                    selecteTaskProjectAndJobInfo?.jobInfo?.receivedDate
                  ).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Due date:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {moment(
                    selecteTaskProjectAndJobInfo?.jobInfo?.dueDate
                  ).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Typography
              sx={{
                display: "flex",
                pt: 3,
                pl: 5,
                textDecoration: "underline",
              }}
              variant="h5"
            >
              Task Details
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                pr: 5,
                mb: 1,
              }}
            >
              <InfoIcon
                sx={{ color: "#4594f1", cursor: "pointer" }}
                onClick={handleClickOpen}
              />
            </Box>
            <Grid container spacing={2} sx={{ p: 5 }}>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Task Name:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {taskDetails?.taskType}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Category:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {taskDetails?.category}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>State:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {taskDetails?.state}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Status:</Typography>
                <Typography sx={{ ...taskcontent }}>
                  {taskDetails?.status}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>
                  Worked Hours:
                </Typography>
                <Typography sx={{ ...taskcontent }}>
                  {taskDetails?.workHours} : {taskDetails?.workMinutes} :{" "}
                  {taskDetails?.workSeconds}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} sx={{ display: "flex" }}>
                <Typography sx={{ ...projectDetails }}>Remarks:</Typography>
                <Paper
                  elevation={0}
                  sx={{
                    width: "80%",
                    ml: 1,
                    p: 1,
                    fontSize: 14,
                    height: 50,
                    backgroundColor: "whitesmoke",
                  }}
                >
                  {taskDetails?.remarks}
                </Paper>
              </Grid>
            </Grid>
          </Card>
          {/* <Card sx={{ m: 1, backgroundColor: "#abb1b4", display: "flex" }}>
            <Box sx={{ width: "15%", ...projectDetails }}>Task Name</Box>
            <Box sx={{ width: "15%", ...projectDetails }}>Status</Box>
            <Box sx={{ width: "20%", ...projectDetails }}>Remarks</Box>
            <Box sx={{ width: "15%", ...projectDetails }}>Updated On</Box>
            <Box sx={{ width: "15%", ...projectDetails }}>Updated By</Box>
            <Box sx={{ width: "30%", ...projectDetails }}>Work Hours</Box>
          </Card>
          {data.map((datas) => (
            <Card
              sx={{
                m: 1,
                backgroundColor: "whitesmoke",
                display: "flex",
                borderRadius: 0,
              }}
            >
              <Box sx={{ width: "15%", ...taskcontent }}>{id.name}</Box>
              <Box sx={{ width: "15%", ...taskcontent }}>{datas.status}</Box>
              <Box sx={{ width: "20%", ...taskcontent }}>{datas.remarks}</Box>
              <Box sx={{ width: "15%", ...taskcontent }}>{datas.updatedOn}</Box>
              <Box sx={{ width: "15%", ...taskcontent }}>{datas.updatedBy}</Box>
              <Box sx={{ width: "30%", ...taskcontent }}>
                {" "}
                {toHoursAndMinutes(
                  toSeconds(
                    datas.workHours,
                    datas.workMinutes,
                    datas.workSeconds
                  )
                )}
              </Box>
            </Card>
          ))} */}
        </Grid>
        <Grid xs={6} md={6} lg={6} sx={{ width: "100%", p: 2 }}>
          <Card
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: 700,
              overflow: "auto",
            }}
          >
            <Typography
              sx={{ pt: 2, textDecoration: "underline" }}
              variant="h6"
            >
              Task Timeline
            </Typography>
            <Box sx={{ width: "90%", p: 5 }}>
              <VerticalTimeline layout="1-column-left" lineColor="black">
                {timeLineData &&
                  timeLineData.length > 0 &&
                  timeLineData.map((datas) => (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "#dee1e3",
                        color: "black",
                        borderRadius: 15,
                        height: 130,
                        width: 300,
                      }}
                      contentArrowStyle={{ borderRight: "7px solid  #dee1e3" }}
                      //   date={
                      //     <Box sx={{ fontSize: 14 }}>
                      //       {moment(datas.updatedOn).format(
                      //         "DD/MM/YYYY hh:mm a"
                      //       )}
                      //     </Box>
                      //   }
                      iconStyle={{
                        background:
                          datas.taskState === "Started"
                            ? "#147B30"
                            : datas.taskState === "Resumed"
                            ? "#147B30"
                            : datas.taskState === "Paused"
                            ? "#E0402B"
                            : datas.taskState === "Not Started"
                            ? "grey"
                            : datas.taskState === "Revert"
                            ? "#5d8cba"
                            : null,
                        color: "#fff",
                      }}
                      icon={
                        datas.taskState === "Started" ? (
                          <FlagIcon />
                        ) : datas.taskState === "Paused" ? (
                          <PauseIcon />
                        ) : datas.taskState === "Resumed" ? (
                          <PlayArrowIcon />
                        ) : datas.taskState === "Not Started" ? (
                          <FlagIcon />
                        ) : (
                          <HistoryIcon />
                        )
                      }
                    >
                      {/* <Box
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        className="vertical-timeline-element-title"
                      >
                         {" "}{datas.taskStatus}
                      </Box> */}
                      <Box
                        sx={{
                          fontSize: 17,
                          fontWeight: "bold",
                          color:
                            datas.taskState === "Started"
                              ? "#147B30"
                              : datas.taskState === "Resumed"
                              ? "#147B30"
                              : datas.taskState === "Paused"
                              ? "#E0402B"
                              : null,
                        }}
                        className="vertical-timeline-element-title"
                      >
                        {datas.taskState}
                      </Box>
                      <Box
                        sx={{
                          fontSize: 12,
                          fontWeight: "bold",
                          display: "flex",
                        }}
                        className="vertical-timeline-element-subtitle"
                      >
                        Worked hours -{" "}
                        <Box sx={{ fontSize: 14, pl: 2 }}>
                          {toHoursAndMinutes(
                            toSeconds(
                              datas.workHours,
                              datas.workMinutes,
                              datas.workSeconds
                            )
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ fontSize: 14, pt: 1 }}>
                        Remarks - {datas.remarks}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          fontSize: 11,
                          pt: 2,
                        }}
                      >
                        {moment(datas.updatedOn).format("DD/MM/YYYY hh:mm a")}
                      </Box>

                      {/* <Box sx={{ fontSize: 14 }}>
                          {moment(datas.updatedOn).format(
                            "DD/MM/YYYY hh:mm a"
                          )}
                        </Box> */}
                    </VerticalTimelineElement>
                  ))}

                <VerticalTimelineElement
                  iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                  icon={<StarIcon />}
                />
              </VerticalTimeline>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <h4>Task Info</h4>
          <Grid container spacing={3} sx={{ mt: 5 }}>
            {keyValue &&
              keyValue.length > 0 &&
              keyValue.map((keys, i) => (
                <Grid sx={{ display: "flex", pl: 2 }} xs={12} md={12} lg={6}>
                  <Box
                    sx={{
                      fontFamily: "lato",
                      fontWeight: "bold",
                      fontSize: "14px",
                      pl: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {keys} :
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "lato",
                      fontSize: "14px",
                      pl: 1,
                    }}
                  >
                    {taskDetails?.taskInfo?.[keys]}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              color: "white",
              // width: '10%',
              height: 40,
              p: 0,
              px: 2,
              fontWeight: "bold",
              backgroundColor: "#bf6373",
              ":hover": { backgroundColor: "#bf6373" },
              borderRadius: 5,
              textTransform: "capitalize",
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
