import { Close, Done } from "@mui/icons-material";
import { Box, Button, Card, Grid, TextField, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import EyeIcon from "../../components/commonComponents/eyeicon";
import DataGride from "../../components/datagride/DataGride";
import {
  getPojectReleatedListAction,
  getRevertTaskListAdminAction,
} from "../../redux/action/action";
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { secondsToHMS } from "../../utils/UIUtil";
import {
  CompletedTaskData,
  revertTaskPageProjectGETAPI,
} from "../../service/taskService";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { allpageFontStyle } from "../../utils/FontStyle";
import DateComponet from "../../components/commonComponents/DateComponet";

function CompletedTaskList() {
  const initialState = {
    projectInfo: "",
    jobInfo: "",
    startDate: null,
    endDate: null,
  };
  const [filterData, setFilterData] = useState(initialState);
  const [selectOptionData, setselectOptionData] = useState({
    projectList: [],
    jobList: [],
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [taskList, setTaskList] = React.useState([]);
  const { globapOrganization, userprofile, autoLoad } = useSelector(
    (state) => state.presist
  );

  const { projectListDashboard, projectReleatedList } = useSelector(
    (state) => state.master
  );
  const dispatch = useDispatch();
  const handelClearAll = () => {};
  const constructProjectData = (arr) => {
    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr?.jobList,
    };
    return res;
  };
  React.useEffect(() => {
    if (projectReleatedList?.jobList?.length > 0) {
      const taskListData = constructProjectData(projectReleatedList);
      setselectOptionData({
        ...selectOptionData,
        jobList: taskListData?.jobList,
      });
    }
  }, [projectReleatedList]);
  const handelSelect = async (e, type) => {
    if (type === "Project") {
      if (e) {
        setselectOptionData({
          ...selectOptionData,
          userList: [],
          status: [],
          jobList: [],
        });
        await dispatch(
          getPojectReleatedListAction({
            projectCode: e?.projectCode,
            orgId: globapOrganization?.orgId,
          })
        );
        setFilterData({
          ...filterData,
          projectInfo: e,
          jobInfo: "",
        });
      } else {
        setselectOptionData({
          ...selectOptionData,
          userList: [],
          status: [],
          jobList: [],
        });
        setFilterData({
          ...filterData,
          projectInfo: "",
          jobInfo: "",
        });
      }
    } else if (type === "Job") {
      if (e) {
        setFilterData({ ...filterData, jobInfo: e });
      } else {
        setFilterData({ ...filterData, jobInfo: "" });
      }
    }
  };

  const handelDate = (e, type) => {
    if (type === "start") {
      if (e) {
        setFilterData({
          ...filterData,
          startDate: moment(e).format("YYYY-MM-DD"),
          endDate: null,
        });
      } else {
        setFilterData({ ...filterData, startDate: "" });
      }
    } else {
      if (e) {
        setFilterData({
          ...filterData,
          endDate: moment(e).format("YYYY-MM-DD"),
        });
      } else {
        setFilterData({ ...filterData, endDate: "" });
      }
    }
  };
  const constructRevertTask = (arr) => {
    const res =
      arr?.userTaskInfo.length > 0 &&
      arr?.userTaskInfo.map((val) => ({
        ...val.taskInfo,
        jobInfo: val.jobInfo,
      }));
    return res;
  };
  const loadData = async (size, number) => {
    const paylaod = {
      // projectCode: filterData?.projectInfo?.projectCode,
      jobCode: filterData?.jobInfo?.jobCode,
      orgId: globapOrganization?.orgId,
      fromDate: filterData.startDate
        ? moment(filterData.startDate).format("YYYY-MM-DD")
        : null,
      toDate: filterData.endDate
        ? moment(filterData.endDate).format("YYYY-MM-DD")
        : null,
      pageSize: size ? size : itemPerPage,
      pageNumber: number ? number : pageNumber,
    };
    if (!paylaod.fromDate || !paylaod.toDate) {
      paylaod.fromDate = null;
      paylaod.toDate = null;
    }
    setTaskList([]);
    const res = await CompletedTaskData("post", paylaod, "/job/completeTasks");
    console.log(res, "res");
    if (res) {
      const resval = res?.data?.data;
      const construct = constructRevertTask(resval);
      setTotalPage(resval.pageInfo?.total_items);
      setTaskList(construct);
    }
  };
  // Page Auto Reload By the Task Status changes
  const changePageSize = (newPageSize) => {
    console.log(newPageSize, "aaaaaaaa");
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    loadData(newPageSize.pageSize, newPageSize.page + 1);
  };
  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      if (
        filterData?.projectInfo?.projectCode &&
        filterData?.jobInfo?.jobCode
      ) {
        loadData();
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: { isAutoLoad: false },
          })
        );
      }
    }
  }, [autoLoad]);

  const construtProjectdata = (arr) => {
    const res = arr.map((val) => val.projectInfo);
    return res;
  };
  const RevertProjectListAPIFUC = async () => {
    const res = await revertTaskPageProjectGETAPI(
      "post",
      `/report/projectInfo/${
        userprofile?.userAccess?.superUser === true
          ? globapOrganization?.orgId
          : userprofile?.userInfo?.userInfo?.orgId || ""
      }/`
    );
    if (res) {
      console.log(res, "res123");
      const project = res.data.project_details;
      setselectOptionData({ ...selectOptionData, projectList: project });
    } else {
      setselectOptionData({ ...selectOptionData, projectList: [] });
    }
  };
  useEffect(() => {
    RevertProjectListAPIFUC();
    // dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    handelClearAll();
  }, [globapOrganization]);
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      setselectOptionData({ ...selectOptionData, projectList: project });
    } else {
      setselectOptionData({ ...selectOptionData, projectList: [] });
    }
  }, [projectListDashboard]);
  const col = [
    {
      field: "taskId",
      headerName: "Task Id",
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taskType",
      headerName: "Type",
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitType",
      headerName: " Unit Type",
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "measureCount",
      headerName: "Est Hrs",
      valueGetter: (params) => secondsToHMS(+params?.row?.productionHours),
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "workHours",
      headerName: "Worked Hrs",
      valueGetter: (params) =>
        `${params?.row?.workHours} : ${params?.row?.workMinutes} : ${params?.row?.workSeconds}`,
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "updatedOn",
      headerName: "Completed Date",
      // align: "left",
      headerAlign: "left",
      valueGetter: (data) =>
        data?.row?.updatedOn
          ? moment(data?.row?.updatedOn, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format(
              "DD-MM-YYYY"
            )
          : "NA",
      width: 200,
    },
    {
      field: "state",
      headerName: "Status",
      width: 220,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      align: "left",
      headerAlign: "left",
      // renderHeader: () => <strong>{"Action"}</strong>,
      getActions: (params) => [
        <>
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Box
                title="View"
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <EyeIcon
                  taskId={params.row.taskId}
                  jobcode={params.row.jobCode}
                  clickOpen={true}
                  clickclose={false}
                />
              </Box>
            }
            label="View"
            // onClick={() => {
            //   handelEditStart(params)
            // }}
            color="primary"
          />
        </>,
      ],
    },
  ];
  return (
    <Box sx={{ ...formContainerStyle }}>
      <Card sx={formContainerCardStyle}>
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: 600,
            overflow: "auto",
          }}
        >
          <Box sx={{ px: 1, py: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.projectList}
                    labelName={(option) => option.projectCode}
                    // disabled={!treeList.length > 0 }
                    value={filterData?.projectInfo}
                    sx={{ pr: 1 }}
                    label={"Project *"}
                    handelChange={(e) => handelSelect(e, "Project")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.jobList}
                    labelName={(option) => option.jobCode}
                    defaultValue={filterData?.jobInfo}
                    value={filterData?.jobInfo}
                    sx={{ pr: 1 }}
                    label={"Job *"}
                    handelChange={(e) => handelSelect(e, "Job")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4} lg={2.5} sx={{}}>
                <Box sx={{ pt: 0 }}>
                  <DateComponet
                    value={
                      filterData?.startDate
                        ? new Date(
                            moment(filterData?.startDate).format(
                              "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                            )
                          )
                        : null
                    }
                    label={"From"}
                    setValue={setFilterData}
                    handelDate={(e) => {
                      handelDate(e, "start");
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={2.5}>
                <DateComponet
                  value={
                    filterData?.endDate
                      ? new Date(
                          moment(filterData?.endDate).format(
                            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                          )
                        )
                      : null
                  }
                  label={"To"}
                  setValue={setFilterData}
                  minDate={
                    new Date(moment(filterData.startDate).format("YYYY-MM-DD"))
                  }
                  handelDate={(e) => {
                    handelDate(e, "end");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={1}>
                <Box sx={{ pt: 1 }}>
                  <Button
                    sx={{
                      ml: 2,
                      mt: 0.2,
                      borderRadius: 5,
                      ...allpageFontStyle.buttonFontStyle2,
                    }}
                    onClick={() => loadData()}
                    size="small"
                    disabled={!filterData.projectInfo || !filterData.jobInfo}
                    variant="contained"
                  >
                    Load
                  </Button>
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={4} lg={1}>
                                    <Button
                                        sx={{ ml: 2, borderRadius: 5 }}
                                        onClick={() => clearData()}
                                        variant="contained"
                                    >
                                        Refresh
                                    </Button>
                                </Grid> */}
            </Grid>
          </Box>

          <Box sx={{}}>
            <DataGride
              columns={col}
              // edit={true}
              hideDeletion={true}
              tableData={taskList || []}
              disableCheckbox={true}
              pageSize={itemPerPage}
              page={pageNumber}
              pageName={"project"}
              onPageSizeChange={changePageSize}
              rowCount={totalPage}
              paginationMode={true}
              customHeight={450}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default CompletedTaskList;
