import store from "../redux/store";
import axios from "./axiosService";

const baseURL = process.env.REACT_APP_BASE_URL;
export const generalTaskService = async (method, data, active) => {
    const { globapOrganization } = store.getState().presist
    try {
        return await axios({
            method,
            url: `${baseURL}/generalTask/info/${globapOrganization?.orgId}/${active ? active : ""}`,
            data: {
                ...data,
                orgId: globapOrganization?.orgId
            }
        })
    } catch (error) {
    }
};
export const generalTaskServiceEdit = async (method, data, active) => {
    const { globapOrganization } = store.getState().presist
    try {
        return await axios({
            method,
            url: `${baseURL}/generalTask/info/${globapOrganization?.orgId}/${active ? active : ""}`,
            data: data
        })
    } catch (error) {
    }
};

export const getAllProj_Dep_Team_User_Service = async (orgId) => {
    try {
        return await axios({
            method: 'get',
            url: `${baseURL}${`/generalTask/pro_dep_team_user/${orgId}/`}`,
        })
    } catch (error) {
    }
};

export const getGeneralTaskMappedDataService = async (taskId) => {
    try {
        return await axios({
            method: 'post',
            url: `${baseURL}${'/generalTask/mapped_list/'}`,
            data: {
                generalTaskId: taskId
            }
        })
    } catch (error) {
    }
};

export const generalTaskMapService = async (data) => {
    try {
        const { globapOrganization } = store.getState().presist
        return await axios({
            method: 'post',
            url: `${baseURL}${'/generalTask/map_general_task/'}`,
            data
        })
    } catch (error) {
    }
};
export const getUsersMappedGeneralTaskService = async (data) => {
    try {
        return await axios({
            method: 'get',
            url: `${baseURL}${'/generalTask/user_mapped_list/'}`,
        })
    } catch (error) {
    }
};
let cancelToken1;
export const getUsersRunningGeneralTaskService = async () => {
    if (typeof cancelToken1 != typeof undefined) {
        cancelToken1.cancel('Operation canceled due to new request.')
    }
    cancelToken1 = axios.CancelToken.source();
    try {
        return await axios({
            method: 'get',
            url: `${baseURL}${'/generalTask/running_general_task/'}`,
            cancelToken: cancelToken1.token,
        })
    } catch (error) {
    }
};
let cancelToken;
export const updateGeneralTaskService = async (data) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source();
    try {
        return await axios({
            method: 'post',
            url: `${baseURL}${'/generalTask/update_general_task/'}`,
            data,
            cancelToken: cancelToken.token,
        })
    } catch (error) {
    }
};

