import axios from "./axiosService";
import { fileAttachmentDownload } from "./fileService";

const baseURL = process.env.REACT_APP_BASE_URL;
export const getTaskReport = async (data) => {
  try {
    return await axios({
      method: 'post',
      url: `${baseURL}/report/task_report/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
let cancelToken
export const getTaskStatusReport = async (data, path) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source();
  if (path !== "/taskStatusReport") cancelToken.cancel()
  try {
    return await axios({
      method: 'post',
      url: `${baseURL}/report/task_report/`,
      cancelToken: cancelToken.token,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getDisplayColumns = async (data) => {
  try {
    return await axios({
      method: 'post',
      url: `${baseURL}/job/display_columns`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response?.data?.data
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const reportDownload = async (data, path, method, fileName) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data,
      responseType: 'arraybuffer'
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      fileAttachmentDownload(response.data, 'xlsx', fileName)
      return response
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};