import axios from "./axiosService";
const baseURL = process.env.REACT_APP_BASE_URL;

export const getDashboardGraphdata = async (id, data) => {
    try {
        return await axios({
            method: "post",
            url: `${baseURL}/report/dashboard_chart/${id}/`,
            data,
        }).then((response) => {

            return response?.data[0]?.data || []
        });
    } catch (error) {
    }
};

export const getUserDashboardGraphdata = async (id, data) => {
    try {
        return await axios({
            method: "post",
            url: `${baseURL}/report/user_dashboard_chart/${id}/`,
            data,
        }).then((response) => {

            return response?.data[0]?.data || []
        });
    } catch (error) {
    }
};