import axios from "./axiosService";
import axios2 from "axios";
import { saveAs } from "file-saver";
const baseURL = process.env.REACT_APP_BASE_URL;
const baseChatURL = process.env.REACT_APP_CHAT_URL;

export const createAnnouncement = async (method, id, data) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/announcement/info/${id}/`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

export const getAnnouncementList = async (
  pageSize,
  pageNumber,
  fromDate,
  toDate,
  projectCode,
  jobCode
) => {
  return await axios({
    method: "get",
    url: `${baseURL}/fcm/notificationHistory?pageSize=${pageSize}&pageNumber=${pageNumber}&fromDate=${fromDate}&toDate=${toDate}&projectCode=${projectCode}&jobCode=${jobCode}&notificationType=ANNOUNCEMENT`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

export const createQuery = async (method, id, data) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/query/info/${id}/`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

let cancelToken;
export const getOverallAnnouncements = async (id, pageSize, pageNum, data) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/announcement/list_announcements/${id}/?pageSize=${pageSize}&pageNumber=${pageNum}`,
      data,
      cancelToken: cancelToken.token,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};

let cancelToken1;
export const getSelfQueries = async (id, pageSize, pageNum, data) => {
  if (typeof cancelToken1 != typeof undefined) {
    cancelToken1.cancel("Operation canceled due to new request.");
  }
  cancelToken1 = axios.CancelToken.source();
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/query/view_query/${id}?pageSize=${pageSize}&pageNumber=${pageNum}`,
      data,
      cancelToken: cancelToken1.token,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
let cancelToken2;
export const getOverallQueries = async (id, pageSize, pageNum, data) => {
  if (typeof cancelToken2 != typeof undefined) {
    cancelToken2.cancel("Operation canceled due to new request.");
  }
  cancelToken2 = axios.CancelToken.source();
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/query/list_queries/${id}?pageSize=${pageSize}&pageNumber=${pageNum}`,
      data,
      cancelToken: cancelToken2.token,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
let cancelToken3;

export const getRequest = async (id, pageSize, pageNum, data, setLoading) => {
  if (typeof cancelToken3 != typeof undefined) {
    cancelToken3.cancel("Operation canceled due to new request.");
  }
  cancelToken3 = axios.CancelToken.source();
  try {
    setLoading && setLoading(true);
    return await axios({
      method: "post",
      url: `${baseURL}/query/request_queries/${id}?pageSize=${pageSize}&pageNumber=${pageNum}`,
      data,
      cancelToken: cancelToken3.token,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      setLoading && setLoading(false);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
    setLoading && setLoading(false);
  }
};

export const getForumData = async (id, code) => {
  try {
    return await axios({
      method: "get",

      url: `${baseURL}/query/reply/${id}/?queryCode=${code}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};

export const postReply = async (method, id, data) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/query/reply/${id}/`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

export const sendAttachment = async (fileValue) => {
  try {
    console.log(fileValue, "yhghfhfghf");
    const formData = new FormData();
    fileValue?.forEach((file) => {
      formData.append("file", file);
      console.log(file, "filjkh");
    });
    console.log(formData, "formdata");
    return await axios2({
      method: "post",
      url: `${baseChatURL}/file/upload`,
      data: formData,
    }).then((response) => {
      return response;
    });
  } catch (error) {
    console.log(error, "eooo");
  }
};

export const downloadFileService = async (file, view) => {
  try {
    return await axios({
      method: "get",
      url: `${baseChatURL}/file/${file?.fileCode}`,
      responseType: "arraybuffer",
    }).then((res) => {
      if (res.data) {
        console.log(file, "sdhgfcjhdvchj");
        const blob = new Blob([res.data], { type: file?.fileType });
        if (view) {
          const objectURL = URL.createObjectURL(blob);
          window.open(objectURL);
        } else saveAs(blob, file.originalFileName);
        // const objectURL = URL.createObjectURL(blob);
        return true;
      }
    });
  } catch (error) {
    return false;
  }
};

export const deleteFile = async (fileId) => {
  try {
    console.log(fileId, "1");
    return await axios({
      method: "DELETE",
      url: `${baseChatURL}/file/${fileId}`,
    }).then((response) => {
      console.log(fileId, "2");
      return response;
    });
  } catch (error) {
    console.log(fileId, "3");
  }
};
export const deleteAnswerType = async (orgId, id, queryCode) => {
  try {
    return await axios({
      method: "DELETE",
      url: `${baseURL}/query/reply/${orgId}/?id=${id}&queryCode=${queryCode}`,
    }).then((response) => {
      return response;
    });
  } catch (error) {
    console.log(id, "3");
  }
};
