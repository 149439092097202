import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import FlagIcon from "@mui/icons-material/Flag";
import HistoryIcon from "@mui/icons-material/History";
import PauseIcon from "@mui/icons-material/Pause";
import PersonIcon from "@mui/icons-material/Person";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StarIcon from "@mui/icons-material/Star";
import UndoIcon from "@mui/icons-material/Undo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import point from "../../pics/check.png";
import { getTimelineAction } from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { secondsToHMS } from "../../utils/UIUtil";
import { allpageFontStyle } from "../../utils/FontStyle";
import { TaskAssignPageImages } from "../../utils/Imageimport";
export default function EyeIcon({
  clickOpen,
  clickclose,
  jobcode,
  taskId,
  icon,
  sx
}) {
  const { timeLineData } = useSelector((state) => state.master);
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = useState({
    taskId: "",
    jobCode: "",
  });
  const dispatch = useDispatch();
  const handleClickOpen = async () => {
    setOpen(clickOpen);
    setPayload({
      taskId: taskId,
      jobCode: jobcode,
    });
    await dispatch(
      getTimelineAction({
        taskId: taskId,
        jobCode: jobcode,
      })
    );
  };

  const handleClose = () => {
    setOpen(clickclose);
    dispatch(
      updateMasterStates({
        name: "timeLineData",
        value: {},
      })
    );
  };
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} : ${minutes} : ${Math.round(seconds)}`;
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const header = {
    width: "30%",
    display: "flex",
    alignItems: "center",
    color: "grey",
    ...allpageFontStyle.typography1,
  };
  const content = { width: "70%", ...allpageFontStyle.typography1 };
  // const split = timeLineData?.jobInfo?.[0]?.displayColumns.split(',')
  return (
    <Box sx={{ p: 0, zIndex: 10000 }}>
      <Button
        sx={{ p: 0, display: "flex", justifyContent: "center", width: 10 }}
        onClick={handleClickOpen}
      >
        {icon ? (
          <Box sx={{ display: "flex", alignItems: "center", m: 0 }}>
            {" "}
            {icon}
          </Box>
        ) : (
          TaskAssignPageImages.eyeIcon
          // <VisibilityIcon fontSize="small" sx={{ ...sx, color: "#358c4e" }} />
        )}
      </Button>
      <Dialog
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "30px",
            border: "3px solid #358c4e",
            zIndex: 1000000,
          },
        }}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f8f9fa",
            ...allpageFontStyle.title,
          }}
        >
          {/* <img alt="" src={preview} style={{ height: 40, width: 40, marginRight: 10 }} />  */}
          {timeLineData?.TaskInfo?.[0]?.taskId}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#f8f9fa", overflow: "hidden" }}>
          <Grid container spacing={2} sx={{ pt: 1, height: "100%" }}>
            <Grid
              xs={6}
              md={6}
              lg={7}
              sx={{ p: 1, backgroundColor: "#f8f9fa", overflowY: "auto" }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  pb: 1,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Box sx={{ ...allpageFontStyle.title, pb: 1 }}>
                  Project Details
                </Box>

                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Project Name :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.projectInfo?.[0]?.projectName}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Project Code :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.projectInfo?.[0]?.projectCode}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Status :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.projectInfo?.[0]?.status}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Scope of Project :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.projectInfo?.[0]?.scopeOfProject}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Notes :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.projectInfo?.[0]?.notes}
                  </Box>
                </Box>
              </Box>

              {/* <Divider variant="middle"/> */}
              <Box
                sx={{
                  backgroundColor: "white",
                  mb: 1,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ ...allpageFontStyle.title, pb: 1 }}>Job Details</Box>

                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Job Code :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.jobInfo?.[0]?.jobCode}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    received Date :
                  </Box>
                  <Box sx={{ ...content }}>
                    {moment(timeLineData?.jobInfo?.[0]?.receivedDate).format(
                      "DD/MM/YYYY"
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />{" "}
                    Due Date :
                  </Box>
                  <Box sx={{ ...content }}>
                    {moment(timeLineData?.jobInfo?.[0]?.dueDate).format(
                      "DD/MM/YYYY"
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                  <Box sx={{ ...header }}>
                    <img
                      alt=""
                      src={point}
                      style={{ height: 15, width: 15, marginRight: 10 }}
                    />
                    Status :
                  </Box>
                  <Box sx={{ ...content }}>
                    {timeLineData?.jobInfo?.[0]?.status}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "white",
                  mb: 1,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ ...allpageFontStyle.title, pb: 1 }}>
                  Task Details
                </Box>
                <Box>
                  <Box sx={{ display: "flex", width: "100%", pb: 1 }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      Task Type :
                    </Box>
                    <Box sx={{ ...content }}>
                      {timeLineData?.TaskInfo?.[0]?.taskType}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "100%", pb: 1 }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      {timeLineData?.TaskInfo?.[0]?.unitType} :
                    </Box>
                    <Box sx={{ ...content }}>
                      {timeLineData?.TaskInfo?.[0]?.measureCount}
                    </Box>
                  </Box>
                  {Object.keys(timeLineData?.TaskInfo?.[0]?.taskInfo || {}).map(
                    (keyname) => (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        {timeLineData?.jobInfo?.[0]?.displayColumns
                          .split(",")
                          .map((array) =>
                            array.match(keyname) ? (
                              <>
                                <Box sx={{ ...header, pb: 1 }}>
                                  <img
                                    alt=""
                                    src={point}
                                    style={{
                                      height: 15,
                                      width: 15,
                                      marginRight: 10,
                                    }}
                                  />
                                  {keyname}
                                </Box>
                                <Box
                                  sx={{ ...content, wordBreak: "break-all" }}
                                >
                                  {
                                    timeLineData?.TaskInfo?.[0]?.taskInfo[
                                    keyname
                                    ]
                                  }
                                </Box>
                              </>
                            ) : null
                          )}
                      </Box>
                    )
                  )}
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      Category :
                    </Box>
                    <Box sx={{ ...content }}>
                      {timeLineData?.TaskInfo?.[0]?.category}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      Status :
                    </Box>
                    <Box sx={{ ...content }}>
                      {timeLineData?.TaskInfo?.[0]?.status}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      Estimated Hours :
                    </Box>
                    <Box sx={{ ...content }}>
                      {" "}
                      {secondsToHMS(
                        +timeLineData?.TaskInfo?.[0]?.productionHours
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "100%", pt: 1 }}>
                    <Box sx={{ ...header }}>
                      <img
                        alt=""
                        src={point}
                        style={{ height: 15, width: 15, marginRight: 10 }}
                      />
                      Worked Hours :
                    </Box>
                    <Box sx={{ ...content, display: "flex" }}>
                      {timeLineData?.TaskInfo?.[0]?.workHours} :{" "}
                      {timeLineData?.TaskInfo?.[0]?.workMinutes} :{" "}
                      {timeLineData?.TaskInfo?.[0]?.workSeconds}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={6}
              md={6}
              lg={5}
              sx={{
                p: 1,
                background: "#f8f9fa",
                pb: 5,
                overflow: "auto",
                height: 620,
                maxHeight: "100%",
              }}
            >
              <VerticalTimeline
                layout="1-column-left"
                lineColor="black"
                animate={true}
              >
                {timeLineData?.taskHistoryInfo &&
                  timeLineData?.taskHistoryInfo?.length > 0 &&
                  timeLineData?.taskHistoryInfo?.map((datas) => (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        boxShadow: "none",
                        background: "#dee1e3",
                        color: "black",
                        borderRadius: 15,
                        // height: 120,
                        minHeight: 120,
                        width: 250,
                        ...allpageFontStyle.title,
                      }}
                      contentArrowStyle={{ borderRight: "7px solid  #dee1e3" }}
                      //   date={
                      //     <Box sx={{ fontSize: 14 }}>
                      //       {moment(datas?.jobTaskHistoryInfo?.updatedOn).format(
                      //         "DD/MM/YYYY hh:mm a"
                      //       )}
                      //     </Box>
                      //   }
                      iconStyle={{
                        ...allpageFontStyle.title,
                        background:
                          datas?.jobTaskHistoryInfo?.taskState === "Started"
                            ? "#147B30"
                            : datas?.jobTaskHistoryInfo?.taskState === "Resumed"
                              ? "#147B30"
                              : datas?.jobTaskHistoryInfo?.taskState === "Paused"
                                ? "#E0402B"
                                : datas?.jobTaskHistoryInfo?.taskState ===
                                  "Not Started"
                                  ? "grey"
                                  : datas?.jobTaskHistoryInfo?.taskState === "Revert"
                                    ? "#5d8cba"
                                    : datas?.jobTaskHistoryInfo?.taskState ===
                                      "Revert Rejected"
                                      ? "red"
                                      : "#5d8cba",
                        color: "#fff",
                      }}
                      icon={
                        datas?.jobTaskHistoryInfo?.taskState === "Started" ? (
                          <FlagIcon />
                        ) : datas?.jobTaskHistoryInfo?.taskState ===
                          "Paused" ? (
                          <PauseIcon />
                        ) : datas?.jobTaskHistoryInfo?.taskState ===
                          "Resumed" ? (
                          <PlayArrowIcon />
                        ) : datas?.jobTaskHistoryInfo?.taskState ===
                          "Not Started" ? (
                          <FlagIcon />
                        ) : datas?.jobTaskHistoryInfo?.taskState ===
                          "Revert Rejected" ? (
                          <PriorityHighIcon />
                        ) : datas?.jobTaskHistoryInfo?.taskState ===
                          "Revert" ? (
                          <UndoIcon />
                        ) : (
                          <HistoryIcon />
                        )
                      }
                    >
                      {/* <Box
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        className="vertical-timeline-element-title"
                      >
                         {" "}{datas?.jobTaskHistoryInfo?.taskStatus}
                      </Box> */}
                      <Box
                        sx={{
                          ...allpageFontStyle.typography1,
                          color:
                            datas?.jobTaskHistoryInfo?.taskState === "Started"
                              ? "#147B30"
                              : datas?.jobTaskHistoryInfo?.taskState ===
                                "Resumed"
                                ? "#147B30"
                                : datas?.jobTaskHistoryInfo?.taskState ===
                                  "Paused"
                                  ? "#E0402B"
                                  : datas?.jobTaskHistoryInfo?.taskState ===
                                    "Not Started"
                                    ? "black"
                                    : datas?.jobTaskHistoryInfo?.taskState ===
                                      "Revert Rejected"
                                      ? "red"
                                      : null,
                        }}
                        className="vertical-timeline-element-title"
                      >
                        {datas?.jobTaskHistoryInfo?.taskState === "Revert"
                          ? datas?.jobTaskHistoryInfo?.taskStatus
                          : datas?.jobTaskHistoryInfo?.taskState}
                      </Box>
                      <Box
                        sx={{
                          ...allpageFontStyle.typography1,
                          // fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="vertical-timeline-element-subtitle"
                      >
                        <AccessTimeIcon fontSize="small" />
                        <Box sx={{ ...allpageFontStyle.typography1, pl: 2 }}>
                          {toHoursAndMinutes(
                            toSeconds(
                              datas?.jobTaskHistoryInfo?.workHours,
                              datas?.jobTaskHistoryInfo?.workMinutes,
                              datas?.jobTaskHistoryInfo?.workSeconds
                            )
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...allpageFontStyle.typography1,
                          pt: 0.3,
                          display: "flex",
                          alignItems: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        <CreateIcon fontSize="small" sx={{ mr: 2 }} />{" "}
                        {datas?.jobTaskHistoryInfo?.remarks}
                      </Box>
                      <Box
                        sx={{
                          ...allpageFontStyle.typography1,
                          pt: 0.3,
                          display: "flex",
                          alignItems: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        <PersonIcon fontSize="small" sx={{ mr: 2 }} />
                        by {datas?.createUserInfo?.firstname}{" "}
                        {datas?.createUserInfo?.lastname}
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          display: "flex",
                          justifyContent: "end",
                          fontSize: 9,
                          pt: 2,
                        }}
                      >
                        {moment(datas?.jobTaskHistoryInfo?.updatedOn).format(
                          "DD/MM/YYYY hh:mm a"
                        )}
                      </Box>

                      {/* <Box sx={{ fontSize: 14 }}>
                          {moment(datas?.jobTaskHistoryInfo?.updatedOn).format(
                            "DD/MM/YYYY hh:mm a"
                          )}
                        </Box> */}
                    </VerticalTimelineElement>
                  ))}

                <VerticalTimelineElement
                  iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                  icon={<StarIcon />}
                />
              </VerticalTimeline>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f8f9fa", position: "absolute", right: 0 }}
        >
          <Button onClick={handleClose}>
            <CloseIcon sx={{ color: "red", borderRadius: 5 }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
