import { Box, Divider, Grid } from "@mui/material";
import "./styleSheet.css";
import { Download, FiberManualRecord } from "@mui/icons-material";
import { downloadFileService } from "../service/helpdeskService";
import { fileDownloadStyle, fileNameStyle } from "./Style";

export default function PopUpData({ data, table, sla, attachment }) {
  console.log(sla, "table");
  const t = Object.keys(data);
  const time = (times) => {
    const t = times.split(":");
    return `${t?.[0]}h : ${t?.[1]}m : ${t?.[2]}s`;
  };
  const fontSize12 = { fontFamily: "Lato", fontSize: 12 };
  const fontSize16 = { fontFamily: "Lato", fontSize: 16 };
  const groupedDetails = sla?.slaDetails?.reduce((acc, details) => {
    const { slaType } = details;
    if (!acc[slaType]) {
      acc[slaType] = [];
    }
    acc[slaType].push(details);
    return acc;
  }, {});
  return (
    <>
      <Grid container spacing={2} sx={{ display: "flex", p: 3, px: 3 }}>
        {t.map((values) => (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              borderBottom: "0.3px solid #f2f2f2",
              pb: 0.3,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} md={4} lg={4} sx={{}}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingLeft: 2,
                    ...fontSize12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiberManualRecord
                    sx={{ mr: 1, color: "#377c34", height: 10, width: 10 }}
                  />
                  {values}
                </span>
              </Grid>
              <Grid
                item
                xs={8}
                md={8}
                lg={8}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  ...fontSize12,
                }}
              >
                <b>: </b>
                <span
                  style={{
                    flexWrap: "wrap",
                    color: "black",
                    paddingLeft: 10,
                    wordBreak: "break-word",
                    // textTransform: "capitalize",
                    paddingTop: 0,

                    margin: 0,
                  }}
                >
                  {data[values] || "NA"}{" "}
                </span>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {attachment?.fileCode ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              borderBottom: "0.3px solid #f2f2f2",
              pb: 0.3,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} md={4} lg={4} sx={{}}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingLeft: 2,
                    ...fontSize12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiberManualRecord
                    sx={{ mr: 1, color: "#377c34", height: 10, width: 10 }}
                  />
                  {"Attachment"}
                </span>
              </Grid>
              <Grid
                item
                xs={8}
                md={8}
                lg={8}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  ...fontSize12,
                }}
              >
                <b>: </b>
                <span
                  style={{
                    flexWrap: "wrap",
                    color: "black",
                    paddingLeft: 10,
                    wordBreak: "break-word",
                    // textTransform: "capitalize",
                    paddingTop: 0,

                    margin: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      // pt: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 11,
                        // p: 0.5,
                        display: "flex",
                        gap: "1rem",
                        borderRadius: "0.6rem",
                        // border: "0.5px solid black",
                        // pt: 0.9,
                      }}
                    >
                      <Box
                        onClick={() => downloadFileService(attachment, true)}
                        sx={{
                          ...fileNameStyle,
                        }}
                      >
                        {attachment?.originalFileName || "file"}
                      </Box>

                      <Download
                        sx={{
                          ...fileDownloadStyle,
                        }}
                        color="info"
                        onClick={() => {
                          downloadFileService(attachment, false);
                        }}
                      />
                    </Box>
                  </Box>
                </span>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {table ? (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 3, px: 3 }}>
          <Box
            sx={{
              ...fontSize16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              color: "black",
              width: "100%",
              py: 1,
            }}
          >
            Task Details
          </Box>
          <table className="popupTable" style={{}}>
            <tr className="tr">
              <th className="th">S.No</th>
              <th className="th">Category</th>
              <th className="th">Task Level</th>
              <th className="th">Unit Type</th>
              <th className="th">Unit of Measure</th>
              <th className="th">Production Time</th>
            </tr>
            {table.map((values, index) => (
              <tr className="tr">
                <td className="td">{index + 1}</td>
                <td className="td">{values?.category}</td>
                <td className="td">{values?.taskName}</td>
                <td className="td">{values?.unitType}</td>
                <td className="td">{values?.unitOfMeasure}</td>
                <td className="td">{time(values?.uomProdTime)}</td>
              </tr>
            ))}
          </table>
        </Box>
      ) : sla ? (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
          <Box
            sx={{
              ...fontSize16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              color: "black",
              width: "100%",
              py: 1,
            }}
          >
            SLA Details
          </Box>

          <table className="popupTable">
            <tr className="tr">
              <th className="th">SLA Type</th>
              <th className="th">Level</th>
              <th className="th">Days</th>
              <th className="th">Hours</th>
              <th className="th">Minutes</th>
            </tr>
            {groupedDetails &&
              Object.entries(groupedDetails).map(([slaType, detailsList]) =>
                detailsList.map((details, index) => (
                  <tr key={`${slaType}-${index}`}>
                    {index === 0 && (
                      <td className="td" rowSpan={detailsList.length}>
                        {slaType}
                      </td>
                    )}
                    <td className="td">{details.slaLevel}</td>
                    <td className="td">{details.slaEstDays} d</td>
                    <td className="td">{details.slaEstHours} h</td>
                    <td className="td">{details.slaEstMinutes} m</td>
                  </tr>
                ))
              )}
          </table>
          <Box
            sx={{
              ...fontSize16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              color: "black",
              width: "100%",
              py: 1,
            }}
          >
            Notified Users
          </Box>
          <Grid container spacing={1}>
            {sla?.userInfo?.map((name, i) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{ display: "flex", alignItems: "center", m: 2 }}
              >
                <Box
                  style={{
                    flexWrap: "wrap",
                    color: "black",
                    paddingLeft: 10,
                    ...fontSize12,
                  }}
                >
                  {i + 1}.{" "}
                  {/* <span style={{ fontWeight: 'bold', color: 'black', paddingLeft: 2, ...fontSize12, display: 'flex', alignItems: 'center' }}><FiberManualRecord fontSize="inherit" sx={{ mr: 0.3, color: '#377c34' }} />Notified Users:</span> */}
                </Box>
                <Box
                  style={{
                    flexWrap: "wrap",
                    color: "black",
                    paddingLeft: 10,
                    ...fontSize12,
                  }}
                >
                  {name?.firstname + " " + name?.lastname}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </>
  );
}
