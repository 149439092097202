import { Box, Button, Card, Popper, TextField, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../pics/workboxLogo.png";
import { passwordChecking, passwordCheckingState } from '../../../utils/Imageimport';
import { formFieldStyle, passwordRulStyle } from "../../../utils/Style";
import { TriggerAlert } from '../../../utils/UIUtil';
import { useDispatch } from 'react-redux';
import { ChangeUserPasswordAction } from '../../../redux/action/action';
const StyledPopper = styled(Popper)(({ theme }) => ({ // You can replace with `PopperUnstyled` for lower bundle size.
    zIndex: 1,
    maxWidth: '375px',
    width: '100%',
    '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
    },
    '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
    },
    '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
    },
    '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
    },
}));


function FirstPasswordReset() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [arrowRef, setArrowRef] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const nav = useNavigate();
    const dispatch = useDispatch()
    // const loadAPi = async () => {
    //     const id = searchParams.get("uid")
    //     const res = await getTokenAPI(id)
    //     if (res) {
    //         console.log(res, "res")
    //         setToken(res?.data?.accessToken)
    //     }

    // }
    // useEffect(() => {
    //     if (searchParams.get("uid")) {
    //         loadAPi()
    //     }
    // }, [])
    const handelChange = (e, type) => {
        if (type === "new") {
            if (e) {
                setPassword({ ...password, newPassword: e.target.value })
            } else {
                setPassword({ ...password, newPassword: "" })
            }
        } else {
            if (e) {
                setPassword({ ...password, confirmPassword: e.target.value })
            } else {
                setPassword({ ...password, confirmPassword: "" })
            }
        }
    }
    const ChangePassword = async () => {
        if (password.newPassword === password.confirmPassword) {
            const pass = { newPassword: password.newPassword }
            dispatch(ChangeUserPasswordAction(pass, nav))
        } else {
            TriggerAlert("error", "Password Is Not Match")
        }
    }
    const styles = {
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            color: 'black',
            '&::before': {
                content: '""',
                margin: 'auto',
                borderColor: 'red',
                display: 'block',
                borderStyle: 'solid',
            },
        }
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100vh", background: grey[100] }}>
            <Card sx={{ px: 10, py: 5, border: "1px solid #00a859" }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ pb: 5, textAlign: 'center' }}>
                        <img src={logo} style={{ height: 50 }} alt="logo" />
                    </Box>
                    <Box>
                        <TextField
                            label=" New Password *"
                            size="small"
                            type='password'
                            onChange={(e) => handelChange(e, "new")}
                            onFocus={(e) => { setAnchorEl(e.currentTarget) }}
                            onBlur={(e) => { setAnchorEl(false) }}
                            inputProps={{ "aria-label": "Code" }}
                            value={password.newPassword}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label=" Confirm Password *"
                            size="small"
                            type='password'
                            onChange={(e) => handelChange(e, "confirm")}
                            inputProps={{ "aria-label": "Code" }}
                            value={password.confirmPassword}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                        />
                        {/* <Typography onClick={() => nav("/login")} sx={{ color: "blue", textAlign: 'right', p: 0.2, cursor: 'pointer' }}>Login</Typography> */}
                    </Box>
                    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-evenly', gap: 2 }}>
                        <Button variant='contained' color='success' disabled={!password.newPassword || !password.confirmPassword} size='small' onClick={ChangePassword}>
                            Submit
                        </Button>
                        <Button variant='contained' color='error' size='small' onClick={() => {

                            setPassword({ ...password, confirmPassword: "", newPassword: "" })
                        }}>
                            Back
                        </Button>
                    </Box>
                </Box>
            </Card>
            <StyledPopper
                anchorEl={anchorEl}
                open={open1}
                // className="tooltip"
                sx={{ background: 'white', borderRadius: 2 }}
                placement="right-start"
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },]}
            // onClose={handleClose}
            >
                <Box component="span" className="arrow" ref={setArrowRef} sx={styles.arrow} />
                <Box sx={{ p: 1 }} ref={arrowRef}>
                    <Box sx={{ pt: 1 }}>
                        <Box sx={{ ...passwordRulStyle, pb: 1 }}>{"Password should meet the conditions given below."}</Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pr: 1 }}>{passwordChecking(password.newPassword, "", 8)}</Box>
                            <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(password.newPassword, "", 8) }}>{"Length should be minimum 8 characters."}</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pr: 1 }}>{passwordChecking(password.newPassword, /[a-z]/)}</Box>
                            <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(password.newPassword, /[a-z]/) }}>{"Should have 1 lower case."}</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pr: 1 }}>{passwordChecking(password.newPassword, /[A-Z]/)}</Box>
                            <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(password.newPassword, /[A-Z]/) }}>{"Should have 1 upper case"}</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pr: 1 }}>{passwordChecking(password.newPassword, /\d/)}</Box>
                            <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(password.newPassword, /\d/) }}>{"Should have 1 numeric."}</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pr: 1 }}>{passwordChecking(password.newPassword, /[@$#_]/)}</Box>
                            <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(password.newPassword, /[@$#_]/) }}>{"Should have any one special charter (#,$,@,_)"}</Box>
                        </Box>
                    </Box></Box>
            </StyledPopper>

        </Box>
    )
}

export default FirstPasswordReset