export const taskStatusReportColor = {
    Open: '#9ea4ae',
    Assigned: '#1358d0',
    Progress: '#fd801e',
    Revert: "#483434",
    End: '#1db82b',
    total: "#ed2e01",

}
export const generaltaskHistoryColor = {
    start: ' #F57328',
    notstart: '#940B92',
    update: '#DA0C81',
    End: '#367E18'

}
export const ADMIN_DASHBOARD_COLORS = {
    project: '#6647ed',
    job: '#17aed1',
    task: '#f18d2d',
    revert: '#fa6c6c',
    dueDate: '#24a042',
    query: "#483434",

}
export const USER_DASHBOARD_COLORS = {
    overall: '#6647ed',
    pending: '#17aed1',
    inprogresstask: '#f18d2d',
    revert: '#fa6c6c',
    completed: '#24a042',
    querypending: "#483434",
    queryclosed: "#BA704F",
    taskBorderColor: '#0F0F0F',
    queryBorderColor: '#183D3D',
    taskBorderBackground: '#E6FFFD',
    queryBorderBackground: '#AEE2FF'

}
export const taskStatusReportColorFontColor = {
    Open: 'white',
    Assigned: 'white',
    Progress: 'white',
    Revert: "white",
    End: 'white'

}
export const profilePageColors = {
    iconColor: "black",
    profileBack: "#A0E9FF",
    iconBack: 'white'
}
export const jobPageColor = {
    jobDetailsColor: "#FF771B",
    slaColor: "#5A4DB2",
    slahigh: "#F15A59",
    slamedium: "orange",
    slalow: "#24a042",

}
export const QueryColor = {
    low: "#339af0",
    mediam: "#ffc078",
    high: "#F15A59",
}
export const QueryColorBack = {
    low: "white",
    mediam: "white",
    high: "white",
    colorlow: "black",
    colormediam: "black",
    colorhigh: "black",
}
export const toggelcolor = {
    myListColor: '#9e9e9e',
    allListColor: '#339af0',
    myListfontcolor: 'white',
    allListfontcolor: 'white',
    hoverMyListColor: "white",
    hoverAllListColor: "white",
}

export const toggleColorAnnouncement = {
    highback: "#ff3300",
    normalback: "#339af0",
    highfontColor: "white",
    normalfontColor: "white",
    hoverhighfontColor: "white",
    hovernormalfontColor: "white",
}

export const supportColor = {
    unReadColor: '#479ef5'
}
// Open: ' #70b9f5',
// Assigned: '#b4a3ff',
// Progress: '#b0e2e8',
// Revert: "#f8d69b",
// End: '#b0e3b2'