import {
    ConfirmationNumber,
    EditCalendar,
    MoreTime,
    Today
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { allpageFontStyle } from "../../utils/FontStyle";
import { TaskAssignPageImages } from "../../utils/Imageimport";

export const CustomLabel = (props) => {
    const { labelArr } = props;
    return (
        <Box>
            {labelArr &&
                labelArr.length > 0 &&
                labelArr.map((val) => (
                    <Box>
                        <Typography>
                            <Box>{val.label}</Box>:<Box>{val.value}</Box>
                        </Typography>
                    </Box>
                ))}
        </Box>
    );
};
export const assignConditionType = [
    { label: "Auto ", value: "auto", tab: 1 },
    { label: "Manual ", value: "number", tab: 2 },
    { label: "Excel ", value: "upload", tab: 3 },
];
export const checkBoxFilterType = [
    { label: "Task", value: "autoRemainTask", checkBoxvalue: false },
    { label: "Revert Task", value: "revert", checkBoxvalue: false },
    // { label: 'Upload Assign', value: 'upload' },
];
export const reassignColumns = [
    // {
    //     field: "taskId",
    //     headerName: "Task Id",
    //     width: 190,
    //     align: "left",
    //     headerAlign: "left",
    // },
    {
        field: "assignedUser",
        headerName: "Assigned User",
        width: 130,
        align: "left",
        headerAlign: "left",
    },
    {
        field: "newUser",
        headerName: "New User",
        width: 120,
        align: "left",
        headerAlign: "left",
    },
    {
        field: "cell",
        headerName: "Cell",
        flex: 1,
        align: "left",
        headerAlign: "left",
        width: 50,
        minWidth: 50,
    }]
export const assigneErrorColumns = [{
    field: "id",
    headerName: "Id",
    width: 100,
    align: "left",
    headerAlign: "left",
},
{
    field: "cellRange",
    headerName: "Range",
    width: 130,
    align: "left",
    headerAlign: "left",
},
{
    field: "empId",
    headerName: "EmpId",
    flex: 1,
    align: "left",
    headerAlign: "left",
    width: 100,
    minWidth: 100,
},

]
export const sampleReassignData = [
    {
        id: 156,
        taskId: "1",
        assignedUser: "emp161",
        newUser: "emp162",
        cell: "C5",
    },
    {
        id: 16346,
        taskId: "2",
        assignedUser: "emp161",
        newUser: "emp162",
        cell: "C8",
    },
    {
        id: 26546,
        taskId: "3",
        assignedUser: "emp161",
        newUser: "emp162",
        cell: "C500",
    },
    {
        id: 365,
        taskId: "4",
        assignedUser: "emp161",
        newUser: "emp162",
        cell: "C545",
    },
];
const ContentView = ({ left, right, style, leftIcon }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...style || {} }}>
            <Box sx={{ fontSize: '0.75rem', fontWeight: 'bold', display: 'flex', alignItems: 'center', gap:0.5 }}>
                {leftIcon || ''}{left}
            </Box>
            <Box sx={{ fontSize: '0.75rem', color: '#048ef0' }}>{right}</Box>
        </Box>
    )
}
export const JobDetailsComponet = (props) => {
    const { jobObject, jobCountArray } = props;

    const filterCount =
        jobCountArray &&
        jobCountArray.length > 0 &&
        jobCountArray.find((val) => val.jobCode === jobObject.jobCode);
    const pageCountObject = { Page: 0, unitType: "Page" };
    const valueStyle = {
        ...allpageFontStyle.typography1
    };
    const labelStyle = { ...allpageFontStyle.typography1 };
    const IconStyle = { fontSize: 25 };
    const labelContainer = { display: "flex", flexDirection: "column-reverse" };
    const IconContainer = {
        display: "flex",
        gap: 1,
        alignItems: "center",
        alignContent: "center",
    };
    const obj = filterCount || pageCountObject;
    return (
        <Box sx={{ display: "flex", mb: 0.3 }}>
            {/* <Box sx={{ display: "flex", gap: 5 }}>
                <Box sx={IconContainer}>
                    <Box>
                        <EditCalendar sx={IconStyle} />
                    </Box>
                    <Box sx={labelContainer}>
                        <strong style={labelStyle}>Created date</strong>{" "}
                        <label style={valueStyle}>
                            {moment(jobObject?.createdOn).format("DD/MM/YYYY")}
                        </label>
                    </Box>
                </Box>
                <Box sx={IconContainer}>
                    <Box>
                        <Today sx={IconStyle} />
                    </Box>
                    <Box sx={labelContainer}>
                        <strong style={labelStyle}>Received date</strong>{" "}
                        <label style={valueStyle}>
                            {moment(jobObject?.receivedDate).format("DD/MM/YYYY")}
                        </label>
                    </Box>
                </Box>
                <Box sx={IconContainer}>
                    <Box>
                        <MoreTime sx={IconStyle} />
                    </Box>
                    <Box sx={labelContainer}>
                        <strong style={labelStyle}>Due date</strong>{" "}
                        <label style={valueStyle}>
                            {moment(jobObject?.dueDate).format("DD/MM/YYYY")}
                        </label>
                    </Box>
                </Box>
                <Box sx={IconContainer}>
                    <Box>
                        <ConfirmationNumber sx={IconStyle} />
                    </Box>
                    <Box sx={labelContainer}>
                        <strong style={labelStyle}>{obj?.unitType}</strong>{" "}
                        <label style={valueStyle}>{obj[obj?.unitType]}</label>
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <ContentView leftIcon={TaskAssignPageImages.createdOn} left={'Created on'} right={moment(jobObject?.createdOn).format("DD/MM/YYYY")} />
                <ContentView leftIcon={TaskAssignPageImages.receivedOn} left={'Received on'} right={moment(jobObject?.receivedDate).format("DD/MM/YYYY")} />
                <ContentView leftIcon={TaskAssignPageImages.dueOn} left={'Due on'} right={moment(jobObject?.dueDate).format("DD/MM/YYYY")} />
                <ContentView leftIcon={TaskAssignPageImages.unitType} left={'Unit Type'} right={`${obj?.unitType} - ${obj[obj?.unitType]}`} />
            </Box>
        </Box>
    );
};
