import axios from "./axiosService";
const baseURL = process.env.REACT_APP_BASE_URL;

export const getProjectDepartmentandTeamAndUser = async (id) => {
    try {
        return await axios({
            method: "get",
            url: `${baseURL}/generalTask/pro_dep_team_user/${id}/`,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const getRoleList = async (id) => {
    try {
        return await axios({
            method: "get",
            url: `${baseURL}`,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const createandEditRole = async (method, data) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}/auth/group_info/`,
            data,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const getRoleMappingDetails = async (method, data) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}/auth/user_group_user_mapped/`,
            data,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const roleMappingToUsers = async (method, data, path) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}${path}`,
            data,
        }).then((response) => {

            return response
        });
    } catch (error) {
    }
};
export const getPermissionList = async (method) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}/auth/permissions_list/`,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const getRolePermissionDetails = async (method, data) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}/auth/group_permissions_list/`,
            data,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};

export const getRoleAndPermissionMapping = async (method, data) => {
    try {
        return await axios({
            method: method,
            url: `${baseURL}/auth/user_group_permissions_mapped/`,
            data,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const getPermissionCategoryListService = async () => {
    try {
        return await axios({
            method: 'post',
            url: `${baseURL}/auth/fetch_group_permissions_info`,
            data:{
                selectType: "category",
            }
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};
export const getPermissionListByCategoryService = async (id) => {
    try {
        return await axios({
            method: 'get',
            url: `${baseURL}/auth/group_permissions_info?type=permissions&categoryId=${id}`,
        }).then((response) => {
            return response
        });
    } catch (error) {
    }
};