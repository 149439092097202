import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { generalTaskAction } from "../../../service/action/action";
import {
  formContainerCardStyle,
  formContainerStyle,
} from "../../../utils/Style";
import { RESET_LABEL } from "../../../utils/textName";
import { allpageFontStyle } from "../../../utils/FontStyle";
import PopupComponent from "../../../components/commonComponents/PopupComponent";
import { PopupMessage } from "../../../utils/UIUtil";

const schema = yup.object().shape({
  taskName: yup.string().required("Required Field"),
  taskDescription: yup.string(),
  taskCategory: yup.string(),
  recurring: yup.boolean(),
  startDate: yup.string().when("recurring", (val, schema) => {
    if (val[0]) return yup.string().nullable();
    else return yup.string().required("Required Field");
  }),
  endDate: yup.string().when("recurring", (val, schema) => {
    if (val[0]) return yup.string().nullable();
    else return yup.string().required("Required Field");
  }),
});
const CreateGeneralTaskScreen = () => {
  const form = useForm({
    defaultValues: {
      taskName: "",
      taskDescription: "",
      taskCategory: "",
      startDate: null,
      endDate: null,
      recurring: true,
    },
    resolver: yupResolver(schema),
  });
  const { pathname, state } = useLocation();
  const isUpdate = pathname === "/generalTask/edit";
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = form;
  const onSubmit = async (e) => {
    if (isUpdate)
      generalTaskAction("patch", { ...e, id: e.id || searchParams.get("id") });
    else generalTaskAction("post", e).then((res) => res && onreset());
  };
  const onreset = () => {
    reset();
    trigger("recurring");
  };
  const [render, setRender] = useState(false);
  const onEdit = () => {
    setValue("id", state?.data?.generalTaskInfo?.id);
    setValue("taskName", state?.data?.generalTaskInfo?.taskName);
    setValue("taskDescription", state?.data?.generalTaskInfo?.taskDescription);
    setValue("taskCategory", state?.data?.generalTaskInfo?.taskCategory);
    setValue(
      "startDate",
      state?.data?.generalTaskInfo?.startDate
        ? moment(state?.data?.generalTaskInfo?.startDate).format(
            "YYYY-MM-DDTHH:mm"
          )
        : null
    );
    setValue(
      "endDate",
      state?.data?.generalTaskInfo?.endDate
        ? moment(state?.data?.generalTaskInfo?.endDate).format(
            "YYYY-MM-DDTHH:mm"
          )
        : null
    );
    setValue("recurring", state?.data?.generalTaskInfo?.recurring);
    setRender(!render);
  };
  const [alertPopup, setAlertPopup] = useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      navigate("/generalTask/list", { replace: true });
    } else if (alertPopup.type === PopupMessage.resetType) {
      onreset();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      navigate("/generalTask/list", { replace: true });
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  useEffect(() => {
    if (isUpdate) onEdit();
    return () => isUpdate && reset();
  }, [pathname]);
  return (
    <Box flex={1} sx={{ ...formContainerStyle, minWidth: 800 }}>
      <Box sx={{ width: "100%" }}>
        <Card sx={{ ...formContainerCardStyle, p: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // px: 5,
            }}
          >
            <Box sx={{ flex: 0.1 }}></Box>
            <Box sx={{ flex: 0.1 }}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  color: "white",
                  width: 90,
                  m: { xs: 1, md: 1, lg: 2 },
                  ...allpageFontStyle.buttonFontStyle2,
                  ":hover": {
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                  },
                  borderRadius: 5,
                }}
                onClick={() => {
                  setAlertPopup((per) => ({
                    ...per,
                    open: true,
                    type: PopupMessage.listType,
                    title: PopupMessage.back,
                  }));
                }}
                color="primary"
              >
                List
              </Button>
            </Box>
          </Box>
          <FormProvider {...form}>
            <form
              style={{
                height: "100%",
                // paddingBottom: 20,
                // paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                justifyContent={"center"}
                spacing={2}
                p={2}
                px={4}
                width={"40%"}
              >
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="taskName"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Task Name*"
                        sx={{
                          ".MuiInputBase-input": {
                            // padding:0.5
                          },
                        }}
                        helperText={
                          errors.taskName ? errors?.taskName?.message : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors?.taskName?.message}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        inputProps={{ "aria-label": "Name" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="taskDescription"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        label="Task Description"
                        sx={{
                          ".MuiInputBase-input": {
                            padding: 0,
                          },
                        }}
                        helperText={
                          errors.taskDescription
                            ? errors?.taskDescription?.message
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors?.taskDescription?.message}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        inputProps={{ "aria-label": "Name" }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="taskCategory"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                size="small"
                                                label="Task Category"
                                                sx={{
                                                    '.MuiInputBase-input': {
                                                        padding: 0
                                                    }
                                                }}
                                                helperText={
                                                    errors.taskCategory
                                                        ? errors?.taskCategory?.message
                                                        : null
                                                }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={errors?.taskCategory?.message}
                                                onChange={onChange}
                                                value={value}
                                                inputRef={ref}
                                                inputProps={{ "aria-label": "Name" }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                {/* <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="recurring"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <FormControl
                                                error={errors.recurring}
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel>Type*</InputLabel>
                                                <Select
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    label="Type*"
                                                    size="small"
                                                    MenuProps={{
                                                        PaperProps: { sx: { maxHeight: 150 } },
                                                    }}
                                                    helperText={
                                                        errors.recurring ? errors.recurring.message : null
                                                    }
                                                    error={errors.recurring?.message}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        setValue('startDate', null);
                                                        setValue('endDate', null);
                                                        trigger('recurring')
                                                    }}
                                                    value={value}
                                                    inputRef={ref}
                                                    inputProps={{
                                                        "aria-label": "Project Code",
                                                    }}
                                                >
                                                    <MenuItem
                                                        sx={{
                                                            borderBottom: "1px solid white",
                                                            fontFamily: "Lato",
                                                            fontSize: 13,
                                                        }}
                                                        value={true}
                                                    >
                                                        Always
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{
                                                            borderBottom: "1px solid white",
                                                            fontFamily: "Lato",
                                                            fontSize: 13,
                                                        }}
                                                        value={false}
                                                    >
                                                        Period
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText sx={{ color: "red" }}>
                                                    {errors.recurring ? errors?.recurring?.message : null}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />

                                </Grid> */}
                {getValues("recurring") === false ? (
                  <>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            fullWidth
                            type="datetime-local"
                            size="small"
                            label="Start Date"
                            helperText={
                              errors.startDate
                                ? errors?.startDate?.message
                                : null
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={moment().format("YYYY-MM-DD")}
                            error={errors?.startDate?.message}
                            onChange={(e) => {
                              onChange(e);
                              setValue("endDate", "");
                              trigger("startDate");
                            }}
                            value={value || ""}
                            inputRef={ref}
                            inputProps={{
                              "aria-label": "Name",
                              min: isUpdate
                                ? ""
                                : moment().format("YYYY-MM-DDTHH:mm"),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="endDate"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            fullWidth
                            type="datetime-local"
                            size="small"
                            label="End Date"
                            helperText={
                              errors.endDate ? errors?.endDate?.message : null
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={errors?.endDate?.message}
                            onChange={onChange}
                            value={value || ""}
                            inputRef={ref}
                            inputProps={{
                              "aria-label": "Name",
                              min: getValues("startDate")
                                ? moment(getValues("startDate")).format(
                                    "YYYY-MM-DDTHH:mm"
                                  )
                                : moment().format("YYYY-MM-DDTHH:mm"),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <Box
                // mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // gap: 2,
                  // mb: 5,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{
                    color: "white",
                    width: 90,
                    m: { xs: 1, md: 1, lg: 2 },
                    ...allpageFontStyle.buttonFontStyle2,
                    ":hover": {
                      boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                    },
                    borderRadius: 5,
                  }}
                  color="success"
                >
                  {isUpdate ? "Update" : "Submit"}
                </Button>{" "}
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    color: "white",
                    width: 90,
                    m: { xs: 1, md: 1, lg: 2 },
                    ...allpageFontStyle.buttonFontStyle2,
                    ":hover": {
                      boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                    },
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    setAlertPopup((per) => ({
                      ...per,
                      open: true,
                      type: PopupMessage.resetType,
                      title: PopupMessage.reset,
                    }));
                  }}
                  color="error"
                >
                  {" "}
                  {RESET_LABEL}
                </Button>{" "}
              </Box>
            </form>
          </FormProvider>
        </Card>
      </Box>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </Box>
  );
};

export default CreateGeneralTaskScreen;
