import {
  Article,
  DownloadForOffline,
  FiberManualRecord,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { downloadFileService } from "../../../service/helpdeskService";
import border from "../../../pics/leaves.png";
import { allpageFontStyle } from "../../../utils/FontStyle";
import { messageAsRead } from "../../../service/notificationService";
import { supportColor } from "../../../utils/CommonColors";
export default function AccordionCard({
  list,
  openPop,
  announcementId,
  notificationInfo,
  getAPICall,
  popClose,
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log(openPop, open, "opennn");
    if (
      openPop &&
      list?.announcementInfo?.announcementCode === announcementId
    ) {
      handleClickOpen();
      console.log(openPop, open, "opennn2");
    }
  }, [openPop]);
  console.log(openPop, open, "opennn3");
  const [attachCount, setAttachCount] = useState(false);
  const handleClickOpen = async () => {
    try {
      if (notificationInfo?.isRead) {
        const id = notificationInfo?.id;
        await messageAsRead(id);
      }
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    if (
      openPop &&
      list?.announcementInfo?.announcementCode === announcementId
    ) {
      popClose();
      // setPayload1({...payload1,searchContent: null, })
    }
    if (notificationInfo?.isRead) {
      getAPICall();
    }
    setOpen(false);
  };
  // console.log(openPop, open,payload1, "opennn4")
  const fileDownload = (file, view) => {
    downloadFileService(file, view);
  };
  console.log(list, "dataaaasfs");
  return (
    <>
      <Card
        onClick={handleClickOpen}
        sx={{
          px: 3,
          my: 1,
          cursor: "pointer",
          position: "relative",
          pt: 1,
          ...allpageFontStyle.typography3,
          pb: 3,
          backgroundColor: !notificationInfo?.isRead ? "#f1f3f5" : "white",
          borderLeft: list?.announcementInfo?.priority
            ? "5px solid #ff6b6b"
            : "5px solid #4dabf7",
          boxShadow: !notificationInfo?.isRead
            ? "none"
            : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {/* <FiberManualRecord
            fontSize="inherit"
            sx={{
              mt: 2.5,
              color: list?.announcementInfo?.priority ? "#ff6b6b" : "#4dabf7",
            }}
          /> */}
          <Box
            sx={{
              height: 45,
              width: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "0.5px solid grey",
            }}
          >
            <Avatar
              sizes=""
              sx={{
                height: 40,
                width: 40,
                borderRadius: "50%",
                objectFit: "contain",
              }}
              alt={
                list?.createUserInfo?.firstname +
                " " +
                list?.createUserInfo?.lastname
              }
              src={`data:image/jpeg;base64,${list?.createdUserImage?.userBase64Image}`}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pl: 2,
              width: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {notificationInfo?.isRead ? (
                <Box sx={{ ...allpageFontStyle.buttonFontStyle2 }}>
                  {list?.announcementInfo?.announcementCode}
                </Box>
              ) : (
                <Box sx={{ ...allpageFontStyle.buttonFontStyle1 }}>
                  {list?.announcementInfo?.announcementCode}
                </Box>
              )}
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    ...allpageFontStyle.typography1,
                    color: notificationInfo?.isRead
                      ? supportColor.unReadColor
                      : "",
                    fontWeight: "bold",
                  }}
                >
                  📁 {list?.announcementInfo?.projectCode}
                </Box>
                {list?.announcementInfo?.jobCode ? (
                  <Box
                    sx={{
                      ...allpageFontStyle.typography2,
                      color: notificationInfo?.isRead
                        ? supportColor.unReadColor
                        : "",
                      ml: 2,
                      fontWeight: "bold",
                    }}
                  >
                    💼 {list?.announcementInfo?.jobCode}
                  </Box>
                ) : null}
              </Box>
            </Box>

            {notificationInfo?.isRead ? (
              <Box
                sx={{
                  ...allpageFontStyle.title2,
                  fontWeight: "bold",
                  color: supportColor.unReadColor,
                }}
              >
                {list?.announcementInfo?.title}
              </Box>
            ) : (
              <Box sx={{ fontFamily: "Lato", fontSize: 13 }}>
                {list?.announcementInfo?.title}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            color: notificationInfo?.isRead ? supportColor.unReadColor : "",
            ...allpageFontStyle.typography2,
            right: 20,
          }}
        >
          {moment(list?.announcementInfo?.createdOn).format(
            "MMMM Do YYYY, h:mm a"
          )}
        </Box>
      </Card>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{borderRadius:5}}
      >
        {/* <img alt="" src={border} style={{position:'absolute', height:60, width:60, left:0}}/>
        <img alt="" src={border} style={{position:'absolute', height:60, width:60, right:0, transform:'rotate(90deg)'}}/>
        <img alt="" src={border} style={{position:'absolute', height:60, width:60, bottom:0, transform:'rotate(-90deg)'}}/>
        <img alt="" src={border} style={{position:'absolute', height:60, width:60, bottom:0, right:0, transform:'rotate(-180deg)'}}/> */}
        <DialogContent
          sx={{
            px: 5,
            fontFamily: "Lato",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              color: list?.announcementInfo?.priority ? "#ff6b6b" : "#1971c2",
              width: "100%",
              py: 1,
              display: "flex",
              justifyContent: "center",
              //   color: "black",
              fontWeight: "bold",
              px: 5,
              ...allpageFontStyle.typography3,
              background: list?.announcementInfo?.priority
                ? "#fff5f5"
                : "#e7f5ff",
              fontSize: 18,
            }}
          >
            Announcement ({list?.announcementInfo?.announcementCode})
          </Box>
          <Box sx={{ display: "flex", px: 3, mt: 3 }}>
            <Box sx={{ ...allpageFontStyle.buttonFontStyle2 }}>
              Project Code :{" "}
              <span style={{ color: "#488a50", marginLeft: 1 }}>
                {list?.announcementInfo?.projectCode}
              </span>
            </Box>
            {list?.announcementInfo?.jobCode ? (
              <Box sx={{ ...allpageFontStyle.buttonFontStyle2, ml: 2 }}>
                Job Code :{" "}
                <span style={{ color: "#488a50", marginLeft: 1 }}>
                  {list?.announcementInfo?.jobCode}
                </span>
              </Box>
            ) : null}
            <Box
              sx={{
                ...allpageFontStyle.buttonFontStyle2,
                fontWeight: "bold",
                ml: 2,
              }}
            >
              Created by :
              <span style={{ color: "#488a50", marginLeft: 1 }}>
                {list?.createUserInfo?.firstname +
                  " " +
                  list?.createUserInfo?.lastname}
              </span>
            </Box>
            <Box sx={{ ...allpageFontStyle.buttonFontStyle2, ml: 2 }}>
              Created on :
              <span style={{ color: "#488a50", marginLeft: 1 }}>
                {moment(list?.announcementInfo?.createdOn).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </span>
            </Box>
          </Box>
          <Box
            sx={{
              //   color: list?.announcementInfo?.priority ? "#f03e3e" : "#1c7ed6",
              ...allpageFontStyle.title,
              px: 3,
              //   textDecoration: "underline",
              mt: 1,
            }}
          >
            {list?.announcementInfo?.title}
          </Box>

          <Typography
            sx={{
              p: 0,
              m: 0,
              width: "100%",
              minWidth: "100%",
              // lineHeight:1.3,
              ".innertag div": {
                width: "100%",
                background: "#f1f3f5",
                maxWidth: "100%",
                minWidth: "100%",
                overflow: "hidden",
                // fontSize: 13,
                fontFamily: "Lato",
                p: 0,
                m: 0,
                px: 3,
                py: 2,
                borderRadius: 2,
              },
              ".innertag table tbody tr td": {
                borderCollapse: "collapse",
                border: "1px solid black",
                background: "transparent",
              },
            }}
          >
            <p
              style={{ padding: 0, background: "transparent" }}
              className="innertag"
              dangerouslySetInnerHTML={{
                __html: list?.announcementInfo?.message,
              }}
            ></p>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              pl: 3,
              mb: 1,
            }}
          >
            {list?.fileInfo?.map((files) => (
              <Box
                sx={{
                  p: 0.5,
                  // border: "0.1px solid grey",
                  display: "flex",
                  m: 0.3,
                  borderRadius: 3,
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Article fontSize="small" sx={{ mr: 0.2 }} />
                <Typography
                  onClick={(e) => {
                    fileDownload(files, true);
                    e.stopPropagation();
                  }}
                  sx={{
                    ...allpageFontStyle.typography1,
                    // fontWeight: "bold",
                    textDecoration: "underline",
                    ":hover": {
                      color: "#1864ab",
                    },
                  }}
                >
                  {files?.originalFileName}
                </Typography>
                <DownloadForOffline
                  onClick={(e) => {
                    fileDownload(files);
                  }}
                  fontSize="small"
                  sx={{ color: "#1864ab", ml: 0.5 }}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <Divider flexItem variant="middle" sx={{ background: "#f8f9fa" }} />
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-end", mr: 5 }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              borderRadius: 3,
              background: list?.announcementInfo?.priority
                ? "#ff8787"
                : "#74c0fc",
              ...allpageFontStyle.typography2,
              color: "black",
              mb: 2,
              ":hover": {
                background: list?.announcementInfo?.priority
                  ? "#ff8787"
                  : "#74c0fc",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
