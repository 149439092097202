import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getAuthToken, getAuthToken1 } from "./authService";
import { TriggerAlert } from "../utils/UIUtil";
// import { getAuthToken, TriggerAlert } from "../actions/action";

// Add a request interceptor
axios.interceptors.request.use(
  function (request) {
    // Do something before request is sent

    if (request.headers?.jobCode) {
      request.headers = {
        "Content-Type": request.headers.filetype,
        // applicationLabel: "Crm-Service",
        correlationId: uuidv4(),
        Authorization: `Bearer ${getAuthToken1() || getAuthToken()}`,
        "Content-Disposition": "attachment; filename=job.xlsx",
        "x-project-code": request.headers.projectCode,

        "x-job-code": request.headers.jobCode,

        "x-receive-date": request.headers.receivedDate + " 00:00:00",

        "x-due-date": request.headers.dueDate + " 00:00:00",

        "x-org-id": request.headers.orgId,

        "x-project-name": request.headers.projectName,
        "x-display-columns": request.headers.displaycolumns,
      };
      return request;
    } else if (request.headers?.projectCode) {
      request.headers = {
        "Content-Type": request.headers.filetype,
        correlationId: uuidv4(),
        Authorization: `Bearer ${getAuthToken1() || getAuthToken()}`,
        "Content-Disposition": "attachment; filename=job.xlsx",
        "x-project-code": request.headers.projectCode,
      }
      return request;
    } else if (request.headers?.filetype) {

      // console.log(request.headers.cell, request.headers, ' request.headers?.cell')
      request.headers = {
        "Content-Type": request.headers.filetype,
        correlationId: uuidv4(),
        Authorization: `Bearer ${getAuthToken1() || getAuthToken()}`,
        "Content-Disposition": "attachment; filename=job.xlsx",
        "x-reprocess-cells": request.headers.cell,

      }
      return request;
    }
    else {
      request.headers = {
        // "Content-Type": "application/json",
        // applicationLabel: "Crm-Service",
        correlationId: uuidv4(),
        Authorization: `Bearer ${getAuthToken1() || getAuthToken()}`,
      };
      return request;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // if (response.data.code === 401) return logout(store.dispatch);
    return Promise.resolve(response);
  },
  function (error) {
    console.log(error.response.data, "errrorrr")
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.data?.error_message) {
      TriggerAlert("error", error?.response?.data?.error_message);
    } else if (error?.response?.data?.message) {
      return Promise.reject(error);
    } else {
      TriggerAlert("error", error?.response?.data?.error_message);
    }

    return Promise.reject(error);
  }
);
export default axios;
