import { Close, Done } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import EyeIcon from "../../components/commonComponents/eyeicon";
import DataGride from "../../components/datagride/DataGride";
import {
  getPojectReleatedListAction,
  getProjectListinDashboardAction,
  getRevertTaskListAdminAction,
  revertTaskapproveandRejectAction,
} from "../../redux/action/action";
import {
  formContainerCardStyle,
  formContainerStyle,
  textFieldsStyle,
} from "../../utils/Style";
import { secondsToHMS } from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import { revertTaskPageProjectGETAPI } from "../../service/taskService";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { allpageFontStyle } from "../../utils/FontStyle";
import DateComponet from "../../components/commonComponents/DateComponet";

function TaskRevert() {
  const initialState = {
    projectInfo: "",
    jobInfo: "",
    categoryInfo: "",
    tasktypeInfo: "",
    userInfo: [],
    state: "",
    status: "",
    startDate: null,
    endDate: null,
  };
  const statusOption = [
    { label: "All", value: "All" },
    { label: "Revert Requested", value: "Revert Requested" },
    { label: "Revert Approved", value: "Reverted" },
    // { label: "Revert Rejected", value: "Revert Rejected" },
  ];
  const [filterData, setFilterData] = useState(initialState);
  const [selectOptionData, setselectOptionData] = useState({
    projectList: [],
    jobList: [],
    taskTypeList: [],
    categoryList: [],
    userList: [],
    state: [],
    status: [],
  });
  const [taskList, setTaskList] = React.useState([]);
  const [payload, setpayload] = useState({
    type: "",
    remarks: "",
    rowData: "",
  });
  const [open, setOpen] = useState(false);
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );

  const { projectListDashboard, projectReleatedList } = useSelector(
    (state) => state.master
  );
  const dispatch = useDispatch();
  const handelClearAll = () => {};
  const constructProjectData = (arr) => {
    const userAll = [{ firstname: "All", lastname: "" }];
    const userMap =
      arr.users && arr.users.length > 0 && arr.users.map((val) => val.userInfo);

    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr?.jobList,
      taskCategory: ["All", ...arr?.taskCategory],
      taskType: ["All", ...arr?.taskType],
      // state: ["All", ...arr?.taskState],
      // status: ["All", ...statusMap],
      userList: [...userAll, ...userMap],
    };
    return res;
  };
  React.useEffect(() => {
    if (projectReleatedList?.jobList?.length > 0) {
      const taskListData = constructProjectData(projectReleatedList);
      setselectOptionData({
        ...selectOptionData,
        userList: taskListData?.userList,
        jobList: taskListData?.jobList,
        taskTypeList: taskListData?.taskType,
        categoryList: taskListData?.taskCategory,
        state: taskListData?.state,
        status: statusOption,
      });
    }
  }, [projectReleatedList]);
  const handelSelect = async (e, type) => {
    if (type === "Project") {
      if (e) {
        setselectOptionData({
          ...selectOptionData,
          userList: [],
          status: [],
          jobList: [],
        });
        await dispatch(
          getPojectReleatedListAction({
            projectCode: e?.projectCode,
            orgId: globapOrganization?.orgId,
          })
        );
        setFilterData({
          ...filterData,
          projectInfo: e,
          jobInfo: "",
          status: statusOption[0],
          userInfo: [{ firstname: "All", lastname: "" }],
        });
      } else {
        setselectOptionData({
          ...selectOptionData,
          userList: [],
          status: [],
          jobList: [],
        });
        setFilterData({
          ...filterData,
          projectInfo: "",
          jobInfo: "",
          status: "",
          userInfo: [],
        });
      }
    } else if (type === "Job") {
      if (e) {
        setFilterData({ ...filterData, jobInfo: e });
      } else {
        setFilterData({ ...filterData, jobInfo: "" });
      }
    } else if (type === "status") {
      if (e) {
        setFilterData({ ...filterData, status: e });
      } else {
        setFilterData({ ...filterData, status: "" });
      }
    } else if (type === "tasktype") {
      if (e) {
        setFilterData({ ...filterData, tasktypeInfo: e });
      } else {
        setFilterData({ ...filterData, tasktypeInfo: "" });
      }
    } else if (type === "userList") {
      if (e) {
        if (e.length > 1 && e.find((i) => i.firstname === "All")) {
          setFilterData((prev) => {
            if (prev.userInfo.find((i) => i.firstname === "All"))
              return {
                ...prev,
                userInfo: e.filter((i) => i.firstname !== "All"),
              };
            else
              return {
                ...prev,
                userInfo: e.filter((i) => i.firstname === "All"),
              };
          });
        } else setFilterData((prev) => ({ ...prev, userInfo: [...e] }));
      } else {
        setFilterData({ ...filterData, userInfo: [] });
      }
    }
  };

  const handelDate = (e, type) => {
    if (type === "start") {
      if (e) {
        setFilterData({
          ...filterData,
          startDate: e ? moment(e).format("YYYY-MM-DD") : null,
          endDate: null,
        });
      } else {
        setFilterData({ ...filterData, startDate: "" });
      }
    } else {
      if (e) {
        setFilterData({
          ...filterData,
          endDate: e ? moment(e).format("YYYY-MM-DD") : null,
        });
      } else {
        setFilterData({ ...filterData, endDate: "" });
      }
    }
  };
  const constructRevertTask = (arr) => {
    const res =
      arr.length > 0 &&
      arr.map((val) => ({
        ...val?.revertRequested,
        ...val?.revertedData,
        assignedUserInfo: val.assignedUserInfo,
        createUserInfo: val.createUserInfo,
        updatedUserInfo: val.updatedUserInfo,
      }));
    return res;
  };
  const loadData = async () => {
    const filterDataRes =
      filterData?.userInfo &&
      filterData.userInfo.length > 0 &&
      filterData.userInfo.filter((val) => val.firstname === "All");
    const paylaod = {
      projectCode: filterData?.projectInfo?.projectCode,
      jobCode: filterData?.jobInfo?.jobCode,
      orgId: globapOrganization?.orgId,
      taskStatus: filterData?.status?.value,
      assignedDate: {
        fromDate: filterData.startDate
          ? moment(filterData.startDate).format("YYYY-MM-DD")
          : null,
        toDate: filterData.endDate
          ? moment(filterData.endDate).format("YYYY-MM-DD")
          : null,
      },
      users:
        filterDataRes.length > 0
          ? "All"
          : filterData?.userInfo &&
            filterData.userInfo.length > 0 &&
            filterData.userInfo.map((val) => val.empId),
    };
    setTaskList([]);
    const res = await dispatch(
      getRevertTaskListAdminAction(
        "post",
        paylaod,
        "/job/users_task_revert_request/"
      )
    );
    if (res) {
      const construct = constructRevertTask(res.data.data);
      setTaskList(construct);
    }
  };
  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      if (
        filterData?.projectInfo?.projectCode &&
        filterData?.jobInfo?.jobCode
      ) {
        loadData();
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: { isAutoLoad: false },
          })
        );
      }
    }
  }, [autoLoad]);

  const construtProjectdata = (arr) => {
    const res = arr.map((val) => val.projectInfo);
    return res;
  };
  const RevertProjectListAPIFUC = async () => {
    const res = await revertTaskPageProjectGETAPI(
      "post",
      `/report/projectInfo/${globapOrganization?.orgId}/`
    );
    if (res) {
      console.log(res, "res123");
      const project = res.data.project_details;
      setselectOptionData({ ...selectOptionData, projectList: project });
    } else {
      setselectOptionData({ ...selectOptionData, projectList: [] });
    }
  };
  useEffect(() => {
    RevertProjectListAPIFUC();
    // dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    handelClearAll();
  }, [globapOrganization]);
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      setselectOptionData({ ...selectOptionData, projectList: project });
    } else {
      setselectOptionData({ ...selectOptionData, projectList: [] });
    }
  }, [projectListDashboard]);
  const handleClick = (row, type) => {
    setpayload({ ...payload, type: type, rowData: row });
    setOpen(row);
  };
  const handleSubmit = async (type) => {
    const paylaodData = {
      taskId: payload?.rowData?.taskId,
      jobCode: payload?.rowData?.jobCode,
      remarks: type === "Approve" ? "Task Revert Approved" : payload?.remarks,
      revertStatus: type === "Approve" ? "Approved" : "Rejected",
    };
    const res = await dispatch(
      revertTaskapproveandRejectAction(
        "patch",
        paylaodData,
        "/job/task_revert_request/"
      )
    );
    if (res) {
      setOpen(false);
      setpayload({ ...payload, remarks: "" });
      loadData();
    }
  };
  const col = [
    {
      field: "taskId",
      headerName: "Task Id",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taskType",
      headerName: "Task Type",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "jobCode",
      headerName: "Job Id",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "assignedTo",
      headerName: "Aassigned To",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Box sx={{ overflowWrap: "break-word", fontFamily: "Lato" }}>
          {params.row.assignedUserInfo.firstname +
            " " +
            params.row.assignedUserInfo.lastname}
        </Box>
      ),
    },
    {
      field: "productionHours",
      headerName: "Est Hours",
      type: "text",
      width: 150,
      valueGetter: (params) => secondsToHMS(Number(params.row.productionHours)),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      align: "left",
      headerAlign: "left",
      // renderHeader: () => <strong>{"Action"}</strong>,
      getActions: (params) => [
        <>
          {allowedPages().WorkStation.taskRevert.approve &&
          params?.row?.status === "Revert Requested" ? (
            <GridActionsCellItem
              sx={{ p: 0 }}
              icon={
                <Tooltip title="Approve" placement="right">
                  <Box sx={{ mx: 2 }}>
                    {params?.row?.status === "Revert Requested" ? <Done /> : ""}
                  </Box>
                </Tooltip>
              }
              label="Approve"
              onClick={() => {
                handleClick(params.row, "Approve");
              }}
              color="success"
            />
          ) : (
            <></>
          )}
          {allowedPages().WorkStation.taskRevert.reject &&
          params?.row?.status === "Revert Requested" ? (
            <GridActionsCellItem
              sx={{ p: 0 }}
              icon={
                <Tooltip title="Reject" placement="top">
                  {params?.row?.status === "Revert Requested" ? <Close /> : ""}
                </Tooltip>
              }
              label="Rejected"
              onClick={() => {
                handleClick(params.row, "Rejected");
              }}
              color="error"
            />
          ) : (
            <></>
          )}
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="View" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <EyeIcon
                    taskId={params.row.taskId}
                    jobcode={params.row.jobCode}
                    clickOpen={true}
                    clickclose={false}
                  />
                </Box>
              </Tooltip>
            }
            label="View"
            // onClick={() => {
            //   handelEditStart(params)
            // }}
            color="primary"
          />
        </>,
      ],
    },
  ];
  return (
    <Box sx={{ ...formContainerStyle }}>
      <Card sx={formContainerCardStyle}>
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: 600,
            overflow: "auto",
          }}
        >
          <Box sx={{ px: 1, py: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.projectList}
                    labelName={(option) => option.projectCode}
                    // disabled={!treeList.length > 0 }
                    value={filterData?.projectInfo}
                    sx={{ pr: 1 }}
                    label={"Project *"}
                    handelChange={(e) => handelSelect(e, "Project")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.jobList}
                    labelName={(option) => option.jobCode}
                    defaultValue={filterData?.jobInfo}
                    value={filterData?.jobInfo}
                    sx={{ pr: 1 }}
                    label={"Job *"}
                    handelChange={(e) => handelSelect(e, "Job")}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={4} lg={3} >
                                    <SelectComponent DataOptions={selectOptionData?.categoryList}
                                        labelName={(option) => option}
                                        defaultValue={filterData?.categoryInfo}
                                        value={filterData?.categoryInfo} sx={{ pr: 1 }} label={"Select Category"}
                                        handelChange={(e) => handelSelect(e, "categroy")} /></Grid> */}
              {/* <Grid item xs={12} md={4} lg={3} >  <SelectComponent DataOptions={selectOptionData?.taskTypeList}
                                    labelName={(option) => option}
                                    defaultValue={filterData?.tasktypeInfo}
                                    value={filterData?.tasktypeInfo} sx={{ pr: 1 }} label={"Select Task Type"}
                                    handelChange={(e) => handelSelect(e, "tasktype")} /></Grid> */}
              <Grid item xs={12} md={4} lg={3}>
                {" "}
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.status}
                    labelName={(option) => option.label}
                    defaultValue={filterData?.status}
                    value={filterData?.status}
                    sx={{ pr: 1 }}
                    label={"Status"}
                    handelChange={(e) => handelSelect(e, "status")}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={4} lg={3} >  <SelectComponent DataOptions={selectOptionData?.state}
                                    labelName={(option) => option}
                                    defaultValue={filterData?.state}
                                    value={filterData?.state} sx={{ pr: 1 }} label={"Select  State"}
                                    handelChange={(e) => handelSelect(e, "state")} /></Grid> */}
              <Grid item xs={12} md={4} lg={3} sx={{}}>
                <Box sx={{ pt: 0 }}>
                  <DateComponet
                    value={
                      filterData?.startDate
                        ? new Date(
                            moment(filterData?.startDate).format(
                              "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                            )
                          )
                        : null
                    }
                    label={"Assigned (From)"}
                    setValue={setFilterData}
                    handelDate={(e) => {
                      handelDate(e, "start");
                    }}
                  />
                </Box>
                {/* <TextField
                                    label="Assigned Date(From)"
                                    type={"date"}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    sx={{ ...textFieldsStyle(), ...allpageFontStyle.dateField }}
                                    onChange={(e) => handelDate(e, "start")}
                                    value={filterData?.startDate}
                                    InputLabelProps={{ shrink: true }}
                                /> */}
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <DateComponet
                  value={
                    filterData?.endDate
                      ? new Date(
                          moment(filterData?.endDate).format(
                            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                          )
                        )
                      : null
                  }
                  label={"Assigned (To)"}
                  setValue={setFilterData}
                  minDate={
                    new Date(moment(filterData.startDate).format("YYYY-MM-DD"))
                  }
                  handelDate={(e) => {
                    handelDate(e, "end");
                  }}
                />
                {/* <TextField
                                    label="Assigned Date(To)"
                                    type={"date"}
                                    fullWidth
                                    size="small"
                                    // helperText={filterData?.startDate&&bandeldateValidation()}
                                    // error={filterData?.startDate&&bandeldateValidation()}
                                    variant="outlined"
                                    inputProps={{
                                        min: moment(new Date(filterData?.startDate)).format(
                                            "YYYY-MM-DD"
                                        ),
                                    }}
                                    sx={{ ...textFieldsStyle(), ...allpageFontStyle.dateField }}
                                    onChange={(e) => handelDate(e, "end")}
                                    value={filterData?.endDate}
                                    InputLabelProps={{ shrink: true }}
                                /> */}
              </Grid>

              <Grid item xs={12} md={4} lg={6}>
                {" "}
                <Box sx={{ pt: 1 }}>
                  <SelectComponent
                    DataOptions={selectOptionData?.userList}
                    labelName={(option) =>
                      option?.firstname + " " + option?.lastname
                    }
                    defaultValue={filterData?.userInfo}
                    multiple={true}
                    filterSelectedOptions={true}
                    value={filterData?.userInfo}
                    sx={{ pr: 1 }}
                    label={"User"}
                    handelChange={(e) => handelSelect(e, "userList")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={1}>
                <Box sx={{ pt: 1 }}>
                  <Button
                    sx={{
                      ml: 2,
                      mt: 0.2,
                      borderRadius: 5,
                      ...allpageFontStyle.buttonFontStyle2,
                    }}
                    onClick={() => loadData()}
                    size="small"
                    disabled={!filterData.projectInfo || !filterData.jobInfo}
                    variant="contained"
                  >
                    Load
                  </Button>
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={4} lg={1}>
                                    <Button
                                        sx={{ ml: 2, borderRadius: 5 }}
                                        onClick={() => clearData()}
                                        variant="contained"
                                    >
                                        Refresh
                                    </Button>
                                </Grid> */}
            </Grid>
          </Box>

          <Box sx={{}}>
            <DataGride
              columns={col}
              // edit={true}
              hideDeletion={true}
              tableData={taskList || []}
              disableCheckbox={true}
              customHeight={450}
            />
          </Box>
        </Box>
        <Dialog sx={{}} open={Boolean(open)} onClose={() => setOpen(false)}>
          <DialogTitle>
            {/* {payload.type === "Approve" ? <Box>Approve</Box> : <Box>Reject</Box>} */}
          </DialogTitle>
          <DialogContent sx={{ p: 4 }}>
            {payload.type === "Approve" ? (
              <Box>
                {"Are you sure you want to revert " + open?.taskId + " ?"}
              </Box>
            ) : (
              <Box sx={{ pt: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                  {"Give your remarks to reject  " + open?.taskId + " ?"}
                </Box>
                <TextField
                  value={payload.remarks}
                  label={"Remarks"}
                  multiline
                  fullWidth
                  rows={2}
                  onChange={(e) => {
                    setpayload({ ...payload, remarks: e.target.value });
                  }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ display: "flex" }}>
              {payload.type === "Approve" ? (
                <Box sx={{ pr: 2 }}>
                  <Button
                    sx={{ borderRadius: 5 }}
                    // disabled={!payload?.remarks}
                    onClick={() => handleSubmit("Approve")}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    yes
                  </Button>
                </Box>
              ) : (
                <Box sx={{ pr: 2 }}>
                  <Button
                    sx={{ borderRadius: 5 }}
                    disabled={!payload?.remarks}
                    onClick={() => handleSubmit("Reject")}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Reject
                  </Button>
                </Box>
              )}
              <Box>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setpayload({ ...payload, remarks: "" });
                  }}
                  sx={{ borderRadius: 5 }}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
}

export default TaskRevert;
