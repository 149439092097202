import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { generalTaskHistoryAction } from "../../redux/action/action";
import { allpageFontStyle } from "../../utils/FontStyle";

export default function GeneralHistory() {
    const dispatch = useDispatch()
    const [payload, setPayload] = useState({

        fromDate: "",
        toDate: ""
    })
    const handleLoad = () => {
        if (payload.fromDate && payload.toDate) {
            // setPayload({ ...payload, isDateRange: true })
            dispatch(generalTaskHistoryAction({ ...payload, isDateRange: true }))
        } else dispatch(generalTaskHistoryAction({ ...payload, isDateRange: false }))
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <TextField
                    sx={{ mx: 5 }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={payload.fromDate}
                    onChange={(e) => { setPayload({ ...payload, fromDate: e.target.value }) }}
                    type="date"
                    id="outlined-basic"
                    label="From Date"
                    variant="outlined"
                />
                <TextField
                    sx={{ mx: 5 }}
                    size='small'
                    value={payload.toDate}
                    onChange={(e) => { setPayload({ ...payload, toDate: e.target.value }) }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    id="outlined-basic"
                    label="To Date"
                    variant="outlined"
                />
                <Button size="small" sx={{ borderRadius: 5, p: 0, ...allpageFontStyle.buttonFontStyle2, }} variant="contained" onClick={handleLoad}>Load</Button>
            </Box>
        </Box>
    );
}
