import { yupResolver } from "@hookform/resolvers/yup";
import { BorderColor, Done, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";
import { departmentMasterAction } from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import {
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
} from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { PopupMessage, checkBoxFilter } from "../../utils/UIUtil";
import PopupComponent from "../../components/commonComponents/PopupComponent";
const schema = yup.object().shape({
  depId: yup.string(),
  depName: yup.string().required("Name Required"),
  // codeName: yup.string().required("Code Required"),
  shortName: yup.string().required("Short Name Required"),
  description: yup.string().nullable(),
});
export default function Department() {
  const [edit, setEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [editpage, setEditPage] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [constructData, setConstructData] = React.useState([]);
  const [isActive, setIsActive] = React.useState(true);
  const { depsaveData, globapOrganization, userProfile } = useSelector(
    (state) => state.presist
  );
  const { departmentList, departmentAutoId, departmentcodeCheck } = useSelector(
    (state) => state.master
  );
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setEditPage(false);
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      setEditPage(false);
      setEdit(false);
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/${
          e ? "?isActive=True" : "?isActive=False"
        }`
      )
    );
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          departmentMasterAction(
            { id: [row?.id] },
            "put",
            `/department/info/${globapOrganization?.orgId}/`
          )
        );
        handelActiveInactive(false);
      }
    });
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          departmentMasterAction(
            { id: row },
            "delete",
            `/department/info/${globapOrganization?.orgId}/`
          )
        );
        dispatch(
          departmentMasterAction(
            "",
            "get",
            `/department/info/${globapOrganization?.orgId}/${
              isActive ? "?isActive=True" : "?isActive=False"
            }`
          )
        );
      }
    });
  };
  const handleEditClick = (row, data) => {
    if (data === "edit") {
      setEditPage(true);
      setSelectedRow(row);
      setEdit(true);
    }

    setValue(
      "depId",
      data === "edit" && row?.depId ? row?.depId : departmentAutoId?.id
    );
    setValue("depName", row?.depName);
    setValue("shortName", row?.shortName);
    // setValue("codeName", row?.codeName);
    setValue("description", row?.description);
  };
  const handelClose = () => {
    // setEditPage(false)
    dispatch(updatePresistStates({ name: "depsaveData", value: {} }));
    // dispatch(
    //   getAutoService(
    //     `/OgaiApp/max/department/id/${globapOrganization?.orgId}/`,
    //     "departmentAutoId"
    //   )
    // );
    dispatch(
      updateMasterStates({
        name: "departmentcodeCheck",
        value: "",
      })
    );
    setSelectedFile([]);
    setEdit(false);
    reset();
  };
  const branchListConstruct = (arr) => {
    const res = arr.map((val) => ({
      createUserInfo: val.createUserInfo,
      updatedUserInfo: val.updatedUserInfo,
      ...val.departmentInfo,
    }));
    return res;
  };
  React.useEffect(() => {
    if (departmentList && departmentList.length > 0) {
      const constructDatavalue = branchListConstruct(departmentList);
      setConstructData(constructDatavalue);
    } else {
      setConstructData([]);
    }
  }, [departmentList]);
  React.useEffect(() => {
    handleEditClick(depsaveData, "no");
  }, []);
  React.useEffect(() => {
    handleEditClick(depsaveData, "no");
  }, [departmentAutoId]);
  React.useEffect(() => {
    return () => {
      dispatch(
        updatePresistStates({ name: "depsaveData", value: getValues() })
      );
      // dispatch(
      //   updateMasterStates({
      //     name: "departmentcodeCheck",
      //     value: "",
      //   })
      // );
    };
  }, []);
  const columns = [
    {
      field: "depName",
      headerName: "Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "codeName",
    //   headerName: "DEPARTMENT CODE",
    //   width: 250,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "shortName",
      headerName: "Short Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 250,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },

    // { field: "Action", headerName: "Action", width: 200, flex: 1 },
    // { field: "date", headerName: "CREATED DATE", width: 200 },
    // { field: "status", headerName: "STATUS", width: 300, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        allowedPages().settings.department.edit && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        !isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),
        // <GridActionsCellItem
        //   icon={<Delete />}
        //   label="Delete"
        //   onClick={() => {
        //     handleDeleteClick(params.row);
        //   }}
        //   color="error"
        // />,
      ],
    },
  ];
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      depId: "",
      depName: "",
      // codeName: "",
      shortName: "",
      description: "",
      orgId: "ORG00001",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;

  const onSubmit = async (data) => {
    delete data.depId;
    // delete data.depCode;
    data.orgId = globapOrganization?.orgId;

    // if (!departmentcodeCheck) {
    if (edit) {
      const editData = {
        id: selectedRow.id,
        depId: selectedRow.depId,
        orgId: data.orgId,
        depName: data.depName,
        shortName: data.shortName,
        // codeName: data.codeName,
        description: data.description,
        createdBy: selectedRow.createdBy,
        updatedBy: userProfile?.useAccess?.userName,
        createdOn: selectedRow.createdOn,
        updatedOn: selectedRow.updatedOn,
      };
      // setEditPage(false)
      await dispatch(
        departmentMasterAction(
          editData,
          "patch",
          `/department/info/${globapOrganization?.orgId}/`
        )
      );

      dispatch(
        departmentMasterAction(
          "",
          "get",
          `/department/info/${globapOrganization?.orgId}/${
            isActive ? "?isActive=True" : "?isActive=False"
          }`
        )
      );
      reset();
      dispatch(updatePresistStates({ name: "depsaveData", value: {} }));

      setEdit(false);
    } else {
      await dispatch(
        departmentMasterAction(
          data,
          "post",
          `/department/info/${globapOrganization?.orgId}/`
        )
      );

      dispatch(
        departmentMasterAction(
          "",
          "get",
          `/department/info/${globapOrganization?.orgId}/${
            isActive ? "?isActive=True" : "?isActive=False"
          }`
        )
      );
      reset();
      dispatch(updatePresistStates({ name: "depsaveData", value: {} }));
    }
    // }
  };

  React.useEffect(() => {
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/${
          isActive ? "?isActive=True" : "?isActive=False"
        }`
      )
    );
  }, [globapOrganization]);
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };

  // const handelCodeChange = async (e) => {
  //   setValue("codeName", e.target.value.toUpperCase());

  //   if (e.target.value.length > 2) {
  //     try {
  //       await dispatch(
  //         codeCheckService(
  //           { codeName: e.target.value },
  //           `/department/code/name/${globapOrganization?.orgId}/`,
  //           "departmentcodeCheck"
  //         )
  //       ).then((response) => {
  //         if (response.data) {
  //           dispatch(
  //             updateMasterStates({
  //               name: "departmentcodeCheck",
  //               value: response.data,
  //             })
  //           );
  //         } else {
  //           dispatch(
  //             updateMasterStates({
  //               name: "departmentcodeCheck",
  //               value: "",
  //             })
  //           );
  //         }
  //       });
  //     } catch (error) {
  //     }
  //   }
  // };

  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formContainerStyle}>
          <Box sx={{ width: "100%" }}>
            <Card sx={formContainerCardStyle}>
              {!editpage ? (
                <Box
                  sx={{
                    pt: 5,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h4 style={allpageFontStyle.title}>LIST OF DEPARTMENT</h4>
                    {allowedPages().settings.department.create && (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          setEditPage(true);
                          reset();
                        }}
                        sx={{
                          ...allpageFontStyle.addButtonFont.buttonHeight,
                          ...allpageFontStyle.buttonFontStyle1,
                          fontWeight: "bold",
                          // backgroundColor: "#43a5fc",
                          borderRadius: 5,
                          color: "#358c4e",
                          p: 0,
                          px: 2,
                          py: 0,
                          ":hover": {
                            backgroundColor: "#358c4e",
                            color: "white",
                            borderColor: "#358c4e",
                          },
                          textTransform: "capitalize",
                          borderColor: "#358c4e",
                        }}
                      >
                        <AddIcon
                          sx={{
                            mr: 0.5,
                            ...allpageFontStyle.addButtonFont.font,
                            p: 0,
                          }}
                        />
                        <Typography
                          sx={{ ...allpageFontStyle.addButtonFont.font }}
                        >
                          Create Department
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      mb: 5,
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <DataGride
                      columns={columns}
                      selectionIs={selectionIs}
                      customHeight={450}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      deleteIcon={isActive ? true : false}
                      tableData={constructData || []}
                      hideDeletion={!allowedPages().settings.department.delete}
                      InActiveFilterCheckBox={() =>
                        checkBoxFilter(
                          isActive,
                          setIsActive,
                          "Active",
                          "Inactive",
                          handelActiveInactive
                        )
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // px: 5,
                    }}
                  >
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Box
                          sx={{
                            m: 2,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Edit
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            m: 2,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Add
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.listType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.listType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{ pt: 5, display: "flex", justifyContent: "center" }}
                    >
                      <Grid container spacing={2} sx={{ width: "70%" }}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="depName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "70%",
                                }}
                                label="Name*"
                                size="small"
                                helperText={
                                  errors.depName
                                    ? errors?.depName?.message
                                    : null
                                }
                                error={errors?.depName?.message}
                                onChange={(e) => {
                                  trigger("depName");
                                  onChange(e);
                                }}
                                onBlur={() => trigger("depName")}
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Name" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="codeName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "70%",
                                }}
                                label="Code *"
                                onChange={(e) => {
                                  trigger("codeName");
                                  onChange(e);
                                  handelCodeChange(e);
                                }}
                                size="small"
                                helperText={
                                  errors?.codeName
                                    ? errors?.codeName?.message
                                    : departmentcodeCheck
                                      ? "Code Already In Use"
                                      : null
                                }
                                error={errors?.codeName || departmentcodeCheck}
                                onBlur={() => trigger("codeName")}
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Code" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="shortName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "70%",
                                }}
                                label="Short Name *"
                                size="small"
                                helperText={
                                  errors?.shortName
                                    ? errors.shortName.message
                                    : null
                                }
                                error={errors?.shortName?.message}
                                onChange={(e) => {
                                  trigger("shortName");
                                  onChange(e);
                                }}
                                onBlur={() => trigger("shortName")}
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Short Name" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="description"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                label="Description"
                                sx={{
                                  width: "70%",
                                }}
                                multiline
                                helperText={
                                  <Box sx={{ color: "gray", fontSize: 10 }}>
                                    {value === ""
                                      ? "Max 500 characters"
                                      : 500 -
                                        value.length +
                                        " " +
                                        "Characters remaining"}
                                  </Box>
                                }
                                rows={3}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "description",
                                  maxLength: 500,
                                }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  {edit ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        type="submit"
                        // onClick={onSubmit}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#258d4d",

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        UPDATE
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      {allowedPages().settings.department.create ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          // onClick={onSubmit}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#258d4d",
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                            borderRadius: 5,
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </form>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
