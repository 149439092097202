import { Chat } from "@mui/icons-material";
import { Box } from "@mui/system";
import Cookies from "universal-cookie";
import store from "../../redux/store";
import { Tooltip } from "@mui/material";

const ChatAppIcon = () => {
    const openUrlInTab = (url, windowName) => {
        // Try to find an existing window with the specified name
        const existingWindow = window.open("", windowName);

        if (existingWindow) {
            // If the window is found, navigate to the URL and bring it to the front
            existingWindow.location.href = url;
            existingWindow.focus();
        } else {
            // If the window is not found, open a new window with the specified name
            window.open(url, "_top");
        }
    };

    const openChat = () => {
        // window.open(process.env.REACT_APP_CHAT_LINK)
        const cookies = new Cookies();
        const { authToken } = store.getState().presist;
        cookies.set("token", authToken);
        const urlToOpen = process.env.REACT_APP_CHAT_LINK;
        const windowName = "ADHANAM_CHAT"; // Specify a unique name for your window

        openUrlInTab(urlToOpen, windowName);
    };
    return (
        <Tooltip title="Chat">
            <Chat
                fontSize="small"
                onClick={openChat}
                sx={{ color: "black", cursor: "pointer" }}
            />
        </Tooltip>
    );
};

export default ChatAppIcon;
