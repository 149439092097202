import { useEffect, useState } from "react";
import { getUsersMappedGeneralTaskAction } from "../../service/action/action";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { textFieldsStyle } from "../../utils/Style"

const SelectGeneralTask = ({ selectedTask, setSelectedTask, runningGeneralTask }) => {
    const [generaltaskList, setGeneralTaskList] = useState([])
    useEffect(() => {
        getUsersMappedGeneralTaskAction().then(res => {
            if (res?.data?.data) setGeneralTaskList(res?.data?.data)
        })
    }, []);
    return (
        <Autocomplete
            fullWidth
            id="select-type"
            options={generaltaskList}
            getOptionLabel={(option) => option.genTaskName}
            getOptionDisabled={(option) => option?.genTaskId === runningGeneralTask?.genTaskId}
            onChange={(e, v) => {
                setSelectedTask(v)
            }}
            value={selectedTask}
            renderOption={(e, option) => <Typography {...e} sx={{ fontFamily: 'Lato', fontSize: 12, }}>{option.genTaskName}</Typography>}
            size="small"
            inputProps={{
                "aria-label": "Project Code",
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="General Task"
                    InputLabelProps={{
                        shrink: true
                    }}
                    sx={{ ...textFieldsStyle() }}
                />
            )}
        />
    )
}

export default SelectGeneralTask;