import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  circularProgressClasses,
  linearProgressClasses,
  styled,
  tableCellClasses,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import {
  taskStatusReportColor,
  taskStatusReportColorFontColor,
} from "../../../utils/CommonColors";
import "./TaskStatusReport.css";
import { allpageFontStyle } from "../../../utils/FontStyle";
import SkeletonColor from "../../../utils/Loading";
import LottiePlayer from "../../../components/commonComponents/LottiePlayer";
import taskanimation from "../../../images/json/taskstatusreport.json";
import DataGride from "../../../components/datagride/DataGride";
import StatusView from "./statusView";
import ColorCode from "./colorCode";
import OverlayLoader from "../../../components/commonComponents/Overlayloading";
// import { gray } from 'd3-color'

function TaskStatusReport(props) {
  const { tableData, columns, columnsRealData, loading } = props;
  const containerRef = useRef()
  console.log(containerRef?.current?.clientHeight, 'sdchubswswuiebicf')
  console.log(tableData, 'sdchubswswuiebicf123')
  // const BACKCOLOR = "green"
  const fontTypo = {
    fontFamily: "Lato",
    // fontWeight: 'bold'
    ...allpageFontStyle.typography2,
  };
  const [columnData, setColumnData] = useState([])
  useEffect(() => {
    if (columnsRealData) {
      const constructedColumn = columnsRealData?.map(i => ({
        ...i,
        field: i?.key,
        renderCell: (params) => params?.row?.[i.key]?.status ?
          <StatusView data={params?.row[i.key]} status={params?.row?.[i.key]?.status} /> : params?.row?.[i.key],
      }))
      const SNoColumn = {
        field: 'serialNo',
        key: 'SN',
        headerName: 'S.No',
        minWidth: 10,
        width: 10,
        renderCell: (params) => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
      };
      const finalCol = [SNoColumn, ...constructedColumn]
      const findObjectIndex = finalCol?.findIndex(i => i.type === "Object")
      if (findObjectIndex >= 0) {
        const keyName = finalCol[findObjectIndex]?.key
        const typeColumn =
        [
            {
              headerName:  'Count',
              findUnitTypeColumn: true,
              key: keyName,
              valueGetter: (params) => params?.row?.[keyName]?.measureCount,
              noFlex:true
    
            },
            {
              headerName: 'Type',
              key: 'category',
              field: 'category',
              minWidth: 50,
              width: 50,
              noFlex:true,
              // flex:1
            },
          ]
        finalCol?.splice(findObjectIndex , 0, ...typeColumn)
      }
      console.log(columnsRealData, 'columnsRealDatacolumnsRealDatacolumnsRealData')
      constructedColumn?.length && setColumnData([...finalCol])
    }
  }, [columnsRealData])
  const labelStyle = {
    ...allpageFontStyle.taskStatusTypography,
    // fontWeight: 'bold',
  };
  const valueStyle = {
    ...allpageFontStyle.taskStatusTypography,
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };
  console.log(columns, "columns");
  const BorderLinearProgress = styled(CircularProgress)(({ theme }) => ({
    height: 6,
    width: 130,
    borderRadius: "50%",
    [`&.${circularProgressClasses.colorPrimary}`]: {
      backgroundColor: "gray",
      color: "blue",
      // borderColor: "black"
    },
  }));

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <Box
      sx={{ height: '100%' }}
    >
      {/* <TableContainer sx={{ maxHeight: 450, height: 450 }} component={Paper}>
                <Table sx={{ minWidth: 450, border: '1px solid lightgray' }} size='small' stickyHeader aria-label="sticky table">
                    <TableHead  >
                        {loading ? (<TableRowsLoader rowsNum={1} />) : (
                            <TableRow sx={{}}>

                                {columnsRealData && columnsRealData?.length > 0 && columnsRealData.map((val, g) => (
                                    <TableCell
                                        sx={{ backgroundColor: "lightgray" }}
                                    // align={val?.type === "Object" ? "center" : "left"}
                                    >{val.headerName}</TableCell>
                                ))}

                            </TableRow>
                        )
                        }
                    </TableHead>
                    <TableBody>
                        {loading ? (<TableRowsLoader rowsNum={12} />) : (
                            tableData && tableData?.length > 0 && tableData.map((val, i) => (
                                <TableRow sx={{
                                    "&:MuiTableRow-root": {
                                        height: 30
                                    }
                                }}>
                                    {columnsRealData && columnsRealData?.length > 0 && columnsRealData.map((el, j) => (
                                        <TableCell
                                            sx={{}}
                                        // align={el?.type === "Object" ? "center" : "left"}
                                        >
                                            <Box key={j}>
                                                <Typography variant='body2' sx={{ ...fontTypo, fontSize: 11, pt: 0.5, }}>
                                                    {(el?.type === "Object" && (val[el?.key]?.status === "Assigned" ||
                                                        val[el?.key]?.status === "Open" ||
                                                        val[el?.key]?.status === "In-Progress" ||
                                                        val[el?.key]?.status === "Revert Requested" ||
                                                        val[el?.key]?.status === "End")) ?
                                                        <>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                // flexWrap: 'wrap'
                                                                // justifyContent: 'center',
                                                            }}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: val[el?.key]?.status === "Assigned" ? taskStatusReportColorFontColor.Assigned :
                                                                        val[el?.key]?.status === "Open" ? taskStatusReportColorFontColor.Open :
                                                                            val[el?.key]?.status === "In-Progress" ? taskStatusReportColorFontColor.Progress :
                                                                                val[el?.key]?.status === "Revert Requested" ? taskStatusReportColorFontColor.Revert :
                                                                                    val[el?.key]?.status === "End" ? taskStatusReportColorFontColor.End : "black",
                                                                    backgroundColor:
                                                                        val[el?.key]?.status === "Assigned" ? taskStatusReportColor.Assigned :
                                                                            val[el?.key]?.status === "Open" ? taskStatusReportColor.Open :
                                                                                val[el?.key]?.status === "In-Progress" ? taskStatusReportColor.Progress :
                                                                                    val[el?.key]?.status === "Revert Requested" ? taskStatusReportColor.Revert :
                                                                                        val[el?.key]?.status === "End" ? taskStatusReportColor.End : "red",
                                                                    p: 0.5,
                                                                    // width: "50%",
                                                                }}>

                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', pr: 1 }}>
                                                                        <Box sx={{ width: 160, fontSize: 10, whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center', overflow: 'hidden' }}>
                                                                            <span style={{ fontWeight: 'bold', letterSpacing: '1px' }} title={val[el?.key]?.assignedTo}> {`${val[el?.key]?.assignedTo}`}</span>
                                                                        </Box>
                                                                        <Box sx={{ pt: 0.8, display: 'flex', justifyContent: 'center' }}><BorderLinearProgress sx={{
                                                                            [`& .${linearProgressClasses.bar}`]: {
                                                                                borderRadius: 5,
                                                                                backgroundColor: "#4c566a"
                                                                            },
                                                                        }} variant="determinate" value={(+val[el?.key]?.completedCount / +val[el?.key]?.measureCount) * 100} /></Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ width: 70 }}>
                                                                    <Box sx={{
                                                                        fontWeight: 'bold',
                                                                        color: "white",
                                                                        whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center', overflow: 'hidden',
                                                                        // display: 'flex',
                                                                        // justifyContent: 'center',
                                                                        // alignItems: 'center',
                                                                        pt: 1,
                                                                        width: 70,
                                                                        height: 40,
                                                                        fontSize: 12,
                                                                        backgroundColor: "#4c566a",
                                                                    }}>
                                                                        {val[el?.key]?.completedCount || 0}{" "}<span style={{ fontWeight: 700, color: 'white' }}>{"  /  "}</span>{" "} {val[el?.key]?.measureCount || 0}
                                                                    </Box>
                                                                </Box>
                                                            </Box>

                                                        </>
                                                        :
                                                        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} title={val[el?.key]}>{val[el?.key]}</span>
                                                    }
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer> */}
      <Box ref={containerRef} sx={{ height: '95%', maxHeight: containerRef?.current?.clientHeight || '95%' }}>
        <DataGride
          columns={columnData || []}
          tableData={tableData}
          hideDeletion={true}
          customHeight={'100%'}
          getRowId={(row) => row?.Id}
          disableCheckbox
          columnHeaderHeight={40}
          hideBackground
          isTaskReportPage
          hidePagination
        />
        <ColorCode />
      </Box>
      {/* {loading ? (
        <LottiePlayer height={200} animationData={taskanimation} />
      ) : (
        <div className="containerstatustable">
          <table className="statustable">
            <thead className="shead">
              <tr className="sheadrow">
                {columnsRealData &&
                  columnsRealData?.length > 0 &&
                  columnsRealData.map((val, g) => (
                    <th className="sh">{val.headerName}</th>
                  ))}
              </tr>
            </thead>
            <tbody className="sbody">
              {tableData &&
                tableData?.length > 0 &&
                tableData.map((val, i) => (
                  <tr className="sbodyrow">
                    {columnsRealData &&
                      columnsRealData?.length > 0 &&
                      columnsRealData.map((el, j) => (
                        <td
                          className="std"
                          style={{
                            backgroundColor:
                              el?.type === "Object" &&
                                (val[el?.key]?.status === "Assigned" ||
                                  val[el?.key]?.status === "Open" ||
                                  val[el?.key]?.status === "In-Progress" ||
                                  val[el?.key]?.status === "Revert Requested" ||
                                  val[el?.key]?.status === "End")
                                ? val[el?.key]?.status === "Assigned"
                                  ? taskStatusReportColor.Assigned
                                  : val[el?.key]?.status === "Open"
                                    ? taskStatusReportColor.Open
                                    : val[el?.key]?.status === "In-Progress"
                                      ? taskStatusReportColor.Progress
                                      : val[el?.key]?.status === "Revert Requested"
                                        ? taskStatusReportColor.Revert
                                        : val[el?.key]?.status === "End"
                                          ? taskStatusReportColor.End
                                          : "red"
                                : "",
                          }}
                        >
                          <Box key={j}>
                            <Typography variant="body2" sx={{ ...fontTypo }}>
                              {el?.type === "Object" &&
                                (val[el?.key]?.status === "Assigned" ||
                                  val[el?.key]?.status === "Open" ||
                                  val[el?.key]?.status === "In-Progress" ||
                                  val[el?.key]?.status === "Revert Requested" ||
                                  val[el?.key]?.status === "End") ? (
                                <>
                                  <Box
                                  >
                                    <Box
                                      sx={{
                                        color:
                                          val[el?.key]?.status === "Assigned"
                                            ? taskStatusReportColorFontColor.Assigned
                                            : val[el?.key]?.status === "Open"
                                              ? taskStatusReportColorFontColor.Open
                                              : val[el?.key]?.status ===
                                                "In-Progress"
                                                ? taskStatusReportColorFontColor.Progress
                                                : val[el?.key]?.status ===
                                                  "Revert Requested"
                                                  ? taskStatusReportColorFontColor.Revert
                                                  : val[el?.key]?.status === "End"
                                                    ? taskStatusReportColorFontColor.End
                                                    : "black",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box sx={{}}>
                                          <Typography sx={labelStyle}>
                                            Status:
                                          </Typography>
                                          <Typography sx={labelStyle}>
                                            Assigned to :
                                          </Typography>
                                          <Typography sx={labelStyle}>
                                            Completed/Total pages:
                                          </Typography>
                                        </Box>

                                        <Box sx={{}}>
                                          <Typography sx={valueStyle}>
                                            {val[el?.key]?.status}
                                          </Typography>
                                          <Typography
                                            sx={valueStyle}
                                            title={val[el?.key]?.assignedTo}
                                          >
                                            {val[el?.key]?.assignedTo}
                                          </Typography>
                                          <Typography sx={valueStyle}>
                                            {val[el?.key]?.completedCount || 0}{" "}
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                color: "white",
                                              }}
                                            >
                                              {"  /  "}
                                            </span>{" "}
                                            {val[el?.key]?.measureCount || 0}
                                          </Typography>
                                        </Box>

                                        <Box sx={{}}>
                                          <CircularProgress
                                            variant="soft"
                                            size="md"
                                            sx={{ color: "white" }}
                                            determinate
                                            value={
                                              (+val[el?.key]?.completedCount /
                                                +val[el?.key]?.measureCount) *
                                              100
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: 400,
                                  }}
                                  title={val[el?.key]}
                                >
                                  {val[el?.key]}
                                </span>
                              )}
                            </Typography>
                          </Box>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )} */}
      <OverlayLoader loading={loading} />
    </Box>
  );
}

export default TaskStatusReport;
