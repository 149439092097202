import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { formFieldStyle } from '../../../utils/Style'
import SelectComponent from '../../../components/commonComponents/SelectComponent'
import { jobPageColor } from '../../../utils/CommonColors'

function JobSLA(props) {
    const { userList, view } = props
    const MINTS_LABEL = "Minutes"
    const HOURS_LABEL = "Hours"
    const DAYS_LABEL = "Days"
    const SLA_LEVEL = "Level 1"
    const SLA_LEVEL2 = "Level 2"
    const MINTS = Array(60).fill().map((_, index) => index);
    const HOURS = Array(24).fill().map((_, index) => index);
    const selectStyle = {
        ".MuiInputBase-input": {
            height: 12,

        }
    }
    const headLabelStyle = {
        textAlign: "center",
        fontFamily: "Lato",
        fontSize: 13,
        // py: 0,
        // px: 0,
        // textDecoration: "underline"
    }
    const hrStyle = {


    }
    const labelContainer = {
        pb: 1
    }
    const SideLabelStyle = {
        fontSize: 12,
        fontFamily: "Lato",
    }
    const errorFont = {
        fontSize: "10px",
        pb: 1,
        fontFamily: "Lato",
        textAlign: 'end'
    }
    const fieldWidth = {
        width: '100%'
    }
    const { setValue, control, getValues, trigger, formState: { errors } } = useFormContext()
    const scrollingContainerRef = useRef(null);
    const autoScrollToBottom = () => {
        if (scrollingContainerRef.current) {
            scrollingContainerRef.current.scrollIntoView();
        }
    };
    console.log(getValues(), errors, "hi")
    const errorMessage = {
        highLevel1: errors?.high?.level1 || errors?.high?.level1?.root ? errors.high?.level1?.message || errors.high?.level1?.root?.message : false,
        highLevel2: errors?.high?.level2 || errors?.high?.level2?.root ? errors.high?.level2?.message || errors.high?.level2?.root?.message : false,
        mediumLevel1: errors?.medium?.level1 || errors?.medium?.level1?.root ? errors.medium?.level1?.message || errors.medium?.level1?.root?.message : false,
        mediumLevel2: errors?.medium?.level2 || errors?.medium?.level2?.root ? errors.medium?.level2?.message || errors.medium?.level2?.root?.message : false,
        lowLevel1: errors?.low?.level1 || errors?.low?.level1?.root ? errors.low?.level1?.message || errors.low?.level1?.root?.message : false,
        lowLevel2: errors?.low?.level2 || errors?.low?.level2?.root ? errors.low?.level2?.message || errors.low?.level2?.root?.message : false

    }
    const legendStyle = {
        fontFamily: 'Lato',
        padding: "1px 20px",
        fontSize: 12,
        borderRadius: 6,
    }
    const fieldSetStyle = {
        height: 110,
        borderRadius: 5,
        marginTop: 4
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', pb: 0 }}>
                <Controller
                    control={control}
                    name={`slaInfo`}
                    render={({
                        field: {
                            onChange,
                            onBlur,
                            value,
                            ref,
                        },
                    }) => (
                        <Box
                            sx={{

                                width: "40%",
                            }}
                        >
                            <FormControl
                                error={
                                    errors?.slaInfo ? errors.slaInfo.message : null
                                }
                                size="small"
                                fullWidth
                            // sx={{ pt: 0.5 }}
                            >
                                <SelectComponent
                                    DataOptions={userList || []}
                                    labelName={(option) => option?.firstname + " " + option?.lastname}
                                    errors={errors?.slaInfo ? errors.slaInfo.message : null}
                                    // disabled={view}
                                    defaultValue={value}
                                    multiple={true}
                                    disabled={view}
                                    label={"Notify Users *"}
                                    onBlur={() => trigger("slaInfo")}
                                    filterSelectedOptions={true}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={value}
                                    // ListboxProps={{ style: { maxHeight: 10, overflow: 'auto' } }}
                                    autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                    renderInput={(parmas) => {
                                        const { InputProps, ...restParams } = parmas;
                                        const { startAdornment, ...restInputProps } = InputProps;
                                        return <TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={"Notify Users *"}
                                            sx={{ ...selectStyle }}
                                            helperText={errors?.slaInfo ? errors.slaInfo.message : null}
                                            error={errors?.slaInfo ? errors.slaInfo.message : null}
                                            // InputProps={formFieldStyle.InputProps}
                                            {...restParams}
                                            InputProps={{
                                                ...restInputProps,
                                                startAdornment: (
                                                    <>
                                                        <div style={{
                                                            // height: 30,
                                                            maxHeight: 40,
                                                            width: '90%',
                                                            overflowY: 'auto',
                                                        }}
                                                        >
                                                            {startAdornment}
                                                            <div ref={scrollingContainerRef} style={{ marginBlock: 13 }} >

                                                            </div>
                                                        </div>
                                                    </>

                                                ),
                                            }}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />
                                    }}
                                    sx={{ pr: 1, width: '100%' }}

                                    handelChange={(e) => {
                                        onChange(e);
                                        autoScrollToBottom()
                                        //   handelChangeTrobleShooter(e)
                                        trigger("slaInfo");
                                    }}
                                />
                            </FormControl>
                        </Box>
                    )}
                />
            </Box>
            {/* <Box sx={{ ...labelContainer }}>
                <Box sx={{ ...headLabelStyle, color: jobPageColor.slahigh }}>
                    HIGH
                </Box>
               
            </Box> */}
            <fieldset style={{ border: `1px solid ${jobPageColor.slahigh}`, ...fieldSetStyle }}>
                <legend style={{ color: "white", backgroundColor: jobPageColor.slahigh, ...legendStyle }}>HIGH</legend>
                <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"high.level1.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "high.level1.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("high.level1");
                                            }}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("high.level1")}
                                            label={DAYS_LABEL}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            disabled={view}
                                            inputRef={ref}
                                            // helperText={
                                            //     errors?.high?.level1?.slaEstDays
                                            //         ? errors.high?.level1?.slaEstDays?.message
                                            //         : false
                                            // }
                                            error={errorMessage?.highLevel1}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}>
                            <Controller
                                control={control}
                                name={`high.level1.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.highLevel1}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.highLevel1}
                                            defaultValue={value}
                                            // multiple={true}
                                            label={HOURS_LABEL}
                                            onBlur={() => trigger("high.level1")}
                                            filterSelectedOptions={true}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            value={value}
                                            disabled={view}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.high?.level1 ? errors.high?.level1?.message : false}
                                                error={errorMessage?.highLevel1}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("high.level1");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}> <Controller
                            control={control}
                            name={`high.level1.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (
                                <FormControl
                                    error={errorMessage?.highLevel1}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.highLevel1}
                                        defaultValue={value}
                                        // multiple={true}
                                        label={MINTS_LABEL}
                                        disabled={view}
                                        filterSelectedOptions={true}
                                        onBlur={() => trigger("high.level1")}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        value={value}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.high?.level1?.slaEstMinutes ? errors.high?.level1?.slaEstMinutes?.message : false}
                                            error={errorMessage?.highLevel1}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("high.level1");
                                        }}
                                    />
                                </FormControl>

                            )}
                        /></Box>

                    </Grid>
                    {/* {errors.high?.level1?.message ?
                    <Grid lg={12} md={12} sx={{ p: 0, m: 0 }}>
                        <Typography color={"red"} sx={{ ...errorFont, }}>{errors.high?.level1?.message}</Typography>
                    </Grid> : null} */}
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"high.level2.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "high.level2.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("high.level2");
                                            }}
                                            disabled={view}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("high.level2")}
                                            label={DAYS_LABEL}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            inputRef={ref}
                                            error={errorMessage?.highLevel2}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box >
                            <Controller
                                control={control}
                                name={`high.level2.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.highLevel2}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.highLevel2}
                                            defaultValue={value}
                                            // multiple={true}
                                            label={HOURS_LABEL}
                                            filterSelectedOptions={true}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            onBlur={() => trigger("high.level2")}
                                            value={value}
                                            disabled={view}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.high?.level2?.slaEstHours ? errors.high?.level2?.slaEstHours?.message : false}
                                                error={errorMessage?.highLevel2}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("high.level2");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Controller
                            control={control}
                            name={`high.level2.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (

                                <FormControl
                                    error={errorMessage?.highLevel2}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.highLevel2}
                                        defaultValue={value}
                                        // multiple={true}
                                        onBlur={() => trigger("high.level2")}
                                        label={MINTS_LABEL}
                                        filterSelectedOptions={true}
                                        disabled={view}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        value={value}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.high?.level2?.slaEstMinutes ? errors.high?.level2?.slaEstMinutes?.message : false}
                                            error={errorMessage?.highLevel2}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("high.level2");
                                        }}
                                    />
                                </FormControl>

                            )}
                        />
                    </Grid>

                </Grid>
            </fieldset>
            {/* <Box sx={{ ...labelContainer }}>
                <Box sx={{ ...headLabelStyle, color: jobPageColor.slamedium }}>MEDIUM</Box>
            </Box> */}
            <fieldset style={{ border: `1px solid ${jobPageColor.slamedium}`, ...fieldSetStyle }}>
                <legend style={{ color: "white", backgroundColor: jobPageColor.slamedium, ...legendStyle }}>MEDIUM</legend>
                <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"medium.level1.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "medium.level1.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("medium.level1");
                                            }}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("medium.level1")}
                                            label={DAYS_LABEL}
                                            disabled={view}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            inputRef={ref}
                                            // helperText={
                                            //     errors?.medium?.level1?.slaEstDays
                                            //         ? errors.medium?.level1?.slaEstDays?.message
                                            //         : false
                                            // }
                                            error={errorMessage?.mediumLevel1}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}>
                            <Controller
                                control={control}
                                name={`medium.level1.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.mediumLevel1}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.mediumLevel1}
                                            defaultValue={value}
                                            // multiple={true}
                                            label={HOURS_LABEL}
                                            disabled={view}
                                            filterSelectedOptions={true}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            value={value}
                                            onBlur={() => trigger("medium.level1")}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.medium?.level1 ? errors.medium?.level1?.message : false}
                                                error={errorMessage?.mediumLevel1}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("medium.level1");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}> <Controller
                            control={control}
                            name={`medium.level1.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (
                                <FormControl
                                    error={errorMessage?.mediumLevel1}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.mediumLevel1}
                                        defaultValue={value}
                                        // multiple={true}
                                        label={MINTS_LABEL}
                                        filterSelectedOptions={true}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        value={value}
                                        disabled={view}
                                        onBlur={() => trigger("medium.level1")}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.medium?.level1?.slaEstMinutes ? errors.medium?.level1?.slaEstMinutes?.message : false}
                                            error={errorMessage?.mediumLevel1}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("medium.level1");
                                        }}
                                    />
                                </FormControl>

                            )}
                        /></Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"medium.level2.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "medium.level2.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("medium.level2");
                                            }}
                                            disabled={view}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("medium.level2")}
                                            label={DAYS_LABEL}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            inputRef={ref}
                                            error={errorMessage?.mediumLevel2}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box >
                            <Controller
                                control={control}
                                name={`medium.level2.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.mediumLevel2}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.mediumLevel2}
                                            defaultValue={value}
                                            // multiple={true}
                                            label={HOURS_LABEL}
                                            filterSelectedOptions={true}
                                            onBlur={() => trigger("medium.level2")}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            value={value}
                                            disabled={view}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.medium?.level2?.slaEstHours ? errors.medium?.level2?.slaEstHours?.message : false}
                                                error={errorMessage?.mediumLevel2}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("medium.level2");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Controller
                            control={control}
                            name={`medium.level2.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (

                                <FormControl
                                    error={errorMessage?.mediumLevel2}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.mediumLevel2}
                                        defaultValue={value}
                                        // multiple={true}

                                        label={MINTS_LABEL}
                                        filterSelectedOptions={true}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        value={value}
                                        disabled={view}
                                        onBlur={() => trigger("medium.level2")}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.medium?.level2?.slaEstMinutes ? errors.medium?.level2?.slaEstMinutes?.message : false}
                                            error={errorMessage?.mediumLevel2}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("medium.level2");
                                        }}
                                    />
                                </FormControl>

                            )}
                        />
                    </Grid>
                </Grid>
            </fieldset>
            {/* <Box sx={{ ...labelContainer }}>
                <Box sx={{ ...headLabelStyle, color: jobPageColor.slalow }}>LOW</Box>
            </Box> */}
            <fieldset style={{ border: `1px solid ${jobPageColor.slalow}`, ...fieldSetStyle }}>
                <legend style={{ color: "white", backgroundColor: jobPageColor.slalow, ...legendStyle }}>LOW</legend>
                <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"low.level1.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "low.level1.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("low.level1");
                                            }}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("low.level1")}
                                            label={DAYS_LABEL}
                                            disabled={view}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            inputRef={ref}
                                            // helperText={
                                            //     errors?.low?.level1?.slaEstDays
                                            //         ? errors.low?.level1?.slaEstDays?.message
                                            //         : false
                                            // }
                                            error={errorMessage?.lowLevel1}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}>
                            <Controller
                                control={control}
                                name={`low.level1.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.lowLevel1}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.lowLevel1}
                                            defaultValue={value}
                                            // multiple={true}
                                            disabled={view}
                                            label={HOURS_LABEL}
                                            onBlur={() => trigger("low.level1")}
                                            filterSelectedOptions={true}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            value={value}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.low?.level1 ? errors.low?.level1?.message : false}
                                                error={errorMessage?.lowLevel1}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("low.level1");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box sx={{ ...fieldWidth }}> <Controller
                            control={control}
                            name={`low.level1.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (
                                <FormControl
                                    error={errorMessage?.lowLevel1}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.lowLevel1}
                                        defaultValue={value}
                                        // multiple={true}
                                        label={MINTS_LABEL}
                                        filterSelectedOptions={true}
                                        disabled={view}
                                        onBlur={() => trigger("low.level1")}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        value={value}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.low?.level1?.slaEstMinutes ? errors.low?.level1?.slaEstMinutes?.message : false}
                                            error={errorMessage?.lowLevel1}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("low.level1");
                                        }}
                                    />
                                </FormControl>

                            )}
                        /></Box>

                    </Grid>
                    {/* {errors.low?.level1?.message ?
                    <Grid lg={12} md={12}>
                        <Typography color={"red"} sx={{ ...errorFont, }}>{errors.low?.level1?.message}</Typography>
                    </Grid> : null} */}
                    <Grid item lg={4} md={4}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name={"low.level2.slaEstDays"}
                                    render={({ field: { onChange, onBlur, value, ref }, }) => (
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                minWidth: '100%',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                ...selectStyle
                                            }}
                                            onChange={(e) => {
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                    e.target.value
                                                );

                                                if (u || e.target.value === "") {
                                                    setValue(
                                                        "low.level2.slaEstDays",
                                                        e.target.value === "" ? null : e.target.value.trim()
                                                    );
                                                }
                                                trigger("low.level2");
                                            }}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                            onBlur={() => trigger("low.level2")}
                                            disabled={view}
                                            label={DAYS_LABEL}
                                            InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                            value={value}
                                            inputRef={ref}
                                            error={errorMessage?.lowLevel2}
                                            size="small"
                                        />
                                    )}
                                />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Box >
                            <Controller
                                control={control}
                                name={`low.level2.slaEstHours`}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                    },
                                }) => (
                                    <FormControl
                                        error={errorMessage?.lowLevel2}
                                        size="small"
                                        fullWidth
                                        sx={{}}
                                    >
                                        <SelectComponent
                                            DataOptions={HOURS || []}
                                            labelName={(option) => option.toString()}
                                            error={errorMessage?.lowLevel2}
                                            defaultValue={value}
                                            // multiple={true}
                                            label={HOURS_LABEL}
                                            filterSelectedOptions={true}
                                            onBlur={() => trigger("low.level2")}
                                            // isOptionEqualToValue={(option, value) => option === value}
                                            value={value}
                                            disabled={view}
                                            autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                            renderInput={(parmas) => (<TextField
                                                {...parmas}
                                                variant={"outlined"}
                                                label={HOURS_LABEL}
                                                sx={{ ...selectStyle }}
                                                // helperText={errors?.low?.level2?.slaEstHours ? errors.low?.level2?.slaEstHours?.message : false}
                                                error={errorMessage?.lowLevel2}
                                                // InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />)}
                                            sx={{ pr: 1, width: '100%' }}

                                            handelChange={(e) => {
                                                // trigger("OwnerUserid");
                                                onChange(e);
                                                trigger("low.level2");
                                            }}
                                        />
                                    </FormControl>

                                )}
                            />
                        </Box>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Controller
                            control={control}
                            name={`low.level2.slaEstMinutes`}
                            render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value,
                                    ref,
                                },
                            }) => (

                                <FormControl
                                    error={errorMessage?.lowLevel2}
                                    size="small"
                                    fullWidth
                                    sx={{}}
                                >
                                    <SelectComponent
                                        DataOptions={MINTS || []}
                                        labelName={(option) => option.toString()}
                                        error={errorMessage?.lowLevel2}
                                        defaultValue={value}
                                        // multiple={true}

                                        label={MINTS_LABEL}
                                        disabled={view}
                                        filterSelectedOptions={true}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        onBlur={() => trigger("low.level1")}
                                        value={value}
                                        autoStyle={{ '& fieldset': { borderRadius: 3 }, }}
                                        renderInput={(parmas) => (<TextField
                                            {...parmas}
                                            variant={"outlined"}
                                            label={MINTS_LABEL}
                                            sx={{ ...selectStyle }}
                                            // helperText={errors?.low?.level2?.slaEstMinutes ? errors.low?.level2?.slaEstMinutes?.message : false}
                                            error={errorMessage?.lowLevel2}
                                            // InputProps={formFieldStyle.InputProps}
                                            InputLabelProps={formFieldStyle.InputLabelProps}
                                        />)}
                                        sx={{ pr: 1, width: '100%' }}

                                        handelChange={(e) => {
                                            // trigger("OwnerUserid");
                                            onChange(e);
                                            trigger("low.level2");
                                        }}
                                    />
                                </FormControl>

                            )}
                        />
                    </Grid>


                </Grid>

            </fieldset>
            {errorMessage.highLevel2 || errorMessage.highLevel1 || errorMessage.lowLevel1 || errorMessage.lowLevel2 || errorMessage.mediumLevel1 || errorMessage.mediumLevel2 ?
                <Grid lg={12} md={12}>
                    <Typography color={"red"} sx={{ ...errorFont, }}>{errors.high?.level2?.message || errors.high?.level2?.root?.message || errors.high?.level1?.message || errors.high?.level1?.root?.message || errors.medium?.level2?.message || errors.medium?.level2?.root?.message
                        || errors.medium?.level1?.message || errors.medium?.level1?.root?.message || errors.low?.level2?.message || errors.low?.level2?.root?.message || errors.low?.level1?.message || errors.low?.level1?.root?.message}</Typography>
                </Grid>
                : null}
        </Box>
    )
}

export default JobSLA