import { Box, Card, Grid, TablePagination, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateTaskAction,
  getDahboardTaskAction,
} from "../../redux/action/action";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import store from "../../redux/store";
import { TriggerAlert, secondsToHMS } from "../../utils/UIUtil";
import {
  TASKRESUME_WARINING_MSG,
  TASKSTART_WARINING_MSG,
} from "../../utils/textName";
import { allpageFontStyle } from "../../utils/FontStyle";
import DataGride, {
  headerBackground,
} from "../../components/datagride/DataGride";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { NotStarted } from "@mui/icons-material";

const TaskCard = ({ handleStart, handleResume }) => {
  const dispatch = useDispatch();
  const [pageInfo, setPageInfo] = useState(null);
  const { runningGeneralTask } = useSelector((state) => state.common);
  const { dashboardTask } = useSelector((state) => state.master);
  const { runingTask, autoLoad } = useSelector((state) => state.presist);
  console.log(dashboardTask, "datsssss");
  useEffect(() => {
    getData();
  }, [runingTask]);
  const getData = () => {
    setPage(0);
    setRowsPerPage(20);
    return dispatch(getDahboardTaskAction(20, 1, setPageInfo));
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(newPage, "pageNo");
  };
  useEffect(() => {
    dispatch(getDahboardTaskAction(rowsPerPage, page + 1, setPageInfo));
    console.log(page, "pagess");
  }, [rowsPerPage, page]);

  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      dispatch(getDahboardTaskAction(rowsPerPage, 1, setPageInfo));
      dispatch(
        updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false } })
      );
    }
  }, [autoLoad]);

  const handleChangeRowsPerPage = (newPageSize) => {
    setRowsPerPage(parseInt(newPageSize.pageSize, 10));
    setPage(newPageSize.page);
  };
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const formatTimeData = () => {
    const timerData = store.getState().presist?.timerData?.timestate;
    const getSeconds = `0${timerData % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };
  const timeStart = async (list) => {
    if (
      runningGeneralTask?.state !== "Started" &&
      runningGeneralTask?.state !== "Updated"
    ) {
      const res = {
        taskId: list?.taskId,
        jobCode: list?.jobCode,
        status: " In-Progress",
        state: "Started",
        remarks: "Task started",
        workHours: "00",
        workMinutes: "00",
        workSeconds: "00",
      };
      const pay = await dispatch(UpdateTaskAction(res));
      if (pay) {
        getData().then((response) => {
          if (response?.data?.data.assignedTaskList?.taskInfo) {
            const res = response?.data?.data.assignedTaskList?.taskInfo;
            const filterthedata = res.find((val) => val.id === list.id);
            dispatch(
              updatePresistStates({
                name: "runingTask",
                value: filterthedata,
              })
            );
          }
        });
        dispatch(
          updatePresistStates({
            name: "timerData",
            value: {
              timestate: 0,
            },
          })
        );
        handleStart();
      }
    } else {
      TriggerAlert(
        "warning",
        TASKSTART_WARINING_MSG(runningGeneralTask?.genTaskName)
      );
    }
  };
  const timeResume = async (list) => {
    if (
      runningGeneralTask?.state !== "Started" &&
      runningGeneralTask?.state !== "Updated"
    ) {
      dispatch(
        updatePresistStates({
          name: "timerData",

          value: {
            timestate: toSeconds(
              Number(list?.workHours),
              Number(list?.workMinutes),
              Number(list?.workSeconds)
            ),
          },
        })
      );
      const res = {
        taskId: list?.taskId,
        jobCode: list?.jobCode,
        status: "In-Progress",
        state: "Resumed",
        remarks: list?.remarks,
        workHours: formatTimeData().H,
        workMinutes: formatTimeData().M,
        workSeconds: formatTimeData().S,
      };
      const pay = await dispatch(UpdateTaskAction(res));
      if (pay) {
        getData().then((response) => {
          if (response?.data?.data.assignedTaskList?.taskInfo) {
            const res = response?.data?.data.assignedTaskList?.taskInfo;
            const filterthedata = res.find((val) => val.id === list.id);
            dispatch(
              updatePresistStates({
                name: "runingTask",
                value: filterthedata,
              })
            );
          }
        });

        handleResume();
      }
    } else {
      TriggerAlert(
        "warning",
        TASKRESUME_WARINING_MSG(runningGeneralTask?.genTaskName)
      );
    }
  };
  console.log(pageInfo, "info");
  const headingStyle = {
    display: "flex",
    justifyContent: "start",
    color: "black",
    ...allpageFontStyle.title,
    alignItems: "center",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    fontSize: { sm: 11, md: 12, lg: 13 },
    px: 1,
  };
  const listStyle = {
    display: "flex",
    justifyContent: "start",
    ...allpageFontStyle.typography1,
    flexWrap: "wrap",
    alignItems: "center",
    pl: 2,
  };
  const OldTable = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 5,
          background: "white",
        }}
      >
        <Card sx={{ px: 1, py: 1, background: headerBackground, mb: 1 }}>
          <Grid container spacing={2} sx={{ px: 5 }}>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              S.No
            </Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ ...headingStyle }}>
              Task Id
            </Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ ...headingStyle }}>
              Type
            </Grid>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              Unit Type
            </Grid>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              Count
            </Grid>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              Est Hrs
            </Grid>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              Worked Hrs
            </Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ ...headingStyle }}>
              Status
            </Grid>
            <Grid item xs={1} md={1} lg={1} sx={{ ...headingStyle }}>
              Action
            </Grid>
          </Grid>
        </Card>
        <Box
          sx={{
            height: 400,
            minHeight: 400,
            maxHeight: 450,
            overflow: "auto",
            flex: 1,
          }}
        >
          {dashboardTask?.map((list, index) => (
            <Card
              onClick={() => console.log(index, "index")}
              key={list?.id}
              sx={{
                p: 0.3,
                background:
                  runingTask?.taskId === list?.taskId &&
                  (list?.state === "Resumed" || list?.state === "Started")
                    ? "#aefca2"
                    : index % 2 === 0
                    ? "white"
                    : "#edf7f1",
                boxShadow: "none",
                borderRadius: 1,
              }}
            >
              <Grid container spacing={2} sx={{ px: 5 }}>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle, pl: 5 }}>
                  {rowsPerPage * page + (index + 1)}
                </Grid>
                <Grid item xs={2} md={2} lg={2} sx={{ ...listStyle }}>
                  {list?.taskId}
                </Grid>
                <Grid item xs={2} md={2} lg={2} sx={{ ...listStyle }}>
                  {list?.taskType}
                </Grid>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle }}>
                  {list?.unitType}
                </Grid>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle }}>
                  {list?.measureCount}
                </Grid>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle }}>
                  {secondsToHMS(+list?.productionHours)}
                </Grid>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle }}>
                  {list?.workHours} : {list?.workMinutes} : {list?.workSeconds}
                </Grid>
                <Grid item xs={2} md={2} lg={2} sx={{ ...listStyle }}>
                  {list?.state}
                </Grid>
                <Grid item xs={1} md={1} lg={1} sx={{ ...listStyle }}>
                  {list?.state === "Assigned" ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title="Start"
                      placement="top"
                    >
                      <PlayArrowIcon
                        onClick={() => timeStart(list)}
                        sx={{ color: "#87c685" }}
                      />
                    </Box>
                  ) : list?.state === "Paused" ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title="Resume"
                      placement="top"
                    >
                      <PlayArrowIcon
                        onClick={() => timeResume(list)}
                        sx={{ color: "#df7257" }}
                      />
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          ))}
        </Box>
        <TablePagination
          labelRowsPerPage=""
          component="div"
          count={pageInfo?.total_items || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage || 0}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 50, 100] || 0}
        />
      </Box>
    );
  };
  const NewTable = () => {
    const columns = [
      {
        field: "taskId",
        headerName: "Task Id",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "taskType",
        headerName: "Type",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "unitType",
        headerName: " Unit Type",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "measureCount",
        headerName: "Count",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "measureCount",
        headerName: "Est Hrs",
        valueGetter: (params) => secondsToHMS(+params?.row?.productionHours),
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "workHours",
        headerName: "Worked Hrs",
        valueGetter: (params) =>
          `${params?.row?.workHours} : ${params?.row?.workMinutes} : ${params?.row?.workSeconds}`,
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "state",
        headerName: "Status",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "measureCount",
        headerName: "Count",
        width: 220,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        cellClassName: "actions",
        headerName: "Action",
        type: "actions",
        flex: 1,
        // renderHeader: () => <p>{"Action"}</p>,
        width: 80,
        minWidth: 100,
        align: "left",
        headerAlign: "left",
        sortable: false,
        getActions: (params) => [
          <>
            {params?.row?.state === "Assigned" ? (
              <GridActionsCellItem
                sx={{ p: 0 }}
                icon={
                  <Tooltip title="Start" placement="right">
                    <Box
                      sx={{
                        p: 0,
                        display: "flex",
                        justifyContent: "center",
                        width: 10,
                      }}
                    >
                      <PlayArrowIcon
                        onClick={() => timeStart(params?.row)}
                        sx={{ color: "#87c685" }}
                      />
                    </Box>
                  </Tooltip>
                }
                label="Start"
                onClick={() => timeStart(params?.row)}
                color="primary"
              />
            ) : (
              <></>
            )}
            {params?.row?.state === "Paused" ? (
              <GridActionsCellItem
                sx={{ p: 0 }}
                // icon={
                //   <Tooltip title="View" placement="right">
                //     <RemoveRedEyeIcon sx={{ color: "#358c4e" }} />
                //   </Tooltip>
                // }
                // label="view"
                // onClick={() => {
                //   handleView(params.row, "view");
                // }}
                icon={
                  <Tooltip title="Resume" placement="right">
                    <Box
                      sx={{
                        p: 0,
                        display: "flex",
                        justifyContent: "center",
                        width: 10,
                      }}
                    >
                      <NotStarted
                        onClick={() => timeResume(params?.row)}
                        sx={{ color: "#df7257" }}
                      />
                    </Box>
                  </Tooltip>
                }
                color="primary"
              />
            ) : (
              <></>
            )}
          </>,
          // <GridActionsCellItem
          //   icon={<Delete />}
          //   label="Delete"
          //   onClick={() => {
          //     handleDeleteClick(params.row);
          //   }}
          //   color="error"
          // />,
        ],
      },
    ];
    return (
      <Box>
        <DataGride
          columns={columns}
          customHeight={450}
          tableData={dashboardTask || []}
          disableCheckbox={true}
          hideDeletion
          rowCount={pageInfo?.total_items || 0}
          pageSize={rowsPerPage || 0}
          page={page}
          pageName={"project"}
          onPageSizeChange={handleChangeRowsPerPage}
          paginationMode={true}
        />
      </Box>
    );
  };
  const MemoizedNewTable = useMemo(() => <NewTable />, [dashboardTask]);
  return MemoizedNewTable;
};

export default React.memo(TaskCard);
