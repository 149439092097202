/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import EyeIcon from "../../components/commonComponents/eyeicon";
import DataGride from "../../components/datagride/DataGride";
import {
  AssignTaskAction,
  getJobByProjectAction,
  getProjectListAction,
  getTaskAction,
} from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { formContainerCardStyle } from "../../utils/Style";
import {
  CustomDialog,
  PopupMessage,
  ProgressBar,
  TriggerAlert,
  secondsToHMS,
} from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import { JobDetailsComponet } from "../taskMapping/TaskJobData";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { RESET_LABEL } from "../../utils/textName";
import { allpageFontStyle } from "../../utils/FontStyle";
import PopupComponent from "../../components/commonComponents/PopupComponent";

function TaskReassignPage() {
  const { projectList, jobList, tasklistData } = useSelector(
    (state) => state.master
  );

  const CustomSelect = (props) => {
    const handleOnchange = (e) => {
      handelEditFun({ id: props?.id, value: e.target.value });
    };

    return (
      <select
        onChange={handleOnchange}
        value={props?.row?.reassignTo}
        style={{
          width: 130,
          borderRadius: 5,
          border: "none",
          outline: "none",
        }}
        name="cars"
        id="cars"
      >
        <option value="" selected>
          Select
        </option>
        {usersList &&
          usersList.length > 0 &&
          usersList.map((val) => (
            <>
              {props?.row?.assignedTo !== val?.value ? (
                <option value={val.value}>{val.label}</option>
              ) : null}
            </>
          ))}
        {/* <option value="volvo">Volvo</option>
        <option value="saab">saaa</option>
        <option value="opel">Opel</option>
        <option value="audi">Audi</option> */}
      </select>
    );
  };
  const col = [
    // {
    //   field: "taskId",
    //   headerName: "Task Id",
    //   width: 150,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "taskType",
      headerName: "Task Type",
      width: 100,
      // minWidth: 100,
      // flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "category",
      headerName: "Category",
      width: 100,
      // minWidth: 100,
      // flex: 1,
      align: "left",
      headerAlign: "left",
    },

    // {
    //   field: "jobCode",
    //   headerName: "Job Id",
    //   width: 100,
    //   // minWidth: 100,
    //   // flex: 1,
    //   align: "left",
    //   headerAlign: "left",
    // },
    // {
    //   field: "remarks",
    //   headerName: "Remarks",
    //   // width: 150,
    //   width: 200,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "assignedUsername",
      headerName: "Assigned To",
      width: 150,
      // minWidth: 150,
      // flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "productionHours",
      headerName: "Est Hours",
      type: "text",
      width: 150,
      // minWidth: 150,
      // flex: 1,
      valueGetter: (params) => secondsToHMS(Number(params.row.productionHours)),
      // editable: true,
      // preProcessEditCellProps: (params) => {
      //   handelEditFun(params)
      //   // const isPaidProps = params.otherFieldsProps.isPaid;
      //   // const hasError = isPaidProps.value && !params.props.value;
      //   // return { ...params.props, error: hasError };
      // },
    },
    // {
    //   field: "reassignToBy",
    //   headerName: "Reassign",
    //   type: "singleSelect",
    //   width: 200,
    //   // minWidth: 200,
    //   // flex: 1,
    //   renderCell: renderData,
    //   renderEditCell: renderSelectEditInputCell,
    //   editable: true,

    // },
    {
      // field: "actions1",
      cellClassName: "actions",
      // type: "actions",
      // flex: 1,
      headerName: "Reassign",
      // renderHeader: () => <p>{"REASSIGN"}</p>,
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) =>
        allowedPages().WorkStation.taskReassignment.reassign
          ? [<CustomSelect {...params} />]
          : [],
    },
    {
      field: "state",
      headerName: "Status",
      width: 150,
      minWidth: 150,
      // minWidth: 150,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      // minWidth: 70,
      align: "left",
      headerAlign: "left",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ p: 0 }}
          icon={
            <Box
              title={"View"}
              sx={{
                p: 0,
                display: "flex",
                justifyContent: "center",
                width: 10,
              }}
            >
              <EyeIcon
                taskId={params.row.taskId}
                jobcode={params.row.jobCode}
                clickOpen={true}
                clickclose={false}
              />
            </Box>
          }
          label="View"
          // onClick={() => {
          //   handelEditStart(params)
          // }}
          color="primary"
        />,
      ],
    },
  ];
  const insialState = {
    task: false,
    revert: false,
    split: false,
    revertList: [],
    colorUnassignedTaskList: [],
    popupstate: false,
    popupmessage: "",
    splitList: [],
  };
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );
  const dispatch = useDispatch();
  const [SingleSelecteState, setSingleSelecteState] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  // const [selectedValue2, setSelectedValue2] = useState("");
  const [sumitState, setSumitState] = useState(false);
  const [constructData, setConstructData] = React.useState([]);
  const [projectCode1, setprojectCode1] = useState("");
  const [jobCode1, setjobCode1] = useState("");
  const [checkedState, setCheckedState] = useState(insialState);
  const [category1, setcategory1] = useState("");
  const [isActive, setIsactive] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [categoryType1, setcategoryType1] = useState("");
  const [changedValue, setChangedValue] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);
  const [columns2, setColumns2] = React.useState(col || []);
  const [open2, setOpen2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const [fileValue, setFileValue] = React.useState("");
  // const fileRef1 = React.useRef(null);
  const [payload, setpayload] = React.useState({
    jobcode: "",
    type: "",
    projectCode: "",
  });
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClearAll();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const apiRefMain = useRef(null);

  const handleClosepopup = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading(false);
  };
  // useEffect(() => {
  //   const scrollFun = async () => {
  //     try {
  //       if (taskList.length > 0 && sumitState === false) {
  //         await taskList.map((val) => (apiRefMain.current.startCellEditMode({ id: val.id, field: 'reassignToBy' })))
  //         await apiRefMain.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //         await apiRefMain.current.scrollToIndexes({ rowIndex: 0, columnIndex: 0 });
  //       }
  //     } catch (error) {
  //     }
  //   }
  //   scrollFun()
  // }, [taskList]);
  const constructProjectData = (arr) => {
    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr.jobList,
      jobCountInfo: arr.jobTaskInfo,
      taskCategory: ["All", ...arr.taskCategory],
      taskType: ["All", ...arr.taskType],
      userlist:
        arr.users &&
        arr.users.length > 0 &&
        arr.users.map((val) => ({
          label: val.userInfo?.firstname + " " + val.userInfo?.lastname,
          value: val.userInfo?.username,
        })),
      userInfo:
        arr.users &&
        arr.users.length > 0 &&
        arr.users.map((val) => val.userInfo),
    };
    return res;
  };
  React.useEffect(() => {
    if (jobList?.jobList?.length > 0) {
      const taskListData = constructProjectData(jobList);
      setConstructData(taskListData);

      setUsersList(taskListData.userlist);
    }
  }, [jobList]);

  // Page Auto Reload By the Task Status changes

  const constructTaskData = (arr) => {
    const res = arr.map((val) =>
      val.taskList.map((el, i) => ({
        id: i + 1,
        ...el.taskInfo,
        createdUserInfo: el.createdUserInfo,
        updatedUserInfo: el.updatedUserInfo,
        assignedUsername: el?.assignedUserInfo
          ? el?.assignedUserInfo?.firstname +
            " " +
            el?.assignedUserInfo?.lastname
          : el?.assignedTo,
        reassignTo: "",
        reassignToBy: "",
        jobInfo: val?.jobInfo,
        // ...val.jobInfo.displayColumns.split(',').map((cl) => ({
        //   [cl]: el.taskInfo?.taskInfo[cl]
        // })),
        dynamicData: val.jobInfo.displayColumns.split(",").map((cl) => ({
          field: cl,
          headerName: cl,
          width:
            el?.taskInfo?.taskInfo[cl].toString().length === 1 ||
            el?.taskInfo?.taskInfo[cl].toString().length === 2 ||
            el?.taskInfo?.taskInfo[cl].toString().length === 3
              ? (cl.toString().length / 10) * 100
              : (el?.taskInfo?.taskInfo[cl].toString().length / 10) * 100,
          // flex: 1,
          // minWidth: 150,
          // renderCell: (params) => <Box sx={{ maxWidth: 100, overflowX: 'auto', fontFamily: "Lato" }} >{params?.row?.taskInfo[cl]}</Box>,
          valueGetter: (params) => {
            return `${params.row.taskInfo[cl] || ""}`;
          },
          align: "left",
          headerAlign: "left",
        })),
      }))
    );
    return res.flat();
  };
  const constructDynamicData = (arr) => {
    if (arr && arr[0]?.dynamicData) {
      const res = setColumns2((per) => [...arr[0]?.dynamicData, ...per]);

      return res;
    }
  };
  const handleClickOpen = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  React.useEffect(() => {
    const day = async () => {
      try {
        if (tasklistData && tasklistData[0]?.taskList?.length > 0) {
          await setColumns2(col);
          const taskListData = constructTaskData(tasklistData);
          const dynamicData = constructDynamicData(taskListData);
          setTaskList(taskListData);
          // setDynamicValue(dynamicData)
          // setUsersList(taskListData.userInfo);
        } else {
          setTaskList([]);
        }
      } catch (error) {}
    };
    day();
  }, [tasklistData]);
  React.useEffect(() => {
    if (payload?.jobcode) {
      assign();
    }
  }, [globapOrganization, pageNumber]);
  useEffect(() => {
    if (tasklistData?.pageInfo?.num_pages) {
      setTotalPage(tasklistData.pageInfo?.total_items);
    } else {
      setTotalPage(1);
    }
  }, [tasklistData]);

  React.useEffect(() => {
    dispatch(getProjectListAction(globapOrganization?.orgId));
  }, [globapOrganization]);
  React.useEffect(() => {
    if (
      projectList &&
      projectList?.project_details?.length &&
      projectList?.project_details?.length > 0
    ) {
      const res = projectList?.project_details.map((val) => val.projectCode);
      setSingleSelecteState(res);
    }
  }, [projectList]);
  const assign = async () => {
    if (payload?.jobcode) {
      const load = {
        projectCode: payload.projectCode ? payload.projectCode : "",
        jobCode: payload.jobcode ? payload.jobcode : "",
        category:
          category1 !== "All" ? (payload.type ? payload.type : "all") : "all",
        taskType:
          categoryType1 !== "All"
            ? payload.taskType
              ? payload.taskType
              : "all"
            : "all",
        reassign: true,
      };
      // setTaskList([])
      await dispatch(getTaskAction("post", load, setLoading));
      setSumitState(false);
      setCheckedState(insialState);
      // setSelectionRow([]);
      // setSelectedFile([]);
    } else {
      TriggerAlert("warning", "Select Jobcode");
    }
  };

  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      assign();
      dispatch(
        updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false } })
      );
    }
  }, [autoLoad]);

  const handelProject = async (e) => {
    if (e) {
      setpayload({ ...payload, jobcode: "" });
      setjobCode1("");
      setTaskList([]);
      setCheckedState(insialState);
      setConstructData([]);
      setpayload({ ...payload, projectCode: e?.projectCode });
      await dispatch(
        getJobByProjectAction({
          projectCode: e?.projectCode,
          orgId: globapOrganization.orgId,
        })
      );
      setprojectCode1(e);
    } else {
      setprojectCode1(e);
      setpayload({ ...payload, jobcode: e });
      setjobCode1(e);
      setTaskList([]);
      setConstructData([]);
    }
  };
  // const CustomComponent = (params) => {
  //   let ref = useGridApiContext();
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         width: "100%",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //       }}
  //     >
  //       <div>{params.value}</div>
  //       <IconButton size="small" sx={{ padding: 0 }} tabindex="-1">
  //         <ArrowDropDownIcon
  //           onClick={(event) => {
  //             event.stopPropagation(); // to not select row
  //             ref.current.startCellEditMode({
  //               id: params.id,
  //               field: params.field,
  //             });
  //           }}
  //         />
  //       </IconButton>
  //     </Box>
  //   );
  // };

  const handelEditFun = async (params) => {
    const findData =
      usersList &&
      usersList.length > 0 &&
      usersList.find((val) => val.value === params.value);

    let filter;

    if (changedValue && changedValue.length > 0) {
      const findDuplicte = changedValue.findIndex(
        (val) => val.id === params.id
      );
      if (findDuplicte !== -1) {
        await setTaskList((per) => {
          filter = per.find((val) => val.id === params.id);
          const arr = [...per];
          const res = per.findIndex((val) => val.id === params.id);

          arr[res].reassignTo = params?.value || "";
          arr[res].reassignToBy = params?.value ? findData?.label : "";
          return arr;
        });
        changedValue[findDuplicte].reassignTo = params?.value || "";
        changedValue[findDuplicte].reassignToBy = params?.value
          ? findData?.label
          : "";
        setIsactive(!isActive);
      } else {
        await setTaskList((per) => {
          filter = per.find((val) => val.id === params.id);
          const arr = [...per];
          const res = per.findIndex((val) => val.id === params.id);

          arr[res].reassignTo = params?.value || "";
          arr[res].reassignToBy = params?.value ? findData?.label : "";
          return arr;
        });
        filter.reassignTo = params?.value || "";
        filter.reassignToBy = params?.value ? findData?.label : "";
        setIsactive(!isActive);
        await changedValue.push(filter);
      }
    } else {
      await setTaskList((per) => {
        filter = per.find((val) => val.id === params.id);
        const arr = [...per];
        const res = per.findIndex((val) => val.id === params.id);

        arr[res].reassignTo = params?.value || "";
        arr[res].reassignToBy = params?.value ? findData?.label : "";
        return arr;
      });
      filter.reassignTo = params?.value || "";
      filter.reassignToBy = params?.value ? findData?.label : "";
      setIsactive(!isActive);
      await changedValue.push(filter);
    }
    // setChangedValue(res)
    // setTaskList(res)
    //  if (findData?.label) {
    // } else {
    //   const res = [...changedValue]
    //   const filterdata = res.filter((val) => val.id !== params.id)
    //   setChangedValue(filterdata)
    // }
  };

  const processRowUpdate = (row) => {
    setSelectedValue(row);
  };
  const handelClearAll = () => {
    dispatch(
      updateMasterStates({
        name: "jobList",
        value: {},
      })
    );
    dispatch(
      updateMasterStates({
        name: "tasklistData",
        value: [],
      })
    );
    setpayload({ ...payload, jobcode: "", type: "", projectCode: "" });
    setConstructData([]);
    setSingleSelecteState([]);
    setUsersList([]);
    setTaskList([]);
    setChangedValue([]);
    setprojectCode1("");
    setjobCode1("");
    setcategory1("");
    setcategoryType1("");
  };
  useEffect(() => {
    return () => {
      handelClearAll();
    };
  }, []);

  function myFunc(obj, prop) {
    return obj.reduce(function (acc, item) {
      let key = item[prop];

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);

      return acc;
    }, {});
  }
  const constructPayloadData = (arr, key) => {
    const res = Object.keys(arr).map((val, i) => ({
      username: val,
      taskList: arr?.[val].map((val) => ({
        taskId: val.taskId,
        jobCode: val.jobCode,
      })),
    }));
    return res;
  };

  const handleSubmit = async () => {
    const constructPayload = myFunc(
      changedValue?.filter((i) => i?.reassignTo),
      "reassignTo"
    );
    const constructPayload1 = constructPayloadData(
      constructPayload,
      "reassignTo"
    );
    const res = await dispatch(AssignTaskAction("post", constructPayload1));
    setTaskList([]);
    if (res) {
      TriggerAlert("success", "Task Reassigned");
      // handelClearAll()
      // scrollToTop()
      setSumitState(true);
      setChangedValue([]);
      setItemPerPage(100);
      setPageNumber(1);
      await setTaskList([]);
      assign();
    }
    handleClose2();
  };
  const checkBoxFilter = () => {
    const handelcheck = (e, type, i) => {
      if (type === "revert") {
        if (e) {
          const value = e.target.checked;
          const taskLoad = [...taskList];
          const taskLoadFilter = taskLoad.filter((val) => val?.revertedFrom);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              revert: value,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Reverted File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              revert: value,
              split: false,
              task: false,
              splitList: [],
              revertList: taskLoadFilter,
              colorUnassignedTaskList: [],
            });
          }
        }
      }
      if (type === "split") {
        if (e) {
          const value = e.target.checked;
          const taskLoad = [...taskList];
          const taskLoadFilter = taskLoad.filter((val) => val?.splitFromTask);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              split: value,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Split File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              split: value,
              task: false,
              revert: false,
              splitList: taskLoadFilter,
              revertList: [],
              colorUnassignedTaskList: [],
            });
          }
        }
      }
    };
    return (
      <Box sx={{ display: "flex", gap: 3, p: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#ffc9c9", ...allpageFontStyle.typography1 }}
            // disabled={checkedState.revert || checkedState.task}
            checked={checkedState.split}
            onChange={(e) => handelcheck(e, "split")}
            id={"split"}
            name={"split"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"split"}>
            {"Splitted"}
          </label>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#f0b878", ...allpageFontStyle.typography1 }}
            //  disabled={checkedState.split || checkedState.task}
            checked={checkedState.revert}
            onChange={(e) => handelcheck(e, "revert")}
            id={"revert"}
            name={"revert"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"revert"}>
            {"Reverted"}
          </label>
        </Box>
      </Box>
    );
  };
  const handelRowColor = (params) => {
    return params?.row?.revertedFrom
      ? "super-app-theme--Revert"
      : params?.row?.splitFromTask
      ? "super-app-theme--Split"
      : "";
  };
  useEffect(() => {
    // if (constructData.jobList && constructData.jobList.length > 0) {
    //   setjobCode1(constructData.jobList[0])
    //   setpayload({ ...payload, jobcode: constructData.jobList[0]?.jobCode });
    // }
    if (constructData.taskType && constructData.taskType.length > 0) {
      setcategoryType1(constructData.taskType[0]);
      setpayload({ ...payload, type: constructData.taskType[0] });
    }
    if (constructData.taskCategory && constructData.taskCategory.length > 0) {
      setcategory1(constructData.taskCategory[0]);
      setpayload({ ...payload, taskType: constructData.taskCategory[0] });
    }
  }, [projectCode1]);

  return (
    <Box
      sx={{
        px: 5,
        pt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        minHeight: 650,
        overflow: "hidden",
        background: grey[100],
      }}
    >
      <Card sx={{ ...formContainerCardStyle, p: 3 }}>
        {" "}
        <Box sx={{ display: "flex", justifyContent: "center", px: 5 }}>
          <SelectComponent
            DataOptions={projectList?.project_details}
            labelName={(option) => option.projectCode}
            value={projectCode1}
            sx={{ pr: 1 }}
            label={"Project *"}
            handelChange={handelProject}
          />
          <SelectComponent
            DataOptions={constructData.jobList}
            labelName={(option) => option.jobCode}
            value={jobCode1}
            sx={{ pr: 1 }}
            label={"Job *"}
            defaultValue={jobCode1}
            handelChange={(e) => {
              if (e) {
                setpayload({ ...payload, jobcode: e?.jobCode });
                setjobCode1(e);
              } else {
                setpayload({ ...payload, jobcode: e });
                setjobCode1(e);
              }
            }}
          />
          <SelectComponent
            DataOptions={constructData.taskCategory}
            labelName={(option) => option}
            value={category1}
            sx={{ pr: 1 }}
            label={"Category"}
            defaultValue={category1}
            handelChange={(e) => {
              setpayload({ ...payload, type: e });
              setcategory1(e);
            }}
          />
          <SelectComponent
            DataOptions={constructData.taskType}
            labelName={(option) => option}
            defaultValue={categoryType1}
            value={categoryType1}
            sx={{ pr: 1 }}
            label={"Task Type"}
            handelChange={(e) => {
              setpayload({ ...payload, taskType: e });
              setcategoryType1(e);
            }}
          />

          <Button
            size="small"
            sx={{
              ml: 2,
              p: 0.5,
              ...allpageFontStyle.buttonFontStyle2,
              borderRadius: 5,
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
            }}
            onClick={() => assign()}
            disabled={!projectCode1 || !jobCode1}
            variant="contained"
          >
            Load
          </Button>
        </Box>
        <Box sx={{ px: 5, pt: 1 }}>
          {jobCode1?.jobCode ? (
            <JobDetailsComponet
              jobObject={jobCode1}
              jobCountArray={constructData?.jobCountInfo}
            />
          ) : null}
        </Box>
        {/* Download  and upload excel ui */}
        {/* <Box
        sx={{ display: "flex", justifyContent: "end", pr: "6%", pb: 1, pt: 1 }}
      >
        {taskList.length > 0 && payload?.jobcode && payload.projectCode ?
          <Box sx={{ pt: 0.7, pr: 1.6, display: 'flex', }}>
            <Box><CustomButton buttonStyle={{}} disabled={!taskList.length > 0} color={'success'}
              label={<Box sx={{ pt: 0.1 }} ><Tooltip title=' Excel Download'>
                <img src={excelDownloaadIcon} alt="upload" style={{ width: 20, height: 20 }} />
                  <Box> <ImageTag src={imageImports.excelDownload} alt="upload" width={20} height={20} /></Box>
              </Tooltip>
              </Box>} onClick={handelDownloadClick} /></Box>
          </Box> : null}
        <Box sx={{ p: 0 }}>
          {taskList.length > 0 && payload?.jobcode && payload.projectCode ?
            <Box sx={{ pt: 0.5, display: 'flex', justifyContent: 'space-between', cursor: 'pointer', pr: 0.5 }}>
              <Box>
                <label
                  htmlFor="upload"
                >
                  <Box sx={{ pt: 0.7, }}><Tooltip title='Upload excel for manual assign'>
                <Box>
                          <ImageTag src={imageImports.excelUpload} alt="upload" width={20} height={20} /></Box>
                  </Tooltip> </Box>
                </label>
              </Box>
            </Box>
            : null}
          <input
            type="file"
            id="upload"
            ref={fileRef1}
            onChange={handelChangeFile}
            style={{ color: "transparent", display: "none" }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}> {fileValue?.name && payload?.jobcode && payload.projectCode ? (
          <Box sx={{ fontSize: 10, mr: 1, pl: 2, display: "flex" }}>
            <Box sx={{ width: 100, overflowWrap: 'break-word' }}> {fileValue?.name}</Box>
            <Box>
              <CloseRounded
                sx={{ fontSize: 15, cursor: "pointer" }}
                color="error"
                onClick={() => {
                  clearField();
                }}
              />
            </Box>
          </Box>
        ) : null}
        </Box>
      </Box> */}
        <Box sx={{ my: 1, px: 5 }}>
          <DataGride
            apiRef={apiRefMain}
            columns={columns2}
            // edit={true}
            hideDeletion={true}
            tableData={
              checkedState.revert
                ? checkedState.revertList
                : checkedState.split
                ? checkedState.splitList
                : taskList || []
            }
            disableCheckbox={true}
            processRowUpdate={processRowUpdate}
            filterCheckBox={checkBoxFilter}
            getRowClassName={handelRowColor}
            rowSelection={false}
            // getRowHeight={() => 'auto'}
            // pageSize={itemPerPage}
            // page={pageNumber}
            // pageName={"project"}
            // onPageSizeChange={changePageSize}
            // rowCount={totalPage}
            // paginationMode={true}
            // onPageChange={changePage}

            // hiddenFooter={true}
            // selectedFile={selectedFile}
            // rowSelectionModel={selectedFile}
            // rowData={onSelectionModelChange}
            // onSelectionModelChange={onSelectionModelChange2}
            // tableData={
            //     employee &&
            //     employee.length > 0 &&
            //     employee.filter((value) => value.isActive === true)
            // }
            // hideDeletion={true}
            customHeight={450}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // pr: 2,
            gap: 1,
          }}
        >
          {allowedPages().WorkStation.taskReassignment.reassign && (
            <Box>
              <Button
                size="small"
                sx={{
                  color: "white",
                  width: 90,
                  ...allpageFontStyle.buttonFontStyle2,
                  backgroundColor: "#258d4d",
                  ":hover": {
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                  },
                  borderRadius: 5,
                }}
                variant="contained"
                onClick={() => handleClickOpen()}
                color="success"
              >
                Reassign
              </Button>
            </Box>
          )}
          <Box>
            <Button
              size="small"
              sx={{
                color: "white",
                width: 90,
                ...allpageFontStyle.buttonFontStyle2,
                borderRadius: 5,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
              }}
              variant="contained"
              onClick={() => {
                setAlertPopup((per) => ({
                  ...per,
                  open: true,
                  type: PopupMessage.resetType,
                  title: PopupMessage.reset,
                }));
              }}
              color="error"
            >
              {RESET_LABEL}
            </Button>
          </Box>
        </Box>
        <Dialog
          // maxWidth={'sm'}
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{ fontSize: 16, textAlign: "center" }}
            id="alert-dialog-title"
          >
            {"Are you sure want to reassign?"}
          </DialogTitle>

          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              size="sm"
              sx={{
                color: "white",
                // width: '10%',
                height: 40,
                p: 0,
                px: 2,
                fontWeight: "bold",
                backgroundColor: "#4d8c5d",
                ":hover": { backgroundColor: "green" },
                borderRadius: 5,
                textTransform: "capitalize",
              }}
              color="success"
              onClick={() => {
                // const constructPayload = myFunc(changedValue, 'reassignTo');
                handleSubmit();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              size="sm"
              sx={{
                color: "white",
                // width: '10%',
                height: 40,
                p: 0,
                px: 2,
                fontWeight: "bold",
                backgroundColor: "#bf6373",
                ":hover": { backgroundColor: "#de3e5a" },
                borderRadius: 5,
                textTransform: "capitalize",
              }}
              color="error"
              onClick={handleClose2}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
        <CustomDialog
          open={loading}
          onClose={handleClosepopup}
          content={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
              >
                <Box>{"Data is getting fetched"}</Box>
                <Box sx={{ textAlign: "center" }}>{"please wait"}</Box>{" "}
              </Box>
              <Box sx={{ px: 2 }}>
                <ProgressBar />
              </Box>
            </Box>
          }
        />
      </Card>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </Box>
  );
}

export default TaskReassignPage;
