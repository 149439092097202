import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WorkIcon from "@mui/icons-material/Work";
import GroupsIcon from "@mui/icons-material/Groups";
import "./calendar.css";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import LoopIcon from "@mui/icons-material/Loop";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";


require("react-big-calendar/lib/css/react-big-calendar.css");


const localizer = momentLocalizer(moment);
const users = [
  { label: "Member1" },
  { label: "Member2" },
  { label: "Member3" },
  { label: "Member4" },
  { label: "Member5" },
  { label: "Member6" },
  { label: "Member7" },
  { label: "Member8" },
  { label: "Member9" },
  { label: "Member10" },
  { label: "Member11" },
  { label: "Member12" },
  { label: "Member13" },
  { label: "Member14" },
  { label: "Member15" },
  { label: "Member16" },
  { label: "Member17" },
  { label: "Member18" },
  { label: "Member19" },
  { label: "Member20" },
];
const dept = [
  { label: "Department1" },
  { label: "Department2" },
  { label: "Department3" },
  { label: "Department4" },
  { label: "Department5" },
  { label: "Department6" },
  { label: "Department7" },
  { label: "Department8" },
  { label: "Department9" },
  { label: "Department10" },
];
const team = [
  { label: "Team1" },
  { label: "Team2" },
  { label: "Team3" },
  { label: "Team4" },
  { label: "Team5" },
  { label: "Team6" },
  { label: "Team7" },
  { label: "Team8" },
  { label: "Team9" },
  { label: "Team10" },
];


export default function CalendarTab() {

  // const [date, setDate] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [data, setData] = useState({
    title: "",
    agenda: "",
    start: "",
    end: "",
    extType: false,
    startTime: "",
    endTime: "",
    conferenceHall: "",
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
    department: [],
    team: [],
    members: [],
    extMembers: [],
    channel: "",
    allday: false,
    recurrence: 1,
    description: ''
  });

  const [event, setEvent] = useState([]);

  const [text, setText] = useState("");
  const [array, setArray] = useState([]);

  const [state, setState] = useState({
    openSlot: false,
    openEvent: false,

    // clickedEvent: {},
  });

  const handleClose = () => {
    setState({ ...state, openEvent: false, openSlot: false });
  };

  const addarray = () => {
    setArray((prev) => [...prev, text]);
    setText("");
  };
  const deleteMember = (i) => {
    let arr = [...array];
    arr.splice(i, 1);
    setArray(arr);
  };

  const handleEventSelected = (event) => {
    // navigate('/hallRequest')
    setOpen(true);
    setData({ ...event });
    setArray(event?.extMembers);
    // setEvent({
    //   openEvent: true,
    //   clickedEvent: event,
    //   start: event.start,
    //   end: event.end,
    //   title: event.title,
    //   desc: event.desc
    // });
  };
  const handleSlotSelected = (slotInfo) => {
    // navigate('/hallRequest',{state:slotInfo})
  allowedPages().meeting.schedule.schedule &&  setOpen(true);
    // setData({
    //   ...data,
    //   // start: slotInfo.start,
    //   // end: slotInfo.end,
    // });
  };

  const [open, setOpen] = useState(false);



  const handleClose2 = () => {
    setOpen(false);
    setState({ ...state, openSlot: false });
    setArray([]);
    setData({
      title: "",
      agenda: "",
      start: "",
      end: "",
      extType: false,
      startTime: "",
      endTime: "",
      conferenceHall: "",
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      department: [],
      team: [],
      members: [],
      extMembers: [],
      channel: "",
      allday: false,
      recurrence: 1,
      description: ''
    });
  };
  const handleSubmit = () => {
    if (
      !data.title ||
      !data.agenda ||
      !data.conferenceHall ||
      !data.members.length ||
      (!data.allday && (!data.fromTime || !data.toTime)) ||
      (!data.channel && (!data.department.length || !data.team.length))
    ) {
      return setTrigger(true);
    }
    // setData({...data, extMembers:array})
    setEvent((prev) => [
      ...prev,
      {
        ...data,
        extMembers: array || [],
        start: data.fromDate
          ? new Date(`${data.fromDate}T${data.fromTime || "00:00"}`)
          : data.fromDate,
        end: data.toDate
          ? new Date(`${data.toDate}T${data.toTime || "00:00"}`)
          : data.toDate,
      },
    ]);
    setOpen(false);
    setData({
      title: "",
      agenda: "",
      start: "",
      end: "",
      extType: false,
      startTime: "",
      endTime: "",
      conferenceHall: "",
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      department: [],
      team: [],
      members: [],
      extMembers: [],
      channel: "",
      allday: false,
      recurrence: 1,
      description: ''
    });
    setTrigger(false);
  };
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {

        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  return (
    <Box sx={{...formContainerStyle,pt:1}}>
      <Card sx={{ ...formContainerCardStyle, p: 3, display:'flex',justifyContent:'center' }}>
        <Calendar
          localizer={localizer}
          events={event}
          // startAccessor="start"
          // endAccessor="end"
          style={{ height: 700, width: "90%" }}
          views={["month", "week", "day", "agenda"]}
          timeslots={2}
          defaultView="month"
          defaultDate={new Date()}
          selectable={true}
          onSelectSlot={(e) => {
            handleSlotSelected(e);
            // setDate(e.start);
          }}
          onSelectEvent={(e) => {
            handleEventSelected(e);
          }}
        />
        <Dialog
          // title={`Book Meeting on ${moment(date).format("MMMM Do YYYY")}`}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          fullWidth
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "black",
              color: "white",
              borderBottom: "2px solid black",
              alignItems: "center",
            }}
          >
            {/* <Box>New Meeting on {moment(date).format("Do MMM YYYY")}</Box> */}
            <Box>Schedule Meeting</Box>
            <Box sx={{ backgroundColor: "black" }}>
              <DialogActions sx={{ backgroundColor: "black", display: "flex" }}>
                <Button
                  sx={{ pr: 3, color: "white", ":hover": { color: "#358c4e" } }}
                  onClick={handleSubmit}
                >
                  Schedule
                </Button>
                <Button
                  variant="text"
                  sx={{ color: "white", ":hover": { color: "#358c4e" } }}
                  onClick={handleClose2}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ height: "100%" }}>
            {/* <Grid container spacing={4}>
            <Grid item xs={6} md={8} lg={8}> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 3,
                alignItems: "center",
                px: 3,
              }}
            >
              <CreateIcon sx={{ mr: 2, color: "#358c4e" }} />
              <TextField
                fullWidth
                size="small"
                label="Title"
                floatingLabelText="Title"
                value={data.title}
                error={trigger && data.title === ""}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",

                pt: 3,
                alignItems: "center",
                px: 3,
              }}
            >
              <PersonAddIcon sx={{ color: "#358c4e" }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 2,
                  width: "100%",
                }}
              >
                <Box sx={{ pr: 5 }}>Type</Box>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setData({ ...data, extType: e.target.checked });
                        setArray([]);
                        setText("");
                      }}
                      checked={data.extType}
                      sx={{ color: "#358c4e" }}
                      color="default"
                    />
                  }
                  label="External"
                />
                {data.extType ? (
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box
                      sx={{
                        width: "40%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        label="External Members"
                        floatingLabelText="External Members"
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                color="success"
                                onClick={addarray}
                                sx={{
                                  color: "#358c4e",
                                  fontWeight: "bold",
                                  p: 0,
                                  width: 10,
                                }}
                              >
                                <AddIcon sx={{ p: 0, color: "#358c4e" }} />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        ml: 5,
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: 13,
                          fontWeight: "bold",
                          color: "#358c4e",
                        }}
                      >
                        List of members
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          // overflow: "auto",
                          flexWrap: "wrap",
                          wordBreak: "break-all",
                          // alignItems: "center",
                          border: "1px solid #358c4e",
                          borderRadius: 2,
                          width: "100%",
                          height: 80,
                          overflow: "auto",
                          px: 2,
                          py: 1,
                        }}
                      >
                        {array?.map((members, i) => (
                          <Chip
                            label={
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  variant="body2"
                                  sx={{
                                    wordBreak: "break-all",
                                    flexWrap: "wrap",
                                    whiteSpace: "break-spaces",
                                  }}
                                >
                                  {members}
                                </Box>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: 14,
                                    height: 20,
                                    width: 20,
                                    borderRadius: "50%",
                                    background: "#358c4e",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    ml: 1,
                                  }}
                                  onClick={() => deleteMember(i)}
                                >
                                  x
                                </Box>
                              </Box>
                            }
                            variant="outlined"
                            sx={{ mx: 1, my: 0.5 }}
                          ></Chip>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
              }}
            >
              <MeetingRoomIcon sx={{ mr: 2, color: "#358c4e" }} />
              <FormControl
                sx={{ display: "flex", justifyContent: "center" }}
                size="small"
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Conference Hall
                </InputLabel>
                <Select
                  fullWidth
                  sx={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={trigger && data.conferenceHall === ''}
                  value={data.conferenceHall}
                  label="Slect Conference Hall"
                  onChange={(e) =>
                    setData({ ...data, conferenceHall: e.target.value })
                  }
                >
                  <MenuItem value={10}>Conference Hall 1</MenuItem>
                  <MenuItem value={20}>Conference Hall 2</MenuItem>
                  <MenuItem value={30}>Conference Hall 3</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                // justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
              }}
            >
              <AccessTimeIcon sx={{ mr: 1, color: "#358c4e" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    sx={{ mx: 1 }}
                    size="small"
                    label="From"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    floatingLabelText="External Members"
                    value={data.fromDate}
                    error={trigger && data.fromDate === ''}
                    onChange={(e) => {
                      setData({ ...data, fromDate: e.target.value });
                      setStartDate(e.target.value)
                    }}
                  />
                  {data.allday === false ? (
                    <TextField
                      sx={{ mx: 1 }}
                      size="small"
                      label="At"
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      floatingLabelText="External Members"
                      error={trigger && data.fromTime === '' && data.allday === false}
                      value={data.fromTime}
                      onChange={(e) => {
                        setData({ ...data, fromTime: e.target.value });
                      }}
                    />
                  ) : null}
                </Box>
                <ArrowForwardIcon sx={{ color: "#358c4e" }} />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    sx={{ mx: 1 }}
                    size="small"
                    label="To"
                    type="date"
                    inputProps={{ min: startDate }}
                    InputLabelProps={{ shrink: true }}
                    floatingLabelText="External Members"
                    value={data.toDate}
                    error={trigger & data.toDate === ''}
                    onChange={(e) => {
                      setData({ ...data, toDate: e.target.value });
                    }}
                  />
                  {data.allday === false ? (
                    <TextField
                      sx={{ mx: 1 }}
                      size="small"
                      label="At"
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      floatingLabelText="External Members"
                      value={data.toTime}
                      error={trigger & data.toTime === '' && data.allday === false}
                      onChange={(e) => {
                        setData({ ...data, toTime: e.target.value });
                      }}
                    />
                  ) : null}
                </Box>

                <FormControlLabel
                  control={
                    <Android12Switch
                      // size="small"
                      color="success"
                      // value={data.allday}
                      checked={data.allday}
                      onChange={(e) => {
                        setData({ ...data, allday: e.target.checked });
                      }}
                    />
                  }
                  label={
                    <Box
                      sx={{ fontSize: 12, color: "#358c4e", fontWeight: "bold" }}
                    >
                      All Day
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
              }}
            >
              <LoopIcon sx={{ mr: 2, color: "#358c4e" }} />
              <FormControl
                sx={{ display: "flex", justifyContent: "center" }}
                size="small"
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Recurrence
                </InputLabel>
                <Select
                  fullWidth
                  sx={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.recurrence}
                  label=" recurrence"
                  onChange={(e) =>
                    setData({ ...data, recurrence: e.target.value })
                  }
                >
                  <MenuItem value={1}>Do not repeat</MenuItem>
                  <MenuItem value={2}>Every weekday (Mon to Fri)</MenuItem>
                  <MenuItem value={3}>Daily</MenuItem>
                  <MenuItem value={4}>Weekly</MenuItem>
                  <MenuItem value={5}>Monthly</MenuItem>
                  <MenuItem value={6}>Yearly</MenuItem>
                  <MenuItem value={7}>Custom</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
              }}
            >
              <WorkIcon sx={{ mr: 2, color: "#358c4e" }} />
              <FormControl
                sx={{ display: "flex", justifyContent: "center" }}
                size="small"
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Channel
                </InputLabel>
                <Select
                  fullWidth
                  sx={{ width: data.channel ? "90%" : "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.channel}
                  label="Chanel"
                  error={trigger & data.channel === ''}
                  onChange={(e) => {
                    setData({
                      ...data,
                      channel: e.target.value,
                      department: [],
                      team: [],
                    });
                  }}
                >
                  <MenuItem value={1}>Department</MenuItem>
                  <MenuItem value={2}>Team</MenuItem>
                </Select>
              </FormControl>
              {data.channel === 1 ? (
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  options={dept}
                  value={data.department}

                  onChange={(e, v) => setData({ ...data, department: v })}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} error={trigger && data.department.length === 0 && data.channel === 1} label="Department" />
                  )}
                />
              ) : data.channel === 2 ? (
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  options={team}
                  value={data.team}
                  onChange={(e, v) => setData({ ...data, team: v })}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField fullWidth error={trigger & data.team.length === 0 && data.channel === 2} {...params} label="Team" />
                  )}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
                width: "100%",
              }}
            >
              <GroupsIcon sx={{ mr: 2, color: "#358c4e" }} />
              <Autocomplete
                multiple
                fullWidth
                size="small"
                id="combo-box-demo"
                options={users}
                value={data.members}
                onChange={(e, v) => setData({ ...data, members: v })}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} error={trigger & data.members.length === 0} label="Members" />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 2,
                alignItems: "center",
                px: 3,
              }}
            >
              <LibraryBooksIcon sx={{ mr: 2, color: "#358c4e" }} />
              <TextField
                fullWidth
                size="small"
                label="Description"
                floatingLabelText="Description"
                value={data.description}

                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pt: 1,
                // alignItems: "center",
                px: 3,
              }}
            >
              <EventNoteIcon sx={{ mr: 2, color: "#358c4e", mt: 1 }} />
              {/* <TextField
              label="Agenda"
              fullWidth
              rows={3}
              multiline
              size="small"
              floatingLabelText="Agenda"
              value={data.agenda}
              onChange={(e) => {
                setData({ ...data, agenda: e.target.value });
              }}
            /> */}

              <Box sx={{ width: "100%", mb: 10 }}>
                <ReactQuill
                  theme="snow"
                  value={data.agenda}
                  modules={modules}
                  formats={formats}
                  placeholder="Agenda"
                  onChange={(e) => {
                    setData({ ...data, agenda: e });
                  }}
                  style={{ border: trigger && data.agenda === '' ? '1px solid red' : 'none' }}
                ></ReactQuill>
              </Box>
            </Box>
            {/* <Box sx={{
            display: "flex",
            justifyContent: "space-evenly",
            pt: 1,
            alignItems: "center",
            px: 3
          }}>
            <EventNoteIcon sx={{ mr: 2, color: "#358c4e" }} />
            

          </Box> */}
            {/* <TimePicker
            format="ampm"
            floatingLabelText="Start Time"
            minutesStep={5}
            value={data.startTime}
            onChange={(e)=> {
              }}
          />
          <TimePicker
            format="ampm"
            floatingLabelText="End Time"
            minutesStep={5}
            value={data.endTime}
            onChange={(e)=>{ setData({ ...data, endTime: e.target.value })   }}
        
          /> */}
            {/* </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs={6} md={8} lg={4}></Grid>
          </Grid> */}
          </DialogContent>
        </Dialog>
      </Card>
    </Box>
  );
}
