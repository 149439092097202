import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopIcon from "@mui/icons-material/Stop";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  colors,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import EyeIcon from "../../components/commonComponents/eyeicon";
import { conformPopup } from "../../components/popup/ConformPopup";
import gif from "../../pics/clock.gif";
import arrow from "../../pics/fast-forward.gif";
import {
  UpdateTaskAction,
  getAssignedTaskAction,
  getAssignedTaskActionGet,
  getProjectCodeListAction,
  getUserTaskAction2,
} from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import store from "../../redux/store";
import { checkAndEndRunningGeneralTaskAction } from "../../service/action/action";
import { allpageFontStyle } from "../../utils/FontStyle";
import { Undo } from "@mui/icons-material";

const cardbuttons = {
  fontSize: 11,
  color: "white",
  height: 30,
  width: 80,
  borderRadius: 5,
  ml: 2,
};
export default function TaskHistoryListPage({
  increment,
  handleStart,
  mapval,
  style,
  handlePause,
  handleReset,
  handleResume,
  jobCode1,
  handleRevert
}) {
  const dispatch = useDispatch();

  const { timerData } = useSelector((state) => state.presist);

  const [remarks, setRemarks] = useState("");

  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedRow, setselectedRow] = useState({ type: "", index: "" });
  const { globapOrganization, runingTask } = useSelector(
    (state) => state.presist
  );
  const [open, setOpen] = useState(false);

  const formatTimeData = () => {
    const timerData = store.getState().presist?.timerData?.timestate;
    const getSeconds = `0${timerData % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };
  const formatTimeDataPause = () => {
    // const timerData = store.getState().presist.timerData.timestate
    const getSeconds = `0${timerData?.timestate % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData?.timestate / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData?.timestate / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };

  useEffect(() => {
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
  }, [globapOrganization]);

  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }

  const params = useParams();
  const path = useLocation();
  // const getApi = () => {
  //   if (path?.pathname?.includes("/dashboard")) {
  //     const pathway = path.pathname.split("/");
  //     if (pathway.length) {
  //       const params = pathway.at(-1);
  //       const payload = {
  //         overAllTasks: params === "overallTasks" ? true : false,
  //         inProgressTasks: params === "pendingTasks" ? true : false,
  //         notStartedTasks: params === "assignedTasks" ? true : false,
  //         completedTasks: params === "completedTasks" ? true : false,
  //       };
  //       dispatch(getDashboardAction("post", payload));
  //     }
  //   }
  // };
  const handelStart = async (taskValue) => {
    const res = {
      taskId: taskValue?.taskId,
      jobCode: taskValue?.jobCode,
      status: " In-Progress",
      state: "Started",
      remarks: "",
      workHours: "00",
      workMinutes: "00",
      workSeconds: "00",
    };
    // getApi();
    const pay = await dispatch(UpdateTaskAction(res));
    await checkAndEndRunningGeneralTaskAction();
    if (pay) {
      if (jobCode1) {
        dispatch(getUserTaskAction2(jobCode1, mapval.id));
      } else {
        dispatch(
          getAssignedTaskAction("post", { jobCode: taskValue?.jobCode })
        ).then((response) => {
          if (response?.data?.data.assignedTaskList) {
            const res = response?.data?.data.assignedTaskList;
            const filterthedata = res.find((val) => val.id === mapval.id);
            dispatch(
              updatePresistStates({
                name: "runingTask",
                value: filterthedata,
              })
            );
          }
        });
      }

      handleStart();
    }
  };
  const handelPause = async (taskValue) => {
    dispatch(
      updatePresistStates({
        name: "timerData",
        value: {
          timestate: toSeconds(
            Number(taskValue.workHours),
            Number(taskValue.workMinutes),
            Number(taskValue.workSeconds)
          ),
        },
      })
    );
    const res = {
      taskId: taskValue?.taskId,
      jobCode: taskValue?.jobCode,
      status: " In-Progress",
      state: "Paused",
      remarks: taskValue.remarks,
      workHours: formatTimeDataPause().H,
      workMinutes: formatTimeDataPause().M,
      workSeconds: formatTimeDataPause().S,
    };

    // startGeneralTaskServiceAction(selectedTask)
    await dispatch(UpdateTaskAction(res));
    // getApi();
    // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }))
    if (jobCode1) {
      dispatch(
        updateMasterStates({
          name: "assignedtaskData",
          value: [],
        })
      );
      dispatch(getUserTaskAction2(jobCode1, mapval.id, "", 1));
    } else {
      dispatch(
        updateMasterStates({
          name: "assignedtaskData",
          value: [],
        })
      );
      dispatch(getAssignedTaskActionGet("", 1)).then((response) => {
        if (response?.data?.data.assignedTaskList?.taskInfo) {
          const res = response?.data?.data.assignedTaskList?.taskInfo;
          const filterthedata = res.find((val) => val.id === mapval.id);
          dispatch(
            updatePresistStates({
              name: "runingTask",
              value: filterthedata,
            })
          );
        }
      });
    }
    handlePause();
  };
  const handelResume = async (taskValue) => {
    dispatch(
      updatePresistStates({
        name: "timerData",
        value: {
          timestate: toSeconds(
            Number(taskValue.workHours),
            Number(taskValue.workMinutes),
            Number(taskValue.workSeconds)
          ),
        },
      })
    );
    const res = {
      taskId: taskValue?.taskId,
      jobCode: taskValue?.jobCode,
      status: "In-Progress",
      state: "Resumed",
      remarks: "",
      workHours: formatTimeData().H,
      workMinutes: formatTimeData().M,
      workSeconds: formatTimeData().S,
    };
    const pay = await dispatch(UpdateTaskAction(res));
    await checkAndEndRunningGeneralTaskAction();
    // getApi();
    if (pay) {
      // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }));
      if (jobCode1) {
        dispatch(
          updateMasterStates({
            name: "assignedtaskData",
            value: [],
          })
        );
        dispatch(getUserTaskAction2(jobCode1, mapval.id, "", 1));
      } else {
        dispatch(
          updateMasterStates({
            name: "assignedtaskData",
            value: [],
          })
        );
        dispatch(getAssignedTaskActionGet("", 1)).then((response) => {
          if (response?.data?.data.assignedTaskList?.taskInfo) {
            const res = response?.data?.data.assignedTaskList?.taskInfo;
            const filterthedata = res.find((val) => val.id === mapval.id);
            dispatch(
              updatePresistStates({
                name: "runingTask",
                value: filterthedata,
              })
            );
          }
        });
      }
      handleResume();
    }
  };

  const handelpopupPasue = (task) => {
    setOpen(task);

    setselectedRow({ type: "pause" });
  };
  const handelReset = async (taskValue) => {
    conformPopup(
      "Confirm",
      "Are you sure you want to end this Task?",
      async () => {
        const res = {
          taskId: taskValue?.taskId,
          jobCode: taskValue?.jobCode,
          status: "Resolved",
          state: "End",
          remarks: taskValue.remarks,
          workHours: formatTimeData().H,
          workMinutes: formatTimeData().M,
          workSeconds: formatTimeData().S,
        };
        const pay = await dispatch(UpdateTaskAction(res));
        if (pay) {
          // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }));
          if (jobCode1) {
            dispatch(
              updateMasterStates({
                name: "assignedtaskData",
                value: [],
              })
            );
            dispatch(getUserTaskAction2(jobCode1, mapval.id, "", 1));
          } else {
            dispatch(
              updateMasterStates({
                name: "assignedtaskData",
                value: [],
              })
            );
            dispatch(getAssignedTaskActionGet("", 1)).then((response) => {
              dispatch(
                updatePresistStates({
                  name: "runingTask",
                  value: {},
                })
              );
            });
          }
          handleReset();
        }
      }
    );
  };

  const handelChangedymanicField = (e, v) => {
    setRemarks(e);
    const res = { ...mapval, remarks: e };
    dispatch(
      updatePresistStates({
        name: "runingTask",
        value: res,
      })
    );
  };

  const handelSave = () => {
    if (selectedRow.type === "pause") {
      handelPause(mapval);
      setOpen(false);
    }
    // if (selectedRow.type === "end") {
    //     handelReset(runingTask)
    //     setOpen(false);
    // }
    // if (selectedRow.type === "start") {
    //     setselectedStart(selectedRow.index);
    //     setOpen(false);
    // }
    // if (selectedRow.type === "resume") {
    //     setselectedResume(selectedRow.index);
    //     setOpen(false);
    // }
  };
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return (
      <Box sx={{ p: 0, fontSize: 15 }}>{`${hours} : ${minutes} : ${Math.round(
        seconds
      )}`}</Box>
    );
  }
  const handleClose = () => {
    setRemarks("");
    setSelectedTask(null);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
        // pl: 5,
        // pr: 5,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          //   justifyContent: "space-evenly",
        }}
      >
        <Box sx={{ fontWeight: "bold", flex: 0.2, fontSize: 12, pr: 2 }}>
          Task Inprogress:
        </Box>
        <Box sx={{ flex: 0.2 }}>
          {" "}
          <Chip
            variant="outlined"
            label={
              <Box sx={{ fontWeight: "bold", fontSize: 12, color: "#801780" }}>
                #{mapval?.taskId}
              </Box>
            }
            sx={{}}
          />
        </Box>
        <Box sx={{ flex: 0.05 }}>
          {" "}
          <img alt="" src={arrow} style={{ height: 20, width: 20 }} />
        </Box>
        <Box sx={{ flex: 0.2 }}>
          <Chip
            variant="outlined"
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                <WorkIcon sx={{ color: "rosybrown", height: 15, width: 15 }} />
                {mapval?.jobCode}
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 0.05 }}>
          {" "}
          <img alt="" src={arrow} style={{ height: 20, width: 20 }} />
        </Box>
        <Box sx={{ flex: 0.1 }}>
          <Box
            sx={{
              fontSize: 12,
              p: 0.5,
              // pl: 3,
              // pr: 3,
              //   minWidth: 150,
              ml: 2,

              fontWeight: "bold",
              //   borderStyle: "solid",
              backgroundColor:
                mapval?.state === "Started" || mapval?.state === "Resumed"
                  ? colors.green[400]
                  : null,
              color:
                mapval?.state === "Started" || mapval?.state === "Resumed"
                  ? "white"
                  : "black",
              //   borderWidth: 1,
              display: "flex",
              //   borderRadius: 5,
            }}
          >
            {mapval?.state === "Started" || mapval?.state === "Resumed" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 13,
                  p: 0,
                }}
              >
                <img
                  alt=""
                  src={gif}
                  style={{ height: 20, width: 20, paddingRight: 2 }}
                />{" "}
                {toHoursAndMinutes(timerData?.timestate)}
              </Box>
            ) : (
              <>
                {toHoursAndMinutes(
                  toSeconds(
                    mapval?.workHours,
                    mapval?.workMinutes,
                    mapval?.workSeconds
                  )
                )}
              </>
            )}
            {/* {toHoursAndMinutes(timerData?.timestate)} */}
          </Box>
        </Box>
        {/* <Box>
          <Button
            clickable={true}
            onClick={() => {
              handelStart(mapval);
            }}
            sx={{
              backgroundColor: colors.green[300],
              ...cardbuttons,
              display: mapval?.status === "In-Progress" ? "none" : null,
              ":hover": { backgroundColor: colors.green[600] },
            }}
            // disabled={
            //     mapval.state === "Not Started" || mapval.state === ""
            //         ? false
            //         : true
            // }
            // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PlayCircleOutlineIcon sx={{ mr: 0.3 }} /><Box >START</Box></Box>}
            variant="filled"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlayCircleOutlineIcon sx={{ mr: 0.1 }} />
              <Box>START</Box>
            </Box>
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              handelpopupPasue(mapval);
            }}
            // disabled={
            //     mapval.state === "Resumed" || mapval.state === "Started"
            //         ? false
            //         : true
            // }
            sx={{
              backgroundColor: colors.deepOrange[300],
              ...cardbuttons,
              display:
                mapval?.state === "Resumed" || mapval?.state === "Started"
                  ? null
                  : "none",
              ":hover": { backgroundColor: colors.deepOrange[600] },
            }}
            variant="filled"
            // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PauseCircleOutlineIcon sx={{ mr: 0.3 }} /><Box >PAUSE</Box></Box>}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PauseCircleOutlineIcon sx={{ mr: 0.3 }} />
              <Box>PAUSE</Box>
            </Box>
          </Button>
        </Box> */}
        <Box sx={{ flex: 0.1 }}>
          <Button
            onClick={() => {
              handelResume(mapval);
            }}
            // disabled={mapval.state === "Paused" ? false : true}
            sx={{
              backgroundColor: colors.orange[400],
              ...cardbuttons,
              display: mapval?.state === "Paused" ? null : "none",
              ":hover": { backgroundColor: colors.orange[400] },
            }}
            variant="filled"
            // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PlayArrowIcon sx={{}} /><Box >RESUME</Box></Box>}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlayArrowIcon sx={{}} />
              <Box sx={{ fontSize: 11 }}>RESUME</Box>
            </Box>
          </Button>
        </Box>
        <Box sx={{ flex: 0.1 }}>
          <Button
            onClick={() => {
              handelReset(mapval);
            }}
            disabled={
              mapval?.state === "Started" || mapval?.state === "Resumed"
                ? false
                : true
            }
            sx={{
              backgroundColor: colors.red[300],
              ...cardbuttons,
              ":hover": { backgroundColor: colors.red[400] },
            }}
            variant="filled"
            // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StopIcon sx={{}} /><Box >END</Box></Box>}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StopIcon sx={{}} />
              <Box sx={{ fontSize: 11 }}>END</Box>
            </Box>
          </Button>
        </Box>
        <Box sx={{ flex: 0.1 }}>
          <Button
            onClick={() => {
              handleRevert(mapval);
            }}
            disabled={
              mapval?.state !== "Paused" 
            }
            sx={{
              backgroundColor: colors.blue[300],
              ...cardbuttons,
              ":hover": { backgroundColor: colors.blue[400] },
              px:2
            }}
            variant="filled"
            // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StopIcon sx={{}} /><Box >END</Box></Box>}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Undo sx={{mr:0.5}} />
              <Box sx={{ fontSize: 11 }}>REVERT</Box>
            </Box>
          </Button>
        </Box>
        <Box sx={{ flex: 0.1 }}>
          <Box title={"Preview"}>
            <EyeIcon
              taskId={mapval?.taskId}
              jobcode={mapval?.jobCode}
              clickOpen={true}
              clickclose={false}
            />
          </Box>
        </Box>
      </Box>

      <Dialog open={Boolean(open)} onClose={() => setSelectedTask(null)}>
        <DialogTitle
          sx={{ display: "flex", justifyContent: "center", fontSize: 14 }}
        >
          {open?.taskId}
        </DialogTitle>

        <DialogContent>
          {selectedRow.type === "end" || selectedRow.type === "pause" ? (
            <>
              <Box sx={{ p: 1 }}>
                {/* <Box sx={{ fontSize: 11, mb: 1 }}>Mandatory*</Box> */}

                <TextField
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  required
                  // value={assignedTaskList[index]?.remarks}

                  onChange={(e) => {
                    handelChangedymanicField(
                      e.target.value,

                      selectedRow.index
                    );
                  }}
                  label={"Remarks"}
                />
              </Box>
            </>
          ) : (
            <Box>
              You already have Started task Or Resumed Task So please Pause the
              task Then continue the anthor task
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="success"
            variant="contained"
            onClick={handelSave}
            sx={{
              color: "white",
              // width: '10%',
              height: 30,
              // p: 0,
              // px: 2,
              ...allpageFontStyle.buttonFontStyle2,
              backgroundColor: "#258d4d",
              ":hover": { backgroundColor: "green" },
              borderRadius: 5,
              textTransform: "capitalize",
            }}
            autoFocus
            disabled={remarks.length === 0}
          >
            Submit
          </Button>

          <Button
            color="error"
            sx={{
              color: "white",
              // width: '10%',
              height: 30,

              ...allpageFontStyle.buttonFontStyle2,
              // backgroundColor: "#bf6373",
              // ":hover": { backgroundColor: "red" },
              borderRadius: 5,
              textTransform: "capitalize",
            }}
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
