const fontfamily = ["Lato"];


export const sweetalertWidth = 300
const commonFontSize = '0.75rem'
export const allpageFontStyle = {
  mainHeading: {
    fontSize: 22,
    fontWeight: "bold",
    fontFamily: fontfamily[0],
  },
  textFieldPlaceholder: {
    fontSize: 12,
    // fontStyle: 'italic',
    color: 'grey',
    paddingLeft: 1
  },
  addButtonFont: {
    font: { fontSize: 12, fontFamily: fontfamily[0], },
    buttonHeight: { height: 35 }
  },
  tableIconSize: {
    fontSize: 12,
  },
  title: {
    fontSize: { xs: 13, md: 14, lg: 16 },
    fontWeight: "bold",
    fontFamily: fontfamily[0],
  },
  title1: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: fontfamily[0],
  },

  formFontStyle: {
    fontSize: 17,
    fontFamily: fontfamily[0],
  },
  buttonFontStyle1: {
    fontSize: 14,
    fontFamily: fontfamily[0],

  },
  buttonFontStyle2: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: fontfamily[0],
  },
  sideBartypography: {
    fontSize: commonFontSize,
    fontFamily: fontfamily[0],
  },
  taskStatusTypography: {
    fontSize: 10
  },
  typography1: {
    fontSize: 12,
    fontFamily: fontfamily[0],
  },
  typography2: {
    fontSize: 13,
    fontFamily: fontfamily[0],
  },
  typography3: {
    fontFamily: fontfamily[0],
    fontSize: 13,
  },
  typography4: {
    fontSize: 13,
    fontFamily: fontfamily[0],
    fontWeight: "bold",
  },
  dataGird: {
    fontSize: commonFontSize
  },
  dateField: {
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
      color: '#d1d1d1',
      // fontStyle: "italic"
    },
  },
  IconFontSize: {

    fontSize: 12
    // fontStyle: "italic"

  }
};
