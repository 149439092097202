import { Box, Typography } from "@mui/material"
import CountUpNumber from "../../components/commonComponents/CountNumber"
import { contentStyleDashboard, labelStyleinDashboard } from "../../utils/Style"
import { TypographyCustom } from "../../utils/UIUtil"
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TopicIcon from '@mui/icons-material/Topic';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ADMIN_DASHBOARD_COLORS } from "../../utils/CommonColors";
import { HelpOutline } from "@mui/icons-material";
import { allpageFontStyle } from "../../utils/FontStyle";
export const LabelData = (props) => {
    const { type, dashboardData, iconStyle, labelcardinsideContainer } = props;
    const containStyle = {};
    const styletittle = {
        ...allpageFontStyle.title,
    }

    console.log(dashboardData, "dashboardData")
    const JopType = () => {
        return (
            <Box>
                <Box sx={{ display: "flex", opacity: 0.9, border: "1px solid", borderRadius: "12px 12px 0px 0px", borderColor: ADMIN_DASHBOARD_COLORS.job, background: "white", justifyContent: "center" }}>
                    <HomeRepairServiceIcon sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"JOB"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>
                    <Box sx={{ width: "100%" }}>
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.jobOverall || 0}</Box>
                            }
                            label={"Total "}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.jobPending || 0}</Box>
                            }
                            label={"Pending "}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.jobCompleted || 0}</Box>
                            }
                            label={"Completed "}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };
    const JopType1 = () => {
        return (
            <Box>
                <Box sx={{ display: "flex", opacity: 0.9, background: "white", border: "1px solid", borderRadius: "12px 12px 0px 0px", borderColor: ADMIN_DASHBOARD_COLORS.project, justifyContent: "center", alignItems: 'center' }}>



                    <TopicIcon sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"PROJECT"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>

                    <Box sx={{ width: "100%" }}>
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.projectOverall || 0}</Box>
                            }
                            label={"Total "}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.projectPending || 0}</Box>
                            }
                            label={"Pending "}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>{dashboardData?.projectCompleted || 0}</Box>
                            }
                            label={"Completed "}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };
    const JopType2 = () => {
        return (
            <Box>
                <Box sx={{ display: "flex", opacity: 0.9, border: "1px solid", borderRadius: "12px 12px 0px 0px", borderColor: ADMIN_DASHBOARD_COLORS.task, background: "white", justifyContent: "center" }}>
                    <LowPriorityIcon sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"REVERT"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>



                    <Box sx={{ width: "100%" }}>
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.revertRequested || 0}
                                </Box>
                            }
                            label={" Requested"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.revertApproved || 0}
                                </Box>
                            }
                            label={" Approved"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.revertRejected || 0}
                                </Box>
                            }
                            label={"Rejected"}
                        />

                    </Box>
                </Box>
            </Box>
        );
    };
    const TaskType = () => {
        return (
            <Box>
                <Box sx={{ display: "flex", opacity: 0.9, border: "1px solid", borderRadius: "12px 12px 0px 0px", borderColor: ADMIN_DASHBOARD_COLORS.revert, background: "white", justifyContent: "center" }}>
                    <AssignmentIcon sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"TASK"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>



                    <Box sx={{ width: "100%" }}>
                        {" "}
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.unassignedTasks || 0}
                                </Box>
                            }
                            label={"Unassigned"}
                        />
                        <TypographyCustom
                            containStyle={containStyle}
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            content={
                                <Box>
                                    {dashboardData?.assignedTasks || 0}
                                </Box>
                            }
                            label={"Assigned"}
                        />
                        <TypographyCustom
                            containStyle={containStyle}
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            content={
                                <Box>
                                    {dashboardData?.pendingTasks || 0}
                                </Box>
                            }
                            label={"Pending"}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };
    const TaskType1 = () => {
        return (
            <Box>
                <Box sx={{ display: "flex", opacity: 0.9, border: "1px solid", borderRadius: "12px 12px 0px 0px", borderColor: ADMIN_DASHBOARD_COLORS.dueDate, background: "white", justifyContent: "center" }}>
                    <CalendarMonthIcon sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"DUE DATE"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>



                    <Box sx={{ width: "100%" }}>
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.jobNearingDueDate || 0}
                                </Box>
                            }
                            label={"Job Nearing"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.jobExceededDueDate || 0}
                                </Box>
                            }
                            label={"Job Exceeded"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.jobExceededDueDateCompleted || 0}
                                </Box>
                            }
                            label={"Completed Exceeding"}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };
    const TaskType2 = () => {
        return (
            <Box>
                <Box sx={{
                    display: "flex", opacity: 0.9, border: "1px solid", borderRadius: "12px 12px 0px 0px",
                    borderColor: ADMIN_DASHBOARD_COLORS.query, background: "white", justifyContent: "center"
                }}>
                    <HelpOutline sx={{ color: 'black', ...iconStyle }} />
                    <Typography variant="body1" sx={{ color: 'black', ...styletittle }} component={"span"}>{"QUERY"}</Typography>
                </Box>
                <Box sx={{ ...labelcardinsideContainer }}>



                    <Box sx={{ width: "100%" }}>
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.overAllQuery || 0}
                                </Box>
                            }
                            label={"Total"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.openQuery || 0}
                                </Box>
                            }
                            label={"Pending"}
                        />
                        <TypographyCustom
                            labelStyle={labelStyleinDashboard}
                            contentStyle={contentStyleDashboard}
                            containStyle={containStyle}
                            content={
                                <Box>
                                    {dashboardData?.resolvedQuery || 0}
                                </Box>
                            }
                            label={"Closed"}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };
    const UserTaskType = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <ListAltIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    {" "}
                    <TypographyCustom
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        containStyle={containStyle}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.reassignedTasks || 0} />
                                    // dashboardData?.reassignedTasks || 0
                                }
                            </Box>
                        }
                        label={"Reassigned Task"}
                    />
                </Box>
            </Box>
        );
    };
    const UserTaskType1 = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <ListAltIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <TypographyCustom
                        containStyle={containStyle}
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.assignedTasks || 0} />
                                    // dashboardData?.assignedTasks || 0
                                }
                            </Box>
                        }
                        label={"Assigned Task"}
                    />
                </Box>
            </Box>
        );
    };
    const UserTaskType2 = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <ListAltIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <TypographyCustom
                        containStyle={containStyle}
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.unassignedTasks || 0} />
                                    // dashboardData?.unassignedTasks || 0
                                }
                            </Box>
                        }
                        label={"Unassigned Task"}
                    />
                </Box>
            </Box>
        );
    };
    const StatusTask = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <PlaylistAddCheckIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    {" "}
                    <TypographyCustom
                        containStyle={containStyle}
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.inprogressTasks || 0} />
                                    // dashboardData?.inprogressTasks || 0
                                }
                            </Box>
                        }
                        label={"In-Progress Task"}
                    />
                </Box>
            </Box>
        );
    };
    const StatusTask1 = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <PlaylistAddCheckIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <TypographyCustom
                        containStyle={containStyle}
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.pausedTasks || 0} />
                                    // dashboardData?.pausedTasks || 0
                                }
                            </Box>
                        }
                        label={"Paused Task"}
                    />
                </Box>
            </Box>
        );
    };
    const StatusTask2 = () => {
        return (
            <Box sx={{ ...labelcardinsideContainer }}>
                <Box>
                    <PlaylistAddCheckIcon sx={iconStyle} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <TypographyCustom
                        containStyle={containStyle}
                        labelStyle={labelStyleinDashboard}
                        contentStyle={contentStyleDashboard}
                        content={
                            <Box>
                                {
                                    <CountUpNumber end={dashboardData?.revertTasks || 0} />
                                    // dashboardData?.revertTasks || 0
                                }
                            </Box>
                        }
                        label={"Reverted Task"}
                    />
                </Box>
            </Box>
        );
    };
    return (
        <Box sx={{}}>
            {type === "job" ? (
                <JopType />
            ) : type === "job1" ? (
                <JopType1 />
            ) : type === "job2" ? (
                <JopType2 />
            ) : type === "task" ? (
                <TaskType />
            ) : type === "task1" ? (
                <TaskType1 />
            ) : type === "task2" ? (
                <TaskType2 />
            ) : type === "userTask" ? (
                <UserTaskType />
            ) : type === "userTask1" ? (
                <UserTaskType1 />
            ) : type === "userTask2" ? (
                <UserTaskType2 />
            ) : type === "statusTask" ? (
                <StatusTask />
            ) : type === "statusTask1" ? (
                <StatusTask1 />
            ) : type === "statusTask2" ? (
                <StatusTask2 />
            ) : null}
        </Box>
    );
};
export const selectOptions = [
    { label: "1 Month", value: "1 Month" },
    { label: "3 Month", value: "3 Month" },
    { label: "6 Month", value: "6 Month" },
    { label: "1 Year", value: "1 Year" },
];
