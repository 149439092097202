import { createSlice } from "@reduxjs/toolkit";
const reducers = {
  branchsaveData: {},
  orgsaveData: {},
  designationsaveData: {},
  gradesaveData: {},
  teamsaveData: {},
  depsaveData: {},
  usersaveData: {},
  authToken: '',
  autoLoad: { isAutoLoad: false },
  changePass: false,
  timerData: {
    timestate: 0,
  },
  globapOrganization: {},
  projectsaveData: {},
  userProfile: {},
  splitUpdate:null,
  splitCompletedCountUpdated:null,
};
export const presistSlice = createSlice({
  name: "presist",
  initialState: reducers,
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updatePresistStates: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    removePresistStates: (state, action) => {
    },
    resetPersistReducer: (state, action) => {
      return { ...reducers };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePresistStates, removePresistStates, resetPersistReducer } =
  presistSlice.actions;

export default presistSlice.reducer;
