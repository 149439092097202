import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

function SidebarList(props) {
    const { arr, nav, path, headerLabel } = props;
    return (
        <Box>
            <Box>
                <Box ><Typography sx={{ textAlign: 'center', fontSize: 12 }}>{headerLabel}</Typography></Box>
                <Grid container spacing={1}>
                    {arr?.map((val) => (
                        <>
                            {/* {val.permission ? */}
                            <Grid item lg={6} md={6} sm={4} sx={{

                            }}>
                                <Box sx={{
                                    // background: path === val.link ? "#258d4d" : "",
                                }}>
                                    <Button
                                        onClick={() => nav(val.link)}
                                        sx={{
                                            ":hover": {
                                                // background: path === val.link ? "" : "black",
                                                color: path === val.link ? "black" : "#258d4d",
                                            },
                                            color: path === val.link ? "#258d4d" : "black",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'center',
                                            gap: 1,

                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <Typography sx={{
                                            fontFamily: "lato",
                                            fontSize: 11,
                                        }}>{val.icon}</Typography>
                                        <Typography sx={{
                                            fontFamily: "lato",
                                            fontSize: 11,
                                            textTransform: 'capitalize'
                                        }}>{val.label}</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            {/* : null} */}
                        </>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default SidebarList;
