import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { enGB } from "date-fns/locale";
import { textFieldsStyle } from "../../utils/Style";
import React from "react";

function DateComponet(props) {
  const {
    value,
    setValue,
    minDate,
    maxDate,
    label,
    handelDate,
    fullWidth,
    style,
    noClear,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DemoContainer
        components={["DatePicker"]}
        sx={{ width: "100%", overflow: "visible" }}
      >
        <DatePicker
          label={label}
          // maxDate={maxDate}
          minDate={minDate}
          value={value}
          maxDate={new Date()}
          onChange={handelDate}
          slotProps={{
            field: { clearable: noClear ? false : true },
            textField: {
              size: "small",
              fullWidth: fullWidth ? false : true,
              sx: { ...textFieldsStyle(), ...style },
              error: false,
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DateComponet;
