import * as React from "react";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { updateCommonStates } from "../../redux/reducers/commonReducer";
import { Box, Button, Dialog } from "@mui/material";
import successGif from "../../images/gif/success.gif";
import errorGif from "../../images/gif/error.gif";
import warinigGif from "../../images/gif/warning.gif";
// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

export default function CustomizedSnackbars() {
  const dispatch = useDispatch();
  const { isSnackOpen, snackMsg } = useSelector((state) => state.common);
  // let autoCloseDuration = 10000
  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (snackMsg?.function) {
      await snackMsg?.function()
    }
    await dispatch(updateCommonStates({ name: "isSnackOpen", value: false }));
    await dispatch(
      updateCommonStates({
        name: "snackMsg",
        value: { type: "", message: "", function: "" },
      })
    );
  };
  // React.useEffect(() => {
  //   let autoCloseTimer;
  //   if (isSnackOpen && autoCloseDuration) {
  //     autoCloseTimer = setTimeout(handleClose, autoCloseDuration);
  //   }

  //   return () => clearTimeout(autoCloseTimer);
  // }, [isSnackOpen, autoCloseDuration]);
  return (
    <Box sx={{ width: "100%", overflow: 'hidden', }}>
      <Stack spacing={2}>
        {/* <Dialog
          open={isSnackOpen}
          // autoHideDuration={5000}
          onClose={handleClose}
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            // severity={snackMsg.type}
            // icon={false}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color:
                snackMsg.type === "success"
                  ? "green"
                  : snackMsg.type === "error"
                    ? "red"
                    : snackMsg.type === "warning"
                      ? "orange"
                      : null,
            }}
          >
            <Box> {snackMsg.message}</Box>
         
          </Alert>
        </Dialog> */}
      </Stack>
      <Dialog open={isSnackOpen} maxWidth="xs"
        onClose={snackMsg?.function ? () => { } : () => handleClose()}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflowX: 'hidden',
            p: 1,

          }}
        >
          <Box>
            {snackMsg?.type ?
              <img
                src={
                  snackMsg?.type === "success"
                    ? successGif
                    : snackMsg?.type === "error"
                      ? errorGif
                      : snackMsg?.type === "warning"
                        ? warinigGif
                        : ""
                }
                style={{ width: 60, height: 60 }}
                alt={snackMsg?.type === "success"
                  ? "success"
                  : snackMsg?.type === "error"
                    ? "error"
                    : snackMsg?.type === "warning"
                      ? "warning"
                      : "EMG"}
              /> : null}
          </Box>
          <Box
            sx={{
              fontFamily: "Lato",
              width: 250,
              height: "100%",
              overflowWrap: "break-word",
              textAlign: "center",
              overflow: 'auto',
            }}
          >
            {snackMsg?.message
              ? snackMsg?.message
              : snackMsg?.type === "error"
                ? "Something went wrong, please try again later"
                : snackMsg?.type === "success"
                  ? "Successfully Done"
                  : ""}
          </Box>
          <Box sx={{ py: 3 }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleClose}
              sx={{
                color: 'white',
                background:
                  snackMsg?.type === "success"
                    ? "blue"
                    : snackMsg?.type === "error"
                      ? "orange"
                      : snackMsg?.type === "warning"
                        ? "orange"
                        : "",
                ":hover": {
                  background:
                    snackMsg?.type === "success"
                      ? "blue"
                      : snackMsg?.type === "error"
                        ? "orange"
                        : snackMsg?.type === "warning"
                          ? "orange"
                          : "",
                },
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
