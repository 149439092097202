/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import EyeIcon from "../../components/commonComponents/eyeicon";
import DataGride from "../../components/datagride/DataGride";
import {
  SplitTaskDataAction,
  getJobByProjectAction,
  getProjectCodeListAction,
  getSplitTaskGetAction,
  getTaskSpiltAction,
} from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { updateProfileStates } from "../../redux/reducers/profileReducer";
import store from "../../redux/store";
import { formContainerCardStyle } from "../../utils/Style";
import { TriggerAlert, secondsToHMS, secondsToHMS1 } from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import SplitDialog from "./splitDialoge";
import "./taskSplit.css";
import Close from "@mui/icons-material/Close";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { allpageFontStyle } from "../../utils/FontStyle";
function TaskSplit() {
  const insialState = {
    task: false,
    revert: false,
    split: false,
    revertList: [],
    colorUnassignedTaskList: [],
    popupstate: false,
    popupmessage: "",
    splitList: [],
  };
  const { assignedtaskDataSplit, projectCodeDta, jobList } = useSelector(
    (state) => state.master
  );
  const { selectedRow } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );
  // const [count, setCount] = useState("");
  // const [open, setOpen] = React.useState(false);
  // const [expanded, setExpanded] = React.useState(false);
  const [show, setShow] = useState(false);
  const [constructData, setConstructData] = React.useState([]);
  const [isActive, setisActive] = useState(false);
  const [projectCode1, setprojectCode1] = useState("");
  // const listInnerRef = useRef();
  const scrollcall = useRef(false);
  const [checkedState, setCheckedState] = useState(insialState);
  const [jobCode1, setjobCode1] = useState("");
  // const [splitField, setsplitField] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [numperofPage, setNumperofPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  // const [selectedRow, setSelectedRow] = useState({});
  const [keyValue, setkeyValue] = useState([]);
  const [assignedTaskList, setAssigedTaskList] = useState([]);
  const [autoFocus, setAutoFocus] = useState(0);
  const setsplitField = (val) => {
    dispatch(
      updateProfileStates({
        name: "splitField",
        value: val,
      })
    );
  };
  const setSelectedRow = (val) => {
    dispatch(
      updateProfileStates({
        name: "selectedRow",
        value: val,
      })
    );
  };
  const setUpcomingTaskData = (val) => {
    dispatch(
      updateProfileStates({
        name: "upcomingTaskData",
        value: val,
      })
    );
  };
  const setErrIndex = (val) => {
    dispatch(
      updateProfileStates({
        name: "errIndex",
        value: val,
      })
    );
  };
  const setIsError = (val) => {
    dispatch(
      updateProfileStates({
        name: "isError",
        value: val,
      })
    );
  };
  useEffect(() => {}, [selectedRow]);
  const reset = () => {
    setsplitField([]);
    setSelectedRow({});
    setUpcomingTaskData({});
    setErrIndex({ index: null, msg: "" });
    setIsError(false);
  };
  const getSplitDetail = (e) => {
    dispatch(getTaskSpiltAction(e?.jobCode, e?.taskId));
  };
  const handleClickOpen = (e) => {
    setSelectedRow(e);
    // setOpen(true);
    console.log(e, "jhfjhfgvhg");
    addArray(e);
    setShow(true);
  };

  const handelProjectCode = (e) => {
    if (e) {
      // setpayload({ ...payload, projectCode: e?.projectCode });
      setprojectCode1(e);
      setjobCode1("");
      setAssigedTaskList([]);
      dispatch(
        getJobByProjectAction({
          projectCode: e?.projectCode,
          orgId: globapOrganization.orgId,
        })
      );
    } else {
      // setpayload({ ...payload, projectCode: e });
      setprojectCode1(e);
      setjobCode1("");
      setAssigedTaskList([]);
      // setConstructData([])
    }
  };
  const handelJopcode = (v) => {
    if (v) {
      // setpayload({ ...payload, jobcode: v });
      setjobCode1(v);
    } else {
      // setpayload({ ...payload, jobcode: v });
      setjobCode1(v);
    }
  };

  const assign = async (split, revert) => {
    if (jobCode1) {
      const load = {
        projectCode: projectCode1?.projectCode ? projectCode1.projectCode : "",
        splitTask: split || false,
        revertedTask: revert || false,
        jobCode: jobCode1?.jobCode ? jobCode1.jobCode : "",
        orgId: globapOrganization?.orgId,
      };
      setAssigedTaskList([]);
      setCheckedState(insialState);
      await dispatch(
        getSplitTaskGetAction(
          load,
          `?pageSize=${itemPerPage}&pageNumber=${
            pageNumber === 0 ? 1 : pageNumber
          }`
        )
      );
      // setSelectionRow([]);
      // setSelectedFile([]);
    } else {
      TriggerAlert("warning", "Select Jobcode");
    }
  };
  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      assign(false, false);
      dispatch(
        updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false } })
      );
    }
  }, [autoLoad]);
  const constuctData = (arr) => {
    // const res = arr.filter((val) => val.jobTaskList?.taskList?.length > 0);
    const res2 = arr[0]?.taskList?.taskList?.map((val) => ({
      ...val.taskInfo,
      assignedTo:
        val?.assignedUserInfo?.firstname +
        "  " +
        val?.assignedUserInfo?.lastname,
      assignedToId: val?.assignedUserInfo?.empId,
      jobInfo: arr[0]?.taskList?.jobInfo,
      hours: secondsToHMS1(+val.taskInfo?.productionHours).H,
      mints: secondsToHMS1(+val.taskInfo?.productionHours).M,
    }));
    // res && res.length > 0 && res.map((val) => val.jobTaskList.taskList);
    // const constrcData=res&&res.length>0&&res.map((val)=>({

    // }))
    return res2;
  };
  useEffect(() => {
    if (
      assignedtaskDataSplit &&
      assignedtaskDataSplit[0]?.pageInfo?.num_pages
    ) {
      setTotalPage(assignedtaskDataSplit[0].pageInfo?.total_items);
      setNumperofPage(assignedtaskDataSplit[0]?.pageInfo?.num_pages);
      // if (pageNumber !== 0) setPageNumber(assignedtaskDataSplit[0]?.pageInfo?.currentPageNumber)
    } else {
      setTotalPage(0);
      setNumperofPage(0);
    }
  }, [assignedtaskDataSplit]);
  useEffect(() => {
    const loadscrll = async () => {
      try {
        if (assignedtaskDataSplit && assignedtaskDataSplit.length > 0) {
          const constuct = constuctData(assignedtaskDataSplit);
          await setAssigedTaskList((per) => [...constuct]);
          scrollcall.current = false;
        }
        // else {
        //   setAssigedTaskList([]);
        // }
      } catch (error) {}
    };
    loadscrll();
  }, [assignedtaskDataSplit]);
  const onScrollFunctin = async (pageNumber, itemPerPage, split, revert) => {
    if (jobCode1) {
      const load = {
        projectCode: projectCode1.projectCode ? projectCode1.projectCode : "",
        splitTask: split || false,
        revertedTask: revert || false,
        jobCode: jobCode1.jobCode ? jobCode1.jobCode : "",
        orgId: globapOrganization?.orgId,
      };
      // setAssigedTaskList([])
      await dispatch(
        getSplitTaskGetAction(
          load,
          `?pageSize=${itemPerPage}&pageNumber=${pageNumber + 1}`
        )
      );
      // setSelectionRow([]);
      // setSelectedFile([]);
    } else {
      TriggerAlert("warning", "Select Jobcode");
    }
  };

  const constructProjectData = (arr) => {
    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr.jobList,
      taskCategory: arr.taskCategory,
      taskType: arr.taskType,
      userInfo:
        arr.users &&
        arr.users.length > 0 &&
        arr.users.map((val) => val.userInfo),
    };
    return res;
  };
  React.useEffect(() => {
    if (jobList?.jobList?.length > 0) {
      const taskListData = constructProjectData(jobList);
      setConstructData(taskListData);
    } else setConstructData([]);
  }, [jobList]);
  const paperTitle = {
    overflowWrap: "break-word",
    ...allpageFontStyle.title1,
    pl: 1,
    textAlign: "center",
  };
  const paperBody = {
    // fontFamily: "lato",
    // fontWeight: "bolder",
    overflowWrap: "break-word",
    fontSize: 12,
    textAlign: "center",
  };
  const checkBoxFilter = () => {
    const handelcheck = (e, type, i) => {
      if (type === "revert") {
        if (e?.target?.checked) {
          const value = e.target.checked;

          const taskLoadFilter = assign(false, true);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              revert: value,
              split: false,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Reverted File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              revert: value,
              split: false,
              task: false,
              splitList: [],
              revertList: taskLoadFilter,
              colorUnassignedTaskList: [],
            });
          }
        } else {
          assign(false, false);
        }
      }
      if (type === "split") {
        if (e?.target?.checked) {
          const value = e.target.checked;
          const taskLoadFilter = assign(true, false);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              split: value,
              revert: false,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Split File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              split: value,
              task: false,
              revert: false,
              splitList: taskLoadFilter,
              revertList: [],
              colorUnassignedTaskList: [],
            });
          }
        } else {
          assign(false, false);
        }
      }
    };
    return (
      <Box sx={{ display: "flex", gap: 3, p: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#ffc9c9", ...allpageFontStyle.typography1 }}
            disabled={!projectCode1 || !jobCode1}
            checked={checkedState.split}
            onChange={(e) => handelcheck(e, "split")}
            id={"split"}
            name={"split"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"split"}>
            {"Splitted"}
          </label>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#f0b878", ...allpageFontStyle.typography1 }}
            disabled={!projectCode1 || !jobCode1}
            checked={checkedState.revert}
            onChange={(e) => handelcheck(e, "revert")}
            id={"revert"}
            name={"revert"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"revert"}>
            {"Reverted"}
          </label>
        </Box>
      </Box>
    );
  };
  const handelRowColor = (params) => {
    return params?.row?.revertedFrom
      ? "super-app-theme--Revert"
      : params?.row?.splitFromTask
      ? "super-app-theme--Split"
      : "";
  };
  // const [upcomingTaskData, setUpcomingTaskData] = useState({});
  const MainTask = ({ selectedRow, hide }) => {
    return (
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          p: hide ? 1 : 0,
          ...paperBody,
          background: green[100],
        }}
      >
        <Box
          sx={{
            width: "10%",
            minWidth: "10%",
            pl: 2,
            fontFamily: "lato",
            ...paperBody,
          }}
        ></Box>
        <Box
          sx={{
            fontFamily: "lato",
            width: "30%",
            minWidth: "30%",
            pl: 1,
            fontWeight: "bold",
            ...paperBody,
          }}
        >
          {selectedRow.measureCount}
        </Box>
        <Box
          sx={{
            fontFamily: "lato",
            width: "30%",
            minWidth: "30%",
            pl: 1,
            fontWeight: "bold",
            ...paperBody,
          }}
        >
          {secondsToHMS(+selectedRow?.productionHours)}
        </Box>

        {!hide ? (
          <Box
            sx={{
              display: "flex",
              width: "30%",
              minWidth: "30%",
              pl: 1,
            }}
          >
            <Box
              sx={{ p: 1 }}
              onClick={() => {
                addArray();
                // navigate(`/tasks/split/:${selectedRow.taskId}`)
              }}
            >
              <Button variant="outlined" color="primary" size="small">
                Split
              </Button>
            </Box>
            <Box sx={{ p: 1 }}>
              {" "}
              <Button
                onClick={() => {
                  setShow(false);
                  updateTaskDatas([]);
                  setIsError(false);
                }}
                variant="outlined"
                color="error"
                size="small"
              >
                Back
              </Button>
            </Box>
          </Box>
        ) : null}
      </Paper>
    );
  };
  function totalMeasureCountBeforeIndex(arr, index, full) {
    let total = 0;

    for (let i = 0; i < index; i++) {
      if (arr[i].hasOwnProperty("measureCount")) {
        total = +arr[i].measureCount + total;
      }
    }

    return { tot: +total, nex: +total + +full };
  }
  const TableValues = ({
    task,
    index,
    taskArr,
    disabled,
    total,
    completedCount,
    measureCount,
  }) => {
    const isError = store.getState().profile.isError;
    const errIndex = store.getState().profile.errIndex;
    const { tot, nex } = totalMeasureCountBeforeIndex(
      taskArr,
      index,
      task.measureCount
    );
    // console.log(tot, nex, "net");
    return (
      <Box key={task.taskId} sx={{ overflow: "hidden", minHeight: 50 }}>
        <Paper
          elevation={0}
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            ...paperBody,
          }}
        >
          <Box
            sx={{
              width: "10%",
              minWidth: "10%",
              // pl: 2,
              ...allpageFontStyle.typography1,
              display: "flex",
              justifyContent: "center",
              ...paperBody,
            }}
          >
            {index + 1}
          </Box>
          <TextField
            sx={{
              ...allpageFontStyle.typography1,
              width: "30%",
              minWidth: "30%",
              pl: 1,
              ml: 1,
              ...paperBody,
            }}
            inputProps={{ style: { height: 15 } }}
            value={task.measureCount}
            autoFocus={index === autoFocus}
            onChange={(e) => {
              const reg = /^\d+$/;
              if (reg.test(e.target.value, index) || e.target.value === "")
                onChangeCount(e.target.value, index, null, completedCount);
            }}
            disabled={disabled}
            // disabled={disabled || index === 0}
            size="small"
            // helperText={errIndex.index === index ? errIndex.msg : ''}
            error={
              (isError &&
                (task.measureCount === 0 || task.measureCount === "")) ||
              errIndex.index === index
            }
            // inputProps={{
            //   inputProps: { min: 1 }
            // }}
          />
          <Box
            sx={{
              width: "10%",
              minWidth: "10%",
              // pl: 2,
              ...allpageFontStyle.typography1,
              display: "flex",
              justifyContent: "center",
              ...paperBody,
            }}
          >
            {tot === 0 ? 1 : tot}-{nex}
          </Box>
          <Box
            sx={{
              fontFamily: "lato",
              width: "30%",
              minWidth: "30%",
              pl: 1,
              ...allpageFontStyle.typography1,
              ...paperBody,
            }}
          >
            {secondsToHMS(task?.productionHours)}
          </Box>
          {index === 0 ? (
            <Box
              sx={{ p: 1 }}
              // onClick={() => {
              //   !disabled && addArray(null, null, null, completedCount);
              //   // navigate(`/tasks/split/:${selectedRow.taskId}`)
              // }}
            >
              <Button
                sx={{ borderRadius: 5, ...allpageFontStyle.buttonFontStyle2 }}
                disabled={measureCount <= total || disabled}
                variant="contained"
                onClick={() => {
                  !disabled && addArray(null, null, null, completedCount);
                  // navigate(`/tasks/split/:${selectedRow.taskId}`)
                }}
                color="primary"
                size="small"
              >
                Split
              </Button>
            </Box>
          ) : null}
          {!disabled && getSplitField()?.length !== 1 ? (
            <Box
              sx={{
                display: "flex",
                width: "30%",
                minWidth: "30%",
              }}
            >
              <Box sx={{ p: 1 }}>
                <Box>
                  <IconButton
                    sx={{ borderRadius: 5, fontSize: 12 }}
                    variant="outlined"
                    onClick={() => {
                      removeData(index);
                    }}
                    size="small"
                    color="error"
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ p: 1 }}></Box>
            </Box>
          ) : (
            <Box sx={{ p: 1 }}></Box>
          )}
        </Paper>
        <Typography
          sx={{
            fontSize: 11,
            color: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {errIndex.index === index ? errIndex.msg : ""}
        </Typography>
        <Divider variant="middle" flexItem />
      </Box>
    );
  };
  const UpcomingTasks = ({ task }) => {
    const upcomingTaskData = store.getState().profile.upcomingTaskData;
    return (
      <Box sx={{ mt: 2 }}>
        <MainTask selectedRow={task} hide={true} />
        {upcomingTaskData[task.taskId]?.map((task, index) => (
          <TableValues task={task} index={index} disabled />
        ))}
      </Box>
    );
  };
  const customTaskId = (taskId, index) => {
    // const splitData = store.getState().profile.splitData;
    // return index === 0 ? taskId : `${taskId}_${splitData?.taskSplitInitialCount[taskId] + index}`
    // return `${taskId}_${splitData?.taskSplitInitialCount[taskId] + (index + 1)}`
    return `${taskId}_S${index + 1}`;
  };
  const updateTaskDatas = async (data, completedCount, isAutoSplit) => {
    const selectedRow = store.getState().profile.selectedRow;
    const upcomingTaskData = store.getState().profile.upcomingTaskData;
    const splitData = store.getState().profile.splitData;
    try {
      let upcomingTaskId = Object.keys(
        getSplitData()?.taskSplitInitialCount
      ).filter((i) => i !== selectedRow.taskId);
      // add splitted task for upcoming task followed by selected task(data) split count (parent tasks)
      const updatedTaskId = data?.map((i, index) => {
        const t = { ...i };
        t.taskId = customTaskId(selectedRow.taskId, index);
        delete t.id;
        delete t.updatedOn;
        delete t.updatedBy;
        delete t.createdBy;
        delete t.createdOn;
        // if (index !== 0) {
        t.splitFromTask = selectedRow.taskId;
        t.state = "Not Started";
        t.status = "Active";
        t.assignedTo = "Unassigned";
        // }`
        return t;
      });
      setsplitField(updatedTaskId);
      let t = {};
      // upcoming task construction for child task split2

      splitData?.data?.map((task, position) => {
        let temp = [];
        if (+completedCount) {
          temp = [selectedRow, ...updatedTaskId].map((i, index) => {
            let prevTask =
              position === 0
                ? i?.taskId
                : t[upcomingTaskId[position - 1]][index].taskId;
            // let prevTask = t[upcomingTaskId[position - 1]][index].taskId;
            let mc =
              completedCount && index === 0 ? completedCount : i?.measureCount;
            let res = {
              ...task,
              // id: Math.random(),
              measureCount: +mc,
              productionHours: prodHrCal(mc, task),
              taskId: customTaskId(task.taskId, index),
              previousTask: prevTask,
              taskInfo: {
                // id: Math.random(),
                ...task.taskInfo,
              },
            };
            delete res.id;
            delete res.updatedOn;
            delete res.updatedBy;
            delete res.createdOn;
            delete res.createdBy;
            // if (index !== 0) {
            res.splitFromTask = task.taskId;
            res.state = "Not Started";
            // res.status = "Active";
            res.assignedTo = "Unassigned";
            // }
            return res;
          });
        } else {
          temp = data.map((i, index) => {
            let prevTask =
              position === 0
                ? !isAutoSplit
                  ? i?.taskId
                  : customTaskId(i?.taskId, index)
                : t[upcomingTaskId[position - 1]][index].taskId;
            // let prevTask = t[upcomingTaskId[position - 1]][index].taskId;
            let res = {
              ...task,
              // id: Math.random(),
              measureCount: +i.measureCount,
              productionHours: prodHrCal(i.measureCount, task),
              taskId: customTaskId(task.taskId, index),
              previousTask: prevTask,
              taskInfo: {
                // id: Math.random(),
                ...task.taskInfo,
              },
            };
            delete res.id;
            delete res.updatedOn;
            delete res.updatedBy;
            delete res.createdOn;
            delete res.createdBy;
            // if (index !== 0) {
            res.splitFromTask = task.taskId;
            res.state = "Not Started";
            // res.status = "Active";
            res.assignedTo = "Unassigned";
            // }
            return res;
          });
          console.log(temp, "res");
        }

        t[task.taskId] = temp;
      });
      setUpcomingTaskData({
        ...upcomingTaskData,
        // [task.taskId]: temp
        ...t,
      });
    } catch (error) {}
  };
  const constructObj = (t) => {
    let res = {
      ...t,
      // id: Math.random(),
      taskInfo: {
        // id: Math.random(),
        ...t.taskInfo,
      },
    };
    delete res.jobInfo;
    delete res.hours;
    delete res.mints;
    delete res?.taskInfo?.id;
    delete res?.id;
    delete res?.updatedBy;
    delete res?.updatedOn;
    delete res?.createdBy;
    delete res?.createdOn;
    return res;
  };
  const addArray = async (
    e,
    mCount,
    arrOfCount,
    completedCount,
    isAutoSplit
  ) => {
    const selectedRow = store.getState().profile.selectedRow;
    const splitField = store.getState().profile.splitField;
    if (arrOfCount?.length) {
      const arr = [];
      arrOfCount?.forEach((mCount) => {
        try {
          const t = e || selectedRow;
          let res = {
            ...t,
            // id: Math.random(),
            taskInfo: {
              // id: Math.random(),
              ...t.taskInfo,
            },
          };
          delete res.jobInfo;
          delete res.hours;
          delete res.mints;
          delete res?.taskInfo?.id;
          res.measureCount = mCount;
          res.productionHours = prodHrCal(mCount, selectedRow);
          arr.push(res);
          setisActive(!isActive);
        } catch (error) {}
      });
      updateTaskDatas(arr, completedCount, isAutoSplit);
    } else {
      try {
        // add split task for selected task
        const t = e || selectedRow;
        const arr = [...splitField];
        let res = constructObj(t);
        // below condition (arr?.length !== 0) make measurecount & productionHours of first row stays as paraent task's count
        // if (arr?.length !== 0) {
        res.measureCount = "";
        res.productionHours = 0;
        // }
        // else res.taskId = customTaskId(res.taskId,1)
        if (mCount && arr.length) {
          arr.push(res);
          onChangeCount(mCount, arr.length - 1, arr);
        } else {
          arr.push(res);
          updateTaskDatas(arr, completedCount);
        }
        setisActive(!isActive);
      } catch (error) {}
    }
  };
  const prodHrCal = (count, selectedRow) => {
    const actMin = +selectedRow.productionHours;
    const actCount = +selectedRow.measureCount;
    const minPerCount = (actMin / actCount) * count;
    return minPerCount;
  };
  const removeData = (index) => {
    const selectedRow = store.getState().profile.selectedRow;
    const splitField = store.getState().profile.splitField;
    let range = [...splitField];
    range.splice(index, 1);
    // if (range?.length) {
    //   const totalCount = range?.reduce((total, data) => total + +data?.measureCount, 0);
    //   const diff = +selectedRow?.measureCount - +totalCount;
    //   if (diff) range[0] = { ...range[0], measureCount: +range[0]?.measureCount + diff, productionHours: prodHrCal(+range[0]?.measureCount + diff, selectedRow) }
    // }
    setErrIndex({ index: null, msg: "" });
    updateTaskDatas(range);
    setisActive(!isActive);
  };

  // const [isError, setIsError] = useState(false)
  const handleSubmit = async (completedCount, callBack, selectedRow1) => {
    // const payload = splitField.map((val, i) => ({
    //   taskId: i === 0 ? val.taskId : val.taskId + "_" + i,
    //   jobCode: val.jobCode,
    //   taskInfo: constructObject(val),
    //   status: val.status,
    //   state: val.state,
    //   assignedTo: val.assignedTo,
    //   assignedOn: val.assignedOn,
    //   productionHours: `${convertH2M(`${val.hours}:${val.mints}`)}`,
    //   category: val.category,
    //   taskType: val.taskType,
    //   previousTask: val.previousTask,
    // }));

    const splitField = store.getState().profile.splitField;
    const selectedRow = store.getState().profile.selectedRow;
    const splitData = store.getState().profile.splitData;
    const upcomingTaskData = store.getState().profile.upcomingTaskData;
    const upcomingList = Object.values(upcomingTaskData);
    const t = [...splitField, ...upcomingList?.flat()];
    const isFind = t.find((i) => i.measureCount === 0 || i.measureCount === "");
    const parentTask = splitData?.data?.map((i) => {
      const t = { ...constructObj(i) };
      t.measureCount = i.measureCount?.toString();
      t.productionHours = +i.productionHours;
      // t.status = 'Resolved';
      // t.state = 'End'
      return t;
    });
    const t1 = { ...constructObj(selectedRow) };
    t1.measureCount = selectedRow1.measureCount?.toString();
    t1.productionHours = +selectedRow1.productionHours;
    // t1.status = 'Resolved';
    // t1.state = 'End'
    if (completedCount) t1.completedCount = completedCount;
    const payload = {
      parent_task: [t1, ...parentTask],
      split_task: [...splitField, ...upcomingList.flat()],
    };
    const te = () => {
      setShow(false);
      updateTaskDatas([]);
      setIsError(false);
    };
    if (isFind) return setIsError(true);
    else {
      await dispatch(SplitTaskDataAction(payload, te));
      reset();
      assign(false, false);
      callBack && callBack();
      // dispatch(getAssignedTaskAction());
    }
  };

  // const [errIndex, setErrIndex] = useState({
  //   index: [],
  //   msg: ''
  // });
  const clearErr = () => {
    setErrIndex({
      index: null,
      msg: "",
    });
  };

  const onChangeCount = (count, index, arr, completedCount) => {
    if (count >= 0) {
      const selectedRow = store.getState().profile.selectedRow;
      const splitField = store.getState().profile.splitField;
      setAutoFocus(index);
      try {
        const t = arr || splitField;
        let tempData = [...t];
        let excess;
        tempData[index] = {
          ...tempData[index],
          measureCount: count,
          productionHours: prodHrCal(count, selectedRow),
        };
        const totalCount = tempData?.reduce(
          (total, data) => total + +data?.measureCount,
          0
        );
        const diff = +totalCount - +selectedRow?.measureCount;
        updateTaskDatas(tempData, completedCount);
        // if (diff < 0) {
        //   excess = Math.abs(diff);
        //   setBalance({ ...tempData[0], measureCount: excess + tempData[0].measureCount, productionHours: prodHrCal(excess + tempData[0].measureCount, selectedRow) });
        //   // tempData[0] = { ...tempData[0], measureCount: excess + tempData[0].measureCount, productionHours: prodHrCal(excess + tempData[0].measureCount, selectedRow) };
        //   updateTaskDatas(tempData, completedCount);
        //   clearErr()
        // } else if (diff > 0) {
        //   const mainDiff = (+tempData[0]?.measureCount - diff)
        //   if (mainDiff >= 1) {
        //     excess = +mainDiff;
        //     setBalance({ ...tempData[0], measureCount: excess, productionHours: prodHrCal(excess, selectedRow) });
        //     // tempData[0] = { ...tempData[0], measureCount: excess, productionHours: prodHrCal(excess, selectedRow)};
        //     updateTaskDatas(tempData, completedCount);
        //     clearErr()
        //   } else {
        //     setErrIndex({ index, msg: `Exceed Total Count` });
        //     setTimeout(() => setErrIndex({ index: null, msg: '' }), 2000)
        //   }
        // } else {
        //   setErrIndex({ index: null, msg: '' })
        // }
      } catch (error) {}
    }
  };

  const reset2 = () => {
    setjobCode1("");
    setprojectCode1("");
    setConstructData([]);
    dispatch(
      updateMasterStates({
        name: "projectCodeDta",
        value: {},
      })
    );
    dispatch(
      updateMasterStates({
        name: "assignedtaskDataSplit",
        value: null,
      })
    );
  };
  const getDynamicData = () => {
    const conData = assignedTaskList[0]?.jobInfo?.displayColumns
      ?.split(",")
      ?.map((name) => {
        return {
          field: `taskInfo.${name}`,
          headerName: name,
          width:
            +((assignedTaskList[0]?.taskInfo?.[name]?.length / 2) * 20) || 100,
          align: "center",
          headerAlign: "center",
          valueGetter: (val) => {
            return val?.row?.taskInfo?.[name];
          },
        };
      });
    return conData;
  };
  // const [pageNumber, setPageNumber] = useState(1);
  // const [itemPerPage, setItemPerPage] = useState(100);
  // const [totalPage, setTotalPage] = useState(0);
  const changePageSize = (newPageSize) => {
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    onScrollFunctin(
      newPageSize.page,
      newPageSize.pageSize,
      checkedState?.split,
      checkedState?.revert
    );
  };
  const getSplitData = () => {
    return store.getState().profile.splitData;
  };
  const getSplitField = () => {
    return store.getState().profile.splitField;
  };

  const column = [
    {
      field: "measureCount",
      headerName: "Page Count",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "taskId",
      headerName: "Task Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (val) => (val.status === "Active" ? "Open" : val.status),
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (val) => (val?.value?.trim() ? val?.value : "NA"),
      // valueGetter: (params) => params.row?.taskInfo?.assignedTo,
    },
    {
      field: "taskType",
      headerName: "Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: "Action",
      width: 200,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {allowedPages().WorkStation.taskSplit.split && (
            <SplitDialog
              splitField={getSplitField()}
              splitData={getSplitData()}
              handleSubmit={handleSubmit}
              paperTitle={paperTitle}
              selectedRow={params.row}
              MainTask={MainTask}
              TableValues={TableValues}
              UpcomingTasks={UpcomingTasks}
              handleClickOpen={() => handleClickOpen(params.row)}
              getSplitDetail={() => getSplitDetail(params.row)}
              reset={reset}
              addArray={addArray}
              setSelectedRow={setSelectedRow}
              setsplitField={setsplitField}
              prodHrCal={prodHrCal}
              setIsEmptyError={setIsError}
            />
          )}
          <Box title="View">
            <EyeIcon
              taskId={params.row.taskId}
              jobcode={params.row.jobCode}
              clickOpen={true}
              clickclose={false}
            />
          </Box>
        </Box>
      ),
    },
  ];
  useEffect(() => {
    if (assignedTaskList?.length) {
      const t = getDynamicData();
      setColumns([...t, ...column]);
    }
  }, [assignedTaskList]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    // dispatch(getAssignedTaskAction());
    return () => {
      reset2();
    };
  }, []);
  useEffect(() => {
    reset2();
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
  }, [globapOrganization?.orgId]);
  return (
    <Box
      sx={{
        p: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        height: "100%",
        minHeight: 600,
        background: grey[100],
      }}
    >
      <Card sx={{ ...formContainerCardStyle, p: 3, minHeight: 650 }}>
        <Grid container spacing={2} sx={{ mx: 7 }}>
          <Grid item xs={12} md={12} lg={1}></Grid>
          <Grid item xs={6} md={8} lg={4}>
            <SelectComponent
              DataOptions={projectCodeDta?.project_details}
              labelName={(option) => option.projectCode}
              value={projectCode1}
              label={"Project Code"}
              handelChange={handelProjectCode}
            />
          </Grid>
          <Grid item xs={6} md={8} lg={4}>
            <SelectComponent
              DataOptions={constructData.jobList}
              labelName={(option) => option.jobCode}
              value={jobCode1}
              label={"Job Code"}
              handelChange={handelJopcode}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Button
              size="small"
              sx={{
                ml: 2,
                ...allpageFontStyle.buttonFontStyle2,
                borderRadius: 5,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
              }}
              disabled={!projectCode1 || !jobCode1}
              onClick={() => assign(false, false)}
              variant="contained"
            >
              Load
            </Button>
          </Grid>
        </Grid>
        {/* <Box sx={{
            width: '90%',

          }}>
            {assignedTaskList && assignedTaskList.length > 0 ?
              <div class="container" onScroll={onScroll}
                ref={listInnerRef}
              >

                <table className="tamain">
                  <thead className="tahead">
                    <tr>
                      <th className="thta">S.No</th>
                      {
                        assignedTaskList?.length ? Object.keys(assignedTaskList[0]?.taskInfo).map(name => (
                          (name !== 'Page Count' && name !== 'category') ?
                            <th style={{}}>
                              {name}
                            </th> : null
                        ))
                          : null
                      }
                      <th className="thta">Task Name</th>
                      <th className="thta">Status</th>
                      <th className="thta">State</th>
                      <th className="thta">Assigned To</th>
                      <th className="thta">Name</th>
                      <th className="thta">Count</th>
                      <th className="thta">Estimate Hours</th>
                      <th className="thta">Action</th>
                    </tr>
                  </thead>
                  <tbody className="tablebody" >
                    {
                      assignedTaskList?.map((val, index) => (
                        <>
                          <tr>
                            <td className="tdta">{index + 1}</td>
                            {
                              Object.keys(val?.taskInfo).map(name => (
                                (name !== 'Page Count' && name !== 'category') ?
                                  <td sx={{}}>
                                    {val?.taskInfo[name]}
                                  </td> : null
                              ))
                            }
                            <td className="tdta">{val.taskId}</td>
                            <td className="tdta">{val.status === 'Active' ? 'Open' : val.status}</td>
                            <td className="tdta">{val.state}</td>
                            <td className="tdta">{val.assignedTo}</td>
                            <td className="tdta">{val.taskType}</td>
                            <td className="tdta">{val.measureCount}</td>
                            <td className="tdta">{secondsToHMS(val?.productionHours)}</td>
                            <td className="tdta"> <SplitDialog
                              splitField={splitField}
                              splitData={splitData}
                              handleSubmit={handleSubmit}
                              paperTitle={paperTitle}
                              selectedRow={val}
                              MainTask={MainTask}
                              TableValues={TableValues}
                              UpcomingTasks={UpcomingTasks}
                              handleClickOpen={() => handleClickOpen(val)}
                              getSplitDetail={() => getSplitDetail(val)}
                              reset={reset}
                              addArray={addArray}
                            />
                              <Box sx={{ p: 1 }}></Box></td>
                          </tr>
                        </>))}
                  </tbody >
                </table>

              </div> : null}
          </Box> */}
        <Box
          sx={{
            pl: 5,
            pr: 3,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          {/* {
          columns?.length ? */}
          <DataGride
            columns={columns}
            customHeight={550}
            disableCheckbox={true}
            // selectionIs={selectionIs}
            // selectionState={selectionState}
            // selectedFile={selectedFile}
            // onSelectionModelChange={onSelectionModelChange}
            // deleteFuntion={handleDeleteClick}
            // getRowId={(data) => data?.}
            filterCheckBox={checkBoxFilter}
            getRowClassName={handelRowColor}
            tableData={assignedTaskList || []}
            pageSize={itemPerPage}
            page={pageNumber}
            onPageSizeChange={changePageSize}
            rowCount={totalPage}
            paginationMode={true}
            hideDeletion={true}
            // onPageChange={changePage}
            pageName={"project"}
          />
          {/* : null
        } */}
        </Box>
      </Card>
    </Box>
  );
}

export default TaskSplit;
