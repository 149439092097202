import firebase from 'firebase/app';
import 'firebase/messaging';


const apiKey = process.env.REACT_APP_APIKEY
const authDomain = process.env.REACT_APP_AUTH_DOMAIN
const projectId = process.env.REACT_APP_PROJECT_ID
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
const messageSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
const appId = process.env.REACT_APP_APP_ID
const measurementId = process.env.REACT_APP_MEASUREMENT_ID
const vapidKey = process.env.REACT_APP_VAPID_KEY

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messageSenderId,
  appId: appId,
  measurementId: measurementId
};

console.log(firebaseConfig, 'currentTokencurrentToken')
firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();

console.log(firebase.messaging(), 'qqqcurrentTokencurrentToken')
export const messaging =  firebase.messaging()
export const getToken = (setTokenFound, setFirebaseToken) => {
  try {
    return messaging
      .getToken({ vapidKey: vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log(currentToken, 'currentTokencurrentToken')
          setTokenFound(true);
          setFirebaseToken(currentToken);
          // const dispatch = useDispatch();
          // dispatch({ type: STORE_USERTOKEN, payload: currentToken });
        } else {
          setTokenFound(false);
        }
      })
      .catch((err) => {
        console.log(err, 'currentTokencurrentTokenerr')
      });
    } catch (error) {
    console.log(error, 'currentTokencurrentTokenerr')

  }

};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
