import { yupResolver } from "@hookform/resolvers/yup";
import {
  Attachment,
  CloseRounded,
  CloudUpload,
  CopyAll,
  Delete,
  Done,
  Download,
  DragIndicator,
  Edit,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import DataGride from "../../components/datagride/DataGride";
import {
  ProjectMaster,
  departmentMasterAction,
  editProjectDetailsAction,
  getDepartmentByTeamAction,
  getProjectCodeListAction,
  getProjectFilterAction,
} from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { updateProfileStates } from "../../redux/reducers/profileReducer";
import {
  codeCheckService,
  createEditGetUnitMaster,
  getPojectTeamUsersList,
  sendAttachmentProject,
} from "../../service/masterService";
import {
  fileDownloadStyle,
  fileNameStyle,
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
  textFieldsStyle,
} from "../../utils/Style";
import {
  PopupMessage,
  TriggerAlert,
  checkBoxFilter,
  removeDuplicatesFromArray,
} from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import "./createProject.css";
import { ATTACH_MESSAGE_PROJECT, RESET_LABEL } from "../../utils/textName";
import ViewPopUp from "../../utils/viewPopUp";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import DateComponet from "../../components/commonComponents/DateComponet";
import Swal from "sweetalert2";
import {
  deleteFile,
  downloadFileService,
  sendAttachment,
} from "../../service/helpdeskService";
import PopupComponent from "../../components/commonComponents/PopupComponent";

const schema = yup.object().shape({
  projectId: yup.string(),
  projectName: yup.string().required("Name Required"),
  scopeOfProject: yup.string().required("Scope Of Project Required"),
  projectCode: yup.string().required("Code Required"),
  attachmentLocations: yup.string(),
  team: yup
    .array()
    .required("Team Required")
    .test({
      message: "Team Required",
      test: (arr) => arr.length !== 0,
    }),
  troubleShootUsers: yup
    .array()
    .required("Troubleshooters Required")
    .test({
      message: "Troubleshooters Required",
      test: (arr) => arr.length !== 0,
    }),
  tasks: yup.array().of(
    yup.object().shape({
      category: yup.string().required("required"),
      projectCode: yup.string(),
      taskName: yup.string().required("required"),
      unitType: yup.string().required("required"),
      unitOfMeasure: yup.string().nullable().required("required"),
      uomProdTime: yup.string().nullable().required("required"),
      mints: yup.string().required("required"),
      seconds: yup.string().required("required"),
    })
  ),
  depId: yup.string().required("Department Required"),
  notes: yup.string().nullable(),
});
function CreatePro() {
  const { projectFilter, projectcodeCheck } = useSelector(
    (state) => state.master
  );
  const { globapOrganization, projectsaveData } = useSelector(
    (state) => state.presist
  );
  const [isActive2, setIsActive2] = React.useState(true);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [dep, setDep] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { departmentTeamList } = useSelector((state) => state.profile);
  const { departmentList, projectCodeDta } = useSelector(
    (state) => state.master
  );

  const [viewData, setViewData] = useState({
    troubleShooters: [],
    task: [],
  });
  const fileRef = React.useRef(null);
  const [fileValue, setFileValue] = React.useState();
  const [fileInfo, setFileInfo] = React.useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [error, setError] = useState("");
  const [editTitle, setEdittitle] = useState(false);
  const [teamid, setTeamid] = useState("");
  const [selectionIs, setSelectionIs] = useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [Preview, setPreview] = React.useState([]);
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [masterArray, setMasterArray] = React.useState("");
  const [constructData, setConstructData] = React.useState([]);
  const [troubleShoot, setTroubleShoot] = React.useState([]);
  // const [end, setEnd] = React.useState("");
  const [id, setId] = React.useState("");
  const [projectCode1, setprojectCode1] = useState("");
  const [state, setState] = React.useState({
    depId: null,
    teamId: null,
    projectCode: null,
    fromDate: null,
    toDate: null,
  });
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setFileInfo("");
      setShow(false);
      setEdit(false);
      navigate("/project");
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      reset();
      setFileInfo("");
      setShow(false);
      setEdit(false);
      navigate("/project");
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const scrollingContainerRef = useRef(null);
  const [rowsData, setRowsData] = useState(null);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const onDragStart = (e, index) => {
    // Store the index of the dragged row in the data transfer
    setDraggedRowIndex(index);
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, i) => {
    // Allow dropping on the row
    setRowsData(i);
    e.preventDefault();
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      getProjectFilterAction(
        globapOrganization?.orgId,
        state,
        pageNumber,
        itemPerPage,
        e ? "&isActive=True" : "&isActive=False"
      )
    );
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          ProjectMaster(
            { id: row },
            "delete",
            `/project/info/${globapOrganization?.orgId}/`,
            true
          )
        );
        setSelectionState([]);
        handelActiveInactive(true);
      }
    });
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          ProjectMaster(
            { id: [row?.id] },
            "put",
            `/project/info/${globapOrganization?.orgId}/`,
            true
          )
        );
        handelActiveInactive(false);
      }
    });
  };
  const onDrop = (e, dropIndex) => {
    // Get the index of the dragged row from the data transfer
    const dragIndex = e.dataTransfer.getData("index");
    const draggedRow = getValues("tasks")[dragIndex];

    // Remove the dragged row from the original position
    const updatedRowsData = getValues("tasks").filter(
      (row, index) => index !== +dragIndex
    );

    // Insert the dragged row at the new position
    updatedRowsData.splice(dropIndex, 0, draggedRow);

    // Update the state with the new order of rows
    setValue("tasks", updatedRowsData);
    setRowsData(null);
    setDraggedRowIndex(null);
  };
  const autoScrollToBottom = () => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollIntoView();
    }
  };
  const handleButtonClick = () => {
    autoScrollToBottom();
  };
  React.useEffect(() => {
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
  }, [globapOrganization]);
  React.useEffect(() => {
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    const getMasterList = async () => {
      try {
        const res = await createEditGetUnitMaster(
          "get",
          {},
          globapOrganization?.orgId,
          "?isActive=True"
        );
        if (res) {
          console.log(res, "res");
          const filterdata = res?.data;
          const mapData = filterdata.map((val) => val.unitName) || [];
          setMasterArray(mapData);
        } else {
          setMasterArray([]);
        }
      } catch (error) {}
    };
    getMasterList();
  }, [globapOrganization]);
  useEffect(() => {
    if (projectFilter?.pageInfo?.num_pages) {
      setTotalPage(projectFilter.pageInfo?.total_items);
    } else {
      setTotalPage(1);
    }
  }, [projectFilter]);
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      projectId: "",
      team: [],
      troubleShootUsers: [],
      projectName: "",
      projectCode: "",
      scopeOfProject: "",
      notes: "",
      tasks: [
        {
          category: "",
          projectCode: "",
          taskName: "",
          unitType: "",
          unitOfMeasure: "",
          uomProdTime: 0,
          mints: 0,
          seconds: 0,
        },
      ],
      depId: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = form;

  const [isActive, setisActive] = useState(true);

  const fieldContainer = {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    p: 2,
  };
  const labelStyle = {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 2,
    fontSize: "13px",
    fontWeight: "bold",
    p: 1,
    width: 200,
    fontFamily: "Lato",
  };
  const addArray = () => {
    const arr = getValues("tasks") || [];
    arr.push({
      category: "",
      taskName: "",
      unitType: "",
      unitOfMeasure: "",
      uomProdTime: 0,
      mints: 0,
      seconds: 0,
    });
    setisActive(!isActive);
    handleButtonClick();
    setValue("tasks", arr);
  };
  const removeData = (index) => {
    const range = getValues("tasks");
    range.splice(index, 1);
    setValue("tasks", range);
    setisActive(!isActive);
  };
  // const constructArr = (arr, code) => {
  //   const res = arr.map((val, i) => ({
  //     projectCode: code,
  //     category: val.category,
  //     taskName: val.taskName,
  //     unitType: val.unitType,
  //     unitOfMeasure: val.unitOfMeasure,
  //     uomProdTime: `${val.uomProdTime.split(":").length > 0
  //         ? val.uomProdTime.split(":")[0]
  //         : val.uomProdTime
  //       }:${val.mints}:${val.seconds}`,
  //     indexVal: i,
  //   }));
  //   return res;
  // };
  const constructArr1 = (arr, code) => {
    const res = arr.map((val, i) => ({
      projectCode: code,
      category: val.category,
      taskName: val.taskName,
      unitType: val.unitType,
      unitOfMeasure: val.unitOfMeasure,
      uomProdTime: `${val.uomProdTime}:${val.mints}:${val.seconds}`,
      indexVal: i,
    }));
    setValue("tasks", res);
    return res;
  };
  const constructArrExampleData = (arr) => {
    const res = arr.map((val, i) => ({
      // projectCode: code,
      category: val?.category,
      taskName: val?.taskName,
      unitType: val?.unitType,
      unitOfMeasure: val?.unitOfMeasure,
      uomProdTime: `${
        val.uomProdTime.split(":").length > 0
          ? val?.uomProdTime.split(":")[0]
          : val?.uomProdTime
      }`,
      mints: val?.mints,
      seconds: val?.seconds,
      indexVal: i,
    }));
    setValue("tasks", res);
    return res;
  };
  const constructArrEdit = (arr) => {
    const res = arr.map((val, i) => ({
      projectCode: val.projectCode,
      category: val.category,
      taskName: val.taskName,
      unitType: val.unitType,
      unitOfMeasure: val.unitOfMeasure,
      uomProdTime: val.uomProdTime.split(":")[0],
      mints: val.uomProdTime.split(":")[1],
      seconds: val.uomProdTime.split(":")[2],
      indexVal: i,
    }));
    return res;
  };
  const constructArrTeamEdit = (arr) => {
    const res = arr.map((val, i) => ({
      id: val.id,
      teamId: val.teamId,
      teamName: val.teamName,
    }));
    return res;
  };
  // const constructTeamArr = (arr) => {
  //   const teamsList = arr?.team?.map((teamsAvailable) => ({
  //     id: 1,
  //     teamId: teamsAvailable,
  //     teamName: departmentTeamList.find(teamsData => teamsData.teamId === teamsAvailable)?.teamName,
  //     projectCode: arr.projectCode,
  //     createdBy: "js@gmail.com",
  //     updatedBy: "js@gmail.com",
  //     createdOn: "2023-06-07T11:59:03.648306Z",
  //     updatedOn: "2023-06-07T11:59:03.659190Z",
  //   }
  //   ))
  //   return teamsList;
  // };
  const constructArrTeam = (arr, code) => {
    const res = arr.map((val) => ({
      projectCode: code,
      teamId: val.teamId,
    }));
    return res;
  };
  const constructArrShoot = (arr, code) => {
    const res = arr.map((val) => ({
      projectCode: code,
      userName: val.empId,
    }));
    return res;
  };
  const handelCloseApi = () => {
    setEdittitle(false);
    // setShow(false);
    setConstructData([]);
    // dispatch(
    //   getProjectFilterAction(
    //     globapOrganization?.orgId,
    //     state,
    //     pageNumber,
    //     itemPerPage
    //   )
    // );
    fileRef.current.value = null;
    setFileValue("");
    setFileInfo("");
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
    reset();
  };
  function myFunc(obj, prop) {
    return obj.reduce(function (acc, item) {
      let key = item[prop].toLowerCase();

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);

      return acc;
    }, {});
  }
  function myFuncDuplicateData(obj, categoryKey, taskNameKey) {
    const categoryMap = {};

    for (const item of obj) {
      const category = item[categoryKey].toLowerCase();
      const taskName = item[taskNameKey];

      if (!categoryMap[category]) {
        categoryMap[category] = new Set();
      }

      if (categoryMap[category].has(taskName)) {
        return false; // Duplicate task name found
      }

      categoryMap[category].add(taskName);
    }

    return true; // No duplicate task names found
  }
  const checkArrayLengths = (object) => {
    const arrayLengths = Object.values(object).map((value) => value.length);
    const firstLength = arrayLengths[0];
    return arrayLengths.every((length) => length === firstLength);
  };

  const arraysHaveSameKeyValuePairs = (object, key) => {
    const arrays = Object.values(object);

    if (arrays.length === 0) {
      return true; // No arrays, so they technically have the same key-value pairs
    }

    const firstArray = arrays[0];

    return arrays.every(
      (array) =>
        array.length === firstArray.length &&
        arraysOfObjectsHaveSameKeyValuePairs(array, firstArray, key)
    );
  };

  const arraysOfObjectsHaveSameKeyValuePairs = (array1, array2, key) => {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.slice().sort(compareObjects);
    const sortedArray2 = array2.slice().sort(compareObjects);

    return sortedArray1.every((obj1, index) =>
      compareObjects(obj1, sortedArray2[index], key)
    );
  };

  const compareObjects = (obj1, obj2, key) => {
    return obj1[key] === obj2[key];
  };
  // const hasDuplicatesInArray = (array, keyToCheck, set = new Set()) =>
  //   array.some((obj) => {
  //     if (set.has(obj[keyToCheck])) {
  //       return true;
  //     }
  //     set.add(obj[keyToCheck]);

  //     return false;
  //   });
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data) => {
    console.log(data, "first");
    const dateres = constructArrExampleData(data.tasks, data.projectCode);
    const res = myFunc(dateres, "category");
    const result = checkArrayLengths(res);
    const result2 = arraysHaveSameKeyValuePairs(res, "taskName");
    const result4 = myFuncDuplicateData(dateres, "category", "taskName");
    // const result4 = checkSameKeyValueInObject(res, "taskName");
    // const result4 = Object.values(res).some((array) =>
    //   hasDuplicatesInArray(array, "taskName")
    // );

    // Check if there are any duplicates in the arrays using recursion
    const mergedObject = Object.values(res).reduce((result, array) => {
      return [...result, ...array];
    }, []);
    const result3 = mergedObject.every(
      (obj) => obj.unitType === mergedObject[0].unitType
    );
    // if (result4) {
    //   await setOpen(true);
    //   setError("category");
    //   constructArrExampleData(data.tasks);
    // }
    if (!result) {
      await setOpen(true);
      setError("category");
      constructArrExampleData(data.tasks);
    } else if (!result2) {
      setOpen(true);
      setError("taskName");
      constructArrExampleData(data.tasks);
    } else if (!result3) {
      setOpen(true);
      setError("unitType");
      constructArrExampleData(data.tasks);
    } else if (!result4) {
      setOpen(true);
      setError("cat");
      constructArrExampleData(data.tasks);
    }

    // setValue("tasks", mergedObject);

    if (!projectcodeCheck && result && result2 && result3 && result4) {
      data.project = {
        // id: id,
        depId: data.depId,
        notes: data.notes,
        projectCode: data.projectCode,
        projectName: data.projectName,
        scopeOfProject: data.scopeOfProject,
        attachmentLocations: data.attachmentLocations
          ? [data.attachmentLocations]
          : [],
        orgId: globapOrganization?.orgId,
        troubleShooterType:
          data?.troubleShootUsers?.length === 1 &&
          data.troubleShootUsers[0].firstname === "All"
            ? "All"
            : "User",
      };
      if (id) {
        data.project.id = id;
      }
      const day = await constructArr1(data.tasks, data.projectCode);
      data.tasks = day;
      data.team = constructArrTeam(data.team, data.projectCode);
      data.troubleShootUsers =
        data?.troubleShootUsers?.length === 1 &&
        data.troubleShootUsers[0].firstname === "All"
          ? "All"
          : constructArrShoot(data.troubleShootUsers, data.projectCode);
      delete data.depId;

      delete data.notes;
      delete data.projectCode;
      delete data.projectId;
      delete data.projectName;
      delete data.scopeOfProject;
      delete data.attachmentLocations;
      console.log(data, "data");
      if (editTitle) {
        await dispatch(
          ProjectMaster(
            data,
            "post",
            `/project/update_project/${globapOrganization?.orgId}/`,
            true
          )
        );
        handelCloseApi();
      } else {
        await dispatch(
          ProjectMaster(
            data,
            "post",
            `/project/info/${globapOrganization?.orgId}/`
          )
        );
        handelCloseApi();
      }
    }
  };
  const handelCodeChange = async (e) => {
    setValue("projectCode", e.target.value.toUpperCase());
    if (e.target.value.length > 2) {
      try {
        // dispatch(loading(true))
        await dispatch(
          codeCheckService(
            { projectCode: e.target.value },
            `/project/code/name/${globapOrganization?.orgId}/`,
            "projectcodeCheck"
          )
        ).then((response) => {
          // dispatch(loading(false))

          if (response.data) {
            dispatch(
              updateMasterStates({
                name: "projectcodeCheck",
                value: response.data,
              })
            );
          } else {
            dispatch(
              updateMasterStates({
                name: "projectcodeCheck",
                value: "",
              })
            );
          }
        });
      } catch (error) {
        // dispatch(loading(false))
      }
    }
    // if (e.target.value.length > 2) {
    //   const res = await dispatch(
    //     codeCheckService(
    //       { codeName: e.target.value },
    //       "/OgaiApp/team/code/name/",
    //       "teamcodeCheck"
    //     )
    //   );
    // }
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const numbers = Array(60)
    .fill()
    .map((_, index) => index);
  const handelDepartment = (e) => {
    setValue("team", []);

    if (e) return dispatch(getDepartmentByTeamAction(e?.target.value));
  };
  const handleEditClick = async (row, data) => {
    setEdittitle(true);
    navigate("/createProject");
    const t = await dispatch(
      editProjectDetailsAction("post", globapOrganization?.orgId, {
        projectCode: row?.projectCode,
      })
    );
    const teamData = t?.teamInfo?.map(
      (teamData) => teamData?.projectTeamDetail
    );
    handelChangemultiTeam(teamData);
    setId(t?.projectInfo[0]?.projectDetails?.id);
    setShow(true);
    dispatch(
      getDepartmentByTeamAction(t?.projectInfo[0]?.projectDetails?.depId)
    );
    navigate("/createProject");
    // dispatch(getDepartmentByTeam(t?.projectInfo[0]?.projectDetails?.depId));
    setPreview(t);
    if (data === "edit") {
      setSelectedRow(row);
      setEdit(true);
    }
    setValue("projectCode", t?.projectInfo[0]?.projectDetails?.projectCode);
    setValue("projectName", t?.projectInfo[0]?.projectDetails?.projectName);
    setValue("depId", t?.projectInfo[0]?.projectDetails?.depId);
    setValue("notes", t?.projectInfo[0]?.projectDetails?.notes);
    setValue(
      "scopeOfProject",
      t?.projectInfo[0]?.projectDetails?.scopeOfProject
    );

    setValue("team", constructArrTeamEdit(teamData));
    console.log(t?.projectInfo[0], "de");
    if (t?.fileInfo?.length > 0) {
      setFileInfo(t?.fileInfo[0]);
    } else {
      setFileInfo([]);
    }
    console.log(
      t?.projectInfo[0]?.projectDetails?.attachmentLocations[0],
      "attach"
    );
    setValue(
      "attachmentLocations",
      t?.projectInfo[0]?.projectDetails?.attachmentLocations?.length > 0
        ? t?.projectInfo[0]?.projectDetails?.attachmentLocations[0]
        : ""
    );
    setValue(
      "troubleShootUsers",
      t?.projectTroubleShootersInfo.length === 1 &&
        t?.projectTroubleShootersInfo[0] === "All"
        ? [{ firstname: "All", lastname: "" }]
        : t?.projectTroubleShootersInfo
    );

    const tasks = t?.taskInfo?.map((taskData) => taskData.projectTaskDetail);
    const taskres = constructArrEdit(tasks);
    setValue("tasks", taskres);
    // setValue(
    //   "depId",
    //   data === "edit" && row?.depId ? row?.depId : departmentAutoId?.id
    // );
    // setValue("depName", row?.depName);
    // setValue("shortName", row?.shortName);
    // setValue("codeName", row?.codeName);
    // setValue("description", row?.description);
  };
  const viewPop = async (row) => {
    const t = await dispatch(
      editProjectDetailsAction("post", globapOrganization?.orgId, {
        projectCode: row?.projectCode,
      })
    );

    setViewData({
      troubleShooters:
        t?.projectTroubleShootersInfo.length === 1 &&
        t?.projectTroubleShootersInfo[0] === "All"
          ? [{ firstname: "All", lastname: "" }]
          : t?.projectTroubleShootersInfo,
      task: t?.taskInfo?.map((taskData) => taskData.projectTaskDetail),
    });
  };
  const handleView = async (row) => {
    setEdit(true);
    setEdittitle(false);
    setView(true);
    const t = await dispatch(
      editProjectDetailsAction("post", globapOrganization?.orgId, {
        projectCode: row?.projectCode,
      })
    );
    const teamData = t?.teamInfo?.map(
      (teamData) => teamData?.projectTeamDetail
    );
    handelChangemultiTeam(teamData);
    setValue(
      "troubleShootUsers",
      t?.projectTroubleShootersInfo.length === 1 &&
        t?.projectTroubleShootersInfo[0] === "All"
        ? [{ firstname: "All", lastname: "" }]
        : t?.projectTroubleShootersInfo
    );
    setId(t?.projectInfo[0]?.projectDetails?.id);
    setShow(true);
    dispatch(
      getDepartmentByTeamAction(t?.projectInfo[0]?.projectDetails?.depId)
    );
    navigate("/createProject");
    // dispatch(getDepartmentByTeam(t?.projectInfo[0]?.projectDetails?.depId));
    setPreview(t);

    setValue("projectCode", t?.projectInfo[0]?.projectDetails?.projectCode);
    setValue("projectName", t?.projectInfo[0]?.projectDetails?.projectName);
    setValue("depId", t?.projectInfo[0]?.projectDetails?.depId);
    setValue("notes", t?.projectInfo[0]?.projectDetails?.notes);
    setValue(
      "scopeOfProject",
      t?.projectInfo[0]?.projectDetails?.scopeOfProject
    );

    setValue("team", constructArrTeamEdit(teamData));

    const tasks = t?.taskInfo?.map((taskData) => taskData.projectTaskDetail);
    const taskres = constructArrEdit(tasks);
    setValue("tasks", taskres);
  };
  const handleAutoSave = (row) => {
    setValue("projectCode", row?.projectCode);
    setValue("projectName", row?.projectName);
    // setValue("depId", row?.depId);
    setValue("notes", row?.notes);
    setValue("scopeOfProject", row?.scopeOfProject);
    setValue("tasks", row?.tasks);
  };

  React.useEffect(() => {
    handleAutoSave(projectsaveData);
    dispatch(
      updateMasterStates({
        name: "projectcodeCheck",
        value: "",
      })
    );
  }, []);
  React.useEffect(() => {
    if (pathname === "/createProject") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [pathname]);

  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      width: 200,
      valueGetter: (params) => params?.row?.projectName,
    },

    {
      field: "projectCode",
      headerName: "Project Code",
      width: 200,
      valueGetter: (params) => params?.row?.projectCode,
    },
    {
      field: "depName",
      headerName: "Department",
      width: 200,
      valueGetter: (params) => params?.row?.depName,
    },
    {
      field: "teamName",
      headerName: "Team",
      width: 200,
      valueGetter: (params) => params?.row?.teamInfo,
    },
    // {
    //   field: "notes",
    //   headerName: "NOTES",
    //   width: 200,
    //   valueGetter: (params) => params?.row?.notes,
    // },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 200,
      valueFormatter: (params) =>
        moment(params.value, "YYYY-MM-DDThh:mm:ssz").format("DD/MM/YYYY"),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      valueGetter: (params) =>
        params?.row?.createUserInfo?.firstname +
        " " +
        params?.row?.createUserInfo?.lastname,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "left",

      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        allowedPages().ProjectManagement.ProjectList.edit && isActive2 ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Edit"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        <GridActionsCellItem
          sx={{ p: 0 }}
          // icon={
          //   <Tooltip title="View" placement="right">
          //     <RemoveRedEyeIcon sx={{ color: "#358c4e" }} />
          //   </Tooltip>
          // }
          // label="view"
          // onClick={() => {
          //   handleView(params.row, "view");
          // }}
          icon={
            <Tooltip title="View" placement="right">
              <Box
                onClick={() => viewPop(params.row)}
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <ViewPopUp
                  value={true}
                  project={params.row}
                  extraData={viewData}
                />
              </Box>
            </Tooltip>
          }
          color="primary"
        />,
        !isActive2 ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),
        // <GridActionsCellItem
        //   icon={<Delete />}
        //   label="Delete"
        //   onClick={() => {
        //     handleDeleteClick(params.row);
        //   }}
        //   color="error"
        // />,
      ],
    },
  ];
  // const handleDeleteClick = async (row) => {
  //   Swal.fire({
  //     text: "Are you sure ?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then(async (res) => {
  //     if (res.value) {
  //       await dispatch(
  //         ProjectMaster(
  //           { id: row },
  //           "delete",
  //           `/project/info/${globapOrganization?.orgId}/`
  //         )
  //       );
  //       dispatch(
  //         ProjectMaster(
  //           "",
  //           "get",
  //           `/project/info/${globapOrganization?.orgId}/`
  //         )
  //       );
  //     }
  //   });
  // };
  const addProjectFun = () => {
    setEdittitle(false);
    setView(false);
    setShow(true);
    navigate("/createProject");
  };
  const handelClose = () => {
    dispatch(updatePresistStates({ name: "projectsaveData", value: {} }));
    dispatch(
      updateMasterStates({
        name: "projectcodeCheck",
        value: "",
      })
    );
    dispatch(
      updateProfileStates({
        name: "departmentTeamList",
        value: [],
      })
    );
    // setShow(false);
    // navigate('/project')
    fileRef.current.value = null;
    setFileValue("");
    setFileInfo("");
    setEdit(false);
    setEdittitle(false);
    reset();
  };
  const handelChangeDepartment = (e) => {
    setState({ ...state, depId: e?.departmentInfo?.depId || null });
    setDep(e);
    if (e?.departmentInfo?.depId)
      dispatch(getDepartmentByTeamAction(e?.departmentInfo?.depId));
    setTeamid("");
    dispatch(
      updateProfileStates({
        name: "teamUserData",
        value: "",
      })
    );
    if (!e) {
      setDep("");
      setTeamid("");
      dispatch(
        updateProfileStates({
          name: "teamUserData",
          value: "",
        })
      );
    }
  };
  React.useEffect(() => {
    return () => {
      setItemPerPage(100);
      setPageNumber(1);
      setTotalPage(0);
      const t = { ...getValues() };
      const t2 = t?.task
        ? [...t?.tasks]
        : [
            {
              category: "",
              projectCode: "",
              taskName: "",
              unitType: "",
              unitOfMeasure: "",
              uomProdTime: 0,
              mints: 0,
              seconds: 0,
            },
          ];
      delete t?.tasks;
      const t3 = t2.map((i) => {
        return { ...i };
      });
      t.tasks = [...t3];
      dispatch(updatePresistStates({ name: "projectsaveData", value: t }));
    };
  }, []);
  const branchListConstruct = (arr) => {
    console.log(arr, "arr");
    const res = arr.map((val) => ({
      ...val.projectInfo,
      depName: val?.departmentInfo?.depName,
      createUserInfo: val.createUserInfo,
      updatedUserInfo: val.updatedUserInfo,
      teamInfo: val?.teamInfo?.map((name) => name?.teamName),
      fileInfo: val?.fileInfo,
      // mangerInfo: val?.teamInfo?.map((name) => name?.teamName),
      mangerInfo: val?.OwnerLead?.Owner?.map(
        (name) => name?.firstname + " " + name.lastname
      ),
    }));
    console.log(res, "res");
    return res;
  };
  React.useEffect(() => {
    if (projectFilter?.length === 0) setConstructData([]);
    if (projectFilter && projectFilter?.length > 0) {
      const constructDatavalue = branchListConstruct(projectFilter);
      setConstructData(constructDatavalue);
    } else if (projectFilter && projectFilter?.projectInfo?.length > 0) {
      const constructDatavalue = branchListConstruct(
        projectFilter?.projectInfo
      );
      setConstructData((per) => {
        const arr = [...constructDatavalue];
        return removeDuplicatesFromArray(arr);
      });
    }
  }, [projectFilter]);
  const handelChangeTeam = (e) => {
    setState({ ...state, teamId: e?.teamId || null });
    setTeamid(e);
    if (!e) {
      dispatch(
        updateProfileStates({
          name: "teamUserData",
          value: "",
        })
      );
    }
  };
  const handelChangemultiTeam = async (data) => {
    const payload = {
      teamId: data.map((val) => val.teamId),
    };
    const res = await getPojectTeamUsersList(
      payload,
      globapOrganization?.orgId
    );
    if (res) {
      const userAll = [{ firstname: "All", lastname: "" }];
      const userList = [...userAll, ...res];
      setTroubleShoot(userList);
    }
    return res;
  };
  const handelChangeTrobleShooter = (e) => {
    if (e?.length > 1 && e.find((i) => i.firstname === "All")) {
      if (getValues("troubleShootUsers").find((i) => i.firstname === "All")) {
        console.log(
          getValues("troubleShootUsers"),
          e.filter((i) => i.firstname !== "All"),
          "!=="
        );
        setValue(
          "troubleShootUsers",
          e.filter((i) => i.firstname !== "All")
        );
      } else {
        console.log(
          e.filter((i) => i.firstname === "All"),
          "==="
        );
        setValue(
          "troubleShootUsers",
          e.filter((i) => i.firstname === "All")
        );
      }
    } else {
      setValue("troubleShootUsers", e);
    }
  };
  const handelLoad = async () => {
    setConstructData([]);
    dispatch(
      getProjectFilterAction(
        globapOrganization?.orgId,
        state,
        pageNumber,
        itemPerPage,
        isActive ? "&isActive=True" : "&isActive=False"
      )
    );
    // const res = await dispatch(
    //   getDepartmentTeamByUsers(dep?.depId, teamid?.teamId)
    // );
  };
  const handelProjectCode = async (e) => {
    if (e) {
      setState({ ...state, projectCode: e?.projectCode || null });
      setprojectCode1(e);
    } else {
      setState({ ...state, projectCode: null });
      setprojectCode1(e);
    }
  };
  const changePageSize = (newPageSize) => {
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    dispatch(
      getProjectFilterAction(
        globapOrganization?.orgId,
        state,
        newPageSize.page + 1,
        newPageSize.pageSize,
        isActive ? "&isActive=True" : "&isActive=False"
      )
    );
  };
  const changePage = (num) => {
    setPageNumber(num.page + 1);
  };
  const CopyData = (i, v) => {
    handleButtonClick();
    const res = getValues("tasks") || [];
    // res.splice(i + 1, 0, v);
    res.push(v);
    setValue("tasks", res);
    setisActive(!isActive);
  };
  const clearField = async () => {
    const res = await deleteFile(getValues("attachmentLocations"));
    if (res) {
      fileRef.current.value = null;
      setValue("attachmentLocations", "");
      setFileValue("");
      setFileInfo("");
    }
  };
  const DownloadFile = async (file, view, type) => {
    if (type) {
      await deleteFile(getValues("attachmentLocations"));
      fileRef.current.value = null;
      setValue("attachmentLocations", "");
      setFileValue("");
      setFileInfo("");
    } else {
      const res = await downloadFileService(file, view);
      console.log(res);
    }
  };
  const handelChangeFile = async (e) => {
    // fileRef.current.value = null;
    const file = e.target.files[0];
    console.log(e.target.files, e.target.files[0], "file");
    const maxSize = 1048576;
    // if (file.size <= maxSize) {
    // TriggerAlert("warning", ATTACH_MESSAGE_PROJECT);
    // } else {
    setFileValue(e.target.files[0]);
    const res = await sendAttachmentProject(file);
    setValue("attachmentLocations", res[0].fileCode);
    console.log(res, "res");
    // }
  };
  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ ...formContainerStyle, p: 1 }}>
          <Box sx={{ ...formContainerCardStyle, px: 1 }}>
            {show ? (
              <>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                > */}

                {/* <Box></Box>
                </Box> */}
                {/* <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                <Save
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={saveData}
                />
              </Box> */}
                {/* <Divider sx={{ backgroundColor: "black" }} /> */}
                <Card sx={{ ...formContainerCardStyle }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // px: 5,
                    }}
                  >
                    <Box sx={{ flex: 0.1 }}>
                      <Typography
                        textAlign={"center"}
                        sx={{ ...allpageFontStyle.title }}
                        variant="body1"
                      >
                        {editTitle ? "Edit " : view ? "view" : "Create"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 0.1 }}>
                      <Button
                        variant="contained"
                        // type="submit"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.listType,
                            title: PopupMessage.back,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                          borderRadius: 5,
                        }}
                      >
                        List
                      </Button>
                    </Box>
                  </Box>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      p: 4,
                      px: 10,
                    }}
                  >
                    <Grid
                      lg={4}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        p: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="projectName"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            sx={{
                              width: "100%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            size="small"
                            disabled={view}
                            label="Project Name*"
                            helperText={
                              errors.projectName && !value
                                ? errors?.projectName?.message
                                : null
                            }
                            error={!value && errors?.projectName?.message}
                            onChange={(e) => {
                              onChange(e);
                              trigger("projectName");
                            }}
                            onBlur={() =>
                              !value ? trigger("projectName") : null
                            }
                            value={value}
                            inputRef={ref}
                            inputProps={{ "aria-label": "Name" }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={4}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        p: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="projectCode"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            sx={{
                              width: "100%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            disabled={view}
                            onChange={(e) => {
                              onChange(e);
                              handelCodeChange(e);
                              trigger("projectCode");
                            }}
                            size="small"
                            label="Project Code*"
                            helperText={
                              !value && errors?.projectCode
                                ? errors.projectCode?.message
                                : projectcodeCheck
                                ? "Code Already In Use"
                                : null
                            }
                            error={
                              (errors?.projectCode || projectcodeCheck) &&
                              !value
                            }
                            onBlur={() => trigger("projectCode")}
                            value={value}
                            inputRef={ref}
                            inputProps={{ "aria-label": "Name" }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={4}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="depId"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <FormControl
                              error={!value ? errors.depId : null}
                              size="small"
                              fullWidth
                            >
                              <InputLabel shrink>Department*</InputLabel>
                              <Select
                                sx={{
                                  width: "100%",
                                  borderRadius: 3,
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                notched
                                disabled={view}
                                label="Department*"
                                size="small"
                                MenuProps={{
                                  PaperProps: { sx: { maxHeight: 150 } },
                                }}
                                helperText={
                                  errors.depId && !value
                                    ? errors.depId.message
                                    : null
                                }
                                error={!value ? errors.depId?.message : null}
                                onChange={(e) => {
                                  onChange(e);
                                  handelDepartment(e);
                                  trigger("depId");
                                }}
                                onBlur={() => trigger("depId")}
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "Project Code",
                                }}
                              >
                                {/* <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  fontFamily: "Lato",
                                  fontSize: 13,
                                }}
                                value={""}
                              >
                                Select Department
                              </MenuItem> */}
                                {departmentList.map((val) => (
                                  <MenuItem
                                    sx={{
                                      borderBottom: "1px solid white",
                                      fontFamily: "Lato",
                                      fontSize: 13,
                                    }}
                                    value={val.departmentInfo?.depId}
                                  >
                                    {val.departmentInfo?.depName}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {errors.depId ? errors?.depId?.message : null}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name={`team`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <FormControl
                              error={errors?.team ? errors.team.message : null}
                              size="small"
                              fullWidth
                              sx={{ pt: 0.5 }}
                            >
                              <SelectComponent
                                DataOptions={departmentTeamList || []}
                                labelName={(option) => option?.teamName}
                                errors={
                                  errors?.team ? errors.team.message : null
                                }
                                disabled={view}
                                defaultValue={value}
                                multiple={true}
                                label={"Team"}
                                onBlur={() => trigger("team")}
                                filterSelectedOptions={true}
                                isOptionEqualToValue={(option, value) =>
                                  option?.id === value?.id
                                }
                                value={value}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={"Team"}
                                    helperText={
                                      errors?.team ? errors.team.message : null
                                    }
                                    error={
                                      errors?.team ? errors.team.message : null
                                    }
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  onChange(e);
                                  handelChangemultiTeam(e);
                                  trigger("team");
                                }}
                              />
                            </FormControl>
                          </Box>
                        )}
                      />
                      {/* <Controller
                        control={control}
                        name="team"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <FormControl
                              error={errors.teamId}
                              size="small"
                              fullWidth
                            >
                              <InputLabel shrink>Team*</InputLabel>
                              <Select
                                sx={{
                                  width: "100%",
                                  borderRadius: 3,
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                notched
                                disabled={view}
                                label="Team*"
                                size="small"
                                MenuProps={{
                                  PaperProps: { sx: { maxHeight: 150 } },
                                }}
                                helperText={
                                  errors.team ? errors.team.message : null
                                }
                                error={errors?.team?.message}
                                multiple
                                onChange={(e) => {
                                  onChange(e);
                                  trigger("team");
                                }}
                                onBlur={() => trigger("team")}
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "Project Code",
                                }}
                              >
                                {departmentTeamList &&
                                  departmentTeamList.length > 0 &&
                                  departmentTeamList.map((val) => (
                                    <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={val.teamId}
                                    >
                                      {val.teamName}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {errors.team ? errors?.team?.message : null}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        )}
                      /> */}
                    </Grid>
                    <Grid
                      lg={6}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name={`troubleShootUsers`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <FormControl
                              error={
                                errors?.troubleShootUsers
                                  ? errors.troubleShootUsers.message
                                  : null
                              }
                              size="small"
                              fullWidth
                              sx={{ pt: 0.5 }}
                            >
                              <SelectComponent
                                DataOptions={troubleShoot || []}
                                labelName={(option) =>
                                  option?.firstname + " " + option?.lastname
                                }
                                errors={
                                  errors?.troubleShootUsers
                                    ? errors.troubleShootUsers.message
                                    : null
                                }
                                disabled={view}
                                defaultValue={value}
                                multiple={true}
                                onBlur={() => trigger("troubleShootUsers")}
                                filterSelectedOptions={true}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                value={value}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={"Troubleshooters"}
                                    helperText={
                                      errors?.troubleShootUsers
                                        ? errors.troubleShootUsers.message
                                        : null
                                    }
                                    error={
                                      errors?.troubleShootUsers
                                        ? errors.troubleShootUsers.message
                                        : null
                                    }
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // onChange(e);
                                  handelChangeTrobleShooter(e);
                                  trigger("troubleShootUsers");
                                }}
                              />
                            </FormControl>
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        pt: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="scopeOfProject"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            sx={{
                              width: "98%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            multiline
                            disabled={view}
                            label=" Project Scope*"
                            helperText={
                              value !== ""
                                ? 500 -
                                  value.length +
                                  " " +
                                  "Characters remaining"
                                : errors?.scopeOfProject
                                ? errors?.scopeOfProject?.message
                                : "Max 500 characters"
                            }
                            error={
                              !value ? errors?.scopeOfProject?.message : null
                            }
                            rows={3}
                            onChange={(e) => {
                              trigger("scopeOfProject");
                              onChange(e);
                            }}
                            onBlur={() => trigger("scopeOfProject")}
                            value={value}
                            inputRef={ref}
                            inputProps={{
                              "aria-label": "description",
                              maxLength: 500,
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      md={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        pt: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="notes"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            sx={{
                              width: "98%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            label="Notes"
                            disabled={view}
                            helperText={
                              value === ""
                                ? "Max 500 characters"
                                : 500 -
                                  value.length +
                                  " " +
                                  "Characters remaining"
                            }
                            multiline
                            rows={3}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            inputProps={{
                              "aria-label": "description",
                              maxLength: 500,
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                    <Grid lg={12} md={6} xs={12}>
                      <Box sx={{ p: "0.8rem" }}>
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                          <Box sx={{ fontSize: 12, pt: 0.6 }}>
                            Attach Files
                            {/* (max size - 1MB) */}
                          </Box>
                          <Box sx={{}}>
                            <label htmlFor="uploadExcel" type="button">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  // disabled={!getValues("projectCode")}

                                  sx={{
                                    borderRadius: 5,
                                    // px: 0.2,
                                    mx: 0.5,
                                    minWidth: 10,
                                    ":hover": {
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                    },
                                  }}
                                >
                                  <Attachment
                                    sx={{
                                      color: "white",
                                      fontSize: 16,
                                      p: 0,
                                      mr: 0,
                                    }}
                                  />
                                  {/* <Box sx={{ fontSize: 11 }}>Upload</Box> */}
                                </Button>
                              </Box>
                            </label>
                          </Box>
                          {fileValue?.name ? (
                            <Box
                              sx={{
                                fontSize: 10,
                                pl: 0.5,
                                pt: 0.9,
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {fileValue?.name}
                              <CloseRounded
                                sx={{
                                  fontSize: 15,
                                  cursor: "pointer",
                                }}
                                color="error"
                                onClick={() => {
                                  clearField();
                                }}
                              />
                            </Box>
                          ) : null}
                        </Box>
                        {edit && fileInfo.originalFileName ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              pt: "0.5rem",
                            }}
                          >
                            <Box
                              sx={{
                                fontSize: 11,
                                p: 0.5,
                                display: "flex",
                                gap: "1rem",
                                borderRadius: "0.6rem",
                                border: "0.5px solid black",
                                // pt: 0.9,
                              }}
                              // onClick={() => DownloadFile(fileInfo, false)}
                            >
                              <Box
                                onClick={() => DownloadFile(fileInfo, true)}
                                sx={{
                                  ...fileNameStyle,
                                }}
                              >
                                {fileInfo?.originalFileName}
                              </Box>

                              <Download
                                sx={{
                                  ...fileDownloadStyle,
                                }}
                                color="info"
                                onClick={() => {
                                  DownloadFile(fileInfo, false);
                                }}
                              />
                              <CloseRounded
                                sx={{
                                  fontSize: 15,
                                  cursor: "pointer",
                                }}
                                color="error"
                                onClick={() => {
                                  clearField();
                                }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                        <input
                          type="file"
                          id="uploadExcel"
                          ref={fileRef}
                          // disabled={!getValues("projectCode")}
                          onChange={handelChangeFile}
                          style={{
                            color: "transparent",
                            display: "none",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
                <Card sx={formContainerCardStyle}>
                  <Grid container>
                    <Grid lg={12} md={12} sm={12}>
                      <Box sx={{ height: 360, width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // borderBottom: "0.5px solid gray",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography sx={{ ...allpageFontStyle.title }}>
                              Task
                            </Typography>
                          </Box>
                          <Box sx={{ pt: 2 }}>
                            <Tooltip title="Add New Row" placement="bottom">
                              <Button
                                size="small"
                                variant="contained"
                                sx={{
                                  ...allpageFontStyle.buttonFontStyle2,
                                  backgroundColor: "#4adc82",
                                  borderRadius: 5,
                                  display: view ? "none" : "flex",
                                  ":hover": {
                                    backgroundColor: "#4adc82",
                                  },
                                  mr: 3,
                                }}
                                onClick={addArray}
                              >
                                + Add
                              </Button>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box sx={{ p: 1 }}>
                          <Box
                            sx={{
                              height: 300,
                              overflow: "auto",
                              mt: view ? 3 : null,
                              border: "1px solid grey",
                              borderRadius: 5,
                              mt: 1,
                            }}
                          >
                            {/* <DragDropContext>
                            <Droppable droppableId="droppable" direction="vertical">
                              {(provided) => ( */}
                            <table
                              id="myTable"
                              style={{
                                width: "100%",
                                overflow: "auto",
                                minWidth: 800,
                              }}
                              // ref={provided.innerRef}
                              // className="dnd-table"
                              // {...provided.droppableProps}
                            >
                              <thead
                                style={{
                                  width: "100%",
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1000,

                                  height: 40,
                                  minHeight: 40,
                                  background: "white",
                                }}
                              >
                                <tr
                                  style={{
                                    width: "102%",

                                    // paddingTop:20
                                  }}
                                >
                                  <th
                                    style={{
                                      ...allpageFontStyle.title1,
                                      width: "3%",
                                      minWidth: 50,
                                    }}
                                  >
                                    S.NO
                                  </th>
                                  <th
                                    style={{
                                      ...allpageFontStyle.title1,
                                      width: "15%",
                                      minWidth: 120,
                                    }}
                                  >
                                    CATEGORY
                                  </th>
                                  <th
                                    style={{
                                      ...allpageFontStyle.title1,
                                      width: "18%",
                                      minWidth: 120,
                                    }}
                                  >
                                    TASK LEVEL
                                  </th>
                                  <th
                                    style={{
                                      width: "18%",
                                      minWidth: 120,
                                      ...allpageFontStyle.title1,
                                    }}
                                  >
                                    {" "}
                                    UNIT TYPE
                                  </th>
                                  <th
                                    style={{
                                      width: "18%",
                                      minWidth: 120,
                                      ...allpageFontStyle.title1,
                                    }}
                                  >
                                    UNIT OF MEASURE
                                  </th>
                                  <th
                                    style={{
                                      width: "10%",
                                      minWidth: 120,
                                      ...allpageFontStyle.title1,
                                    }}
                                  >
                                    HOURS
                                  </th>
                                  <th
                                    style={{
                                      width: "10%",
                                      minWidth: 120,
                                      ...allpageFontStyle.title1,
                                    }}
                                  >
                                    MINUTES
                                  </th>
                                  <th
                                    style={{
                                      width: "10%",
                                      minWidth: 120,
                                      ...allpageFontStyle.title1,
                                    }}
                                  >
                                    SECONDS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getValues("tasks") &&
                                  getValues("tasks").length > 0 &&
                                  getValues("tasks").map((val, i) => (
                                    // <Draggable key={i} draggableId={'drage'} index={i}>
                                    //   {(provided) => (
                                    <tr
                                      style={{
                                        width: "100%",
                                        background:
                                          i === rowsData
                                            ? "#f0f0f0"
                                            : "transparent",
                                        opacity:
                                          draggedRowIndex === i ? 0.5 : 1,
                                      }}
                                      // id={'drage'}
                                      // ref={provided.innerRef}
                                      //   {...provided.draggableProps}
                                    >
                                      <td
                                        // {...provided.dragHandleProps}
                                        onDragStart={(e) => onDragStart(e, i)}
                                        onDragOver={(e) => onDragOver(e, i)}
                                        onDrop={(e) => onDrop(e, i)}
                                        draggable={
                                          getValues("tasks").length > 1
                                        }
                                        style={{
                                          width: "3%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* {getValues("tasks").length > 1 ? */}
                                          <Box>
                                            <DragIndicator
                                              sx={{
                                                fontSize: "30px",
                                                display: view ? "none" : "flex",
                                                pt: 0.5,
                                                visibility:
                                                  getValues("tasks").length > 1
                                                    ? "visible"
                                                    : "hidden",
                                                cursor: "grab",
                                              }}
                                            />
                                          </Box>
                                          {/* : null} */}
                                          <Box sx={{ pl: 2.5 }}> {i + 1}</Box>
                                        </Box>
                                        {/* <button className="drag-handle-button">Drag</button> */}
                                        {"."}
                                      </td>
                                      <td style={{ padding: 2, width: "15%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.category`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                minWidth: "100%",
                                                "& .MuiInputBase-input.Mui-disabled":
                                                  {
                                                    WebkitTextFillColor:
                                                      "#000000",
                                                  },
                                              }}
                                              disabled={view}
                                              size="small"
                                              // helperText={
                                              //   errors?.tasks?.length
                                              //     ? errors?.tasks[i]?.category
                                              //         ?.message
                                              //     : null
                                              // }
                                              error={
                                                errors?.tasks?.length
                                                  ? errors?.tasks[i]?.category
                                                      ?.message
                                                  : false
                                              }
                                              onChange={(e) => {
                                                trigger(`tasks.${i}.category`);
                                                onChange(e.target.value.trim());
                                              }}
                                              onBlur={() =>
                                                trigger(`tasks.${i}.category`)
                                              }
                                              value={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td style={{ padding: 2, width: "18%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.taskName`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                minWidth: "100%",
                                                "& .MuiInputBase-input.Mui-disabled":
                                                  {
                                                    WebkitTextFillColor:
                                                      "#000000",
                                                  },
                                              }}
                                              onChange={(e) => {
                                                trigger(`tasks.${i}.taskName`);
                                                onChange(e.target.value.trim());
                                              }}
                                              disabled={view}
                                              // helperText={
                                              //   errors?.tasks?.length
                                              //     ? errors.tasks[i]?.taskName
                                              //         ?.message
                                              //     : null
                                              // }
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]?.taskName
                                                      ?.message
                                                  : false
                                              }
                                              size="small"
                                              onBlur={() =>
                                                trigger(`tasks.${i}.taskName`)
                                              }
                                              value={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td style={{ padding: 2, width: "10%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.unitType`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <FormControl
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]?.mints
                                                      ?.message
                                                  : false
                                              }
                                              size="medium"
                                              fullWidth
                                              sx={{ pt: 0.5 }}
                                            >
                                              <Autocomplete
                                                id="combo-box-demo"
                                                options={masterArray}
                                                sx={{ mb: 0.5 }}
                                                error={
                                                  errors?.tasks?.length
                                                    ? errors.tasks[i]?.mints
                                                        ?.message
                                                    : false
                                                }
                                                onChange={(e, v) => {
                                                  trigger(
                                                    `tasks.${i}.unitType`
                                                  );
                                                  onChange(v);
                                                  setisActive(!isActive);
                                                  setValue(
                                                    `tasks.${i}.unitType`,
                                                    v
                                                  );
                                                }}
                                                onBlur={(e, v) => {
                                                  trigger(
                                                    `tasks.${i}.unitType`
                                                  );
                                                  // setValue(v)
                                                }}
                                                // onInputChange={(e) => {

                                                //   const t = numbers.find(
                                                //     (number) =>
                                                //       number === e?.target?.value
                                                //   );
                                                //   if (t)
                                                //     setValue(
                                                //       `tasks.${i}.unitType`,
                                                //       e.target.value
                                                //     );
                                                // }}
                                                value={value}
                                                size="small"
                                                inputRef={ref}
                                                inputProps={{
                                                  "aria-label": "Project Code",
                                                }}
                                                disabled={view}
                                                renderInput={(params) => (
                                                  <TextField
                                                    sx={{
                                                      minWidth: "100%",
                                                      "& .MuiInputBase-input.Mui-disabled":
                                                        {
                                                          WebkitTextFillColor:
                                                            "#000000",
                                                        },
                                                    }}
                                                    error={
                                                      errors?.tasks?.length
                                                        ? errors.tasks[i]
                                                            ?.unitType?.message
                                                        : false
                                                    }
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </FormControl>
                                          )}
                                        />
                                        {/* <Controller
                                        control={control}
                                        name={`tasks.${i}.unitType`}
                                        render={({
                                          field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            ref,
                                          },
                                        }) => (
                                          <TextField
                                            sx={{
                                              width: "100%",
                                              "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                              },
                                            }}
                                            disabled= {view}
                                            onChange={(e) => {
                                              trigger(`tasks.${i}.unitType`);
                                              onChange(e.target.value.trim());
                                            }}
                                            // helperText={
                                            //   errors?.tasks?.length
                                            //     ? errors.tasks[i]?.unitType
                                            //         ?.message
                                            //     : null
                                            // }
                                            error={
                                              errors?.tasks?.length
                                                ? errors.tasks[i]?.unitType
                                                  ?.message
                                                : false
                                            }
                                            size="small"
                                            onBlur={() =>
                                              trigger(`tasks.${i}.unitType`)
                                            }
                                            value={value}
                                            inputRef={ref}
                                          />
                                        )}
                                      /> */}
                                      </td>
                                      <td style={{ padding: 2, width: "18%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.unitOfMeasure`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                minWidth: "100%",
                                                "& .MuiInputBase-input.Mui-disabled":
                                                  {
                                                    WebkitTextFillColor:
                                                      "#000000",
                                                  },
                                              }}
                                              disabled={view}
                                              onChange={(e) => {
                                                trigger(
                                                  `tasks.${i}.unitOfMeasure`
                                                );
                                                // onChange(e.target.value.trim())
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                  e.target.value
                                                );
                                                if (
                                                  u ||
                                                  e.target.value === ""
                                                ) {
                                                  setValue(
                                                    `tasks.${i}.unitOfMeasure`,
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                              onInput={(e) =>
                                                (e.target.value =
                                                  e.target.value.slice(0, 3))
                                              }
                                              // helperText={
                                              //   errors?.tasks?.length
                                              //     ? errors.tasks[i]?.unitOfMeasure
                                              //         ?.message
                                              //     : null
                                              // }
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]
                                                      ?.unitOfMeasure?.message
                                                  : false
                                              }
                                              size="small"
                                              onBlur={() =>
                                                trigger(
                                                  `tasks.${i}.unitOfMeasure`
                                                )
                                              }
                                              value={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      </td>

                                      <td style={{ padding: 2, width: "10%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.uomProdTime`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                minWidth: "100%",
                                                "& .MuiInputBase-input.Mui-disabled":
                                                  {
                                                    WebkitTextFillColor:
                                                      "#000000",
                                                  },
                                              }}
                                              disabled={view}
                                              onChange={(e) => {
                                                trigger(
                                                  `tasks.${i}.uomProdTime`
                                                );
                                                var numberRegex = /^\d+$/;
                                                const u = numberRegex.test(
                                                  e.target.value
                                                );

                                                if (
                                                  u ||
                                                  e.target.value === ""
                                                ) {
                                                  setValue(
                                                    `tasks.${i}.uomProdTime`,
                                                    e.target.value.trim()
                                                  );
                                                }
                                              }}
                                              onInput={(e) =>
                                                (e.target.value =
                                                  e.target.value.slice(0, 3))
                                              }
                                              onBlur={() =>
                                                trigger(
                                                  `tasks.${i}.uomProdTime`
                                                )
                                              }
                                              value={value}
                                              inputRef={ref}
                                              // helperText={
                                              //   errors?.tasks?.length
                                              //     ? errors.tasks[i]?.uomProdTime
                                              //       ?.message
                                              //     : null
                                              // }
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]?.uomProdTime
                                                      ?.message
                                                  : false
                                              }
                                              size="small"
                                            />
                                          )}
                                        />
                                      </td>
                                      <td style={{ padding: 2, width: "10%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.mints`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <FormControl
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]?.mints
                                                      ?.message
                                                  : false
                                              }
                                              size="small"
                                              fullWidth
                                              sx={{ pt: 0.5 }}
                                            >
                                              <Autocomplete
                                                id="combo-box-demo"
                                                options={numbers}
                                                sx={{ width: 160, mb: 0.5 }}
                                                error={
                                                  errors?.tasks?.length
                                                    ? errors.tasks[i]?.mints
                                                        ?.message
                                                    : false
                                                }
                                                onChange={(e, v) => {
                                                  trigger(`tasks.${i}.mints`);
                                                  onChange(v);
                                                  setisActive(!isActive);
                                                  setValue(
                                                    `tasks.${i}.mints`,
                                                    v
                                                  );
                                                }}
                                                onBlur={(e, v) => {
                                                  trigger(`tasks.${i}.mints`);
                                                  // setValue(v)
                                                }}
                                                onInputChange={(e) => {
                                                  const t = numbers.find(
                                                    (number) =>
                                                      number ===
                                                      e?.target?.value
                                                  );
                                                  if (t)
                                                    setValue(
                                                      `tasks.${i}.mints`,
                                                      e?.target?.value
                                                    );
                                                }}
                                                value={value}
                                                size="small"
                                                inputRef={ref}
                                                inputProps={{
                                                  "aria-label": "Project Code",
                                                }}
                                                disabled={view}
                                                renderInput={(params) => (
                                                  <TextField
                                                    sx={{
                                                      minWidth: "100%",
                                                      "& .MuiInputBase-input.Mui-disabled":
                                                        {
                                                          WebkitTextFillColor:
                                                            "#000000",
                                                        },
                                                    }}
                                                    error={
                                                      errors?.tasks?.length
                                                        ? errors.tasks[i]?.mints
                                                            ?.message
                                                        : false
                                                    }
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </FormControl>
                                          )}
                                        />
                                      </td>
                                      <td style={{ padding: 2, width: "5%" }}>
                                        <Controller
                                          control={control}
                                          name={`tasks.${i}.seconds`}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <FormControl
                                              error={
                                                errors?.tasks?.length
                                                  ? errors.tasks[i]?.seconds
                                                      ?.message
                                                  : false
                                              }
                                              size="small"
                                              fullWidth
                                              sx={{ pt: 0.5 }}
                                            >
                                              <Autocomplete
                                                id="combo-box-demo"
                                                options={numbers}
                                                sx={{ width: 160, mb: 0.3 }}
                                                onChange={(e, v) => {
                                                  onChange(v);
                                                  setisActive(!isActive);
                                                  setValue(
                                                    `tasks.${i}.seconds`,
                                                    v
                                                  );
                                                }}
                                                onInputChange={(e) => {
                                                  const t = numbers.find(
                                                    (number) =>
                                                      number ===
                                                      e?.target?.value
                                                  );

                                                  if (t)
                                                    setValue(
                                                      `tasks.${i}.seconds`,
                                                      e.target.value
                                                    );
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                size="small"
                                                inputRef={ref}
                                                inputProps={{
                                                  "aria-label": "Project Code",
                                                }}
                                                disabled={view}
                                                renderInput={(params) => (
                                                  <TextField
                                                    sx={{
                                                      minWidth: "100%",
                                                      "& .MuiInputBase-input.Mui-disabled":
                                                        {
                                                          WebkitTextFillColor:
                                                            "#000000",
                                                        },
                                                    }}
                                                    error={
                                                      errors?.tasks?.length
                                                        ? errors.tasks[i]
                                                            ?.seconds?.message
                                                        : false
                                                    }
                                                    {...params}
                                                  />
                                                )}
                                              />
                                              {/* <Select
                                              sx={{
                                                width: "100%",
                                              }}
                                              MenuProps={{
                                                PaperProps: {
                                                  sx: { maxHeight: 150 },
                                                },
                                              }}
                                              onChange={(e) => {
                                                onChange(e);
                                                setisActive(!isActive);
                                                setValue(
                                                  `tasks.${i}.seconds`,
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={onBlur}
                                              value={value}
                                              size="small"
                                              inputRef={ref}
                                              inputProps={{
                                                "aria-label": "Project Code",
                                              }}
                                            >
                                              <MenuItem
                                                sx={{
                                                  borderBottom:
                                                    "1px solid white",
                                                  fontFamily: "Lato",
                                                  fontSize: 13,
                                                }}
                                                value={""}
                                              >
                                                Select Seconds
                                              </MenuItem>
                                              {numbers &&
                                                numbers.length > 0 &&
                                                numbers.map((val) => (
                                                  <MenuItem
                                                    sx={{
                                                      borderBottom:
                                                        "1px solid white",
                                                      fontFamily: "Lato",
                                                      fontSize: 13,
                                                    }}
                                                    value={val}
                                                  >
                                                    {val}
                                                  </MenuItem>
                                                ))}
                                            </Select> */}
                                              {/* <FormHelperText
                                              sx={{ color: "red" }}
                                            >
                                              {errors?.tasks?.length
                                                ? errors.tasks[i]?.seconds
                                                    ?.message
                                                : null}
                                            </FormHelperText> */}
                                            </FormControl>
                                          )}
                                        />
                                      </td>
                                      <td style={{ width: "3%" }}>
                                        <Box
                                          sx={{
                                            display: view ? "none" : "flex",
                                          }}
                                        >
                                          {/* {i === 0 ? null : ( */}
                                          <Tooltip
                                            title="Copy Row"
                                            placement="bottom"
                                          >
                                            <Button
                                              variant="text"
                                              sx={{
                                                height: 20,
                                                width: 20,
                                                color: "#59a3f3",
                                                ":hover": {
                                                  color: "#59a3f3",
                                                },
                                              }}
                                              onClick={() => {
                                                CopyData(i, val);
                                              }}
                                            >
                                              <CopyAll />
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            title="Delete Row"
                                            placement="bottom"
                                          >
                                            <Button
                                              variant="text"
                                              sx={{
                                                height: 20,
                                                width: 20,
                                                color: "red",
                                                ":hover": {
                                                  color: "red",
                                                },
                                              }}
                                              onClick={() => {
                                                removeData(i);
                                              }}
                                            >
                                              <Delete />
                                            </Button>
                                          </Tooltip>

                                          {/* )} */}
                                        </Box>
                                      </td>
                                    </tr>
                                    //   )}
                                    // </Draggable>
                                  ))}
                              </tbody>
                            </table>
                            {/* )}
                            </Droppable>
                          </DragDropContext> */}
                            <div
                              ref={scrollingContainerRef}
                              style={{ marginBottom: 50 }}
                            ></div>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "10px",
                          display:
                            view ||
                            !allowedPages().ProjectManagement.createProject
                              .create
                              ? "none"
                              : "flex",
                          justifyContent: "center",
                          pb: 5,
                        }}
                      >
                        {" "}
                        <Button
                          color="success"
                          type="submit"
                          disabled={!getValues("tasks")?.length > 0}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                            borderRadius: 5,
                          }}
                          variant="contained"
                        >
                          {edit ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="error"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.resetType,
                              title: PopupMessage.reset,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                            borderRadius: 5,
                          }}
                          variant="contained"
                        >
                          {RESET_LABEL}
                        </Button>
                      </Box>
                      {view &&
                      allowedPages().ProjectManagement?.ProjectList?.showPage() ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </Card>
              </>
            ) : (
              <Box
                sx={{
                  px: 3,
                  pl: 1,
                  height: "100%",
                  mb: 5,
                  minHeight: 600,
                  overflowY: "auto",
                  overflowX: "hidden",
                  background: grey[100],
                }}
              >
                <Card sx={formContainerCardStyle}>
                  <Box
                    sx={{
                      m: 1,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {allowedPages().ProjectManagement?.createProject
                      ?.create && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          py: 2,
                          px: 2,
                        }}
                      >
                        <Button
                          sx={{
                            ...allpageFontStyle.addButtonFont.buttonHeight,

                            ...allpageFontStyle.buttonFontStyle1,
                            fontWeight: "bold",
                            // backgroundColor: "#43a5fc",
                            borderRadius: 5,
                            color: "#358c4e",
                            p: 0,
                            px: 2,
                            py: 0,
                            ":hover": {
                              backgroundColor: "#358c4e",
                              color: "white",
                              borderColor: "#358c4e",
                            },
                            textTransform: "capitalize",
                            borderColor: "#358c4e",
                          }}
                          variant="outlined"
                          size="small"
                          onClick={addProjectFun}
                        >
                          <AddIcon
                            sx={{
                              mr: 0.5,
                              ...allpageFontStyle.addButtonFont.font,
                              p: 0,
                            }}
                          />
                          <Typography
                            sx={{ ...allpageFontStyle.addButtonFont.font }}
                          >
                            Create Project
                          </Typography>
                        </Button>
                      </Box>
                    )}
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12} md={4} lg={2}>
                        {" "}
                        <Box sx={{ pt: 0.7, pl: 1.5 }}>
                          <SelectComponent
                            DataOptions={projectCodeDta?.project_details}
                            labelName={(option) => option.projectCode}
                            value={projectCode1}
                            label={"Project Code"}
                            handelChange={handelProjectCode}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={2}>
                        {" "}
                        <Box sx={{ pt: 0.7 }}>
                          <SelectComponent
                            DataOptions={departmentList}
                            labelName={(option) =>
                              option.departmentInfo?.depName
                            }
                            value={dep}
                            label={"Department"}
                            handelChange={handelChangeDepartment}
                          />

                          {/* <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          ref={autoCdep}
                          options={departmentList}
                          getOptionLabel={(option) => option.departmentInfo?.depName}
                          onChange={(e, value) => {
                            handelChangeDepartment(value);
                          }}
                          value={dep || null}
                          // value={
                          //   departmentList &&
                          //   departmentList.length > 0 &&
                          //   departmentList.find((val) => val.depName === dep?.depName)
                          // }
                          // defaultValue={}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Select Department"
                            />
                          )}
                        /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={2}>
                        {" "}
                        <Box sx={{ pt: 0.7 }}>
                          <SelectComponent
                            DataOptions={departmentTeamList}
                            labelName={(option) => option.teamName}
                            value={teamid}
                            label={"Team"}
                            handelChange={handelChangeTeam}
                          />

                          {/* <Autocomplete
                          id="size-small-outlined"
                          ref={autoCteam}
                          size="small"
                          options={departmentTeamList}
                          getOptionLabel={(option) => option.teamName}
                          value={teamid || null}
                          // defaultValue={}
                          // value={
                          //   departmentTeamList &&
                          //   departmentTeamList.length > 0 &&
                          //   departmentTeamList.find(
                          //     (val) => val.teamName === teamid?.teamName
                          //   )
                          // }
                          onChange={(e, value) => {
                            handelChangeTeam(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Select Team"
                            />
                          )}
                        /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={2.5}>
                        <Box sx={{}}>
                          <DateComponet
                            value={start}
                            label={"Start Date"}
                            maxDate={new Date()}
                            setValue={setStart}
                            handelDate={(e) => {
                              setStart(e);
                              setEnd(e);
                              setState({
                                ...state,
                                fromDate: e
                                  ? moment(e).format("YYYY-MM-DD")
                                  : null,
                                toDate: e
                                  ? moment(e).format("YYYY-MM-DD")
                                  : null,
                              });
                            }}
                          />
                          {/* <TextField
                            size="small"
                            sx={{ width: "100%", ...textFieldsStyle() }}
                            type="date"
                            value={state.fromDate}
                            id="outlined-basic"
                            label="Start Date"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              setStart(e.target.value);
                              setState({ ...state, fromDate: e.target.value });
                            }}
                          /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={2.5}>
                        <Box sx={{ width: "100%" }}>
                          <DateComponet
                            value={end}
                            minDate={
                              new Date(moment(start).format("YYYY-MM-DD"))
                            }
                            label={"End Date"}
                            setValue={setEnd}
                            handelDate={(e) => {
                              setEnd(e);
                              setState({
                                ...state,
                                toDate: e
                                  ? moment(e).format("YYYY-MM-DD")
                                  : null,
                              });
                            }}
                          />
                          {/* <TextField
                            size="small"
                            sx={{ width: "100%", ...textFieldsStyle() }}
                            type="date"
                            id="outlined-basic"
                            label="End Date"
                            value={state.toDate}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              min: moment(start).format("YYYY-MM-DD"),
                            }}
                            onChange={(e) => {
                              setState({ ...state, toDate: e.target.value });
                            }}
                          /> */}
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={1}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box sx={{ pt: 1 }}>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={
                              !state.projectCode &&
                              !state.depId &&
                              !state.teamId &&
                              !state?.fromDate &&
                              !state.toDate
                            }
                            sx={{
                              ...allpageFontStyle.buttonFontStyle2,
                              borderRadius: 5,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                            onClick={() => {
                              handelLoad();
                            }}
                          >
                            Load
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      pt: 5,
                      display: "flex",
                      flexDirection: "column",
                      height: 450,
                    }}
                  >
                    <DataGride
                      // hideDeletion
                      columns={columns}
                      selectionIs={selectionIs}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      deleteIcon={isActive2 ? true : false}
                      // disableCheckbox={true}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      customHeight={450}
                      pageName={"project"}
                      tableData={constructData || []}
                      // deleteFuntion={handleDeleteClick}category
                      pageSize={itemPerPage}
                      page={pageNumber}
                      onPageSizeChange={changePageSize}
                      rowCount={totalPage}
                      paginationMode={true}
                      onPageChange={changePage}
                      InActiveFilterCheckBox={() =>
                        checkBoxFilter(
                          isActive2,
                          setIsActive2,
                          "Active",
                          "Inactive",
                          handelActiveInactive
                        )
                      }
                    />
                  </Box>
                </Card>
              </Box>
            )}
          </Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <Box sx={{ p: 1 }}>
                {error === "category" ? (
                  <Box>
                    <b>Category </b>Mismatch
                  </Box>
                ) : error === "cat" ? (
                  <Box>
                    <b>Category </b>Mismatch
                  </Box>
                ) : null}
                {error === "taskName" ? (
                  <Box>
                    <b>Task Name </b> Mismatch
                  </Box>
                ) : null}
                {error === "unitType" ? (
                  <Box>
                    <b>Unit Type </b> Mismatch
                  </Box>
                ) : null}
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </form>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}

export default CreatePro;
