import { Box, Button, Card, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGride from "../../components/datagride/DataGride";
import {
  formContainerCardStyle,
  formContainerStyle,
  textFieldsStyle,
} from "../../utils/Style";
import { generalTaskHistory } from "../../service/taskService";
import { CancelRounded, Close, Visibility } from "@mui/icons-material";
import TimelinePopUp from "./TimelinePopUp";
import { allpageFontStyle } from "../../utils/FontStyle";
import moment from "moment";
import DateComponet from "../../components/commonComponents/DateComponet";

function UserGeneralTaskList() {
  const [payload, setPayload] = useState({
    fromDate: null,
    toDate: null,
  });
  const [open, setOpen] = useState({ selectedRow: {}, view: false });

  const handelPopupOpen = (row) => {
    setOpen({ selectedRow: row, view: true });
  };
  const constructfunction = (arr) => {
    const res =
      arr?.userTaskHistoryInfo &&
      arr?.userTaskHistoryInfo?.length > 0 &&
      arr.userTaskHistoryInfo.map((val) => ({
        ...val.generalTaskInfo,
        taskHistoryInfo: val?.taskHistoryInfo,
        userGeneralTaskInfo: val?.userGeneralTaskInfo,
      }));
    return res;
  };
  const [tableData, setTableData] = useState([]);
  const getGeneralTaskHistory = async () => {
    const payloadData = {
      fromDate: payload.fromDate
        ? moment(payload.fromDate).format("YYYY-MM-DD")
        : null,
      toDate: payload.toDate
        ? moment(payload.toDate).format("YYYY-MM-DD")
        : null,
    };
    const res = await generalTaskHistory(payloadData);
    if (res) {
      const response = res?.data?.data;
      const constructData = constructfunction(response);
      setTableData(constructData);
    }
  };
  const handleLoad = () => {
    getGeneralTaskHistory();
  };
  useEffect(() => {
    handleLoad();
  }, []);

  const columns = [
    {
      field: "taskName",
      headerName: "Task Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taskType",
      headerName: "Status",
      width: 150,
      align: "left",
      headerAlign: "left",
      valueGetter: (params) => {
        return `${params.row?.userGeneralTaskInfo?.state}`;
      },
    },
    // { field: "taskInfo", headerName: "Category", width: 100, align: "left", valueGetter: (params) => { return `${params.row?.category || ""}` }, headerAlign: "left", },
    // { field: "measureCount", headerName: "Per Unit Count", width: 180, align: "left", headerAlign: "left", },
    // { field: "completedCount", headerName: "Completed Count", width: 180, align: "left", headerAlign: "left", },
    // { field: "remarks", headerName: "Remarks", width: 270, align: "left", headerAlign: "left" },
    // { field: "status", headerName: "Status", width: 150, align: "left", headerAlign: "left", },
    {
      field: "actions",
      headerName: "Action",
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Button sx={{ color: "black" }} title="View">
            <Visibility
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handelPopupOpen(params?.row);
              }}
            />
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ ...formContainerStyle, px: 5, pl: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Card sx={{ ...formContainerCardStyle, p: 4, pl: 2 }}>
          <Grid spacing={1} container>
            <Grid
              item
              lg={open?.view ? 8 : 12}
              sm={12}
              md={open?.view ? 8 : 12}
            >
              <Box sx={{ m: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "80%",
                      justifyContent: "center",
                      gap: 4,
                      pb: 2,
                    }}
                  >
                    <DateComponet
                      value={
                        payload.fromDate
                          ? new Date(
                              moment(payload.fromDate).format(
                                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                              )
                            )
                          : null
                      }
                      fullWidth={false}
                      label={"Start Date"}
                      setValue={setPayload}
                      handelDate={(e) => {
                        setPayload({
                          ...payload,
                          fromDate: e ? moment(e).format("YYYY-MM-DD") : null,
                          toDate: e ? moment(e).format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                    {/* <TextField
                                        sx={{ mx: 5, ...textFieldsStyle(), ...allpageFontStyle.dateField }}
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                        value={payload.fromDate}
                                        onChange={(e) => {
                                            setPayload({ ...payload, fromDate: e.target.value });
                                        }}
                                        type="date"
                                        id="outlined-basic"
                                        label="Start Date"
                                        variant="outlined"
                                    /> */}
                    <DateComponet
                      value={
                        payload.toDate
                          ? new Date(
                              moment(payload.toDate).format(
                                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                              )
                            )
                          : null
                      }
                      minDate={new Date(payload.fromDate)}
                      fullWidth={false}
                      label={"End Date"}
                      setValue={setPayload}
                      handelDate={(e) => {
                        setPayload({
                          ...payload,
                          toDate: e ? moment(e).format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                    {/* <TextField
                                        sx={{ mx: 5, ...textFieldsStyle(), ...allpageFontStyle.dateField }}
                                        size="small"
                                        value={payload.toDate}
                                        onChange={(e) => {
                                            setPayload({ ...payload, toDate: e.target.value });
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        type="date"
                                        id="outlined-basic"
                                        label="End Date"
                                        variant="outlined"
                                    /> */}
                    <Button
                      size="small"
                      sx={{
                        borderRadius: 5,
                        height: 30,
                        mt: 1,
                        ...allpageFontStyle.buttonFontStyle2,
                      }}
                      variant="contained"
                      onClick={handleLoad}
                    >
                      Load
                    </Button>
                  </Box>
                </Box>
                <DataGride
                  columns={columns}
                  tableData={tableData}
                  disableCheckbox={true}
                  hideDeletion={true}
                  customHeight={550} // getRowHeight={() => 'auto'}
                />
              </Box>
            </Grid>
            <Grid item lg={open?.view ? 4 : 0} sm={12} md={open?.view ? 4 : 0}>
              {open?.view && (
                <Box sx={{ display: "flex", justifyContent: "end", pb: 2 }}>
                  <CancelRounded
                    sx={{ cursor: "pointer", color: "red" }}
                    onClick={() => setOpen({ selectedRow: {}, view: false })}
                  />
                </Box>
              )}
              {open?.view ? (
                <Box>
                  <TimelinePopUp taskdata={open.selectedRow} view={open.view} />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
}
export default UserGeneralTaskList;
