import { createSlice } from "@reduxjs/toolkit";
const reducers = {
  organizationList: [],
  gender: [],
  // create task page
  taskList: [],
  SelectedTask: {},
  followUp: [],
  branchList: [],
  departmentList: [],
  designationList: [],
  gradeList: [],
  teamList: [],
  AutoId: "",
  employeeList: [],
  timeData: {
    dateValue: "",
    startFun: "",
    pauseFun: "",
    resetFun: "",
    start: false,
    pause: false,
  },
  globapOrganization: {},
  projectList: [],
  projectFilter: [],
  editProject: [],
  overlayLoading: false,
};
export const masterSlice = createSlice({
  name: "master",
  initialState: reducers,
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updateMasterStates: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    addAndUpdatemasterStates: (state, action) => {
      state[action.payload.name] += action.payload.value;
    },
    resetmasterReducer: (state, action) => {
      return { ...reducers };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateMasterStates,
  addAndUpdatemasterStates,
  resetmasterReducer,
} = masterSlice.actions;

export default masterSlice.reducer;
