import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Attachment, CloseRounded, Delete } from "@mui/icons-material";
import { green, grey, pink } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { formFieldStyle } from "../../../utils/Style";
import { useDispatch } from "react-redux";
import {
  createAnnouncementAction,
  getJobByProjectAction,
  getProjectCodeBYUserAction,
} from "../../../redux/action/action";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { box, contents, headings } from "../styles";
import "../stylesheet.css";
import { useNavigate } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { deleteFile, sendAttachment } from "../../../service/helpdeskService";
import Dropzone from "react-dropzone";
import { getValue } from "@testing-library/user-event/dist/utils";
import { ATTACH_MESSAGE, RESET_LABEL } from "../../../utils/textName";
import { PopupMessage, TriggerAlert } from "../../../utils/UIUtil";
import { toggleColorAnnouncement } from "../../../utils/CommonColors";
import PopupComponent from "../../commonComponents/PopupComponent";

const schema = yup.object().shape({
  projectCode: yup.string().required("Select Project"),
  jobCode: yup.string().nullable(),
  title: yup.string().required("Title Required"),
  message: yup.string().required("Content required"),
  priority: yup.number(),
  attachmentLocations: yup.object(),
  isGeneralAnnouncement: yup.boolean(),
});

export const defaultFonts = [
  "Arial",
  "Courier New",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];
export const sortedFontOptions = [
  "Logical",
  "Salesforce Sans",
  "Garamond",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
  ...defaultFonts,
].sort();
export default function CreateAnnouncement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { globapOrganization } = useSelector((state) => state.presist);
  const { userprofile } = useSelector((state) => state.presist);
  const { projectCodeDta, jobList } = useSelector((state) => state.master);
  const [fileValue, setFileValue] = React.useState([]);
  // console.log(projectCodeDta, jobList, "projects");
  // const [values, setValues] = React.useState("");
  // const [count, setCount] = useState(0);
  const [contentError, setContentError] = useState(false);
  const [multiSelectValue, setMultiSelectValue] = React.useState([]);
  const [filePayload, setFilePayload] = React.useState([]);
  const fileRef = React.useRef(null);
  const [type, setType] = React.useState("General");
  // const [priority, setPriority] = useState(false);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
    } else if (alertPopup.type === PopupMessage.resetType) {
      handleClear();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const form = useForm({
    defaultValues: {
      projectCode: "",
      jobCode: "",
      orgId: globapOrganization?.orgId,
      title: "",
      message: "",
      priority: 0,
      attachmentLocations: {},
      isGeneralAnnouncement: true,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;
  useEffect(() => {
    dispatch(
      getProjectCodeBYUserAction(
        "post",
        `/report/projectInfo/${
          userprofile?.userAccess?.superUser === true
            ? globapOrganization?.orgId
            : userprofile?.userInfo?.userInfo?.orgId || ""
        }/`
      )
    );
  }, []);
  console.log(errors, "errorsasa");
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: "#c92a2a",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "black" : "#ffc9c9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "red",
        border: "6px solid black",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#ff3300" : "#ff3300",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 15,
      height: 15,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#339af0" : "#339af0",
      boxSizing: "border-box",
    },
  }));

  const onSubmit = async (data) => {
    if (contentError) {
      trigger("message");
    } else {
      console.log(data, "final");
      const t = Object.values(getValues("attachmentLocations"));
      if (fileValue.length === t.length) {
        await dispatch(
          createAnnouncementAction("post", globapOrganization.orgId, {
            ...data,
            attachmentLocations: t.length ? t : null,
          })
        );
      }
      reset();
      setFileValue([]);
    }
  };
  const handleClear = () => {
    setContentError(false);
    reset();
    setFileValue([]);
  };
  const clearField = (file, index) => {
    console.log("clearrr");
    const u = [...fileValue];
    const t = u.splice(index, 1);
    console.log(t, "tvalu");
    setFileValue(u);
    console.log(getValues("attachmentLocations"), "attchhkh");
    console.log(
      getValues("attachmentLocations")[file.path],
      file.path,
      "checkkk"
    );
    if (getValues("attachmentLocations")[file?.path]) {
      deleteFile(getValues("attachmentLocations")[file?.path]);
    }
    const v = { ...getValues("attachmentLocations") };
    delete v[file?.path];
    setValue("attachmentLocations", v);
    trigger("attachmentLocations");

    // setValue('attachmentLocations', { ...getValues('attachmentLocations'), ...t });
  };
  console.log(fileValue, getValues("attachmentLocations"), "fileeee2");

  const handelChangeFile = async (acceptedFiles) => {
    console.log(acceptedFiles, "ghg");
    const maxSize = 1048576;
    // let v = fileValue.filter((value) => value !== fileValue.map((file) => file))
    const e = acceptedFiles?.filter((file) => file.size <= maxSize);
    console.log(e, "ghge");
    if (e.length !== acceptedFiles.length) {
      TriggerAlert("warning", ATTACH_MESSAGE);
    }
    if (e.length) {
      let w = e.filter(
        (value) =>
          !(fileValue?.findIndex((file) => file.name === value.name) >= 0)
      );
      console.log(w, "vvvvvvvv");
      console.log(e, fileValue, "fdgfhgj");
      if (w.length !== e.length && fileValue.length) {
        alert("File already exists");
      }
      setFileValue([...fileValue, ...w]);
      sendAttachment(w).then((res) => {
        const t = {};
        res?.data.map((list) => {
          t[list.originalFileName] = list?.fileCode;
        });
        // setValue('attachmentLocations', [...getValues('attachmentLocations'),...res?.data.map((list)=>list?.fileCode)  ];);
        setValue("attachmentLocations", {
          ...getValues("attachmentLocations"),
          ...t,
        });
        trigger("attachmentLocations");
        console.log(t, "ttttttt");
        console.log(
          res?.data,
          res?.data.map((list) => list?.fileCode),
          "response"
        );
      });
    }
  };
  useEffect(() => {
    if (
      errors?.projectCode?.message ||
      errors?.message?.message ||
      errors?.title?.message
    ) {
      TriggerAlert("warning", "Mandatory fields can't be empty");
    }
  }, [errors]);
  console.log(filePayload, "uploadresss");
  console.log(fileValue, "filevaue");

  const handleChange = (event) => {
    let t = event.target.value === "true" ? true : false;
    console.log(typeof event.target.value, t, "radioo");
    setValue("isGeneralAnnouncement", t);
    console.log(getValues("isGeneralAnnouncement"), "hghgg");
    trigger("isGeneralAnnouncement");
    if (event.target.value === "true") {
      setValue("jobCode", null);
    }
  };
  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: grey[100],
            height: "100%",
            pt: 2,
            overflow: "hidden",
          }}
        >
          <Card
            sx={{
              width: { lg: "95%", md: "75%" },
              height: { lg: "95%", md: "95%" },
              // px: 4,
              py: 4,
              borderRadius: 5,
              overflow: "auto",
              boxShadow: "none",
              px: 3,
            }}
          >
            <Box sx={{ pl: 5, mb: 5 }}>
              <Controller
                control={control}
                name="isGeneralAnnouncement"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: green[800],
                                "&.Mui-checked": {
                                  color: green[600],
                                },
                              }}
                            />
                          }
                          label={<Box sx={{ fontSize: 12 }}>General</Box>}
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: pink[800],
                                "&.Mui-checked": {
                                  color: pink[300],
                                },
                              }}
                            />
                          }
                          label={<Box sx={{ fontSize: 12 }}>Job Specific</Box>}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Box sx={{ ...headings }}>Project Code*</Box> */}
                <Box sx={{ ...contents }}>
                  <Controller
                    control={control}
                    name="projectCode"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormControl
                        size="small"
                        // error={errors?.projectCode?.message}
                        fullWidth
                        sx={{}}
                      >
                        <InputLabel shrink>Project Code*</InputLabel>
                        <Select
                          fullWidth
                          notched
                          label="Project Code*"
                          value={value}
                          onChange={(e) => {
                            console.log(e.target.value, "uh");
                            onChange(e);
                            trigger("projectCode");
                            dispatch(
                              getJobByProjectAction({
                                projectCode: e.target.value,
                                orgId:
                                  userprofile?.userAccess?.superUser === true
                                    ? globapOrganization?.orgId
                                    : userprofile?.userInfo?.userInfo?.orgId,
                              })
                            );
                          }}
                          // error={errors?.projectCode?.message}
                          onBlur={() => trigger("projectCode")}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                          sx={{
                            width: "100%",
                            borderRadius: 2,
                          }}
                          // label='Project Name*'
                          inputProps={{
                            "aria-label": "Project Name",
                          }}
                        >
                          {projectCodeDta?.project_details?.map((val) => (
                            <MenuItem
                              sx={{
                                borderBottom: "1px solid white",
                                fontFamily: "Lato",
                                fontSize: 13,
                              }}
                              value={val?.projectCode}
                            >
                              {val?.projectCode}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText sx={{ color: "red" }}>
                            {errors.projectCode ? errors.projectCode.message : null}
                          </FormHelperText> */}
                      </FormControl>
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Box sx={{ ...headings }}>Job Code</Box> */}
                <Box sx={{ ...contents }}>
                  <Controller
                    control={control}
                    name="jobCode"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormControl
                        size="small"
                        // error={errors?.jobCode?.message}
                        fullWidth
                        sx={{}}
                      >
                        <InputLabel shrink>Job Code</InputLabel>
                        <Select
                          disabled={getValues("isGeneralAnnouncement")}
                          notched
                          fullWidth
                          label="Job Code"
                          // helperText={
                          //   errors.jobCode ? errors?.jobCode?.message : null
                          // }
                          // error={errors?.jobCode?.message}
                          onChange={(e) => {
                            onChange(e);
                            trigger("jobCode");
                          }}
                          onBlur={() => trigger("jobCode")}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                          value={value}
                          sx={{
                            width: "100%",
                            borderRadius: 2,
                            background: getValues("isGeneralAnnouncement")
                              ? "#f6f7fe"
                              : "white",
                          }}
                          // label='Project Name*'
                          inputProps={{
                            "aria-label": "Project Name",
                          }}
                        >
                          {jobList?.jobList?.map((val) => (
                            <MenuItem
                              sx={{
                                borderBottom: "1px solid white",
                                fontFamily: "Lato",
                                fontSize: 13,
                              }}
                              value={val?.jobCode}
                            >
                              {val?.jobCode}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText sx={{ color: "red" }}>
                            {errors.jobCode ? errors.jobCode.message : null}
                          </FormHelperText> */}
                      </FormControl>
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Box sx={headings}>Title*</Box> */}
                <Box sx={contents}>
                  <Controller
                    control={control}
                    name="title"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        // helperText={errors.title ? errors?.title?.message : null}
                        // error={errors?.title?.message}
                        onChange={(e) => {
                          onChange(e);
                          trigger("title");
                        }}
                        onBlur={() => trigger("title")}
                        size="small"
                        label={"Title*"}
                        value={value}
                        inputProps={{ "aria-label": "Name" }}
                        InputProps={formFieldStyle.InputProps}
                        InputLabelProps={formFieldStyle.InputLabelProps}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {/* <Box sx={headings}>Priority</Box> */}
                  <Box sx={contents}>
                    {/* <Box sx={{ fontSize: 12 }}>Priority</Box> */}
                    <Controller
                      control={control}
                      name="priority"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControlLabel
                          control={
                            <>
                              <ToggleButtonGroup
                                // disabled={state}
                                color="primary"
                                // value={value}
                                exclusive
                                onChange={(e, v) => {
                                  // console.log(e.target.checked, "jhu");
                                  if (v === "high") {
                                    setValue("priority", 1);
                                  } else {
                                    setValue("priority", 0);
                                  }
                                }}
                                aria-label="Platform"
                              >
                                <ToggleButton
                                  size="small"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: 70,
                                    color:
                                      getValues("priority") === 0
                                        ? toggleColorAnnouncement.normalfontColor
                                        : null,
                                    background:
                                      getValues("priority") === 0
                                        ? toggleColorAnnouncement.normalback
                                        : null,
                                    ":hover": {
                                      color:
                                        toggleColorAnnouncement.hovernormalfontColor,
                                      background:
                                        toggleColorAnnouncement.normalback,
                                    },
                                  }}
                                  value="normal"
                                >
                                  Normal
                                </ToggleButton>
                                <ToggleButton
                                  // selected
                                  size="small"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: 70,
                                    background:
                                      getValues("priority") === 1
                                        ? toggleColorAnnouncement.highback
                                        : null,
                                    color:
                                      getValues("priority") === 1
                                        ? toggleColorAnnouncement.highfontColor
                                        : null,
                                    ":hover": {
                                      color:
                                        toggleColorAnnouncement.hoverhighfontColor,
                                      background:
                                        toggleColorAnnouncement.highback,
                                    },
                                  }}
                                  value="high"
                                >
                                  High
                                </ToggleButton>
                              </ToggleButtonGroup>
                              {/* <Stack direction="row" spacing={3} alignItems="center" >
                                <Typography sx={{ fontFamily: 'Lato', fontSize: 12, fontWeight: 700, color: !value ? "#339af0" : "black" }}>Normal</Typography>
                                <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={Boolean(value)}
                                  // onChange={() => { setPayload({ ...payload, priority: !payload.priority }) }}
                                  onChange={(e) => {
                                    console.log(e.target.checked, "jhu");
                                    setValue("priority", +Number(e.target.checked));
                                  }}
                                  sx={{ m: 1 }} />
                                <Typography sx={{ fontFamily: 'Lato', fontSize: 12, fontWeight: 700, color: value ? "#ff3300" : "black" }}>High</Typography>
                              </Stack> */}
                            </>
                          }
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    width: "95%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    pl: 5,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: 12,
                      my: 1,
                      // color: errors?.message?.message ? "#d32f2f" : ""
                    }}
                  >
                    Content*
                  </Box>
                  <Controller
                    control={control}
                    name="message"
                    // helperText={errors.message ? errors?.message?.message : null}

                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Box
                      // onBlur={() => trigger("message")}
                      // error={errors?.message?.message && getValues('message')===''}
                      // sx={{
                      //   border:
                      //     errors?.message || contentError
                      //       ? "0.5px solid red"
                      //       : null,
                      // }}
                      >
                        <SunEditor
                          setContents={value}
                          onChange={(e) => {
                            setValue("message", e);
                            console.log(
                              e.length,
                              contentError,
                              e
                                .toString()
                                .replace(/<br>/, "")
                                .includes("<div></div>"),
                              e,
                              "contentssss"
                            );
                            trigger("message");
                            if (
                              e.includes("<div><br></div>") &&
                              e.length === 16
                            ) {
                              setContentError(true);
                              console.log(contentError, "contenteroor");
                            } else {
                              setContentError(false);
                            }
                          }}
                          onInput={(e) => {
                            if (!e.target.outerText) {
                              setContentError(true);
                              console.log("matchh");
                            } else {
                              setContentError(false);
                            }
                            console.log(
                              e.target.outerText,
                              e.target.outerText.length,
                              contentError,
                              "inputt"
                            );
                          }}
                          height="250"
                          // width="200"
                          setDefaultStyle="font-family: Arial; font-size: 14px;"
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],

                              ["table", "horizontalRule", "link"],
                            ],
                            defaultTag: "div",
                            minHeight: "200px",
                            maxHeight: 300,
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                        />
                      </Box>
                    )}
                  />
                  {/* {errors?.message || contentError ? (
                      <Typography sx={{ fontSize: 12, color: "red", ml: 0.5 }}>
                        {errors?.message?.message}
                      </Typography>
                    ) : null} */}
                  {/* <Controller
                  control={control}
                  name="message"
                  // helperText={errors.message ? errors?.message?.message : null}
                  // error={errors?.message?.message}
                  // onBlur={() => trigger("message")}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <><Box sx={{ borderBottom: '1px solid #c4c4c4' }}>
                      {console.log(count, 'count')}
                      <ReactQuill
                        theme="snow"
                        value={value}
                        modules={modules}
                        formats={formats}
                        // scrollingContainer
                        placeholder="Max characters = 1000"

                        onChange={(content, delta) => {
                          if (delta.ops.length >= 2) {
                            console.log(delta?.ops, "datadada")
                            let t = delta.ops.slice(1).reduce((acc, curr) => acc += curr?.insert?.length, 0) || 0

                            console.log(t, "dfdsgsgfdg")
                            if (delta.ops[1].delete) { setCount(delta?.ops[0]?.retain); console.log(delta?.ops[0]?.retain, "efdgfdg") }
                            else { setCount(delta?.ops[0]?.retain + t) }
                            if ((delta?.ops[0]?.retain + t) <= 1000) {
                              console.log('retain')
                              return onChange(content);
                            }


                          }
                          { onChange(content); 
                            setCount(delta?.ops[0]?.insert?.length) };
                          trigger("message");
                        }}

                        style={{
                          //     '.ql-toolbar': { position: 'absolute', top: 0,left:0,right:0,background:'red'},
                          '.ql-editor': {
                            height: '160px',
                            overflowY: 'hidden',
                            overflow: 'hidden',
                            // Bottom: '20px'
                            // marginBottom:10
                            background: 'red'
                          },
                          //  '.ql-container ql-snow': {
                          //       height: '600px',
                          //       overflowY: 'hidden',
                          //       background:'red'
                          //   },
                          width: '100%',
                          height: 160,
                          maxHeight: 160,
                          overflow: 'auto',

                          //  background:'red',
                          border:
                            errors?.message ? "1px solid red" : "none",
                        }}
                      ></ReactQuill>
                    </Box>
                      {errors?.message && value === "" ?

                        <Typography sx={{ fontSize: 12, color: 'red', ml: 0.5 }}>{errors?.message?.message}</Typography>
                        :
                        // null
                        <Typography sx={{ fontSize: 12, ml: 0.5 }}> {count ? 'Remaining characters =' + (1000 - (count)) : 'Remaining characters = 1000'}</Typography>
                      }
                    </>
                  )}
                /> */}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    px: 5,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ fontSize: 12, my: 1, mr: 2, minWidth: 80 }}>
                    Attach Files (max size - 1MB)
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Dropzone
                      maxFiles={10}
                      multiple
                      noClick
                      onDrop={(acceptedFiles) =>
                        handelChangeFile(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps, open }) => (
                        <div {...getRootProps()}>
                          <input multiple={false} {...getInputProps()} />
                          <Button
                            onClick={open}
                            size="small"
                            variant="contained"
                            component="span"
                            sx={{
                              borderRadius: 5,
                              mx: 0.7,
                              height: 26,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            <Attachment sx={{ color: "white", mr: 0 }} />
                            {/* <Box sx={{ fontSize: 10 }}>Upload File(s)</Box> */}
                          </Button>
                        </div>
                      )}
                    </Dropzone>

                    {/* </label> */}
                    {fileValue.length >= 1 ? (
                      <Box
                        sx={{
                          fontSize: 11,
                          mr: 1,
                          pl: 2,
                          display: "flex",
                          flexWrap: "wrap",
                          maxWidth: "70%",
                          // background:'red'
                        }}
                      >
                        {fileValue?.map((list, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mx: 1,
                            }}
                          >
                            {list?.name}

                            {!getValues("attachmentLocations")[list?.name] ? (
                              <CircularProgress
                                sx={{ ml: 0.5 }}
                                size={20}
                                color="success"
                              />
                            ) : (
                              <Delete
                                sx={{
                                  fontSize: 15,
                                  cursor: "pointer",
                                  ml: 0.5,
                                }}
                                color="error"
                                onClick={() => {
                                  clearField(list, index);
                                }}
                              />
                            )}
                          </Box>
                        ))}
                      </Box>
                    ) : null}

                    {/* <input
                        type="file"
                        id="uploadAttachments"
                        ref={fileRef}
                        multiple

                        onChange={handelChangeFile}
                        style={{ color: "black", display: "none" }}
                      /> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "center",
                    pt: 3,
                  }}
                >
                  {/* <Button
                    // onClick={onSubmit}
                    size="small"
                    color="primary"
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{
                      // mx: 2,
                      borderRadius: 5,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                    }}
                    variant="contained"
                  >
                    List
                  </Button> */}
                  <Button
                    // onClick={onSubmit}
                    size="small"
                    type="submit"
                    onClick={() => {
                      trigger("message");
                    }}
                    sx={{
                      // mx: 2,
                      backgroundColor: "#258d4d",
                      ":hover": {
                        backgroundColor: "#258d4d",
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                      borderRadius: 5,
                      // ":hover": {
                      //   boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      // },
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>

                  <Button
                    color="error"
                    size="small"
                    onClick={() => {
                      setAlertPopup((per) => ({
                        ...per,
                        open: true,
                        type: PopupMessage.resetType,
                        title: PopupMessage.reset,
                      }));
                    }}
                    sx={{
                      borderRadius: 5,
                      // mx: 2,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                    }}
                    variant="contained"
                  >
                    {RESET_LABEL}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </form>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
