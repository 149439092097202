import { grey } from "@mui/material/colors";
import { allpageFontStyle } from "./FontStyle";

export const fontStyle = {
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
};
export const inputButton = {
  backgroundColor: "gray",
  color: "white",
  border: "none",
  padding: 4,
  borderRadius: 2,
};
export const fileNameStyle = {
  textDecoration: "underline",
  cursor: "pointer",
  ":hover": {
    color: "blue",
  },
};
export const fileDownloadStyle = {
  fontSize: 16,
  cursor: "pointer",
  mt: 0.2,
};
export const labelStyleinCreateJob = {
  fontSize: 16,
};
export const labelStyleinDashboard = {
  ...allpageFontStyle.typography1,
  // backgroundColor:'pink',
  // textAlign: "right",
  overflowWrap: "break-word",
  paddingBottom: 0,
};
export const contentStyleDashboard = {
  fontSize: "1.2rem",
  display: "flex",
  justifyContent: "center",
  paddingTop: 0,
  paddingBottom: 0,
  fontWeight: "bold",
  // backgroundColor:'red'
};
export const containStyleTypo = {
  display: "flex",
  alignItems: "center",
};
export const passwordRulStyle = {
  fontFamily: "Lato",
  fontSize: 14,
};

export const formContainerStyle = {
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  minHeight: 800,
  height: "100%",
  background: grey[100],
  p: 3,
};
export const formContainerCardStyle = {
  m: 1,
  width: "100%",
  boxShadow: "none",
  borderRadius: 5,
  minWidth: 400,
};

export const formFieldStyle = {
  InputProps: {
    style: {
      borderRadius: 8,
      outline: "none",
    },
  },
  inputProps: {
    style: {
      height: 13,
      fontSize: 11,
      padding: 7,
    },
  },
  InputLabelProps: { shrink: true },
};
export const textFieldsStyle = (h, f) => {
  return {
    ".MuiInputBase-input": {
      height: h || 13,
      // ...heightSmall && { fontSize: 10 }
      fontSize: f || 13,
    },
    "&.MuiTextField-root": {
      minWidth: 100,
    },
  };
};
export const DateFieldsStyle = (h, w, f) => {
  return {
    ".MuiFormControl-root": {
      height: h || 12,
      width: w || 600,
      fontSize: f || 13,
    },
  };
};
