import {
  AssignmentTurnedIn,
  Flag,
  History,
  NotStarted,
  Pause,
  PlayArrow,
  PriorityHigh,
  Undo,
  Update,
} from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { generaltaskHistoryColor } from "../../utils/CommonColors";
import moment from "moment";
import "./Timeline.css";

function TimelinePopUp(props) {
  const { taskdata, view } = props;
  const [open, setOpen] = useState(false);
  const handelPopupOpen = () => {
    setOpen(true);
  };
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} : ${minutes} : ${Math.round(seconds)}`;
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const fontStyle = {
    fontSize: 11,
    // fontFamily: "Lato"
  };
  const fontStyleLabel = {
    fontWeight: "bold",
    fontSize: 12,
  };
  const dateTime = (date) => moment(date).format("DD/MM/YYYY hh:mm a");
  return (
    <Box>
      <Box>
        {view ? (
          <Box sx={{ height: 650, minHeight: 650, overflowY: "auto" }}>
            <Box>
              <VerticalTimeline
                layout="1-column"
                lineColor="black"
                animate={true}
              >
                {taskdata?.taskHistoryInfo &&
                  taskdata?.taskHistoryInfo?.length > 0 &&
                  taskdata?.taskHistoryInfo.map((val) => (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--education"
                      iconStyle={{
                        background:
                          val?.state === "Started"
                            ? generaltaskHistoryColor.start
                            : val?.state === "Updated"
                            ? generaltaskHistoryColor.update
                            : val?.state === "End"
                            ? generaltaskHistoryColor.End
                            : val?.state === "Not Started"
                            ? generaltaskHistoryColor.notstart
                            : "#5d8cba",
                        color: "#fff",
                      }}
                      contentArrowStyle={{
                        display: "none",
                      }}
                      contentStyle={{
                        width: 300,
                        height: 80,

                        padding: "0px 0px 0px 5px",
                        backgroundColor: "white",
                        borderTop: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                        borderLeft: "5px solid",
                        borderLeftColor:
                          val?.state === "Started"
                            ? generaltaskHistoryColor.start
                            : val?.state === "Updated"
                            ? generaltaskHistoryColor.update
                            : val?.state === "End"
                            ? generaltaskHistoryColor.End
                            : val?.state === "Not Started"
                            ? generaltaskHistoryColor.notstart
                            : "#5d8cba",
                      }}
                      icon={
                        val?.state === "Started" ? (
                          <Flag />
                        ) : val?.state === "Updated" ? (
                          <Update />
                        ) : val?.state === "End" ? (
                          <AssignmentTurnedIn />
                        ) : val?.state === "Not Started" ? (
                          <NotStarted />
                        ) : (
                          <History />
                        )
                      }
                    >
                      <Box sx={{ fontFamily: "Lato" }}>
                        <Box sx={{ p: 0, m: 0 }}>
                          <b style={{ ...fontStyleLabel }}>Status: </b>
                          <span style={{ ...fontStyle }}>{val.state}</span>
                        </Box>
                        {/* <Box sx={{ p: 0, m: 0 }}>
                                                    <b style={{ ...fontStyleLabel }}>Status: </b>
                                                    <span style={{ ...fontStyle }}>{val.status}</span>
                                                </Box> */}
                        <Box sx={{ p: 0, m: 0 }}>
                          {val?.state === "Started" && (
                            <>
                              {" "}
                              <b style={{ ...fontStyleLabel }}>
                                Started Time:{" "}
                              </b>
                              <span style={{ ...fontStyle }}>
                                {dateTime(val.createdOn)}
                              </span>
                            </>
                          )}
                          {val?.state === "End" && (
                            <>
                              {" "}
                              <b style={{ ...fontStyleLabel }}>Ended Time: </b>
                              <span style={{ ...fontStyle }}>
                                {dateTime(val.createdOn)}
                              </span>
                            </>
                          )}
                        </Box>
                        {val?.state === "Started" ? null : (
                          <Box sx={{ p: 0, m: 0 }}>
                            <b style={{ ...fontStyleLabel }}>Working Time: </b>{" "}
                            <span style={{ ...fontStyle }}>
                              {" "}
                              {toHoursAndMinutes(
                                toSeconds(
                                  val?.workHours,
                                  val?.workMinutes,
                                  val?.workSeconds
                                )
                              )}
                            </span>
                          </Box>
                        )}
                      </Box>
                    </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>
            </Box>
          </Box>
        ) : (
          <Visibility
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handelPopupOpen();
            }}
          />
        )}
      </Box>
      <Dialog
        open={open}
        sx={{}}
        // maxWidth={"sm"}
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle>General Task {taskdata?.taskName}</DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <VerticalTimeline
                layout="1-column"
                lineColor="black"
                animate={true}
              >
                {taskdata?.taskHistoryInfo &&
                  taskdata?.taskHistoryInfo?.length > 0 &&
                  taskdata?.taskHistoryInfo.map((val) => (
                    <VerticalTimelineElement
                      iconStyle={{
                        background:
                          val?.state === "Started"
                            ? generaltaskHistoryColor.start
                            : val?.state === "Updated"
                            ? generaltaskHistoryColor.update
                            : val?.state === "End"
                            ? generaltaskHistoryColor.End
                            : val?.state === "Not Started"
                            ? generaltaskHistoryColor.notstart
                            : "#5d8cba",
                        color: "#fff",
                      }}
                      contentArrowStyle={{
                        display: "none",
                      }}
                      contentStyle={{
                        width: 400,
                        backgroundColor: "white",
                        borderTop: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                        borderLeft: "5px solid",
                        borderLeftColor:
                          val?.state === "Started"
                            ? generaltaskHistoryColor.start
                            : val?.state === "Updated"
                            ? generaltaskHistoryColor.update
                            : val?.state === "End"
                            ? generaltaskHistoryColor.End
                            : val?.state === "Not Started"
                            ? generaltaskHistoryColor.notstart
                            : "#5d8cba",
                      }}
                      icon={
                        val?.state === "Started" ? (
                          <Flag />
                        ) : val?.state === "Updated" ? (
                          <Update />
                        ) : val?.state === "End" ? (
                          <AssignmentTurnedIn />
                        ) : val?.state === "Not Started" ? (
                          <NotStarted />
                        ) : (
                          <History />
                        )
                      }
                    >
                      <Box sx={{ fontFamily: "Lato" }}>
                        <Box sx={{ p: 0, m: 0 }}>
                          <b style={{ ...fontStyleLabel }}>State: </b>
                          <span style={{ ...fontStyle }}>{val.state}</span>
                        </Box>
                        <Box sx={{ p: 0, m: 0 }}>
                          <b style={{ ...fontStyleLabel }}>Status: </b>
                          <span style={{ ...fontStyle }}>{val.status}</span>
                        </Box>
                        <Box sx={{ p: 0, m: 0 }}>
                          {val?.state === "Started" && (
                            <>
                              {" "}
                              <b style={{ ...fontStyleLabel }}>
                                Started Time:{" "}
                              </b>
                              <span style={{ ...fontStyle }}>
                                {dateTime(val.createdOn)}
                              </span>
                            </>
                          )}
                          {val?.state === "End" && (
                            <>
                              {" "}
                              <b style={{ ...fontStyleLabel }}>Ended Time: </b>
                              <span style={{ ...fontStyle }}>
                                {dateTime(val.createdOn)}
                              </span>
                            </>
                          )}
                        </Box>
                        {val?.state === "Started" ? null : (
                          <Box sx={{ p: 0, m: 0 }}>
                            <b style={{ ...fontStyleLabel }}>Working Time: </b>{" "}
                            <span style={{ ...fontStyle }}>
                              {" "}
                              {toHoursAndMinutes(
                                toSeconds(
                                  val?.workHours,
                                  val?.workMinutes,
                                  val?.workSeconds
                                )
                              )}
                            </span>
                          </Box>
                        )}
                      </Box>
                    </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default TimelinePopUp;
