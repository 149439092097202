import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CloseIcon from "@mui/icons-material/Close";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import DraftsIcon from "@mui/icons-material/Drafts";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlagIcon from "@mui/icons-material/Flag";
import InfoIcon from "@mui/icons-material/Info";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import UndoIcon from "@mui/icons-material/Undo";
import CampaignIcon from "@mui/icons-material/Campaign";
import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from "@mui/icons-material/Clear";
import ForumIcon from "@mui/icons-material/Forum";
import {
  Badge,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import moment from "moment";
import * as React from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotificationListAction } from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { messageAsRead } from "../../service/notificationService";
import { formFieldStyle } from "../../utils/Style";
import {
  AccessTime,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import DateComponet from "../commonComponents/DateComponet";
import { getNotificationCountAction } from "../../service/action/action";

export default function TemporaryDrawer() {
  const [value, setValue] = React.useState({
    fromDate: "",
    toDate: "",
  });
  const { userprofile } = useSelector((state) => state.presist);
  const [shouldCloseCalendar, setShouldCloseCalendar] = React.useState(false);
  const dispatch = useDispatch();
  const [state, setState] = React.useState(false);
  const [badge, setBadge] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const { notificationList, push, notificationCount } = useSelector(
    (state) => state.master
  );
  const { unreadCount } = useSelector((state) => state.common);
  console.log(unreadCount, "unread");
  const navigate = useNavigate();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 7,
      top: 7,
      // border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  const toggleDrawer = (open) => {
    setState(open);
    dispatch(
      updateMasterStates({
        name: "push",
        value: 0,
      })
    );
  };

  const messageUnread = async (list) => {
    await messageAsRead(list.id);
    getNotificationCountAction();
    if (
      list?.notificationType === "TASK_ASSIGNED" ||
      list?.notificationType === "TASK_REASSIGNED" ||
      list?.notificationType === "TASK_PAUSED" ||
      list?.notificationType === "TASK_RESUMED" ||
      list?.notificationType === "TASK_STARTED" ||
      list?.notificationType === "TASK_END" ||
      list?.notificationType === "TASK_TIMER_NEAR"
    ) {
      navigate("/tasks");
    } else if (
      list?.notificationType === "TASK_REVERTED" ||
      list?.notificationType === "TASK_REVERT_REQUEST"
    ) {
      navigate("/tasks/revert");
    } else if (
      list?.notificationType === "ANNOUNCEMENT" &&
      list?.notificationInfo?.data?.announcementId
    ) {
      navigate("/helpdesk", {
        state: {
          tab: 0,
          announcementId: list?.notificationInfo?.data?.announcementId,
          projectCode: list?.notificationInfo?.data?.projectCode,
          open: true,
        },
      });
      // } else if (list?.notificationType === "QUERY") {
      //   navigate("/helpdesk", {state:{tab:1}});
    } else if (
      list?.notificationType === "SLA_LEVEL_1" ||
      list?.notificationType === "SLA_LEVEL_2"
    ) {
      if (userprofile?.userAccess?.isTroubleShooter === true) {
        navigate("/helpdesk?", {
          state: {
            tab: 2,
            queryCode: list?.notificationInfo?.data?.queryCode,
            projectCode: list?.notificationInfo?.data?.projectCode,
          },
        });
      } else {
        navigate(`/forum/${list?.notificationInfo?.data?.queryCode}`);
      }
    } else if (list?.notificationType === "QUERY") {
      navigate(`/forum/${list?.notificationInfo?.data?.queryCode}`);
      console.log(list?.notificationInfo?.data?.queryCode, "codeeee");
    }
    onclose();
  };
  const seeMore = async () => {
    try {
      setLoader(true);
      let id = notificationList?.pageInfo?.currentPageNumber + 1;
      await dispatch(
        getNotificationListAction(
          10,
          id,
          value.fromDate ? moment(value.fromDate).format("YYYY-MM-DD") : "",
          value.toDate ? moment(value.toDate).format("YYYY-MM-DD") : ""
        )
      );
      setLoader(false);
    } catch {
      setLoader(false);
    }
  };
  React.useEffect(() => {
    dispatch(
      getNotificationListAction(
        10,
        1,
        value.fromDate ? moment(value.fromDate).format("YYYY-MM-DD") : "",
        value.toDate ? moment(value.toDate).format("YYYY-MM-DD") : "",
        setBadge
      )
    );
  }, []);
  const notificationOpen = () => {
    dispatch(
      getNotificationListAction(
        10,
        1,
        value.fromDate ? moment(value.fromDate).format("YYYY-MM-DD") : "",
        value.toDate ? moment(value.toDate).format("YYYY-MM-DD") : ""
      )
    );
    toggleDrawer(!state);
  };
  const onclose = () => {
    toggleDrawer(false);
    setValue("");
    dispatch(
      updateMasterStates({
        name: "notificationList",
        value: {},
      })
    );
  };
  const onload = () => {
    dispatch(
      getNotificationListAction(
        10,
        1,
        value.fromDate ? moment(value.fromDate).format("YYYY-MM-DD") : "",
        value.toDate ? moment(value.toDate).format("YYYY-MM-DD") : ""
      )
    );
  };
  const onclear = async () => {
    setValue({
      fromDate: "",
      toDate: "",
    });
    dispatch(getNotificationListAction(10, 1, "", ""));
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 400,
        backgroundColor: "white",
        height: "100%",
      }}
      role="presentation"
      // onClick={() => toggleDrawer(false)}
      // onKeyDown={() => toggleDrawer(false)}
    >
      <List
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "black",
            py: 2,
            // position:'absolute', zIndex:999999,top:0, right:0, left:0
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pl: 2, m: 0 }}>
            <MarkUnreadChatAltIcon sx={{ color: "#358c4e" }} />
            <Box
              sx={{ color: "white", fontSize: 20, pl: 2, fontWeight: "bold" }}
            >
              Notification
            </Box>
          </Box>
          <Button onClick={onclose}>
            <CloseIcon
              sx={{
                height: 25,
                width: 25,
                color: "#358c4e",
                // backgroundColor: "grey",
                // borderRadius: "50%",
              }}
            />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            py: 3,
            px: 2,
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: "center",
          }}
        >
          {/* <DatePicker
            value={value}
            // animations={[transition()]}
            //  onOpen={()=>setShouldCloseCalendar(true)}
            //  onClose={() => shouldCloseCalendar}
            onChange={(e) => {
              // if(e.length ===2)
              //   setShouldCloseCalendar(false);

              const date = e?.map((val) =>
                moment(new Date(val).toLocaleDateString()).format("YYYY-MM-DD")
                // new Date(val).toLocaleDateString()
              );
              setValue(date);
            }}
            range
            dateSeparator=" to "
            rangeHover
            className="green"
            render={
              <TextField
                size="small"
                label="Date Range"
                color="success"

                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { fontSize: 12 } }}
              />
            }
          /> */}
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <DateComponet
                noClear
                value={
                  value.fromDate
                    ? new Date(
                        moment(value.fromDate).format(
                          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                        )
                      )
                    : null
                }
                maxDate={new Date(value.toDate)}
                label={"From"}
                style={{ width: "100%" }}
                setValue={setValue}
                handelDate={(e) => {
                  setValue({ ...value, fromDate: e, toDate: e });
                }}
              />
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DateComponet
                noClear
                value={
                  value.toDate
                    ? new Date(
                        moment(value.toDate).format(
                          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                        )
                      )
                    : null
                }
                minDate={new Date(value.fromDate)}
                label={"To"}
                style={{ width: "100%" }}
                setValue={setValue}
                handelDate={(e) => {
                  setValue({ ...value, toDate: e });
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: 1,
                pb: 0.5,
                // background: 'red'
              }}
            >
              <CachedIcon
                titleAccess="Load"
                sx={{
                  color: "white",
                  cursor: "pointer",
                  background: "#358c4e",
                  borderRadius: "50%",
                  ml: 0.5,
                  p: 0.2,
                }}
                onClick={onload}
                fontSize="medium"
              />
              <ClearIcon
                sx={{
                  color: "white",
                  cursor: "pointer",
                  background: "#fa5252",
                  borderRadius: "50%",
                  ml: 0.5,
                  p: 0.2,
                }}
                onClick={onclear}
                fontSize="medium"
              />
            </Grid>
          </Grid>
          {/* <TextField
            sx={{
              width: "85%",
              mr: 0.5,
            }}
            value={value.fromDate}
            onChange={(e) => {
              setValue({ ...value, fromDate: e.target.value });
            }}
            label="From"
            type="date"
            size="small"
            inputProps={{
              max: value.toDate
                ? moment(value.toDate).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              style: { padding: 4, fontSize: 14 },
            }}
            InputProps={formFieldStyle.InputProps}
            InputLabelProps={formFieldStyle.InputLabelProps}
          /> */}
          {/* <TextField
            sx={{
              width: "85%",
            }}
            value={value.toDate || ""}
            onChange={(e) => {
              setValue({ ...value, toDate: e.target.value });
            }}
            label="To"
            type="date"
            size="small"
            inputProps={{
              min: moment(value.fromDate).format("YYYY-MM-DD"),
              max: moment().format("YYYY-MM-DD"),
              style: { padding: 4, fontSize: 14 },
            }}
            InputProps={formFieldStyle.InputProps}
            InputLabelProps={formFieldStyle.InputLabelProps}
          /> */}
          {console.log(value, "datesss")}

          {/* <Button
            onClick={onload}
            size="small"
            sx={{ ml: 1, borderRadius: 5, fontSize: 11 }}
            variant="contained"
            color="success"
            disabled={(value.fromDate && ! value.toDate) || (!value.fromDate && value.toDate)}
          >
            L
          </Button> */}

          {/* <Button
            onClick={onclear}
            size="small"
            sx={{ ml: 1, borderRadius: 5, fontSize: 11 }}
            variant="contained"
            color="error"
            disabled={(value.fromDate && ! value.toDate) || (!value.fromDate && value.toDate)}
          >
            C
          </Button>  */}
        </Box>
        <Divider />
        <Box sx={{ backgroundColor: "white", flex: 1, overflow: "auto" }}>
          {notificationList?.notificationData?.map((list) => (
            <>
              <Card
                onClick={() => {
                  messageUnread(list);
                }}
                sx={{
                  m: 1,
                  position: "relative",
                  cursor: "pointer",
                  p: 0.2,
                  border:
                    list?.isRead === false
                      ? `1px solid ${
                          list?.notificationType === "ANNOUNCEMENT" ||
                          list?.notificationType === "QUERY"
                            ? list?.notificationInfo?.data?.criticality ===
                              "low"
                              ? "#339af0"
                              : list?.notificationInfo?.data?.criticality ===
                                "medium"
                              ? "#ffc078"
                              : list?.notificationInfo?.data?.criticality ===
                                "high"
                              ? "red"
                              : "#358c4e"
                            : list?.notificationType === "SLA_LEVEL_1" ||
                              list?.notificationType === "SLA_LEVEL_2"
                            ? "#358c4e"
                            : "#358c4e"
                        }`
                      : `1px solid ${
                          list?.notificationType === "ANNOUNCEMENT" ||
                          list?.notificationType === "QUERY"
                            ? list?.notificationInfo?.data?.criticality ===
                              "low"
                              ? "#339af0"
                              : list?.notificationInfo?.data?.criticality ===
                                "medium"
                              ? "#ffc078"
                              : list?.notificationInfo?.data?.criticality ===
                                "high"
                              ? "red"
                              : "#358c4e"
                            : list?.notificationType === "SLA_LEVEL_1" ||
                              list?.notificationType === "SLA_LEVEL_2"
                            ? "#358c4e"
                            : "#358c4e"
                        }`,
                  boxShadow: "none",
                  // my: list?.notificationType === "ANNOUNCEMENT" || list?.notificationType === "QUERY" ? 5 : 1,
                  // backgroundColor: list?.isRead === false ? "#ced2d6" : "white",
                  // border: list?.notificationType === "ANNOUNCEMENT" && list?.notificationInfo?.priority === "high" ? `1px solid red` : ``
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ pt: 0.5 }}>
                      {list?.notificationType === "TASK_ASSIGNED" ? (
                        <AddTaskOutlinedIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_REASSIGNED" ? (
                        <AssignmentReturnIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_REVERTED" ? (
                        <UndoIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_SPLIT" ? (
                        <CallSplitIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_PAUSED" ? (
                        <PauseIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_RESUMED" ? (
                        <PlayArrowIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_STARTED" ? (
                        <FlagIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_END" ? (
                        <DisabledByDefaultIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "TASK_TIMER_NEAR" ? (
                        <AccessTimeIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "ANNOUNCEMENT" ? (
                        <CampaignIcon
                          sx={{
                            color:
                              list?.notificationInfo?.data?.priority === "HIGH"
                                ? "red"
                                : "#358c4e",
                          }}
                        />
                      ) : list?.notificationType === "SLA_LEVEL_1" ||
                        list?.notificationType === "SLA_LEVEL_2" ? (
                        <AccessTime
                          sx={{
                            color: "red",
                          }}
                        />
                      ) : list?.notificationType === "QUERY" ? (
                        <ForumIcon
                          sx={{
                            color:
                              list?.notificationInfo?.data?.criticality ===
                              "low"
                                ? "#339af0"
                                : list?.notificationInfo?.data?.criticality ===
                                  "medium"
                                ? "#ffc078"
                                : list?.notificationInfo?.data?.criticality ===
                                  "high"
                                ? "red"
                                : "#358c4e",
                          }}
                        />
                      ) : (
                        <InfoIcon
                          sx={{
                            color: list?.isRead === false ? "grey" : "#358c4e",
                          }}
                        />
                      )}
                    </Box>

                    <Box
                      sx={{
                        // pl: 2,
                        fontSize: 12,
                        fontWeight: list?.isRead === false ? 300 : 800,
                        textAlign:
                          list?.notificationType === "ANNOUNCEMENT" ||
                          list?.notificationType === "QUERY"
                            ? ""
                            : "",
                        color: list?.isRead === false ? "#737373" : "black",
                        width: "80%",
                      }}
                    >
                      {list?.notificationType === "QUERY" ? (
                        <Box sx={{ display: "flex" }}>
                          <Box>{list?.notificationType}</Box>
                          <Box sx={{ ml: 0.5 }}>
                            ({list?.notificationInfo?.data?.queryCode})
                          </Box>
                        </Box>
                      ) : (
                        <>{list?.notificationType}</>
                      )}
                    </Box>

                    {list?.isRead === true ? (
                      <Box
                        sx={{
                          fontSize: 11,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EmailIcon
                          sx={{ color: "black", height: 15, width: 15 }}
                          fontSize="small"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          fontSize: 11,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DraftsIcon
                          sx={{ color: "grey", height: 15, width: 15 }}
                          fontSize="small"
                        />
                      </Box>
                    )}
                  </Box>
                  {list?.notificationType === "ANNOUNCEMENT" ||
                  list?.notificationType === "QUERY" ||
                  list?.notificationType === "SLA_LEVEL_1" ||
                  list?.notificationType === "SLA_LEVEL_2" ? (
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{}}> </Box>
                      <Typography
                        sx={{
                          fontSize: 11,
                          color: list?.isRead === false ? "#737373" : "black",
                          display: "flex",
                          pl: 3,
                          pt: 1,
                          pb: 1,
                          // justifyContent: "center",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        {list?.notificationInfo?.data?.projectCode && (
                          <Box
                            sx={{
                              flex: 0.5,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color:
                                list?.isRead === false ? "#737373" : "black",
                            }}
                          >
                            <b>PC: </b>
                            <span
                              title={list?.notificationInfo?.data?.projectCode}
                            >
                              {list?.notificationInfo?.data?.projectCode}
                            </span>
                          </Box>
                        )}
                        {list?.notificationInfo?.data?.jobCode && (
                          <Box
                            sx={{
                              flex: 0.5,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color:
                                list?.isRead === false ? "#737373" : "black",
                            }}
                          >
                            <b>JC: </b>
                            <span title={list?.notificationInfo?.data?.jobCode}>
                              {list?.notificationInfo?.data?.jobCode}
                            </span>
                          </Box>
                        )}
                      </Typography>
                    </Box>
                  ) : null}
                  {/* {list?.notificationType === "ANNOUNCEMENT" ||
                    list?.notificationType === "QUERY" ? (
                    <Box sx={{ textAlign: "center", p: 0, m: 0 }}>
                      {" "}
                      <hr
                        style={{
                          borderWidth: 0,
                          height: 0.2,
                          backgroundColor:
                            list?.notificationInfo?.data?.criticality === "low"
                              ? "#339af0"
                              : list?.notificationInfo?.data?.criticality ===
                                "medium"
                                ? "#ffc078"
                                : list?.notificationInfo?.data?.criticality ===
                                  "high"
                                  ? "red"
                                  : "#358c4e",
                        }}
                      />
                    </Box>
                  ) : null} */}
                  {/* {list?.notificationType === "SLA_LEVEL_1" ||
                    list?.notificationType === "SLA_LEVEL_2" ? (
                    <Box sx={{ textAlign: "center", p: 0, m: 0 }}>
                      {" "}
                      <hr
                        style={{
                          borderWidth: 0,
                          height: 0.2,
                          backgroundColor: "red",
                        }}
                      />
                    </Box>
                  ) : null} */}

                  <Box
                    sx={{
                      fontSize: 12,
                      pl: 3,
                      mb: 1,
                      color: list?.isRead === false ? "grey" : "black",
                      mt:
                        list?.notificationType === "ANNOUNCEMENT" ||
                        list?.notificationType === "QUERY"
                          ? 0
                          : 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* {list?.notificationType === "ANNOUNCEMENT" ||
                      list?.notificationType === "QUERY" ? (
                      <b style={{ fontWeight: 800 }}>Title</b>
                    ) : null} */}
                    {list?.notificationType === "SLA_LEVEL_1" ||
                    list?.notificationType === "SLA_LEVEL_2" ? null : (
                      <Box
                        sx={{
                          pb: 2,
                          fontSize: 12,
                          fontWeight: 600,
                          // color: list?.isRead === false ? "#76a876" : "#358c4e",
                          width: "100%",
                        }}
                      >
                        {" "}
                        {list?.notificationInfo?.notification?.title} by{" "}
                        {list?.created_user}
                      </Box>
                    )}
                    {list?.message === "List of tasks has been assigned." ? (
                      <Box sx={{}}>
                        Number of tasks :{" "}
                        {list?.notificationInfo?.data?.taskId?.length}
                      </Box>
                    ) : list?.notificationType === "TASK_REVERT_REQUEST" ? (
                      <Box sx={{}}>
                        Task ID : {list?.notificationInfo?.data?.taskId}
                      </Box>
                    ) : null}
                    {/* list?.notificationType === "ANNOUNCEMENT" ?
                        <p dangerouslySetInnerHTML={{ __html: list?.message }} />
                      : list?.message}*/}
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 1,
                    right: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon
                    sx={{ height: 15, width: 15, color: "#358c4e", mr: 0.5 }}
                  />
                  <Box sx={{ fontSize: 11, color: "#358c4e" }}>
                    {/* {moment(list?.createdOn).startOf().fromNow()} */}
                    {moment(list?.createdOn).format("MMMM Do YYYY, h:mm a")}
                  </Box>
                </Box>
              </Card>
              <Divider />
            </>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mb: 2,
            }}
          >
            {!loader ? (
              notificationList?.pageInfo?.hasNext ? (
                <Button
                  onClick={seeMore}
                  sx={{
                    fontSize: 14,
                    color: "black",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                  variant="text"
                >
                  <ExpandMoreIcon /> See More
                </Button>
              ) : null
            ) : (
              <CircularProgress
                sx={{ mr: 5, mt: 0, mb: 1 }}
                size={25}
                color="success"
              />
            )}
          </Box>
        </Box>
      </List>
    </Box>
  );
  return (
    <div>
      <React.Fragment>
        <Button sx={{ mr: 2 }} onClick={() => notificationOpen()}>
          {/* {console.log(notificationList, "ygyug")} */}
          {/* {badge || push === 1 ? ( */}
          <Box
            sx={{
              position: "relative",
              display: "grid",
              placeItems: "center",
            }}
          >
            {/* <img alt="" style={{ height: 35, width: 35 }} src={notification} /> */}
            <Tooltip title="Notification">
              <NotificationsIcon fontSize="medium" sx={{ color: "black" }} />
            </Tooltip>
            {unreadCount ? (
              <Box
                sx={{
                  position: "absolute",
                  height: 23,
                  width: 23,
                  borderRadius: "50%",
                  background: "#fa5252",
                  left: 15,
                  top: -7,
                  fontSize: 11,
                  color: "white",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                {unreadCount}
              </Box>
            ) : null}
          </Box>
          {/* ) : (
            <Tooltip title="Notification">
              <NotificationsIcon fontSize="medium" sx={{ color: "black" }} />
            </Tooltip>
          )} */}
        </Button>
        <Drawer
          sx={{
            zIndex: 100000,
            // opacity:0.9 ,
          }}
          anchor={"right"}
          open={state}
          onClose={() => toggleDrawer(false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
