import { yupResolver } from "@hookform/resolvers/yup";
import { BorderColor, Done, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";

import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import Swal from "sweetalert2";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import {
  createTeamMasterAction,
  departmentMasterAction,
  getUserDataBydepartementAction,
} from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { createBranchMaster } from "../../service/masterService";
import {
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
} from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import ViewPopUp from "../../utils/viewPopUp";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { PopupMessage, checkBoxFilter } from "../../utils/UIUtil";
import PopupComponent from "../../components/commonComponents/PopupComponent";
const schema = yup.object().shape({
  teamId: yup.string(),
  teamName: yup.string().required("Name Required"),
  // codeName: yup.string().required("Code Required"),
  shortName: yup.string().required("Short Name Required"),
  branchId: yup.string().required("Branch Required"),
  depId: yup.string().required("Department Required"),
  OwnerUserid: yup
    .array()
    .min(1, "Team Manager Required")
    .required("Team Manager Required"),
  tlUserid: yup.object().nullable().required("Team Lead Required"),
  description: yup.string().nullable(),
});
export default function Team() {
  const teamuserDefaultState = {
    teamOwner: [],
    tlUserid: "",
  };
  const [edit, setEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const { teamsaveData, globapOrganization, userProfile } = useSelector(
    (state) => state.presist
  );
  const navigate = useNavigate();
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [editpage, setEditPage] = React.useState(false);
  // const [isActive, setIsActive] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [employee, setEmployee] = React.useState([]);
  const [teamUsers, setTeamUsers] = React.useState(teamuserDefaultState);
  const { pathname } = useLocation();
  const [isActive, setIsActive] = React.useState(true);
  const {
    branchList,
    teamList,
    departmentList,
    teamAutoId,
    // teamcodeCheck,
    departmentUserList,
  } = useSelector((state) => state.master);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setEditPage(false);
      setEdit(false);
      navigate("/team");
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      reset();
      setEditPage(false);
      setEdit(false);
      navigate("/team");
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      createTeamMasterAction(
        "",
        "get",
        `/team/info/${
          globapOrganization?.orgId
        }/?pageSize=${itemPerPage}&pageNumber=${pageNumber}${
          e ? "&isActive=True" : "&isActive=False"
        }`
      )
    );
  };
  React.useEffect(() => {
    handleEditClick(teamsaveData, "no");
  }, []);
  React.useEffect(() => {
    handleEditClick(teamsaveData, "no");
  }, [teamAutoId]);
  React.useEffect(() => {
    return () => {
      dispatch(
        updatePresistStates({ name: "teamsaveData", value: getValues() })
      );
      // dispatch(
      //   updateMasterStates({
      //     name: "teamcodeCheck",
      //     value: "",
      //   })
      // );
    };
  }, []);
  React.useEffect(() => {
    if (pathname === "/createTeam") {
      setEditPage(true);
    } else {
      setEditPage(false);
      reset();
    }
  }, [pathname]);
  const changePageSize = (newPageSize) => {
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    // setConstructData([])
    dispatch(
      createTeamMasterAction(
        "",
        "get",
        `/team/info/${globapOrganization?.orgId}/?pageSize=${
          newPageSize.pageSize
        }&pageNumber=${newPageSize.page + 1}${
          isActive ? "&isActive=True" : "&isActive=False"
        }`
      )
    );
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          createTeamMasterAction(
            { teamid: row },
            "delete",
            `/team/info/${globapOrganization?.orgId}/`
          )
        );
        setSelectionState([]);
        handelActiveInactive(true);
      }
    });
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          departmentMasterAction(
            { teamid: [row?.teamId] },
            "put",
            `/team/info/${globapOrganization?.orgId}/`
          )
        );
        handelActiveInactive(false);
      }
    });
  };
  const handleEditClick = async (row, data) => {
    console.log(row, "jh");
    if (data === "edit") {
      setEditPage(true);
      navigate("/createTeam");
      setSelectedRow(row);
      setEdit(true);
      await dispatch(getUserDataBydepartementAction({ depId: row?.depId }));
    }
    setTeamUsers({
      ...teamUsers,
      tlUserid: row?.teamLeadOption,
      teamOwner: row?.userId,
    });
    setValue("branchName", row?.branchName);
    setValue("branchId", row?.branchId);
    setValue(
      "teamId",
      data === "edit" && row?.teamId ? row.teamId : teamAutoId?.id
    );
    setValue("depName", row?.depName);
    setValue("depId", row?.depId);
    setValue("OwnerUserid", row?.userId);
    setValue("tlUserid", row?.teamLeadOption || null);
    // setValue("codeName", row?.codeName);
    setValue("teamName", row?.teamName);
    setValue("shortName", row?.shortName);
    setValue("description", row?.description);
  };
  const handleView = async (row) => {
    console.log(row, "rowww");
    setEditPage(true);
    setSelectedRow(row);
    setView(true);
    await dispatch(getUserDataBydepartementAction({ depId: row?.depId }));
    setValue("branchName", row?.branchName);
    setValue("branchId", row?.branchId);
    setValue("depName", row?.depName);
    setValue("depId", row?.depId);
    setValue("tlUserid", row?.teamLeadOption || null);
    setValue("OwnerUserid", row?.userId);
    // setValue("codeName", row?.codeName);
    setValue("teamName", row?.teamName);
    setValue("shortName", row?.shortName);
    setValue("description", row?.description);
  };
  const handelClose = () => {
    // setEditPage(false)
    // navigate('/team')
    dispatch(updatePresistStates({ name: "teamsaveData", value: {} }));
    dispatch(updateMasterStates({ name: "departmentUserList", value: [] }));

    // dispatch(
    //   updateMasterStates({
    //     name: "teamcodeCheck",
    //     value: "",
    //   })
    // );
    setTeamUsers(teamuserDefaultState);
    setSelectedFile([]);
    setEdit(false);
    setValue("branchName", "");
    setValue("depName", "");
    setValue("branchid", "");
    setValue("depid", "");
    reset();
  };

  const dispatch = useDispatch();
  const columns = [
    {
      field: "teamName",
      headerName: "Name",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "teamOwner",
      headerName: "Team Manager",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "teamLead",
      headerName: "Team Lead",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "branchName",
      headerName: "Branch Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "depName",
      headerName: "Department Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "codeName",
    //   headerName: "TEAM CODE",
    //   width: 200,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "shortName",
      headerName: "Short Name",
      width: 190,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 300,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        allowedPages().TeamManagement.teamList.edit && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
              console.log(params.row, "parammms");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        <GridActionsCellItem
          sx={{ p: 0 }}
          // icon={
          //   <Tooltip title="View" placement="right">
          //     <RemoveRedEyeIcon sx={{ color: "#358c4e" }} />
          //   </Tooltip>
          // }
          // label="view"
          // onClick={() => {
          //   handleView(params.row);
          // }}
          icon={
            <Tooltip title="View" placement="right">
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <ViewPopUp
                  value={true}
                  team={params.row}
                  // teamMembers={teamList?.teamMembersInfo}
                />
              </Box>
            </Tooltip>
          }
          color="primary"
        />,
        !isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),
        // <GridActionsCellItem
        //   icon={<Delete />}
        //   label="Delete"
        //   onClick={() => {
        //     handleDeleteClick(params.row);
        //   }}
        //   color="error"
        // />,
      ],
    },
    // { field: "date", headerName: "CREATED DATE", width: 200 },
    // { field: "status", headerName: "STATUS", width: 300, flex: 1 },
  ];

  const form = useForm({
    defaultValues: {
      teamId: "",
      teamName: "",
      // codeName: "",
      shortName: "",
      description: "",
      // branchName: "",
      // depName: "",
      orgId: "ORG00001",
      branchId: "",
      OwnerUserid: [],
      tlUserid: null,
      depId: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;
  console.log(errors, "errors");
  console.log(getValues(), "getValues");
  const constructOrderData = (arr) => {
    const result =
      arr &&
      arr.length > 0 &&
      arr.map((val, i) => ({
        ...val.teamInfo,
        createUserInfo: val.createUserInfo,
        updatedUserInfo: val.updatedUserInfo,
        userId: val?.teamOwnerInfo,
        depName: val.departmentInfo?.depName,
        depId: val.departmentInfo?.depId,
        branchId: val.branchInfo?.branchId,
        branchName: val.branchInfo?.branchName,
        teamLeadOption: val?.teamLeadInfo[0] || [],
        teamOwner: val?.teamOwnerInfo?.map(
          (el) => el.firstname + " " + el.lastname
        ),
        teamLead: val?.teamLeadInfo?.map(
          (el) => el.firstname + " " + el.lastname
        ),
        teamMembers: val?.teamMembersInfo,
      }));

    return result;
  };
  React.useEffect(() => {
    // dispatch(getPatientPharmacyNotPaidOrderAction());
    if (teamList?.teamInfo?.length > 0) {
      const employeedata = constructOrderData(teamList?.teamInfo);
      setEmployee(employeedata);
      if (teamList?.pageInfo?.num_pages) {
        setTotalPage(teamList?.pageInfo?.total_items);
      }
    } else {
      setEmployee([]);
    }
  }, [teamList]);

  React.useEffect(() => {
    dispatch(
      createBranchMaster(
        "",
        "get",
        `/branch/info/${globapOrganization?.orgId}/?isActive=True`
      )
    ).then((response) => {
      if (response?.data && response.data?.data?.length > 0) {
        dispatch(
          updateMasterStates({
            name: "branchList",
            value: response.data?.data,
          })
        );
      } else {
        dispatch(updateMasterStates({ name: "branchList", value: [] }));
      }
    });
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    dispatch(
      createTeamMasterAction(
        "",
        "get",
        `/team/info/${globapOrganization?.orgId}/?pageSize=1000&pageNumber=1${
          isActive ? "&isActive=True" : "&isActive=False"
        }`
      )
    );
  }, [globapOrganization]);
  const selectFliterFun = (mainarr, arr) => {
    console.log(arr);
    if (arr?.length > 0) {
      const res = mainarr?.filter(
        (val) => !arr.find((al) => al?.empId === val?.empId)
      );
      return res;
    } else {
      const res = mainarr?.filter((val) => arr?.empId !== val?.empId);
      return res;
    }
  };
  const onSubmit = async (data) => {
    // delete data.teamCode;
    console.log(data, "data");
    delete data.teamId;
    data.orgId = globapOrganization?.orgId;
    data.OwnerUserid = data.OwnerUserid.map((val) => val.id);
    data.tlUserid = [data.tlUserid.id];
    // if (!teamcodeCheck) {
    if (edit) {
      const editData = {
        id: selectedRow.id,
        teamId: selectedRow.teamId,
        teamName: data?.teamName,
        shortName: data?.shortName,
        orgId: data.orgId,
        // codeName: data?.codeName,
        description: data?.description,
        remarks: selectedRow.remarks,
        createdBy: selectedRow.createdBy,
        updatedBy: userProfile?.useAccess?.userName,
        createdOn: selectedRow.createdOn,
        updatedOn: selectedRow.updatedOn,
        branchId: data.branchId,
        OwnerUserid: data.OwnerUserid,
        tlUserid: data.tlUserid,
        depId: data.depId,
      };
      // setEditPage(false)
      await dispatch(
        createTeamMasterAction(
          editData,
          "patch",
          `/team/info/${globapOrganization?.orgId}/`
        )
      );
      await dispatch(
        createTeamMasterAction(
          "",
          "get",
          `/team/info/${globapOrganization?.orgId}/?pageSize=1000&pageNumber=1${
            isActive ? "&isActive=True" : "&isActive=False"
          }`
        )
      );
      await setValue("branchName", "");
      await setValue("depName", "");
      reset();
      dispatch(updatePresistStates({ name: "teamsaveData", value: {} }));

      setEdit(false);
    } else {
      await dispatch(
        createTeamMasterAction(
          data,
          "post",
          `/team/info/${globapOrganization?.orgId}/`
        )
      );
      await dispatch(
        createTeamMasterAction(
          "",
          "get",
          `/team/info/${globapOrganization?.orgId}/?pageSize=1000&pageNumber=1${
            isActive ? "&isActive=True" : "&isActive=False"
          }`
        )
      );
      await setValue("branchName", "");
      await setValue("depName", "");
      reset();
      dispatch(updatePresistStates({ name: "teamsaveData", value: {} }));
    }
    // }
  };

  //   setValue("codeName", e.target.value.toUpperCase());

  //   if (e.target.value.length > 2) {
  //     try {
  //       // dispatch(loading(true))
  //       await dispatch(
  //         codeCheckService(
  //           { codeName: e.target.value },
  //           `/team/code/name/${globapOrganization?.orgId}/`,
  //           "teamcodeCheck"
  //         )
  //       ).then((response) => {
  //         // dispatch(loading(false))

  //         if (response.data) {
  //           dispatch(
  //             updateMasterStates({
  //               name: "teamcodeCheck",
  //               value: response.data,
  //             })
  //           );
  //         } else {
  //           dispatch(
  //             updateMasterStates({
  //               name: "teamcodeCheck",
  //               value: "",
  //             })
  //           );
  //         }
  //       });
  //     } catch (error) {
  //       // dispatch(loading(false))
  //     }
  //   }
  // };
  const handelDepartment = async (e) => {
    await dispatch(getUserDataBydepartementAction({ depId: e.target.value }));
  };
  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formContainerStyle}>
          <Box sx={{ width: "100%" }}>
            <Card sx={formContainerCardStyle}>
              {!editpage ? (
                <Box
                  sx={{
                    pt: 5,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: 7,
                      pr: 5,
                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h4 style={allpageFontStyle.title}>LIST OF TEAM</h4>
                    {allowedPages().TeamManagement.creatTeam.create && (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          setEditPage(true);
                          reset();
                          navigate("/createTeam");
                        }}
                        sx={{
                          ...allpageFontStyle.addButtonFont.buttonHeight,

                          ...allpageFontStyle.buttonFontStyle1,
                          fontWeight: "bold",
                          // backgroundColor: "#43a5fc",
                          borderRadius: 5,
                          color: "#358c4e",
                          p: 0,
                          px: 2,
                          py: 0,
                          ":hover": {
                            backgroundColor: "#358c4e",
                            color: "white",
                            borderColor: "#358c4e",
                          },
                          textTransform: "capitalize",
                          borderColor: "#358c4e",
                        }}
                      >
                        <AddIcon
                          sx={{
                            mr: 0.5,
                            ...allpageFontStyle.addButtonFont.font,
                            p: 0,
                          }}
                        />
                        <Typography
                          sx={{ ...allpageFontStyle.addButtonFont.font }}
                        >
                          Create Team
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      mb: 5,
                      width: "100%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <DataGride
                      columns={columns}
                      selectionIs={selectionIs}
                      customHeight={450}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      pageSize={itemPerPage}
                      page={pageNumber}
                      pageName={"project"}
                      onPageSizeChange={changePageSize}
                      rowCount={totalPage}
                      getRowId={(data) => data?.teamId}
                      paginationMode={true}
                      deleteIcon={isActive ? true : false}
                      tableData={employee && employee.length > 0 && employee}
                      hideDeletion={
                        !allowedPages().TeamManagement.teamList.delete
                      }
                      InActiveFilterCheckBox={() =>
                        checkBoxFilter(
                          isActive,
                          setIsActive,
                          "Active",
                          "Inactive",
                          handelActiveInactive
                        )
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // px: 5,
                    }}
                  >
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Edit
                          </Typography>
                        </Box>
                      ) : view ? (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            View
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Add{" "}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.editType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.listType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={9}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{ pt: 2, display: "flex", justifyContent: "center" }}
                    >
                      <Grid container spacing={2} sx={{ width: "70%" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="teamName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                label="Team Name *"
                                size="small"
                                helperText={
                                  errors.teamName && !value
                                    ? errors.teamName.message
                                    : null
                                }
                                error={!value ? errors.teamName?.message : null}
                                sx={{
                                  width: "85%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                disabled={view}
                                onChange={(e) => {
                                  onChange(e);
                                  trigger("teamName");
                                }}
                                onBlur={() =>
                                  !value ? trigger("teamName") : null
                                }
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Team Name" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="shortName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                label="Team Short Name *"
                                size="small"
                                helperText={
                                  errors?.shortName
                                    ? errors.shortName.message
                                    : null
                                }
                                error={errors?.shortName?.message}
                                sx={{
                                  width: "85%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                disabled={view}
                                onChange={(e) => {
                                  trigger("shortName");
                                  onChange(e);
                                }}
                                onBlur={() =>
                                  !value ? trigger("shortName") : null
                                }
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "Team Short Name *",
                                }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Controller
                            control={control}
                            name="branchId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <FormControl
                                  error={!value ? errors.branchId : null}
                                  size="small"
                                  fullWidth
                                >
                                  <InputLabel shrink>Branch*</InputLabel>
                                  <Select
                                    sx={{
                                      width: "100%",
                                      borderRadius: 3,
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                    disabled={view}
                                    notched
                                    MenuProps={{
                                      PaperProps: { sx: { maxHeight: 150 } },
                                    }}
                                    label="Branch*"
                                    onChange={(e) => {
                                      onChange(e);
                                      trigger("branchId");
                                    }}
                                    onBlur={() =>
                                      !value ? trigger("branchId") : null
                                    }
                                    value={value}
                                    inputRef={ref}
                                    inputProps={{
                                      "aria-label": "Project Code",
                                    }}
                                  >
                                    {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Branch
                                    </MenuItem> */}
                                    {branchList.map((val) => (
                                      <MenuItem
                                        sx={{
                                          borderBottom: "1px solid white",
                                          fontFamily: "Lato",
                                          fontSize: 13,
                                        }}
                                        value={val.BranchInfo?.branchId}
                                      >
                                        {val.BranchInfo?.branchName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText sx={{ color: "red" }}>
                                    {errors.branchId && !value
                                      ? errors?.branchId?.message
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Controller
                            control={control}
                            name="depId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <FormControl
                                  error={!value ? errors.depId : null}
                                  size="small"
                                  fullWidth
                                >
                                  <InputLabel shrink>Department*</InputLabel>
                                  <Select
                                    notched
                                    label="Department*"
                                    sx={{
                                      width: "100%",
                                      borderRadius: 3,
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                    disabled={view}
                                    MenuProps={{
                                      PaperProps: { sx: { maxHeight: 150 } },
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      handelDepartment(e);
                                      trigger("depId");
                                    }}
                                    onBlur={() =>
                                      !value ? trigger("depId") : null
                                    }
                                    value={value}
                                    inputRef={ref}
                                    inputProps={{
                                      "aria-label": "Project Code",
                                    }}
                                  >
                                    {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Department
                                    </MenuItem> */}
                                    {departmentList.map((val) => (
                                      <MenuItem
                                        sx={{
                                          borderBottom: "1px solid white",
                                          fontFamily: "Lato",
                                          fontSize: 13,
                                        }}
                                        value={val.departmentInfo?.depId}
                                      >
                                        {val.departmentInfo?.depName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText sx={{ color: "red" }}>
                                    {errors.depId
                                      ? errors.depId?.message
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Controller
                            control={control}
                            name={`OwnerUserid`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <FormControl
                                  error={
                                    errors?.OwnerUserid
                                      ? errors.OwnerUserid?.message
                                      : false
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{ pt: 0.5 }}
                                >
                                  <SelectComponent
                                    DataOptions={
                                      selectFliterFun(
                                        departmentUserList,
                                        teamUsers.tlUserid
                                      ) || []
                                    }
                                    labelName={(option) =>
                                      option?.firstname + " " + option?.lastname
                                    }
                                    errors={
                                      errors?.OwnerUserid
                                        ? errors.OwnerUserid?.message
                                        : false
                                    }
                                    disabled={view}
                                    defaultValue={value}
                                    multiple={true}
                                    label={"Team Manager*"}
                                    filterSelectedOptions={true}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    value={value}
                                    autoStyle={{
                                      "& fieldset": { borderRadius: 3 },
                                    }}
                                    renderInput={(parmas) => (
                                      <TextField
                                        {...parmas}
                                        variant={"outlined"}
                                        label={"Team Manager*"}
                                        helperText={
                                          errors?.OwnerUserid
                                            ? errors.OwnerUserid?.message
                                            : false
                                        }
                                        error={
                                          errors?.OwnerUserid
                                            ? errors.OwnerUserid?.message
                                            : false
                                        }
                                        // InputProps={formFieldStyle.InputProps}
                                        InputLabelProps={
                                          formFieldStyle.InputLabelProps
                                        }
                                      />
                                    )}
                                    sx={{ pr: 1, width: "100%" }}
                                    handelChange={(e) => {
                                      // trigger("OwnerUserid");
                                      setTeamUsers({
                                        ...teamUsers,
                                        teamOwner: e,
                                      });
                                      onChange(e);
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Controller
                            control={control}
                            name={`tlUserid`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <FormControl
                                  error={
                                    errors?.tlUserid
                                      ? errors.tlUserid?.message
                                      : false
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{ pt: 0.5 }}
                                >
                                  <SelectComponent
                                    DataOptions={
                                      selectFliterFun(
                                        departmentUserList,
                                        teamUsers.teamOwner
                                      ) || []
                                    }
                                    labelName={(option) =>
                                      option?.firstname + " " + option?.lastname
                                    }
                                    errors={
                                      errors?.tlUserid
                                        ? errors.tlUserid?.message
                                        : false
                                    }
                                    disabled={view}
                                    defaultValue={value}
                                    // multiple={true}
                                    label={"Team Lead*"}
                                    filterSelectedOptions={true}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    value={value}
                                    autoStyle={{
                                      "& fieldset": { borderRadius: 3 },
                                    }}
                                    renderInput={(parmas) => (
                                      <TextField
                                        {...parmas}
                                        variant={"outlined"}
                                        label={"Team Lead*"}
                                        helperText={
                                          errors?.tlUserid
                                            ? errors.tlUserid?.message
                                            : false
                                        }
                                        error={
                                          errors?.tlUserid
                                            ? errors.tlUserid?.message
                                            : false
                                        }
                                        // InputProps={formFieldStyle.InputProps}
                                        InputLabelProps={
                                          formFieldStyle.InputLabelProps
                                        }
                                      />
                                    )}
                                    sx={{ pr: 1, width: "100%" }}
                                    handelChange={(e) => {
                                      // trigger("OwnerUserid");

                                      setTeamUsers({
                                        ...teamUsers,
                                        tlUserid: e,
                                      });
                                      console.log(e, "e");
                                      setValue("tlUserid", e?.empId);
                                      onChange(e);
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="description"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "85%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                disabled={view}
                                label="Description"
                                size="small"
                                helperText={
                                  <Box sx={{ color: "gray", fontSize: 10 }}>
                                    {value === ""
                                      ? "Max 500 characters"
                                      : 500 -
                                        value.length +
                                        " " +
                                        "Characters remaining"}
                                  </Box>
                                }
                                multiline
                                rows={3}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "description",
                                  maxLength: 500,
                                }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      sx={{
                        color: "white",
                        width: 90,
                        m: 5,
                        backgroundColor: "#8bb679",
                        borderRadius: 5,
                      }}
                    >
                      ADD
                    </Button>
                  </Box> */}
                    </Box>
                  </Grid>

                  {edit ? (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        type="submit"
                        // onClick={() => navigate('/team')}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#258d4d",
                          ":hover": { backgroundColor: "#258d4d" },
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        UPDATE
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      {allowedPages().TeamManagement.creatTeam.create ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          // onClick={onSubmit}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#258d4d",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  )}
                  {view ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // type="submit"
                        onClick={() => {
                          setEditPage(false);
                          setEdit(false);
                          setView(false);
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#3378e8",

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        List
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              )}

              {/* <Box sx={{ pt: 4, display: "flex" }}>
                {" "}
                <Divider
                  style={{ backgroundColor: "black" }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              </Box> */}
            </Card>
          </Box>
        </Box>
      </form>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
