import { saveAs } from 'file-saver';
export const fileAttachmentDownload = async (file, fileType, fileName) => {
  try {
    if (file) {
      const blob = new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      saveAs(blob, fileName);
    }
  } catch (error) {
    // dispatch(addOverlayLoading(false));
  }
};