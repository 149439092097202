import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import SelectComponent from "../../components/commonComponents/SelectComponent";
// import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { grey } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
    createandEditRole,
    getProjectDepartmentandTeamAndUser,
    getRoleMappingDetails,
    roleMappingToUsers,
} from "../../service/roleService";
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { TriggerAlert } from "../../utils/UIUtil";
import { mappeduserConsrtucdata } from "./Mappingdata";

const schema = yup.object().shape({
    roleName: yup.string().required("Name Required"),
    // team: yup.array().required("Name Required"),
    user: yup.array().required("Name Required"),
    // dep: yup.array().required("Name Required"),
    // project: yup.array().required("Role Description Required"),
});

function RoleAndUserMapping() {
    const initialState = {
        // projectList: [],
        userList: [],
        // teamList: [],
        roleList: [],
        // departmentList: [],
    };
    const { globapOrganization } = useSelector((state) => state.presist);

    // const dispatch = useDispatch();
    const location = useLocation();
    const nav = useNavigate();
    const { state } = location;
    // const [tabselection, setTabselection] = useState(state?.type || "dep")
    const [edit, setEdit] = useState(false);
    // const [roleArray, setRoleArray] = useState([])
    const [render, setrender] = useState(false);
    const [selectOptionData, setselectOptionData] = useState(initialState);
    const form = useForm({
        defaultValues: {
            // team: [],
            roleName: "",
            // project: [],
            // scopeOfProject: [],
            user: [],
            // dep: [],
        },
        resolver: yupResolver(schema),
    });
    const {
        handleSubmit,
        setValue,
        control,
        // watch,
        getValues,
        reset,
        // trigger,
        formState: { errors },
    } = form;
    const handelclose = () => {
        setEdit(false);
        reset();
        nav("/Role/userMapping");
    };
    const handelSelect = async (e, type) => {
        if (type === "Role") {
            if (e) {
                setrender(!render)
                setValue("roleName", e);
                try {
                    const res = await getRoleMappingDetails("post", { groupId: e?.id, orgId: globapOrganization?.orgId })
                    if (res) {
                        const mappedUsers = res?.data?.data
                        const userList = mappeduserConsrtucdata(mappedUsers)
                        setValue("user", userList || []);
                    }
                } catch (error) {

                }
                // if (tabselection === "user") {
                //     setValue("user", state?.user)
                // }
            } else {
                setrender(!render)
                setValue("roleName", "");
                setValue("user", []);
            }
        }
        // else if (type === "team") {
        //     if (e) {
        //         setValue("team", e);
        //     } else {
        //         setValue("team", []);
        //     }
        // }
        //  else if (type === "dep") {
        //     if (e) {
        //         setValue("dep", e);
        //     } else {
        //         setValue("dep", []);
        //     }
        // }
        else if (type === "User") {
            if (e) {
                setValue("user", e);
            } else {
                setValue("user", []);
            }
        }
    };

    const onSubmit = async (data) => {
        try {
            const payload = {
                groupId: data?.roleName?.id,
                users: data?.user?.map((val) => val.id) || [],
            };
            const res = await roleMappingToUsers("post", payload, "/auth/user_group/");
            if (res) {
                TriggerAlert("success", res.data.message)
                reset()
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        const getDataAPI = async () => {
            try {
                reset()
                const res1 = await createandEditRole("get", {});
                const res = await getProjectDepartmentandTeamAndUser(globapOrganization?.orgId);
                if (res1 && res) {
                    // setValue("roleName", "");
                    // setValue("user", []);
                    // setRoleArray(res1?.data?.data)
                    const mappingListData = res?.data?.data;
                    const RoleData = res1?.data?.data;
                    setselectOptionData({
                        ...selectOptionData,
                        // projectList: mappingListData.projectInfo,
                        // teamList: mappingListData.teamInfo,
                        // departmentList: mappingListData.departmentInfo,
                        userList: mappingListData.userInfo,
                        roleList: RoleData,
                    });
                } else {
                    setselectOptionData(initialState);
                }
            } catch (error) { }
        };
        getDataAPI();
    }, [globapOrganization]);
    useEffect(() => {
        const setEditData = () => {
            if (state) {
                setValue("roleName", state?.name);
                // if (state.type === "dep") {
                //     setValue("dep", state?.dep)
                // }
                // if (state.type === "team") {
                //     setValue("team", state?.team)
                // }
                // if (state.type === "project") {
                //     setValue("project", state?.pro)
                // }
                setValue("user", state?.user);

                nav("/Role/userMapping");
            } else {
                reset();
            }
        };
        setEditData();
        return () => {
            // setselectOptionData(initialState)
        };
    }, []);

    return (
        <Box flex={1} sx={{ background: grey[100], minWidth:800 }} p={2}>
            <FormProvider {...form}>
                <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={formContainerStyle}>
                        <Box sx={{ width: '50%' }}>
                            <Card sx={formContainerCardStyle} >
                                <Grid container mt={2} spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ p: 1 }}>
                                        <Card
                                            sx={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                pt: 3,
                                                boxShadow: 'none'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box sx={{ width: "85%" }}>
                                                    <Controller
                                                        control={control}
                                                        name="roleName"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <SelectComponent
                                                                DataOptions={selectOptionData?.roleList}
                                                                labelName={(option) => option.name}
                                                                value={value}
                                                                // multiple={true}

                                                                // filterSelectedOptions={true}
                                                                // isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                sx={{ pr: 1 }}
                                                                label={"Role"}
                                                                handelChange={(e) => handelSelect(e, "Role")}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                                {/* {tabselection === "project" &&
                                        <Grid item xs={6} md={6} lg={6}>
                                            <Controller
                                                control={control}
                                                name="project"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <SelectComponent
                                                        DataOptions={selectOptionData?.projectList}
                                                        labelName={(option) => option.projectCode}
                                                        value={value}
                                                        multiple={true}
                                                        filterSelectedOptions={true}
                                                        isOptionEqualToValue={(option, value) => option.projectCode === value.projectCode}
                                                        sx={{ pr: 1 }}
                                                        label={"Select Project"}
                                                        handelChange={(e) => handelSelect(e, "Project")}
                                                    />
                                                )}
                                            />
                                        </Grid>}
                                    {tabselection === "team" &&
                                        <Grid item xs={6} md={6} lg={6}>
                                            <Controller
                                                control={control}
                                                name="team"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <SelectComponent
                                                        DataOptions={selectOptionData?.teamList}
                                                        labelName={(option) => option.teamName}
                                                        value={value}
                                                        multiple={true}
                                                        filterSelectedOptions={true}
                                                        isOptionEqualToValue={(option, value) => option.teamId === value.teamId}
                                                        sx={{ pr: 1 }}
                                                        label={"Select Team"}
                                                        handelChange={(e) => handelSelect(e, "team")}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {tabselection === "dep" &&
                                        <Grid item xs={6} md={6} lg={6}>
                                            <Controller
                                                control={control}
                                                name="dep"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <SelectComponent
                                                        DataOptions={selectOptionData?.departmentList}
                                                        labelName={(option) => option.depName}
                                                        value={value}
                                                        sx={{ pr: 1 }}
                                                        multiple={true}
                                                        filterSelectedOptions={true}
                                                        isOptionEqualToValue={(option, value) => option.depId === value.depId}
                                                        label={"Select department"}
                                                        handelChange={(e) => handelSelect(e, "dep")}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    } */}

                                                <Box sx={{ width: "85%" }}>
                                                    <Controller
                                                        control={control}
                                                        name="user"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <SelectComponent
                                                                DataOptions={selectOptionData?.userList}
                                                                labelName={(option) =>
                                                                    option.firstname + " " + option.lastname
                                                                }
                                                                value={value}
                                                                multiple={true}
                                                                filterSelectedOptions={true}
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option.username === value.username
                                                                }
                                                                sx={{ pr: 1 }}
                                                                label={"User"}
                                                                handelChange={(e) => handelSelect(e, "User")}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "50%",
                                                        gap: 2,
                                                        pt: 4,
                                                        pb: 3,
                                                    }}
                                                >
                                                    <Box>
                                                        <Button
                                                            sx={{ borderRadius: 5, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }}
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={() => onSubmit(getValues())}
                                                            disabled={!getValues("roleName")?.id}
                                                        >
                                                            {"Submit"}
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            sx={{ borderRadius: 5, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }}
                                                            variant="contained"
                                                            color="error"
                                                            onClick={handelclose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                    {/* <Grid></Grid> */}
                                </Grid>
                            </Card></Box></Box>
                </form>
            </FormProvider>
        </Box>
    );
}

export default RoleAndUserMapping;
