import { Box } from "@mui/material";
import { secondsToHMS } from "./UIUtil";

export const col = [
  // {
  //   field: "taskId",
  //   headerName: "Task Id",
  //   width: 150,
  //   align: "left",
  //   headerAlign: "left",
  // },
  {
    field: "taskType",
    headerName: "Task Type",
    width: 100,
    // minWidth: 100,
    // flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "category",
    headerName: "Category",
    width: 100,
    // minWidth: 100,
    // flex: 1,
    align: "left",
    headerAlign: "left",
  },

  {
    field: "jobCode",
    headerName: "Job Id",
    width: 100,
    // minWidth: 100,
    // flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "remarks",
    headerName: "Remarks",
    // width: 150,
    Width: 150,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box sx={{ overflowWrap: "break-word", fontFamily: "Lato" }}>
        {params?.row?.remarks}
      </Box>
    ),
  },
  {
    field: "assignedUsername",
    headerName: "Assigned To",
    width: 150,
    // minWidth: 150,
    // flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "productionHours",
    headerName: "Est Hours",
    type: "text",
    width: 150,
    // minWidth: 150,
    // flex: 1,
    valueGetter: (params) => secondsToHMS(Number(params.row.productionHours)),
    // editable: true,
    // preProcessEditCellProps: (params) => {
    //   handelEditFun(params)
    //   // const isPaidProps = params.otherFieldsProps.isPaid;
    //   // const hasError = isPaidProps.value && !params.props.value;
    //   // return { ...params.props, error: hasError };
    // },
  },

  {
    field: "state",
    headerName: "Status",
    width: 150,
    // minWidth: 150,
    // flex: 1,
    align: "left",
    headerAlign: "left",
  },
  // {
  //   field: "actions",
  //   headerName: "Action",
  //   type: "actions",
  //   // flex: 1,
  //   // minWidth: 70,
  //   align: "left",
  //   headerAlign: "left",
  //   width: 100,
  //   getActions: (params) => [
  //     <GridActionsCellItem
  //       icon={
  //         <Tooltip title="Edit" placement="right">
  //           <Edit />
  //         </Tooltip>
  //       }
  //       label="Delete"
  //       onClick={() => {
  //         handelEditStart(params)
  //       }}
  //       color="primary"
  //     />,
  //   ],
  // },
];
export const chartData = [
  {
    name: "Nov",
    time: 4000,
    projects: 2400,
    users: 2400,
  },
  {
    name: "Dec",
    time: 3000,
    projects: 1398,
    users: 2210,
  },
  {
    name: "Jan",
    time: 2000,
    projects: 9800,
    users: 2290,
  },
  {
    name: "Feb",
    time: 2780,
    projects: 3908,
    users: 2000,
  },
  {
    name: "Mar",
    time: 1890,
    projects: 4800,
    users: 2181,
  },
  {
    name: "Apr",
    time: 2390,
    projects: 3800,
    users: 2500,
  },
  {
    name: "May",
    time: 3490,
    projects: 4300,
    users: 2100,
  },
];
export const demoData = [];
export const roleData = [
  "OrgService.change_organization",
  "ProjectService.delete_projectteam",
  "ProjectService.delete_projects",
  "TeamService.view_team",
  "GeneralTaskService.change_generaltask",
  "TeamService.delete_team",
  "auth.change_permission",
  "GeneralTaskService.view_generaltask",
  "JobService.delete_jobtaskhistory",
  "BranchService.view_branches",
  "TeamService.view_teamusermapping",
  "Users.change_userprofile",
  "contenttypes.view_contenttype",
  "GeneralTaskService.delete_generaltask",
  "TeamService.add_teamusermapping",
  "JobService.add_jobtask",
  "JobService.change_jobtask",
  "ProjectService.add_projectteam",
  "DesignationService.change_designation",
  "ProjectService.change_task",
  "Users.change_logininfo",
  "NotificationApp.delete_fcminfo",
  "Users.change_userroles",
  "admin.delete_logentry",
  "auth.change_group",
  "Users.add_users",
  "TeamService.change_teamusermapping",
  "Users.add_userroles",
  "auth.view_group",
  "DepartmentService.view_department",
  "GradeService.add_grade",
  "Users.add_roles",
  "DepartmentService.delete_department",
  "JobService.view_jobs",
  "Users.delete_users",
  "GradeService.delete_grade",
  "auth.add_group",
  "ProjectService.view_task",
  "DepartmentService.add_department",
  "DesignationService.add_designation",
  "admin.view_logentry",
  "contenttypes.add_contenttype",
  "NotificationApp.add_fcminfo",
  "BranchService.add_branches",
  "ProjectService.add_projects",
  "contenttypes.change_contenttype",
  "sessions.delete_session",
  "BranchService.delete_branches",
  "ProjectService.delete_task",
  "Users.view_userroles",
  "admin.add_logentry",
  "auth.add_permission",
  "BranchService.change_branches",
  "auth.delete_permission",
  "JobService.view_jobtaskhistory",
  "JobService.view_jobtask",
  "JobService.change_jobtaskhistory",
  "ProjectService.change_projectteam",
  "sessions.change_session",
  "Users.view_logininfo",
  "OrgService.add_organization",
  "Users.delete_roles",
  "GeneralTaskService.add_generaltask",
  "Users.add_logininfo",
  "sessions.add_session",
  "Users.add_userprofile",
  "JobService.change_jobs",
  "OrgService.delete_organization",
  "TeamService.delete_teamusermapping",
  "Users.delete_userprofile",
  "contenttypes.delete_contenttype",
  "NotificationApp.view_fcminfo",
  "Users.view_userprofile",
  "JobService.add_jobs",
  "sessions.view_session",
  "Users.change_roles",
  "ProjectService.view_projects",
  "TeamService.change_team",
  "ProjectService.change_projects",
  "Users.delete_logininfo",
  "JobService.add_jobtaskhistory",
  "DesignationService.view_designation",
  "JobService.delete_jobtask",
  "DepartmentService.change_department",
  "auth.view_permission",
  "Users.view_roles",
  "OrgService.view_organization",
  "admin.change_logentry",
  "TeamService.add_team",
  "JobService.delete_jobs",
  "Users.delete_userroles",
  "DesignationService.delete_designation",
  "GradeService.change_grade",
  "ProjectService.add_task",
  "Users.view_users",
  "Users.change_users",
  "auth.delete_group",
  "NotificationApp.change_fcminfo",
  "GradeService.view_grade",
  "ProjectService.view_projectteam"
]
export const permissionData = [
  "OrgService.change_organization",
  "ProjectService.delete_projectteam",
  "ProjectService.delete_projects",
  "TeamService.view_team",
  "GeneralTaskService.change_generaltask",
  "TeamService.delete_team",
  "auth.change_permission",
  "GeneralTaskService.view_generaltask",
  "JobService.delete_jobtaskhistory",
  "BranchService.view_branches",
  "TeamService.view_teamusermapping",
  "Users.change_userprofile",
  "contenttypes.view_contenttype",
  "GeneralTaskService.delete_generaltask",
  "TeamService.add_teamusermapping",
  "JobService.add_jobtask",
  "JobService.change_jobtask",
  "ProjectService.add_projectteam",
  "DesignationService.change_designation",
  "ProjectService.change_task",
  "Users.change_logininfo",
  "NotificationApp.delete_fcminfo",
  "Users.change_userroles",
  "admin.delete_logentry",
  "auth.change_group",
  "Users.add_users",
  "TeamService.change_teamusermapping",
  "Users.add_userroles",
  "auth.view_group",
  "DepartmentService.view_department",
  "GradeService.add_grade",
  "Users.add_roles",
  "DepartmentService.delete_department",
  "JobService.view_jobs",
  "Users.delete_users",
  "GradeService.delete_grade",
  "auth.add_group",
  "ProjectService.view_task",
  "DepartmentService.add_department",
  "DesignationService.add_designation",
  "admin.view_logentry",
  "contenttypes.add_contenttype",
  "NotificationApp.add_fcminfo",
  "BranchService.add_branches",
  "ProjectService.add_projects",
  "contenttypes.change_contenttype",
  "sessions.delete_session",
  "BranchService.delete_branches",
  "ProjectService.delete_task",
  "Users.view_userroles",
  "admin.add_logentry",
  "auth.add_permission",
  "BranchService.change_branches",
  "auth.delete_permission",
  "JobService.view_jobtaskhistory",
  "JobService.view_jobtask",
  "JobService.change_jobtaskhistory",
  "ProjectService.change_projectteam",
  "sessions.change_session",
  "Users.view_logininfo",
  "OrgService.add_organization",
  "Users.delete_roles",
  "GeneralTaskService.add_generaltask",
  "Users.add_logininfo",
  "sessions.add_session",
  "Users.add_userprofile",
  "JobService.change_jobs",
  "OrgService.delete_organization",
  "TeamService.delete_teamusermapping",
  "Users.delete_userprofile",
  "ProjectService.view_projectteam",
  "contenttypes.delete_contenttype",
  "NotificationApp.view_fcminfo",
  "Users.view_userprofile",
  "JobService.add_jobs",
  "sessions.view_session",
  "Users.change_roles",
  "ProjectService.view_projects",
  "TeamService.change_team",
  "ProjectService.change_projects",
  "Users.delete_logininfo",
  "DesignationService.view_designation",
  "JobService.delete_jobtask",
  "DepartmentService.change_department",
  "auth.view_permission",
  "Users.view_roles",
  "OrgService.view_organization",
  "admin.change_logentry",
  "TeamService.add_team",
  "JobService.delete_jobs",
  "Users.delete_userroles",
  "DesignationService.delete_designation",
  "GradeService.change_grade",
  "ProjectService.add_task",
  "Users.view_users",
  "Users.change_users",
  "auth.delete_group",
  "NotificationApp.change_fcminfo",
  "GradeService.view_grade",
  "JobService.add_jobtaskhistory"
]
export const permissionSomeData = ["OrgService.change_organization",
  "ProjectService.delete_projectteam",
  "ProjectService.delete_projects",
  "TeamService.view_team",
  "GeneralTaskService.change_generaltask",
  "TeamService.delete_team",
  "auth.change_permission",
  "GeneralTaskService.view_generaltask",
  "JobService.delete_jobtaskhistory",
  "BranchService.view_branches",
  "TeamService.view_teamusermapping", "TeamService.add_team",
  "JobService.delete_jobs",
  "Users.delete_userroles",
  "DesignationService.delete_designation",
  "GradeService.change_grade",
  "ProjectService.add_task",
  "Users.view_users",
  "Users.change_users",]
export const mappingListData = {
  projectInfo: [
    {
      projectId: "PRO00001",
      projectCode: "OGA150",
      projectName: "Ogai project MMT_3",
    },
    {
      projectId: "PRO00002",
      projectCode: "OGA156",
      projectName: "Ogai project MMT_2",
    },
    {
      projectId: "PRO00003",
      projectCode: "OGA157",
      projectName: "Ogai project MMT_1",
    },
  ],
  departmentInfo: [
    {
      depId: "DPT00001",
      depName: "Financial N01",
    },
    {
      depId: "DPT00002",
      depName: "Financial N02",
    },
    {
      depId: "DPT00003",
      depName: "Financial N03",
    },
  ],
  teamInfo: [
    {
      teamId: "OGT00001",
      teamName: "Team D 1",
    },
    {
      teamId: "OGT00003",
      teamName: "Team D 2",
    },
    {
      teamId: "OGT00003",
      teamName: "Team D 3",
    },
  ],
  userInfo: [
    {
      username: "EMP150",
      firstname: "saravana kumar",
      lastname: "KJ",
      empId: "EMP150",
      emailAddress: "kjsk@gmail.com",
    },
    {
      username: "EMP152",
      firstname: "Srini",
      lastname: "R",
      empId: "EMP152",
      emailAddress: "srini@gmail.com",
    },
    {
      username: "EMP153",
      firstname: "jagdesh",
      lastname: "j",
      empId: "EMP153",
      emailAddress: "jag@gmail.com",
    },
  ],
}

export const mappingOption = [
  { id: 1, roleId: 1, roleName: "Project", roleDes: "Project" },
  { id: 2, roleId: 2, roleName: "User", roleDes: "User" },
  { id: 3, roleId: 3, roleName: "Team", roleDes: "Team" },
  { id: 4, roleId: 4, roleName: "Department", roleDes: "Dep" },
];
