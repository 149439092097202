import { FileDownload } from "@mui/icons-material";

export const assginPagedownloadButtonLabel = (style) => (
  <FileDownload sx={style} />
);
export const PASSWORD_WARINING =
  "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character(@$!%*?&)";
export const TASKSTART_WARINING_MSG = (task) =>
  `You have a task running TaskName: ${task}. Please pause/close the running task to start the new one.`;
export const TASKRESUME_WARINING_MSG = (task) =>
  `You have a task running TaskName: ${task}. Please pause/close the running task to start the new one.`;
export const MAINTASK_WARINING_MSG = (task, task2) =>
  `You have a task running TaskName: ${task}. Please pause/close the running task to start the new one.`;
export const GENERALTASK_WARINING_MSG = (task, task2) =>
  `You have a task running TaskName: ${task}. Please pause/close the running task to start the new one.`;
export const RESET_LABEL = "Reset";
export const ATTACH_MESSAGE = "Sorry cant attach.  Exceeded given limit 1 MB.";
export const ATTACH_MESSAGE_PROJECT =
  "Sorry cant attach.  Exceeded given limit 1 MB.";
