import { Box } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";
import { allpageFontStyle } from "../../utils/FontStyle";

function GoogleChart(props) {
  const { chartType, data, options, width, ref, height } = props;
  return (
    <Box>
      <Box>
        <Chart
          chartType={chartType}
          ref={ref}
          data={data}
          style={allpageFontStyle.typography1}
          options={options}
          width={width || "100%"}
          height={height || "400px"}
        />
      </Box>
    </Box>
  );
}

export default GoogleChart;
