import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Card, IconButton, InputAdornment, Popper, TextField, styled } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAction, passswordServiceAction } from "../redux/action/action";
import { resetPersistReducer } from "../redux/reducers/PresistReducer";
import { resetCommonReducer } from "../redux/reducers/commonReducer";
import { resetmasterReducer } from "../redux/reducers/masterReducer";
import { resetProfileReducer } from "../redux/reducers/profileReducer";
import { passwordChecking, passwordCheckingState } from "../utils/Imageimport";
import { passwordRulStyle } from "../utils/Style";
const StyledPopper = styled(Popper)(({ theme }) => ({ // You can replace with `PopperUnstyled` for lower bundle size.
    zIndex: 1,
    maxWidth: '375px',
    width: '100%',
    '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
    },
    '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
    },
    '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
    },
    '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
    },
}));
export default function ChangePassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open2, setOpen2] = React.useState(false);
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [arrowRef, setArrowRef] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const butref = useRef()
    const open1 = Boolean(anchorEl);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const [showPassword3, setShowPassword3] = React.useState(false);
    const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const styles = {
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            color: 'black',
            '&::before': {
                content: '""',
                margin: 'auto',
                borderColor: 'red',
                display: 'block',
                borderStyle: 'solid',
            },
        }
    };
    const onSubmit = async () => {
        handleClickOpen2()
        // setCurrentPassword('')
        // setNewPassword('');
        // setConfirmPassword('')
        // if (newPassword === confirmPassword) {
        //     await dispatch(
        //         passswordService(
        //             { newPassword: newPassword },
        //             `/users/changepassword`,
        //             "branchcodeCheck"
        //         )
        //     );
        //     dispatch(logout(navigate));
        //     dispatch(resetmasterReducer());
        //     dispatch(resetCommonReducer());
        //     dispatch(resetPersistReducer());
        //     dispatch(resetProfileReducer())
        // }


    }
    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    const pageNavigate = async () => {

        if (newPassword === confirmPassword) {
            const res = await dispatch(
                passswordServiceAction(
                    { newPassword: newPassword },
                    `/users/changepassword`,
                    "branchcodeCheck"
                )
            );
            if (res) {
                setCurrentPassword('')
                setNewPassword('');
                setConfirmPassword('')
                dispatch(logoutAction(navigate));
                dispatch(resetmasterReducer());
                dispatch(resetCommonReducer());
                dispatch(resetPersistReducer());
                dispatch(resetProfileReducer())
            } else {
                setAnchorEl(butref.current);
            }
        }
    }


    useEffect(() => {
        return () => {
            setCurrentPassword('')
            setNewPassword('');
            setConfirmPassword('')
        }
    }, [])

    return (

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>   <Box>  <Typography variant="h5"> Reset Password</Typography></Box> <Box sx={{ px: 1.5, pt: 1 }}>
                {/* <Box 
                >
                    <Tooltip title={"Password Rule"} ref={butref}>
                        <Info id="basic-button"
                            
                            onClick={(e) => { handleClick(e) }} sx={{ fontSize: 15, color: 'blue', cursor: 'pointer' }} />
                    </Tooltip>
                  
                    <Popover
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        sx={{ width: "80%" }}
                        onClose={handleClose1}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >


                        <Box sx={{ p: 1 }}><Box sx={{ width: "100%", fontSize: 14, overflowWrap: 'break-word' }}>{PASSWORD_WARINING}</Box></Box>

                    </Popover>
                </Box> */}
            </Box></Box>



            <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '40%', m: 5, p: 5, borderStyle: 'solid', borderColor: '#59a3f3' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', m: 3 }}>
                    {/* <Typography>Create Password</Typography> */}
                    {/* <FormControl sx={{ width: '25ch' }} variant="outlined"> */}
                    <TextField
                        size="small"
                        label="Create Password"
                        id="outlined-adornment-password"
                        type={showPassword2 ? 'text' : 'password'}
                        onChange={(e) => { setNewPassword(e.target.value) }}
                        value={newPassword}
                        onFocus={(e) => {
                            setAnchorEl(e.currentTarget)
                        }}
                        onBlur={(e) => {
                            setAnchorEl(false)
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="start"
                                    >
                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    {/* </FormControl> */}
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', m: 3 }}>
                    {/* <Typography>Confirm Password</Typography> */}
                    {/* <FormControl sx={{ width: '25ch' }} variant="outlined"> */}
                    <TextField
                        size="small"
                        id="outlined-adornment-password"
                        label="Confirm Password"
                        type={showPassword3 ? 'text' : 'password'}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                        value={confirmPassword}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword3}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="start"
                                    >
                                        {showPassword3 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>

                        }}

                    />
                    {(newPassword !== confirmPassword && confirmPassword.length > 1 && newPassword.length > 1) ?
                        <Typography sx={{ fontSize: 11, color: 'red' }}>Does not match with new password</Typography>
                        : null
                    }
                    {/* </FormControl> */}
                </Box>
                <StyledPopper
                    anchorEl={anchorEl}
                    open={open1}
                    // className="tooltip"
                    sx={{ background: 'white', borderRadius: 2 }}
                    placement="right-start"
                    modifiers={[
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },]}
                // onClose={handleClose}
                ><Box component="span" className="arrow" ref={setArrowRef} sx={styles.arrow} />
                    <Box sx={{ p: 1 }}>
                        <Box sx={{ pt: 1 }}>
                            <Box sx={{ ...passwordRulStyle, pb: 1 }}>{"Password should meet the conditions given below."}</Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ pr: 1 }}>{passwordChecking(newPassword, "", 8)}</Box>
                                <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(newPassword, "", 8) }}>{"Length should be minimum 8 characters."}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ pr: 1 }}>{passwordChecking(newPassword, /[a-z]/)}</Box>
                                <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(newPassword, /[a-z]/) }}>{"Should have 1 lower case."}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ pr: 1 }}>{passwordChecking(newPassword, /[A-Z]/)}</Box>
                                <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(newPassword, /[A-Z]/) }}>{"Should have 1 upper case"}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ pr: 1 }}>{passwordChecking(newPassword, /\d/)}</Box>
                                <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(newPassword, /\d/) }}>{"Should have 1 numeric."}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ pr: 1 }}>{passwordChecking(newPassword, /[@$#_]/)}</Box>
                                <Box sx={{ ...passwordRulStyle, color: passwordCheckingState(newPassword, /[@$#_]/) }}>{"Should have any one special charter (#,$,@,_)"}</Box>
                            </Box>
                        </Box></Box>

                </StyledPopper>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        onClick={() => onSubmit()}
                        disabled={!newPassword || !confirmPassword}
                        variant="contained">Change Password</Button>
                </Box>
            </Card>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Successfully Changed !"}
                </DialogTitle>
                <DialogContent>
                    Now you will be redirected to login page
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button sx={{ borderRadius: 5 }} variant="contained" onClick={pageNavigate} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



        </Box>
    )
}