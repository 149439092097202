import store from "../redux/store";
import { updateCommonStates } from "../redux/reducers/commonReducer";
import { updateMasterStates } from "../redux/reducers/masterReducer";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { fontStyle } from "./Style";
import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EyeIcon from "../components/commonComponents/eyeicon";
import { allpageFontStyle } from "./FontStyle";
import { toggelcolor, toggleColorAnnouncement } from "./CommonColors";

export const TASK_PAGE_ITEMS_PER_PAGE = 50;
export const PopupMessage = {
  back: "Are you sure you want to go back?",
  reset: "Are you sure you want to reset?",
  listType: "list",
  viewType: "view",
  editType: "edit",
  resetType: "reset",
};
export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};
export function removeDuplicatesFromArray(arr) {
  const tempObj = {};
  const resultArray = arr.filter((obj) => {
    const key = JSON.stringify(obj);
    if (!tempObj[key]) {
      tempObj[key] = true;
      return true;
    }
    return false;
  });
  return resultArray;
}
export function removeDuplicatesFromArray1(arr) {
  const tempObj = {};
  const resultArray = arr.filter((obj) => {
    const key = JSON.stringify(obj?.id);
    if (!tempObj[key]) {
      tempObj[key] = true;
      return true;
    }
    return false;
  });
  return resultArray;
}
export const TypographyCustom = (props) => {
  const { content, label, labelStyle, contentStyle, type, containStyle } =
    props;
  return (
    <>
      {type ? (
        <Box sx={{ display: "flex", px: 1, ...containStyle }}>
          <b style={{ ...fontStyle, paddingRight: 5, ...labelStyle }}>
            {label}
          </b>
          <span
            style={{
              ...fontStyle,
              paddingTop: 1,
              justifyContent: "space-between",
              ...contentStyle,
            }}
          >
            {content}
          </span>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            px: 2,
            ...containStyle,
            justifyContent: "space-between",
            py: 0,
            alignItems: "center",
          }}
        >
          <b
            style={{
              ...fontStyle,
              paddingRight: 2,
              ...labelStyle,
              paddingLeft: 2,
            }}
          >
            {label}
          </b>
          <span
            style={{
              ...fontStyle,
              paddingTop: 1,
              ...contentStyle,
              paddingLeft: 2,
            }}
          >
            {content}
          </span>
        </Box>
      )}
    </>
  );
};
export const CustomCheckBox = (props) => {
  const {
    onChange,
    checkedState,
    label,
    containStyle,
    checkBoxStyle,
    checkBoxStyleFromcontrol,
    disabled,
    color,
    labelPosition,
  } = props;
  return (
    <Box sx={{ ...containStyle }}>
      <FormControlLabel
        sx={{ ...checkBoxStyleFromcontrol }}
        labelPlacement={labelPosition}
        control={
          <Checkbox
            disabled={disabled}
            sx={{ ...checkBoxStyle }}
            color={color}
            checked={checkedState}
            onChange={onChange}
          />
        }
        label={label}
      />
    </Box>
  );
};
export const CustomButton = (props) => {
  const {
    label,
    onClick,
    buttonStyle,
    containStyle,
    component,
    varient,
    startIcon,
    endIcon,
    size,
    color,
    disabled,
    buttonType,
  } = props;
  return (
    <Box sx={{ ...containStyle }}>
      {" "}
      {buttonType || "" === "button" ? (
        <Button
          sx={buttonStyle}
          component={component}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          size={size || "small"}
          disabled={disabled}
          variant={varient || "contained"}
          color={color}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          sx={buttonStyle}
          onClick={onClick}
          size={size || "small"}
          disabled={disabled}
          variant={varient || "contained"}
          color={color}
        >
          {label}
        </IconButton>
      )}
    </Box>
  );
};
export const ProgressBar = () => <CircularProgress size={25} />;
export const CustomDialog = ({
  open,
  onClose,
  title,
  content,
  actions,
  autoCloseDuration,
  titleStyle,
  contentStyle,
  maxWidth,
  actionStyle,
  containStyle,
}) => {
  useEffect(() => {
    let autoCloseTimer;
    if (open && autoCloseDuration) {
      autoCloseTimer = setTimeout(onClose, autoCloseDuration);
    }

    return () => clearTimeout(autoCloseTimer);
  }, [open, onClose, autoCloseDuration]);
  return (
    <Dialog
      sx={{ ...containStyle }}
      maxWidth={maxWidth}
      open={open}
      disableBackdropClick
      onClose={onClose}
    >
      {title && <DialogTitle sx={{ ...titleStyle }}>{title}</DialogTitle>}
      <DialogContent sx={{ ...contentStyle }}>{content}</DialogContent>
      {actions && (
        <DialogActions sx={{ ...actionStyle, borderRadius: 5 }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
export const allowedPages = () => {
  const access = store.getState().presist.userprofile;

  return {
    adminDashboard: access?.userAccess?.superUser,
    userDashboard: access?.userAccess?.superUser ? false : true,
    organization: access?.userAccess?.superUser,
    branch: access?.userAccess?.superUser,
    department: access?.userAccess?.superUser,
    designation: access?.userAccess?.superUser,
    grade: access?.userAccess?.superUser,
    createUser: access?.userAccess?.superUser,
    groups: access?.userAccess?.superUser,
    permissions: access?.userAccess?.superUser,
    team: access?.userAccess?.superUser,
    mangeTeam: access?.userAccess?.superUser,
    createProject: access?.userAccess?.superUser,
    joblist: access?.userAccess?.superUser,
    createjop: access?.userAccess?.superUser,
    tasklist: access?.userAccess?.superUser,
    taskAssignment: access?.userAccess?.superUser,
    taskReassignment: access?.userAccess?.superUser,
    userTask: access?.userAccess?.superUser ? true : true,
    revertTask: access?.userAccess?.superUser ? true : true,
    taskSplit: access?.userAccess?.superUser,
    taskRevert: access?.userAccess?.superUser,
    report: access?.userAccess?.superUser,
    logout: access?.userAccess?.superUser,
    meeting: access?.userAccess?.superUser,
  };
};
export const TriggerAlert = (type, msg, fun) => {
  store.dispatch(updateCommonStates({ name: "isSnackOpen", value: true }));
  store.dispatch(
    updateCommonStates({
      name: "snackMsg",
      value: { type: type, message: msg, function: fun },
    })
  );
};
export const loading = (load) => async () => {
  store.dispatch(updateMasterStates({ name: "overlayLoading", value: load }));
};
export function secondsToHMS(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours?.toString()?.length > 1 ? "" : 0}${hours} : ${
    minutes?.toString()?.length > 1 ? "" : 0
  }${minutes} : ${
    Math.round(seconds)?.toString()?.length > 1 ? "" : 0
  }${Math.round(seconds)}`;
}
export function secondsToHMS1(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { H: hours, M: Math.ceil(minutes), S: Math.ceil(seconds) };
}
export const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h} h:${Math.ceil(m)} m`;
};
export const dynamicolum = [
  // {
  //   field: "taskId",
  //   headerName: "TASK ID",
  //   width: 150,
  //   align: "left",
  //   headerAlign: "left",
  // },

  {
    field: "taskInfo",
    headerName: "Category",
    width: 200,
    align: "left",
    valueGetter: (params) => {
      return `${params.row?.category || ""}`;
    },
    headerAlign: "left",
  },

  {
    field: "taskType",
    headerName: "Task Type",
    width: 200,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "measureCount",
    headerName: "Per Unit Count",
    width: 200,
    align: "left",
    // flex: 1,
    headerAlign: "left",
  },
  {
    field: "productionHours",
    headerName: "Est Hours",
    width: 200,
    align: "left",
    // flex: 1,
    headerAlign: "left",
    valueGetter: (params) => secondsToHMS(Number(params.row.productionHours)),
  },
  {
    field: "actions",
    headerName: "Action",
    type: "actions",
    flex: 1,
    // minWidth: 70,
    align: "left",
    headerAlign: "left",
    width: 100,
    minWidth: 100,
    getActions: (params) => [
      <GridActionsCellItem
        sx={{ p: 0 }}
        icon={
          <Box
            title="View"
            sx={{ p: 0, display: "flex", justifyContent: "center", width: 10 }}
          >
            <EyeIcon
              taskId={params.row.taskId}
              jobcode={params.row.jobCode}
              clickOpen={true}
              clickclose={false}
            />
          </Box>
        }
        label="View"
        // onClick={() => {
        //   handelEditStart(params)
        // }}
        color="primary"
      />,
    ],
  },
];

export const colors1 = [
  "#4252a3",
  "#e85327",
  "#642b77",
  "#4adc82",
  "#00bfff",
  "#ffb90f",
  "#8a2be2",
  "#f57f7d",
  "#008b8b",
  "#FF6633",
  "#FFB399",
  "#9e9fa2",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];
export const jobDynamicValue = [
  {
    field: "taskType",
    headerName: "Task Type",
    width: 250,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box>
        {params.row["Task Type"].split(",")?.map((val, i) => (
          <>
            <Typography
              variant="filled"
              sx={{ color: colors1[i], fontWeight: "bold" }}
            >
              {val}
              {params.row["Task Type"].split(",").length - 1 === i
                ? null
                : ",  "}
            </Typography>
          </>
        ))}
      </Box>
    ),
  },
  {
    field: "measureCount",
    headerName: "Per Unit Count",
    width: 150,
    align: "left",
    valueGetter: (params) => params.row["Measure Count"],
    headerAlign: "left",
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "EstimateHrs",
    headerName: "Est Hours",
    type: "text",
    flex: 1,
    width: 300,

    renderCell: (params) => (
      <Box>
        {params.row["Estimated Hours"].split(",")?.map((val, i) => (
          <>
            <Typography
              variant="filled"
              sx={{ color: colors1[i], fontWeight: "bold" }}
            >
              {/* {convertMinsToHrsMins(Number(val)).toString()} */}
              {val}
              {params.row["Estimated Hours"].split(",").length - 1 === i
                ? null
                : ",  "}
            </Typography>
          </>
        ))}
      </Box>
    ),
  },
  {
    field: "Overall Estimated Hours",
    headerName: "OverAll Est.Hr",
    width: 150,
    align: "left",
    headerAlign: "left",
  },
];
export const checkBoxFilter = (
  isActive,
  setIsActive,
  label,
  secondsLabel,
  callBack
) => {
  const handelcheck = async (e, v) => {
    setIsActive(v);
    callBack && (await callBack(v));
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 3, p: 1 }}>
      <Box sx={{ display: "flex", gap: 0.5 }}>
        <ToggleButtonGroup
          color="primary"
          sx={{ height: "20px" }}
          // value={isActive}
          exclusive
          onChange={handelcheck}
          aria-label="Platform"
        >
          <ToggleButton
            sx={{
              ...allpageFontStyle.typography1,
              textTransform: "capitalize",
              color: isActive ? toggleColorAnnouncement.normalfontColor : null,
              background: isActive ? toggleColorAnnouncement.normalback : null,
              ":hover": {
                color: toggleColorAnnouncement.hovernormalfontColor,
                background: toggleColorAnnouncement.normalback,
              },
            }}
            value={true}
          >
            Active
          </ToggleButton>
          <ToggleButton
            sx={{
              ...allpageFontStyle.typography1,
              textTransform: "capitalize",
              background: !isActive ? toggelcolor.myListColor : null,
              color: !isActive ? "white" : null,
              ":hover": { color: "white", background: toggelcolor.myListColor },
            }}
            value={false}
          >
            Inactive
          </ToggleButton>
        </ToggleButtonGroup>
        {/* <Stack diretion="row" spacing={1} alignItems="center">
          <Typography sx={{ ...allpageFontStyle.typography1, color: isActive ? "#358c4e" : "lightgray" }}>{label}</Typography>
          <Switch checked={isActive} color="primary" onChange={handelcheck} name="gilad" />
          <Typography sx={{ ...allpageFontStyle.typography1, color: isActive ? "lightgray" : "#358c4e" }}>{secondsLabel}</Typography>
        </Stack> */}
      </Box>
    </Box>
  );
};
