import { yupResolver } from "@hookform/resolvers/yup";
import { BorderColor, Done, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";
import { orgDataAction, orgDetailsAction } from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import {
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
} from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import ViewPopUp from "../../utils/viewPopUp";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { PopupMessage, checkBoxFilter } from "../../utils/UIUtil";
import PopupComponent from "../../components/commonComponents/PopupComponent";

const styles = {
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
    },
  },
};
const schema = yup.object().shape({
  orgName: yup.string().required("Name Required"),
  orgType: yup.string(),
  address1: yup.string().required("Address Required"),
  address2: yup.string(),
  emailAddress: yup
    .string()
    .email("Enter valid email Id")
    .required("Email ID required"),
  website: yup
    .string()
    .required("Website Required")
    .matches(
      "((http|https)://)|(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
      "Should be a valid website"
    ),
  extensionNo: yup.string().required("Extension Number Required"),
  mobileNumber: yup
    .string()
    .min(10, "Mobile Number must contain 10 digits")
    .required("Mobile Number Required"),
  contactNo: yup
    .string()
    // .min(12, "Contact No must contain 11 digits")
    .required("Landline Number Required")
    .matches(/^\d{3}-\d{8}$/, "Should be in format 044-12345678"),
  city: yup.string().required("City Name Required"),
  state: yup.string().required("State Name Required"),
  pincode: yup.string().min(6).required("Enter Valid Pincode"),
  orgDesc: yup.string().nullable(),
});
export default function Organization() {
  const { organizationList, organizationsAutoId } = useSelector(
    (state) => state.master
  );

  const [selectedRow, setSelectedRow] = React.useState({});
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [editpage, setEditPage] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [constructData, setConstructData] = React.useState([]);
  const { orgsaveData, userProfile } = useSelector((state) => state.presist);
  const [edit, setEdit] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setEditPage(false);
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      setEditPage(false);
      setEdit(false);
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(orgDetailsAction({ id: row }, "delete"));
        dispatch(orgDataAction(true));
      }
    });
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(orgDetailsAction({ id: [row?.id] }, "put"));
        dispatch(orgDataAction(false));
      }
    });
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(orgDataAction(e));
  };
  const handleEditClick = (row, data) => {
    if (data === "edit") {
      setEditPage(true);
      setSelectedRow(row);
      setEdit(true);
    }
    setValue(
      "orgId",
      data === "edit" && row?.orgId ? row.orgId : organizationsAutoId?.id
    );
    setValue("orgName", row?.orgName);
    setValue("orgType", row?.orgType);
    setValue("city", row?.city);
    setValue("state", row?.state);
    setValue("address1", row?.address1);
    setValue("address2", row?.address2);
    setValue("pincode", row?.pincode);
    setValue("orgDesc", row?.orgDesc);
    setValue("contactNo", row?.contactNo);
    setValue("website", row?.website);
    setValue("emailAddress", row?.emailAddress);
    setValue("mobileNumber", row?.mobileNumber);
    setValue("extensionNo", row?.extensionNo);
  };

  const handelClose = () => {
    // setEditPage(false)
    dispatch(updatePresistStates({ name: "orgsaveData", value: {} }));
    setSelectionIs(false);
    setSelectedFile([]);
    setEdit(false);
    reset();
  };
  const orgListConstruct = (arr) => {
    const res = arr.map((val) => ({
      createUserInfo: val.createUserInfo,
      updatedUserInfo: val.updatedUserInfo,
      ...val.organizationInfo,
    }));
    return res;
  };
  React.useEffect(() => {
    if (organizationList && organizationList.length > 0) {
      const constructDatavalue = orgListConstruct(organizationList);
      setConstructData(constructDatavalue);
    } else {
      setConstructData([]);
    }
  }, [organizationList]);
  React.useEffect(() => {
    handleEditClick(orgsaveData, "no");
  }, []);
  React.useEffect(() => {
    handleEditClick(orgsaveData, "no");
  }, [organizationsAutoId]);
  React.useEffect(() => {
    return () => {
      dispatch(
        updatePresistStates({ name: "orgsaveData", value: getValues() })
      );
    };
  }, []);

  const columns = [
    {
      field: "orgName",
      headerName: "Name",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "orgType",
      headerName: "Type",
      width: 170,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "city",
      headerName: "City",
      width: 180,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "state",
      headerName: "State",
      width: 190,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdBy",
      headerName: "Created BY",
      width: 220,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
      align: "left",
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      width: 100,
      minWidth: 100,
      align: "left",
      headerAlign: "left",
      getActions: (params) => [
        allowedPages().settings.organization.edit && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        <GridActionsCellItem
          sx={{ p: 0 }}
          // icon={
          //   <Tooltip title="View" placement="right">
          //     <RemoveRedEyeIcon sx={{ color: "#358c4e" }} />
          //   </Tooltip>
          // }
          // label="view"
          // onClick={() => {
          //   handleView(params.row, "view");
          // }}
          icon={
            <Tooltip title="View" placement="right">
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <ViewPopUp value={true} org={params.row} />
              </Box>
            </Tooltip>
          }
          color="primary"
        />,
        !isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),
        // <GridActionsCellItem
        //   icon={<Delete />}
        //   label="Delete"
        //   onClick={() => {
        //     handleDeleteClick(params.row);
        //   }}
        //   color="error"
        // />,
      ],
    },
  ];
  const dispatch = useDispatch();
  const handleView = (row) => {
    setView(true);
    setEditPage(true);

    setValue("orgName", row?.orgName);
    setValue("orgType", row?.orgType);
    setValue("city", row?.city);
    setValue("state", row?.state);
    setValue("address1", row?.address1);
    setValue("address2", row?.address2);
    setValue("pincode", row?.pincode);
    setValue("orgDesc", row?.orgDesc);
    setValue("contactNo", row?.contactNo);
    setValue("website", row?.website);
    setValue("emailAddress", row?.emailAddress);
    setValue("mobileNumber", row?.mobileNumber);
    setValue("extensionNo", row?.extensionNo);
  };
  React.useEffect(() => {
    dispatch(orgDataAction(isActive));
  }, []);

  const form = useForm({
    defaultValues: {
      orgName: "",
      orgType: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      pincode: "",
      orgDesc: "",
      contactNo: "",
      orgId: "",
      website: "",
      emailAddress: "",
      mobileNumber: "",
      extensionNo: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = form;

  const editType = (row) => {
    setValue("orgName", row.orgName);
    setValue("orgType", row.orgType);
    setValue("city", row.city);
    setValue("State", row.state);
    setValue("address1", row.createdBy);

    // setShow(true);
    // setEdit(true);
    // setSelectedRow(row);
  };

  const onSubmit = async (data) => {
    delete data.orgId;

    if (edit) {
      const editData = {
        id: selectedRow.id,
        orgId: selectedRow.orgId,
        orgName: data.orgName,
        orgDesc: data.orgDesc,
        orgType: data.orgType,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        contactNo: data.contactNo,
        mobileNumber: data.mobileNumber,
        extensionNo: data.extensionNo,
        pincode: data.pincode,
        website: data.website,
        emailAddress: data.emailAddress,
        createdBy: selectedRow.createdBy,
        updatedBy: userProfile?.useAccess?.userName,
        createdOn: selectedRow.createdOn,
        updatedOn: selectedRow.updatedOn,
      };

      // setEditPage(false)
      await dispatch(orgDetailsAction(editData, "patch"));
      dispatch(orgDataAction(isActive));

      reset();
      dispatch(updatePresistStates({ name: "orgsaveData", value: {} }));

      setEdit(false);
    } else {
      await dispatch(orgDetailsAction(data, "post"));
      dispatch(orgDataAction(isActive));

      reset();

      dispatch(updatePresistStates({ name: "orgsaveData", value: {} }));
      // dispatch(
      //   getAutoService("/OgaiApp/max/organizations/id/", "organizationsAutoId")
      // );
    }
  };

  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formContainerStyle}>
          <Box sx={{ width: "100%" }}>
            <Card sx={formContainerCardStyle}>
              {!editpage ? (
                <Box
                  sx={{
                    pt: 5,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      ...allpageFontStyle.title,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={allpageFontStyle.title}>LIST OF ORGANIZATION</h4>
                    {allowedPages().settings.organization.create && (
                      <Button
                        size="small"
                        variant="outlined"
                        // color="success"
                        onClick={() => {
                          setEditPage(true);
                          reset();
                        }}
                        sx={{
                          // width: '10%',
                          ...allpageFontStyle.addButtonFont.buttonHeight,
                          ...allpageFontStyle.buttonFontStyle1,
                          fontWeight: "bold",
                          // backgroundColor: "#43a5fc",
                          borderRadius: 5,
                          color: "#358c4e",
                          p: 0,
                          px: 2,
                          py: 0,
                          ":hover": {
                            backgroundColor: "#358c4e",
                            color: "white",
                            borderColor: "#358c4e",
                          },
                          textTransform: "capitalize",
                          borderColor: "#358c4e",
                        }}
                      >
                        <AddIcon
                          sx={{
                            mr: 0.5,
                            ...allpageFontStyle.addButtonFont.font,
                            p: 0,
                          }}
                        />
                        <Typography
                          sx={{ ...allpageFontStyle.addButtonFont.font }}
                        >
                          Create Org
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      mb: 5,
                    }}
                  >
                    <DataGride
                      columns={columns}
                      customHeight={450}
                      disableCheckbox={true}
                      selectionIs={selectionIs}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      tableData={constructData || []}
                      hideDeletion
                      // InActiveFilterCheckBox={() =>
                      //   checkBoxFilter(
                      //     isActive,
                      //     setIsActive,
                      //     "Active",
                      //     "Inactive",
                      //     handelActiveInactive
                      //   )
                      // }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    // minHeight: 600,
                    overflow: "auto",

                    width: "100%",
                  }}
                >
                  <Box sx={{ pt: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // px: 5,
                      }}
                    >
                      <Box sx={{ flex: 0.1 }}>
                        {edit ? (
                          <Box
                            sx={{
                              ...allpageFontStyle.buttonFontStyle2,
                              fontWeight: "bold",
                              m: 2,
                              mb: 3,
                            }}
                          >
                            <Typography
                              fontSize={20}
                              sx={{ ...allpageFontStyle.title }}
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              Edit{" "}
                            </Typography>
                          </Box>
                        ) : view ? (
                          <Box
                            sx={{
                              ...allpageFontStyle.buttonFontStyle2,
                              fontWeight: "bold",
                              m: 2,
                              mb: 3,
                            }}
                          >
                            <Typography
                              fontSize={20}
                              sx={{
                                ...allpageFontStyle.buttonFontStyle2,
                              }}
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              View{" "}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              ...allpageFontStyle.buttonFontStyle2,
                              fontWeight: "bold",
                              m: 2,
                              mb: 3,
                            }}
                          >
                            <Typography
                              fontSize={20}
                              sx={{
                                ...allpageFontStyle.title,
                              }}
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              Add{" "}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ flex: 0.1 }}>
                        {edit ? (
                          <Button
                            size="small"
                            variant="contained"
                            // type="submit"
                            onClick={() => {
                              setAlertPopup((per) => ({
                                ...per,
                                open: true,
                                type: PopupMessage.editType,
                                title: PopupMessage.back,
                              }));
                            }}
                            sx={{
                              color: "white",
                              width: 90,
                              m: { xs: 1, md: 1, lg: 2 },
                              ...allpageFontStyle.buttonFontStyle2,
                              backgroundColor: "#3378e8",

                              borderRadius: 5,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            List
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="contained"
                            // type="submit"
                            onClick={() => {
                              setAlertPopup((per) => ({
                                ...per,
                                open: true,
                                type: PopupMessage.listType,
                                title: PopupMessage.back,
                              }));
                            }}
                            sx={{
                              backgroundColor: "#3378e8",
                              color: "white",
                              width: 90,
                              // m: { xs: 1, md: 1, lg: 2 },
                              ...allpageFontStyle.buttonFontStyle2,
                              fontWeight: "bold",
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                              borderRadius: 5,
                            }}
                          >
                            List
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Grid container spacing={2} sx={{}}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Controller
                          control={control}
                          name="orgName"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              label="Name*"
                              onChange={(e) => {
                                trigger("orgName");
                                onChange(e);
                              }}
                              disabled={view}
                              onBlur={() => trigger("orgName")}
                              value={value}
                              helperText={
                                errors.orgName ? errors.orgName.message : null
                              }
                              error={errors?.orgName?.message}
                              inputRef={ref}
                              inputProps={{ "aria-label": "orgName" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="orgType"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              label="Type"
                              disabled={view}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "orgType" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="address1"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              size="small"
                              helperText={
                                errors.address1 ? errors.address1.message : null
                              }
                              error={errors?.address1?.message}
                              label="Address 1*"
                              onChange={(e) => {
                                trigger("address1");
                                onChange(e);
                              }}
                              onBlur={() => trigger("address1")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "address1" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="address2"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              disabled={view}
                              label="Address 2"
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "address2" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="city"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              label="City *"
                              onChange={(e) => {
                                trigger("city");
                                onChange(e);
                              }}
                              disabled={view}
                              onBlur={() => trigger("city")}
                              helperText={
                                errors.city ? errors.city.message : ""
                              }
                              error={errors?.city}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "city" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="state"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              disabled={view}
                              label="State *"
                              onChange={(e) => {
                                trigger("state");
                                onChange(e);
                              }}
                              onBlur={() => trigger("state")}
                              value={value}
                              helperText={
                                errors.state ? errors.state.message : null
                              }
                              error={errors?.state?.message}
                              inputRef={ref}
                              inputProps={{ "aria-label": "state" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="pincode"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              size="small"
                              disabled={view}
                              label="Pincode *"
                              onChange={(e) => {
                                trigger("pincode");
                                var numberRegex = /^\d+$/;
                                const u = numberRegex.test(e.target.value);

                                if (u || e.target.value === "") {
                                  setValue("pincode", e.target.value);
                                }
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 6))
                              }
                              onBlur={() => trigger("pincode")}
                              helperText={
                                errors.pincode ? errors.pincode.message : null
                              }
                              error={errors?.pincode?.message}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "pincode" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="emailAddress"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Email *"
                              size="small"
                              helperText={
                                errors.emailAddress
                                  ? errors.emailAddress.message
                                  : null
                              }
                              error={errors.emailAddress?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("emailAddress");
                                onChange(e);
                              }}
                              onBlur={() => trigger("emailAddress")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="website"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Website*"
                              size="small"
                              // helperText={errors.emailAddress ? errors.emailAddress.message : null}
                              // error={errors.emailAddress?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              helperText={
                                errors.website ? errors.website.message : null
                              }
                              error={errors.website?.message}
                              onChange={(e) => {
                                trigger("website");
                                onChange(e);
                              }}
                              onBlur={() => trigger("website")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="mobileNumber"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Mobile*"
                              size="small"
                              helperText={
                                errors.mobileNumber
                                  ? errors?.mobileNumber?.message
                                  : null
                              }
                              error={errors?.mobileNumber?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              onChange={(e) => {
                                trigger("mobileNumber");
                                var numberRegex = /^\d+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue("mobileNumber", e.target.value);
                                }
                              }}
                              disabled={view}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 10))
                              }
                              onBlur={() => trigger("mobileNumber")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="contactNo"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                                "& input::placeholder": {
                                  ...allpageFontStyle.textFieldPlaceholder,
                                },
                              }}
                              size="small"
                              disabled={view}
                              onChange={(e) => {
                                trigger("contactNo");
                                var numberRegex = /^\d+$/;
                                let temp = e.target.value.replace(/-/, "");
                                const u = numberRegex.test(temp);
                                // var number =  e.target.value.trim().replace(/[^0-9]/g, "");
                                // if (number.length < 4) setValue(number);
                                // if (number.length < 7) setValue(number.replace(/(\d{3})(\d{1})/, "$1-$2"));
                                // if (number.length < 11) setValue(number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3")) ;
                                // const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  if (e.target.value === 3)
                                    setValue("contactNo", e.target.value + "-");
                                  else if (e.target.value.length <= 12) {
                                    setValue("contactNo", e.target.value);
                                  }
                                }
                              }}
                              helperText={
                                errors.contactNo
                                  ? errors.contactNo.message
                                  : null
                              }
                              InputLabelProps={{ shrink: true }}
                              placeholder="Ex - 044-12345678"
                              error={errors?.contactNo?.message}
                              label="Contact No*"
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 12))
                              }
                              onBlur={() => trigger("contactNo")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="extensionNo"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Extn. No*"
                              size="small"
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("extensionNo");
                                var numberRegex = /^\d+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue("extensionNo", e.target.value);
                                }
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 4))
                              }
                              error={errors?.extensionNo?.message}
                              helperText={
                                errors.extensionNo
                                  ? errors.extensionNo.message
                                  : null
                              }
                              onBlur={() => trigger("extensionNo")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Controller
                          control={control}
                          name="orgDesc"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              multiline
                              disabled={view}
                              label="Description"
                              rows={3}
                              helperText={
                                <Box sx={{ color: "gray", fontSize: 10 }}>
                                  {value === ""
                                    ? "Max 500 characters"
                                    : 500 -
                                      value.length +
                                      " " +
                                      "Characters remaining"}
                                </Box>
                              }
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              inputRef={ref}
                              inputProps={{
                                "aria-label": "orgDesc",
                                maxLength: 500,
                              }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {edit ? (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        type="submit"
                        // onClick={onSubmit}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#258d4d",

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        UPDATE
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          fontSize: 12,

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      {allowedPages().settings.organization.create ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          // onClick={onSubmit}
                          sx={{
                            backgroundColor: "#258d4d",
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },

                            ...allpageFontStyle.buttonFontStyle2,
                            fontWeight: "bold",
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                            borderRadius: 5,
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          ...allpageFontStyle.buttonFontStyle2,
                          fontWeight: "bold",
                          color: "white",
                          // textTransform: 'capitalize',
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </form>
      {/* {
        organizationList.map((data,index) =>(
          <button onClick={editType(data)}>{data.name}</button>
        ))
      } */}
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
