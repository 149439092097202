import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import {
  getJobByProjectAction,
  getOverallQueriesAction,
  getProjectCodeBYUserAction,
  getProjectTreeByUserAction,
  getRequestAction,
  getoverallAnnouncementsAction,
} from "../../redux/action/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import CampaignIcon from "@mui/icons-material/Campaign";
import ForumIcon from "@mui/icons-material/Forum";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CardList from "./announcements/cardList";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryCard from "./queries/querycard";
import AnnouncementCArd from "./announcementCard";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formFieldStyle } from "../../utils/Style";
import QueryList from "./queryList";
import {
  Add,
  ArrowDropDown,
  Cancel,
  FiberManualRecord,
  Search,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { allowedPages } from "../../utils/permissions";
import { toggelcolor } from "../../utils/CommonColors";
import SelectComponent from "../commonComponents/SelectComponent";
// import AccordionCard from "./announcementCard";
import { allpageFontStyle } from "../../utils/FontStyle";
import AccordionCard from "./announcements/accordion";
import DateComponet from "../commonComponents/DateComponet";
let openPop;
let announcId;
export default function SupportPage() {
  const [params, setParams] = useSearchParams();
  // setParams('name=jbhjhg')
  let path = params.get("projectCode");
  let tab = +params.get("tab");
  let search = params.get("search");
  let jobPath = params.get("jobCode");

  const setTab = (tab) => {
    setParams(`projectCode=${path}&tab=${tab}`);
    //   setParams('projectCode', path)
    //   setParams('tab', tab)
  };
  console.log(params.get("name"), "paramass");
  // useEffect(() => {
  //   if (path) {
  //     onload(path);
  //   }
  // }, [path]);
  const [searchTerm, setSearchTerm] = useState("");
  const listInnerRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const announceTop = useRef(null);
  const queryTop = useRef(null);
  console.log(location, "location");
  const navigate = useNavigate();
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );
  openPop = location?.state?.open || openPop;
  announcId = location?.state?.announcementId || announcId;
  const { userprofile } = useSelector((state) => state.presist);
  const {
    projectCodeDta,
    jobList,
    OverallAnnouncements,
    overallQueries,
    requestQueries,
    projectTree,
  } = useSelector((state) => state.master);
  const popClose = () => {
    openPop = null;
    announcId = null;
    setPayload1({ ...payload1, searchContent: "" });
    setPayload1({ ...payload2, searchContent: "" });
    setPayload1({ ...payload3, searchContent: "" });
    onSearch("");
  };
  useEffect(() => {
    if (
      projectCodeDta?.project_details?.length &&
      (path === "null" || path === "undefined" || !path)
    ) {
      setParams(
        `projectCode=${projectCodeDta?.project_details?.[0]?.projectCode}&tab=${tab}`
      );
    }
  }, [projectCodeDta]);
  useEffect(() => {
    if (location?.state?.tab >= 0 && location?.state?.tab !== null) {
      let t = location?.state?.tab;
      setTab(t);
      location.state.tab = null;
    }
    console.log(location?.state?.announcementId, "announcementId");
    // return  (()=>location.state.announcementId = null)
  }, [location?.state?.tab]);
  const [announceId, setAnnounceId] = useState(null);

  useEffect(() => {
    console.log(location.state, "stateeee");
    if (location?.state?.announcementId && location.state?.projectCode) {
      setPayload1({
        ...payload1,
        projectCode: location.state?.projectCode,
        jobCode: "all",
        searchContent: location?.state?.announcementId.toString(),
      });
      setPayload2({
        ...payload2,
        projectCode: location.state?.projectCode,
        jobCode: "all",
      });
      setPayload3({
        ...payload3,
        projectCode: location.state?.projectCode,
        jobCode: "all",
      });
      // setTab(0)
      setParams(`projectCode=${location.state?.projectCode}&tab=${0}`);
      dispatch(
        getoverallAnnouncementsAction(
          globapOrganization.orgId,
          10,
          1,
          {
            ...payload1,
            searchContent: location?.state?.announcementId.toString(),
          },
          setLoading
        )
      );

      setAnnounceId(location.state.announcementId);
      location.state.announcementId = null;
    }
    if (location?.state?.queryCode && location.state?.projectCode) {
      setTab(location?.state?.tab);
      setParams(
        `projectCode=${location.state?.projectCode}&tab=2&search=${location?.state?.queryCode}`
      );
      setPayload1({
        ...payload1,
        projectCode: location.state?.projectCode,
        jobCode: "all",
      });
      setPayload2({
        ...payload2,
        projectCode: location.state?.projectCode,
        jobCode: "all",
      });
      setPayload3({
        ...payload3,
        projectCode: location.state?.projectCode,
        jobCode: "all",
        searchContent: location?.state?.queryCode,
      });
      dispatch(
        getRequestAction(
          globapOrganization.orgId,
          10,
          1,
          {
            ...payload3,
            projectCode: location.state?.projectCode,
            jobCode: "all",
            searchContent: location?.state?.queryCode,
          },
          setLoading
        )
      );
    }

    // console.log(list[0], "matched")
  }, [location?.state?.announcementId, location?.state?.queryCode]);
  console.log(location?.state?.announcementId, "matched");
  console.log(location?.state?.tab, "requests");
  useEffect(() => {
    dispatch(
      getProjectTreeByUserAction(
        "get",
        `/announcement/announcements/${globapOrganization?.orgId}/`
      )
    );
  }, []);
  const [more, setMore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [scrollLoader, SetScrollLoader] = useState(false);
  // const [filters, setFilters] = useState({
  //   projectCode: "all",
  //   jobCode: "all",
  // });
  const [payload1, setPayload1] = useState({
    projectCode: path || "all",
    jobCode: jobPath === "General" || !jobPath ? "all" : jobPath,
    orgId: globapOrganization?.orgId,
    fromDate: null,
    toDate: null,
    myAnnouncement: false,
    searchContent: null,
    searchId: null,
    isGeneralAnnouncement: false,
    // attachmentLocations:[]
  });
  const [payload2, setPayload2] = useState({
    projectCode: payload1.projectCode,
    jobCode: payload1.jobCode,
    orgId: globapOrganization.orgId,
    fromDate: null,
    toDate: null,
    queryStatus: "all",
    searchContent: null,
    myQuery: false,
  });
  const [payload3, setPayload3] = useState({
    projectCode: "all",
    jobCode: "all",
    orgId: globapOrganization.orgId,
    priority: "all",
    searchContent: null,
    fromDate: null,
    toDate: null,
  });
  const onload = (projectCode, jobCode) => {
    console.log(jobCode, "fgfhgfh");
    if (announceId) {
      setAnnounceId(null);
    }
    if (tab === 0) {
      const tab0 = {
        projectCode: projectCode || payload1.projectCode,
        jobCode: jobCode || payload1.jobCode,
        orgId: globapOrganization?.orgId,
        fromDate: payload1.fromDate
          ? moment(payload1.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: payload1.toDate
          ? moment(payload1.toDate).format("YYYY-MM-DD")
          : null,
        myAnnouncement: payload1.myAnnouncement,
        searchContent: payload1.searchContent,
        searchId: payload1.searchId,
        isGeneralAnnouncement: jobCode === "all" ? true : false,
      };
      dispatch(
        getoverallAnnouncementsAction(
          globapOrganization.orgId,
          10,
          1,
          tab0,
          setLoading
        )
      );
    } else if (tab === 1) {
      const v = {
        projectCode: projectCode || payload2.projectCode,
        jobCode: jobCode || payload2.jobCode,
        orgId: globapOrganization.orgId,
        fromDate: payload2.fromDate
          ? moment(payload2.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: payload2.toDate
          ? moment(payload2.toDate).format("YYYY-MM-DD")
          : null,
        queryStatus: payload2.queryStatus,
        searchContent: payload2.searchContent,
        myQuery: payload2.myQuery,
      };
      dispatch(
        getOverallQueriesAction(globapOrganization?.orgId, 10, 1, v, setLoading)
      );
    } else if (tab === 2) {
      const v = {
        projectCode: projectCode || payload3.projectCode,
        jobCode: jobCode || payload3.jobCode,
        orgId: globapOrganization.orgId,
        priority: payload3.priority,
        searchContent: payload3.searchContent,
        fromDate: payload3.fromDate
          ? moment(payload3.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: payload3.toDate
          ? moment(payload3.toDate).format("YYYY-MM-DD")
          : null,
      };
      if (v.projectCode !== "undefined") {
        dispatch(
          getRequestAction(globapOrganization.orgId, 10, 1, v, setLoading)
        );
        console.log(v.projectCode, "here1");
      }
    }
  };
  // const filterOnchange = (e) => {
  //   setPayload3({ ...payload3, priority: e.target.value });
  //   const v = {
  //     projectCode: payload3.projectCode,
  //     jobCode: payload3.jobCode,
  //     orgId: globapOrganization.orgId,
  //     priority: e.target.value,
  //     searchContent: payload3.searchContent,
  //   };
  //   dispatch(getRequestAction(globapOrganization.orgId, 10, 1, v, setLoading));
  // };
  // const [tab, setTab] = useState(
  //   allowedPages().support.announcements.showPage() ? 0 : 1
  // );
  const handleAnnouncements = () => {
    setTab(0);
    // dispatch(
    //   getoverallAnnouncementsAction(
    //     globapOrganization.orgId,
    //     10,
    //     1,
    //     payload1,
    //     setLoading
    //   )
    // );
  };
  const handleQueries = () => {
    setTab(1);
    // dispatch(
    //   getOverallQueriesAction(
    //     globapOrganization?.orgId,
    //     10,
    //     1,
    //     payload2,
    //     setLoading
    //   )
    // );
  };
  const handleRequests = () => {
    setTab(2);
    // dispatch(
    //   getRequestAction(globapOrganization.orgId, 10, 1, { ...payload3, projectCode: path || payload3.projectCode }, setLoading)
    // );
  };
  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      if (tab === 0) {
        dispatch(
          getoverallAnnouncementsAction(
            globapOrganization.orgId,
            10,
            1,
            payload1,
            setLoading
          )
        );
      } else if (tab === 1) {
        dispatch(
          getOverallQueriesAction(
            globapOrganization?.orgId,
            10,
            1,
            payload2,
            setLoading
          )
        );
      } else if (tab === 2) {
        dispatch(
          getRequestAction(
            globapOrganization.orgId,
            10,
            1,
            payload3,
            setLoading
          )
        );
        console.log(payload3.projectCode, "here4");
      }
    }
  }, [autoLoad]);
  const buttonStyle = {
    boxShadow: "none",
    textTransform: "capitalize",
    borderRadius: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    px: 2,
    ...allpageFontStyle.title1,
  };
  const getAPICallAnnouncement = async () => {
    await dispatch(
      getoverallAnnouncementsAction(
        globapOrganization.orgId,
        10,
        1,
        {
          ...payload1,
          projectCode: path || payload1.projectCode,
          isGeneralAnnouncement: jobPath === "General" ? true : false,
        },
        setLoading
      )
    );
    await dispatch(
      getProjectTreeByUserAction(
        "get",
        `/announcement/announcements/${globapOrganization?.orgId}/`
      )
    );
  };
  useEffect(() => {
    console.log(jobPath, "jobPath");
    if (tab === 0) {
      // if (announceTop.current) {
      //   announceTop.current.scrollIntoView();
      // }

      dispatch(
        getoverallAnnouncementsAction(
          globapOrganization.orgId,
          10,
          1,
          {
            ...payload1,
            projectCode: path || payload1.projectCode,
            isGeneralAnnouncement: jobPath === "General" ? true : false,
          },
          setLoading
        )
      );
      console.log(jobPath, "jobPath");
    } else if (tab === 1) {
      // if (queryTop.current) {
      //   queryTop.current.scrollIntoView();
      // }
      setPayload2({ ...payload2, searchContent: null });
      dispatch(
        getOverallQueriesAction(
          globapOrganization?.orgId,
          10,
          1,
          {
            ...payload2,
            searchContent: null,
            projectCode: path || payload2.projectCode,
          },
          setLoading
        )
      );
    } else if (tab === 2) {
      const t = projectCodeDta?.project_details?.filter(
        (value) => value.isTroubleShooter === true
      );
      const u = projectCodeDta?.project_details?.find(
        (value) => value.projectCode === path
      );
      const projectCode = u?.projectCode;
      console.log(t, "filtereddd");
      setParams(
        `projectCode=${u ? projectCode : t?.[0]?.projectCode}&tab=${tab}`
      );
      setPayload3({ ...payload3, searchContent: search || null });

      // dispatch(
      //   getRequestAction(
      //     globapOrganization.orgId,
      //     10,
      //     1,
      //     { ...payload3, searchContent: null, projectCode: path || payload3.projectCode },
      //     setLoading
      //   )
      // );
    }
  }, [tab, projectCodeDta]);
  const onScroll = () => {
    console.log("jhjg");
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log(
        Math.floor(scrollTop + clientHeight),
        scrollHeight,
        "heightss"
      );
      if (
        Math.floor(scrollTop + clientHeight) === scrollHeight ||
        Math.floor(scrollTop + clientHeight) === scrollHeight - 1
      ) {
        console.log(scrollHeight, "hghghgjhjgy7t");
        if (
          (tab === 0 && OverallAnnouncements?.pageInfo?.hasNext) ||
          (tab === 1 && overallQueries?.pageInfo?.hasNext) ||
          (tab === 2 && requestQueries?.pageInfo?.hasNext)
        ) {
          onScrollFunction();
        }
      }
    }
  };
  let currentPageNo;
  const onScrollFunction = () => {
    if (tab === 0) {
      currentPageNo = OverallAnnouncements?.pageInfo?.currentPageNumber + 1;
      dispatch(
        getoverallAnnouncementsAction(
          globapOrganization.orgId,
          10,
          OverallAnnouncements?.pageInfo?.currentPageNumber + 1,
          payload1,
          setLoading,
          SetScrollLoader
        )
      );
    } else if (tab === 1) {
      dispatch(
        getOverallQueriesAction(
          globapOrganization?.orgId,
          10,
          overallQueries?.pageInfo?.currentPageNumber + 1,
          payload2,
          setLoading,
          SetScrollLoader
        )
      );
    } else if (tab === 2) {
      dispatch(
        getRequestAction(
          globapOrganization.orgId,
          10,
          requestQueries?.pageInfo?.currentPageNumber + 1,
          payload3,
          setLoading,
          SetScrollLoader
        )
      );
      console.log(payload3, "here3");
    }
  };
  // console.log(filters, "filters");
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const handleWhose = (e, v) => {
    setPayload1({ ...payload1, myAnnouncement: v === "my" ? true : false });
    dispatch(
      getoverallAnnouncementsAction(
        globapOrganization.orgId,
        10,
        1,
        {
          ...payload1,
          myAnnouncement: v === "my" ? true : false,
          projectCode: path || payload1.projectCode,
        },
        setLoading
      )
    );
  };
  const handleWhoseQueries = (e, v) => {
    setPayload2({ ...payload2, myQuery: v === "my" ? true : false });

    dispatch(
      getOverallQueriesAction(
        globapOrganization?.orgId,
        10,
        1,
        {
          ...payload2,
          myQuery: v === "my" ? true : false,
          projectCode: path || payload2.projectCode,
        },
        setLoading
      )
    );
  };
  const onSearch = async (e) => {
    if (announceId) {
      setAnnounceId(null);
    }
    if (tab === 1) {
      setPayload2({ ...payload2, searchContent: e });

      await dispatch(
        getOverallQueriesAction(
          globapOrganization?.orgId,
          10,
          1,
          { ...payload2, searchContent: e },
          setLoading
        )
      );
    } else if (tab === 2) {
      setPayload3({ ...payload3, searchContent: e });
      dispatch(
        getRequestAction(
          globapOrganization.orgId,
          10,
          1,
          { ...payload3, searchContent: e },
          setLoading
        )
      );
      console.log(payload2.projectCode, "here2");
    } else if (tab === 0) {
      setPayload1({ ...payload1, searchContent: e });
      dispatch(
        getoverallAnnouncementsAction(
          globapOrganization.orgId,
          10,
          1,
          {
            ...payload1,
            searchContent: e,
            isGeneralAnnouncement: jobPath === "General" ? true : false,
          },
          setLoading
        )
      );
    }
  };
  const toggleStyle = {
    width: 65,
    ...allpageFontStyle.typography1,
    padding: 0,
  };

  const getTree = (projectCode, jobCode) => {
    console.log(jobCode === "General" ? true : false, "fffg");
    setParams(`projectCode=${projectCode}&jobCode=${jobCode}&tab=${tab}`);
    if (tab === 0) {
      setPayload1({
        ...payload1,
        projectCode: projectCode,
        jobCode: jobCode === "General" ? "all" : jobCode,
      });
    } else if (tab === 1) {
      setPayload2({ ...payload2, projectCode: projectCode, jobCode: jobCode });
    } else if (tab === 2) {
      setPayload3({ ...payload3, projectCode: projectCode, jobCode: jobCode });
    }
    // dispatch(getProjectTreeByUserAction('post',`/announcement/announcements/${globapOrganization?.orgId}/`, {
    //   projectCode:path,
    //   jobCode:jobPath,
    //   fromDate:null,
    //   toDate:null
    // } ))
    onload(projectCode, jobCode === "General" ? "all" : jobCode);
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: grey[200],
        overflow: "hidden",
        ...allpageFontStyle.typography3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mt: 1,
          px: 2,
          flex: 1,
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            width: "15%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // mt: 2,
            background: "white",
            pt: 0.5,
            overflow: "auto",
            maxHeight: "90%",
            minHeight: 500,
            minWidth: 200,
            // justifyContent:'center',
          }}
        >
          <TextField
            sx={{
              ...allpageFontStyle.sideBartypography,
              p: 0,
              pb:0.2,
              width: "95%",
              mb: 5,
              height:29,
              
              "& .MuiOutlinedInput-root": {
                paddingY: 0,
                "& fieldset": {
                  border: "none", // Remove the border
                },
                "&:hover fieldset": {
                  border: "none", // Remove the border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // Remove the border on focus
                },
                "& input::placeholder": {
                  ...allpageFontStyle.textFieldPlaceholder,
                },
              },
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              background: "#fff",
              borderRadius: 5,
            }}
            value={searchTerm}
            id="search"
            // label="Search"
            type="search"
            // placeholder="search"
            placeholder="Proj.code"
            size="small"
            onChange={(e) => {
              console.log(e.target.value, "ghhghg");
              setSearchTerm(e.target.value.toUpperCase());
            }}
            InputProps={{
              style: { height: 35 },
              startAdornment: (
                <InputAdornment position="end">
                  {searchTerm ? null : ( // /> //   fontSize="small" //   onClick={() => setSearchTerm(null)} //   sx={{ color: "#ff8787", cursor: "pointer" }} // <Cancel
                    <Search fontSize="small" />
                  )}
                </InputAdornment>
              ),
            }}
            color="success"
            autoComplete="off"
            variant="outlined"
          />
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {projectTree?.map((val) =>
              tab !== 2 || val?.isTroubleShooter ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {val?.projectCode.includes(searchTerm) ? (
                    <>
                      <Card
                        onClick={() =>
                          setParams(
                            `projectCode=${val?.projectCode}&tab=${tab}`
                          )
                        }
                        sx={{
                          m: 0,
                          width: "100%",
                          py: 1,
                          pl: 3,
                          ...allpageFontStyle.title1,
                          display: "flex",
                          // justifyContent: "center",
                          boxShadow: "none",
                          //  border:'0.5px solid black'
                          ":hover": { background: "#f1f3f5" },
                          borderRadius: 0,
                          background:
                            path === val?.projectCode ? "#f1f3f5" : "white",
                          borderLeft:
                            path === val?.projectCode
                              ? "5px solid #408b4f"
                              : "none",
                          cursor: "pointer",
                          alignItems: "center",
                          //  border:'0.3px solid black'
                          // flexDirection:'column'
                        }}
                      >
                        {val?.projectCode}{" "}
                        <span
                          style={{
                            ...allpageFontStyle.typography1,
                            marginLeft: 2,
                            color: "gray",
                          }}
                        >
                          {tab === 0 ? `(${val?.unreadCount || 0})` : null}
                          {/* {tab === 0
                            ? `(${val?.announcementCount})`
                            : tab === 1
                              ? `(${val?.overAllQueryCount})`
                              : tab === 2
                                ? `(${val?.openQueryCount})`
                                : null} */}
                        </span>
                        {path !== val?.projectCode ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flex: 1,
                              justifyContent: "flex-end",
                            }}
                          >
                            <ArrowDropDown />
                          </Box>
                        ) : null}
                      </Card>
                      <Collapse in={path === val?.projectCode}>
                        {tab === 0 ? (
                          <>
                            {val?.jobInfoList?.map((value) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // backgroundColor: 'gray',
                                  width: "100%",
                                }}
                              >
                                <List
                                  onClick={() =>
                                    getTree(val.projectCode, value.name)
                                  }
                                  sx={{
                                    m: 0,
                                    width: "100%",
                                    // pl: 3,
                                    py: 0,
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    display: "flex",
                                    boxShadow: "none",
                                    // ":hover": { background: "#f1f3f5" },
                                    borderRadius: 0,
                                    cursor: "pointer",
                                    alignItems: "center",
                                    background:
                                      jobPath === value?.name
                                        ? "#ebfbee"
                                        : "white",
                                    ":hover": { backgroundColor: "#ebfbee" },
                                  }}
                                >
                                  <ListItemButton sx={{ pl: 5 }}>
                                    {jobPath === value?.name ? (
                                      <FiberManualRecord
                                        fontSize="inherit"
                                        sx={{ color: "#358c4e", mr: 0.3 }}
                                      />
                                    ) : null}{" "}
                                    {value?.name}{" "}
                                    <span
                                      style={{
                                        fontSize: 12,
                                        marginLeft: 2,
                                        color: "gray",
                                      }}
                                    >
                                      {tab === 0
                                        ? `(${value?.unreadCount || 0})`
                                        : null}
                                    </span>
                                  </ListItemButton>
                                </List>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            {val?.jobQueryList?.map((value) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // backgroundColor: 'gray',
                                  width: "100%",
                                }}
                              >
                                <List
                                  onClick={() =>
                                    getTree(val.projectCode, value.jobCode)
                                  }
                                  sx={{
                                    m: 0,
                                    width: "100%",
                                    // pl: 3,
                                    py: 0,
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    display: "flex",
                                    boxShadow: "none",
                                    // ":hover": { background: "#f1f3f5" },
                                    borderRadius: 0,
                                    cursor: "pointer",
                                    alignItems: "center",
                                    background:
                                      jobPath === value?.jobCode
                                        ? "#ebfbee"
                                        : "white",
                                    ":hover": { backgroundColor: "#ebfbee" },
                                  }}
                                >
                                  <ListItemButton sx={{ pl: 5 }}>
                                    {jobPath === value?.jobCode ? (
                                      <FiberManualRecord
                                        fontSize="inherit"
                                        sx={{ color: "#358c4e", mr: 0.3 }}
                                      />
                                    ) : null}{" "}
                                    {value?.jobCode}{" "}
                                    <span
                                      style={{
                                        fontSize: 12,
                                        marginLeft: 2,
                                        color: "gray",
                                      }}
                                    >
                                      {tab !== 0
                                        ? `(${value?.openQuery || 0})`
                                        : null}
                                    </span>
                                  </ListItemButton>
                                </List>
                              </Box>
                            ))}
                          </>
                        )}
                      </Collapse>
                    </>
                  ) : null}
                  <Divider flexItem />
                </Box>
              ) : null
            )}
          </Box>
        </Box>

        <Box
          sx={{
            // flex: 1,
            width: "90%",
            display: "flex",
            flexDirection: "column",
            background: "white",
            ml: 0.5,
            flex: 1,
            maxHeight: "90%",
            minHeight: 500,
            minWidth: 600,
            // borderRadius:3
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: grey[200],
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {allowedPages().support.announcements.showPage() && (
                <Button
                  disableRipple
                  onClick={handleAnnouncements}
                  variant="contained"
                  sx={{
                    fontWeight: tab === 0 ? "bold" : "none",
                    background: tab === 0 ? "white" : grey[200],
                    color: tab === 0 ? "black" : grey[900],
                    borderBottom: tab === 0 ? "2px solid #358c4e" : null,
                    borderTop: "0px solid #868e96",
                    borderLeft: "0px solid #868e96",
                    borderRight: "0px solid #868e96",

                    ...buttonStyle,
                    ":hover": {
                      background: tab === 0 ? "white" : grey[200],
                      boxShadow: "none",
                    },
                  }}
                >
                  <CampaignIcon
                    fontSize="medium"
                    sx={{ mr: 1, color: tab === 0 ? "#358c4e" : grey[800] }}
                  />{" "}
                  Announcements
                </Button>
              )}

              {allowedPages().support.queries.showPage() && (
                <Button
                  disableRipple
                  onClick={handleQueries}
                  variant="contained"
                  sx={{
                    fontWeight: tab === 1 ? "bold" : "none",
                    background: tab === 1 ? "white" : grey[200],
                    color: tab === 1 ? "black" : grey[900],
                    borderBottom: tab === 1 ? "2px solid #358c4e" : null,
                    borderTop: "0px solid #868e96",
                    borderLeft: "0px solid #868e96",
                    borderRight: "0px solid #868e96",
                    ...buttonStyle,
                    ":hover": {
                      background: tab === 1 ? "white" : grey[200],
                      boxShadow: "none",
                    },
                  }}
                >
                  <ForumIcon
                    fontSize="medium"
                    sx={{ mr: 1, color: tab === 1 ? "#358c4e" : grey[800] }}
                  />{" "}
                  Queries
                </Button>
              )}
              {userprofile?.userAccess?.isTroubleShooter ||
              userprofile?.userAccess?.superUser ? (
                <Button
                  disableRipple
                  onClick={handleRequests}
                  variant="contained"
                  sx={{
                    fontWeight: tab === 2 ? "bold" : "none",
                    background: tab === 2 ? "white" : grey[200],
                    color: tab === 2 ? "black" : grey[900],
                    ...buttonStyle,
                    borderBottom: tab === 2 ? "2px solid #358c4e" : null,
                    borderTop: "0px solid #868e96",
                    borderLeft: "0px solid #868e96",
                    borderRight: "0px solid #868e96",
                    ":hover": {
                      background: tab === 2 ? "white" : grey[200],
                      boxShadow: "none",
                    },
                  }}
                >
                  <AnnouncementIcon
                    fontSize="medium"
                    sx={{ mr: 1, color: tab === 2 ? "#358c4e" : grey[800] }}
                  />{" "}
                  Requests
                </Button>
              ) : null}
            </Box>
            <Box
              flex={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <Box
                sx={{
                  width: "20%",
                  mx: 1,
                  display: "flex",
                  alignItems: "center",
                  minWidth: 200,
                }}
              >
                <Typography fontSize={11}>Pro Code : &nbsp;&nbsp;</Typography>
                <Select
                  fullWidth
                  notched
                  value={payload1.projectCode}
                  onChange={(e) => {
                    onload(e.target.value);
                    setPayload1({
                      ...payload1,
                      projectCode: e.target.value,
                      jobCode: "all",
                    });
                    setPayload2({
                      ...payload2,
                      projectCode: e.target.value,
                      jobCode: "all",
                    });
                    setPayload3({
                      ...payload3,
                      projectCode: e.target.value,
                      jobCode: "all",
                    });

                    dispatch(
                      updateMasterStates({
                        name: "jobList",
                        value: {},
                      })
                    );
                    if (e.target.value !== "all") {
                      dispatch(
                        getJobByProjectAction({
                          projectCode: e.target.value,
                          orgId:
                            userprofile?.userAccess?.superUser === true
                              ? globapOrganization?.orgId
                              : userprofile?.userInfo?.userInfo?.orgId,
                        })
                      );
                    }
                  }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  variant="standard"
                  sx={{
                    // width: "100%",
                    flex: 1,
                    borderRadius: 1,
                    p: 0,
                    height: 30,
                    ...allpageFontStyle.typography2
                  }}
                  label="Project Code"
                  inputProps={{
                    "aria-label": "Project Name",
                  }}
                >
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid white",
                      fontFamily: "Lato",
                      ...allpageFontStyle.typography2,
                    }}
                    value={"all"}
                  >
                    {"All"}
                  </MenuItem>
                  {projectCodeDta?.project_details?.map((val) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid white",
                        ...allpageFontStyle.typography2
                      }}
                      value={val?.projectCode}
                    >
                      {val?.projectCode}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  mx: 1,
                  display: "flex",
                  alignItems: "center",
                  minWidth: 200,
                }}
              >
                <Typography fontSize={11}>Job Code : &nbsp;&nbsp;</Typography>
                <Select
                  fullWidth
                  notched
                  onChange={(e) => {
                    onload(null, e.target.value);
                    setPayload1({ ...payload1, jobCode: e.target.value });
                    setPayload2({ ...payload2, jobCode: e.target.value });
                    setPayload3({ ...payload3, jobCode: e.target.value });
                  }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  variant="standard"
                  value={payload1.jobCode}
                  sx={{
                    // width: "80%",
                    flex: 1,
                    borderRadius: 1,
                    height: 30,
                    ...allpageFontStyle.typography2
                  }}
                  label="Job Code"
                  inputProps={{
                    "aria-label": "Project Name",
                  }}
                >
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid white",
                      ...allpageFontStyle.typography2
                    }}
                    value={"all"}
                  >
                    {"All"}
                  </MenuItem>
                  {jobList?.jobList?.map((val) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid white",
                        ...allpageFontStyle.typography2
                      }}
                      value={val?.jobCode}
                    >
                      {val?.jobCode}
                    </MenuItem>
                  ))}
                </Select>
              </Box> */}
              <TextField
                sx={{
                  p: 0,
                  width: "20%",
                  // height: 30,
                  ...allpageFontStyle.sideBartypography,
                  "& .MuiOutlinedInput-root": {
                    paddingY: 0,
                    "& fieldset": {
                      border: "none", // Remove the border
                    },
                    "&:hover fieldset": {
                      border: "none", // Remove the border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Remove the border on focus
                    },
                  },
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  background: "#fff",
                  borderRadius: 5,
                }}
                value={
                  tab === 1
                    ? payload2.searchContent || ""
                    : tab === 2
                    ? payload3.searchContent || ""
                    : tab === 0
                    ? payload1.searchContent
                    : ""
                }
                id="search"
                // label="Search"
                placeholder=""
                type="search"
                size="small"
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                InputProps={{
                  // style: { height: 35 },
                  startAdornment: (
                    <InputAdornment position="end">
                      {(payload1.searchContent && tab === 0) ||
                      (payload2.searchContent && tab === 1) ||
                      (payload3.searchContent && tab === 2) ? (
                        // <Cancel
                        //   sx={{ color: "#ff8787", cursor: "pointer" }}
                        //   onClick={() => onSearch("")}
                        //   fontSize="small"
                        // />
                        <></>
                      ) : (
                        <Search fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: 3,
                  },
                }}
                color="success"
                autoComplete="off"
                variant="outlined"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              borderLeft: "0px solid #868e96",
              borderTop: "0px solid #868e96",
              borderRight: "0px solid #868e96",
              borderBottom: "0px solid #868e96",
              height: "100%",
            }}
          >
            <Box
              ref={listInnerRef}
              onScroll={onScroll}
              sx={{
                flex: 1,
                pt: 2,
                // px: 3,
                overflow: "auto",
                maxHeight: "85%",
                // minHeight: 650,
                // height:'50%',
                width: "85%",

                // background: "white",
                // background: tab === 0 ? 'linear-gradient(  135deg, #FAF3F0 0%, #fdfcfb 100%);' : 'white'
                // background: 'white'

                // borderRadius:3
              }}
            >
              {console.log(payload1, "payload1111")}
              {tab === 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {announceId ? (
                    <Box>
                      <Button
                        sx={{
                          background: "#358c4e",
                          color: "white",
                          ...allpageFontStyle.typography2,
                          borderRadius: 5,
                          ml: 5,
                          ":hover": { background: "#358c4e", color: "white" },
                        }}
                        onClick={() => {
                          setAnnounceId(null);
                          onload(path);
                        }}
                      >
                        Show all
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      ref={announceTop}
                      sx={{ display: "flex", alignItems: "center", pl: 2 }}
                    >
                      <Box sx={{ mr: 1, pt: 1 }}>
                        <ToggleButtonGroup
                          // disabled={state}
                          color="primary"
                          // value={value}
                          exclusive
                          onChange={(e, v) => {
                            handleWhose(e, v);
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            size="small"
                            sx={{
                              ...toggleStyle,
                              textTransform: "capitalize",
                              color: payload1.myAnnouncement
                                ? toggelcolor.myListfontcolor
                                : null,
                              background: payload1.myAnnouncement
                                ? toggelcolor.myListColor
                                : null,
                              ":hover": {
                                color: toggelcolor.hoverMyListColor,
                                background: toggelcolor.myListColor,
                              },
                            }}
                            value={payload1.myAnnouncement ? "my" : "my"}
                          >
                            self{" "}
                            {payload1.myAnnouncement
                              ? `(
                            ${
                              OverallAnnouncements?.pageInfo?.total_items || 0
                            })`
                              : null}
                          </ToggleButton>
                          <ToggleButton
                            // selected
                            size="small"
                            sx={{
                              ...toggleStyle,
                              textTransform: "capitalize",
                              background: !payload1.myAnnouncement
                                ? toggelcolor.allListColor
                                : null,
                              color: !payload1.myAnnouncement
                                ? toggelcolor.allListfontcolor
                                : null,
                              ":hover": {
                                color: toggelcolor.hoverAllListColor,
                                background: toggelcolor.allListColor,
                              },
                            }}
                            value={!payload1.myAnnouncement ? "all" : "all"}
                          >
                            All{" "}
                            {!payload1.myAnnouncement
                              ? `(
                            ${
                              OverallAnnouncements?.pageInfo?.total_items || 0
                            })`
                              : null}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box sx={{ mr: 1, width: 200 }}>
                        <DateComponet
                          value={
                            payload1?.fromDate
                              ? new Date(
                                  moment(payload1?.fromDate).format(
                                    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                  )
                                )
                              : null
                          }
                          maxDate={new Date(payload1.toDate)}
                          label={"From"}
                          setValue={setPayload1}
                          handelDate={(e) => {
                            setPayload1({
                              ...payload1,
                              fromDate: e ? e : null,
                              toDate: e ? e : null,
                            });
                          }}
                        />
                        {/* <TextField
                          sx={{
                            width: "100%",
                            padding: 0,
                            ...allpageFontStyle.dateField
                          }}
                          value={payload1.fromDate}
                          onChange={(e) => {
                            setPayload1({
                              ...payload1,
                              fromDate: e.target.value,
                            });
                          }}
                          label="From"
                          size="small"
                          type="date"
                          inputProps={{
                            style: {
                              height: 13,
                              ...allpageFontStyle.typography2,
                              padding: 7,
                            },
                            max: payload1.toDate
                              ? moment(payload1.toDate).format("YYYY-MM-DD")
                              : null,
                          }}
                          InputProps={formFieldStyle.InputProps}
                          InputLabelProps={formFieldStyle.InputLabelProps}
                        /> */}
                      </Box>
                      <Box sx={{ mr: 1, width: 200 }}>
                        <DateComponet
                          value={
                            payload1?.toDate
                              ? new Date(
                                  moment(payload1?.toDate || "").format(
                                    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                  )
                                )
                              : null
                          }
                          minDate={new Date(payload1.fromDate)}
                          // maxDate={new Date()}
                          label={"To"}
                          setValue={setPayload1}
                          handelDate={(e) => {
                            setPayload1({
                              ...payload1,
                              toDate: e ? e : null,
                            });
                          }}
                        />
                        {/* <TextField
                          sx={{
                            width: "100%",
                            ...allpageFontStyle.dateField
                          }}
                          value={payload1.toDate}
                          onChange={(e) => {
                            setPayload1({
                              ...payload1,
                              toDate: e.target.value,
                            });
                          }}
                          label="To"
                          type="date"
                          size="small"
                          inputProps={{
                            min: moment(payload1.fromDate).format("YYYY-MM-DD"),
                            style: {
                              height: 13,
                              ...allpageFontStyle.typography2,
                              padding: 7,
                            },
                          }}
                          InputProps={formFieldStyle.InputProps}
                          InputLabelProps={formFieldStyle.InputLabelProps}
                        /> */}
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          mr: 1,
                          flex: 1,
                          pt: 1.7,
                        }}
                      >
                        <Box
                          onClick={() => onload(path)}
                          variant="contained"
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#1971c2",
                            p: 0.5,
                            borderRadius: 10,
                            ...allpageFontStyle.typography2,
                            width: 60,
                            color: "#fff",
                          }}
                          disabled={
                            (payload1.fromDate && !payload1.toDate) ||
                            (!payload1.fromDate && payload1.toDate)
                          }
                        >
                          Load
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          mr: 2,
                        }}
                      >
                        {allowedPages().support.announcements.create && (
                          <Button
                            color="success"
                            size="small"
                            onClick={() => navigate("/createAnnouncement")}
                            variant="outlined"
                            sx={{
                              // width: '10%',
                              height: 30,

                              ...allpageFontStyle.buttonFontStyle1,
                              fontWeight: "bold",
                              // backgroundColor: "#43a5fc",
                              borderRadius: 5,
                              color: "#358c4e",

                              ":hover": {
                                backgroundColor: "#358c4e",
                                color: "white",
                                borderColor: "#358c4e",
                              },
                              textTransform: "capitalize",
                              borderColor: "#358c4e",
                            }}
                          >
                            <Add fontSize="small" sx={{ mr: 0.5, p: 0 }} />
                            Create
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}

                  <Box
                    // ref={listInnerRef}
                    // onScroll={onScroll}
                    sx={{
                      width: "100%",
                      // overflow: "scroll",
                      // maxHeight: 500,
                      // background: '#f1f3f5',
                      px: 2,
                      // borderRadius:3,
                    }}
                  >
                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 20,
                        }}
                      >
                        <CircularProgress size={60} color="success" />
                      </Box>
                    ) : (
                      // location?.state?.announcementId ?
                      // <AnnouncementCArd
                      //   list={ list?.[0]  }
                      //   more={more}
                      //   setMore={setMore}
                      // // announcementId={location?.state?.announcementId}
                      // /> :
                      OverallAnnouncements?.announcementDetail?.map(
                        (list, index) => (
                          <>
                            {console.log(list, "listtt")}
                            {index === 0 ? (
                              <Divider
                                sx={{ my: 0.5 }}
                                orientation="horizontal"
                                flexItem
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    ...allpageFontStyle.typography2,
                                    alignItems: "center",
                                    //  color:'red'
                                  }}
                                >
                                  <CalendarMonthIcon fontSize="inherit" />{" "}
                                  {moment(
                                    list?.announcementInfo?.createdOn
                                  ).format("MMMM Do YYYY")}
                                </Box>
                              </Divider>
                            ) : moment(
                                OverallAnnouncements?.announcementDetail[index]
                                  ?.announcementInfo?.createdOn
                              ).format("MMMM Do YYYY") !==
                              moment(
                                OverallAnnouncements?.announcementDetail[
                                  index - 1
                                ]?.announcementInfo?.createdOn
                              ).format("MMMM Do YYYY") ? (
                              <Divider
                                sx={{ my: 0.5 }}
                                orientation="horizontal"
                                flexItem
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    ...allpageFontStyle.typography2,
                                    alignItems: "center",
                                    // my: 0.5,
                                  }}
                                >
                                  <CalendarMonthIcon fontSize="inherit" />{" "}
                                  {moment(
                                    list?.announcementInfo?.createdOn
                                  ).format("MMMM Do YYYY")}
                                </Box>
                              </Divider>
                            ) : null}
                            {/* <CardList list={list} more={more} setMore={setMore} /> */}
                            {console.log(location?.state, "popuppp")}
                            <AccordionCard
                              list={list}
                              openPop={openPop}
                              getAPICall={getAPICallAnnouncement}
                              key={list?.announcementInfo?.announcementCode}
                              notificationInfo={list?.announcementNotification}
                              announcementId={announcId}
                              popClose={popClose}
                            />
                            {/* <AnnouncementCArd
                              list={list}
                              more={more}
                              setMore={setMore}
                              // announcementId={location?.state?.announcementId}
                            /> */}
                          </>
                        )
                      )
                    )}
                    {scrollLoader ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size={40} color="success" />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : tab === 1 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 20,
                      }}
                    >
                      <CircularProgress size={60} color="success" />
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        ref={queryTop}
                        sx={{ display: "flex", alignItems: "center", pl: 2 }}
                      >
                        <Box sx={{ mr: 1, pt: 1 }}>
                          <ToggleButtonGroup
                            // disabled={state}
                            color="primary"
                            // value={value}
                            exclusive
                            onChange={(e, v) => {
                              handleWhoseQueries(e, v);
                            }}
                            aria-label="Platform"
                          >
                            <ToggleButton
                              size="small"
                              sx={{
                                ...toggleStyle,
                                textTransform: "capitalize",
                                color: payload2.myQuery
                                  ? toggelcolor.myListfontcolor
                                  : null,
                                background: payload2.myQuery
                                  ? toggelcolor.myListColor
                                  : null,
                                ":hover": {
                                  color: toggelcolor.hoverMyListColor,
                                  background: toggelcolor.myListColor,
                                },
                              }}
                              value={payload2.myQuery ? "my" : "my"}
                            >
                              Self{" "}
                              {payload2.myQuery
                                ? `(
                            ${overallQueries?.pageInfo?.total_items || 0})`
                                : null}
                            </ToggleButton>
                            <ToggleButton
                              // selected
                              size="small"
                              sx={{
                                ...toggleStyle,
                                textTransform: "capitalize",
                                background: !payload2.myQuery
                                  ? toggelcolor.allListColor
                                  : null,
                                color: !payload2.myQuery
                                  ? toggelcolor.allListfontcolor
                                  : null,
                                ":hover": {
                                  color: toggelcolor.hoverAllListColor,
                                  background: toggelcolor.allListColor,
                                },
                              }}
                              value={!payload2.myQuery ? "all" : "all"}
                            >
                              All{" "}
                              {!payload2.myQuery
                                ? `(
                            ${overallQueries?.pageInfo?.total_items || 0})`
                                : null}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ mr: 1, width: 200 }}>
                          <DateComponet
                            value={
                              payload2?.fromDate
                                ? new Date(
                                    moment(payload2?.fromDate || "").format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            maxDate={new Date(payload2.toDate)}
                            label={"From"}
                            setValue={setPayload2}
                            handelDate={(e) => {
                              setPayload2({
                                ...payload2,
                                fromDate: e ? e : null,
                                toDate: e ? e : null,
                              });
                            }}
                          />
                          {/* <TextField
                            sx={{
                              width: "100%",
                              padding: 0,
                              ...allpageFontStyle.dateField
                            }}
                            value={payload2.fromDate}
                            onChange={(e) => {
                              setPayload2({
                                ...payload2,
                                fromDate: e.target.value,
                              });
                            }}
                            label="From"
                            size="small"
                            type="date"
                            inputProps={{
                              style: {
                                height: 13,
                                ...allpageFontStyle.typography2,
                                padding: 7,
                              },
                              max: payload2.toDate
                                ? moment(payload2.toDate).format("YYYY-MM-DD")
                                : null,
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          /> */}
                        </Box>
                        <Box sx={{ mr: 1, width: 200 }}>
                          <DateComponet
                            value={
                              payload2?.toDate
                                ? new Date(
                                    moment(payload2?.toDate || "").format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            minDate={new Date(payload2.fromDate)}
                            label={"To"}
                            setValue={setPayload2}
                            handelDate={(e) => {
                              setPayload2({
                                ...payload2,
                                toDate: e ? e : null,
                              });
                            }}
                          />
                          {/* <TextField
                            sx={{
                              width: "100%",
                              padding: 0,
                              ...allpageFontStyle.dateField
                            }}
                            value={payload2.toDate}
                            onChange={(e) => {
                              setPayload2({
                                ...payload2,
                                toDate: e.target.value,
                              });
                            }}
                            label="To"
                            type="date"
                            size="small"
                            inputProps={{
                              style: {
                                height: 13,
                                ...allpageFontStyle.typography2,
                                padding: 7,
                              },
                              min: moment(payload2.fromDate).format(
                                "YYYY-MM-DD"
                              ),
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          /> */}
                        </Box>
                        <Box sx={{ mr: 1, pt: 1.3, width: 150 }}>
                          <FormControl size="small" fullWidth sx={{}}>
                            <InputLabel
                              sx={{ ...allpageFontStyle.typography2 }}
                              shrink
                            >
                              Status
                            </InputLabel>
                            <Select
                              fullWidth
                              notched
                              value={payload2.queryStatus}
                              onChange={(e) => {
                                setPayload2({
                                  ...payload2,
                                  queryStatus: e.target.value,
                                });
                              }}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 150 } },
                              }}
                              sx={{
                                width: "100%",
                                borderRadius: 2,
                                height: 30,
                                ...allpageFontStyle.typography2,
                              }}
                              label="Status"
                              inputProps={{
                                "aria-label": "Project Name",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",

                                  ...allpageFontStyle.typography2,
                                }}
                                value={"all"}
                              >
                                All
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  ...allpageFontStyle.typography2,
                                }}
                                value={"Open"}
                              >
                                Open
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  ...allpageFontStyle.sideBartypography,
                                }}
                                value={"ANSWER"}
                              >
                                Resolved
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            p: 1,
                            pt: 2,
                            display: "flex",
                            alignItems: "center",
                            mr: 1,
                            flex: 1,
                          }}
                        >
                          <Box
                            onClick={() => onload(path)}
                            variant="contained"
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "#1971c2",
                              p: 0.5,
                              borderRadius: 10,
                              ...allpageFontStyle.typography2,
                              width: 60,
                              color: "#fff",
                            }}
                            disabled={
                              (payload2.fromDate && !payload2.toDate) ||
                              (!payload2.fromDate && payload2.toDate)
                            }
                          >
                            Load{" "}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            p: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 2,
                            pt: 1.6,
                          }}
                        >
                          {allowedPages().support.queries.create && (
                            <Button
                              color="success"
                              size="small"
                              onClick={() => navigate("/createQuery")}
                              variant="outlined"
                              sx={{
                                // width: '10%',
                                height: 30,

                                ...allpageFontStyle.buttonFontStyle1,
                                fontWeight: "bold",
                                // backgroundColor: "#43a5fc",
                                borderRadius: 5,
                                color: "#358c4e",

                                ":hover": {
                                  backgroundColor: "#358c4e",
                                  color: "white",
                                  borderColor: "#358c4e",
                                },
                                textTransform: "capitalize",
                                borderColor: "#358c4e",
                              }}
                            >
                              <Add fontSize="small" sx={{ mr: 0.5, p: 0 }} />
                              Create
                            </Button>
                          )}
                        </Box>
                      </Box>
                      {overallQueries?.queryDetail?.map((list) => (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            // background:'pink',
                            // alignItems:'center'
                          }}
                        >
                          {/* <QueryCard list={list} overall={true} /> */}
                          <QueryList list={list} />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {scrollLoader ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={40} color="success" />
                    </Box>
                  ) : null}
                </Box>
              ) : tab === 2 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 20,
                      }}
                    >
                      <CircularProgress size={60} color="success" />
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: 2,
                          py: 2,
                        }}
                      >
                        <Box sx={{ mr: 1, pt: 1, width: 150 }}>
                          <FormControl size="small" fullWidth>
                            <InputLabel
                              sx={{ ...allpageFontStyle.typography2 }}
                              shrink
                            >
                              Criticality
                            </InputLabel>
                            <Select
                              notched
                              onChange={(e) => {
                                // filterOnchange(e)
                                setPayload3({
                                  ...payload3,
                                  priority: e.target.value,
                                });
                              }}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 150 } },
                              }}
                              value={payload3.priority}
                              sx={{
                                width: "100%",
                                borderRadius: 2,
                                height: 30,
                                ...allpageFontStyle.typography2,
                              }}
                              label="Criticality"
                              inputProps={{
                                "aria-label": "Project Name",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  fontFamily: "Lato",
                                  ...allpageFontStyle.typography2,
                                }}
                                value={"all"}
                              >
                                {"All"}
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  ...allpageFontStyle.typography2,
                                }}
                                value={"LOW"}
                              >
                                {"Low"}
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  ...allpageFontStyle.typography2,
                                }}
                                value={"MEDIUM"}
                              >
                                {"Medium"}
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  ...allpageFontStyle.typography2,
                                }}
                                value={"HIGH"}
                              >
                                {"High"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ mr: 1, width: 200 }}>
                          <DateComponet
                            value={
                              payload3?.fromDate
                                ? new Date(
                                    moment(payload3?.fromDate || "").format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            maxDate={
                              new Date(
                                moment(payload3.toDate).format("YYYY-MM-DD")
                              )
                            }
                            label={"From"}
                            setValue={setPayload3}
                            handelDate={(e) => {
                              setPayload3({
                                ...payload3,
                                fromDate: e ? e : null,
                                toDate: e ? e : null,
                              });
                            }}
                          />
                          {/* <TextField
                            sx={{
                              width: "100%",
                              ...allpageFontStyle.dateField
                            }}
                            value={payload3.fromDate}
                            onChange={(e) => {
                              setPayload3({
                                ...payload3,
                                fromDate: e.target.value,
                              });
                            }}
                            label="From"
                            size="small"
                            type="date"
                            inputProps={{
                              style: {
                                height: 13,
                                ...allpageFontStyle.typography2,
                                padding: 7,
                              },
                              max: payload3.toDate
                                ? moment(payload3.toDate).format("YYYY-MM-DD")
                                : null,
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          /> */}
                        </Box>
                        <Box sx={{ mr: 1, width: 200 }}>
                          <DateComponet
                            value={
                              payload3?.toDate
                                ? new Date(
                                    moment(payload3?.toDate || "").format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            minDate={
                              new Date(
                                moment(payload3.fromDate).format("YYYY-MM-DD")
                              )
                            }
                            label={"To"}
                            setValue={setPayload3}
                            handelDate={(e) => {
                              setPayload3({
                                ...payload3,
                                toDate: e ? e : null,
                              });
                            }}
                          />
                          {/* <TextField
                            sx={{
                              width: "100%",
                              ...allpageFontStyle.dateField
                            }}
                            value={payload3.toDate}
                            onChange={(e) => {
                              setPayload3({
                                ...payload3,
                                toDate: e.target.value,
                              });
                            }}
                            label="To"
                            type="date"
                            size="small"
                            inputProps={{
                              style: {
                                height: 13,
                                ...allpageFontStyle.typography2,
                                padding: 7,
                              },
                              min: moment(payload3.fromDate).format(
                                "YYYY-MM-DD"
                              ),
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          /> */}
                        </Box>
                        <Box sx={{ mr: 1, flex: 1, pt: 1 }}>
                          <Box
                            onClick={() => {
                              if (
                                !(
                                  (payload3.fromDate && !payload3.toDate) ||
                                  (!payload3.fromDate && payload3.toDate)
                                )
                              ) {
                                onload(path);
                              }
                            }}
                            variant="contained"
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              background:
                                (payload3.fromDate && !payload3.toDate) ||
                                (!payload3.fromDate && payload3.toDate)
                                  ? "grey"
                                  : "#1971c2",
                              p: 0.5,
                              borderRadius: 10,
                              ...allpageFontStyle.typography2,
                              width: 60,
                              // height: 30,
                              // width: 30,
                              justifyContent: "center",
                              color: "#fff",
                            }}
                          >
                            Load
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 1,
                          }}
                        >
                          <Box
                            onClick={() => onload(path)}
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              background: "#1971c2",
                              borderRadius: 3,
                              color: "white",
                              ...allpageFontStyle.typography1,
                              p: 0.5,
                              px: 1,
                              // width: 70,
                              // height: 30,
                              justifyContent: "center",
                            }}
                            variant="contained"
                            size="small"
                          >
                            <CachedIcon
                              fontSize="small"
                              sx={{ color: "white" }}
                            />{" "}
                            Refresh
                            {/* <CachedIcon
                              fontSize="medium"
                              sx={{ color: "white", ml: 0.5 }}
                            /> */}
                          </Box>
                        </Box>
                      </Box>

                      {requestQueries?.queryDetail?.map((list) => (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* <QueryCard list={list} overall={true} /> */}
                          <QueryList list={list} request={true} />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {scrollLoader ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={40} color="success" />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
