import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
// import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { createEditGetUnitMaster } from "../../../service/masterService";
import { formFieldStyle } from "../../../utils/Style";
import { TriggerAlert } from "../../../utils/UIUtil";
import { allowedPages } from "../../../utils/permissions";
import { RESET_LABEL } from "../../../utils/textName";
import { allpageFontStyle } from "../../../utils/FontStyle";
const schema = yup.object().shape({
    unitName: yup.string().required("Unit Type Required"),
    // projectMasterDes: yup.string().required("Role Description Required"),
});
function CreateMaster(props) {
    const { edit, setEdit, editValue, reloadFun } = props;
    const { state, pathname } = useLocation();
    const nav = useNavigate();
    console.log(editValue, "editValue")
    const path = pathname === "/EditMaster";
    const { globapOrganization } = useSelector((state) => state.presist);
    const [routData, setroutData] = useState(editValue || null);

    // const dispatch = useDispatch();
    // const [selectedData, setSelectedData] = useState()
    const form = useForm({
        defaultValues: {
            unitName: "",
            projectCode: "",
            projectMasterDes: "",
        },
        resolver: yupResolver(schema),
    });
    const {
        handleSubmit,
        setValue,
        control,
        // watch,
        // getValues,
        reset,
        trigger,
        formState: { errors },
    } = form;
    useEffect(() => {
        const setEditValue = () => {
            if (edit) {
                setValue("unitName", editValue?.unitName);
                // setValue("projectMasterDes", routData.projectMasterDes)
            } else {
                handelclose();
            }
        };
        setEditValue();
        return () => path && handelclose();
    }, [edit, editValue]);

    const onSubmit = async (data) => {
        if (edit) {
            const payload = {
                id: editValue?.id,
                orgId: globapOrganization?.orgId,
                unitName: data.unitName,
            };
            const res = await createEditGetUnitMaster(
                "patch",
                payload,
                globapOrganization?.orgId
            );
            if (res) {
                reloadFun();
                TriggerAlert("success", res.data.message);
                handelclose();
            }
        } else {
            const payload = {
                unitName: data.unitName,
                orgId: globapOrganization?.orgId,
            };
            const res = await createEditGetUnitMaster(
                "post",
                payload,
                globapOrganization?.orgId
            );
            if (res) {
                reloadFun();
                TriggerAlert("success", res.data.message);
                handelclose();
            }
        }
    };
    const handelclose = () => {
        reset();
        setroutData(null);
        setEdit(false);
    };

    return (
        <Box>
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ width: "50%" }}>
                            {/* <Card sx={formContainerCardStyle} > */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    textAlign: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Controller
                                        control={control}
                                        name="unitName"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextField
                                                sx={{ textTransform: "capitalize", width: "70%" }}
                                                fullWidth
                                                size="small"
                                                label="Unit Type*"
                                                helperText={
                                                    errors.unitName ? errors?.unitName?.message : null
                                                }
                                                error={errors?.unitName?.message}
                                                onChange={(e) => {
                                                    trigger("unitName");
                                                    onChange(e);
                                                }}
                                                onBlur={() => trigger("unitName")}
                                                value={value}
                                                inputRef={ref}
                                                // InputLabelProps={{ shrink: true }}
                                                inputProps={{ "aria-label": "Name" }}
                                                InputProps={formFieldStyle.InputProps}
                                                InputLabelProps={formFieldStyle.InputLabelProps}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    pt: 2,
                                    gap: 2,
                                    mb: 5,
                                }}
                            >
                                {/* {edit && */}
                                {/* } */}
                                <Box>
                                    {edit ? (
                                        <Button
                                            size="small"
                                            sx={{
                                                borderRadius: 5,
                                                ...allpageFontStyle.buttonFontStyle2,
                                                ":hover": {
                                                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                                },
                                            }}
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                        >
                                            {"Update"}
                                        </Button>
                                    ) : allowedPages().ProjectManagement.createProject.create ? (
                                        <Button
                                            size="small"
                                            sx={{
                                                borderRadius: 5,
                                                ...allpageFontStyle.buttonFontStyle2,
                                                ":hover": {
                                                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                                },
                                            }}
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                        >
                                            {"Submit"}
                                        </Button>
                                    ) : null}
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        sx={{
                                            borderRadius: 5,
                                            ...allpageFontStyle.buttonFontStyle2,
                                            ":hover": {
                                                boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                            },
                                        }}
                                        variant="contained"
                                        color="error"
                                        onClick={handelclose}
                                    >
                                        {" "}
                                        {RESET_LABEL}
                                    </Button>
                                </Box>
                                {/* <Box>
                                    <Button size='small' sx={{
                                        borderRadius: 5, fontSize: 12,
                                        ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' }
                                    }}
                                        variant='contained' onClick={() => nav("/projectMasterList")}>View List</Button>
                                </Box> */}
                            </Box>
                            {/* </Card> */}
                        </Box>
                    </Box>
                </form>
            </FormProvider>
        </Box>
    );
}

export default CreateMaster;
