import { Delete, Visibility } from "@mui/icons-material";
import { Box, Checkbox, InputAdornment, Tooltip } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbarQuickFilter,
  gridClasses,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { allpageFontStyle } from "../../utils/FontStyle";
import { BpCheckedIcon, BpIcon } from "../CheckboxCommpnent/TableCheckBox";
import './index.css'
const ODD_OPACITY = 0.2;
const PADDING = 10;
const REVERT = "#f0b878";
const OPEN = "#9cdeec";
const SPLIT = "#ffc9c9";
const ASSIGNED = "#6647ed";
const CELLOPEN = blue[500];
const PROGRESS = "#fa6c6c";
const CELREVERT = "#f18d2d";
const END = "#24a042";
export const headerBackground = "#f9efd1";
const evenRow = grey[50];
const headerColorTheam = {
  "& .super-app-theme--header1": {
    backgroundColor: "#4A3F35",
    color: "white",
  },
  "& .super-app-theme--header2": {
    backgroundColor: "#116D6E",
    color: "white",
  },
  "& .super-app-theme--header3": {
    backgroundColor: "#FF4301",
    color: "white",
  },
  "& .super-app-theme--header4": {
    backgroundColor: "#C69749",
    color: "white",
  },
  "& .super-app-theme--header5": {
    backgroundColor: "#404258",
    color: "white",
  },
  "& .super-app-theme--header6": {
    backgroundColor: "#395B64",
    color: "white",
  },
};
export const StickyDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    position: "sticky",
    // Replace background colour if necessary
    backgroundColor: headerBackground,
    color: "rgb(0,0,0)",
    fontWeight: "bold",
    borderRadius: 0,
    // Display header above grid data, but below any popups
    zIndex: theme.zIndex.mobileStepper - 1,
    paddingLeft: PADDING,
    paddingRight: PADDING,
    fontSize: allpageFontStyle.dataGird.fontSize,
  },

  ...headerColorTheam,
  fontFamily: "Lato",
  "& .super-app-theme--CELLOPEN": {
    backgroundColor: CELLOPEN,
    color: "while",
  },
  "& .super-app-theme--ASSIGNED": {
    backgroundColor: ASSIGNED,
    color: "while",
  },
  "& .super-app-theme--PROGRESS": {
    backgroundColor: PROGRESS,
    color: "while",
  },
  "& .super-app-theme--CELREVERT": {
    backgroundColor: CELREVERT,
    color: "while",
  },
  "& .super-app-theme--END": {
    backgroundColor: END,
    color: "while",
  },
  "& .super-app-theme--Open": {
    backgroundColor: OPEN,
    "&:hover": {
      backgroundColor: OPEN,
    },
    "&.Mui-selected": {
      backgroundColor: OPEN,
      "&:hover": {
        backgroundColor: OPEN,
      },
    },
  },
  "& .super-app-theme--Split": {
    backgroundColor: SPLIT,
    "&:hover": {
      backgroundColor: SPLIT,
    },
    "&.Mui-selected": {
      backgroundColor: SPLIT,
      "&:hover": {
        backgroundColor: SPLIT,
      },
    },
  },

  "& .super-app-theme--Revert": {
    backgroundColor: REVERT,
    "&:hover": {
      backgroundColor: REVERT,
    },

    "&.Mui-selected": {
      backgroundColor: REVERT,
      "&:hover": {
        backgroundColor: REVERT,
      },
    },
  },
  // "& .MuiDataGrid-virtualScroller": {
  //   // Undo the margins that were added to push the rows below the previously fixed header
  //   marginTop: "0 !important",
  // },
  // "& .MuiDataGrid-main": {
  //   // Not sure why it is hidden by default, but it prevented the header from sticking
  //   overflow: "visible",
  // },
  ":root": {
    oveflow: "auto",
  },

  ".MuiDataGrid-checkboxInput": {
    color: "#028df0",
  },
  ".MuiCheckbox-root.Mui-checked": {
    color: "#028df0",
  },
  ".MuiDataGrid-cell": {
    maxHeight: 10,
    overflowWrap: "break-word",
  },
  [`& .${gridClasses.row}`]: {
    paddingLeft: PADDING,
    paddingRight: PADDING,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  // alternate row color
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: evenRow,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
function DataGride(props) {
  // const apiRef1 = useGridApiRef();
  const {
    tableData,
    edit,
    apiRef,
    columns,
    onSelectionModelChange,
    selectedFile,
    selectionState,
    selectionIs,
    deleteFuntion,
    getRowHeight,
    hideDeletion,
    customHeight,
    pageName,
    disableCheckbox,
    hiddenFooter,
    rowData,
    onPageSizeChange,
    processRowUpdate,
    pageSize,
    page,
    deleteIcon,
    slotFooter,
    InActiveFilterCheckBox,
    rowCount,
    getRowId,
    filterCheckBox,
    getRowClassName,
    key,
    columnHeaderHeight = 40,
    paginationMode,
    rowSelection = true,
    containerStyle,
    hideBackground,
    isTaskReportPage,
    hidePagination
  } = props;
  const deleteFun = () => {
    if (selectionState?.length > 0 && selectionIs) {
      deleteFuntion(selectionState);
    }
  };

  function QuickCheckBox(props) {
    console.log(props, "props");
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Checkbox
          size="small"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          {...props}
        />
      </Box>
    );
  }
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: grey[100],

        }}
      >
        <Box
          sx={{
            p: 0.7,
            // pb: 0,
          }}
        >
          <GridToolbarQuickFilter
            variant="outlined"
            size="small"
            placeholder=""
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingY: 0,
                "& fieldset": {
                  border: "none", // Remove the border
                },
                "&:hover fieldset": {
                  border: "none", // Remove the border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // Remove the border on focus
                },
              },
              // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              background: "#fff",
              borderRadius: 20,
              border: '1px solid #c7c7c8',
              padding: 0
            }}
            inputProps={{
              style: {
                padding: 0,
              },
            }}
            endAdornment={
              <InputAdornment position="end">

                <Visibility />
              </InputAdornment>
            }
          // InputProps={{
          //   style: {
          //     padding: 3
          //   }
          // }}
          />
          {/* <TextField
            variant="outlined"
            size="small"
            placeholder=""
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingY: 0,
                '& fieldset': {
                  border: 'none', // Remove the border
                },
                '&:hover fieldset': {
                  border: 'none', // Remove the border on hover
                },
                '&.Mui-focused fieldset': {
                  border: 'none', // Remove the border on focus
                },
              },
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              border: '1px solid grey'
            }}
            inputProps={{
              padding: 4
            }}
          /> */}
        </Box>
        {filterCheckBox ? <Box>{filterCheckBox()}</Box> : null}
        {!hideDeletion ? (
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-end", gap: 2 }}>
              {InActiveFilterCheckBox && <Box>{InActiveFilterCheckBox()}</Box>}
              {/* {deleteIcon && ( */}
              {deleteIcon && (
                <Box>
                  <Tooltip title="Delete" placement="bottom">
                    <Delete
                      sx={{ cursor: "pointer" }}
                      color={selectionIs ? "error" : ""}
                      onClick={deleteIcon ? deleteFun : () => { }}
                    />
                  </Tooltip>
                </Box>
              )}
              {/* )} */}
            </Box>
          </Box>
        ) : null}
      </Box>
    );
  }
  const widthPerLetter = 9;
  const additionalWidth = 30;
  const maxWidth = 400;
  const [updatedColumn, setUpdatedColumn] = useState([]);
  useEffect(() => {
    if (columns?.length && tableData?.length) {
      let columnWidth = {};
      let columnWidth2 = {};
      let finalColumn = [];

      tableData?.map((item, index) => {
        const updatedColumn = columns?.map((column, position) => {
          const fieldName = column?.field;
          let fieldLength = 0;
          if (isTaskReportPage && item[column?.field]?.status) {
            const tColumn = { ...column }
            tColumn.minWidth = 220
            tColumn.getCellClassName = 'bg-unassigned'
            return tColumn;
          }
          else if (column.valueGetter) {
            const t = column.valueGetter({ row: item });
            fieldLength = t?.toString()?.length;
            console.log(t, "cfyweyufvyu");
          } else if (column.valueFormatter) {
            const t = column.valueFormatter({ row: item });
            fieldLength = t?.toString()?.length;
            console.log(t, "cfyweyufvyu");
          } else fieldLength = item[fieldName]?.toString()?.length;
          const headerLength = column["headerName"]?.length
            ? column["headerName"]?.length * widthPerLetter + additionalWidth
            : 0;
          const width = fieldLength * widthPerLetter + additionalWidth;
          const finalWidth1 = headerLength > width ? headerLength : width;
          const finalWidth =
            finalWidth1 > 300
              ? finalWidth1 - 100
              : finalWidth1 > 150
                ? finalWidth1 - 30
                : finalWidth1;
          if (!columnWidth[fieldName] || columnWidth[fieldName] < finalWidth)
            columnWidth[fieldName] = finalWidth;
          columnWidth2[fieldName] = [
            ...(columnWidth2[fieldName] || []),
            finalWidth,
          ];
          const temp = { ...column };
          const finalWidth2 = columnWidth[fieldName] || temp.width;
          if (column?.key === 'SN') {
            temp.minWidth = 50;
            temp.width = 50;
            return temp;
          } else {
            temp.minWidth = finalWidth2 < maxWidth ? finalWidth2 : maxWidth;
            // if(!isTaskReportPage || (isTaskReportPage && !temp.noFlex)) temp.flex = 1;
            if (!isTaskReportPage) temp.flex = 1;
            temp.width = 100;
            return temp;
          }
        });
        let tColumn = [...updatedColumn]
        console.log(tColumn, "columnWidthcolumnWidth");

        if (index === tableData?.length - 1) {
          if (updatedColumn?.length && isTaskReportPage) {
            const t = {
              headerName: '',
              field: 'empty',
              valueGetter: () => '',
              sortable: false,
              flex: 1
            }
            tColumn = [...updatedColumn, t].map(i => {
              if (i.findUnitTypeColumn) {
                const unitType = item[i.key]?.unitType
                return { ...i, headerName: (unitType || '') + 'Count' }
              } return i
            })
            // const lastOne = tColumn.at(-1)
            // lastOne.flex = 1
            // lastOne.minWidth = 200
          }
          finalColumn = tColumn;
        }
      });
      console.log(columnWidth2, "columnWidthcoswdwdlumnWidth");
      setUpdatedColumn(finalColumn);
    } else {
      const updatedColumn = columns?.map((column) => {
        const headerLength = column["headerName"]?.length
          ? column["headerName"]?.length * widthPerLetter + additionalWidth
          : 100;
        const finalWidth =
          headerLength > 300
            ? headerLength - 100
            : headerLength > 150
              ? headerLength - 30
              : headerLength;
        const temp = { ...column };
        const finalWidth2 = finalWidth || 100;
        if (column?.key === 'SN') {
          temp.minWidth = 10;
          temp.width = 10;
        } else {
          temp.minWidth = finalWidth2 < maxWidth ? finalWidth2 : maxWidth;
          temp.flex = 1;
          temp.width = 100;
        }
        return temp;
      });
      setUpdatedColumn(updatedColumn);
      console.log(updatedColumn, "werufghwu9ierbfu9ierbgifgu");
    }
  }, [columns, tableData?.length]);
  return (
    <Box sx={{
      height: customHeight || 330,
      width: "100%",
      // background: hideBackground ? 'none' : '#f9f9fb',
      border:'1px solid #f1f1f1',
      ...containerStyle,
      // px: 1
    }}>
      {" "}
      <StickyDataGrid
        style={{ border: `none`, overflow: "hidden", background: '#fff' }}
        columnHeaderHeight={columnHeaderHeight}
        apiRef={apiRef}
        key={key}
        rows={tableData || []}
        columns={updatedColumn}
        // pageSize={pageSize}
        // paginationModel={{ page: page + 1, pageSize: pageSize }}
        edit={edit}
        initialState={{
          pagination: {
            paginationModel:
              pageName === "project"
                ? { pageSize: pageSize, page: page }
                : null,
          },
        }}
        hideFooterPagination={hidePagination}
        getRowHeight={getRowHeight}
        onPaginationModelChange={onPageSizeChange}
        pageSizeOptions={[25, 50, 100]}
        rowCount={rowCount}
        // paginationModel ={onPageChange}
        pagination
        getRowId={(dtat) => {
          if (getRowId) return getRowId(dtat);
          return pageName === "team"
            ? dtat?.teamId
            : dtat?.id ||
            dtat?.project_details?.projectCode ||
            dtat?.projectCode;
        }}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: QuickSearchToolbar,
          ...slotFooter,
          baseCheckbox: QuickCheckBox,
        }}
        checkboxSelection={!disableCheckbox}
        hideFooter={hiddenFooter}
        paginationMode={paginationMode ? "server" : "client"}
        density={"compact"}
        rowSelection={rowSelection}
        getRowClassName={(params) => {
          if (getRowClassName) return getRowClassName(params);
          else
            return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
        }}
        onRowSelectionModelChange={onSelectionModelChange}
        onRowClick={rowData}
        rowSelectionModel={selectedFile}
        autoWidth
        getCellClassName={params => params?.value?.assignedTo === "Unassigned" && isTaskReportPage ? 'bg-unassigned' : ''}
        disableColumnMenu
        sx={{
          // boxShadow: 4,
          // border: 2,
          overflowY: "auto",
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
          {
            outline: "none !important",
            color: "red",
          },
          "& .MuiDataGrid-footerContainer .MuiTablePagination-selectLabel": {
            display: "none",
          },
          "& .MuiDataGrid-overlay": {
            display: "none",
          },

          ".MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
          },
          ".MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
            fontSize: 13,
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
            background: headerBackground,
            display: hidePagination ? 'none' : 'block'
          },

          fontSize: allpageFontStyle.dataGird.fontSize,
          overflowX: 'auto'
        }}
      />
    </Box>
  );
}

export default DataGride;
