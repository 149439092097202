import { conformPopup } from "../../components/popup/ConformPopup";
import { logoutAction } from "../../redux/action/action";
import { resetPersistReducer } from "../../redux/reducers/PresistReducer";
import {
  UpdateUnreadCount,
  endGeneralTask,
  resetCommonReducer,
  updateRunningGeneralTask,
} from "../../redux/reducers/commonReducer";
import { resetmasterReducer } from "../../redux/reducers/masterReducer";
import { resetProfileReducer } from "../../redux/reducers/profileReducer";
import store from "../../redux/store";
import { TriggerAlert, loading } from "../../utils/UIUtil";
import {
  generalTaskMapService,
  generalTaskService,
  getAllProj_Dep_Team_User_Service,
  getGeneralTaskMappedDataService,
  getUsersMappedGeneralTaskService,
  getUsersRunningGeneralTaskService,
  updateGeneralTaskService,
} from "../generalTaskService";
import { getUnreadNotificationCount } from "../notificationService";
const dispatch = store.dispatch;
export const generalTaskAction = async (method, data, active) => {
  dispatch(loading(true));
  try {
    return generalTaskService(method, data, active).then((res) => {
      dispatch(loading(false));
      if (res) {
        if (method !== "get") TriggerAlert("success", res?.data?.message);
        return res;
      }
    });
  } catch (error) {
    dispatch(loading(false));
  }
};

export const getAllProj_Dep_Team_UserAction = async (id) => {
  dispatch(loading(true));
  try {
    return await getAllProj_Dep_Team_User_Service(id).then((res) => {
      dispatch(loading(false));
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};

export const getGeneralTaskMappedDataAction = async (taskId) => {
  dispatch(loading(true));
  try {
    return await getGeneralTaskMappedDataService(taskId).then((res) => {
      dispatch(loading(false));
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};

export const generalTaskMapAction = async (method, data) => {
  dispatch(loading(true));
  try {
    return generalTaskMapService(method, data).then((res) => {
      dispatch(loading(false));
      if (res) {
        TriggerAlert("success", res?.data?.message);
        return res;
      }
    });
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getUsersMappedGeneralTaskAction = async () => {
  dispatch(loading(true));
  try {
    return getUsersMappedGeneralTaskService().then((res) => {
      dispatch(loading(false));
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};
export const getUsersRunningGeneralTaskAction = async () => {
  dispatch(loading(true));
  try {
    return getUsersRunningGeneralTaskService().then((res) => {
      dispatch(loading(false));
      const t = res?.data?.data;
      if (t?.length) {
        if (t.at(-1)?.state !== "End")
          dispatch(updateRunningGeneralTask(t.at(-1)));
      }
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};
export const startGeneralTaskServiceAction = async (selectedtask) => {
  const payload = {
    generalTaskId: selectedtask?.genTaskId,

    status: "In-Progress",

    state: "Started",

    remarks: "",

    workHours: 0,

    workMinutes: 0,

    workSeconds: 0,
  };
  dispatch(loading(true));
  try {
    return updateGeneralTaskService(payload).then((res) => {
      dispatch(loading(false));
      getUsersRunningGeneralTaskAction();
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};
export const updateGeneralTaskServiceAction = async (
  selectedtask,
  workHours,
  workMinutes,
  workSeconds,
  noLoader
) => {
  const payload = {
    generalTaskId: selectedtask?.genTaskId,

    status: "In-Progress",

    state: "Updated",

    remarks: "",

    workHours,

    workMinutes,

    workSeconds,
  };
  !noLoader && dispatch(loading(true));
  try {
    return updateGeneralTaskService(payload).then((res) => {
      !noLoader && dispatch(loading(false));
      // getUsersRunningGeneralTaskAction()
      return res;
    });
  } catch (error) {
    !noLoader && dispatch(loading(false));
  }
};
export const endGeneralTaskServiceAction = async (
  selectedtask,
  workHours,
  workMinutes,
  workSeconds,
  upComingTask
) => {
  const payload = {
    generalTaskId: selectedtask?.genTaskId,

    status: "In-Progress",

    state: "End",

    remarks: "",

    workHours,

    workMinutes,

    workSeconds,
  };
  dispatch(loading(true));
  try {
    return updateGeneralTaskService(payload).then((res) => {
      dispatch(loading(false));
      // after ending running task remove it from redux if upcoming task is not available
      !upComingTask && dispatch(updateRunningGeneralTask(null));
      dispatch(endGeneralTask(null));
      getUsersRunningGeneralTaskAction();
      return res;
    });
  } catch (error) {
    dispatch(loading(false));
  }
};
export const onLogoutAction = (nav) => {
  dispatch(logoutAction());
  dispatch(resetmasterReducer());
  dispatch(resetCommonReducer());
  dispatch(resetPersistReducer());
  dispatch(resetProfileReducer());
  nav("/");
};
export const checkAndEndRunningGeneralTaskAction = async (data, callBack) => {
  let res = true;
  const { runningGeneralTask } = store.getState().common;
  if (runningGeneralTask) {
    if (data?.logout)
      return conformPopup(
        "Alert",
        `You have to close the running "${runningGeneralTask?.genTaskName}" task before you logout!`,
        () =>
          dispatch(
            endGeneralTask({
              task: runningGeneralTask,
              upComingTask: null,
              logout: data?.logout,
            })
          ),
        "Close Task & Logout",
        "Cancel"
      );
    else
      dispatch(
        endGeneralTask({ task: runningGeneralTask, upComingTask: null })
      );
  } else {
    callBack && callBack();
  }
  return res;
};

export const getNotificationCountAction = async () => {
  const countRes = await getUnreadNotificationCount();
  if (countRes?.data) {
    dispatch(
      UpdateUnreadCount({
        name: "unreadCount",
        value: countRes?.data?.unReadCount,
      })
    );
  }
};
