import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getPojectReleatedListAction,
  getProjectListinDashboardAction,
} from "../../redux/action/action";
import store from "../../redux/store";
import {
  getJobReportService,
  getProjectReportService,
} from "../../service/masterService";
import {
  getDisplayColumns,
  getTaskReport,
  getTaskStatusReport,
  reportDownload,
} from "../../service/reportService";
import { TriggerAlert, loading } from "../../utils/UIUtil";
import {
  taskStatusReportColor,
  taskStatusReportColorFontColor,
} from "../../utils/CommonColors";
import { useLocation } from "react-router-dom";
// import { updatePresistStates } from "../../redux/reducers/PresistReducer";

const dispatch = store.dispatch;
const initialState = {
  projectCode: "",
  jobCode: "",
  columnHeader: ["All"],
  category: "All",
  taskType: "All",
  users: [],
  taskState: "All",
  taskStatus: "All",
  createdDate: {
    fromDate: null,
    toDate: null,
  },
  assignedDate: {
    fromDate: null,
    toDate: null,
  },
};

const constructProjectData = (arr) => {
  const t = [...arr.taskStatus].find((i) => i.status === "All")?.stateList;
  const res = {
    projectInfo: arr?.projectInfo[0],
    jobList: arr.jobList,
    taskCategory: ["All", ...arr?.taskCategory],
    taskType: ["All", ...arr?.taskType],
    // state: ["All", ...arr.taskState],
    state: ["All", ...t],
    status: [...arr.taskStatus],
    userList:
      arr.users && arr.users.length > 0 && arr.users.map((val) => val.userInfo),
  };
  return res;
};
const construtProjectdata = (arr) => {
  const res = arr.map((val) => val);
  return res;
};

export const useReport = (pathName) => {
  const taskReportData = useTaskReport();
  const taskStatusReportData = useTaskStatusReport();
  const projectReportData = useProjectReport();
  const jobReportData = useJobReport();
  if (pathName === "/taskReport" || pathName === "/taskList")
    return taskReportData;
  else if (pathName === "/taskStatusReport") return taskStatusReportData;
  else if (pathName === "/project_report") return projectReportData;
  else return jobReportData;
};

export const useTaskReport = () => {
  const location = useLocation();
  const {
    projectListDashboard,
    // jobList,
    projectReleatedList,
  } = store.getState().master;
  const { globapOrganization } = useSelector((state) => state.presist);
  const [selectedData, setSelectedData] = useState(initialState);
  // const [selectOptionData, setselectOptionData] = useState({
  //     projectList: [],
  //     jobList: [],
  //     taskTypeList: [],
  //     categoryList: [],
  //     excelExport: false,
  //     userList: [],
  //     state: [],
  //     status: []
  // })
  const [filters, setFilters] = useState([
    {
      name: "Project*",
      field: "projectCode",
      options: [],
      label: "projectCode",
      value: "projectCode",
      showSelect: "Select",
      onChange: (e, selectedData) => {
        let code = e.target.value;
        setSelectedData((prev) => ({ ...initialState, projectCode: code }));
        setFilters((prev) => {
          const t = [...prev];
          // Select Job
          t[1].options = [];
          // Select Category
          t[2].options = [];
          // select task type
          t[3].options = [];
          // Select  Status
          t[4].options = [];
          // Select  State
          t[5].options = [];
          // Select  Users
          t[6].options = [];
          return t;
        });
        if (code) {
          dispatch(
            getPojectReleatedListAction({
              projectCode: code,
              orgId: globapOrganization.orgId,
            })
          );
        }
      },
    },
    {
      name: "Job*",
      field: "jobCode",
      label: "jobCode",
      value: "jobInfo",
      showSelect: "Select",
      options: [],
      onChange: (e, selectedData) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, jobCode: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, jobCode: "" }));
        }
      },
    },
    {
      name: "Category",
      field: "category",
      options: [],
      onChange: (e) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, category: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, category: "" }));
        }
      },
    },
    {
      name: "Task Type",
      field: "taskType",
      options: [],
      onChange: (e) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, taskType: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, taskType: "" }));
        }
      },
    },
    {
      name: "Status",
      field: "taskStatus",
      options: [],
      value: "status",
      label: "status",
      onChange: function (e) {
        if (e.target.value) {
          const find = filters[4].options.find(
            (i) => i.status === e.target.value
          );
          if (find) {
            setFilters((prev) => {
              let t = [...prev];
              t[5].options = ["All", ...find.stateList];
              return t;
            });
          }
          setSelectedData((prev) => ({ ...prev, taskStatus: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, taskStatus: "" }));
        }
      },
    },
    {
      name: "State",
      field: "taskState",
      options: [],
      onChange: (e) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, taskState: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, taskState: "" }));
        }
      },
    },
    {
      name: "Users",
      field: "users",
      options: [],
      type: "multiSelect",
      value: "username",
      labelGetter: (data) => data?.firstname + " " + data?.lastname,
      onChange: (e, v) => {
        if (v) {
          if (v.length > 1 && v.find((i) => i.username === "All")) {
            setSelectedData((prev) => {
              if (prev.users.find((i) => i.username === "All"))
                return {
                  ...prev,
                  users: v.filter((i) => i.username !== "All"),
                };
              else
                return {
                  ...prev,
                  users: v.filter((i) => i.username === "All"),
                };
            });
          } else setSelectedData((prev) => ({ ...prev, users: [...v] }));
        } else {
          setSelectedData((prev) => ({ ...prev, users: [] }));
        }
      },
    },
    {
      name: "Created (From)",
      valueGetter: (data) => data["createdDate"]["fromDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: e ? moment(e).format("YYYY-MM-DD") : null,
              toDate: null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: e ? moment(e).format("YYYY-MM-DD") : null,
              toDate: null,
            },
          }));
        }
      },
    },
    {
      name: "Created (To)",
      valueGetter: (data) => data["createdDate"]["toDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: e ? moment(e).format("YYYY-MM-DD") : null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: e ? moment(e).format("YYYY-MM-DD") : null,
            },
          }));
        }
      },
      minDate: (data) => data["createdDate"]["fromDate"],
    },
    {
      name: "Assigned (From)",
      valueGetter: (data) => data["assignedDate"]["fromDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            assignedDate: {
              ...prev.assignedDate,
              fromDate: e ? moment(e).format("YYYY-MM-DD") : null,
              toDate: null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            assignedDate: {
              ...prev.assignedDate,
              fromDate: e ? moment(e).format("YYYY-MM-DD") : null,
              toDate: null,
            },
          }));
        }
      },
    },
    {
      name: "Assigned (To)",
      valueGetter: (data) => data["assignedDate"]["toDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            assignedDate: {
              ...prev.assignedDate,
              toDate: e ? moment(e).format("YYYY-MM-DD") : null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            assignedDate: {
              ...prev.assignedDate,
              toDate: e ? moment(e).format("YYYY-MM-DD") : null,
            },
          }));
        }
      },
      minDate: (data) => data["assignedDate"]["fromDate"],
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const commonValueHeaders = [
    {
      name: "Project Code",
      field: "projectCode",
    },
    {
      name: "Project Name",
      field: "projectName",
    },
    {
      name: "Job Code",
      field: "jobCode",
    },
  ];
  const onLoad = async (download) => {
    try {
      dispatch(loading(true));
      if (!selectedData.projectCode || !selectedData.jobCode) {
        dispatch(loading(false));
        return TriggerAlert("warning", "Kindly Select Project & Job");
      }
      selectedData.excelExport = false;
      const payload = { ...selectedData };
      payload.filterColumnHeaders = false;
      payload.columnHeader = "All";
      payload.users = selectedData.users?.length
        ? selectedData.users?.length === 1 &&
          selectedData.users[0].username === "All"
          ? "All"
          : selectedData.users?.map((i) => i.username)
        : "All";
      if (download === "download") {
        payload.export = true;
        payload.excelExport = true;
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")
        // payload.assignedDate.fromDate = moment(selectedData.assignedDate.fromDate).format("YYYY-MM-DD")
        // payload.assignedDate.toDate = moment(selectedData.assignedDate.toDate).format("YYYY-MM-DD")
        await reportDownload(
          payload,
          "/report/task_report/",
          "post",
          `Task_Report_${payload.projectCode}.xlsx`
        );
      } else {
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")
        // payload.assignedDate.fromDate = moment(selectedData.assignedDate.fromDate).format("YYYY-MM-DD")
        // payload.assignedDate.toDate = moment(selectedData.assignedDate.toDate).format("YYYY-MM-DD")
        const res = await getTaskReport(payload);
        if (res?.data?.data) {
          const column = res?.data?.data?.columnHeaders?.map((i, index) => {
            return {
              field: i.key,
              headerName: i.headerName,
              align: "left",
              headerAlign: "left",
              width: 200,
              ...(res?.data?.data?.columnHeaders?.length === index + 1 && {
                flex: 1,
                minWidth: 200,
              }),
            };
          });
          setColumns(column);
          setTableData(res?.data?.data?.data);
        }
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
  const handelReset = () => {
    setTableData([]);
    setSelectedData(initialState);
  };
  useEffect(() => {
    dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    // handelClearAll()
  }, []);
  useEffect(() => {
    if (location.pathname !== "/taskReport") handelReset();
  }, [location.pathname]);

  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      // setselectOptionData({ ...selectOptionData, projectList: project });
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = project;
        return t;
      });
    } else {
      // setselectOptionData({ ...selectOptionData, projectList: [] });
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = [];
        return t;
      });
    }
  }, [projectListDashboard]);
  const userAll = { firstname: "All", lastname: "", username: "All" };
  useEffect(() => {
    if (projectReleatedList?.jobList?.length > 0) {
      const taskListData = constructProjectData(projectReleatedList);
      // setselectOptionData({
      //     ...selectOptionData,
      //     jobList: taskListData.jobList,
      //     taskTypeList: taskListData.taskType,
      //     categoryList: taskListData.taskCategory,
      //     state: taskListData.state,
      //     status: taskListData.status
      // });
      setFilters((prev) => {
        const t = [...prev];
        // select job
        t[1].options = taskListData.jobList;
        // Select Category
        t[2].options = taskListData.taskCategory;
        // select task type
        t[3].options = taskListData.taskType;
        // Select  Status
        t[4].options = taskListData.status;
        // Select  State
        t[5].options = taskListData.state;
        // Select  Users
        t[6].options = [userAll, ...taskListData.userList];
        return t;
      });
      setSelectedData((prev) => ({ ...prev, users: [userAll] }));
    }
  }, [projectReleatedList]);
  return {
    filters,
    tableData,
    selectedData,
    onLoad,
    onDownload: () => onLoad("download"),
    columns,
    commonValueHeaders,
  };
};
export const useTaskStatusReport = () => {
  const location = useLocation();
  const {
    projectListDashboard,
    // jobList,
    projectReleatedList,
  } = store.getState().master;
  const { globapOrganization } = useSelector((state) => state.presist);
  const [selectedData, setSelectedData] = useState(initialState);
  const [apiloading, setApiLoading] = useState(false);
  // const [selectOptionData, setselectOptionData] = useState({
  //     projectList: [],
  //     jobList: [],
  //     taskTypeList: [],
  //     categoryList: [],
  //     excelExport: false,
  //     userList: [],
  //     state: [],
  //     status: []
  // })
  const columsAPI = async (e) => {
    const APIPayload = {
      jobCode: e,
    };
    const res = await getDisplayColumns(APIPayload);

    if (res) {
      setFilters((prev) => {
        const t = [...prev];
        // Select Display
        t[2].options = res;
        return t;
      });
    } else {
      setFilters((prev) => {
        const t = [...prev];
        // Select Display
        t[2].options = [];
        return t;
      });
    }
    return res;
  };

  const [filters, setFilters] = useState([
    {
      name: "Project*",
      field: "projectCode",
      options: [],
      label: "projectCode",
      value: "projectCode",
      showSelect: "Select",
      onChange: (e, selectedData) => {
        let code = e.target.value;
        setSelectedData((prev) => ({ ...initialState, projectCode: code }));
        setFilters((prev) => {
          const t = [...prev];
          // Select Job
          t[1].options = [];
          // Select Display
          t[2].options = [];
          return t;
        });
        if (code) {
          dispatch(
            getPojectReleatedListAction({
              projectCode: code,
              orgId: globapOrganization.orgId,
            })
          );
        }
      },
    },
    {
      name: "Job*",
      field: "jobCode",
      label: "jobCode",
      value: "jobInfo",
      showSelect: "Select",
      options: [],
      onChange: (e, selectedData) => {
        if (e.target.value) {
          const eventvalue = e.target.value;
          columsAPI(eventvalue);
          setSelectedData((prev) => ({ ...prev, columnHeader: ["All"] }));
          setSelectedData((prev) => ({ ...prev, jobCode: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, jobCode: "" }));
        }
      },
    },
    {
      name: "Display Header*",
      field: "columnHeader",
      options: [],
      type: "multiSelect",
      labelGetter: (data) => data,
      onChange: (e, v) => {
        if (v?.length > 0) {
          if (v.length > 1 && v.find((i) => i === "All")) {
            setSelectedData((prev) => {
              if (prev.columnHeader.find((i) => i === "All"))
                return { ...prev, columnHeader: v.filter((i) => i !== "All") };
              else
                return { ...prev, columnHeader: v.filter((i) => i === "All") };
            });
          } else setSelectedData((prev) => ({ ...prev, columnHeader: [...v] }));
        } else {
          setSelectedData((prev) => ({ ...prev, columnHeader: ["All"] }));
        }
      },
    },
    // {
    //     name: 'Display Colums*',
    //     field: 'columnHeader',
    //     options: [],
    //     onChange: (e, selectedData) => {
    //         if (e.target.value) { setSelectedData(prev => ({ ...prev, columnHeader: e.target.value })) }
    //         else {
    //             setSelectedData(prev => ({ ...prev, columnHeader: '' }))
    //         }
    //     }
    // }
  ]);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsRealData, setColumnsRealData] = useState([]);
  const commonValueHeaders = [
    {
      name: "Project Code",
      field: "projectCode",
    },
    {
      name: "Project Name",
      field: "projectName",
    },
    {
      name: "Job Code",
      field: "jobCode",
    },
  ];
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    width: 100,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "white",
    },
  }));
  const onLoad = async (download) => {
    try {
      // dispatch(loading(true))
      if (!selectedData.projectCode || !selectedData.jobCode) {
        dispatch(loading(false));
        return TriggerAlert("warning", "Kindly Select Project & Job");
      }
      selectedData.excelExport = false;
      const payload = { ...selectedData };
      payload.filterColumnHeaders = true;
      payload.category = null;
      payload.taskType = null;
      payload.users = null;
      payload.taskState = null;
      payload.taskStatus = null;
      payload.columnHeader = selectedData.columnHeader?.length
        ? selectedData.columnHeader?.length === 1 &&
          selectedData.columnHeader[0] === "All"
          ? "All"
          : selectedData.columnHeader.join(",")
        : "All";
      if (download === "download") {
        payload.export = true;
        payload.excelExport = true;
        dispatch(loading(true));
        await reportDownload(
          payload,
          "/report/task_report/",
          "post",
          `Task_Report_${payload.projectCode}.xlsx`
        );
        dispatch(loading(false));
      } else {
        setApiLoading(true);
        const res = await getTaskStatusReport(payload, location.pathname);
        if (res?.data?.data) {
          setApiLoading(false);
          setColumnsRealData(res?.data?.data?.columnHeaders);
          const column = res?.data?.data?.columnHeaders?.map((i, index) => {
            return {
              field: i.key,
              // headerName: i.headerName,
              renderHeader: () =>
                i?.type === "Object" ? (
                  <strong>{i.headerName}</strong>
                ) : (
                  i.headerName
                ),
              // headerClassName: i?.type === "Object" ? `super-app-theme--header${NUM = NUM + 1}` : "",
              align: i?.type === "Object" ? "center" : "left",
              headerAlign: i?.type === "Object" ? "center" : "left",
              // flex: i?.type === "Object" ? null : 1,
              maxWidth: i?.type === "Object" ? 200 : 350,
              width: i?.type === "Object" ? 200 : 350,
              renderCell: (param) =>
                i?.type === "Object" &&
                (param.row[i.key]?.status === "Assigned" ||
                  param.row[i.key]?.status === "Open" ||
                  param.row[i.key]?.status === "In-Progress" ||
                  param.row[i.key]?.status === "Revert Requested" ||
                  param.row[i.key]?.status === "End") ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color:
                            param.row[i.key].status === "Assigned"
                              ? taskStatusReportColorFontColor.Assigned
                              : param.row[i.key].status === "Open"
                              ? taskStatusReportColorFontColor.Open
                              : param.row[i.key].status === "In-Progress"
                              ? taskStatusReportColorFontColor.Progress
                              : param.row[i.key].status === "Revert Requested"
                              ? taskStatusReportColorFontColor.Revert
                              : param.row[i.key].status === "End"
                              ? taskStatusReportColorFontColor.End
                              : "black",
                          backgroundColor:
                            param.row[i.key].status === "Assigned"
                              ? taskStatusReportColor.Assigned
                              : param.row[i.key].status === "Open"
                              ? taskStatusReportColor.Open
                              : param.row[i.key].status === "In-Progress"
                              ? taskStatusReportColor.Progress
                              : param.row[i.key].status === "Revert Requested"
                              ? taskStatusReportColor.Revert
                              : param.row[i.key].status === "End"
                              ? taskStatusReportColor.End
                              : "red",
                          p: 0.5,
                          // width: "50%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            pr: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 100,
                              fontSize: 10,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                letterSpacing: "1px",
                              }}
                              title={param.row[i.key]?.assignedTo}
                            >
                              {" "}
                              {param.row[i.key]?.assignedTo === "Unassigned"
                                ? "Open"
                                : `${param.row[i.key]?.assignedTo}`}
                            </span>
                          </Box>
                          <Box sx={{ pt: 0.8 }}>
                            <BorderLinearProgress
                              sx={{
                                [`& .${linearProgressClasses.bar}`]: {
                                  borderRadius: 5,
                                  backgroundColor: "#4c566a",
                                },
                              }}
                              variant="determinate"
                              value={
                                (+param.row[i.key]?.completedCount /
                                  +param.row[i.key]?.measureCount) *
                                100
                              }
                            />
                          </Box>

                          {/* <Box sx={{ textAlign: "center", fontWeight: 'bold', fontSize: 12, }}>
                                            {val[el?.key]?.completedCount || 0}{" "}<span style={{ fontWeight: 700, color: 'black' }}>{"  /  "}</span>{" "} {val[el?.key]?.measureCount || 0}
                                         </Box> */}
                        </Box>
                        {/* <Divider flexItem variant="fullWidth" orientation="vertical" sx={{ color: "#ffffff", backgroundColor: 'black' }} /> */}
                      </Box>
                      <Box sx={{ width: 70 }}>
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: "white",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            overflow: "hidden",
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            pt: 1,
                            width: 70,
                            height: 40,
                            fontSize: 12,
                            backgroundColor: "#4c566a",
                          }}
                        >
                          <span
                            title={`${
                              param.row[i.key]?.completedCount || 0
                            }${" "}${"  /  "}${" "}${
                              param.row[i.key]?.measureCount || 0
                            }`}
                          >
                            {param.row[i.key]?.completedCount || 0}{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {"  /  "}
                            </span>{" "}
                            {param.row[i.key]?.measureCount || 0}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <span
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    title={param.row[i.key]}
                  >
                    {param.row[i.key]}
                  </span>
                ),
              ...(res?.data?.data?.columnHeaders?.length === index + 1 && {
                flex: 1,
                minWidth: 230,
              }),
            };
          });
          setColumns(column);
          setTableData(res?.data?.data?.data);
        }
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
  const handelReset = () => {
    setTableData([]);
    setColumnsRealData([]);
    // onLoad()
    setApiLoading(false);
    setSelectedData(initialState);
  };
  useEffect(() => {
    if (location.pathname !== "/taskStatusReport") handelReset();
    return () => {
      if (location.pathname !== "/taskStatusReport") handelReset();
    };
  }, [location.pathname]);
  // Page Auto Reload By the Task Status changes

  // useEffect(() => {
  //     if (autoLoad?.isAutoLoad) {
  //         if (selectedData.projectCode && selectedData.jobCode) {
  //             onLoad()
  //             dispatch(updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false, }, }));
  //         }
  //     }
  // }, [autoLoad])
  useEffect(() => {
    dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    // handelClearAll()
  }, []);
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      // setselectOptionData({ ...selectOptionData, projectList: project });
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = project;
        return t;
      });
    } else {
      // setselectOptionData({ ...selectOptionData, projectList: [] });
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = [];
        return t;
      });
    }
  }, [projectListDashboard]);
  const userAll = { firstname: "All", lastname: "", username: "All" };
  useEffect(() => {
    if (projectReleatedList?.jobList?.length > 0) {
      const taskListData = constructProjectData(projectReleatedList);
      // setselectOptionData({
      //     ...selectOptionData,
      //     jobList: taskListData.jobList,
      //     taskTypeList: taskListData.taskType,
      //     categoryList: taskListData.taskCategory,
      //     state: taskListData.state,
      //     status: taskListData.status
      // });
      setFilters((prev) => {
        const t = [...prev];
        // select job
        t[1].options = taskListData.jobList;
        return t;
      });
      setSelectedData((prev) => ({ ...prev, users: [userAll] }));
    }
  }, [projectReleatedList]);
  return {
    filters,
    tableData,
    selectedData,
    apiloading,
    onLoad,
    onDownload: () => onLoad("download"),
    columns,
    columnsRealData,
    commonValueHeaders,
  };
};

export const useProjectReport = () => {
  const location = useLocation();
  const { projectListDashboard } = store.getState().master;
  const [selectedData, setSelectedData] = useState({
    projectCode: "All",
    projectStatus: "All",
    excelExport: false,
    createdDate: {
      fromDate: null,
      toDate: null,
    },
  });
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([
    {
      field: "Project_Code",
      headerName: "Project Code",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "Project_Name",
      headerName: "Project Name",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "Scope_Of_Project",
      headerName: "Scope",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "Status",
      headerName: "Status",
      headerAlign: "left",
      width: 200,
      valueGetter: (data) =>
        data?.row?.Status === "Active" ? "Open" : "Completed",
    },
    {
      field: "Notes",
      headerName: "Notes",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      headerAlign: "left",
      valueGetter: (data) =>
        data?.row?.Created_On ? data?.row?.Created_On : "NA",
      flex: 1,
    },
  ]);
  const [filters, setFilters] = useState([
    {
      name: "Project",
      field: "projectCode",
      options: [],
      label: "projectCode",
      value: "projectCode",
      onChange: (e) => {
        let code = e.target.value;
        setSelectedData((prev) => ({ ...prev, projectCode: code }));
      },
    },
    {
      name: "Project Status",
      field: "projectStatus",
      options: [
        { name: "All", value: "All" },
        { name: "Open", value: "Active" },
        { name: "Completed", value: "Completed" },
      ],
      label: "name",
      value: "value",
      onChange: (e) => {
        let code = e.target.value;
        setSelectedData((prev) => ({ ...prev, projectStatus: code }));
      },
    },
    {
      name: "Created (From)",
      valueGetter: (data) => data["createdDate"]["fromDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: e ? e : null,
              toDate: null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: null,
              toDate: null,
            },
          }));
        }
      },
    },
    {
      name: "Created (To)",
      valueGetter: (data) => data["createdDate"]["toDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: e ? e : null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: null,
            },
          }));
        }
      },
      minDate: (data) => data["createdDate"]["fromDate"],
    },
  ]);
  const onLoad = async (download) => {
    try {
      dispatch(loading(true));
      const payload = { ...selectedData };
      payload.excelExport = false;
      if (download === "download") {
        payload.export = true;
        payload.excelExport = true;
        const downloadPayload = {
          projectCode: selectedData.projectCode,
          excelExport: true,
        };
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")
        await reportDownload(
          selectedData.projectCode === "All" ? payload : downloadPayload,
          "/report/project_detailed_report/",
          selectedData.projectCode === "All" ? "patch" : "post",
          `Project_Report_${payload.projectCode}.xlsx`
        );
      } else {
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")

        const res = await getProjectReportService(payload);
        console.log(
          res?.data?.data?.projectDetail,
          "res?.data?.data?.projectDetail"
        );
        setTableData(res?.data?.data?.projectDetail || []);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
  const handelReset = () => {
    setTableData([]);
    setSelectedData({
      projectCode: "All",
      projectStatus: "All",
      excelExport: false,
      createdDate: {
        fromDate: null,
        toDate: null,
      },
    });
  };
  useEffect(() => {
    if (location.pathname !== "/project_report") handelReset();
  }, [location.pathname]);
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = [{ projectCode: "All" }, ...project];
        return t;
      });
    } else {
      // setselectOptionData({ ...selectOptionData, projectList: [] });
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = [];
        return t;
      });
    }
  }, [projectListDashboard]);
  const commonValueHeaders = [];
  return {
    filters,
    tableData,
    selectedData,
    onLoad,
    onDownload: () => onLoad("download"),
    columns,
    commonValueHeaders,
  };
};

export const useJobReport = () => {
  const location = useLocation();
  const { projectListDashboard, projectReleatedList } = store.getState().master;

  const { globapOrganization } = useSelector((state) => state.presist);
  const [selectedData, setSelectedData] = useState({
    projectCode: "",
    jobCode: "All",
    jobStatus: "All",
    excelExport: false,
    createdDate: {
      fromDate: null,
      toDate: null,
    },
  });
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([
    {
      name: "Project *",
      field: "projectCode",
      options: [],
      label: "projectCode",
      value: "projectCode",
      showSelect: "Select",
      onChange: (e) => {
        let code = e.target.value;
        setSelectedData((prev) => ({ ...prev, projectCode: code }));
        if (code) {
          dispatch(
            getPojectReleatedListAction({
              projectCode: code,
              orgId: globapOrganization.orgId,
            })
          );
        }
      },
    },
    {
      name: "Job",
      field: "jobCode",
      label: "jobCode",
      value: "jobInfo",
      options: [],
      onChange: (e, selectedData) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, jobCode: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, jobCode: "" }));
        }
      },
    },
    {
      name: "Status",
      field: "status",
      // options: ['All','Open','In-Progress','Completed'],
      options: [
        { name: "All", value: "All" },
        { name: "Open", value: "Active" },
        { name: "In-Progress", value: "In-Progress" },
        { name: "Completed", value: "Completed" },
      ],
      label: "name",
      value: "value",
      onChange: (e, selectedData) => {
        if (e.target.value) {
          setSelectedData((prev) => ({ ...prev, jobStatus: e.target.value }));
        } else {
          setSelectedData((prev) => ({ ...prev, jobStatus: "" }));
        }
      },
    },
    {
      name: "Created (From)",
      valueGetter: (data) => data["createdDate"]["fromDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: e ? e : null,
              toDate: null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              fromDate: null,
              toDate: null,
            },
          }));
        }
      },
    },
    {
      name: "Created (To)",
      valueGetter: (data) => data["createdDate"]["toDate"],
      type: "date",
      onChange: (e) => {
        if (e) {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: e ? e : null,
            },
          }));
        } else {
          setSelectedData((prev) => ({
            ...prev,
            createdDate: {
              ...prev.createdDate,
              toDate: null,
            },
          }));
        }
      },
      minDate: (data) => data["createdDate"]["fromDate"],
    },
  ]);
  const [columns, setColumns] = useState([
    {
      field: "Project_Code",
      headerName: "Project Code",
      // align: "left",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "Project_Name",
      headerName: "Project Name",
      // align: "left",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "Job_Code",
      headerName: "Job Code",
      // align: "left",
      headerAlign: "left",
      width: 200,
    },

    {
      field: "receivedDate",
      headerName: "Recevied Date",
      // align: "left",
      headerAlign: "left",
      valueGetter: (data) =>
        data?.row?.Received_Date
          ? moment(data?.row?.Received_Date, "DD-MM-YYYY").format("DD-MM-YYYY")
          : "NA",
      width: 200,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      // align: "left",
      headerAlign: "left",
      valueGetter: (data) =>
        data?.row?.Due_Date
          ? moment(data?.row?.Due_Date, "DD-MM-YYYY").format("DD-MM-YYYY")
          : "NA",
      width: 200,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      // align: "left",
      headerAlign: "left",
      // width: 200,
      width: 200,
      valueGetter: (data) =>
        data?.row?.Created_On ? data?.row?.Created_On : "NA",
    },
    {
      field: "Status",
      headerName: "Status",
      // align: "left",
      headerAlign: "left",
      valueGetter: (data) =>
        data?.row?.Status === "Active" ? "Open" : data?.row?.Status,
      flex: 1,
    },
  ]);
  const onLoad = async (download) => {
    if (!selectedData.projectCode) {
      dispatch(loading(false));
      return TriggerAlert("warning", "Kindly Select Project");
    }
    try {
      dispatch(loading(true));
      const payload = { ...selectedData };
      payload.excelExport = false;
      if (download === "download") {
        payload.export = true;
        payload.excelExport = true;
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")
        await reportDownload(
          payload,
          "/report/job_detailed_report/",
          "post",
          `Job_Report_${payload.projectCode}.xlsx`
        );
      } else {
        // payload.createdDate.fromDate = moment(selectedData.createdDate.fromDate).format("YYYY-MM-DD")
        // payload.createdDate.toDate = moment(selectedData.createdDate.toDate).format("YYYY-MM-DD")
        const res = await getJobReportService(payload);
        setTableData(res?.data?.data?.jobDetail || []);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
  const handelReset = () => {
    setTableData([]);
    setSelectedData({
      projectCode: "",
      jobCode: "All",
      jobStatus: "All",
      excelExport: false,
      createdDate: {
        fromDate: null,
        toDate: null,
      },
    });
  };
  useEffect(() => {
    if (location.pathname !== "/job_report") handelReset();
  }, [location.pathname]);
  // const onDownload = async () => {
  //     try {
  //         dispatch(loading(true))
  //         if (!selectedData.projectCode || !selectedData.jobCode) {
  //             dispatch(loading(false))
  //             return TriggerAlert("warning", "Kindly Select Project & Job");
  //         }
  //         selectedData.excelExport = true
  //         const res = await reportDownload(selectedData, "/report/job_detailed_report/", "post")
  //         if (res) {
  //         }
  //         dispatch(loading(false))
  //     } catch (error) {
  //         dispatch(loading(false))
  //     }
  // }
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = project;
        return t;
      });
    } else {
      setFilters((prev) => {
        const t = [...prev];
        // Select Project
        t[0].options = [];
        return t;
      });
    }
  }, [projectListDashboard]);
  useEffect(() => {
    if (projectReleatedList?.jobList?.length > 0) {
      const taskListData = constructProjectData(projectReleatedList);
      setFilters((prev) => {
        const t = [...prev];
        // select job
        t[1].options = [{ jobCode: "All" }, ...taskListData.jobList];
        return t;
      });
    }
  }, [projectReleatedList]);
  const commonValueHeaders = [];
  return {
    filters,
    tableData,
    selectedData,
    onLoad,
    onDownload: () => onLoad("download"),
    columns,
    commonValueHeaders,
  };
};
