import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  generalTaskAction,
  generalTaskMapAction,
  getAllProj_Dep_Team_UserAction,
  getGeneralTaskMappedDataAction,
} from "../../service/action/action";
import { createandEditRole } from "../../service/roleService";
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { allpageFontStyle } from "../../utils/FontStyle";

const schema = yup.object().shape({
  generalTaskId: yup.object().nullable().required("Required"),
  type: yup.object().nullable().required("Required"),
  project: yup.array(),
  department: yup.array(),
  team: yup.array(),
  user: yup.array(),
  role: yup.array(),
  // project: yup.array().when("type", ([val], schema) => {
  //     if (val?.value === 'Project') return yup.array().min(1, 'Required')
  //     else return yup.array()
  // }),
  // department: yup.array().when("type", ([val], schema) => {
  //     if (val?.value === 'Department') return yup.array().min(1, 'Required')
  //     else return yup.array()
  // }),
  // team: yup.array().when("type", ([val], schema) => {
  //     if (val?.value === 'Team') return yup.array().min(1, 'Required')
  //     else return yup.array()
  // }),
  // user: yup.array().when("type", ([val], schema) => {
  //     if (val?.value === 'User') return yup.array().min(1, 'Required')
  //     else return yup.array()
  // }),
});

const GeneralTaskMapScreen = () => {
  const location = useLocation();
  const form = useForm({
    defaultValues: {
      generalTaskId: null,
      type: null,
      project: [],
      department: [],
      team: [],
      user: [],
      role: [],
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = form;
  const onSubmit = (data) => {
    const payload = {};
    payload.generalTaskId = data.generalTaskId?.value;
    // payload.project = data.project?.map(i => i.value);
    // payload.department = data.department?.map(i => i.value);
    // payload.team = data.team?.map(i => i.value);
    payload.user = data.user?.map((i) => i.value);
    payload.role = data.role?.map((i) => i.value);
    payload.selectType = data.type?.value;
    console.log(data, "sdjhfjhsde");
    generalTaskMapAction(payload);
  };
  const [data, setData] = useState([]);
  const [dropdownData, setDropDownData] = useState({
    project: [],
    department: [],
    team: [],
    user: [],
    role: [],
  });
  const mapTo = [
    { label: "Role", value: "ROLE" },
    { label: "User", value: "USER" },
  ];
  const setAllData = (dataList) => {
    const projectData = dataList?.data?.projectInfo.map((i) => ({
      label: i.projectName,
      value: i.id,
    }));
    const depData = dataList?.data?.departmentInfo.map((i) => ({
      label: i.depName,
      value: i.id,
    }));
    const teamData = dataList?.data?.teamInfo.map((i) => ({
      label: i.teamName,
      value: i.id,
    }));
    const userData = dataList?.data?.userInfo.map((i) => ({
      label: `${i.firstname} ${i.lastname}`,
      value: i.username,
    }));
    setDropDownData({
      project: projectData,
      department: depData,
      team: teamData,
      user: userData,
    });
    if (location.state)
      onEditFun.editOtherData(
        projectData,
        depData,
        teamData,
        userData,
        location.state
      );
  };
  const onEditFun = {
    editGeneralTask: (data, editData) => {
      const t = data?.find((i) => i.value === editData?.taskId);
      if (t) setValue("generalTaskId", t);
    },
    editOtherData: (projectData, depData, teamData, userData, editData) => {
      if (editData?.data?.mappedProject) {
        const pro = projectData?.filter((i) =>
          editData?.data?.mappedProject?.find((j) => j.id === i.value)
        );
        setValue("type", { label: "Project", value: "Project" });
        setValue("project", pro);
      } else if (editData?.data?.mappedDepartment) {
        const dep = depData?.filter((i) =>
          editData?.data?.mappedDepartment?.find((j) => j.id === i.value)
        );
        setValue("type", { label: "Department", value: "Department" });
        setValue("department", dep);
      } else if (editData?.data?.mappedTeam) {
        const team = teamData?.filter((i) =>
          editData?.data?.mappedTeam?.find((j) => j.id === i.value)
        );
        setValue("type", { label: "Team", value: "Team" });
        setValue("team", team);
      } else if (editData?.data?.mappedUsers) {
        const user = userData?.filter((i) =>
          editData?.data?.mappedUsers?.find((j) => j.username === i.value)
        );
        setValue("type", { label: "User", value: "User" });
        setValue("user", user);
      }
    },
  };
  const { globapOrganization } = useSelector((state) => state.presist);
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {
    generalTaskAction("get", " ", "?isActive=True").then((res) => {
      if (res) {
        const constructedData = res?.data?.data?.map((i) => ({
          label: i?.generalTaskInfo?.taskName,
          value: i?.generalTaskInfo?.id,
        }));
        console.log(constructedData, "constructedData");
        setData(constructedData);
        if (location.state)
          onEditFun.editGeneralTask(constructedData, location.state);
      }
    });
    getAllProj_Dep_Team_UserAction(globapOrganization?.orgId).then((res) => {
      if (res) setAllData(res?.data);
    });
    createandEditRole("get", {}).then((res) => {
      if (res) {
        const t = res?.data?.data?.map((i) => ({ label: i.name, value: i.id }));
        setRoleList(t);
      } else {
        setRoleList([]);
      }
    });
  }, []);
  const onEdit = (editData) => {
    console.log(editData, "editDataeditDataeditData");
    if (editData?.data?.mappedProject) {
      const pro = editData?.data?.mappedProject?.map((i) => ({
        label: i.projectName,
        value: i.id,
      }));
      setValue("type", { label: "Project", value: "Project" });
      setValue("project", pro);
    } else if (editData?.data?.mappedDepartment) {
      const dep = editData?.data?.mappedDepartment?.map((i) => ({
        label: i.depName,
        value: i.id,
      }));
      setValue("type", { label: "Department", value: "Department" });
      setValue("department", dep);
    } else if (editData?.data?.mappedTeam) {
      const team = editData?.data?.mappedTeam?.map((i) => ({
        label: i.teamName,
        value: i.id,
      }));
      setValue("type", { label: "Team", value: "Team" });
      setValue("team", team);
    } else if (editData?.data?.mappedUser?.length && editData.type === "USER") {
      const user = editData?.data?.mappedUser?.map((i) => ({
        label: `${i.firstname} ${i.lastname}`,
        value: i.username,
      }));
      setValue("type", { label: "User", value: "USER" });
      setValue("user", user);
    } else if (editData?.data?.mappedRole?.length && editData.type === "ROLE") {
      const role = editData?.data?.mappedRole?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      setValue("type", { label: "Role", value: "ROLE" });
      setValue("role", role);
    }
    trigger("type");
  };
  useEffect(() => {
    if (location.state?.data) onEdit(location.state);
  }, [location.state]);

  return (
    <Box flex={1} sx={{ ...formContainerStyle, minWidth: 800 }}>
      <Box sx={{ width: "100%" }}>
        <Card sx={formContainerCardStyle}>
          <FormProvider {...form}>
            <form
              style={{
                height: "100%",
                paddingBottom: 20,
                paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container p={3} px={5} spacing={2} width={"50%"}>
                <Grid item lg={12} md={12} xs={12}>
                  <Controller
                    control={control}
                    name="generalTaskId"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormControl
                        error={errors?.generalTaskId?.message}
                        size="small"
                        fullWidth
                        sx={{ pt: 0.5 }}
                      >
                        <Autocomplete
                          fullWidth
                          id="select-task"
                          options={data || []}
                          error={errors?.generalTaskId?.message}
                          onChange={(e, v) => {
                            setValue("project", []);
                            setValue("department", []);
                            setValue("role", []);
                            setValue("user", []);
                            setValue("type", null);
                            onChange(v);
                          }}
                          value={value}
                          size="small"
                          inputRef={ref}
                          inputProps={{
                            "aria-label": "Project Code",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Task *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.generalTaskId?.message}
                              helperText={errors?.generalTaskId?.message}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormControl
                        error={errors?.type?.message}
                        size="small"
                        fullWidth
                        sx={{ pt: 0.5 }}
                      >
                        <Autocomplete
                          fullWidth
                          id="select-type"
                          options={mapTo}
                          error={errors?.type?.message}
                          onChange={(e, v) => {
                            onChange(v);
                            setValue("project", []);
                            setValue("department", []);
                            setValue("role", []);
                            setValue("user", []);
                            trigger("type");
                            console.log(v, "wefdwefe");

                            const t = getValues("generalTaskId");
                            if (t && v) {
                              getGeneralTaskMappedDataAction(t?.value).then(
                                (res) => {
                                  onEdit({
                                    data: res?.data?.data,
                                    type: v?.value,
                                  });
                                }
                              );
                            }
                          }}
                          value={value}
                          size="small"
                          inputRef={ref}
                          inputProps={{
                            "aria-label": "Project Code",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Allocate To *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.type?.message}
                              helperText={errors?.type?.message}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                {getValues("type") ? (
                  <Grid item lg={12} md={12} xs={12}>
                    <Controller
                      control={control}
                      name={
                        getValues("type")?.value === "ROLE"
                          ? "role"
                          : getValues("type")?.value === "Department"
                          ? "department"
                          : getValues("type")?.value === "Team"
                          ? "team"
                          : "user"
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl
                          error={
                            getValues("type")?.value === "ROLE"
                              ? errors.role?.message
                              : getValues("type")?.value === "Department"
                              ? errors.department?.message
                              : getValues("type")?.value === "Team"
                              ? errors.team?.message
                              : errors.user?.message
                          }
                          size="small"
                          fullWidth
                          sx={{ pt: 0.5 }}
                        >
                          <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            multiple
                            id="select-project"
                            options={
                              getValues("type")?.value === "ROLE"
                                ? roleList
                                : getValues("type")?.value === "Department"
                                ? dropdownData.department
                                : getValues("type")?.value === "Team"
                                ? dropdownData.team
                                : dropdownData.user
                            }
                            error={
                              getValues("type")?.value === "ROLE"
                                ? errors.role?.message
                                : getValues("type")?.value === "Department"
                                ? errors.department?.message
                                : getValues("type")?.value === "Team"
                                ? errors.team?.message
                                : errors.user?.message
                            }
                            onChange={(e, v) => onChange(v)}
                            value={
                              getValues("type")?.value === "ROLE"
                                ? getValues("role")
                                : getValues("type")?.value === "Department"
                                ? getValues("department")
                                : getValues("type")?.value === "Team"
                                ? getValues("team")
                                : getValues("user")
                            }
                            size="small"
                            inputRef={ref}
                            inputProps={{
                              "aria-label": "Project Code",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  getValues("type")?.value === "ROLE"
                                    ? "Role"
                                    : getValues("type")?.value === "Department"
                                    ? "Department"
                                    : getValues("type")?.value === "Team"
                                    ? "Team"
                                    : "User"
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  getValues("type")?.value === "ROLE"
                                    ? errors.role?.message
                                    : getValues("type")?.value === "Department"
                                    ? errors.department?.message
                                    : getValues("type")?.value === "Team"
                                    ? errors.team?.message
                                    : errors.user?.message
                                }
                                helperText={
                                  getValues("type")?.value === "ROLE"
                                    ? errors.role?.message
                                    : getValues("type")?.value === "Department"
                                    ? errors.department?.message
                                    : getValues("type")?.value === "Team"
                                    ? errors.team?.message
                                    : errors.user?.message
                                }
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid
                  display={"flex"}
                  alignSelf={"center"}
                  justifyContent={"center"}
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Button
                    sx={{
                      color: "white",
                      width: 90,
                      m: { xs: 1, md: 1, lg: 2 },
                      ...allpageFontStyle.buttonFontStyle2,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                      borderRadius: 5,
                    }}
                    size="small"
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Submit
                  </Button>
                  {Object.values(errors)?.length ? (
                    <Typography
                      variant="subtitle2"
                      sx={{ visibility: "hidden" }}
                    >
                      hidden
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Card>
      </Box>
    </Box>
  );
};

export default GeneralTaskMapScreen;
