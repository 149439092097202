import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, CircularProgress, Paper, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch } from "react-redux";
import { alertPopup } from "../../components/popup/ConformPopup";
import { updateProfileStates } from "../../redux/reducers/profileReducer";
import { useSelector } from "react-redux";
import { PopupMessage, secondsToHMS } from "../../utils/UIUtil";
import store from "../../redux/store";
import { sendSplitCompletedCountRequest } from "../../service/taskService";
import { Cancel, Check } from "@mui/icons-material";
import { getTimeline } from "../../service/masterService";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { allpageFontStyle } from "../../utils/FontStyle";
import PopupComponent from "../../components/commonComponents/PopupComponent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: 500,
    minWidth: 500,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, ...allpageFontStyle.title }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SplitDialog({
  splitField,
  handleSubmit,
  paperTitle,
  splitData,
  MainTask,
  TableValues,
  UpcomingTasks,
  selectedRow,
  handleClickOpen,
  reset,
  addArray,
  getSplitDetail,
  setSelectedRow,
  setsplitField,
  prodHrCal,
  setIsEmptyError,
}) {
  const isShowComplete =
    selectedRow?.state === "Paused" || selectedRow?.state === "Resumed";
  console.log(selectedRow?.state, isShowComplete, ";ghcghcgh");
  const [open, setOpen] = React.useState(false);
  const { balance } = useSelector((state) => state.profile);
  const { splitCompletedCountUpdated } = useSelector((state) => state.presist);
  const dispatch = useDispatch();
  const [splitCount, setSplitCount] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [isError1, setIsError1] = React.useState(false);
  const [completedCount, setCompletedCount] = React.useState(0);
  const [disableComplete, setDisableComplete] = React.useState(false);
  const [load, setLoad] = React.useState(0);
  const getUpdatedSelectedRow = () => {
    const selectedRow = store.getState().profile.selectedRow;
    return selectedRow;
  };
  // const [selectedRow,setSelectedRow] = React.useState({})
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
    } else if (alertPopup.type === PopupMessage.resetType) {
      handleClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  React.useEffect(() => {
    if (open?.state === "Paused" || open?.state === "Resumed")
      setCompletedCount(open?.completedCount);
  }, [open]);
  const handleClickOpen2 = () => {
    if (selectedRow?.measureCount < 2) {
      return alertPopup(`Sorry, can't split if count is less than 2 pages `);
    }
    getSplitDetail();
    setOpen(selectedRow);
  };
  const handleClose = () => {
    setIsError1(false);
    setSelectedRow(null);
    setCompletedCount(0);
    setDisableComplete(false);
    setOpen(false);
    setSplitCount("");
    setIsError(false);
    setsplitField([]);
    reset();
    setLoad(0);
    dispatch(
      updateProfileStates({
        name: "splitData",
        value: null,
      })
    );
    dispatch(
      updateProfileStates({
        name: "balance",
        value: null,
      })
    );
    dispatch(
      updatePresistStates({ name: "autoLoad", value: { isAutoLoad: true } })
    );
  };
  const [isSplit, setIsSplit] = React.useState(false);
  const autoSplit = async () => {
    setIsSplit(true);
    const balanceCount = disableComplete
      ? selectedRow?.measureCount - completedCount
      : selectedRow?.measureCount;
    try {
      if (isShowComplete && (!(completedCount >= 0) || completedCount === ""))
        return setIsError1("Kindly Enter Completed Count");
      if (splitCount >= balanceCount || !splitCount) return setIsError(true);
      else {
        const total = +balanceCount;
        const noOfSplit = Math.floor(total / +splitCount);
        const balance = total % +splitCount;
        if (noOfSplit > 0) {
          const countArr = [];
          for (let i = 1; i <= noOfSplit; i++) {
            if (i === noOfSplit && balance > 0) {
              if (balance <= 10) countArr.push(+splitCount + +balance);
              else {
                countArr.push(splitCount);
                countArr.push(balance);
              }
            } else countArr.push(splitCount);
          }

          addArray(null, null, countArr, completedCount, true);
        } else return setIsError(true);
      }
    } catch (error) {}
    setIsSplit(false);
  };
  const disableSplit = () => {
    if (isShowComplete && !disableComplete) {
      if (!disableComplete) return true;
      if (completedCount < 0 || completedCount === "") return true;
      else return true;
    } else return false;
  };
  const updateCompletedCount = (count) => {
    let t1 = {
      ...selectedRow,
      measureCount: count,
      productionHours: prodHrCal(count, selectedRow),
    };
    setSelectedRow(t1);
  };
  React.useEffect(() => {
    if (splitData && open) handleClickOpen();
  }, [splitData, open]);
  const [total, setTotal] = React.useState(0);
  const [ismisMatch, setIsMismatch] = React.useState(false);
  React.useEffect(() => {
    const totalCount = splitField?.reduce(
      (total, data) => total + +data?.measureCount,
      0
    );
    if (totalCount === getUpdatedSelectedRow()?.measureCount)
      setIsMismatch(false);
    setTotal(totalCount);
  }, [splitField]);
  React.useEffect(() => {
    if (
      splitCompletedCountUpdated?.taskId === selectedRow?.taskId &&
      isShowComplete &&
      load === 2
    ) {
      getTimeline({
        jobCode: selectedRow.jobCode,
        taskId: selectedRow.taskId,
      }).then((res) => {
        if (res?.data) {
          const completedCount = res.data?.TaskInfo?.[0]?.completedCount;
          // updateCompletedCount(completedCount);
          setCompletedCount(completedCount);
          setLoad(3);
        }
      });
      dispatch(
        updatePresistStates({
          name: "splitCompletedCountUpdated",
          value: null,
        })
      );
    }
  }, [splitCompletedCountUpdated]);
  return (
    <div>
      <Box sx={{ p: 0 }}>
        <Box>
          <Chip
            sx={{ ...allpageFontStyle.buttonFontStyle2 }}
            clickable={true}
            onClick={handleClickOpen2}
            label={"Split"}
            variant="filled"
          />
        </Box>
      </Box>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(open)}
        maxWidth="xl"
        // fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Split {open?.taskId}
        </BootstrapDialogTitle>
        <DialogContent sx={{ overflow: "hidden" }} dividers>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Box sx={{ width: "100%", display: "flex", mb: 2 }}>
              <Typography
                sx={{ ...allpageFontStyle.typography1 }}
                justifySelf={"flex-start"}
              >
                Total {selectedRow?.unitType} : &nbsp;
                {selectedRow?.measureCount}
              </Typography>
              <Typography
                ml={2}
                sx={{ ...allpageFontStyle.typography1 }}
                justifySelf={"flex-start"}
              >
                Total Hours : &nbsp;{secondsToHMS(selectedRow?.productionHours)}
              </Typography>
              {/* {isShowComplete ?
                <>
                  <Typography variant="body2" fontSize={'0.7rem'} justifySelf={'flex-start'}>, Completed {selectedRow?.unitType} : {completedCount || 'NA'}</Typography>
                  <Typography variant="body2" fontSize={'0.7rem'} justifySelf={'flex-start'}>, Remaining {selectedRow?.unitType} to split : {selectedRow?.measureCount - completedCount}
                  </Typography>
                </>
                : null} */}
            </Box>
            {isShowComplete ? (
              <>
                {!disableComplete ? (
                  <Box
                    mb={2}
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* {load === 0 ? */}
                    {load === 3 ? (
                      <Typography
                        variant="subtitle2"
                        color={"green"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Check
                          fontSize="small"
                          sx={{
                            mr: 1,
                            color: "green",
                            ...allpageFontStyle.typography1,
                          }}
                        />{" "}
                        Completed Count updated by user{" "}
                      </Typography>
                    ) : null}
                    {load === 2 ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Check
                          fontSize="small"
                          sx={{
                            mr: 1,
                            color: "green",
                            ...allpageFontStyle.typography1,
                          }}
                        />
                        <Typography mr={1} variant="subtitle2" color={"green"}>
                          Request sent.
                        </Typography>
                        <Typography variant="subtitle2" color={"green"}>
                          waiting for user to respond...
                          <CircularProgress size={11} />
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"error"}
                          sx={{ display: "flex", alignItems: "center", ml: 2 }}
                        >
                          <Cancel
                            onClick={() => setLoad(0)}
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        </Typography>
                      </Box>
                    ) : load === 0 ? (
                      <>
                        <Typography
                          mr={1}
                          textAlign={"left"}
                          variant="subtitle2"
                          sx={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            ...allpageFontStyle.typography1,
                          }}
                          onClick={async () => {
                            if (load != 1) {
                              try {
                                setLoad(1);
                                const taskData = getUpdatedSelectedRow();
                                console.log(taskData, "gthdgthdghgdgf");
                                const payload = {
                                  projectCode: taskData?.jobInfo?.projectCode,
                                  jobCode: taskData?.jobInfo?.jobCode,
                                  taskId: taskData?.taskId,
                                  assignedTo: taskData?.assignedToId,
                                  request_type: "TASK_SPLIT_REQUEST",
                                };
                                sendSplitCompletedCountRequest(payload).then(
                                  (res) => {
                                    if (res?.data) setLoad(2);
                                    else setLoad(0);
                                  }
                                );
                              } catch (error) {
                                setLoad(0);
                              }
                            }
                          }}
                        >
                          Send request to user to update completed count
                        </Typography>
                        {load === 1 ? <CircularProgress size={15} /> : null}
                      </>
                    ) : null}
                  </Box>
                ) : null}
                <Box
                  mb={2}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // ml: 4
                  }}
                >
                  <>
                    <TextField
                      size="small"
                      sx={{
                        mr: 2,
                        width: "50%",
                      }}
                      value={completedCount}
                      label={`Updated Complete ${selectedRow?.unitType}*`}
                      disabled={disableComplete}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          padding: 5,
                          paddingLeft: 10,
                        },
                      }}
                      onChange={(e) => {
                        const regex = /^\d+$/;
                        if (
                          regex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setIsError1(false);
                          setCompletedCount(e.target.value);
                          if (+selectedRow?.measureCount - +e.target.value <= 0)
                            return setIsError1(
                              `* Remaining ${selectedRow?.unitType} to split  should be more than 0 `
                            );
                        }
                      }}
                      error={isError1}
                      helperText={isError1}
                    />
                    <Button
                      size="small"
                      disabled={disableComplete || load === 2}
                      variant="contained"
                      sx={{
                        backgroundColor: "#258d4d",
                        ":hover": { backgroundColor: "#258d4d" },
                        color: "white",
                        borderRadius: 5,
                        ...allpageFontStyle.buttonFontStyle2,
                      }}
                      onClick={() => {
                        const balance =
                          selectedRow?.measureCount - completedCount;
                        if (completedCount >= 0 && balance > 0) {
                          setDisableComplete(true);
                          updateCompletedCount(balance);
                          // let t = [...splitField];
                          // let tObj = { ...t[0], measureCount: balance, productionHours: prodHrCal(balance, selectedRow) };
                          // t.splice(0, 1, tObj)
                          // setsplitField(t);
                        }
                      }}
                    >
                      Confirm
                    </Button>
                  </>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </Box>
              </>
            ) : null}
            <Box
              mb={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                sx={{
                  mr: 2,
                  width: "50%",
                }}
                value={splitCount}
                label={`Split By ${selectedRow?.unitType}`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: 5,
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  const regex = /^\d+$/;
                  if (regex.test(e.target.value) || e.target.value === "") {
                    setIsError(false);
                    setSplitCount(e.target.value);
                  }
                }}
                disabled={completedCount >= selectedRow.measureCount}
                error={isError}
                helperText={isError ? "Enter Valid Split Count" : ""}
              />
              <Button
                size="small"
                sx={{
                  borderRadius: 5,
                  ...allpageFontStyle.buttonFontStyle2,
                  textTransform: "capitalize",
                }}
                disabled={disableSplit() || splitField?.length > 1}
                variant="contained"
                onClick={autoSplit}
              >
                Auto Split
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ ...allpageFontStyle.typography1 }}
                variant="subtitle2"
              >
                {selectedRow?.unitType} to Split:{" "}
                {getUpdatedSelectedRow()?.measureCount}{" "}
              </Typography>
              <Typography
                sx={{ ...allpageFontStyle.typography1 }}
                variant="subtitle2"
              >
                Entered: {total}{" "}
              </Typography>
              <Typography
                sx={{ ...allpageFontStyle.typography1 }}
                variant="subtitle2"
              >
                Remaining: {getUpdatedSelectedRow()?.measureCount - total}{" "}
              </Typography>
            </Box>
            {ismisMatch ? (
              <Typography
                variant="subtitle2"
                mb={1}
                textAlign={"center"}
                sx={{ background: "red", width: "100%" }}
                color={"white"}
              >
                Total {selectedRow?.unitType} mismatch !{" "}
              </Typography>
            ) : null}
            <Paper
              elevation={0}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                // width: "90%",
                minWidth: "100%",
                background:
                  "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);",
                position: "sticky",
              }}
            >
              <Box sx={{ width: "10%", minWidth: "10%", ...paperTitle }}>
                S.No
              </Box>
              <Box sx={{ width: "27%", minWidth: "27%", ml: 1, ...paperTitle }}>
                Count
              </Box>
              <Box sx={{ width: "10%", minWidth: "10%", ml: 1, ...paperTitle }}>
                Range
              </Box>
              <Box sx={{ width: "30%", minWidth: "10%", ...paperTitle }}>
                Hours
              </Box>

              <Box sx={{ width: "30%", minWidth: "35%", ...paperTitle }}>
                Action
              </Box>
            </Paper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "90%",
                minWidth: "90%",
                overflowY: "auto",
                height: "100%",
                maxHeight: 650,
                pb: 20,
              }}
            >
              {/* <MainTask selectedRow={selectedRow} /> */}
              {splitField?.map((task, index, taskarr) => (
                <>
                  <TableValues
                    task={task}
                    taskArr={taskarr}
                    index={index}
                    total={total}
                    measureCount={getUpdatedSelectedRow()?.measureCount}
                    disabled={disableSplit()}
                    completedCount={completedCount}
                  />
                </>
              ))}
              {/* {splitData?.data?.length ? <Typography mt={2} fontWeight={'bold'} textAlign={'center'} >Upcoming Tasks</Typography> : null}
              {

                splitData?.data?.map((task, index) => (

                  <UpcomingTasks task={task} />
                ))
              } */}
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            p: 0.5,
          }}
        >
          {splitField && splitField.length > 1 ? (
            <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
              <Button
                size="small"
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,
                  // p: 0,
                  // px: 2,
                  ...allpageFontStyle.buttonFontStyle2,
                  fontWeight: "bold",
                  backgroundColor: "#258d4d",
                  ":hover": { backgroundColor: "#258d4d" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                color="success"
                variant="contained"
                onClick={() => {
                  console.log(splitField, "sefwef");
                  setIsEmptyError(true);
                  if (total === getUpdatedSelectedRow()?.measureCount) {
                    handleSubmit(completedCount, handleClose, selectedRow);
                  } else setIsMismatch(true);
                }}
                autoFocus
              >
                Submit
              </Button>
            </Box>
          ) : null}
          <Button
            size="small"
            sx={{
              color: "white",
              // width: '10%',
              height: 30,
              // p: 0,
              // px: 2,
              ...allpageFontStyle.buttonFontStyle2,
              fontWeight: "bold",
              borderRadius: 5,
              textTransform: "capitalize",
            }}
            variant="contained"
            color="error"
            autoFocus
            onClick={() => {
              setAlertPopup((per) => ({
                ...per,
                open: true,
                type: PopupMessage.resetType,
                title: PopupMessage.reset,
              }));
            }}
          >
            Cancel
          </Button>
        </Box>
      </BootstrapDialog>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </div>
  );
}
