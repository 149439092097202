import { yupResolver } from "@hookform/resolvers/yup";
import {
  BorderColor,
  CloseRounded,
  ContentCopy,
  Done,
  Edit,
  LockOpen,
  LockReset,
  Opacity,
  RestartAlt,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { GridActionsCellItem } from "@mui/x-data-grid";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { enGB } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";
import {
  SuperAdminPasswordReset,
  checkEmpEmail,
  getGradeMappedDesignation,
} from "../../service/employeeService";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import {
  DownloadUserList,
  EmployeeServiceAction,
  TaskAssignExcelUploadAction,
  departmentMasterAction,
  designationMasterAction,
  getEmployeeServiceAction,
  getGradeByDesigAction,
  gradeMasterAction,
} from "../../redux/action/action";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { ResetUserPassword } from "../../service/authService";
import { ImageTag, imageImports } from "../../utils/Imageimport";
import {
  DateFieldsStyle,
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
  textFieldsStyle,
} from "../../utils/Style";
import {
  CustomButton,
  PopupMessage,
  TriggerAlert,
  checkBoxFilter,
  loading,
  removeDuplicatesFromArray,
} from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import ViewPopUp from "../../utils/viewPopUp";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PopupComponent from "../../components/commonComponents/PopupComponent";

const schema = yup.object().shape({
  // username: yup.string(),
  empId: yup.string().required("Employee Id Required"),
  firstname: yup.string().required("Firstname Required"),
  lastname: yup.string().required("Lastname Required"),
  extensionNo: yup.string().nullable(),
  landLineNo: yup
    .string()
    .min(12, "Contact No must contain 11 digits")
    // .required("Landline Number Required")
    // .matches(/^\d{3}-\d{8}$/, "Should be in format 044-12345678")
    .nullable(),

  emailAddress: yup
    .string()
    .email("Enter valid email Id")
    .required(" Email Id required"),
  DOB: yup
    .string()
    // .date("DOB is required")
    // .max(moment(new Date("2005-01-01")).format("YYYY-MM-DD"), "Date cannot be in the feature")
    .required("DOB is required"),
  mobileNumber: yup
    .string()
    .min(10, "Mobile No must contain 10 digits")
    .nullable(),
  // yup
  //   .string().when("mobileNumber",(value, schema)=>{
  //     if(value[0]) return yup.string().min(10, "Mobile No must contain 10 digits")
  //     else return yup.string().nullable
  //   }),
  // .min(10, "Mobile No must contain 10 digits").nullable(),
  // .required("Mobile Number Required"),
  depId: yup.string().required("Please select Department"),
  gender: yup.string().required("Please select Gender"),
  desigId: yup.string().required("Please select Designation"),
  gradeId: yup.string().required("Select designaton for grade"),
  superUser: yup.boolean(),
});
export default function CreateUser() {
  const { designationList, gradeList, departmentList, grades } = useSelector(
    (state) => state.master
  );
  const passwordResetInisialState = {
    sendmailOption: false,
    autogenaratePassword: "",
    resetpasswordUserDetails: {},
    passwordMessage: "",
  };
  const { employeeList } = useSelector((state) => state.profile);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [editpage, setEditPage] = React.useState(false);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [view, setView] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [emailStatus, setEmailStatus] = React.useState(true);
  const [passwordResetDialog, setPasswordResetDialog] = React.useState(false);
  const [passwordState, setPasswordState] = React.useState(
    passwordResetInisialState
  );
  const [valueDate, setValueDate] = React.useState(null);
  const [selectionState, setSelectionState] = React.useState([]);
  const [GradeMappedDesignationList, setGradeMappedDesignationList] =
    React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [employee, setEmployee] = React.useState([]);
  const [fileValue, setFileValue] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [grade, setGrade] = React.useState([]);

  const { usersaveData, globapOrganization } = useSelector(
    (state) => state.presist
  );
  const { pathname } = useLocation();
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });

  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setEditPage(false);
      setEdit(false);
      navigate("/user");
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      setEditPage(false);
      setEdit(false);
      navigate("/user");
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const navigate = useNavigate();
  const fileRef1 = React.useRef(null);
  const constructOrderData = (arr) => {
    const result =
      arr &&
      arr.length > 0 &&
      arr.map((val, i) => ({
        ...val.userInfo,
        gradeName: val.gradeInfo?.gradeName,
        depName: val.departmentInfo?.depName,
        desigName: val.designationInfo?.desigName,
        superUser: val.userAccess?.is_superuser,
      }));
    return result;
  };
  React.useEffect(() => {
    // dispatch(getPatientPharmacyNotPaidOrderAction());
    if (employeeList?.userDetails && employeeList.userDetails.length > 0) {
      // setEmployee([])
      const employeedata = constructOrderData(employeeList.userDetails);
      // setEmployee(employeedata)
      setEmployee((per) => {
        const arr = [...employeedata];
        return removeDuplicatesFromArray(arr);
      });
      setTotalPage(employeeList.pageInfo?.total_items);
    } else {
      setEmployee([]);
    }
  }, [employeeList]);
  React.useEffect(() => {
    return () => {
      setItemPerPage(100);
      setPageNumber(1);
      setTotalPage(0);
    };
  }, []);
  const changePageSize = (newPageSize) => {
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    dispatch(
      getEmployeeServiceAction(
        `/${globapOrganization.orgId}/`,
        newPageSize.pageSize,
        newPageSize.page + 1,
        isActive
      )
    );
  };
  const constructOrderDatas = (arr) => {
    const result =
      arr &&
      arr.length > 0 &&
      arr.map((val, i) => ({
        ...val.gradeInfo,
        designationName: val.desigInfo?.desigName,
      }));
    return result;
  };
  React.useEffect(() => {
    // dispatch(getPatientPharmacyNotPaidOrderAction());
    if (gradeList) {
      const employeedata = constructOrderDatas(gradeList);
      setGrade(employeedata);
    }
  }, [gradeList]);
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          EmployeeServiceAction(
            { empId: [row.empId] },
            "put",
            `/${globapOrganization.orgId}/`
          )
        );
        dispatch(
          getEmployeeServiceAction(
            `/${globapOrganization.orgId}/`,
            itemPerPage,
            pageNumber,
            isActive
          )
        );
      }
    });
  };
  const handleDeleteClick = async (row) => {
    console.log(row);
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          EmployeeServiceAction(
            { empId: row },
            "delete",
            `/${globapOrganization.orgId}/`
          )
        );
        setSelectionState([]);
        setEmployee([]);
        dispatch(
          getEmployeeServiceAction(
            `/${globapOrganization.orgId}/`,
            itemPerPage,
            pageNumber,
            isActive
          )
        );
      }
    });
  };
  const getDesignationAPICall = async () => {
    const res = await getGradeMappedDesignation(globapOrganization?.orgId);
    if (res) setGradeMappedDesignationList(res);
    console.log(res, "res123");
  };
  React.useEffect(() => {
    dispatch(
      gradeMasterAction(
        "",
        "get",
        `/grade/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    // dispatch(
    //   designationMasterAction(
    //     "",
    //     "get",
    //     `/designation/info/${globapOrganization?.orgId}/`
    //   )
    // );
    getDesignationAPICall();
  }, [globapOrganization]);
  const handelDialog = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handelCloseDialog = () => {
    setSelectedRow("");
    setOpen(false);
  };
  const generatePassword = (length = 8) => {
    let charset = "";
    let newPassword = "";

    const includeSpecialChars = true;
    const includeNumbers = true;
    const includeLowercase = true;
    const includeUppercase = true;

    // Include character sets based on the conditions
    if (includeSpecialChars) charset += "!#$@_";
    if (includeNumbers) charset += "0123456789";
    if (includeLowercase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (includeUppercase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Make sure at least one character from each set is included
    newPassword += getRandomChar("!#$@_");
    newPassword += getRandomChar("0123456789");
    newPassword += getRandomChar("abcdefghijklmnopqrstuvwxyz");
    newPassword += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ");

    // Fill the remaining characters randomly
    for (let i = newPassword.length; i < length; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setPasswordState((per) => ({ ...per, autogenaratePassword: newPassword }));
    return newPassword;
  };

  const getRandomChar = (charset) => {
    return charset.charAt(Math.floor(Math.random() * charset.length));
  };

  const handelCloseResetPasswordDialog = () => {
    setPasswordResetDialog(false);
    setPasswordState(passwordResetInisialState);
  };

  const handelResetPasword = async () => {
    try {
      dispatch(loading(true));
      const payload = { username: selectedRow?.empId };
      const res = await ResetUserPassword(payload);
      dispatch(loading(false));
      if (res) {
        handelCloseDialog();
        TriggerAlert("success", res?.data?.message);
      }
    } catch (error) {}
  };
  const handelsuperAdminResetPassword = async () => {
    try {
      const payload = {
        empId: passwordState?.resetpasswordUserDetails?.empId,
        requestType: "password",
        sendEmail: passwordState.sendmailOption,
        newPassword: passwordState.autogenaratePassword,
      };
      dispatch(loading(true));
      const res = await SuperAdminPasswordReset(payload);
      dispatch(loading(false));
      if (res) {
        handelCloseResetPasswordDialog();
        TriggerAlert("success", res.data.message);
      }
    } catch (error) {}
  };
  const resetIconOnclick = (row) => {
    setPasswordResetDialog(true);
    generatePassword();
    setPasswordState((per) => ({ ...per, resetpasswordUserDetails: row }));
  };
  const handelFunFormatChange = (dateString) => {
    try {
      var parts = dateString.split("-");
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10) - 1; // months are zero-based in JavaScript
      var year = parseInt(parts[2], 10);

      // Create a new Date object
      var dateObject = new Date(year, month, day);

      // Format the date as required
      var formattedDate = dateObject.toString();
      console.log(formattedDate, "for");
      return formattedDate;
    } catch (error) {}
  };
  const handleEditClick = (row, data) => {
    if (data === "edit") {
      setEditPage(true);
      navigate("/createUser");
      setSelectedRow(row);
      setEdit(true);
      setValue("DOB", moment(row?.DOB).format("DD-MM-YYYY"));
      const date = moment(row?.DOB, "DD-MM-YYYY").format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      console.log(date, "date");
      setValueDate(new Date(date));
    }
    setValue("firstname", row?.firstname);
    setValue("empId", row?.empId);
    setValue("lastname", row?.lastname);
    setValue("mobileNumber", row?.mobileNumber || null);
    setValue("emailAddress", row?.emailAddress);
    setValue("depId", row?.depId);

    setValue("gender", row?.gender);
    setValue("desigId", row?.desigId);
    setValue("gradeId", row?.gradeId);
    setValue("gradeName", row?.gradeName);
    setValue("superUser", row?.superUser);
    setValue("extensionNo", row?.extensionNo || null);
    setValue("landLineNo", row?.landLineNo || null);
  };
  const handleView = (row) => {
    setEditPage(true);
    setView(true);
    setValue("firstname", row?.firstname);
    setValue("empId", row?.empId);
    setValue("lastname", row?.lastname);
    setValue("mobileNumber", row?.mobileNumber || null);
    setValue("emailAddress", row?.emailAddress);
    setValue("depId", row?.depId);
    setValue("DOB", row?.DOB);
    setValue("gender", row?.gender);
    setValue("desigId", row?.desigId);
    setValue("gradeId", row?.gradeId);
    setValue("gradeName", row?.gradeName);
    setValue("superUser", row?.superUser);
    setValue("extensionNo", row?.extensionNo || null);
    setValue("landLineNo", row?.landLineNo || null);
  };
  const handelClose = () => {
    // navigate('/user')
    // setEditPage(false)
    dispatch(updatePresistStates({ name: "usersaveData", value: {} }));
    setSelectedFile([]);
    setEdit(false);
    setEmailStatus(true);
    reset();
    setValueDate(null);
  };
  React.useEffect(() => {
    handleEditClick(usersaveData, "no");
  }, []);
  React.useEffect(() => {
    return () => {
      dispatch(
        updatePresistStates({ name: "usersaveData", value: getValues() })
      );
    };
  }, []);
  const columns = [
    {
      field: "empId",
      headerName: "Employee Id",
      width: 130,
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "firstname",
    //   headerName: "FIRST NAME",
    //   width: 250,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "Name",
      headerName: "Name",
      width: 200,
      valueGetter: (params) => {
        return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
      },
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "lastname",
    //   headerName: "LAST NAME",
    //   width: 240,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "emailAddress",
      headerName: "Email",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "depName",
      headerName: "Department",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "desigName",
      headerName: "Designation",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 150,
      minWidth: 150,
      getActions: (params) => [
        allowedPages().UserManagement.userList.edit && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),

        // allowedPages().UserManagement.userList.resetPassword ? <GridActionsCellItem
        //   icon={<LockReset sx={{ color: "#358c4e" }} />}
        //   label="Reset Password"
        //   onClick={() => {
        //     handelDialog(params.row);
        //   }}
        //   color="primary"
        // />
        //  : <></>,

        <GridActionsCellItem
          sx={{ p: 0 }}
          icon={
            <Tooltip title="View" placement="right">
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <ViewPopUp value={true} user={params.row} />
              </Box>
            </Tooltip>
          }
          color="primary"
        />,
        !isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),

        allowedPages().UserManagement.userList.resetPassword && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Reset Password" placement="top">
                <Box sx={{ ml: 2 }}>
                  <LockReset sx={{ color: "#358c4e" }} />
                </Box>
              </Tooltip>
            }
            label="Reset Password"
            onClick={() => {
              resetIconOnclick(params.row);
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
      ],
    },
  ];

  const form = useForm({
    defaultValues: {
      // username: "",
      // password: "",
      empId: "",
      firstname: "",
      extensionNo: null,
      lastname: "",
      emailAddress: "",
      mobileNumber: null,
      DOB: null,
      gender: "",
      depId: "",
      desigId: "",
      gradeId: "",
      landLineNo: null,
      superUser: false,
      gradeName: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;

  React.useEffect(() => {
    setEmployee([]);
    dispatch(
      getEmployeeServiceAction(
        `/${globapOrganization?.orgId}/`,
        itemPerPage,
        pageNumber,
        isActive
      )
    );
    setValue("superUser", false);
  }, [globapOrganization]);

  const onSubmit = async (data) => {
    data.orgId = globapOrganization?.orgId;

    if (edit) {
      const editData = {
        id: selectedRow.id,
        empId: data.empId,
        firstname: data.firstname,
        lastname: data.lastname,
        emailAddress: data.emailAddress,
        mobileNumber: data.mobileNumber,
        DOB: moment(data.DOB).format("DD-MM-YYYY"),
        landLineNo: data.landLineNo,
        gender: data.gender,
        depId: data.depId,
        extensionNo: data.extensionNo,
        desigId: data.desigId,
        gradeId: data.gradeId,
        superUser: false,
        createdOn: selectedRow.createdOn,
        updatedOn: selectedRow.updatedOn,
      };
      // setEditPage(false)
      if (editData) setEmployee([]);
      await dispatch(
        EmployeeServiceAction(
          editData,
          "patch",
          `/${globapOrganization.orgId}/`,
          () => {
            dispatch(
              getEmployeeServiceAction(
                `/${globapOrganization.orgId}/`,
                itemPerPage,
                pageNumber,
                isActive
              )
            );
            reset();
            setValueDate(null);
            setEdit(false);
          }
        )
      );
    } else {
      data.DOB = moment(data.DOB).format("DD-MM-YYYY");
      setEmployee([]);
      await dispatch(
        EmployeeServiceAction(
          data,
          "post",
          `/${globapOrganization.orgId}/`,
          () => {
            dispatch(
              getEmployeeServiceAction(
                `/${globapOrganization.orgId}/`,
                itemPerPage,
                pageNumber,
                isActive
              )
            );
            reset();
            setValueDate(null);
          }
        )
      );
    }
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0 && isActive) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      getEmployeeServiceAction(
        `/${globapOrganization.orgId}/`,
        itemPerPage,
        pageNumber,
        e
      )
    );
  };
  const handelCheckEmail = async (e) => {
    const payload = {
      emailAddress: getValues("emailAddress"),
    };
    if (getValues("emailAddress")) {
      const res = await checkEmpEmail(payload, globapOrganization.orgId);
      console.log(res?.isValid === false ? false : true, !res?.isValid, "con");
      setEmailStatus(res?.isValid === false ? false : true);
    }
  };
  React.useEffect(() => {
    if (pathname === "/createUser") {
      setEditPage(true);
    } else {
      setEditPage(false);
      reset();
    }
  }, [pathname]);
  const handelChangeFile = async (e) => {
    try {
      setFileValue(e.target.files[0]);
      const res = await dispatch(
        TaskAssignExcelUploadAction(
          "post",
          {},
          e.target.files[0],
          "",
          "/users/user_list_upload"
        )
      );

      if (res) {
        dispatch(
          getEmployeeServiceAction(
            `/${globapOrganization.orgId}/`,
            itemPerPage,
            pageNumber,
            isActive
          )
        );
        setFileValue("");
        clearField();
      }
    } catch (err) {
      clearField();
    }
  };
  const clearField = () => {
    fileRef1.current.value = null;
    setFileValue("");
  };
  const handelDownloadClick = () => {
    dispatch(
      DownloadUserList(
        "GET",
        {},
        "/users/generate_excel",
        globapOrganization.orgId
      )
    );
  };

  const handelDateFun = (e) => {
    console.log(e, "props12345");
    const format = moment(e).format("YYYY/MM/DD");
    console.log(format, "format");
    setValue("DOB", format);
    setValueDate(new Date(format));
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard: ", text);
        setPasswordState((per) => ({
          ...per,
          passwordMessage: "Password copied to clipboard!",
        }));
        setTimeout(
          () => setPasswordState((per) => ({ ...per, passwordMessage: "" })),
          2000
        );
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };

  return (
    <FormProvider {...form}>
      <form
        style={{ height: "100%" }}
        key={1}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={formContainerStyle}>
          <Box sx={{ width: "100%" }}>
            <Card sx={formContainerCardStyle}>
              {!editpage ? (
                <Box
                  sx={{
                    pt: 3,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: 7,
                      pr: 5,

                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <h4 style={{ ...allpageFontStyle.title }}>LIST OF USER</h4>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Box sx={{ display: 'flex', justifyContent: 'end', pt: 2, alignItems: 'center' }}>
                        {fileValue?.name ? (
                          <Box sx={{ fontSize: 10, mr: 1, pl: 2, display: "flex" }}>
                            <Box sx={{ width: 100, overflowWrap: 'break-word' }}> {fileValue?.name}</Box>
                            <Box>
                              <CloseRounded
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                color="error"
                                onClick={() => {
                                  clearField();
                                }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </Box> */}

                      {true && (
                        <Box
                          sx={{
                            pt: 0.7,
                            pr: 1.6,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <CustomButton
                              buttonStyle={{}}
                              color={"success"}
                              label={
                                <Box sx={{}}>
                                  <Tooltip
                                    title="Download Template"
                                    placement="bottom"
                                  >
                                    <Box>
                                      <ImageTag
                                        src={imageImports.download3}
                                        alt="upload"
                                        width={25}
                                        height={25}
                                      />
                                    </Box>

                                    {/* <img src={''} alt="upload" style={{ width: 20, height: 20 }} /> */}
                                  </Tooltip>
                                </Box>
                              }
                              onClick={handelDownloadClick}
                            />
                          </Box>
                        </Box>
                      )}
                      {allowedPages().UserManagement.createUser.create && (
                        <Box
                          sx={{
                            pr: 1.6,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              cursor: "pointer",
                              pr: 1.5,
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <label htmlFor="upload">
                                <Box sx={{ pt: 0.7, cursor: "pointer" }}>
                                  <Tooltip
                                    title="Upload excel to create user"
                                    placement="bottom"
                                  >
                                    <Box>
                                      <ImageTag
                                        src={imageImports.upload}
                                        alt="upload"
                                        width={25}
                                        height={25}
                                      />
                                    </Box>

                                    {/* <img src={''} alt="upload" style={{ width: 20, cursor: 'pointer', height: 20 }} /> */}
                                  </Tooltip>{" "}
                                </Box>
                              </label>
                            </Box>
                          </Box>
                          <input
                            type="file"
                            id="upload"
                            ref={fileRef1}
                            onChange={handelChangeFile}
                            style={{ color: "transparent", display: "none" }}
                          />
                        </Box>
                      )}
                      <Box>
                        {allowedPages().UserManagement.createUser.create && (
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              setEditPage(true);
                              reset();
                              setValueDate(null);
                              navigate("/createUser");
                            }}
                            sx={{
                              ...allpageFontStyle.addButtonFont.buttonHeight,
                              ...allpageFontStyle.buttonFontStyle1,
                              fontWeight: "bold",
                              // backgroundColor: "#43a5fc",
                              borderRadius: 5,
                              color: "#358c4e",
                              p: 0,
                              px: 2,
                              py: 0,
                              ":hover": {
                                backgroundColor: "#358c4e",
                                color: "white",
                                borderColor: "#358c4e",
                              },
                              textTransform: "capitalize",
                              borderColor: "#358c4e",
                            }}
                          >
                            <AddIcon
                              sx={{
                                mr: 0.5,
                                ...allpageFontStyle.addButtonFont.font,
                                p: 0,
                              }}
                            />
                            <Typography
                              sx={{ ...allpageFontStyle.addButtonFont.font }}
                            >
                              Create User
                            </Typography>
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      mb: 5,
                      flex: 1,
                      maxHeight: "85%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <DataGride
                      columns={columns}
                      customHeight={450}
                      selectionIs={selectionIs}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      deleteIcon={isActive ? true : false}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      getRowId={(row) => row?.empId}
                      InActiveFilterCheckBox={() =>
                        checkBoxFilter(
                          isActive,
                          setIsActive,
                          "Active",
                          "Inactive",
                          handelActiveInactive
                        )
                      }
                      pageSize={itemPerPage}
                      page={pageNumber}
                      pageName={"project"}
                      onPageSizeChange={changePageSize}
                      rowCount={totalPage}
                      paginationMode={true}
                      tableData={
                        employee && employee.length > 0 && employee
                        // .filter((value) => value.isActive === true)
                      }
                      hideDeletion={
                        !allowedPages().UserManagement.userList.delete
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    minHeight: 600,
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // px: 5,
                    }}
                  >
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Edit{" "}
                          </Typography>
                        </Box>
                      ) : view ? (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            View{" "}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            mb: 3,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Add{" "}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.editType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.listType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      )}
                    </Box>
                  </Box>

                  {/* {edit || view ? (
                    <Box
                      sx={{
                        m: 3,
                        fontFamily: "lato",
                        fontSize: 14,
                        pl: 2,
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "lato",
                          fontSize: 14,
                          fontWeight: "bold",
                          ml: 15
                        }}
                      >
                        EMP ID:
                      </Box>{" "}
                      &nbsp; {selectedRow.empId}
                    </Box>
                  ) : null} */}
                  <Box
                    sx={{
                      pt: 1,
                      pl: 1,
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        p: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                        fontFamily: "Lato",
                        color: "grey",
                        fontSize: 14,
                      }}
                    ></Box>
                    <Grid container spacing={2} sx={{}}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="empId"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Employee Id *"
                              size="small"
                              helperText={
                                errors?.empId ? errors.empId?.message : null
                              }
                              error={errors.empId?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view || edit}
                              onChange={(e) => {
                                trigger("empId");
                                onChange(e);
                                setValue("empId", e.target.value.toUpperCase());
                              }}
                              onBlur={() => trigger("empId")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Code" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="emailAddress"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Email *"
                              size="small"
                              helperText={
                                errors.emailAddress
                                  ? errors.emailAddress?.message
                                  : null
                              }
                              error={
                                errors.emailAddress?.message || !emailStatus
                              }
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("emailAddress");
                                onChange(e);
                              }}
                              onBlur={(e) => {
                                handelCheckEmail(e);
                                trigger("emailAddress");
                              }}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="firstname"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="First Name *"
                              size="small"
                              helperText={
                                errors?.firstname
                                  ? errors?.firstname?.message
                                  : null
                              }
                              error={errors.firstname?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("firstname");
                                var numberRegex = /^[a-zA-Z\s]+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue("firstname", e.target.value);
                                }
                              }}
                              onBlur={() => trigger("firstname")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Code" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="landLineNo"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "85%",
                              }}
                            >
                              <TextField
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  "& input::placeholder": {
                                    ...allpageFontStyle.textFieldPlaceholder,
                                  },
                                }}
                                disabled={view}
                                size="small"
                                onChange={(e) => {
                                  trigger("landLineNo");
                                  var numberRegex = /^\d+$/;
                                  let temp = e.target.value.replace(/-/, "");
                                  const u = numberRegex.test(temp);
                                  // var number =  e.target.value.trim().replace(/[^0-9]/g, "");
                                  // if (number.length < 4) setValue(number);
                                  // if (number.length < 7) setValue(number.replace(/(\d{3})(\d{1})/, "$1-$2"));
                                  // if (number.length < 11) setValue(number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3")) ;
                                  // const u = numberRegex.test(e.target.value);
                                  if (u || e.target.value === "") {
                                    if (e.target.value === 3)
                                      setValue(
                                        "landLineNo",
                                        e.target.value + "-"
                                      );
                                    else if (e.target.value.length <= 12) {
                                      setValue(
                                        "landLineNo",
                                        e.target.value || null
                                      );
                                    }
                                  }
                                }}
                                helperText={
                                  errors.landLineNo
                                    ? errors.landLineNo?.message
                                    : null
                                }
                                error={errors?.landLineNo?.message}
                                label="Contact No"
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 12))
                                }
                                onBlur={() => trigger("landLineNo")}
                                value={value || ""}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Description" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                placeholder="Ex - 044-12345678"
                              />
                              {/* <Box sx={{fontSize:11, color:'grey'}}>Should be in format 044-12345678</Box> */}
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="lastname"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Last Name *"
                              size="small"
                              helperText={
                                errors?.lastname
                                  ? errors?.lastname?.message
                                  : null
                              }
                              error={errors.lastname?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("lastname");
                                var numberRegex = /^[a-zA-Z\s]+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue("lastname", e.target.value);
                                }
                              }}
                              onBlur={() => trigger("lastname")}
                              value={value}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Short Name" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="extensionNo"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Extn. No"
                              size="small"
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                trigger("extensionNo");
                                var numberRegex = /^\d+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue(
                                    "extensionNo",
                                    e.target.value || null
                                  );
                                }
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 4))
                              }
                              onBlur={() => trigger("extensionNo")}
                              value={value || ""}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Controller
                          control={control}
                          name="gender"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "85%",
                              }}
                            >
                              <FormControl
                                error={errors.gender}
                                size="small"
                                fullWidth
                              >
                                <InputLabel shrink>Gender*</InputLabel>
                                <Select
                                  notched
                                  label="Gender*"
                                  sx={{
                                    width: "100%",
                                    borderRadius: 3,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "#000000",
                                    },
                                  }}
                                  MenuProps={{
                                    PaperProps: { sx: { maxHeight: 150 } },
                                  }}
                                  onChange={(e) => {
                                    trigger("gender");
                                    onChange(e);
                                  }}
                                  disabled={view}
                                  onBlur={() => trigger("gender")}
                                  value={value}
                                  inputRef={ref}
                                  inputProps={{ "aria-label": "Project Code" }}
                                >
                                  {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Gender
                                    </MenuItem> */}

                                  <MenuItem
                                    sx={{
                                      borderBottom: "1px solid white",
                                      fontFamily: "Lato",
                                      fontSize: 13,
                                    }}
                                    value={"male"}
                                  >
                                    {" "}
                                    Male
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      borderBottom: "1px solid white",
                                      fontFamily: "Lato",
                                      fontSize: 13,
                                    }}
                                    value={"female"}
                                  >
                                    Female
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      borderBottom: "1px solid white",
                                      fontFamily: "Lato",
                                      fontSize: 13,
                                    }}
                                    value={"others"}
                                  >
                                    Others
                                  </MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: "red" }}>
                                  {errors.gender
                                    ? errors.gender?.message
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Controller
                          control={control}
                          name="depId"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "85%",
                              }}
                            >
                              <FormControl
                                error={errors.depId}
                                size="small"
                                fullWidth
                              >
                                <InputLabel shrink>Department*</InputLabel>
                                <Select
                                  notched
                                  sx={{
                                    width: "100%",
                                    borderRadius: 3,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "#000000",
                                    },
                                  }}
                                  disabled={view}
                                  label="Department*"
                                  MenuProps={{
                                    PaperProps: { sx: { maxHeight: 150 } },
                                  }}
                                  onChange={(e) => {
                                    trigger("depId");
                                    onChange(e);
                                  }}
                                  onBlur={() => trigger("depId")}
                                  value={value}
                                  inputRef={ref}
                                  inputProps={{ "aria-label": "Project Code" }}
                                >
                                  {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Department
                                    </MenuItem> */}
                                  {departmentList.map((val) => (
                                    <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={val.departmentInfo?.depId}
                                    >
                                      {val.departmentInfo?.depName}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText sx={{ color: "red" }}>
                                  {errors.depId
                                    ? errors?.desigId?.message
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="DOB"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={enGB}
                              >
                                <DemoContainer
                                  components={["DatePicker"]}
                                  sx={{ width: "85%", overflow: "hidden" }}
                                >
                                  <DatePicker
                                    label="DOB*"
                                    maxDate={new Date("2005-01-01")}
                                    value={valueDate}
                                    sx={{
                                      ...(!getValues("DOB") && {
                                        ...allpageFontStyle.dateField,
                                      }),
                                    }}
                                    onChange={handelDateFun}
                                    slotProps={{
                                      field: { clearable: true },
                                      textField: {
                                        size: "small",
                                        fullWidth: true,
                                        helperText: errors.DOB
                                          ? "DOB is required"
                                          : "",
                                        error: errors.DOB ? true : false,
                                        InputLabelProps: {
                                          ...formFieldStyle.InputLabelProps,
                                        },
                                        InputProps: {
                                          ...formFieldStyle.InputProps,
                                        },
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              {/* <TextField
                                type="date"
                                label="DOB*"
                                size="small"
                                helperText={
                                  errors.DOB ? errors.DOB.message : null
                                }
                                error={errors.DOB?.message}
                                sx={{
                                  width: "85%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...!getValues("DOB") && { ...allpageFontStyle.dateField }
                                }}
                                disabled={view}

                                onChange={(e) => {
                                  trigger("DOB");
                                  onChange(e);
                                }}
                                placeholder="DD-MM-YYYY"
                                onBlur={() => trigger("DOB")}
                                inputProps={{
                                  max: moment(new Date("2005-01-01")).format(
                                    "YYYY-MM-DD"
                                  ),
                                }}
                                value={value}
                                inputRef={ref}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              /> */}
                            </>
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Controller
                          control={control}
                          name="desigId"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "85%",
                              }}
                            >
                              <FormControl
                                error={!value ? errors.desigId : null}
                                size="small"
                                fullWidth
                              >
                                <InputLabel shrink>Designation*</InputLabel>
                                <Select
                                  notched
                                  label="Designation*"
                                  sx={{
                                    width: "100%",
                                    borderRadius: 3,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "#000000",
                                    },
                                  }}
                                  MenuProps={{
                                    PaperProps: { sx: { maxHeight: 150 } },
                                  }}
                                  disabled={view}
                                  onChange={async (e) => {
                                    trigger("desigId");
                                    onChange(e);
                                    setValue("gradeId", "");
                                    setValue("gradeName", "");

                                    const res = await dispatch(
                                      getGradeByDesigAction({
                                        desigId: e.target.value,
                                        orgId: globapOrganization?.orgId,
                                      })
                                    );
                                    console.log(res, "dgfd");
                                    setValue("gradeId", res?.gradeId);
                                    setValue("gradeName", res?.gradeName);
                                  }}
                                  onBlur={() =>
                                    !value ? trigger("desigId") : null
                                  }
                                  value={value}
                                  inputRef={ref}
                                  inputProps={{ "aria-label": "Project Code" }}
                                >
                                  {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Designation
                                    </MenuItem> */}
                                  {GradeMappedDesignationList &&
                                    GradeMappedDesignationList.length > 0 &&
                                    GradeMappedDesignationList.map((val) => (
                                      <MenuItem
                                        sx={{
                                          borderBottom: "1px solid white",
                                          fontFamily: "Lato",
                                          fontSize: 13,
                                        }}
                                        value={val?.desigId}
                                      >
                                        {val?.desigName}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={{ color: "red" }}>
                                  {errors?.desigId
                                    ? errors?.desigId?.message
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Controller
                          control={control}
                          name="mobileNumber"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              label="Mobile"
                              size="small"
                              // helperText={
                              //   errors.mobileNumber
                              //     ? errors?.mobileNumber?.message
                              //     : null
                              // }
                              // error={errors?.mobileNumber?.message}
                              sx={{
                                width: "85%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              disabled={view}
                              onChange={(e) => {
                                // trigger("mobileNumber");
                                var numberRegex = /^\d+$/;
                                const u = numberRegex.test(e.target.value);
                                if (u || e.target.value === "") {
                                  setValue(
                                    "mobileNumber",
                                    e.target.value || null
                                  );
                                }
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 10))
                              }
                              // onBlur={() => trigger("mobileNumber")}
                              value={value || ""}
                              inputRef={ref}
                              inputProps={{ "aria-label": "Description" }}
                              InputProps={formFieldStyle.InputProps}
                              InputLabelProps={formFieldStyle.InputLabelProps}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Controller
                          control={control}
                          name="gradeId"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "85%",
                              }}
                            >
                              <TextField
                                label="Grade*"
                                size="small"
                                helperText={
                                  errors?.gradeId && !value
                                    ? errors?.gradeId?.message
                                    : null
                                }
                                error={!value ? errors.gradeId?.message : null}
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                disabled
                                value={getValues("gradeName")}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Short Name" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            </Box>
                          )}
                        />
                      </Grid>
                      {/* <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Controller
                            control={control}
                            name="superUser"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <FormControl
                                  error={errors.superUser}
                                  size="small"
                                  fullWidth
                                >
                                  <InputLabel shrink>User Type</InputLabel>
                                  <Select
                                    notched
                                    label="User Type"
                                    sx={{
                                      width: "100%",
                                      borderRadius: 3,
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                    disabled={view}
                                    onChange={(e) => {
                                      trigger("superUser");
                                      onChange(e);
                                    }}
                                    onBlur={() => trigger("superUser")}
                                    value={value}
                                    inputRef={ref}
                                    MenuProps={{
                                      PaperProps: { sx: { maxHeight: 150 } },
                                    }}
                                    inputProps={{ "aria-label": "Project Code" }}
                                  >
                                    <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={true}
                                    >
                                      Admin
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={false}
                                    >
                                      Employee
                                    </MenuItem>
                                  </Select>
                                  <FormHelperText sx={{ color: "red" }}>
                                    {errors?.superUser
                                      ? errors?.superUser?.message
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid> */}
                    </Grid>
                  </Box>
                  {edit ? (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // mb: 10,

                        mt: 5,
                      }}
                    >
                      <Box sx={{}}>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          // onClick={() => }
                          // onClick={onSubmit}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#258d4d",

                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          UPDATE
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.resetType,
                              title: PopupMessage.reset,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          {RESET_LABEL}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: view ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // mb: 10,
                        mt: 5,
                      }}
                    >
                      {allowedPages().UserManagement.createUser.create ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          // onClick={onSubmit}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#258d4d",
                            ":hover": { backgroundColor: "#258d4d" },
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  )}
                  {view ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // type="submit"
                        onClick={() => {
                          setEditPage(false);
                          setEdit(false);
                          setView(false);
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#3378e8",

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        List
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </form>
      <Dialog open={open} disableBackdropClick onClose={handelCloseDialog}>
        <DialogContent sx={{ fontFamily: "Lato" }}>
          {" "}
          Are you sure you want to reset the password ?
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            p: 2,
          }}
        >
          <Box sx={{ pr: 2 }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#258d4d",
                ":hover": { backgroundColor: "green" },
              }}
              onClick={handelResetPasword}
            >
              Yes
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              onClick={handelCloseDialog}
              color="error"
            >
              No
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={passwordResetDialog}
        disableBackdropClick
        onClose={() => handelCloseResetPasswordDialog()}
      >
        <DialogTitle sx={{ ...allpageFontStyle.title, textAlign: "center" }}>
          Reset Password
        </DialogTitle>
        <DialogContent sx={{ fontFamily: "Lato" }}>
          <Box sx={{ display: "flex", gap: 2, pb: 3 }}>
            <Box>
              <Typography sx={{ ...allpageFontStyle.typography1 }}>
                <b>Emp Id : </b>
                {passwordState?.resetpasswordUserDetails?.empId}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ ...allpageFontStyle.typography1 }}>
                <b>Name : </b>
                {passwordState?.resetpasswordUserDetails?.firstname +
                  " " +
                  passwordState?.resetpasswordUserDetails?.lastname}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Typography
                sx={{ ...allpageFontStyle.typography1, textAlign: "center" }}
              >
                <b>Auto Generate Password</b>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    ...allpageFontStyle.typography1,
                    width: 100,
                    fontWeight: "bold",
                    background: "#f2f2f2",
                    px: 2.5,
                    py: 0.6,
                    textAlign: "center",
                    color: "blue",
                  }}
                >
                  {passwordState.autogenaratePassword}
                </Typography>
                <Box sx={{ pt: 0.5 }}>
                  <ContentCopy
                    sx={{ cursor: "pointer", fontSize: 19 }}
                    onClick={() =>
                      copyToClipboard(passwordState.autogenaratePassword)
                    }
                  />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <RestartAlt
                    sx={{ cursor: "pointer", fontSize: 19 }}
                    onClick={() => generatePassword()}
                  />
                </Box>
                <Box sx={{ pb: 0.5 }}>
                  <FormControlLabel
                    sx={{ ...allpageFontStyle.typography1, m: 0, p: 0 }}
                    control={
                      <Checkbox
                        size="small"
                        onChange={(e) => {
                          setPasswordState({
                            ...passwordState,
                            sendmailOption: e.target.checked,
                          });
                        }}
                        checked={passwordState.sendmailOption}
                        sx={{
                          color: "#4b0f0e",
                          ...allpageFontStyle.typography1,
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ ...allpageFontStyle.typography1, m: 0, p: 0 }}
                      >
                        {"Send Email"}
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            </Box>
            {passwordState.passwordMessage && (
              <Typography
                sx={{
                  ...allpageFontStyle.typography1,
                  display: "flex",
                  justifyContent: "center",
                  pt: 1,
                  color: "#358c4e",
                }}
              >
                {passwordState.passwordMessage}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}></Box>
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            p: 2,
          }}
        >
          <Box sx={{ pr: 2 }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#258d4d",
                borderRadius: 5,
                ...allpageFontStyle.buttonFontStyle2,
                ":hover": { backgroundColor: "green" },
              }}
              onClick={handelsuperAdminResetPassword}
            >
              Submit
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{ ...allpageFontStyle.buttonFontStyle2, borderRadius: 5 }}
              onClick={handelCloseResetPasswordDialog}
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
