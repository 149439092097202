/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export default function PopupComponent({
  open,
  handleResult,
  handelClose,
  title,
}) {
  // eslint-disable-next-line react/prop-types
  console.log(open, "opne");
  return (
    <div>
      {" "}
      <Dialog
        maxWidth={"xs"}
        // fullWidth
        open={open}
        onClose={handelClose}
      >
        <DialogTitle
          sx={{
            // display: 'flex',
            // justifyContent: 'center',
            fontFamily: "Lato",
            textAlign: "center",
            wordWrap: "break-word",
            // width: 420,
            fontSize: 16,
            px: 4,
          }}
        >
          {title || "Are you sure ?"}
        </DialogTitle>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              height: 23,
              // m: { xs: 1, md: 1, lg: 0 },
              fontSize: 11,
              fontWeight: "bold",
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
              borderRadius: 5,
            }}
            variant="contained"
            color="success"
            onClick={() => {
              handleResult();
            }}
          >
            Yes
          </Button>
          <Button
            sx={{
              height: 23,
              // m: { xs: 1, md: 1, lg: 0 },
              fontSize: 11,
              fontWeight: "bold",
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
              borderRadius: 5,
            }}
            variant="contained"
            color="error"
            onClick={handelClose}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
