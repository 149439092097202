import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { grey } from "@mui/material/colors";
import React, { useRef, useState } from "react";
import { box, contents, headings } from "./styles";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import PropTypes from "prop-types";
import one from "../../pics/sla2.png";
import Spinner from "./spinner";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import { Controller, useFormContext } from "react-hook-form";
import SelectComponent from "../commonComponents/SelectComponent";
import { formFieldStyle } from "../../utils/Style";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  Done,
} from "@mui/icons-material";
const steps = ["High*", "Medium*", "Low*"];
console.log(steps, "steps");

export default function SLAMaster(props) {
  const { userList, view } = props;
  const MINTS_LABEL = "Minutes";
  const HOURS_LABEL = "Hours";
  const DAYS_LABEL = "Days";
  const SLA_LEVEL = "Level 1";
  const SLA_LEVEL2 = "Level 2";
  const MINTS = Array(60)
    .fill()
    .map((_, index) => index);
  const HOURS = Array(24)
    .fill()
    .map((_, index) => index);
  const selectStyle = {
    ".MuiInputBase-input": {
      height: 12,
    },
  };
  const {
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const scrollingContainerRef = useRef(null);
  const autoScrollToBottom = () => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollIntoView();
    }
  };
  console.log(getValues(), errors, "hi");
  const errorMessage = {
    highLevel1:
      errors?.high?.level1 || errors?.high?.level1?.root
        ? errors.high?.level1?.message || errors.high?.level1?.root?.message
        : false,
    highLevel2:
      errors?.high?.level2 || errors?.high?.level2?.root
        ? errors.high?.level2?.message || errors.high?.level2?.root?.message
        : false,
    mediumLevel1:
      errors?.medium?.level1 || errors?.medium?.level1?.root
        ? errors.medium?.level1?.message || errors.medium?.level1?.root?.message
        : false,
    mediumLevel2:
      errors?.medium?.level2 || errors?.medium?.level2?.root
        ? errors.medium?.level2?.message || errors.medium?.level2?.root?.message
        : false,
    lowLevel1:
      errors?.low?.level1 || errors?.low?.level1?.root
        ? errors.low?.level1?.message || errors.low?.level1?.root?.message
        : false,
    lowLevel2:
      errors?.low?.level2 || errors?.low?.level2?.root
        ? errors.low?.level2?.message || errors.low?.level2?.root?.message
        : false,
  };
  const [slaSteps, setSlaSteps] = useState(0);
  const [type, setType] = useState(0);
  const [count, setCount] = useState(0);
  const SideLabelStyle = {
    fontSize: 12,
    fontFamily: "Lato",
  };
  const errorFont = {
    fontSize: "10px",
    pb: 1,
    fontFamily: "Lato",
    textAlign: "end",
  };
  const fieldWidth = {
    width: "100%",
  };
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,#495057 0%,#495057 50%,#495057 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,#495057 0%,#495057 50%,#495057 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[100] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        slaSteps === 0
          ? "linear-gradient( 136deg, #ff8787 0%, #ff8787 50%, #ff8787 100%)"
          : slaSteps === 1
          ? "linear-gradient( 136deg, #e88f1b 0%, #e88f1b 50%, #e88f1b 100%)"
          : slaSteps === 2
          ? "linear-gradient( 136deg, #40c057 0%, #40c057 50%, #40c057 100%)"
          : "linear-gradient( 136deg, grey 0%, grey 50%, grey 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        ownerState?.label === "High"
          ? "linear-gradient( 136deg, #ff8787 0%, #ff8787 50%, #ff8787 100%)"
          : ownerState?.label === "Medium"
          ? "linear-gradient( 136deg, #e88f1b 0%, #e88f1b 50%, #e88f1b 100%)"
          : ownerState?.label === "Low"
          ? "linear-gradient( 136deg, #40c057 0%, #40c057 50%, #40c057 100%)"
          : "linear-gradient( 136deg, grey 0%, grey 50%, grey 100%)",
    }),
  }));
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  function ColorlibStepIcon(props) {
    const { active, completed, className, label } = props;

    const icons = {
      1:
        slaSteps === 0 ? (
          <ManageHistoryIcon
            fontSize="medium"
            sx={{ color: "white", mb: 0.3, mr: 0.1 }}
          />
        ) : (
          <Done fontSize="large" sx={{ color: "white", mb: 0.3, mr: 0.1 }} />
        ),
      2:
        slaSteps === 0 ? (
          <ManageHistoryIcon
            fontSize="medium"
            sx={{ color: "white", mb: 0.3, mr: 0.1 }}
          />
        ) : slaSteps === 1 ? (
          <ManageHistoryIcon
            fontSize="medium"
            sx={{ color: "white", mb: 0.3, mr: 0.1 }}
          />
        ) : slaSteps === 2 ? (
          <Done fontSize="large" sx={{ color: "white", mb: 0.3, mr: 0.1 }} />
        ) : null,
      3: (
        <ManageHistoryIcon
          fontSize="medium"
          sx={{ color: slaSteps === 2 ? "white" : "white", mb: 0.3, mr: 0.1 }}
        />
      ),
      // 1: <img alt="" src={one} style={{ height: "3vh", width: "1.5vw" }} />,
      // 2: <img alt="" src={one} style={{ height: "3vh", width: "1.5vw" }} />,
      // 3: <img alt="" src={one} style={{ height: "3vh", width: "1.5vw" }} />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, label }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <Box
      sx={{
        // height: "100%",
        // width: "100%",
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          // zIndex: 1000,
          // width: "100%",
          // height: 500,
          // minHeight:500,
          px: 5,
          py: 2,
          display: "flex",
          flexDirection: "column",
          borderRadius: 5,
          overflow: "auto",
          //   position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          //   borderTopRightRadius:100
          // boxShadow: priority ? ' 0px 50px 15px #ff8787' : '0px 50px 15px #37b24d'
          boxShadow: "none",
        }}
      >
        <Box sx={{ width: "100%", pt: 1 }}>
          <Stepper
            sx={{ width: "100%", pb: 5 }}
            alternativeLabel
            activeStep={slaSteps}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{}}
                  StepIconComponent={(props) => (
                    <ColorlibStepIcon {...props} label={label} />
                  )}
                >
                  {
                    <Box
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        fontFamily: "Lato",
                      }}
                    >
                      {label}
                    </Box>
                  }
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box>
            {slaSteps === 0 ? (
              <Box sx={{ display: "flex", pt: 0 }}>
                <Box
                  sx={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright",
                    p: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    background: "#fa5252",
                    color: "white",
                    height: 70,
                  }}
                >
                  High
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    pt: 5,
                    borderLeft: "1px solid #fa5252",
                    px: 5,
                  }}
                >
                  <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"high.level1.slaEstDays"}
                            key={"highLevel1Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "high.level1.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("high.level1");
                                }}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("high.level1")}
                                label={DAYS_LABEL}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                disabled={view}
                                inputRef={ref}
                                // helperText={
                                //     errors?.high?.level1?.slaEstDays
                                //         ? errors.high?.level1?.slaEstDays?.message
                                //         : false
                                // }
                                error={errorMessage?.highLevel1}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        <Controller
                          control={control}
                          name={`high.level1.slaEstHours`}
                          key={"highLevel1Hours"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.highLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.highLevel1}
                                defaultValue={value}
                                // multiple={true}
                                label={HOURS_LABEL}
                                onBlur={() => trigger("high.level1")}
                                filterSelectedOptions={true}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                disabled={view}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.high?.level1 ? errors.high?.level1?.message : false}
                                    error={errorMessage?.highLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  console.log(e, "uhuih");
                                  trigger("high.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        {" "}
                        <Controller
                          control={control}
                          name={`high.level1.slaEstMinutes`}
                          key={"highLevel1Mints"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.highLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={MINTS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.highLevel1}
                                defaultValue={value}
                                // multiple={true}
                                label={MINTS_LABEL}
                                disabled={view}
                                filterSelectedOptions={true}
                                onBlur={() => trigger("high.level1")}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={MINTS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.high?.level1?.slaEstMinutes ? errors.high?.level1?.slaEstMinutes?.message : false}
                                    error={errorMessage?.highLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("high.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    {/* {errors.high?.level1?.message ?
                    <Grid lg={12} md={12} sx={{ p: 0, m: 0 }}>
                        <Typography color={"red"} sx={{ ...errorFont, }}>{errors.high?.level1?.message}</Typography>
                    </Grid> : null} */}
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"high.level2.slaEstDays"}
                            key={"highLevel2Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "high.level2.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("high.level2");
                                }}
                                disabled={view}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("high.level2")}
                                label={DAYS_LABEL}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                inputRef={ref}
                                error={errorMessage?.highLevel2}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box>
                        <Controller
                          control={control}
                          key={"highLevel2Hours"}
                          name={`high.level2.slaEstHours`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.highLevel2}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.highLevel2}
                                defaultValue={value}
                                // multiple={true}
                                label={HOURS_LABEL}
                                filterSelectedOptions={true}
                                // isOptionEqualToValue={(option, value) => option === value}
                                onBlur={() => trigger("high.level2")}
                                value={value}
                                disabled={view}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.high?.level2?.slaEstHours ? errors.high?.level2?.slaEstHours?.message : false}
                                    error={errorMessage?.highLevel2}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("high.level2");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Controller
                        control={control}
                        name={`high.level2.slaEstMinutes`}
                        key={"highLevel2Mints"}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <FormControl
                            error={errorMessage?.highLevel2}
                            size="small"
                            fullWidth
                            sx={{}}
                          >
                            <SelectComponent
                              zeroInEmpty={value === 0}
                              DataOptions={MINTS || []}
                              labelName={(option) => option.toString()}
                              error={errorMessage?.highLevel2}
                              defaultValue={value}
                              // multiple={true}
                              onBlur={() => trigger("high.level2")}
                              label={MINTS_LABEL}
                              filterSelectedOptions={true}
                              disabled={view}
                              // isOptionEqualToValue={(option, value) => option === value}
                              value={value}
                              autoStyle={{ "& fieldset": { borderRadius: 3 } }}
                              renderInput={(parmas) => (
                                <TextField
                                  {...parmas}
                                  variant={"outlined"}
                                  label={MINTS_LABEL}
                                  sx={{ ...selectStyle }}
                                  // helperText={errors?.high?.level2?.slaEstMinutes ? errors.high?.level2?.slaEstMinutes?.message : false}
                                  error={errorMessage?.highLevel2}
                                  // InputProps={formFieldStyle.InputProps}
                                  InputLabelProps={
                                    formFieldStyle.InputLabelProps
                                  }
                                />
                              )}
                              sx={{ pr: 1, width: "100%" }}
                              handelChange={(e) => {
                                // trigger("OwnerUserid");
                                onChange(e);
                                trigger("high.level2");
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}
                  >
                    <Button
                      onClick={() => {
                        console.log(getValues("high.level1.slaEstDays"));
                        if (
                          (getValues("high.level1.slaEstDays") === 0 &&
                            getValues("high.level1.slaEstHours") === 0 &&
                            getValues("high.level1.slaEstMinutes") === 0) ||
                          (getValues("high.level2.slaEstDays") === 0 &&
                            getValues("high.level2.slaEstHours") === 0 &&
                            getValues("high.level2.slaEstMinutes") === 0)
                        ) {
                          trigger("high.level1");
                          trigger("high.level2");
                        } else {
                          setSlaSteps(1);
                        }
                        // setSlaSteps(1)
                      }}
                      size="small"
                      color="success"
                      sx={{
                        borderRadius: 5,
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        ":hover": { color: "white", background: "#3d9055" },
                      }}
                      variant="outlined"
                    >
                      Next{" "}
                      <KeyboardDoubleArrowRightRoundedIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : slaSteps === 1 ? (
              <Box sx={{ display: "flex", pt: 0 }}>
                <Box
                  sx={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright",
                    p: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    background: "#f08c00",
                    color: "white",
                    height: 100,
                  }}
                >
                  Medium
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    pt: 5,
                    borderLeft: "1px solid #f08c00",
                    px: 5,
                  }}
                >
                  <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"medium.level1.slaEstDays"}
                            key={"mediumLevel1Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "medium.level1.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("medium.level1");
                                }}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("medium.level1")}
                                label={DAYS_LABEL}
                                disabled={view}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                inputRef={ref}
                                // helperText={
                                //     errors?.medium?.level1?.slaEstDays
                                //         ? errors.medium?.level1?.slaEstDays?.message
                                //         : false
                                // }
                                error={errorMessage?.mediumLevel1}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        <Controller
                          control={control}
                          name={`medium.level1.slaEstHours`}
                          key={"mediumLevel1Hours"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.mediumLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.mediumLevel1}
                                defaultValue={value}
                                // multiple={true}
                                label={HOURS_LABEL}
                                disabled={view}
                                filterSelectedOptions={true}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                onBlur={() => trigger("medium.level1")}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.medium?.level1 ? errors.medium?.level1?.message : false}
                                    error={errorMessage?.mediumLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("medium.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        {" "}
                        <Controller
                          control={control}
                          name={`medium.level1.slaEstMinutes`}
                          key={"mediumLevel1Mints"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.mediumLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={MINTS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.mediumLevel1}
                                defaultValue={value}
                                // multiple={true}
                                label={MINTS_LABEL}
                                filterSelectedOptions={true}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                disabled={view}
                                onBlur={() => trigger("medium.level1")}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={MINTS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.medium?.level1?.slaEstMinutes ? errors.medium?.level1?.slaEstMinutes?.message : false}
                                    error={errorMessage?.mediumLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("medium.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"medium.level2.slaEstDays"}
                            key={"mediumLevel2Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "medium.level2.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("medium.level2");
                                }}
                                disabled={view}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("medium.level2")}
                                label={DAYS_LABEL}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                inputRef={ref}
                                error={errorMessage?.mediumLevel2}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box>
                        <Controller
                          control={control}
                          name={`medium.level2.slaEstHours`}
                          key={"mediumLevel2Hours"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.mediumLevel2}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.mediumLevel2}
                                defaultValue={value}
                                // multiple={true}
                                label={HOURS_LABEL}
                                filterSelectedOptions={true}
                                onBlur={() => trigger("medium.level2")}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                disabled={view}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.medium?.level2?.slaEstHours ? errors.medium?.level2?.slaEstHours?.message : false}
                                    error={errorMessage?.mediumLevel2}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("medium.level2");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Controller
                        control={control}
                        name={`medium.level2.slaEstMinutes`}
                        key={"mediumLevel2Mints"}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <FormControl
                            error={errorMessage?.mediumLevel2}
                            size="small"
                            fullWidth
                            sx={{}}
                          >
                            <SelectComponent
                              zeroInEmpty={value === 0}
                              DataOptions={MINTS || []}
                              labelName={(option) => option.toString()}
                              error={errorMessage?.mediumLevel2}
                              defaultValue={value}
                              // multiple={true}

                              label={MINTS_LABEL}
                              filterSelectedOptions={true}
                              // isOptionEqualToValue={(option, value) => option === value}
                              value={value}
                              disabled={view}
                              onBlur={() => trigger("medium.level2")}
                              autoStyle={{ "& fieldset": { borderRadius: 3 } }}
                              renderInput={(parmas) => (
                                <TextField
                                  {...parmas}
                                  variant={"outlined"}
                                  label={MINTS_LABEL}
                                  sx={{ ...selectStyle }}
                                  // helperText={errors?.medium?.level2?.slaEstMinutes ? errors.medium?.level2?.slaEstMinutes?.message : false}
                                  error={errorMessage?.mediumLevel2}
                                  // InputProps={formFieldStyle.InputProps}
                                  InputLabelProps={
                                    formFieldStyle.InputLabelProps
                                  }
                                />
                              )}
                              sx={{ pr: 1, width: "100%" }}
                              handelChange={(e) => {
                                // trigger("OwnerUserid");
                                onChange(e);
                                trigger("medium.level2");
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}
                  >
                    <Button
                      size="small"
                      onClick={() => setSlaSteps(0)}
                      sx={{
                        borderRadius: 5,
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        mr: 2,
                        ":hover": { color: "white", background: "#4289e4" },
                      }}
                      variant="outlined"
                    >
                      <KeyboardDoubleArrowLeftRoundedIcon fontSize="small" />
                      Prev
                    </Button>
                    <Button
                      size="small"
                      // onClick={() => setSlaSteps(2)}
                      onClick={() => {
                        console.log(getValues("medium.level1.slaEstDays"));
                        if (
                          (getValues("medium.level1.slaEstDays") === 0 &&
                            getValues("medium.level1.slaEstHours") === 0 &&
                            getValues("medium.level1.slaEstMinutes") === 0) ||
                          (getValues("medium.level2.slaEstDays") === 0 &&
                            getValues("medium.level2.slaEstHours") === 0 &&
                            getValues("medium.level2.slaEstMinutes") === 0)
                        ) {
                          trigger("medium.level1");
                          trigger("medium.level2");
                        } else {
                          setSlaSteps(2);
                        }
                        // setSlaSteps(1)
                      }}
                      color="success"
                      sx={{
                        borderRadius: 5,
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        ":hover": { color: "white", background: "#3d9055" },
                      }}
                      variant="outlined"
                    >
                      Next
                      <KeyboardDoubleArrowRightRoundedIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : slaSteps === 2 ? (
              <Box sx={{ display: "flex", pt: 0 }}>
                <Box
                  sx={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright",
                    p: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    background: "#37b24d",
                    color: "white",
                    height: 50,
                  }}
                >
                  Low
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    pt: 5,
                    borderLeft: "1px solid #37b24d",
                    px: 5,
                  }}
                >
                  <Grid container spacing={1.4} sx={{ pt: 0.5 }}>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"low.level1.slaEstDays"}
                            key={"lowLevel1Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "low.level1.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("low.level1");
                                }}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("low.level1")}
                                label={DAYS_LABEL}
                                disabled={view}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                inputRef={ref}
                                // helperText={
                                //     errors?.low?.level1?.slaEstDays
                                //         ? errors.low?.level1?.slaEstDays?.message
                                //         : false
                                // }
                                error={errorMessage?.lowLevel1}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        <Controller
                          control={control}
                          key={"lowLevel1Hours"}
                          name={`low.level1.slaEstHours`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.lowLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.lowLevel1}
                                defaultValue={value}
                                // multiple={true}
                                disabled={view}
                                label={HOURS_LABEL}
                                onBlur={() => trigger("low.level1")}
                                filterSelectedOptions={true}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.low?.level1 ? errors.low?.level1?.message : false}
                                    error={errorMessage?.lowLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("low.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ ...fieldWidth }}>
                        {" "}
                        <Controller
                          control={control}
                          name={`low.level1.slaEstMinutes`}
                          key={"lowLevel1Mints"}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.lowLevel1}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={MINTS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.lowLevel1}
                                defaultValue={value}
                                // multiple={true}
                                label={MINTS_LABEL}
                                filterSelectedOptions={true}
                                disabled={view}
                                onBlur={() => trigger("low.level1")}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={MINTS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.low?.level1?.slaEstMinutes ? errors.low?.level1?.slaEstMinutes?.message : false}
                                    error={errorMessage?.lowLevel1}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("low.level1");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    {/* {errors.low?.level1?.message ?
                    <Grid lg={12} md={12}>
                        <Typography color={"red"} sx={{ ...errorFont, }}>{errors.low?.level1?.message}</Typography>
                    </Grid> : null} */}
                    <Grid item lg={4} md={4} xs={12}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box sx={{ ...SideLabelStyle }}>{SLA_LEVEL2}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name={"low.level2.slaEstDays"}
                            key={"lowLevel2Days"}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "100%",
                                  minWidth: "100%",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                  ...selectStyle,
                                }}
                                onChange={(e) => {
                                  var numberRegex = /^\d+$/;
                                  const u = numberRegex.test(e.target.value);

                                  if (u || e.target.value === "") {
                                    setValue(
                                      "low.level2.slaEstDays",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value.trim()
                                    );
                                  }
                                  trigger("low.level2");
                                }}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onBlur={() => trigger("low.level2")}
                                disabled={view}
                                label={DAYS_LABEL}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                                value={value}
                                inputRef={ref}
                                error={errorMessage?.lowLevel2}
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box>
                        <Controller
                          key={"lowLevel2Hours"}
                          control={control}
                          name={`low.level2.slaEstHours`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <FormControl
                              error={errorMessage?.lowLevel2}
                              size="small"
                              fullWidth
                              sx={{}}
                            >
                              <SelectComponent
                                zeroInEmpty={value === 0}
                                DataOptions={HOURS || []}
                                labelName={(option) => option.toString()}
                                error={errorMessage?.lowLevel2}
                                defaultValue={value}
                                // multiple={true}
                                label={HOURS_LABEL}
                                filterSelectedOptions={true}
                                onBlur={() => trigger("low.level2")}
                                // isOptionEqualToValue={(option, value) => option === value}
                                value={value}
                                disabled={view}
                                autoStyle={{
                                  "& fieldset": { borderRadius: 3 },
                                }}
                                renderInput={(parmas) => (
                                  <TextField
                                    {...parmas}
                                    variant={"outlined"}
                                    label={HOURS_LABEL}
                                    sx={{ ...selectStyle }}
                                    // helperText={errors?.low?.level2?.slaEstHours ? errors.low?.level2?.slaEstHours?.message : false}
                                    error={errorMessage?.lowLevel2}
                                    // InputProps={formFieldStyle.InputProps}
                                    InputLabelProps={
                                      formFieldStyle.InputLabelProps
                                    }
                                  />
                                )}
                                sx={{ pr: 1, width: "100%" }}
                                handelChange={(e) => {
                                  // trigger("OwnerUserid");
                                  onChange(e);
                                  trigger("low.level2");
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Controller
                        control={control}
                        name={`low.level2.slaEstMinutes`}
                        key={"lowLevel2Mints"}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <FormControl
                            error={errorMessage?.lowLevel2}
                            size="small"
                            fullWidth
                            sx={{}}
                          >
                            <SelectComponent
                              zeroInEmpty={value === 0}
                              DataOptions={MINTS || []}
                              labelName={(option) => option.toString()}
                              error={errorMessage?.lowLevel2}
                              defaultValue={value}
                              // multiple={true}

                              label={MINTS_LABEL}
                              disabled={view}
                              filterSelectedOptions={true}
                              // isOptionEqualToValue={(option, value) => option === value}
                              onBlur={() => trigger("low.level1")}
                              value={value}
                              autoStyle={{ "& fieldset": { borderRadius: 3 } }}
                              renderInput={(parmas) => (
                                <TextField
                                  {...parmas}
                                  variant={"outlined"}
                                  label={MINTS_LABEL}
                                  sx={{ ...selectStyle }}
                                  // helperText={errors?.low?.level2?.slaEstMinutes ? errors.low?.level2?.slaEstMinutes?.message : false}
                                  error={errorMessage?.lowLevel2}
                                  // InputProps={formFieldStyle.InputProps}
                                  InputLabelProps={
                                    formFieldStyle.InputLabelProps
                                  }
                                />
                              )}
                              sx={{ pr: 1, width: "100%" }}
                              handelChange={(e) => {
                                // trigger("OwnerUserid");
                                onChange(e);
                                trigger("low.level2");
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}
                  >
                    <Button
                      size="small"
                      onClick={() => setSlaSteps(1)}
                      sx={{
                        borderRadius: 5,
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        mr: 2,
                        ":hover": { color: "white", background: "#4289e4" },
                      }}
                      variant="outlined"
                    >
                      <KeyboardDoubleArrowLeftRoundedIcon fontSize="small" />
                      Prev
                    </Button>
                    {/* <Button
                      onClick={() => setSlaSteps(0)}
                      color="success"
                      sx={{
                        borderRadius: 5,
                        fontSize: 12,
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                    >
                      Submit <DoubleArrowRoundedIcon fontSize="inherit" />
                    </Button> */}
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            width: "93%",
            display: "flex",
            justifyContent: "center",
            pt: 4,
            pl: 2,
          }}
        >
          <Controller
            control={control}
            name={`slaInfo`}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Box
                sx={{
                  width: "93%",
                }}
              >
                <FormControl
                  error={errors?.slaInfo ? errors.slaInfo.message : null}
                  size="small"
                  fullWidth
                  // sx={{ pt: 0.5 }}
                >
                  <SelectComponent
                    DataOptions={userList || []}
                    labelName={(option) =>
                      option?.firstname + " " + option?.lastname
                    }
                    errors={errors?.slaInfo ? errors.slaInfo.message : null}
                    // disabled={view}
                    defaultValue={value}
                    multiple={true}
                    disabled={view}
                    label={"Notify Users *"}
                    onBlur={() => trigger("slaInfo")}
                    filterSelectedOptions={true}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={value}
                    disableCloseOnSelect={true}
                    // ListboxProps={{ style: { maxHeight: 10, overflow: 'auto' } }}
                    // renderOptionData={(props, option, { selected }) => (
                    //   <li {...props}>
                    //     <Checkbox
                    //       icon={icon}
                    //       checkedIcon={checkedIcon}
                    //       style={{ marginRight: 8 }}
                    //       checked={selected}
                    //     />
                    //     {option?.firstname + " " + option?.lastname}
                    //   </li>
                    // )}
                    autoStyle={{ "& fieldset": { borderRadius: 3 } }}
                    renderInput={(parmas) => {
                      const { InputProps, ...restParams } = parmas;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...parmas}
                          variant={"outlined"}
                          label={"Notify Users *"}
                          sx={{ ...selectStyle }}
                          helperText={
                            errors?.slaInfo ? errors.slaInfo.message : null
                          }
                          error={
                            errors?.slaInfo ? errors.slaInfo.message : null
                          }
                          // InputProps={formFieldStyle.InputProps}
                          {...restParams}
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <>
                                <div
                                  style={{
                                    // height: 30,
                                    maxHeight: 50,
                                    width: "95%",
                                    overflowY: "auto",
                                  }}
                                >
                                  {startAdornment}
                                  <div
                                    ref={scrollingContainerRef}
                                    style={{ marginBlock: 13 }}
                                  ></div>
                                </div>
                              </>
                            ),
                          }}
                          InputLabelProps={formFieldStyle.InputLabelProps}
                        />
                      );
                    }}
                    sx={{ pr: 1, width: "100%" }}
                    handelChange={(e) => {
                      onChange(e);
                      autoScrollToBottom();
                      //   handelChangeTrobleShooter(e)
                      trigger("slaInfo");
                    }}
                  />
                </FormControl>
              </Box>
            )}
          />
        </Box>
      </Card>
    </Box>
  );
}
