import axios from "./axiosService";
// import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const orgData = async (active) => {
  try {
    return await axios({
      method: "GET",
      url: `${baseURL}/organization/info/?isActive=${active ? "True" : "False"}`,
    }).then((response) => {

      return response
    });
  } catch (error) {
  }
};

export const orgDetails = async (data, method) => {
  return await axios({
    method: method,
    url: `${baseURL}/organization/info/`,
    data: data,
  }).then((response) => {

    return response;
  });
};

// export const orgData = () => async (dispatch) => {
//     try {
//         await axios({
//             method: "GET",
//             url: `${baseURL}/OgaiApp/organization/`,

//         }).then((response) => {

//             if (response.data?.data && response.data?.data) {

//                 dispatch(updateMasterStates());
//             }
//         });
//     } catch (error) {
//         dispatch(updateMasterStates());
//     }
// };

// export const orgData = async () => {
//     return await axios({

//         method: 'GET',
//         url: `${baseURL}/OgaiApp/organization/`,
//     }).then((response) => {
//         dispatch(updateCommonStates({ name: "authToken", value: true }));
//         return response
//     })

// }

