import React from 'react';
import Lottie from 'lottie-react';


const LottiePlayer = (props) => {
    const { animationData, width, height } = props
    return (
        <div style={{ width: width || "100%", height: height, display: 'flex', justifyContent: 'center' }}>
            <Lottie animationData={animationData} loop={true} width={width} height={height} autoplay={true} />
        </div>
    );
};

export default LottiePlayer;
