import axios from "./axiosService";
const baseURL = process.env.REACT_APP_BASE_URL;
export const getNotificationList = async (
  pageSize,
  pageNumber,
  fromDate,
  toDate
) => {
  return await axios({
    method: "get",
    url: `${baseURL}/fcm/notificationHistory?pageSize=${pageSize}&pageNumber=${pageNumber}&fromDate=${fromDate}&toDate=${toDate}`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const messageAsRead = async (id) => {
  return await axios({
    method: "patch",
    url: `${baseURL}/fcm/notificationHistory?notificationId=${id}`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const getUnreadNotificationCount = async () => {
  return await axios({
    method: "get",
    url: `${baseURL}/fcm/unreadNotifyCount`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
