import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

function DialogComponent(props) {
    const { open, close, body, footer } = props
    return (<Dialog open={open} onClose={close}>
        <DialogContent>
            <Box sx={{ p: 1 }}>
                {body}

            </Box>
        </DialogContent>
        <DialogActions>
            <Box>{footer}</Box>
        </DialogActions>
    </Dialog>);
}

export default DialogComponent;
