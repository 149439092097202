import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { Chart } from "react-google-charts";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import { getDashboardAction, getProjectListinDashboardAction } from "../../redux/action/action";
import TaskCard from "./taskCard";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { USER_DASHBOARD_COLORS, jobPageColor } from "../../utils/CommonColors";
import { allpageFontStyle } from "../../utils/FontStyle";
import DataGride from "../../components/datagride/DataGride";
// import GoogleChart from "../../components/commonComponents/GoogleChart";
// import { selectOptions } from "../dashboard/CardcountDetaile";
// import { getUserDashboardGraphdata } from "../../service/dashboardService";


const cardStyle = {
  ...allpageFontStyle.typography1,
  pt: 1,

  fontWeight: "bold",
  color: "white",
};
const numberStyle = {
  ml: 1,
  fontSize: '1.5rem',
  fontFamily: 'Times New Roman',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: "white",
  fontWeight: 'bold',

}
// const data1 = [
//   [
//     "Week",
//     "overAllTasks"
//   ],
//   [
//     "1-Week",
//     0
//   ],
//   [
//     "2-Week",
//     0
//   ],
//   [
//     "3-Week",
//     0
//   ],
//   [
//     "4-Week",
//     0
//   ],
//   [
//     "5-Week",
//     0
//   ]
// ];
// const optiontaskChart = {
//   // title: "Project",
//   curveType: "function",
//   backgroundColor: grey[100],
//   series: [{ color: "#E7711B" }, { color: "#00E9D0" }, { color: "#6284FF" }],
//   legend: { position: "bottom" },
// };
export default function Dashboard({ handleStart, handleResume }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(false)
  // const ref1 = useRef(false)
  const [selectOptionData, setselectOptionData] = useState();
  // const [chartFilter, setChartFilter] = useState({
  //   job: "",
  //   task: "",
  //   taskRevert: "",
  //   project: "",
  // });
  // const [chartData, setChartData] = useState({
  //   job: "",
  //   task: "",
  //   taskRevert: "",
  //   project: "",
  // });
  const [filterData, setFilterData] = useState({
    projectInfo: "",
  });
  const { dashboardData,

  } = useSelector((state) => state.profile);
  const { globapOrganization, autoLoad } = useSelector((state) => state.presist);
  const { projectListDashboard } = useSelector((state) => state.master);
  useEffect(() => {
    // dispatch(getDashboardAction('post', globapOrganization?.orgId))
  }, [globapOrganization])
  const navToTask = (type) => {
    if (type === 'nav') { navigate('/tasks/revert') }
    else if (type === "query") {
      navigate('/helpdesk', { state: { tab: 1 } })
    }
    else {
      navigate('/tasks')
    }
  }
  useEffect(() => {
    if (globapOrganization?.orgId) {
      dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    }

  }, [globapOrganization]);
  const handelSelect = (e, type) => {
    if (type === "Project") {
      if (e) {
        setFilterData({ ...filterData, projectInfo: e });
        dispatch(getDashboardAction('post', globapOrganization?.orgId, { projectCode: e }))
        // loadData(e)
      } else {

        setFilterData({ ...filterData, projectInfo: selectOptionData[0] });
        dispatch(getDashboardAction('post', globapOrganization?.orgId, { projectCode: "All" }))
        setFilterData({ ...filterData, projectInfo: "" });
      }
    }
  };
  const construtProjectdata = (arr) => {
    const res = arr.map((val) => val?.projectCode);
    return ["All", ...res];
  };
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard)
      setselectOptionData(project)
    } else {
      setselectOptionData(["All"])
    }
  }, [projectListDashboard])
  useEffect(() => {
    if (selectOptionData && selectOptionData.length > 0 && globapOrganization?.orgId) {
      setFilterData({ ...filterData, projectInfo: selectOptionData[0] });
      if (!ref.current) {

        dispatch(getDashboardAction('post', globapOrganization?.orgId, { projectCode: 'All' }))
        ref.current = true
      }
    }
  }, [selectOptionData]);
  // const loadData = async (e) => {
  //   try {
  //     const orgId = globapOrganization?.orgId

  //     const payloadTask = {
  //       projectCode: e ? e : "All",
  //       viewRange: selectOptions[0]?.value,
  //       selectType: "overAllTasks"
  //     };
  //     setChartFilter({
  //       ...chartFilter,
  //       task: selectOptions[0],
  //     });
  //     const task = await getUserDashboardGraphdata(orgId, payloadTask);
  //     setChartData({
  //       ...chartData,
  //       task: task,
  //     });

  //   } catch (error) {

  //   }
  // };
  // useEffect(() => {
  //   if (!ref1.current) {
  //     loadData();
  //     ref1.current = true
  //   }
  // }, []);
  // const handelFilter = async (e, type) => {
  //   try {
  //     const orgId = globapOrganization?.orgId
  //     if (type === "task") {
  //       if (e) {
  //         const payload = {
  //           projectCode: filterData.projectInfo,
  //           viewRange: e?.value,
  //           selectType: "overAllTasks"
  //         };
  //         setChartFilter({ ...chartFilter, task: e });
  //         const res = await getUserDashboardGraphdata(orgId, payload);
  //         setChartData({ ...chartData, task: res });
  //       }
  //     }

  //   } catch (error) {

  //   }
  // };
  // const tittleStyle = {
  //   textAlign: 'center',
  //   flex: 1
  // }
  // const tittleFontStyle = {
  //   fontFamily: 'Lato',
  //   fontWeight: 'bold'
  // }
  // const ChartEmtyBoxstyle = {
  //   flex: { lg: 1, sm: 1, md: 1 }
  // }
  // const titleOption = {
  //   project: <Box sx={tittleStyle}><Typography sx={tittleFontStyle}>Project</Typography></Box>,
  //   job: <Box sx={tittleStyle}><Typography sx={tittleFontStyle}>Job</Typography></Box>,
  //   task: <Box sx={tittleStyle}><Typography sx={tittleFontStyle}>Task</Typography></Box>,
  //   revert: <Box sx={tittleStyle}><Typography sx={tittleFontStyle}>Task Revert</Typography></Box>
  // }



  // Page Auto Reload By the Task Status changes 


  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      dispatch(getDashboardAction('post', globapOrganization?.orgId, { projectCode: filterData?.projectInfo ? filterData?.projectInfo : 'All' }))
      dispatch(updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false, }, }));
    }
  }, [autoLoad])
  const legendStyle = {
    ...allpageFontStyle.title1,
    padding: 3,
    borderRadius: 6,
  }
  const fieldsetStyle = {
    borderRadius: 6,
  }
  return (
    <Box sx={{ flex: 1, minHeight: 600, background: grey[100], px: 3, py: 2 }}>
      <Box>
        <Grid container spacing={2} sx={{ pl: 1, pb: 2, pt: 2 }}>
          <Grid item xs={6} md={4} lg={3}>
            <SelectComponent
              DataOptions={selectOptionData || []}
              labelName={(option) => option}
              defaultValue={filterData?.projectInfo}
              clearIcon={true}
              value={filterData?.projectInfo}
              sx={{ pr: 1 }}
              label={"Project"}
              handelChange={(e) => handelSelect(e, "Project")}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{ px: 1 }}>
        <Grid xs={12} sm={12} lg={8.5} >
          <fieldset style={{ border: `1px solid ${USER_DASHBOARD_COLORS.taskBorderColor}`, backgroundColor: USER_DASHBOARD_COLORS.taskBorderBackground, ...fieldsetStyle }}>
            <legend style={{ color: "white", backgroundColor: USER_DASHBOARD_COLORS.taskBorderColor, ...legendStyle }}>Task Details</legend>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} lg={2.4} md={4} >
                <Card
                  onClick={() => navToTask()}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.overall,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Overall
                    {/* <br /> Tasks */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.overAllTasks || 0}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} lg={2.4} md={4} >
                <Card
                  onClick={() => navToTask()}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.pending,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Pending
                    {/* <br /> Tasks */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.pendingTasks || 0}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} lg={2.4} md={4} >
                <Card
                  onClick={() => navToTask()}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.inprogresstask,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Inprogress
                    {/* <br /> Tasks */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.inProgressTasks || 0}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} lg={2.4} md={4} >
                <Card
                  onClick={() => navToTask("nav")}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.revert,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Reverted
                    {/* <br /> Tasks */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.revertedTasks || 0}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} lg={2.4} md={4} >
                <Card
                  onClick={() => navToTask()}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.completed,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Completed
                    {/* <br /> Tasks */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.completedTasks || 0}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
        <Grid xs={12} sm={12} lg={3.5} >
          <fieldset style={{ border: `1px solid ${USER_DASHBOARD_COLORS.queryBorderColor}`, backgroundColor: USER_DASHBOARD_COLORS.queryBorderBackground, ...fieldsetStyle }}>
            <legend style={{ color: "white", backgroundColor: USER_DASHBOARD_COLORS.queryBorderColor, ...legendStyle }}>Query Details</legend>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={6} md={4} >
                <Card
                  onClick={() => navToTask("query")}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.querypending,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Open
                    {/* <br /> Queries */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.openQuery || 0}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} md={4} >
                <Card
                  onClick={() => navToTask("query")}
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: USER_DASHBOARD_COLORS.queryclosed,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={cardStyle}>
                    Resolved
                    {/* <br /> Queries */}
                  </Box>
                  <Box sx={numberStyle}>
                    {dashboardData?.taskCount?.resolvedQuery || 0}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>

      </Grid>
      {/* <Grid container spacing={3} sx={{ pb: 2, pt: 5, }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card sx={{ boxShadow: 'none', border: "1px solid", borderColor: '#17aed1', backgroundColor: grey[100], p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={ChartEmtyBoxstyle}></Box>
              {titleOption.task}
              <Box sx={{ textAlign: "start", flex: 1.1, mb: 1 }}>
                <SelectComponent
                  DataOptions={selectOptions || []}
                  labelName={(option) => option.label}
                  defaultValue={chartFilter?.task}
                  clearIcon={true}
                  sx={{ width: "100%", pr: 1 }}
                  value={chartFilter?.task}
                  label={"Select"}
                  handelChange={(e) => handelFilter(e, "task")}
                />
              </Box>
            </Box>
            <Box sx={{}}>
              <GoogleChart
                data={chartData?.task?.length > 0 ? chartData?.task : data1}
                chartType={"LineChart"}
                options={optiontaskChart}
                width={"100%"}
                height={300}
              />
            </Box>
          </Card>
        </Grid>
      </Grid> */}
      {/* <Box sx={{ m: 1, flex: 1 }}>
        <DataGride
          columns={columns}
          customHeight={450}
          onSelectionModelChange={onSelectionModelChange}
          deleteFuntion={handleDeleteClick}
          tableData={constructData || []}
          hideDeletion={
            !allowedPages().settings.organization.delete
          }
        />
      </Box> */}
      <Box sx={{ m: 1, flex: 1 }}>
        <TaskCard handleStart={handleStart} handleResume={handleResume} />
      </Box>
    </Box >
  );
}
