import { Box } from "@mui/material";
import { NameWithStatusColor, TASK_STATUS_LIST } from "./statusView";

const ColorCode = () => {
  return (
    <Box sx={{ display: "flex", justifyContent:'flex-end', gap:2,  p:1, background:'#ffffff'}}>
      <NameWithStatusColor name={TASK_STATUS_LIST.Assigned} status={TASK_STATUS_LIST.Assigned} isColorCode />
      <NameWithStatusColor name={TASK_STATUS_LIST["In-Progress"]} status={TASK_STATUS_LIST["In-Progress"]} isColorCode />
      <NameWithStatusColor name={TASK_STATUS_LIST.completed} status={TASK_STATUS_LIST.completed} isColorCode />
      <NameWithStatusColor name={TASK_STATUS_LIST.pending} status={TASK_STATUS_LIST.pending} isColorCode />
      <NameWithStatusColor name={TASK_STATUS_LIST["Revert Requested"]} status={TASK_STATUS_LIST["Revert Requested"]} isColorCode />
      <NameWithStatusColor name={TASK_STATUS_LIST.End} status={TASK_STATUS_LIST.End} isColorCode />
    </Box>
  );
};

export default ColorCode;
