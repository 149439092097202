import Swal from "sweetalert2";

export const conformPopup = (title, text, sucessFunction,confirmButtonText,cancelButtonText) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: "warning",
    showCancelButton: true,
    // confirmButtonColor: "#fff",
    // cancelButtonColor: "#fff",
    confirmButtonText: confirmButtonText || "Yes",
    cancelButtonText: cancelButtonText || "No",
  
  }).then(async (res) => {
    if (res.value) {
      await sucessFunction();
    }
  });
};


export const alertPopup = (text) => {
  return Swal.fire({
    title: 'Alert',
    text: text,
    icon: "warning",
    confirmButtonText: "Ok",
  })
};
