import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
// import { DataGrid } from "@mui/x-data-grid";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  BorderColor,
  CalendarTodayTwoTone,
  Close,
  CloseRounded,
  DomainVerification,
  Done,
  Edit,
  Info,
  Visibility,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment/moment";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import DataGride, {
  headerBackground,
} from "../../components/datagride/DataGride";
// import { getJobData, getjobcode } from "../../service/masterService";
import { useSelector } from "react-redux";
// import axios from "axios";
// import { CreateJobService, CreateJobServiceValid } from "../../service/taskService";
// import { min } from "moment/moment";
import SLAMaster from "../../components/helpdesk/SLAMaster";
import DialogComponent from "../../components/commonComponents/DialogComponent";
import job from "../../pics/check.png";
import {
  CreateJobServiceAction,
  CreateJobServiceValidAction,
  getExcelAction,
  getJobDataAction,
  getProjectCodeListAction,
  getjobcodeAction,
} from "../../redux/action/action";
// import SelectComponent from "../../components/commonComponents/SelectComponent";
// import { async } from "q";
// import { GridActionsCellItem } from "@mui/x-data-grid";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import {
  containStyleTypo,
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
  labelStyleinCreateJob,
} from "../../utils/Style";
import {
  CustomDialog,
  PopupMessage,
  ProgressBar,
  TriggerAlert,
  TypographyCustom,
  checkBoxFilter,
  removeDuplicatesFromArray,
} from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import JobSLA from "./sla/JobSLA";
import {
  CreateSLAAction,
  EditJobList,
  getCreateJobService,
  getSlaList,
} from "../../service/taskService";
import {
  getEmployeeList,
  getEmployeeService,
} from "../../service/employeeService";
import { jobPageColor } from "../../utils/CommonColors";
import { RESET_LABEL } from "../../utils/textName";
import ViewPopUp from "../../utils/viewPopUp";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { red } from "@mui/material/colors";
import DateComponet from "../../components/commonComponents/DateComponet";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from "date-fns/locale";
import EyeIcon from "../../components/commonComponents/eyeicon";
import Swal from "sweetalert2";
import PopupComponent from "../../components/commonComponents/PopupComponent";
const DAYERROR = "DAY Required";
const MINTSERROR = "Mints Required";
const HOURSERROR = "Hours Required";
const LEVELERROR = "Oops! At least one field should have data.";

const schema = yup.object().shape({
  orgId: yup.string(),
  projectName: yup.string(),
  jobCode: yup.string(),
  dueDate: yup.string().required("Due Date Required"),
  receivedDate: yup.string().required("Recevied Date Required"),
  projectCode: yup.string().required("Project code Required"),
  displaycolumns: yup.string().required("Column Header Required"),
  slaInfo: yup
    .array()
    .min(1, "Notify Users Required")
    .required("Notify Users Required"),
  high: yup.object({
    level1: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level1", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
    level2: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level2", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
  }),
  medium: yup.object({
    level1: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level1", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
    level2: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level2", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
  }),
  low: yup.object({
    level1: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level1", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
    level2: yup
      .object({
        slaEstDays: yup.number().nullable(),
        slaEstHours: yup.number().nullable(),
        slaEstMinutes: yup.number().nullable(),
      })
      .test("level2", LEVELERROR, function (values) {
        if (
          values.slaEstDays > 0 ||
          values.slaEstHours > 0 ||
          values.slaEstMinutes > 0
        ) {
          return true; // At least one field contains a value above 0
        }
        return false; // None of the fields contain a value above 0
      }),
  }),
});
export default function CreateJob() {
  const { projectCodeDta, JobList, projectByJobCode } = useSelector(
    (state) => state.master
  );
  console.log(projectByJobCode, "responseeee");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { globapOrganization } = useSelector((state) => state.presist);
  const inputRef = React.useRef(null);
  const inputRef1 = React.useRef(null);
  const fileRef = React.useRef(null);
  const [fileValue, setFileValue] = React.useState();
  const [remarks, setRemarks] = React.useState("");
  const [date, setDate] = React.useState("");
  const [editpage, setEditPage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [columns2, setColumns2] = React.useState([]);
  const [constructData, setConstructData] = React.useState([]);
  const [submittedRes, setSubmittedRes] = React.useState([]);
  const [multiSelectValue, setMultiSelectValue] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [excelpopup, setExcelpopup] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [projectCode1, setprojectCode1] = React.useState("");
  const [loading1, setLoading1] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [valueDate, setValueDate] = React.useState({
    reciveDate: null,
    closeDate: null,
  });
  const [stateValues, setStateValues] = React.useState({
    fromDate: null,
    toDate: null,
  });
  const [viewdata, setViewData] = React.useState({
    slaDetails: [],
    userInfo: [],
    fields: [],
  });
  const [userList, setUserList] = React.useState([]);
  const scrollingContainerRef1 = React.useRef(null);
  const legendStyle = {
    fontFamily: "Lato",
    padding: "2px 25px",
    borderRadius: 6,
  };
  const [dailog, setDailog] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      handelClear();
      setEditPage(false);
      navigate("/jobList");
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClear();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      reset();
      handelClear();
      setEditPage(false);
      navigate("/jobList");
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      getJobDataAction(
        globapOrganization?.orgId,
        {
          orgId: globapOrganization?.orgId,
          projectCode: projectCode1?.projectCode || null,
          fromDate: stateValues.fromDate,
          toDate: stateValues.toDate,
        },
        itemPerPage,
        pageNumber,
        e ? "&isActive=True" : "&isActive=False"
      )
    );
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await EditJobList(
          "put",
          { id: [row?.id] },
          `/job/delete/${globapOrganization?.orgId}/`
        );
        handelActiveInactive(false);
      }
    });
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await EditJobList(
          "delete",
          { id: row },
          `/job/delete/${globapOrganization?.orgId}/`
        );
        setSelectionState([]);
        handelActiveInactive(true);
      }
    });
  };
  const constructOrderData = (arr) => {
    const result =
      arr &&
      arr.length > 0 &&
      arr.map((val, i) => ({
        ...val.userInfo,
        // gradeName: val.gradeInfo?.gradeName,
        // depName: val.departmentInfo?.depName,
        // desigName: val.designationInfo?.desigName,
        // superUser: val.userAccess?.is_superuser,
      }));
    return result;
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const UserListAPIFun = async () => {
    try {
      const res = await getEmployeeList(globapOrganization.orgId);
      console.log(res, "reswe");
      if (res) setUserList(res?.data?.data);
    } catch (error) {}
  };
  React.useEffect(() => {
    UserListAPIFun();
  }, []);
  const viewPop = async (row) => {
    const res = await getSlaList(
      `${globapOrganization.orgId}?projectCode=${row?.projectCode}&jobCode=${row?.jobCode}&orgId=${globapOrganization?.orgId}`
    );
    if (res) {
      const userfilterData = userList?.filter((val) =>
        res[0]?.slaInfo.includes(val.empId)
      );
      const dynamicField = row.displayColumns.split(",");
      setViewData({
        slaDetails: res,
        userInfo: userfilterData,
        fields: dynamicField,
      });
      console.log(res, userfilterData, row?.displayColumns, "rowdata");
    }
  };
  const handelEditFun = async (row, type) => {
    dispatch(getjobcodeAction(row?.projectCode));
    try {
      const res = await getSlaList(
        `${globapOrganization.orgId}?projectCode=${row?.projectCode}&jobCode=${row?.jobCode}&orgId=${globapOrganization?.orgId}`
      );
      console.log(res, "res");
      if (res) {
        const userfilterData = userList?.filter((val) =>
          res[0]?.slaInfo.includes(val.empId)
        );
        const dynamicField = row.displayColumns.split(",");
        setSelectedValue(dynamicField);
        setEditPage(true);
        if (!type) {
          setEdit(true);
        }
        navigate("/createjob");
        setValue("projectCode", row?.projectCode);
        setValue("jobCode", row?.jobCode);
        setValue("projectName", row?.projectName);
        setValue("displaycolumns", row?.displayColumns);
        setValue("dueDate", moment(row?.dueDate).format("YYYY-MM-DD"));
        setValue(
          "receivedDate",
          moment(row?.receivedDate).format("YYYY-MM-DD")
        );
        const datestart = moment(row?.receivedDate).format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
        );
        const dateend = moment(row?.dueDate).format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
        );
        console.log(datestart, dateend, "date");
        setValueDate({
          closeDate: new Date(datestart),
          reciveDate: new Date(dateend),
        });
        setValue("slaInfo", userfilterData || []);
        setValue("high.level1.slaEstDays", res[0].slaEstDays);
        setValue("high.level1.slaEstHours", res[0].slaEstHours);
        setValue("high.level1.slaEstMinutes", res[0].slaEstMinutes);
        setValue("high.level2.slaEstDays", res[1].slaEstDays);
        setValue("high.level2.slaEstHours", res[1].slaEstHours);
        setValue("high.level2.slaEstMinutes", res[1].slaEstMinutes);
        setValue("medium.level1.slaEstDays", res[2].slaEstDays);
        setValue("medium.level1.slaEstHours", res[2].slaEstHours);
        setValue("medium.level1.slaEstMinutes", res[2].slaEstMinutes);
        setValue("medium.level2.slaEstDays", res[3].slaEstDays);
        setValue("medium.level2.slaEstHours", res[3].slaEstHours);
        setValue("medium.level2.slaEstMinutes", res[3].slaEstMinutes);
        setValue("low.level1.slaEstDays", res[4].slaEstDays);
        setValue("low.level1.slaEstHours", res[4].slaEstHours);
        setValue("low.level1.slaEstMinutes", res[4].slaEstMinutes);
        setValue("low.level2.slaEstDays", res[5].slaEstDays);
        setValue("low.level2.slaEstHours", res[5].slaEstHours);
        setValue("low.level2.slaEstMinutes", res[5].slaEstMinutes);
      }
    } catch (error) {}
  };
  const handelShowTheSLA = async (row) => {
    try {
      if (row?.length > 0) {
        const userfilterData = userList?.filter((val) =>
          row[0]?.slaInfo.includes(val.empId)
        );

        setValue("slaInfo", userfilterData || []);
        setValue("high.level1.slaEstDays", row[0].slaEstDays);
        setValue("high.level1.slaEstHours", row[0].slaEstHours);
        setValue("high.level1.slaEstMinutes", row[0].slaEstMinutes);
        setValue("high.level2.slaEstDays", row[1].slaEstDays);
        setValue("high.level2.slaEstHours", row[1].slaEstHours);
        setValue("high.level2.slaEstMinutes", row[1].slaEstMinutes);
        setValue("medium.level1.slaEstDays", row[2].slaEstDays);
        setValue("medium.level1.slaEstHours", row[2].slaEstHours);
        setValue("medium.level1.slaEstMinutes", row[2].slaEstMinutes);
        setValue("medium.level2.slaEstDays", row[3].slaEstDays);
        setValue("medium.level2.slaEstHours", row[3].slaEstHours);
        setValue("medium.level2.slaEstMinutes", row[3].slaEstMinutes);
        setValue("low.level1.slaEstDays", row[4].slaEstDays);
        setValue("low.level1.slaEstHours", row[4].slaEstHours);
        setValue("low.level1.slaEstMinutes", row[4].slaEstMinutes);
        setValue("low.level2.slaEstDays", row[5].slaEstDays);
        setValue("low.level2.slaEstHours", row[5].slaEstHours);
        setValue("low.level2.slaEstMinutes", row[5].slaEstMinutes);
      } else {
        setValue("slaInfo", []);
        setValue("high.level1.slaEstDays", 0);
        setValue("high.level1.slaEstHours", 0);
        setValue("high.level1.slaEstMinutes", 0);
        setValue("high.level2.slaEstDays", 0);
        setValue("high.level2.slaEstHours", 0);
        setValue("high.level2.slaEstMinutes", 0);
        setValue("medium.level1.slaEstDays", 0);
        setValue("medium.level1.slaEstHours", 0);
        setValue("medium.level1.slaEstMinutes", 0);
        setValue("medium.level2.slaEstDays", 0);
        setValue("medium.level2.slaEstHours", 0);
        setValue("medium.level2.slaEstMinutes", 0);
        setValue("low.level1.slaEstDays", 0);
        setValue("low.level1.slaEstHours", 0);
        setValue("low.level1.slaEstMinutes", 0);
        setValue("low.level2.slaEstDays", 0);
        setValue("low.level2.slaEstHours", 0);
        setValue("low.level2.slaEstMinutes", 0);
      }
    } catch (error) {}
  };
  const handelViewFun = (row) => {
    setView(true);
    handelEditFun(row, "type");
  };
  const columns = [
    { field: "projectCode", headerName: "Project Code", width: 200 },
    { field: "jobCode", headerName: "Job Code", width: 200 },
    {
      field: "receivedDate",
      headerName: "Received Date",
      width: 200,
      valueGetter: (params) =>
        moment(params?.row?.receivedDate).format("DD/MM/YYYY"),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 200,
      valueGetter: (params) =>
        moment(params?.row?.dueDate).format("DD/MM/YYYY"),
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 200,
      valueFormatter: (params) =>
        moment(params.value, "YYYY-MM-DDThh:mm:ssz").format("DD/MM/YYYY"),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      valueGetter: (params) =>
        params?.row?.createUserInfo?.firstname +
        " " +
        params?.row?.createUserInfo?.lastname,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        params?.row?.status === "Active" ? "Open" : params?.row?.status,
      width: 200,
      minWidth: 200,
    },
    {
      field: "Action",
      flex: 1,
      headerName: "Action",
      sortable: false,
      renderCell: (param) => (
        <Box sx={{ display: "flex", gap: 2 }}>
          {isActive && (
            <Tooltip title="Edit" placement="left">
              {" "}
              <Edit
                sx={{ cursor: "pointer", color: "#358c4e" }}
                onClick={() => handelEditFun(param.row)}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="View" placement="right">
            <Visibility sx={{ cursor: "pointer", color: '#358c4e' }} onClick={() => handelViewFun(param.row)} />
          </Tooltip> */}
          {!isActive ? (
            <Tooltip title="Restore" placement="top">
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <Done
                  onClick={() => handelActivetingUser(param.row)}
                  sx={{ color: "#358c4e" }}
                />
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
          {isActive && (
            <Tooltip title="View" placement="right">
              <Box
                onClick={() => viewPop(param.row)}
                sx={{
                  p: 0,
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <ViewPopUp value={true} job={param.row} slaDetails={viewdata} />
              </Box>
            </Tooltip>
          )}
        </Box>
      ),
      width: 200,
      minWidth: 200,
    },
    // { field: "createdTime", headerName: "Created Time", width: 150 },
    // { field: "status", headerName: "Status", width: 100, flex: 1 },
  ];
  // const handelEditFun = async (params) => {
  //   // const findData = usersList && usersList.length > 0 && usersList.find((val) => val.value === params.value)

  //   let res = [...submittedRes];
  //   const filter =
  //     submittedRes &&
  //     submittedRes.length > 0 &&
  //     submittedRes.findIndex((val) => val.id === params.id);

  //   if (res && res.length > 0) {
  //     const findDuplicte = res.findIndex((val) => val.id === params.id);
  //     if (findDuplicte !== -1) {
  //       res[findDuplicte].productionHours = params.props.value;
  //     } else {
  //       res[filter].productionHours = params.props.value;
  //     }
  //   } else {
  //     res[filter].productionHours = params.props.value;
  //   }

  // };
  const autoScrollToBottom = () => {
    if (scrollingContainerRef1.current) {
      scrollingContainerRef1.current.scrollIntoView();
    }
  };

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      orgId: "",
      jobCode: "",
      projectName: "",
      projectCode: "",
      receivedDate: null,
      dueDate: null,
      displaycolumns: "",
      slaInfo: [],
      high: {
        level1: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
        level2: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
      },
      medium: {
        level1: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
        level2: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
      },
      low: {
        level1: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
        level2: {
          slaEstDays: 0,
          slaEstHours: 0,
          slaEstMinutes: 0,
        },
      },
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;

  const fieldContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    p: 0.8,
  };
  const branchListConstruct = (arr) => {
    const res = arr.map((val, i) => ({
      createUserInfo: val?.createUserInfo,
      updatedUserInfo: val?.updatedUserInfo,
      ...val.jobInfo,
    }));
    return res;
  };
  React.useEffect(() => {
    const day = async () => {
      if (JobList && JobList?.jobDetail?.length > 0) {
        // setConstructData([]);
        const constructDatavalue = branchListConstruct(JobList?.jobDetail);
        // await setConstructData(constructDatavalue);
        await setActive(!active);
        setConstructData((per) => {
          const arr = [...constructDatavalue];
          return removeDuplicatesFromArray(arr);
        });
        if (JobList?.pageInfo?.num_pages) {
          setTotalPage(JobList.pageInfo?.total_items);
        }
      } else {
        setConstructData([]);
      }
    };
    day();
  }, [JobList]);
  React.useEffect(() => {
    return () => {
      setItemPerPage(100);
      setPageNumber(0);
      setTotalPage(0);
    };
  }, []);
  React.useEffect(() => {
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
    setConstructData([]);
    dispatch(
      getJobDataAction(
        globapOrganization?.orgId,
        {
          orgId: globapOrganization?.orgId,
          projectCode: projectCode1?.projectCode || null,
          fromDate: stateValues.fromDate,
          toDate: stateValues.toDate,
        },
        itemPerPage,
        pageNumber,
        isActive ? "&isActive=True" : "&isActive=False"
      )
    );
  }, [globapOrganization]);
  React.useEffect(() => {
    if (projectByJobCode?.jobCode && getValues("projectCode"))
      setValue(
        "jobCode",
        projectByJobCode?.jobCode ? projectByJobCode.jobCode : ""
      );
  }, [projectByJobCode]);
  const handelClear = () => {
    // setEditPage(false)
    // navigate('/jobList')
    setSubmittedRes([]);
    setSelectedValue([]);
    setMultiSelectValue([]);
    setValueDate({ closeDate: null, reciveDate: null });
    setEdit(false);
    reset();
    if (!edit && !view) {
      fileRef.current.value = null;
    }
    setFileValue("");
  };
  const constructPaylaod = (arr) => {
    const res = arr.map((val) => ({
      ...val,
      // updatedBy: userprofile?.userAccess?.userName,
    }));
    return res;
  };

  const constructColumn = (data) => {
    const customColumn = data?.columnHeaders?.map((item, i) => {
      return {
        field: item.key,
        headerName: item.headerName,
        width: i == 0 ? 90 : 190,
        align: "left",
        flex: i === data?.columnHeaders.length - 1 ? 1 : "",
        headerAlign: "left",
        // valueGetter: (params) => params.row[item.key] === 'Active' ? 'Open' : params.row[item.key] === 'In-Active' ? 'Pending' : params.row[item.key],
        autoWidth: true,
      };
    });
    return customColumn;
  };
  const onSubmit = async (data) => {
    const userList = data.slaInfo.map((val) => val?.empId);
    const payload = [
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "HIGH",
        slaLevel: "LEVEL_1",
        slaEstDays: data?.high?.level1?.slaEstDays || 0,
        slaEstHours: data?.high?.level1?.slaEstHours || 0,
        slaEstMinutes: data?.high?.level1?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "HIGH",
        slaLevel: "LEVEL_2",
        slaEstDays: data?.high?.level2?.slaEstDays || 0,
        slaEstHours: data?.high?.level2?.slaEstHours || 0,
        slaEstMinutes: data?.high?.level2?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "MEDIUM",
        slaLevel: "LEVEL_1",
        slaEstDays: data?.medium?.level1?.slaEstDays || 0,
        slaEstHours: data?.medium?.level1?.slaEstHours || 0,
        slaEstMinutes: data?.medium?.level1?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "MEDIUM",
        slaLevel: "LEVEL_2",
        slaEstDays: data?.medium?.level2?.slaEstDays || 0,
        slaEstHours: data?.medium?.level2?.slaEstHours || 0,
        slaEstMinutes: data?.medium?.level2?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "LOW",
        slaLevel: "LEVEL_1",
        slaEstDays: data?.low?.level1?.slaEstDays || 0,
        slaEstHours: data?.low?.level1?.slaEstHours || 0,
        slaEstMinutes: data?.low?.level1?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
      {
        orgId: globapOrganization?.orgId,
        projectCode: data?.projectCode,
        jobCode: data.jobCode,
        slaType: "LOW",
        slaLevel: "LEVEL_2",
        slaEstDays: data?.low?.level2?.slaEstDays || 0,
        slaEstHours: data?.low?.level2?.slaEstHours || 0,
        slaEstMinutes: data?.low?.level2?.slaEstMinutes || 0,
        slaInfo: userList || [],
      },
    ];

    if (edit) {
      const slaEdit = await CreateSLAAction(payload, globapOrganization?.orgId);
      if (slaEdit) {
        TriggerAlert("success", "SLA updated successfully.");
        setValueDate({ closeDate: null, reciveDate: null });
      }
    } else {
      data.orgId = globapOrganization?.orgId;
      // data.displaycolumns = toString(data?.displaycolumns);

      const res = await dispatch(
        CreateJobServiceAction("post", data, fileValue, setLoading)
      );
      await dispatch(
        getJobDataAction(
          globapOrganization?.orgId,
          {
            orgId: globapOrganization?.orgId,
            projectCode: projectCode1?.projectCode || null,
            fromDate: stateValues?.fromDate,
            toDate: stateValues?.toDate,
          },
          itemPerPage,
          pageNumber,
          isActive ? "&isActive=True" : "&isActive=False"
        )
      );
      // const construct=constuctTask(res)
      console.log(res, "res123");
      if (res) {
        // handelClear();
        const slaRes = await CreateSLAAction(
          payload,
          globapOrganization?.orgId
        );
        const getTaskListForCreatedJob = await getCreateJobService(
          `?projectCode=${res.projectCOde}&jobCode=${res.jobCode}`,
          setLoading
        );
        console.log(slaRes, getTaskListForCreatedJob, "slaRes");
        if (slaRes && getTaskListForCreatedJob) {
          const column = constructColumn(getTaskListForCreatedJob?.data?.data);
          await setColumns2(column);
          // const merge = await setColumns2((per) => [...res1[0]?.dynamicData, ...per])
          TriggerAlert("success", res?.message);
          setSubmittedRes(getTaskListForCreatedJob?.data?.data?.data);
        }
      }
    }
  };
  const handleClosepopup = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading(false);
  };
  const handleClosepopup1 = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading1(false);
  };
  // const groupData = (datas) => {
  //   const obj = {};
  //   datas.map((data, index1) => {
  //     const name = Object.values(data.taskInfo).toString();

  //     if (!obj[name]) {
  //       obj[name] = { ...data };
  //       datas.map((data2, index2) => {
  //         if (index2 > index1) {
  //           const name2 = Object.values(data2.taskInfo).toString();
  //           if (name === name2) {
  //             if (obj[name]) {
  //               obj[name] = {
  //                 ...obj[name],
  //                 taskType: obj[name].taskType + ", " + data2.taskType,
  //                 productionHours:
  //                   obj[name]?.productionHours + "," + data2.productionHours,
  //               };
  //             }
  //           }
  //         }
  //       });
  //     }
  //   });
  //   setSubmittedRes(Object.values(obj));
  // };
  const handleInterviewDateClick = () => {
    if (stateValues?.fromDate) inputRef?.current?.showPicker();
  };
  const handelProjectCode = async (e) => {
    const res = await dispatch(getjobcodeAction(e.target.value));
    console.log(res, "res");
    const arr =
      projectCodeDta?.project_details &&
      projectCodeDta?.project_details.length > 0 &&
      projectCodeDta?.project_details.find(
        (val) => val.projectCode === e.target.value
      );
    setValue("projectName", arr?.projectName ? arr?.projectName : "");
    if (res) {
      handelShowTheSLA(res.previousSlaInfo);
    }
  };
  const handleInterviewDateClick1 = () => {
    inputRef1.current.showPicker();
  };
  const handelChangeFile = async (e) => {
    try {
      setFileValue(e.target.files[0]);
      setSelectedValue([]);
      const res = await dispatch(
        CreateJobServiceValidAction(
          "post",
          { projectCode: getValues("projectCode") },
          e.target.files[0]
        )
      );
      if (res) {
        setMultiSelectValue(res);
      }
    } catch (err) {
      clearField();
    }
  };
  const handelChangeMultiSelect = (e) => {
    setSelectedValue(e);
    setValue("displaycolumns", e.toString());
  };
  const clearField = () => {
    fileRef.current.value = null;
    setFileValue("");
    setMultiSelectValue([]);
    setSelectedValue([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dateCheck = (e) => {
    const x = moment(e).isBefore(new Date(), "day");
    if (x) {
      setOpen(true);
    }
    // setOpen(true)
  };
  const handelSelect = async (e) => {
    if (e) {
      setprojectCode1(e);
    } else {
      setprojectCode1(e);
      dispatch(
        getJobDataAction(
          globapOrganization?.orgId,
          {
            orgId: globapOrganization?.orgId,
            projectCode: e?.projectCode || null,
            fromDate: stateValues.fromDate,
            toDate: stateValues.toDate,
          },
          itemPerPage,
          pageNumber,
          isActive ? "&isActive=True" : "&isActive=False"
        )
      );
    }
  };
  React.useEffect(() => {
    dispatch(getProjectCodeListAction(globapOrganization?.orgId));
  }, [globapOrganization]);
  const excelpopupBody = () => {
    return <Box>hi</Box>;
  };
  const excelpopupFooter = () => {
    return (
      <Box>
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setExcelpopup(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    );
  };
  const saveRemarks = () => {
    setOpen(false);
  };
  const changePageSize = (newPageSize) => {
    setItemPerPage(newPageSize.pageSize);
    setPageNumber(newPageSize.page + 1);
    // setConstructData([])
    dispatch(
      getJobDataAction(
        globapOrganization?.orgId,
        {
          orgId: globapOrganization?.orgId,
          projectCode: projectCode1?.projectCode || null,
          fromDate: stateValues.fromDate,
          toDate: stateValues.toDate,
        },
        newPageSize.pageSize,
        newPageSize.page + 1,
        isActive ? "&isActive=True" : "&isActive=False"
      )
    );
  };
  const handelDateRecive = (e) => {
    console.log(e, "props12345");
    const format = moment(e).format("YYYY-MM-DD");
    setDate(e);
    console.log(format, "format");
    setValue("receivedDate", format);
    setValue("dueDate", null);
    setValueDate({
      ...valueDate,
      reciveDate: new Date(format),
      closeDate: null,
    });
  };
  const handelDateClose = (e) => {
    console.log(e, "props12345");
    const format = moment(e).format("YYYY-MM-DD");
    console.log(format, "format");
    setValue("dueDate", format);
    setValueDate({ ...valueDate, closeDate: new Date(format) });
  };
  React.useEffect(() => {
    if (pathname === "/createjob") {
      setEditPage(true);
    } else {
      setEdit(false);
      reset();
      setView(false);
      setEditPage(false);
    }
  }, [pathname]);
  const handelSearch = async () => {
    if (projectCode1 || (stateValues?.fromDate && stateValues?.toDate)) {
      setConstructData([]);
      await dispatch(
        getJobDataAction(
          globapOrganization?.orgId,
          {
            orgId: globapOrganization?.orgId,
            projectCode: projectCode1?.projectCode || null,
            fromDate: stateValues.fromDate,
            toDate: stateValues.toDate,
          },
          itemPerPage,
          pageNumber,
          isActive ? "&isActive=True" : "&isActive=False"
        )
      );
    } else {
      TriggerAlert("warning", "Select Date/Project");
    }
  };
  console.log(errors, "errorrrrr");
  return (
    <>
      <FormProvider {...form}>
        <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ ...formContainerStyle, py: 1, px: 2.3 }}>
            <Box sx={{ width: "100%" }}>
              <Card sx={{ ...formContainerCardStyle, p: 3 }}>
                {!editpage ? (
                  <Box>
                    {allowedPages().Jobs.createJob.create && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{
                            ...allpageFontStyle.addButtonFont.buttonHeight,
                            ...allpageFontStyle.buttonFontStyle1,
                            fontWeight: "bold",
                            // backgroundColor: "#43a5fc",
                            borderRadius: 5,
                            color: "#358c4e",
                            p: 0,
                            px: 2,
                            py: 0,
                            ":hover": {
                              backgroundColor: "#358c4e",
                              color: "white",
                              borderColor: "#358c4e",
                            },
                            textTransform: "capitalize",
                            borderColor: "#358c4e",
                          }}
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setEditPage(true);
                            reset();
                            setValueDate({ closeDate: null, reciveDate: null });
                            navigate("/createjob");
                          }}
                        >
                          <AddIcon
                            sx={{
                              mr: 0.5,
                              ...allpageFontStyle.addButtonFont.font,
                              p: 0,
                            }}
                          />
                          <Typography
                            sx={{ ...allpageFontStyle.addButtonFont.font }}
                          >
                            Create Job
                          </Typography>
                        </Button>
                      </Box>
                    )}
                    <Box
                      sx={{
                        pl: 2,
                        fontFamily: "Lato",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ flex: 1, pt: 1.0 }}>
                        <h4 style={allpageFontStyle.title}>LIST OF JOB</h4>
                      </Box>
                      <Box
                        sx={{
                          alignItems: "end",
                          display: "flex",
                          flex: 1,
                          pt: 1,
                          pr: 1,
                        }}
                      >
                        <SelectComponent
                          sx={{}}
                          DataOptions={projectCodeDta?.project_details}
                          labelName={(option) => option.projectCode}
                          value={projectCode1}
                          label={"Project"}
                          handelChange={handelSelect}
                        />
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={fieldContainer}>
                          <DateComponet
                            value={
                              stateValues?.fromDate
                                ? new Date(
                                    moment(stateValues.fromDate).format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            label={"Start Date"}
                            setValue={setStateValues}
                            handelDate={(e) => {
                              setStateValues({
                                ...stateValues,
                                fromDate: e
                                  ? moment(e).format("YYYY-MM-DD")
                                  : null,
                                toDate: null,
                              });
                            }}
                          />
                          {/* <Box>
                            <input
                              type="date"
                              id="select-date"
                              style={{ visibility: "hidden" }}
                              ref={inputRef1}
                              onChange={(e) =>
                                setStateValues({
                                  ...stateValues,
                                  fromDate: e.target.value,
                                })
                              }
                            />
                            <label
                              htmlFor="select-date"
                              style={{ display: "flex" }}
                            >
                              <Typography
                                sx={{ pr: 1, ...allpageFontStyle.typography1 }}
                              >
                                {stateValues.fromDate
                                  ? moment(stateValues.fromDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "From Date"}
                              </Typography>
                              <CalendarTodayTwoTone
                                sx={{ color: "#9e9e9e", cursor: "pointer" }}
                                onClick={handleInterviewDateClick1}
                              />
                            </label>
                          </Box> */}
                        </Box>
                        <Box sx={fieldContainer}>
                          <DateComponet
                            value={
                              stateValues?.toDate
                                ? new Date(
                                    moment(stateValues.toDate).format(
                                      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                                    )
                                  )
                                : null
                            }
                            minDate={
                              new Date(
                                moment(stateValues.fromDate).format(
                                  "YYYY-MM-DD"
                                )
                              )
                            }
                            label={"End Date"}
                            setValue={setStateValues}
                            handelDate={(e) => {
                              setStateValues({
                                ...stateValues,
                                toDate: e
                                  ? moment(e).format("YYYY-MM-DD")
                                  : null,
                              });
                            }}
                          />{" "}
                          {/* <Box>
                            <input
                              type="date"
                              id="select-date"
                              style={{ visibility: "hidden" }}
                              ref={inputRef}
                              min={moment(
                                new Date(stateValues?.fromDate)
                              ).format("YYYY-MM-DD")}
                              onChange={(e) =>
                                setStateValues({
                                  ...stateValues,
                                  toDate: e.target.value,
                                })
                              }
                            />
                            <label
                              htmlFor="select-date"
                              style={{ display: "flex" }}
                            >
                              <Typography
                                sx={{ pr: 1, ...allpageFontStyle.typography1 }}
                              >
                                {stateValues.toDate
                                  ? moment(stateValues.toDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "To Date"}
                              </Typography>
                              <CalendarTodayTwoTone
                                disabled={!stateValues?.fromDate}
                                sx={{ color: "#9e9e9e", cursor: "pointer" }}
                                onClick={handleInterviewDateClick}
                              />
                            </label>
                          </Box> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            pb: 1,
                            pr: 2.2,
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              handelSearch();
                            }}
                            sx={{
                              // backgroundColor: "#f6be42",
                              borderRadius: 5,
                              ...allpageFontStyle.buttonFontStyle2,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            Search
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        px: 2,
                      }}
                    >
                      <DataGride
                        customHeight={450}
                        // hideDeletion={true}
                        deleteIcon={isActive ? true : false}
                        tableData={constructData || []}
                        columns={columns}
                        pageSize={itemPerPage}
                        page={pageNumber}
                        pageName={"project"}
                        selectionIs={selectionIs}
                        selectionState={selectionState}
                        selectedFile={selectedFile}
                        onSelectionModelChange={onSelectionModelChange}
                        deleteFuntion={handleDeleteClick}
                        onPageSizeChange={changePageSize}
                        rowCount={totalPage}
                        paginationMode={true}
                        InActiveFilterCheckBox={() =>
                          checkBoxFilter(
                            isActive,
                            setIsActive,
                            "Active",
                            "Inactive",
                            handelActiveInactive
                          )
                        }
                        // onPageChange={changePage}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        fontFamily: "lato",
                        fontWeight: "bold",
                        m: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // px: 5,
                        }}
                      >
                        <Box sx={{ flex: 0.1 }}></Box>
                        <Box sx={{ flex: 0.1 }}>
                          <Button
                            variant="contained"
                            // type="submit"
                            onClick={() => {
                              setAlertPopup((per) => ({
                                ...per,
                                open: true,
                                type: PopupMessage.listType,
                                title: PopupMessage.back,
                              }));
                            }}
                            sx={{
                              borderRadius: 5,
                              width: 90,
                              m: { xs: 1, md: 1, lg: 2 },
                              fontSize: 12,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            List
                          </Button>
                        </Box>
                      </Box>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          px: 1,
                        }}
                      >
                        <Grid
                          lg={5}
                          md={12}
                          sx={{ pb: { md: "2rem", lg: "0rem" } }}
                        >
                          <fieldset
                            style={{
                              height: "100%",
                              border: `1px solid ${jobPageColor.jobDetailsColor}`,
                            }}
                          >
                            <legend
                              style={{
                                color: "white",
                                backgroundColor: jobPageColor.jobDetailsColor,
                                ...legendStyle,
                              }}
                            >
                              Job Details
                            </legend>
                            <Grid
                              container
                              spacing={{ xs: 1.1, md: 1.1, lg: 6 }}
                              sx={{ pt: 4 }}
                            >
                              {/* <Grid item lg={6} md={12}>
                                <Grid container spacing={2}> */}
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "70%",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="projectCode"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "85%",
                                      }}
                                    >
                                      <FormControl
                                        error={errors.projectCode}
                                        size="small"
                                        fullWidth
                                      >
                                        <InputLabel shrink>
                                          Project Code*
                                        </InputLabel>
                                        <Select
                                          notched
                                          label="Project Code*"
                                          sx={{
                                            width: "100%",
                                            borderRadius: 3,
                                            "& .MuiInputBase-input.Mui-disabled":
                                              {
                                                WebkitTextFillColor: "#000000",
                                              },
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: { maxHeight: 150 },
                                            },
                                          }}
                                          disabled={edit || view}
                                          // SelectDisplayProps={{ p: 0 }}
                                          onChange={(e) => {
                                            onChange(e);
                                            handelProjectCode(e);
                                            trigger("projectCode");
                                          }}
                                          onBlur={() => trigger("projectCode")}
                                          value={value}
                                          inputRef={ref}
                                        >
                                          {projectCodeDta?.project_details &&
                                            projectCodeDta?.project_details
                                              .length > 0 &&
                                            projectCodeDta?.project_details.map(
                                              (val, i) => (
                                                <MenuItem
                                                  sx={{
                                                    borderBottom:
                                                      "1px solid white",
                                                    fontFamily: "Lato",
                                                    fontSize: 13,
                                                  }}
                                                  value={val.projectCode}
                                                >
                                                  {val.projectCode}
                                                </MenuItem>
                                              )
                                            )}
                                        </Select>
                                        <FormHelperText sx={{ color: "red" }}>
                                          {errors.projectCode
                                            ? errors.projectCode.message
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </Box>
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="projectName"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <TextField
                                      label="Project Name"
                                      size="small"
                                      helperText={
                                        errors.projectName
                                          ? errors.projectName.message
                                          : null
                                      }
                                      error={errors.projectName?.message}
                                      sx={{
                                        width: "85%",
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                        "& input::placeholder": {
                                          ...allpageFontStyle.textFieldPlaceholder,
                                        },
                                      }}
                                      inputProps={{
                                        "aria-label": "Project Name",
                                      }}
                                      placeholder="Autofill"
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      disabled
                                      value={value}
                                      inputRef={ref}
                                      InputProps={formFieldStyle.InputProps}
                                      InputLabelProps={
                                        formFieldStyle.InputLabelProps
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="receivedDate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={enGB}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                          sx={{
                                            width: "85%",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <DatePicker
                                            label="Received Date *"
                                            value={valueDate.reciveDate}
                                            maxDate={new Date()}
                                            disabled={
                                              edit || view ? true : false
                                            }
                                            sx={{}}
                                            onChange={handelDateRecive}
                                            slotProps={{
                                              field: { clearable: true },
                                              textField: {
                                                size: "small",
                                                fullWidth: true,
                                                helperText: errors?.receivedDate
                                                  ? "Recevied Date Required"
                                                  : "",
                                                error: errors?.receivedDate
                                                  ? true
                                                  : false,
                                                InputLabelProps: {
                                                  ...formFieldStyle.InputLabelProps,
                                                },
                                                InputProps: {
                                                  ...formFieldStyle.InputProps,
                                                },
                                                sx: {
                                                  "&.MuiTextField-root": {
                                                    minWidth: 100,
                                                  },
                                                  ...(!getValues(
                                                    "receivedDate"
                                                  ) && {
                                                    ...allpageFontStyle.dateField,
                                                  }),
                                                },
                                              },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                      {/* <TextField
                                        sx={{
                                          width: "85%",
                                          "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                          },
                                          ...allpageFontStyle.dateField
                                        }}
                                        label="Received Date*"
                                        size="small"
                                        helperText={
                                          errors?.receivedDate
                                            ? errors?.receivedDate.message
                                            : null
                                        }
                                        error={errors?.receivedDate?.message}
                                        onChange={(e) => {
                                          onChange(e);
                                          trigger("receivedDate");
                                          setDate(e.target.value);
                                          // dateCheck(e.target.value);
                                        }}
                                        onBlur={() => trigger("receivedDate")}
                                        value={value}
                                        inputRef={ref}
                                        disabled={edit || view}
                                        type="date"
                                        inputProps={{
                                          max: moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          ),
                                        }}
                                        // endAdornment={
                                        //   <InputAdornment position="end">
                                        //     <IconButton onClick={handleInterviewDateClick}>
                                        //       <DateRangeOutlined />
                                        //     </IconButton>
                                        //   </InputAdornment>
                                        // }
                                        // inputProps={{ "aria-label": "received Date" }}
                                        InputProps={formFieldStyle.InputProps}
                                        InputLabelProps={
                                          formFieldStyle.InputLabelProps
                                        }
                                      /> */}
                                    </>
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="dueDate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={enGB}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                          sx={{
                                            width: "85%",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <DatePicker
                                            label="Due Date *"
                                            value={valueDate.closeDate}
                                            disabled={
                                              date === "" ? true : false
                                            }
                                            sx={{}}
                                            minDate={
                                              new Date(valueDate.reciveDate)
                                            }
                                            // maxDate={new Date()}
                                            onChange={handelDateClose}
                                            slotProps={{
                                              field: { clearable: true },
                                              textField: {
                                                size: "small",
                                                fullWidth: true,
                                                helperText: errors?.dueDate
                                                  ? "Due Date Required"
                                                  : "",
                                                error: errors?.dueDate
                                                  ? true
                                                  : false,
                                                InputLabelProps: {
                                                  ...formFieldStyle.InputLabelProps,
                                                },
                                                InputProps: {
                                                  ...formFieldStyle.InputProps,
                                                },
                                                sx: {
                                                  "&.MuiTextField-root": {
                                                    minWidth: 100,
                                                  },
                                                  ...(!getValues("dueDate") && {
                                                    ...allpageFontStyle.dateField,
                                                  }),
                                                },
                                              },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                      {/* <TextField
                                        sx={{
                                          width: "85%",
                                          "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                          },
                                          ...allpageFontStyle.dateField
                                        }}
                                        type="date"
                                        label="Due Date *"
                                        size="small"
                                        helperText={
                                          errors.dueDate
                                            ? errors.dueDate.message
                                            : null
                                        }
                                        error={errors.dueDate?.message}
                                        disabled={date === ""}
                                        onChange={(e) => {
                                          onChange(e);
                                          trigger("dueDate");
                                        }}
                                        onBlur={() => trigger("dueDate")}
                                        value={value}
                                        inputRef={ref}
                                        // endAdornment={
                                        //   <InputAdornment position="end">
                                        //     <IconButton onClick={handleInterviewDateClick}>
                                        //       <DateRangeOutlined />
                                        //     </IconButton>
                                        //   </InputAdornment>
                                        // }
                                        inputProps={{ min: date }}
                                        InputProps={formFieldStyle.InputProps}
                                        InputLabelProps={
                                          formFieldStyle.InputLabelProps
                                        }
                                      /> */}
                                    </>
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="jobCode"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <TextField
                                      label="Job Code"
                                      size="small"
                                      helperText={
                                        errors.jobCode
                                          ? errors.jobCode.message
                                          : null
                                      }
                                      error={errors?.shortName?.message}
                                      sx={{
                                        width: "85%",
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                        "& input::placeholder": {
                                          ...allpageFontStyle.textFieldPlaceholder,
                                        },
                                      }}
                                      disabled
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      inputRef={ref}
                                      inputProps={{ "aria-label": "Job Code" }}
                                      placeholder="Autofill"
                                      InputProps={formFieldStyle.InputProps}
                                      InputLabelProps={
                                        formFieldStyle.InputLabelProps
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {!edit && !view ? (
                                  <Box sx={{}}>
                                    <label htmlFor="uploadExcel" type="button">
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          component="span"
                                          disabled={!getValues("projectCode")}
                                          sx={{
                                            borderRadius: 5,
                                            p: 0.5,
                                            width: "100px",
                                            ":hover": {
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                            },
                                          }}
                                        >
                                          <CloudUploadIcon
                                            sx={{
                                              color: "white",
                                              fontSize: 16,
                                              mr: 1.6,
                                            }}
                                          />
                                          <Box sx={{ fontSize: 11 }}>
                                            Upload
                                          </Box>
                                        </Button>
                                      </Box>
                                    </label>
                                    {fileValue?.name ? (
                                      <Box
                                        sx={{
                                          fontSize: 10,
                                          pl: 0.5,
                                          pt: 0.5,
                                          justifyContent: "center",
                                          display: "flex",
                                        }}
                                      >
                                        {fileValue?.name}
                                        <CloseRounded
                                          sx={{
                                            fontSize: 15,
                                            cursor: "pointer",
                                          }}
                                          color="error"
                                          onClick={() => {
                                            clearField();
                                          }}
                                        />
                                      </Box>
                                    ) : null}
                                    {/* <Box
                                      sx={{
                                        fontSize: 11,
                                        pt: 1,
                                        pl: 0.5,
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "dodgerblue",
                                      }}
                                    >
                                      File & Task categories should match
                                    </Box> */}
                                    <input
                                      type="file"
                                      id="uploadExcel"
                                      ref={fileRef}
                                      disabled={!getValues("projectCode")}
                                      onChange={handelChangeFile}
                                      style={{
                                        color: "transparent",
                                        display: "none",
                                      }}
                                    />
                                  </Box>
                                ) : null}
                              </Grid>
                              {(multiSelectValue &&
                                multiSelectValue.length > 0) ||
                              edit ||
                              view ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    pb: "1.5rem",
                                  }}
                                >
                                  <Autocomplete
                                    multiple
                                    // limitTags={2}
                                    size="small"
                                    id="multiple-limit-tags"
                                    disabled={edit || view}
                                    options={multiSelectValue || []}
                                    filterSelectedOptions
                                    getOptionDisabled={(option) =>
                                      option ===
                                        multiSelectValue[
                                          multiSelectValue?.length - 1
                                        ] ||
                                      option ===
                                        multiSelectValue[
                                          multiSelectValue?.length - 2
                                        ]
                                    }
                                    getOptionLabel={(option) => option}
                                    value={selectedValue}
                                    disableCloseOnSelect={true}
                                    onChange={(e, v) => {
                                      handelChangeMultiSelect(v);
                                      autoScrollToBottom();
                                    }}
                                    sx={{
                                      "& fieldset": { borderRadius: 2 },
                                      width: {
                                        md: "85%",
                                        lg: "93%",
                                        xs: "85%",
                                      },
                                      height: 15,
                                    }}
                                    // getOptionDisabled={(option) => selectedValue.length > 2 ? true : false}
                                    renderInput={(parmas) => {
                                      const { InputProps, ...restParams } =
                                        parmas;
                                      const {
                                        startAdornment,
                                        ...restInputProps
                                      } = InputProps;
                                      return (
                                        <TextField
                                          {...parmas}
                                          variant={"outlined"}
                                          label={"Field *"}
                                          sx={{
                                            ".MuiInputBase-input": {
                                              height: 12,
                                              fontSize: 13,
                                            },
                                          }}
                                          // helperText={errors?.slaInfo ? errors.slaInfo.message : null}
                                          // error={errors?.slaInfo ? errors.slaInfo.message : null}
                                          // InputProps={formFieldStyle.InputProps}
                                          {...restParams}
                                          InputProps={{
                                            ...restInputProps,
                                            startAdornment: (
                                              <>
                                                <div
                                                  style={{
                                                    // height: 30,
                                                    maxHeight: 50,
                                                    width: "100%",
                                                    overflowY: "auto",
                                                  }}
                                                >
                                                  {startAdornment}
                                                  <div
                                                    ref={scrollingContainerRef1}
                                                    style={{ marginBlock: 13 }}
                                                  ></div>
                                                </div>
                                              </>
                                            ),
                                          }}
                                          InputLabelProps={
                                            formFieldStyle.InputLabelProps
                                          }
                                        />
                                      );
                                    }}
                                    // sx={{}}
                                  />
                                </Grid>
                              ) : null}
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                sx={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // width: "100%",
                                  pb: "1.5rem",
                                  pt: "1rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    fontFamily: "Lato",
                                    fontSize: "0.7rem",
                                    fontWeight: 500,
                                    pl: "1rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <Box
                                      sx={{ display: "flex", gap: "0.5rem" }}
                                    >
                                      <Box>
                                        <DomainVerification
                                          sx={{ fontSize: "1rem" }}
                                        />
                                      </Box>
                                      <Box>
                                        File & Task categories should match
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{ display: "flex", gap: "0.5rem" }}
                                    >
                                      <Box>
                                        <DomainVerification
                                          sx={{ fontSize: "1rem" }}
                                        />
                                      </Box>
                                      <Box>
                                        Please ensure last row & column in the
                                        task excel sheet should have the
                                        information in the order Page count then
                                        Category.
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </fieldset>
                        </Grid>
                        <Grid lg={0.1} md={12}></Grid>
                        <Grid lg={6} md={12}>
                          <fieldset
                            style={{
                              height: "100%",
                              border: `1px solid ${jobPageColor.slaColor}`,
                            }}
                          >
                            <legend
                              style={{
                                color: "white",
                                backgroundColor: jobPageColor.slaColor,
                                ...legendStyle,
                              }}
                            >
                              SLA Details
                            </legend>
                            {/* {getValues("projectCode") ? (
                              <Grid
                                container
                                spacing={2}
                                sx={{ pl: 7, fontSize: 13, fontFamily: "Lato" }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  lg={4}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  Level 1 Escalation (Team-Lead)
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  md={8}
                                  lg={8}
                                  sx={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {projectByJobCode?.slaEscalatedInfo?.sla1EscalatedTo.map(
                                    (teams) =>
                                      teams.teamInfo.map((list) => list?.users)
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  lg={4}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  Level 2 Escalation (Team-Manager)
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  md={8}
                                  lg={8}
                                  sx={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {projectByJobCode?.slaEscalatedInfo?.sla2EscalatedTo.map(
                                    (teams) =>
                                      teams.teamInfo.map((list) =>
                                        list?.users.join(", ")
                                      )
                                  )}
                                </Grid>
                              </Grid>
                            ) : null} */}
                            {getValues("projectCode") ? (
                              <Box
                                onClick={() => setDailog(true)}
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: 13,
                                  color: "dodgerblue",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                Info{" "}
                                <Info
                                  fontSize="inherit"
                                  sx={{ cursor: "pointer", mx: 1 }}
                                />
                              </Box>
                            ) : null}

                            {/* <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                fontFamily: "Lato",
                                fontSize: 12,
                                pl: 7,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box>Level 1 Escalation (Team-Lead)</Box>
                                <Box sx={{ fontWeight: "bold", ml: 2 }}>
                                  {" "}
                                  {projectByJobCode?.slaEscalatedInfo?.sla1EscalatedTo.map(
                                    (teams) =>
                                      teams.teamInfo.map((list) => list?.users)
                                  )}
                                </Box>
                              </Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box>Level 2 Escalation (Team-Manager)</Box>
                                <Box sx={{ fontWeight: "bold", ml: 2 }}>
                                  {" "}
                                  {projectByJobCode?.slaEscalatedInfo?.sla2EscalatedTo.map(
                                    (teams) =>
                                      teams.teamInfo.map((list) => list?.users)
                                  )}
                                </Box>
                              </Box>
                            </Box> */}
                            <SLAMaster userList={userList} />
                            {/* <JobSLA userList={userList} view={view} /> */}
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              {errors.high || errors.medium || errors.low ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      ontFamily: "Lato",
                                      fontSize: 11,

                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      fontFamily: "Lato",
                                      fontSize: 11,
                                      color: red[300],
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    Please ensure no pending (High, Medium &
                                    Low) before CREATE.
                                  </Box>
                                  <Box
                                    sx={{
                                      fontFamily: "Lato",
                                      fontSize: 11,
                                      color: red[300],
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    Click Next, to proceed.
                                  </Box>
                                </Box>
                              ) : null}{" "}
                            </Box>
                          </fieldset>
                        </Grid>
                      </Grid>
                      {view ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                          }}
                        >
                          <Button
                            variant="contained"
                            // type="submit"
                            onClick={() => {
                              reset();
                              setView(false);
                              handelClear();
                              setEditPage(false);
                              navigate("/jobList");
                            }}
                            sx={{
                              borderRadius: 5,
                              width: 90,
                              m: { xs: 1, md: 1, lg: 2 },
                              fontSize: 12,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            List
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                          }}
                        >
                          <Button
                            variant="contained"
                            color="success"
                            type="submit"
                            // onClick={onSubmit}
                            // disabled={selectedValue?.length > 0 ? false : true}
                            // disabled={
                            //   getValues("high.level1.slaEstDays") === 0 ||
                            //   getValues("high.level1.slaEstHours") === 0 ||
                            //   getValues("high.level1.slaEstMinutes") === 0
                            // }
                            sx={{
                              borderRadius: 5,
                              width: 90,
                              m: { xs: 1, md: 1, lg: 2 },
                              fontSize: 12,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            {edit ? "Update" : "Create"}
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setAlertPopup((per) => ({
                                ...per,
                                open: true,
                                type: PopupMessage.resetType,
                                title: PopupMessage.reset,
                              }));
                            }}
                            sx={{
                              borderRadius: 5,
                              width: 90,
                              m: { xs: 1, md: 1, lg: 2 },
                              fontSize: 12,
                              ":hover": {
                                boxShadow:
                                  "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                              },
                            }}
                          >
                            {RESET_LABEL}
                          </Button>
                        </Box>
                      )}
                    </Box>
                    {columns2?.length && submittedRes?.length ? (
                      <Box sx={{ mx: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "column",
                                lg: "row",
                              },
                              alignItems: "center",
                            }}
                          >
                            <TypographyCustom
                              containStyle={containStyleTypo}
                              labelStyle={labelStyleinCreateJob}
                              type={"right"}
                              content={
                                <Chip
                                  variant="outlined"
                                  sx={{
                                    p: 0,
                                    fontSize: 15,
                                    mr: 10,
                                    height: 20,
                                  }}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {submittedRes[0]["Project_Name"]}
                                    </Box>
                                  }
                                />
                              }
                              label={
                                <Box
                                  sx={{
                                    fontSize: 15,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  <img
                                    alt=""
                                    src={job}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      marginRight: 5,
                                    }}
                                  />
                                  Project Name:
                                </Box>
                              }
                            />
                            {/* <img alt="" src={arrow} style={{ height: 20, width: 20, marginLeft: 30, marginRight: 30 }} /> */}
                            <TypographyCustom
                              containStyle={containStyleTypo}
                              labelStyle={labelStyleinCreateJob}
                              type={"right"}
                              content={
                                <Chip
                                  variant="outlined"
                                  sx={{
                                    p: 0,
                                    fontSize: 15,
                                    mr: 10,
                                    height: 20,
                                  }}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {submittedRes[0]["Project_Code"]}
                                    </Box>
                                  }
                                />
                              }
                              label={
                                <Box
                                  sx={{
                                    fontSize: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    height: 20,
                                  }}
                                >
                                  {" "}
                                  <img
                                    alt=""
                                    src={job}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      marginRight: 5,
                                    }}
                                  />
                                  Project Code:
                                </Box>
                              }
                            />
                            {/* <img alt="" src={arrow} style={{ height: 20, width: 20, marginLeft: 30, marginRight: 30 }} /> */}

                            <TypographyCustom
                              containStyle={containStyleTypo}
                              labelStyle={labelStyleinCreateJob}
                              type={"right"}
                              content={
                                <Chip
                                  variant="outlined"
                                  sx={{ p: 0, fontSize: 15, height: 20 }}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {submittedRes[0]["Job_Code"]}
                                    </Box>
                                  }
                                />
                              }
                              label={
                                <Box
                                  sx={{
                                    fontSize: 15,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  <img
                                    alt=""
                                    src={job}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      marginRight: 5,
                                    }}
                                  />
                                  Job Code:
                                </Box>
                              }
                            />
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Button
                              onClick={() =>
                                dispatch(
                                  getExcelAction(
                                    {
                                      jobCode: submittedRes[0]["Job_Code"],
                                      projectCode:
                                        submittedRes[0]["Project_Code"],
                                    },
                                    setLoading1
                                  )
                                )
                              }
                            >
                              Download
                              <FileDownloadOutlinedIcon
                                sx={{ height: 30, width: 30 }}
                              />
                            </Button>
                          </Box>
                        </Box>
                        <DataGride
                          columns={columns2}
                          hideDeletion={true}
                          // edit={true}
                          getRowHeight={() => "auto"}
                          tableData={submittedRes || []}
                          disableCheckbox={true}
                          getRowId={(data) => data["SN"]}
                          customHeight={400}
                          // processRowUpdate={processRowUpdate}
                          // hiddenFooter={true}
                          // selectedFile={selectedFile}
                          // rowSelectionModel={selectedFile}
                          // rowData={onSelectionModelChange}
                          // onSelectionModelChange={onSelectionModelChange2}
                          // tableData={
                          //     employee &&
                          //     employee.length > 0 &&
                          //     employee.filter((value) => value.isActive === true)
                          // }
                          // hideDeletion={true}
                        />
                      </Box>
                    ) : null}
                  </Box>
                )}

                {/* {submittedRes && submittedRes.length > 0 &&
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={EditTaskData}
                    sx={{
                      color: "white",
                      width: 90,
                      m: 1,
                      borderRadius: 2,
                    }}
                  >
                    Edit
                  </Button>
                </Box>} */}
              </Card>
            </Box>
            <DialogComponent
              open={excelpopup}
              close={() => setExcelpopup(false)}
              body={excelpopupBody}
              footer={excelpopupFooter}
            />
            <Dialog open={open}>
              <DialogContent>
                <Box sx={{ p: 1 }}>
                  {/* <Box sx={{ fontSize: 11, mb: 2 }}>Mandatory*</Box> */}
                  <TextField
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                    // onChange={(e) => {
                    //   handelChangedymanicField(e.target.value, selectedRow.index);
                    // }}
                    label={"Remarks*"}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  sx={{
                    color: "white",
                    // width: '10%',
                    height: 40,
                    p: 0,
                    px: 2,
                    fontWeight: "bold",
                    backgroundColor: "#4d8c5d",
                    borderRadius: 5,
                    textTransform: "capitalize",
                    ":hover": { backgroundColor: "#4d8c5d" },
                  }}
                  variant="contained"
                  onClick={saveRemarks}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    color: "white",
                    // width: '10%',
                    height: 40,
                    p: 0,
                    px: 2,
                    fontWeight: "bold",
                    backgroundColor: "#bf6373",
                    ":hover": { backgroundColor: "#bf6373" },
                    borderRadius: 5,
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </form>
      </FormProvider>
      <CustomDialog
        open={loading}
        onClose={handleClosepopup}
        content={
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
            >
              <Box>{"Task is getting created"}</Box>
              <Box sx={{ textAlign: "center" }}>{"please wait"}</Box>{" "}
            </Box>
            <Box sx={{ px: 2 }}>
              <ProgressBar />
            </Box>
          </Box>
        }
      />

      <CustomDialog
        open={loading1}
        onClose={handleClosepopup1}
        content={
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
            >
              <Box>{"Data is getting downloaded"}</Box>
              <Box sx={{ textAlign: "center" }}>{"please wait"}</Box>{" "}
            </Box>
            <Box sx={{ px: 2 }}>
              <ProgressBar />
            </Box>
          </Box>
        }
      />
      <Dialog
        maxWidth={"sm"}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "30px",
            border: `3px solid ${headerBackground}`,
            zIndex: 1000000,
          },
        }}
        open={dailog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            background: headerBackground,
            p: 1,
            mb: 2,
          }}
        >
          <Typography variant="h6" textAlign={"center"}>
            SLA Details
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", p: 0, px: 2 }}
        >
          <Grid container spacing={2} sx={{ fontSize: 13, fontFamily: "Lato" }}>
            <Grid
              item
              xs={6}
              md={4}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                p: 0,
              }}
            >
              Level 1 Escalation (Team-Lead) :
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={7}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {projectByJobCode?.slaEscalatedInfo?.sla1EscalatedTo?.[0]
                ?.teamInfo?.length > 0 ? (
                <p
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <p
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {projectByJobCode?.slaEscalatedInfo?.sla1EscalatedTo.map(
                      (teams) => (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            pl: 5,
                          }}
                        >
                          {teams.teamInfo
                            .map((list) => list?.users)
                            .join(",  ")}
                        </Box>
                      )
                    )}
                  </p>
                </p>
              ) : (
                <Box sx={{ pl: 3 }}>--</Box>
              )}
            </Grid>
            {/* <Divider flexItem variant="middle"/> */}
            <Grid
              item
              xs={6}
              md={4}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                p: 0,
              }}
            >
              Level 2 Escalation (Team-Manager) :
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={7}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                pl: 10,
              }}
            >
              {projectByJobCode?.slaEscalatedInfo?.sla2EscalatedTo?.[0]
                ?.teamInfo?.length > 0 ? (
                <p
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <p
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {projectByJobCode?.slaEscalatedInfo?.sla2EscalatedTo.map(
                      (teams) =>
                        teams.teamInfo.map((list) => (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              pl: 5,
                            }}
                          >
                            {list?.users.join(",  ")}
                          </Box>
                        ))
                    )}
                  </p>
                </p>
              ) : (
                <Box sx={{ pl: 3 }}>--</Box>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              TroubleShooters :
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={7}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {projectByJobCode?.slaEscalatedInfo?.troubleShooterInfo.map(
                (list) => (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      pl: 5,
                    }}
                  >
                    {projectByJobCode?.slaEscalatedInfo?.troubleShooterInfo?.[0]
                      ?.users?.length > 0 ? (
                      <p style={{ padding: 0, margin: 0 }}>
                        {list.users.map((user) => (
                          <p style={{ padding: 0, margin: 0 }}>{user}</p>
                        ))}
                      </p>
                    ) : (
                      <Box sx={{ pl: 3 }}>--</Box>
                    )}
                  </Box>
                )
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            sx={{
              background: red[300],
              borderRadius: 5,
              color: "white",
              mr: 5,
              ":hover": { background: red[300] },
              px: 2,
            }}
            onClick={() => setDailog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </>
  );
}
