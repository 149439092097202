/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import pass from "../../images/login/password.png";
import user from "../../images/login/user3.png";
import logo from "../../pics/workboxLogo.png";
import {
  ChangeUserPasswordAction,
  LoginUserAction,
} from "../../redux/action/action";
import {
  passwordChecking,
  passwordCheckingState,
} from "../../utils/Imageimport";
import { passwordRulStyle } from "../../utils/Style";
import { TriggerAlert } from "../../utils/UIUtil";
import "./loginScreen.css";
import FirstPasswordReset from "./ResetPassword/FirstPasswordReset";
import { getNotificationCountAction } from "../../service/action/action";
const StyledPopper = styled(Popper)(({ theme }) => ({
  // You can replace with `PopperUnstyled` for lower bundle size.
  zIndex: 1,
  maxWidth: "375px",
  width: "100%",
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    left: 0,
    marginTop: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    left: 0,
    marginBottom: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    left: 0,
    marginLeft: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: 0,
    marginRight: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}));
const Login = () => {
  const { changePass, userProfile } = useSelector((state) => state.presist);
  // const { userProfile } = useSelector((state) => state.common);

  const schema = yup.object().shape({
    // username: yup.string().email().required("User name required"),
    password: yup.string().required("Password Required"),
  });
  const schema2 = yup.object().shape({
    forgetuserName: yup.string().email().nullable(),
    forgetpassword: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must be at least 1 lower case characters")
      .matches(/[A-Z]/, "Password must be at least 1 Upper case characters")
      .matches(/\d/, "Password must be at least 1 numeric value")
      .matches(/[@$#_]/, "Password must be at least 1 symbol (@$#_)")
      .required("Password Required"),
    confirmpassword: yup.string().required("Confirm Password Required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgetPass, setForgetPass] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [passwordState, setPasswordState] = useState({
    loginPassword: false,
    newPassword: false,
    confirmpassword: false,
  });
  const butref = useRef();
  const open = Boolean(anchorEl);
  const loginSubmit = async (data) => {
    // localStorage.setItem("authToken", true);
    // dispatch(updateCommonStates({ name: "authToken", value: true }));
    data.username.toUpperCase();
    dispatch(LoginUserAction(data, navigate));

    // navigate("/home");
  };
  const handelEyeIcon = (type) => {
    if (type === "loginpassword")
      return setPasswordState((per) => {
        return { ...per, loginPassword: !per.loginPassword };
      });
    else if (type === "newpassword")
      return setPasswordState((per) => {
        return { ...per, newPassword: !per.newPassword };
      });
    else if (type === "confirmpassword")
      return setPasswordState((per) => {
        return { ...per, confirmpassword: !per.confirmpassword };
      });
  };
  const changeSubmit = (data) => {
    if (data.confirmpassword === data.forgetpassword) {
      dispatch(
        ChangeUserPasswordAction(
          { newPassword: data?.confirmpassword },
          navigate
        )
      );
    } else {
      TriggerAlert("error", " Password does not match");
    }
  };
  const styles = {
    arrow: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      color: "black",
      "&::before": {
        content: '""',
        margin: "auto",
        borderColor: "red",
        display: "block",
        borderStyle: "solid",
      },
    },
  };
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    control: control2,
    getValues: getValue2,
    setValue: setValue2,
    trigger,
    handleSubmit: handleSubmitReset,
    formState: { errors: errors2 },
  } = useForm({
    defaultValues: {
      forgetuserName: userProfile?.userAccess?.userName,
      forgetpassword: "",
      confirmpassword: "",
    },
    resolver: yupResolver(schema2),
  });

  useEffect(() => {
    if (changePass && userProfile?.userAccess?.userName) {
      setValue2("forgetuserName", userProfile?.userAccess?.userName);
    }
  }, [changePass, userProfile]);

  useEffect(() => {
    if (errors2?.forgetpassword?.message || errors2?.confirmpassword?.message) {
      setAnchorEl(butref.current);
    } else {
      setAnchorEl(null);
    }
  }, [errors2]);

  return (
    <Box>
      {changePass ? (
        <FirstPasswordReset />
      ) : (
        <>
          <Box className="main-container" sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid container>
                  <Grid
                    sx={{
                      borderRight: {
                        lg: "4px solid #d9d4d4",
                        md: "4px solid #d9d4d4",
                        sm: "none",
                        xs: "none",
                      },
                      display: {
                        lg: "flex",
                        md: "flex",
                        sm: "none",
                        xs: "none",
                      },
                      pr: 6,
                    }}
                  >
                    <Box
                      sx={{
                        pt: changePass ? 0 : 0,
                        pr: 9,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {/* <Box sx={{}}>
                  <img src={logo} style={{ height: 15 }} alt="logo" />
                </Box> */}
                      <Box sx={{ p: 1 }}>
                        <img src={logo} style={{ height: 50 }} alt="logo" />
                      </Box>
                      {/* <img src={logo} style={{ height: 100 }} alt="logo" /> */}
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      pl: 6,
                    }}
                  >
                    {forgetPass ? (
                      <form key={2} onSubmit={handleSubmitReset(changeSubmit)}>
                        <Box sx={{ pt: 10 }}>
                          <Box sx={{ pb: 3 }}>
                            {" "}
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontFamily: "Lato",
                                fontWeight: "bold",
                              }}
                            >
                              Welcome Back!
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontFamily: "Lato" }}
                            >
                              <Box sx={{ display: "flex" }}>
                                {" "}
                                <Box> Change Password</Box>
                                <Box sx={{ px: 1.5, pt: 0.2 }}>
                                  {/* <Box>
                            <Tooltip title={"Password Rule"} ref={butref}>
                              <Info id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => { handleClick(e) }} sx={{ fontSize: 15, color: 'blue', cursor: 'pointer' }} />
                            </Tooltip>
                            <Popover
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              sx={{ width: "80%" }}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                            >
                            <Box sx={{ p: 1 }}><Box sx={{ width: "100%", fontSize: 14, overflowWrap: 'break-word' }}>{PASSWORD_WARINING}</Box></Box>
                            </Popover>
                          </Box> */}
                                  {/* <CustomDropDown
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            Content={<Box sx={{ width: "100%", fontSize: 14, overflowWrap: 'break-word' }}>{PASSWORD_WARINING}</Box>}
                            open={open} /> */}
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          {forgetPass ? (
                            <Box sx={{ pb: 2 }}>
                              <Paper
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: 400,
                                }}
                              >
                                <Box sx={{ p: "10px" }} aria-label="menu">
                                  <img
                                    src={user}
                                    style={{ height: 20 }}
                                    alt="logo"
                                  />
                                </Box>

                                <Controller
                                  control={control2}
                                  name="forgetuserName"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <InputBase
                                      sx={{ ml: 1, flex: 1 }}
                                      placeholder="User Name"
                                      inputProps={{ "aria-label": "User Name" }}
                                      value={value}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </Paper>
                              {errors2 && errors2.forgetuserName ? (
                                <Typography sx={{ color: "red", fontSize: 10 }}>
                                  {errors2.forgetuserName.message}
                                </Typography>
                              ) : null}
                            </Box>
                          ) : null}
                          <Box sx={{ pt: 1 }}>
                            {" "}
                            <Paper
                              ref={butref}
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                              }}
                            >
                              <Box sx={{ p: "10px" }} aria-label="menu">
                                <img
                                  src={pass}
                                  style={{ height: 20 }}
                                  alt="logo"
                                />
                              </Box>
                              <Controller
                                control={control2}
                                name="forgetpassword"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Password"
                                    inputProps={{ "aria-label": "Password" }}
                                    value={value}
                                    type={
                                      passwordState.newPassword
                                        ? "text"
                                        : "password"
                                    }
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ p: 1 }}
                                      >
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            handelEyeIcon("newpassword")
                                          }
                                          disableFocusRipple
                                          edge="end"
                                        >
                                          {passwordState.newPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    onFocus={(e) => {
                                      setAnchorEl(e.currentTarget);
                                    }}
                                    onBlur={(e) => {
                                      setAnchorEl(false);
                                    }}
                                    onChange={(e) => {
                                      trigger("forgetpassword");

                                      onChange(e);
                                    }}
                                  />
                                )}
                              />
                            </Paper>
                            {/* {errors2 && errors2.forgetpassword ? (
                        <Typography sx={{ color: "red", fontSize: 10 }}>
                          {errors2.forgetpassword.message}
                        </Typography>
                      ) : null} */}
                          </Box>
                          <Box sx={{ pt: 3 }}>
                            {" "}
                            <Paper
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                              }}
                            >
                              <Box sx={{ p: "10px" }} aria-label="menu">
                                <img
                                  src={pass}
                                  style={{ height: 20 }}
                                  alt="logo"
                                />
                              </Box>
                              <Controller
                                control={control2}
                                name="confirmpassword"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Confirm Password"
                                    inputProps={{ "aria-label": "Password" }}
                                    type={
                                      passwordState.confirmpassword
                                        ? "text"
                                        : "password"
                                    }
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ p: 1 }}
                                      >
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            handelEyeIcon("confirmpassword")
                                          }
                                          disableFocusRipple
                                          edge="end"
                                        >
                                          {passwordState.confirmpassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </Paper>
                            {errors2 && errors2.confirmpassword ? (
                              <Typography sx={{ color: "red", fontSize: 10 }}>
                                {errors2.confirmpassword.message}
                              </Typography>
                            ) : null}
                            <StyledPopper
                              anchorEl={anchorEl}
                              open={open}
                              sx={{
                                background: "white",
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 2,
                              }}
                              placement="bottom-start"
                              modifiers={[
                                {
                                  name: "arrow",
                                  enabled: true,
                                  options: {
                                    element: arrowRef,
                                  },
                                },
                              ]}
                              // onClose={handleClose}
                            >
                              <Box
                                component="span"
                                className="arrow"
                                ref={setArrowRef}
                                sx={styles.arrow}
                              />
                              <Box sx={{ p: 1 }} ref={arrowRef}>
                                <Box sx={{ pt: 1 }}>
                                  <Box sx={{ ...passwordRulStyle, pb: 1 }}>
                                    {
                                      "Password should meet the conditions given below."
                                    }
                                  </Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ pr: 1 }}>
                                      {passwordChecking(
                                        getValue2("forgetpassword"),
                                        "",
                                        8
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        ...passwordRulStyle,
                                        color: passwordCheckingState(
                                          getValue2("forgetpassword"),
                                          "",
                                          8
                                        ),
                                      }}
                                    >
                                      {"Length should be minimum 8 characters."}
                                    </Box>
                                  </Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ pr: 1 }}>
                                      {passwordChecking(
                                        getValue2("forgetpassword"),
                                        /[a-z]/
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        ...passwordRulStyle,
                                        color: passwordCheckingState(
                                          getValue2("forgetpassword"),
                                          /[a-z]/
                                        ),
                                      }}
                                    >
                                      {"Should have 1 lower case."}
                                    </Box>
                                  </Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ pr: 1 }}>
                                      {passwordChecking(
                                        getValue2("forgetpassword"),
                                        /[A-Z]/
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        ...passwordRulStyle,
                                        color: passwordCheckingState(
                                          getValue2("forgetpassword"),
                                          /[A-Z]/
                                        ),
                                      }}
                                    >
                                      {"Should have 1 upper case"}
                                    </Box>
                                  </Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ pr: 1 }}>
                                      {passwordChecking(
                                        getValue2("forgetpassword"),
                                        /\d/
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        ...passwordRulStyle,
                                        color: passwordCheckingState(
                                          getValue2("forgetpassword"),
                                          /\d/
                                        ),
                                      }}
                                    >
                                      {"Should have 1 numeric."}
                                    </Box>
                                  </Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ pr: 1 }}>
                                      {passwordChecking(
                                        getValue2("forgetpassword"),
                                        /[@$#_]/
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        ...passwordRulStyle,
                                        color: passwordCheckingState(
                                          getValue2("forgetpassword"),
                                          /[@$#_]/
                                        ),
                                      }}
                                    >
                                      {
                                        "Should have any one special charter (#,$,@,_)"
                                      }
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </StyledPopper>
                          </Box>
                          <Box
                            sx={{
                              pt: 4,
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              className="reset-form-items"
                              // onClick={changeSubmit}
                              variant="contained"
                              type="submit"
                            >
                              Change Password
                            </Button>
                            {forgetPass ? (
                              <Button
                                className="login-form-items"
                                onClick={() => {
                                  // dispatch(
                                  //   updatePresistStates({ name: "changePass", value: false })
                                  // );
                                  setForgetPass(false);
                                }}
                                variant="contained"
                              >
                                Back
                              </Button>
                            ) : null}
                          </Box>
                        </Box>
                      </form>
                    ) : (
                      <form key={1} onSubmit={handleSubmit(loginSubmit)}>
                        <Box sx={{ pt: 10 }}>
                          <Box sx={{ pb: 3 }}>
                            {" "}
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontFamily: "Lato",
                                fontWeight: "bold",
                              }}
                            >
                              Welcome Back!
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontFamily: "Lato" }}
                            >
                              Login to continue
                            </Typography>
                          </Box>
                          <Box sx={{ pb: 2 }}>
                            <Paper
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                              }}
                            >
                              <Box
                                disabled
                                sx={{ p: "10px" }}
                                aria-label="menu"
                              >
                                <img
                                  src={user}
                                  style={{ height: 20 }}
                                  alt="logo"
                                />
                              </Box>
                              <Controller
                                control={control}
                                name="username"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="User Name"
                                    inputProps={{ "aria-label": "User Name" }}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      setValue(
                                        "username",
                                        e.target.value.toUpperCase()
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Paper>
                            {errors && errors.username ? (
                              <Typography sx={{ color: "red", fontSize: 10 }}>
                                {errors.username.message}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box sx={{ pt: 3 }}>
                            {" "}
                            <Paper
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                              }}
                            >
                              <Box sx={{ p: "10px" }}>
                                <img
                                  src={pass}
                                  disabled
                                  style={{ height: 20 }}
                                  alt="logo"
                                />
                              </Box>
                              <Controller
                                control={control}
                                name="password"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Password"
                                    inputProps={{ "aria-label": "Password" }}
                                    value={value}
                                    type={
                                      passwordState.loginPassword
                                        ? "text"
                                        : "password"
                                    }
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ p: 1 }}
                                      >
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            handelEyeIcon("loginpassword")
                                          }
                                          disableFocusRipple
                                          edge="end"
                                        >
                                          {passwordState.loginPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </Paper>
                            {errors && errors.password ? (
                              <Typography sx={{ color: "red", fontSize: 10 }}>
                                {errors.password.message}
                              </Typography>
                            ) : null}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                mt: 3,
                              }}
                            >
                              <a
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // dispatch(
                                  //   updatePresistStates({ name: "changePass", value: true })
                                  // );
                                  // setForgetPass(true);
                                  navigate("/ForgetPassword");
                                }}
                                className="auth-link"
                              >
                                Forgot password?
                              </a>
                            </Box>
                          </Box>
                          <Box sx={{ pt: 4, borderRadius: "10px" }}>
                            <Button
                              className="login-form-items"
                              // onClick={loginSubmit}
                              variant="contained"
                              type="submit"
                            >
                              Login
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Login;
