import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { green, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { allpageFontStyle } from "../../utils/FontStyle";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
export function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={
        <BpCheckedIcon
          sx={{
            backgroundColor: props.checkboxColor
              ? props.checkboxColor
              : "#137cbd",
          }}
        />
      }
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function PermissionList({
  name,
  setSelectedPermission,
  selectedPermission,
  permissionList,
  userPermission,
  isSuperAdmin,
  userPermissionObj,
}) {
  const [checked, setChecked] = React.useState([true, false, false]);
  const allMatch = permissionList?.permissions?.every((i) =>
    selectedPermission.includes(i.id)
  );
  const allMatchPermission = permissionList?.permissions?.every((i) =>
    userPermissionObj.find(
      (j) =>
        j.page === permissionList?.appLabel && j.userAccess?.includes(i.name)
    )
  );
  const someMatch = permissionList?.permissions?.some((i) =>
    selectedPermission.includes(i.id)
  );
  const extractId = permissionList?.permissions.map((i) => i.id);
  const handleChange1 = (event) => {
    if (event.target.checked)
      setSelectedPermission((prev) => {
        const t = [...prev, ...extractId];
        return [...new Set(t)];
      });
    else
      setSelectedPermission((prev) => {
        const t = [...prev].filter((i) => !extractId.includes(i));
        return [...new Set(t)];
      });
  };

  const handleChange = (e, id) => {
    console.log(e, "asdcdecfe");
    if (e.target.checked) setSelectedPermission((prev) => [...prev, id]);
    else
      setSelectedPermission((prev) => {
        const t = [...prev];
        const index = t.findIndex((i) => i === id);
        if (index >= 0) t.splice(index, 1);
        return t;
      });
  };

  const children = (
    <Box sx={{ display: "flex", ml: 3 }}>
      {permissionList?.permissions?.map((list) => (
        <>
          {console.log(
            userPermission,
            list,
            list?.name,
            "wedhjqwdvbjhwqvjdvwed"
          )}
          <></>
          <FormControlLabel
            sx={{ mr: 3 }}
            key={list?.id}
            label={
              <Typography
                sx={{ ...allpageFontStyle.typography1 }}
                variant="subtitle2"
              >
                {list?.name}
              </Typography>
            }
            control={
              <BpCheckbox
                disabled={
                  !userPermissionObj?.find(
                    (i) =>
                      i.page === permissionList?.appLabel &&
                      i.userAccess?.includes(list?.name)
                  ) && !isSuperAdmin
                }
                // disabled={!userPermissionObj?.includes(list?.name) && !isSuperAdmin}
                size="small"
                checked={selectedPermission.includes(list.id)}
                onChange={(e) => handleChange(e, list.id)}
              />
            }
          />
        </>
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        background: grey[50],
        px: 3,
        borderRadius: 5,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ width: 350 }}>
        <FormControlLabel
          label={
            <Typography
              sx={{
                fontWeight: "bold",
                ...allpageFontStyle.typography1,
                color: green[600],
                px: 1,
                borderRadius: 2,
              }}
              variant="subtitle2"
            >
              {name}
            </Typography>
          }
          control={
            <BpCheckbox
              size="small"
              checked={allMatch}
              indeterminate={someMatch && !allMatch}
              onChange={handleChange1}
              disabled={!allMatchPermission && !isSuperAdmin}
            />
          }
        />
      </Box>
      <Box>
        <Divider orientation="vertical" sx={{ background: "black" }} />
      </Box>
      {children}
    </Box>
  );
}
