import axios from "./axiosService";
// import { TriggerAlert } from "../actions/action";
// import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createEmployeeService = async (data, method) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/users/register`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const createEmployeeServices = async (data, method, path, callBack) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/users/register${path || ""}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getEmployeeService = async (id, pageSize, pageNumber, active) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/users/register${id}?pageSize=${pageSize}&pageNumber=${pageNumber}&isActive=${
        active ? "True" : "False"
      }`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getEmployeeList = async (id) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/users/org_users_list/${id}/`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const checkEmpEmail = async (data, id) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/email-validate?orgId=${id}`,
      data: data,
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log(error.response.data, "err");
    return error?.response?.data;
  }
};
export const editEmployeeService = async (data, id) => {
  try {
    return await axios({
      method: "put",
      url: `${baseURL}user/${id}`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const ProfileUploadService = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/uploadImage`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const teamMapping = async (data, id) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/team/team_user_mapping/${id}/`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

export const getDepartmentByTeam = async (id) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/team/list_active_teams/${id}/`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getDepartmentTeamByUsers = async (id, id2, orgid) => {
  return await axios({
    method: "get",
    url: `${baseURL}/team/list_active_users/${orgid}/${id}/${id2}/`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const profileInfo = async () => {
  return await axios({
    method: "get",
    url: `${baseURL}/users/login_user_profile`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const SuperAdminPasswordReset = async (data) => {
  return await axios({
    method: "post",
    url: `${baseURL}/users/adminReset/`,
    data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const getGradeMappedDesignation = async (id) => {
  return await axios({
    method: "get",
    url: `${baseURL}/grade/mapped/desig/list/${id}/`,
  })
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {});
};
export const getNotification = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/fcm/info/`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const dashboardInfo = async () => {
  return await axios({
    method: "get",
    url: `${baseURL}/dashboard/`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

export const getTaskSplit = async (code, id) => {
  return await axios({
    method: "get",
    url: `${baseURL}/job/getTaskSpilt?jobCode=${code}&taskId=${id}`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const getDashboardData = async (method, id, data) => {
  return await axios({
    method: method,
    url: `${baseURL}/report/user_dashboard_count/${id}/`,
    data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};
export const getGradeByDesig = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/grade/`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getDashboardTask = async (itemPerPage, pageNumber) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/job/getTasks?pageSize=${itemPerPage}&pageNumber=${pageNumber}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
  }
};
