import { Box, Card } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../../components/commonComponents/SelectComponent";
import DataGride from "../../../components/datagride/DataGride";
import {
    getJobListAction,
    getProjectCodeListAction,
} from "../../../redux/action/action";
import {
    formContainerCardStyle,
    formContainerStyle,
} from "../../../utils/Style";

function JoblistPage() {
    const { globapOrganization } = useSelector((state) => state.presist);
    const [constructData, setConstructData] = React.useState([]);
    const [projectCode1, setprojectCode1] = useState("");

    const dispatch = useDispatch();
    const { projectCodeDta, projectJoblist } = useSelector(
        (state) => state.master
    );
    const columns = [
        { field: "projectCode", headerName: "Project Code", width: 200 },
        { field: "jobCode", headerName: "Job Code", width: 200 },
        {
            field: "receivedDate",
            headerName: "received Date",
            width: 200,
            valueGetter: (params) =>
                params?.row?.receivedDate ? moment(params?.row?.receivedDate).format("DD/MM/YYYY") : '',
        },
        {
            field: "dueDate",
            headerName: "Due Date",
            width: 200,
            valueGetter: (params) =>
                params?.row?.dueDate ? moment(params?.row?.dueDate).format("DD/MM/YYYY") : '',
        },
        // { field: "jobDownload", headerName: "Job Download", width: 150 },
        {
            field: "createdBy",
            headerName: "Created By",
            width: 250,
            minWidth: 250,
            flex: 1,
            valueGetter: (params) =>
                params?.row?.createUserInfo?.firstname +
                " " +
                params?.row?.createUserInfo?.lastname,
        },
        // { field: "createdTime", headerName: "Created Time", width: 150 },
        // { field: "status", headerName: "Status", width: 100, flex: 1 },
    ];
    const branchListConstruct = (arr) => {
        const res = arr.map((val, i) => ({
            createUserInfo: val.createUserInfo,
            updatedUserInfo: val.updatedUserInfo,
            ...val.jobInfo,
        }));
        return res;
    };
    React.useEffect(() => {
        if (projectJoblist && projectJoblist.length > 0) {
            const constructDatavalue = branchListConstruct(projectJoblist);
            setConstructData(constructDatavalue);
        } else {
            setConstructData([]);
        }
    }, [projectJoblist]);
    React.useEffect(() => {
        dispatch(getProjectCodeListAction(globapOrganization?.orgId));
    }, [globapOrganization]);
    const handelProjectCode = async (e) => {
        if (e) {
            const obj = {
                projectCode: e?.projectCode,
                orgId: globapOrganization?.orgId,
            };
            setprojectCode1(e);
            await dispatch(getJobListAction(obj));
        } else {
            setprojectCode1(e);
        }
    };
    return (
        <Box sx={formContainerStyle}>
            <Card
                sx={{
                    m: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    boxShadow: "none",
                    ...formContainerCardStyle,
                }}
            >
                <Box sx={{ py: 4, px: 5, display: "flex", justifyContent: "center" }}>
                    <Box sx={{ width: "50%" }}>
                        <SelectComponent
                            DataOptions={projectCodeDta?.project_details}
                            labelName={(option) => option.projectCode}
                            value={projectCode1}
                            label={"Project Code"}
                            handelChange={handelProjectCode}
                        />
                    </Box>

                    {/* <InputLabel id="demo-simple-select-label">Project Code</InputLabel> */}
                    {/* <Select
                                        onChange={(e) => {
                                            handelProjectCode(e);
                                        }}

                                        label='Project Code'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"

                                    >
                                        <MenuItem
                                            sx={{
                                                borderBottom: "1px solid white",
                                                fontFamily: "Lato",
                                                fontSize: 13,
                                            }}
                                            value={""}
                                        >
                                            Select Project Code
                                        </MenuItem>
                                        {projectCodeDta?.project_details &&
                                            projectCodeDta?.project_details.length > 0 &&
                                            projectCodeDta?.project_details.map((val, i) => (
                                                <MenuItem
                                                    sx={{
                                                        borderBottom: "1px solid white",
                                                        fontFamily: "Lato",
                                                        fontSize: 13,
                                                    }}
                                                    value={val.projectCode}
                                                >
                                                    {val.projectCode}
                                                </MenuItem>
                                            ))}
                                    </Select> */}
                    {/* </FormControl> */}
                </Box>
                <Box
                    sx={{
                        pl: 5,
                        pr: 3,
                        mb: 5,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flex: 1,
                        maxHeight: "85%",
                    }}
                >
                    <DataGride
                        customHeight={450}
                        disableCheckbox={true}
                        hideDeletion={true}
                        tableData={constructData || []}
                        columns={columns}
                    />
                </Box>
            </Card>
        </Box>
    );
}

export default JoblistPage;
