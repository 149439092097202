import { combineReducers, configureStore } from "@reduxjs/toolkit";
import profileReducer from "./reducers/profileReducer";
import commonReducer from "./reducers/commonReducer";
import masterReducer from "./reducers/masterReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PresistReducer from "./reducers/PresistReducer";

const reducers = combineReducers({
  profile: profileReducer,
  common: commonReducer,
  master: masterReducer,
  // dashboard: dashboardReduser,
  presist: PresistReducer,
});
export const addUserLogoutRequest = (Id) => ({
  type: "USER_LOGUT_REQUEST",

  payload: Id,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["presist"],
  // transforms: [
  //   encryptTransform({
  //     secretKey: uuidv4()
  //   })
  // ]
};
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGUT_REQUEST") {
    state = undefined;
    return;
  }
  return reducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default configureStore({ reducer: persistedReducer, devTools: true });
