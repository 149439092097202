import { BorderColor, Done, Edit, Link } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Card, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataGride from "../../components/datagride/DataGride";
import {
  generalTaskAction,
  getGeneralTaskMappedDataAction,
} from "../../service/action/action";
import { allowedPages } from "../../utils/permissions";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import Swal from "sweetalert2";
import { checkBoxFilter } from "../../utils/UIUtil";
import {
  generalTaskService,
  generalTaskServiceEdit,
} from "../../service/generalTaskService";

const GeneralTaskListScreen = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [isActive, setIsActive] = React.useState(true);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    generalTaskAction("get", "", e ? "?isActive=True" : "?isActive=False").then(
      (res) => {
        if (res) setData(res?.data?.data);
      }
    );
  };
  const handelActivetingUser = async (row) => {
    // console.log(row, "row")
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await generalTaskServiceEdit("put", { id: [row?.generalTaskInfo?.id] });
        handelActiveInactive(false);
      }
    });
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await generalTaskServiceEdit("delete", { id: row });
        setSelectionState([]);
        handelActiveInactive(true);
      }
    });
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const columns = [
    {
      field: "taskName",
      headerName: "Task Name",
      valueGetter: (params) => params.row?.generalTaskInfo?.taskName,
      flex: 1,
    },
    {
      field: "taskDescription",
      headerName: "Task Description",
      valueGetter: (params) => params.row?.generalTaskInfo?.taskDescription,
      flex: 1,
    },
    // {
    //     field: 'taskCategory',
    //     headerName: 'TASK CATEGORY',
    //     valueGetter: (params) => params.row?.generalTaskInfo?.taskCategory,
    //     width: 200
    // },
    // {
    //     field: 'always',
    //     headerName: 'Valid By',
    //     valueGetter: (params) => params.row?.generalTaskInfo?.recurring ? 'Always' : 'Period',
    //     width: 100
    // },
    // {
    //     field: 'startDate',
    //     headerName: 'Start Date',
    //     valueGetter: (params) => params.row?.generalTaskInfo?.startDate ? moment(params.row?.generalTaskInfo?.startDate).format('DD-MM-YYYY  hh:mm A') : '',
    //     width: 200
    // },
    // {
    //     field: 'endDate',
    //     headerName: 'End Date',
    //     valueGetter: (params) => params.row?.generalTaskInfo?.endDate ? moment(params.row?.generalTaskInfo?.endDate).format('DD-MM-YYYY  hh:mm A') : '',
    //     width: 200,
    // },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {allowedPages().Generaltask.GeneralTaskList.edit && isActive && (
            <Tooltip title="Edit" placement="right-end">
              <Edit
                onClick={() =>
                  navigate(
                    `/generalTask/edit?id=${params.row?.generalTaskInfo?.id}`,
                    { state: { data: params.row } }
                  )
                }
                fontSize="small"
                color="success"
                sx={{ mr: 2, cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {allowedPages().Generaltask.generalTaskAllocation.allocatetask &&
            isActive && (
              <Tooltip title="Map" placement="right-end">
                <Link
                  onClick={() => {
                    getGeneralTaskMappedDataAction(
                      params.row?.generalTaskInfo?.id
                    ).then((res) => {
                      navigate(`/generalTask/map`, {
                        state: {
                          data: res?.data?.data,
                          taskId: params.row?.generalTaskInfo?.id,
                        },
                      });
                    });
                  }}
                  fontSize="small"
                  color="secondary"
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            )}
          {!isActive ? (
            <Tooltip title="Restore" placement="top">
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: 10,
                  ml: 2,
                }}
              >
                <Done
                  onClick={() => handelActivetingUser(params.row)}
                  sx={{ color: "#358c4e" }}
                />
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
      ),
      flex: 1,
    },
  ];

  useEffect(() => {
    generalTaskAction(
      "get",
      "",
      isActive ? "?isActive=True" : "?isActive=False"
    ).then((res) => {
      if (res) setData(res?.data?.data);
    });
  }, []);
  return (
    <Box sx={{ height: "100%", background: grey[100], minHeight: 600 }} p={3}>
      <Card sx={{ boxShadow: "none", p: 3, borderRadius: 5 }}>
        {allowedPages().Generaltask.createGeneralTask.create && (
          <Box
            p={2}
            py={0}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                navigate("/generalTask/create");
              }}
              sx={{
                height: 40,

                ...allpageFontStyle.typography1,
                fontWeight: "bold",
                // backgroundColor: "#43a5fc",
                borderRadius: 5,
                color: "#358c4e",
                p: 0,
                px: 2,
                py: 0,
                ":hover": {
                  backgroundColor: "#358c4e",
                  color: "white",
                  borderColor: "#358c4e",
                },
                textTransform: "capitalize",
                borderColor: "#358c4e",
              }}
            >
              <AddIcon sx={{ mr: 1 }} /> Create General Task
            </Button>
          </Box>
        )}
        <Box flex={1} p={2}>
          <DataGride
            tableData={data}
            columns={columns}
            getRowId={(data) => data?.generalTaskInfo?.id}
            customHeight={450}
            // disableCheckbox
            deleteIcon={isActive ? true : false}
            selectionIs={selectionIs}
            selectionState={selectionState}
            selectedFile={selectedFile}
            onSelectionModelChange={onSelectionModelChange}
            deleteFuntion={handleDeleteClick}
            InActiveFilterCheckBox={() =>
              checkBoxFilter(
                isActive,
                setIsActive,
                "Active",
                "Inactive",
                handelActiveInactive
              )
            }
          />
        </Box>
      </Card>
    </Box>
  );
};

export default GeneralTaskListScreen;
