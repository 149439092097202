import { AddToQueueOutlined, AssessmentOutlined, AssignmentOutlined, AssignmentReturnOutlined, BallotOutlined, BusinessOutlined, CameraFrontOutlined, CrisisAlert, DocumentScannerOutlined, EditCalendarOutlined, EditNoteOutlined, ElevatorOutlined, EngineeringOutlined, FeedOutlined, FormatListBulletedOutlined, GroupAddOutlined, Groups3Outlined, LockOpen, LowPriorityOutlined, MergeOutlined, MilitaryTechOutlined, NoteAltOutlined, PhotoCameraFrontOutlined, PostAddOutlined, RestorePageOutlined, RoomPreferencesOutlined, SafetyDividerOutlined, SplitscreenOutlined, SummarizeOutlined, TableChartOutlined, UndoOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { allowedPages } from "../../utils/permissions";



export const UseCodition = (path) => {
    const { pathname } = useLocation();

    return pathname === path
}

export const listPopup = {

    setting: [
        {
            icon: <BusinessOutlined />,
            label: 'Organization',
            link: '/org',
            permission: allowedPages().settings.organization?.showPage()
        },
        {
            icon: <MergeOutlined />,
            label: 'Branch',
            link: '/branch',
            permission: allowedPages().settings.branch?.showPage()
        },
        {
            icon: <SafetyDividerOutlined />,
            label: 'Department',
            link: '/department',
            permission: allowedPages().settings.department?.showPage()
        },
        {
            icon: <EngineeringOutlined />,
            label: 'Designation',
            link: '/designation',
            permission: allowedPages().settings.designation?.showPage()
        },
        {
            icon: <MilitaryTechOutlined />,
            label: 'Grade',
            link: '/grade',
            permission: allowedPages().settings.grade?.showPage()
        },
        {
            icon: <CrisisAlert />,
            label: 'Unit Type',
            link: '/projectMasterList',
            permission: allowedPages().settings.unitType?.showPage()
        },
    ],
    userManagement: [
        {
            icon: <CameraFrontOutlined />,
            label: 'Create User',
            link: '/createUser',
            permission: allowedPages().UserManagement.createUser?.showPage()
        },
        {
            icon: <PhotoCameraFrontOutlined />,
            label: 'User List',
            link: '/user',
            permission: allowedPages().UserManagement.userList?.showPage()
        },

    ],
    teamManagement: [
        {
            icon: <GroupAddOutlined />,
            label: 'Create Team',
            link: '/createTeam',
            permission: allowedPages().TeamManagement.creatTeam?.showPage()
        },
        {
            icon: <Groups3Outlined />,
            label: 'Team List',
            link: '/team',
            permission: allowedPages().TeamManagement.teamList?.showPage()
        },
        {
            icon: <ElevatorOutlined />,
            label: 'User Allocation',
            link: '/team/mapping',
            permission: allowedPages().TeamManagement.teamAllocation?.showPage()
        },

    ],
    role: [
        {
            icon: <LockOpen />,
            label: 'Role Permission',
            link: '/Role/Permission',
            permission: allowedPages().role.rolePermission?.showPage()
        },

    ],
    projectManagement: [
        {
            icon: <PostAddOutlined />,
            label: 'Create Project',
            link: '/createProject',
            permission: allowedPages().ProjectManagement.createProject?.showPage()
        },
        {
            icon: <SummarizeOutlined />,
            label: 'Project List',
            link: '/project',
            permission: allowedPages().ProjectManagement.ProjectList?.showPage()
        },

    ],
    jobs: [
        {
            icon: <AddToQueueOutlined />,
            label: 'Create Job',
            link: '/createjob',
            permission: allowedPages().Jobs.createJob?.showPage()
        },
        {
            icon: <BallotOutlined />,
            label: 'Job List',
            link: '/jobList',
            permission: allowedPages().Jobs.jobList?.showPage()
        },

    ],
    generalTask: [
        {
            icon: <EditNoteOutlined />,
            label: 'Create General Task',
            link: '/generalTask/create',
            permission: allowedPages().Generaltask.createGeneralTask?.showPage()
        },
        {
            icon: <FormatListBulletedOutlined />,
            label: ' General Task List',
            link: '/generalTask/list',
            permission: allowedPages().Generaltask.GeneralTaskList?.showPage()
        },
        {
            icon: <LowPriorityOutlined />,
            label: 'Task Allocation',
            link: '/generalTask/map',
            permission: allowedPages().Generaltask.generalTaskAllocation?.showPage()
        },

    ],
    taskManagement: [
        {
            icon: <NoteAltOutlined />,
            label: 'Task Assignment',
            link: '/taskMapping',
            permission: allowedPages().WorkStation.taskAssignment?.showPage()
        },
        {
            icon: <RestorePageOutlined />,
            label: 'Task Reassignment',
            link: '/taskreassign',
            permission: allowedPages().WorkStation.taskReassignment?.showPage()
        },
        {
            icon: <SplitscreenOutlined />,
            label: 'Task Split',
            link: '/tasks/split',
            permission: allowedPages().WorkStation.taskSplit?.showPage()
        },
        {
            icon: <AssignmentOutlined />,
            label: 'Tasks',
            link: '/tasks',
            permission: allowedPages().WorkStation.tasks?.showPage()
        },
        {
            icon: <AssignmentOutlined />,
            label: 'Completed Task List',
            link: '/completedTask',
            permission: allowedPages().WorkStation.tasks?.showPage()
        },
        {
            icon: <FeedOutlined />,
            label: ' General Task Details',
            link: '/generalTask/user',
            permission: allowedPages().WorkStation.tasks?.showPage()
        },
        {
            icon: <UndoOutlined />,
            label: 'Task Revert',
            link: '/tasks/revert',
            permission: allowedPages().WorkStation.taskRevert?.showPage()
        },
        {
            icon: <AssignmentReturnOutlined />,
            label: 'Reverted Task',
            link: '/taskRevert',
            permission: allowedPages().WorkStation.revertedTask?.showPage()
        },
    ],
    report: [
        {
            icon: <AssessmentOutlined />,
            label: 'Task Report',
            link: '/taskReport',
            permission: allowedPages().report.taskReport?.showPage()
        },
        {
            icon: <SummarizeOutlined />,
            label: 'Task Status Report',
            link: '/taskStatusReport',
            permission: allowedPages().report.taskStatusReport?.showPage()
        },
        {
            icon: <DocumentScannerOutlined />,
            label: 'Project Report',
            link: '/project_report',
            permission: allowedPages().report.projectReport?.showPage()
        },
        {
            icon: <TableChartOutlined />,
            label: 'Job Report',
            link: '/job_report',
            permission: allowedPages().report.jobReport?.showPage()
        },

    ],
    meeting: [
        {
            icon: <EditCalendarOutlined />,
            label: 'Schedule Meeting',
            link: '/request',
            permission: allowedPages().meeting.schedule?.showPage()
        },
        {
            icon: <RoomPreferencesOutlined />,
            label: 'Create Hall',
            link: '/createHall',
            permission: allowedPages().meeting.createHall?.showPage()
        },

    ],
}