import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  AttachFile,
  Attachment,
  CloseRounded,
  Delete,
  Info,
} from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import { grey, red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch } from "react-redux";
import {
  createAnnouncementAction,
  createQueryAction,
  getJobByProjectAction,
  getProjectCodeBYUserAction,
  getjobcodeAction,
} from "../../../redux/action/action";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../stylesheet.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { sortedFontOptions } from "../announcements/createAnnouncement";
import Dropzone from "react-dropzone";
import { deleteFile, sendAttachment } from "../../../service/helpdeskService";
import { ATTACH_MESSAGE, RESET_LABEL } from "../../../utils/textName";
import { PopupMessage, TriggerAlert } from "../../../utils/UIUtil";
import { headerBackground } from "../../datagride/DataGride";
import PopupComponent from "../../commonComponents/PopupComponent";
const schema = yup.object().shape({
  projectCode: yup.string().required("Select Project"),
  jobCode: yup.string().required("Select Job"),
  queryContent: yup.string().required("Content required"),
  criticality: yup.string(),
  attachmentLocations: yup.object(),
});

export var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],

    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

export var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",

  "align",
  "size",
];

export default function CreateQuery() {
  const navigate = useNavigate();
  const { globapOrganization } = useSelector((state) => state.presist);
  const { userprofile } = useSelector((state) => state.presist);
  const { projectCodeDta, jobList, projectByJobCode } = useSelector(
    (state) => state.master
  );
  const [count, setCount] = useState(0);
  const [dailog, setDailog] = React.useState(false);
  const { state } = useLocation();
  console.log(state, "state");
  const dispatch = useDispatch();
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
    } else if (alertPopup.type === PopupMessage.resetType) {
      handleClear();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  useEffect(() => {
    dispatch(
      getProjectCodeBYUserAction(
        "post",
        `/report/projectInfo/${
          userprofile?.userAccess?.superUser === true
            ? globapOrganization?.orgId
            : userprofile?.userInfo?.userInfo?.orgId || ""
        }/`
      )
    );
  }, []);

  useEffect(() => {
    if (state) {
      setValue("projectCode", state.projectCode);
      setValue("jobCode", state.jobCode);
      setValue("criticality", state.criticality);
      setValue("queryContent", state.queryContent);
      setValue("queryCode", state.queryCode);
      setValue("orgId", state.orgId);
      setAlignment(state.criticality);
      dispatch(
        getJobByProjectAction({
          projectCode: state.projectCode,
          orgId: state.orgId,
        })
      );
    }
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: "#c92a2a",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "black" : "#ffc9c9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "red",
        border: "6px solid black",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const box = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    pb: 1,
  };
  const headings = {
    width: "10%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "end",
  };
  const contents = {
    width: "80%",
    // px: 1,
  };
  const form = useForm({
    defaultValues: {
      queryCode: null,
      projectCode: "",
      jobCode: "",
      orgId: globapOrganization?.orgId,
      queryContent: "",
      criticality: "low",
      answerCode: null,
      currentSlaLevel: null,
      attachmentLocations: {},
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;
  const onsubmit = async (data) => {
    console.log(data, "data");
    const t = Object.values(getValues("attachmentLocations"));
    await dispatch(
      createQueryAction("post", globapOrganization.orgId, {
        ...data,
        attachmentLocations: t.length ? t : null,
      })
    );
    handleClear();
    // if (state) navigate('/myQueryList')
    setFileValue([]);
  };
  const handleClear = () => {
    reset();
    setAlignment("low");
    setFileValue([]);
    //  if (state)  navigate('/myQueryList')
  };
  const [alignment, setAlignment] = React.useState("low");
  const [fileValue, setFileValue] = React.useState([]);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    console.log(newAlignment, "yhgyuhgyu");
  };
  const clearField = (file, index) => {
    console.log("clearrr");
    const u = [...fileValue];
    u.splice(index, 1);
    console.log(file, "fileeee1");
    setFileValue(u);
    console.log(getValues("attachmentLocations"), "attchhkh");
    console.log(
      getValues("attachmentLocations")[file.path],
      file.path,
      "checkkk"
    );
    if (getValues("attachmentLocations")[file?.path]) {
      deleteFile(getValues("attachmentLocations")[file?.path]);
      console.log(file, "fileeee2");
    }
    const v = { ...getValues("attachmentLocations") };
    delete v[file?.path];
    setValue("attachmentLocations", v);
    trigger("attachmentLocations");
  };

  const handelChangeFile = async (acceptedFiles) => {
    console.log(fileValue, "ghg");
    let v = fileValue.filter(
      (value) => value !== fileValue.map((file) => file)
    );
    console.log(acceptedFiles, "ghg");
    const maxSize = 1048576;
    // let v = fileValue.filter((value) => value !== fileValue.map((file) => file))
    const e = acceptedFiles?.filter((file) => file.size <= maxSize);
    console.log(e, "ghge");
    if (e.length !== acceptedFiles.length) {
      TriggerAlert("warning", ATTACH_MESSAGE);
    }
    if (e.length) {
      let w = e.filter(
        (value) =>
          !(fileValue?.findIndex((file) => file.name === value.name) >= 0)
      );
      console.log(w, "vvvvvvvv");
      console.log(e, fileValue, "fdgfhgj");
      if (w.length !== e.length && fileValue.length) {
        alert("File already exists");
      }
      setFileValue([...fileValue, ...w]);
      sendAttachment(w).then((res) => {
        const t = {};
        res?.data.map((list) => {
          t[list.originalFileName] = list?.fileCode;
        });
        // setValue('attachmentLocations', [...getValues('attachmentLocations'),...res?.data.map((list)=>list?.fileCode)  ];);
        setValue("attachmentLocations", {
          ...getValues("attachmentLocations"),
          ...t,
        });
        trigger("attachmentLocations");
        console.log(
          res?.data,
          res?.data.map((list) => list?.fileCode),
          "response"
        );
      });
    }
  };
  console.log(fileValue, getValues("attachmentLocations"), "attachmmmmm");
  useEffect(() => {
    if (
      errors?.projectCode?.message ||
      errors?.queryContent?.message ||
      errors?.jobCode?.message
    ) {
      TriggerAlert("warning", "Mandatory fields can't be empty");
    }
  }, [errors]);
  const toggleStyle = {
    width: 50,
    height: 23,
    fontSize: 11,
    fontFamily: "Lato",
  };
  useEffect(() => {
    dispatch(getjobcodeAction(getValues("projectCode")));
  }, [getValues("projectCode")]);
  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onsubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            background: grey[100],
            height: "100%",
            pt: 2,
            // flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* <Card sx={{position:'relative' ,width: '60%', height: '60vh', top:0,left:0, background:priority ? '#ff8787' : '#37b24d ', borderRadius:5}}> */}

          <Card
            sx={{
              // zIndex: 1000,
              // width: { lg: "65%", md: "65%" },
              // height: { lg: 720, md: 720 },
              width: { lg: "95%", md: "75%" },
              height: { lg: "95%", md: "95%" },
              // minHeight: "60vh",
              // p: 2,
              py: 10,
              pl: 8,
              // display: "flex",
              // flexDirection: "column",
              borderRadius: 5,
              overflow: "auto",
              //   position: "absolute",
              // justifyContent: "center",
              // alignItems: "center",
              //   borderTopRightRadius:100
              // boxShadow: priority ? ' 0px 50px 15px #ff8787' : '0px 50px 15px #37b24d'
              boxShadow: "none",
            }}
          >
            {getValues("projectCode") ? (
              <Box
                onClick={() => setDailog(true)}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: 13,
                  color: "dodgerblue",
                  alignItems: "center",
                  cursor: "pointer",
                  px: 5,
                  py: 2,
                  // background: "red",
                  // py: 2,
                }}
              >
                Info{" "}
                <Info fontSize="inherit" sx={{ cursor: "pointer", mx: 1 }} />
              </Box>
            ) : null}
            <Grid container spacing={2} sx={{ px: 2 }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box sx={{}}>
                  {/* <Box sx={{ ...headings }}>Project Code*</Box> */}
                  <Box sx={{ ...contents }}>
                    <Controller
                      control={control}
                      name="projectCode"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl
                          size="small"
                          //  error={errors?.projectCode?.message}
                          fullWidth
                          sx={{}}
                        >
                          <InputLabel shrink>Project Code*</InputLabel>
                          <Select
                            notched
                            fullWidth
                            // notched
                            disabled={state}
                            label={"Project Code*"}
                            value={value}
                            onChange={(e) => {
                              console.log(e.target.value, "uh");
                              onChange(e);
                              trigger("projectCode");
                              dispatch(
                                getJobByProjectAction({
                                  projectCode: e.target.value,
                                  orgId:
                                    userprofile?.userAccess?.superUser === true
                                      ? globapOrganization?.orgId
                                      : userprofile?.userInfo?.userInfo?.orgId,
                                })
                              );
                            }}
                            // error={errors?.projectCode?.message}
                            onBlur={() => trigger("projectCode")}
                            // MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                            sx={{
                              width: "100%",
                              borderRadius: 2,
                            }}
                            // label='Project Name*'
                            inputProps={{
                              "aria-label": "Project Name",
                            }}
                          >
                            {projectCodeDta?.project_details?.map((val) => (
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  fontFamily: "Lato",
                                  fontSize: 13,
                                }}
                                value={val?.projectCode}
                              >
                                {val?.projectCode}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <FormHelperText sx={{ color: "red" }}>
                            {errors.projectCode ? errors.projectCode.message : null}
                          </FormHelperText> */}
                        </FormControl>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box sx={{}}>
                  {/* <Box sx={{ ...headings }}>Job Code*</Box> */}
                  <Box sx={{ ...contents }}>
                    <Controller
                      control={control}
                      name="jobCode"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl
                          size="small"
                          // error={errors?.jobCode?.message}
                          fullWidth
                          sx={{}}
                        >
                          <InputLabel shrink>Job Code*</InputLabel>
                          <Select
                            disabled={state}
                            fullWidth
                            notched
                            label="Job Code*"
                            // helperText={
                            //   errors.jobCode ? errors?.jobCode?.message : null
                            // }
                            // error={errors?.jobCode?.message}
                            onChange={(e) => {
                              onChange(e);
                              trigger("jobCode");
                            }}
                            onBlur={() => trigger("jobCode")}
                            // MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                            value={value}
                            sx={{
                              width: "100%",
                              borderRadius: 2,
                            }}
                            inputProps={{
                              "aria-label": "Project Name",
                            }}
                          >
                            {jobList?.jobList?.map((val) => (
                              <MenuItem
                                sx={{
                                  borderBottom: "1px solid white",
                                  fontFamily: "Lato",
                                  fontSize: 13,
                                }}
                                value={val?.jobCode}
                              >
                                {val?.jobCode}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <FormHelperText sx={{ color: "red" }}>
                            {errors.jobCode ? errors.jobCode.message : null}
                          </FormHelperText> */}
                        </FormControl>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12} sx={{ pl: 1 }}>
                <Box
                  sx={{ display: "flex", gap: 1, alignItems: "center", pt: 1 }}
                >
                  <Box
                    sx={{
                      fontSize: 11,
                      display: { sm: "none", xs: "none", lg: "block" },
                    }}
                  >
                    Priority*
                  </Box>
                  <Box sx={{ pt: 0.5, m: 0 }}>
                    <Controller
                      control={control}
                      name="criticality"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl size="small" fullWidth>
                          {/* <InputLabel shrink>Priority*</InputLabel> */}
                          <ToggleButtonGroup
                            disabled={state}
                            color="primary"
                            // value={value}
                            sx={{}}
                            exclusive
                            onChange={(e, v) => {
                              handleChange(e, v);
                              onChange(v);
                            }}
                            aria-label="Platform"
                          >
                            <ToggleButton
                              size="small"
                              sx={{
                                textTransform: "capitalize",
                                ...toggleStyle,
                                color:
                                  alignment?.toLowerCase() === "low"
                                    ? "white"
                                    : null,
                                borderRadius: "15px 15px",
                                background:
                                  alignment?.toLowerCase() === "low"
                                    ? "#339af0"
                                    : null,
                                ":hover": {
                                  color: "white",
                                  background: "#339af0",
                                },
                              }}
                              value="low"
                            >
                              Low
                            </ToggleButton>
                            <ToggleButton
                              // selected
                              size="small"
                              sx={{
                                ...toggleStyle,
                                textTransform: "capitalize",
                                background:
                                  alignment?.toLowerCase() === "medium"
                                    ? "#ffc078"
                                    : null,
                                color:
                                  alignment?.toLowerCase() === "medium"
                                    ? "white"
                                    : null,
                                ":hover": {
                                  color: "white",
                                  background: "#ffc078",
                                },
                              }}
                              value="medium"
                            >
                              Medium
                            </ToggleButton>
                            <ToggleButton
                              size="small"
                              sx={{
                                ...toggleStyle,
                                textTransform: "capitalize",
                                background:
                                  alignment?.toLowerCase() === "high"
                                    ? "#ff8787"
                                    : null,
                                borderRadius: "15px 15px",
                                color:
                                  alignment?.toLowerCase() === "high"
                                    ? "white"
                                    : null,
                                ":hover": {
                                  color: "white",
                                  background: "#ff8787",
                                },
                              }}
                              value="high"
                            >
                              High
                            </ToggleButton>
                          </ToggleButtonGroup>
                          <FormHelperText sx={{ color: "red" }}>
                            {errors.criticality
                              ? errors.criticality.message
                              : null}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {" "}
                <Box sx={{}}>
                  {/* <Box sx={headings}>Query*</Box> */}

                  <Box sx={{ width: "92%", overflow: "hidden" }}>
                    <Box
                      sx={{
                        fontSize: 12,
                        my: 1,
                        //  color: errors?.queryContent?.message ? "#d32f2f" : ""
                      }}
                    >
                      Query*
                    </Box>
                    <Controller
                      control={control}
                      name="queryContent"
                      // helperText={errors.message ? errors?.message?.message : null}
                      // error={errors?.message?.message}
                      // onBlur={() => trigger("message")}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Box
                        // onBlur={() => trigger("message")}
                        // error={errors?.message?.message && getValues('message')===''}
                        // sx={{
                        //   border:
                        //     errors?.queryContent
                        //       ? "0.5px solid red"
                        //       : null,
                        // }}
                        >
                          <SunEditor
                            setContents={value}
                            onChange={(e) => setValue("queryContent", e)}
                            height="300"
                            // width="200"
                            setDefaultStyle="font-family: Arial; font-size: 14px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                ["table", "horizontalRule", "link"],
                              ],
                              defaultTag: "div",
                              minHeight: "200px",
                              maxHeight: 300,
                              showPathLabel: false,
                              font: sortedFontOptions,
                            }}
                          />
                        </Box>
                      )}
                    />
                    {/* {errors?.queryContent && getValues('queryContent') === '' ? (
                    <Typography sx={{ fontSize: 12, color: "#d32f2f", ml: 0.5 }}>
                      {errors?.queryContent?.message}
                    </Typography>
                  ) : null} */}
                    {/* <Controller
                  control={control}
                  name="queryContent"
                  // helperText={errors.message ? errors?.message?.message : null}
                  // error={errors?.message?.message}
                  // onBlur={() => trigger("message")}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <Box sx={{ borderBottom: "1px solid #c4c4c4" }}>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          modules={modules}
                          formats={formats}
                          // scrollingContainer
                          placeholder="Max characters = 1000"
                          onChange={(content, delta) => {
                            if (delta.ops.length >= 2) {
                              console.log(delta?.ops, "datadada");
                              let t =
                                delta.ops
                                  .slice(1)
                                  .reduce(
                                    (acc, curr) =>
                                      (acc += curr?.insert?.length),
                                    0
                                  ) || 0;

                              console.log(t, "dfdsgsgfdg");
                              if (delta.ops[1].delete) {
                                setCount(delta?.ops[0]?.retain);
                                console.log(delta?.ops[0]?.retain, "efdgfdg");
                              } else {
                                setCount(delta?.ops[0]?.retain + t);
                              }
                              if (delta?.ops[0]?.retain + t <= 1000) {
                                console.log("retain");
                                return onChange(content);
                              }
                            }
                            else {
                              onChange(content);
                              setCount(delta?.ops[0]?.insert?.length);
                            }
                            trigger("queryContent");
                          }}
                          style={{
                            //     '.ql-toolbar': { position: 'absolute', top: 0,left:0,right:0,background:'red'},
                            ".ql-editor": {
                              height: "160px",
                              overflowY: "hidden",
                              overflow: "hidden",
                              // Bottom: '20px'
                              // marginBottom:10
                              background: "red",
                            },
                            //  '.ql-container ql-snow': {
                            //       height: '600px',
                            //       overflowY: 'hidden',
                            //       background:'red'
                            //   },
                            width: "100%",
                            height: 160,
                            maxHeight: 160,
                            overflow: "auto",

                            //  background:'red',
                            border: errors?.queryContent
                              ? "1px solid red"
                              : "none",
                          }}
                        ></ReactQuill>
                      </Box>
                      {errors?.queryContent && value === "" ? (
                        <Typography
                          sx={{ fontSize: 12, color: "red", ml: 0.5 }}
                        >
                          {errors?.queryContent?.message}
                        </Typography>
                      ) : (
                        // null
                        <Typography sx={{ fontSize: 12, ml: 0.5 }}>
                          {" "}
                          {count < 999
                            ? "Remaining characters =" + (1000 - count)
                            : count >= 999 ? 'Reached max limit'
                              : "Remaining characters = 1000"}
                        </Typography>
                      )}
                    </>
                  )}
                /> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{}}>
                  <Box
                    sx={{ ...contents, overflow: "hidden", display: "flex" }}
                  >
                    <Box sx={{ fontSize: 12, my: 1, mr: 2 }}>
                      Attach Files (max size - 1MB)
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      {/* <label
                        htmlFor="uploadAttachments"
                        type="button"
                        style={{
                          display: "flex",
                          // flexDirection: "column",
                        }}
                      > */}
                      <Dropzone
                        maxFiles={10}
                        multiple
                        noClick
                        onDrop={(acceptedFiles) =>
                          handelChangeFile(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps, open }) => (
                          <div {...getRootProps()} style={{ paddingTop: 5 }}>
                            <input multiple={false} {...getInputProps()} />
                            <Button
                              onClick={open}
                              size="small"
                              variant="contained"
                              component="span"
                              sx={{
                                borderRadius: 5,
                                mx: 0.7,
                                height: 26,
                                ":hover": {
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                                },
                              }}
                            >
                              <Attachment sx={{ color: "white", mr: 0 }} />
                              {/* <Box sx={{ fontSize: 10 }}>Upload File(s)</Box> */}
                            </Button>
                          </div>
                        )}
                      </Dropzone>

                      {/* </label> */}
                      {fileValue.length >= 1 ? (
                        <Box
                          sx={{
                            fontSize: 11,
                            mr: 1,
                            pl: 2,
                            display: "flex",
                            flexWrap: "wrap",
                            maxWidth: "70%",
                            // background:'red'
                          }}
                        >
                          {fileValue?.map((list, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mx: 1,
                              }}
                            >
                              {list?.name}

                              {!getValues("attachmentLocations")[list?.name] ? (
                                <CircularProgress
                                  sx={{ ml: 0.5 }}
                                  size={20}
                                  color="success"
                                />
                              ) : (
                                <Delete
                                  sx={{
                                    fontSize: 15,
                                    cursor: "pointer",
                                    ml: 0.5,
                                  }}
                                  color="error"
                                  onClick={() => {
                                    clearField(list, index);
                                  }}
                                />
                              )}
                            </Box>
                          ))}
                        </Box>
                      ) : null}

                      {/* <input
                        type="file"
                        id="uploadAttachments"
                        ref={fileRef}
                        multiple

                        onChange={handelChangeFile}
                        style={{ color: "black", display: "none" }}
                      /> */}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {" "}
                <Box sx={{ ...box, justifyContent: "center", pt: 5 }}>
                  {/* <Button
                    onClick={() => navigate(-1)}
                    size="small"
                    color="primary"
                    sx={{
                      mx: 2,

                      borderRadius: 5,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                    }}
                    variant="contained"
                  >
                    List
                  </Button> */}
                  <Button
                    size="small"
                    type="submit"
                    sx={{
                      mx: 2,
                      backgroundColor: "#258d4d",

                      borderRadius: 5,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                        backgroundColor: "#258d4d",
                      },
                    }}
                    variant="contained"
                  >
                    {state ? "Update" : "Submit"}
                  </Button>

                  <Button
                    color="error"
                    size="small"
                    onClick={() => {
                      setAlertPopup((per) => ({
                        ...per,
                        open: true,
                        type: PopupMessage.resetType,
                        title: PopupMessage.reset,
                      }));
                    }}
                    sx={{
                      borderRadius: 5,
                      mx: 2,
                      ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                      },
                    }}
                    variant="contained"
                  >
                    {RESET_LABEL}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </form>
      <Dialog
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "30px",
            border: `3px solid ${headerBackground}`,
            zIndex: 1000000,
          },
        }}
        open={dailog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            background: headerBackground,
            p: 1,
            mb: 2,
          }}
        >
          <Typography variant="h6" textAlign={"center"}>
            SLA Details
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            spacing={2}
            sx={{ pl: 7, fontSize: 13, fontFamily: "Lato" }}
          >
            <Grid
              item
              xs={6}
              md={4}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Level 1 Escalation (Team-Lead)
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={8}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <ul>
                <li>
                  {projectByJobCode?.slaEscalatedInfo?.sla1EscalatedTo.map(
                    (teams) =>
                      teams.teamInfo.map((list) => list?.users).join(",  ")
                  )}
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Level 2 Escalation (Team-Manager)
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={8}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <ul>
                <li>
                  {projectByJobCode?.slaEscalatedInfo?.sla2EscalatedTo.map(
                    (teams) =>
                      teams.teamInfo.map((list) => list?.users).join(",  ")
                  )}
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              TroubleShooters
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              lg={8}
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {projectByJobCode?.slaEscalatedInfo?.troubleShooterInfo.map(
                (list) => (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ul>
                      {list.users.map((user) => (
                        <li>{user}</li>
                      ))}
                    </ul>
                  </Box>
                )
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            sx={{
              background: red[300],
              borderRadius: 5,
              color: "white",
              mr: 5,
              ":hover": { background: red[300] },
              px: 2,
            }}
            onClick={() => setDailog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
