import { Box } from "@mui/material";
import "./index.css";
import { assignConditionType } from "../TaskJobData";
const AssignTab = ({value,setValue}) => {

  return <Box className="tab-container">
    <Box onClick={()=>setValue(assignConditionType[0])} className={`tab tab-1 ${value.tab === 1 ?'tab-1-checked' :''}`}>Auto</Box>
    <Box onClick={()=>setValue(assignConditionType[1])} className={`tab tab-2 ${value.tab === 2 ?'tab-2-checked' :''}`}>Manual</Box>
    <Box onClick={()=>setValue(assignConditionType[2])} className={`tab tab-3 ${value.tab === 3 ?'tab-3-checked' :''}`}>Excel</Box>
  </Box>;
};

export default AssignTab;
