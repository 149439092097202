import { Avatar, Box, Card, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useNavigate } from "react-router-dom";
import { downloadFileService } from "../../service/helpdeskService";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  FiberManualRecord,
  Info,
  PriorityHigh,
  RunningWithErrors,
} from "@mui/icons-material";
import { allpageFontStyle } from "../../utils/FontStyle";
export default function QueryList({ list, request }) {
  const navigate = useNavigate();
  const [attachCount, setAttachCount] = useState(false);
  console.log(list, "ghghjghhj");
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        height: 30,
        width: 30,
        bgcolor: stringToColor(name),
        ...allpageFontStyle.sideBartypography
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  const fileDownload = (file) => {
    downloadFileService(file);
  };
  const replaceFun = (str) => {
    let originalString = str;
    let stringWithoutParentheses = originalString?.replace(/\s*\(.*?\)\s*/, "");
    return stringWithoutParentheses;
  };
  return (
    <Box
      onClick={() => navigate(`/forum/${list.queryInfo?.queryCode}`)}
      sx={{
        width: "98%",
        display: "flex",
        borderBottom: "1px solid #cfd1d4",
        py: 1,
        pt: 2,
        pr: 3,
        ":hover": {
          background: "#e7f5ff",
          ".innertag div": { textDecoration: "underline" },
          ".queryCode": { textDecoration: "underline" },
          cursor: "pointer",
        },
      }}
    >
      <Box sx={{
        height: 45, width: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', border: '0.5px solid grey',
        mr: 2
      }}>

        <Avatar sizes="" sx={{ height: 40, width: 40, borderRadius: '50%' }} alt={list?.createdUserInfo?.firstname +
          " " +
          list?.createdUserInfo?.lastname} src={`data:image/jpeg;base64,${list?.createdUserImage?.userBase64Image}`} />
      </Box>

      <Box
        sx={{ width: "95%", p: 0, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            className="queryCode"
            sx={{
              ...allpageFontStyle.buttonFontStyle2,
              color:
                request && list?.alertInfo?.alertStatus === "HIGH"
                  ? "#ff6b6b"
                  : request && list?.alertInfo?.alertStatus !== "HIGH"
                    ? "#1971c2"
                    // : list?.queryInfo?.criticality === "high"
                    // ? "#ff6b6b"
                    // : list?.queryInfo?.criticality === "medium"
                    // ? "#fd7e14"
                    : "#1971c2",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
              // letterSpacing: 0.1,
              alignItems: "center",
              // background:'pink'
            }}
          >
            {/* {list?.alertInfo?.alertStatus === "HIGH" ? (
              <FiberManualRecord fontSize="small" sx={{ mr: 0.5, p: 0 }} />
            ) : null} */}

            <Box sx={{}}> {list?.queryInfo?.queryCode}</Box>

          </Box>
          {request ? (
            list?.alertInfo?.alertMessage ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  ...allpageFontStyle.buttonFontStyle2,
                  width: "50%",

                  alignItems: "center",
                }}
              >
                {" "}
                <Box sx={{ ...allpageFontStyle.typography1, color: "#fa5252" }}>
                  {list?.alertInfo?.alertMessage}!
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  ...allpageFontStyle.typography4,
                  width: "50%",

                  alignItems: "center",
                }}
              >
                <Box sx={{ mr: 1 }}>Remaining Time :</Box>
                <Box
                  sx={{
                    display: "flex",
                    ...allpageFontStyle.title1,
                    color:
                      list?.alertInfo?.alertStatus === "LOW"
                        ? "#1971c2"
                        : list?.alertInfo?.alertStatus === "MEDIUM"
                          ? "#f59f00"
                          : list?.alertInfo?.alertStatus === "HIGH"
                            ? "#fa5252"
                            : null,
                  }}
                >
                  {list?.alertInfo?.remainingTime?.days &&
                    list?.alertInfo?.remainingTime?.days !== 0
                    ? `${list?.alertInfo?.remainingTime?.days || 0}d`
                    : null}{" "}
                  {list?.alertInfo?.remainingTime?.hours &&
                    list?.alertInfo?.remainingTime?.hours !== 0
                    ? `${list?.alertInfo?.remainingTime?.hours || 0}h`
                    : null}{" "}
                  {list?.alertInfo?.remainingTime?.minutes &&
                    list?.alertInfo?.remainingTime?.minutes !== 0
                    ? `${list?.alertInfo?.remainingTime?.minutes || 0}m`
                    : null}
                  {/* <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Box sx={{px:1, display:'flex', justifyContent:'center', alignItems:'center'}}>{+list?.alertInfo?.remainingTime?.days <= 9 ? 0+list?.alertInfo?.remainingTime?.days : list?.alertInfo?.remainingTime?.days }</Box>
                    <Box>D</Box>
                  </Box>
:
                  <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Box sx={{px:1}}>{list?.alertInfo?.remainingTime?.hours || 0}</Box>
                    <Box>H</Box>
                  </Box>
:
                  <Box sx={{display:'flex', flexDirection:'column', alignItems:'center',px:1}}>
                    <Box sx={{px:1}}>{list?.alertInfo?.remainingTime?.minutes || 0}</Box>
                    <Box>M</Box>
                  </Box> */}
                </Box>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: 'flex', justifyContent: 'flex-end', ...allpageFontStyle.typography2, alignItems: 'center', width: "50%", fontWeight: 'bold',
                color:
                  list?.queryInfo?.criticality === "high"
                    ? "#ff6b6b"
                    : list?.queryInfo?.criticality === "medium"
                      ? "#fd7e14"
                      : "#1971c2",
              }}
            >
              <FiberManualRecord fontSize="inherit" />
              {list?.queryInfo?.criticality === "high"
                ? "HIGH"
                : list?.queryInfo?.criticality === "medium"
                  ? "MEDIUM"
                  : list?.queryInfo?.criticality === "low"
                    ? "LOW"
                    : null}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ color: "#4c566a", ...allpageFontStyle.typography2 }}>
            created by
            <span
              style={{
                fontWeight: "bold",
                color: "#258d4d",
                marginLeft: 1,
                marginRight: 1,
              }}
            >
              {list?.createdUserInfo?.firstname +
                " " +
                list?.createdUserInfo?.lastname}
            </span>
            on
            <span
              style={{
                fontWeight: "bold",
                color: "#258d4d",
                marginLeft: 1,
                marginRight: 1,
              }}
            >
              {" "}
              {moment(list?.queryInfo?.createdOn).format("MMM Do YYYY, h:mm a")}
            </span>
          </Box>
          {request ?
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                // width: "50%",
                alignItems: "center",
                // ml: 2,
                px: 0,
                // background:'pink'
              }}
            >
              {list?.alertInfo?.currentLevel && (

                <Box sx={{
                  display: "flex", alignItems: "center",
                  // background:list.alertInfo?.currentLevel === "LEVEL_1"
                  // ? "#fd7e14"
                  // : "#ffe3e3",
                  color: 'black', borderRadius: 3,
                }}>
                  <Info fontSize='inherit' sx={{ color: '#1c7ed6' }} />
                  <Box sx={{ ...allpageFontStyle.buttonFontStyle2 }}>
                    Escalated To{" "}
                    {list?.alertInfo?.escalateTo &&
                      replaceFun(list?.alertInfo?.escalateTo[0])}
                  </Box>
                  <Box
                    sx={{

                      ml: 0.5,
                      px: 0,
                      ...allpageFontStyle.buttonFontStyle2

                      // background:
                      //   list.alertInfo?.currentLevel === "LEVEL_1"
                      //     ? "#fd7e14"
                      //     : "#fa5252",
                      //     color:'white'
                    }}
                  >
                    for {list.alertInfo?.currentLevel}
                  </Box>
                </Box>
              )}
            </Box>
            : null}
        </Box>
        <Typography
          sx={{
            p: 0,
            m: 0,
            mt: 1,
            ".innertag div": {
              width: "100%",
              maxWidth: "100%",
              overflow: "hidden",
              fontSize: 13,
              // fontFamily: "Lato",
              p: 0,
              m: 0, pr: 2,
              // background:'#f8f9fa'
              // ':hover':{cursor:'pointer'}
              // ':hover':{'.innertag':{ color:'#4c6ef5',textDecoration:'underline', cursor:'pointer'}}
            },
            ".innertag table tbody tr td": {
              borderCollapse: "collapse",
              border: "1px solid black",
            },
          }}
        >
          <p
            style={{ padding: 0, margin: 0 }}
            className="innertag"
            dangerouslySetInnerHTML={{
              __html: list?.queryInfo?.queryContent,
            }}
          ></p>
        </Typography>

        <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
          {list?.fileInfo?.length ? (
            <Box
              sx={{
                ...allpageFontStyle.typography2,
                display: "flex",
                my: 0.5,
                flexWrap: "wrap",
                width: "50%",
                alignItems: "center",
              }}
            >
              {list?.fileInfo?.length > 0 ? (
                <Box
                  // onClick={(e) => {fileDownload(files);e.stopPropagation();}}
                  sx={{
                    p: 0.5,
                    border: "0.1px solid grey",
                    display: "flex",
                    m: 0.5,
                    borderRadius: 3,
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 9999,
                  }}
                >
                  {list.fileInfo[0].originalFileName}
                  {/* <DownloadForOfflineIcon fontSize="small" sx={{ color: '#1864ab', ml: 0.5 }} /> */}
                </Box>
              ) : null}
              {list?.fileInfo?.length > 1 ? (
                <Box
                  // onClick={(e) => {fileDownload(files);e.stopPropagation();}}
                  sx={{
                    p: 0.5,
                    border: "0.1px solid grey",
                    display: "flex",
                    m: 0.5,
                    borderRadius: 3,
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 9999,
                  }}
                >
                  {list.fileInfo[1].originalFileName}
                  {/* <DownloadForOfflineIcon fontSize="small" sx={{ color: '#1864ab', ml: 0.5 }} /> */}
                </Box>
              ) : null}
              {list?.fileInfo?.length > 2 ? (
                <Box
                  sx={{
                    color: "#1864ab",
                    ...allpageFontStyle.typography2,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                  onClick={() => setAttachCount(true)}
                >
                  +{list.fileInfo?.length - 2} attachments
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box
              sx={{
                ...allpageFontStyle.typography2,
                display: "flex",
                my: 0.5,
                flexWrap: "wrap",
                width: "70%",
                // background:'red',
                visibility: "hidden",
              }}
            >
              {list.fileInfo?.map((files) => (
                <Box
                  // onClick={(e) => {fileDownload(files);e.stopPropagation();}}
                  sx={{
                    p: 0.5,
                    border: "0.1px solid grey",
                    display: "flex",
                    m: 0.5,
                    borderRadius: 3,
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 9999,
                  }}
                >
                  {files?.originalFileName}
                  {/* <DownloadForOfflineIcon fontSize="small" sx={{ color: '#1864ab', ml: 0.5 }} /> */}
                </Box>
              ))}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: 'flex-end', minWidth:'50%' }}>

            <Box sx={{ color: "#4c566a", ...allpageFontStyle.typography2, ml: 2, display: 'flex', alignItems: 'center',whiteSpace:'nowrap', wordBreak:'keep-all' }}>
              <FiberManualRecord fontSize="inherit" sx={{ color: "#258d4d" }} />
              <span style={{ whiteSpace:'nowrap'}}>last updated by</span>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#258d4d",
                  paddingLeft: 3,
                  paddingRight: 3,
                  wordBreak:'keep-all',
                 whiteSpace:'nowrap'
                }}
              >
                {list?.createdUserInfo?.firstname +
                  " " +
                  list?.createdUserInfo?.lastname}
              </span>
              on
              <span
                style={{
                  fontWeight: "bold",
                  color: "#258d4d",
                  paddingLeft: 3,
                  paddingRight: 3,
                  whiteSpace:'nowrap'
                }}
              >
                {" "}
                {moment(list?.queryInfo?.createdOn).format("MMM Do YYYY, h:mm a")}
              </span>
            </Box>
            {request ? (
              null
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // width: "30%",
                  ml: 3,
                  alignItems: "center",
                }}
              >
                {/* <Box
                sx={{
                  display: "flex",
                  // background: "#358c4e",
                  px: 1,
                  fontSize: 13,
                  alignItems: "center",
                  borderRadius: 2,
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {list.queryInfo?.status}
              </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    background:
                      // list.queryInfo?.status === "Open" ? "#4dabf7" :
                      "#358c4e",
                    px: 1,

                    alignItems: "center",
                    borderRadius: 2,
                    color: "white",
                    ...allpageFontStyle.typography4
                  }}
                >
                  <QuestionAnswerIcon sx={{ mr: 1 }} fontSize="inherit" />
                  {list?.ansListInfo?.length}
                </Box>
              </Box>
            )}

          </Box>
        </Box>
      </Box>
    </Box>
  );
}
