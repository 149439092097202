import { CheckCircle, CheckCircleOutline, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../components/commonComponents/SelectComponent";
import {
  departmentMasterAction,
  getDepartmentByTeamAction,
  getDepartmentTeamByUsersAction,
  teamMappingAction,
} from "../redux/action/action";
import { updateProfileStates } from "../redux/reducers/profileReducer";
import {
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
} from "../utils/Style";
import { RESET_LABEL } from "../utils/textName";
import { allpageFontStyle } from "../utils/FontStyle";
import { PopupMessage } from "../utils/UIUtil";
import PopupComponent from "../components/commonComponents/PopupComponent";
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
export default function MangeTeam() {
  const { departmentTeamList, teamUserData } = useSelector(
    (state) => state.profile
  );
  const { departmentList } = useSelector((state) => state.master);
  const { globapOrganization } = useSelector((state) => state.presist);
  const autoCdep = useRef(null);
  const autoCteam = useRef(null);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [initialList, setInitialList] = useState([]);
  const [render, setRender] = useState(false);
  const [leftAll, setLeftAll] = useState(false);
  const [rightAll, setRightAll] = useState(false);
  const [value, setValue] = useState("");
  const [dep, setDep] = useState("");
  const [teamid, setTeamid] = useState("");
  const [teamOwner, setTeamOwner] = useState([]);
  const [teamLead, setTeamLead] = useState([]);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    handelClose();
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getEmployeeService());
    dispatch(
      departmentMasterAction(
        "",
        "get",
        `/department/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    setLeft([]);
    setRight([]);
    setDep("");
    setTeamid("");
  }, [globapOrganization]);

  useEffect(() => {
    return () => {
      handelClose();
    };
  }, []);
  useEffect(() => {
    if (teamUserData?.teamOwner) {
      setTeamOwner(teamUserData?.teamOwner);
      setTeamLead(teamUserData?.teamLead);
      if (teamUserData?.mappedUsers && teamUserData?.mappedUsers.length > 0) {
        const res = constructEditEmployee(teamUserData.mappedUsers);
        setRight(res);
        setInitialList(res);
      } else {
        setRight([]);
        setInitialList([]);
        // setTeamOwner('')
      }
    }
    if (
      teamUserData?.availableUsers &&
      teamUserData?.availableUsers.length > 0
    ) {
      setTeamOwner(teamUserData?.teamOwner);
      const res = constructGlobalEmployee(teamUserData.availableUsers);
      setLeft(res);
    } else {
      // setTeamOwner('')
    }
  }, [teamUserData]);
  const handleToggle = (value) => () => {
    console.log(value, "value");
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const constructEditEmployee = (arr) => {
    const result = arr.map((val) => ({
      empName: `${val?.firstname} ${val?.lastname}`,
      value: val,
      memberType: val?.memberType,
    }));
    return result.sort((a, b) =>
      a.empName.toLowerCase().localeCompare(b.empName)
    );
  };
  const constructGlobalEmployee = (arr) => {
    const result = arr.map((val) => ({
      memberType: "Member",
      empName: `${val.firstname} ${val.lastname}`,
      value: val,
    }));
    return result.sort((a, b) =>
      a.empName.toLowerCase().localeCompare(b.empName)
    );
  };
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCheckedLeft = () => {
    setRightAll(false);
    setLeftAll(false);
    setValue("");
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const leftSelectAll = (e) => {
    setLeftAll(e.target.checked);
    if (e.target.checked) {
      setChecked(left);
    } else {
      setChecked([]);
    }
  };
  const rightSelectAll = (e) => {
    setRightAll(e.target.checked);
    if (e.target.checked) {
      setChecked(right);
    } else {
      setChecked([]);
    }
  };
  const handleCheckedRight = () => {
    setRightAll(false);
    setLeftAll(false);
    setValue("");
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const customList = (title, items, side, teamOwner, teamLead) => (
    <Box sx={{ m: 3, borderStyle: "solid", borderWidth: 1 }}>
      <Card sx={{ minWidth: 300 }}>
        <CardHeader
          sx={{
            px: 2,
            py: 0.5,
            backgroundColor: side === "right" ? "#e0e0e0" : "#e0e0e0",
          }}
          // avatar={
          //   <Checkbox
          //     onClick={handleToggleAll(items)}
          //     checked={
          //       numberOfChecked(items) === items.length && items.length !== 0
          //     }
          //     indeterminate={
          //       numberOfChecked(items) !== items.length &&
          //       numberOfChecked(items) !== 0
          //     }
          //     disabled={items.length === 0}
          //     inputProps={{
          //       "aria-label": "all items selected",
          //     }}
          //   />
          // }
          titleTypographyProps={{
            fontSize: 15,
            display: "flex",
            justifyContent: side === "left" ? "start" : "space-between",
          }}
          action={
            side === "left" ? (
              <Box sx={{ pt: 0.6 }}>
                <TextField
                  sx={{ fontSize: 11, p: 0, mt: 0.5, width: 160 }}
                  id="search"
                  label="Search"
                  size="small"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  InputProps={{
                    style: { height: 30 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={formFieldStyle.InputLabelProps}
                  color="success"
                  autoComplete="off"
                  variant="outlined"
                />
              </Box>
            ) : (
              <Typography
                variant="caption"
                style={{ marginTop: "20px", paddingRight: "10px" }}
                display="block"
              ></Typography>
            )
          }
          title={title}
          // subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            // width: 200,
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'space-evenly',
            // alignItems: 'center',
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {side === "right" && teamOwner ? (
            <>
              {teamOwner &&
                teamOwner.length > 0 &&
                teamOwner.map((val) => (
                  <ListItem role="listitem">
                    <ListItemIcon>
                      <Checkbox
                        tabIndex={-1}
                        disableRipple
                        disabled
                        icon={<CheckCircleOutline />}
                        checkedIcon={<CheckCircle />}
                      />
                    </ListItemIcon>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{ width: "100%" }}
                        // id={labelId}
                        primary={`${val} (Team Manager)`}
                      />
                      {side === "right" && <Box sx={{ pl: 10 }}></Box>}
                    </Box>
                  </ListItem>
                ))}
            </>
          ) : null}
          {side === "right" && teamLead && teamLead.length > 0 ? (
            <>
              <ListItem role="listitem">
                <ListItemIcon>
                  <Checkbox
                    tabIndex={-1}
                    disableRipple
                    disabled
                    icon={<CheckCircleOutline />}
                    checkedIcon={<CheckCircle />}
                  />
                </ListItemIcon>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{ width: "100%" }}
                    // id={labelId}
                    primary={`${teamLead[0]} (Team Lead)`}
                  />
                  {side === "right" && <Box sx={{ pl: 10 }}></Box>}
                </Box>
              </ListItem>
            </>
          ) : null}

          {items
            .filter((item) => {
              if (!value) return true;
              if (item.empName.toLowerCase().includes(value)) {
                return true;
              }
            })
            .map((value, i) => {
              const labelId = `transfer-list-all-item-${i}-label`;

              return (
                <ListItem key={i} role="listitem">
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      onClick={handleToggle(value)}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      icon={<CheckCircleOutline />}
                      checkedIcon={<CheckCircle color="success" />}
                    />
                  </ListItemIcon>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ width: 150 }}
                      id={labelId}
                      primary={value.empName}
                    />
                    {side === "right" && (
                      <Box sx={{ pl: 10 }}>
                        {/* <Select
                          value={value.memberType}
                          defaultValue={value.memberType}
                          onChange={(e) => {
                            handelMember(i, e);
                          }}
                          sx={{
                            p: 0.5,
                            boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.20)",
                            fontFamily: "Lato",
                            flex: 1,

                            height: 30,
                            width: 130,
                          }}
                        >
                          <MenuItem
                            sx={{
                              borderBottom: "1px solid white",
                              fontFamily: "Lato",
                              fontSize: 13,
                            }}
                            value={"Member"}
                          >
                            Member
                          </MenuItem>
                          <MenuItem
                            sx={{
                              borderBottom: "1px solid white",
                              fontFamily: "Lato",
                              fontSize: 13,
                            }}
                            value={"Owner"}
                          >
                            Owner
                          </MenuItem>
                        </Select> */}
                      </Box>
                    )}
                  </Box>
                </ListItem>
              );
            })}
          <ListItem />
        </List>
      </Card>
    </Box>
  );
  const handelChangeTeam = (e) => {
    setTeamid(e);
    if (!e) {
      setTeamOwner("");
      setTeamLead("");
      setLeft([]);
      setRight([]);
      dispatch(
        updateProfileStates({
          name: "teamUserData",
          value: "",
        })
      );
    }
  };
  const handelChangeDepartment = (e) => {
    setDep(e);
    dispatch(getDepartmentByTeamAction(e?.departmentInfo?.depId));
    setTeamid("");
    dispatch(
      updateProfileStates({
        name: "teamUserData",
        value: "",
      })
    );
    if (!e) {
      setDep("");
      setTeamid("");
      setTeamOwner("");
      setTeamLead("");
      setLeft([]);
      setRight([]);
      dispatch(
        updateProfileStates({
          name: "teamUserData",
          value: "",
        })
      );
    }
  };
  const submitData = async () => {
    const payloadData = {
      teamId: teamid.teamId,
      orgId: globapOrganization.orgId,
      users: right.map((val) => ({
        userName: val.value.username,
        memberType: "Member",
      })),
    };
    await dispatch(teamMappingAction(payloadData, globapOrganization?.orgId));
    handelClose();
  };
  const handelClose = () => {
    setTeamid("");
    setDep("");
    if (autoCteam.current) autoCteam.current.value = null;
    if (autoCdep.current) autoCdep.current.value = null;
    setRight([]);
    setLeft([]);
    setTeamOwner("");
    setTeamLead("");
    dispatch(
      updateProfileStates({
        name: "teamUserData",
        value: "",
      })
    );
    dispatch(
      updateProfileStates({
        name: "departmentTeamList",
        value: [],
      })
    );
  };
  const handelLoad = async () => {
    await dispatch(
      getDepartmentTeamByUsersAction(
        dep?.departmentInfo?.depId,
        teamid?.teamId,
        globapOrganization?.orgId
      )
    );
  };
  return (
    <Box
      flxe={1}
      sx={{
        minHeight: 600,
        height: "100%",
        background: grey[100],
        // display:'flex', alignItems:'center',
        p: 3,
      }}
    >
      <Card sx={{ ...formContainerCardStyle, minWidth: 1000 }}>
        <Box
          sx={{ m: 2, pb: 2, display: "flex", justifyContent: "center", p: 3 }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", width: "70%" }}
          >
            <Grid item xs={12} md={4} lg={5} sx={{}}>
              {" "}
              {/* <Box sx={{backgroundColor:'green'}}> */}
              <SelectComponent
                DataOptions={departmentList}
                labelName={(option) => option.departmentInfo?.depName}
                value={dep}
                label={"Department"}
                handelChange={handelChangeDepartment}
              />
              {/* <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  ref={autoCdep}
                  options={departmentList}
                  getOptionLabel={(option) => option.departmentInfo?.depName}
                  onChange={(e, value) => {
                    handelChangeDepartment(value);
                  }}
                  value={dep || null}
                  // value={
                  //   departmentList &&
                  //   departmentList.length > 0 &&
                  //   departmentList.find((val) => val.depName === dep?.depName)
                  // }
                  // defaultValue={}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Department"
                    // placeholder="Select Department"
                    />
                  )}
                /> */}
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              {" "}
              <Box sx={{}}>
                <SelectComponent
                  DataOptions={departmentTeamList}
                  labelName={(option) => option.teamName}
                  value={teamid}
                  label={"Team"}
                  handelChange={handelChangeTeam}
                />
                {/* <Autocomplete
                  id="size-small-outlined"
                  ref={autoCteam}
                  size="small"
                  options={departmentTeamList}
                  getOptionLabel={(option) => option.teamName}
                  value={teamid || null}
                  // defaultValue={}
                  // value={
                  //   departmentTeamList &&
                  //   departmentTeamList.length > 0 &&
                  //   departmentTeamList.find(
                  //     (val) => val.teamName === teamid?.teamName
                  //   )
                  // }
                  onChange={(e, value) => {
                    handelChangeTeam(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Team"
                    // placeholder="Select Team"
                    />
                  )}
                /> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!dep?.departmentInfo?.depId || !teamid?.teamId}
                  sx={{
                    mt: 0,
                    borderRadius: 5,
                    ":hover": {
                      boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                    },
                  }}
                  onClick={() => {
                    handelLoad();
                  }}
                >
                  Load
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* {(teamUserData?.team_un_mapped_users &&
          teamUserData?.team_un_mapped_users.length > 0) ||
        (teamUserData?.team_mapped_users &&
          teamUserData?.team_mapped_users.length > 0 &&
          !dep?.depId &&
          !teamid?.teamId) ? (
          <> */}
        <Box>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            className="mt-1"
          >
            <Grid item xs={5.5} className="pt-0">
              {customList(
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    color="success"
                    size="small"
                    checked={leftAll}
                    onChange={(e) => leftSelectAll(e)}
                  />
                  User List
                </Box>,
                left,
                "left"
              )}
            </Grid>
            <Grid item xs={1}>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="contained"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="contained"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5.5} className="pt-0">
              {/* {teamOwner ?
                <Box sx={{ textAlign: 'center' }}>
                  <Typography><b>Team Manager:</b> {teamOwner}</Typography>
                </Box> : null} */}
              {customList(
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      color="success"
                      size="small"
                      checked={rightAll}
                      onChange={(e) => rightSelectAll(e)}
                    />
                    Selected User
                  </Box>
                  {/* {teamOwner ? <Typography><b>Team Owner:</b> {teamOwner}</Typography> : null} */}
                </Box>,
                right,
                "right",
                teamOwner,
                teamLead
              )}
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{
                color: "white",
                width: 90,
                m: { xs: 1, md: 1, lg: 2 },
                ...allpageFontStyle.buttonFontStyle2,
                backgroundColor: "#258d4d",
                borderRadius: 5,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
              }}
              disabled={initialList.length === 0 && right.length === 0}
              onClick={() => {
                submitData();
              }}
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                setAlertPopup((per) => ({
                  ...per,
                  open: true,
                  type: PopupMessage.resetType,
                  title: PopupMessage.reset,
                }));
              }}
              sx={{
                color: "white",
                width: 90,
                m: { xs: 1, md: 1, lg: 2 },
                ...allpageFontStyle.buttonFontStyle2,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
                borderRadius: 5,
              }}
            >
              {RESET_LABEL}
            </Button>
          </Box>
        </Box>
        {/* </>
        ) : null} */}
      </Card>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </Box>
  );
}
