import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CalendarTab from "./components/Calendar/calendar";
import OverlayLoader from "./components/commonComponents/Overlayloading";
import CustomizedSnackbars from "./components/commonComponents/snackBar";
import { getToken, onMessageListener } from "./firebase/firebase";
import GeneralHistory from "./components/generalTask/generalHistory";
import AnnouncementList from "./components/helpdesk/announcements/myAnnouncements";
import CreateAnnouncement from "./components/helpdesk/announcements/createAnnouncement";
import MiniDrawer from "./components/navBar/navBarDrawer";
import MangeTeam from "./mangeTeam/MangeTeam";
import RestrictedAccess from "./pages/404";
import Organization from "./pages/Organization/Organization";
import Team from "./pages/Team.js/Team";
import Branch from "./pages/branch/branch";
import Department from "./pages/department/department";
import Designation from "./pages/designation/designation";
import CreateGeneralTaskScreen from "./pages/generalTask/createTask/createGeneralTask";
import GeneralTaskListScreen from "./pages/generalTask/taskList";
import GeneralTaskMapScreen from "./pages/generalTask/taskMap";
import Grade from "./pages/grade/grade";
import Home from "./pages/home/Home";
import CreateJob from "./pages/job/createJob";
import ForgetPassword from "./pages/loginpage/Forgetpassword/ForgetPassword";
import Login from "./pages/loginpage/Login";
import ResetPassword from "./pages/loginpage/ResetPassword/ResetPassword";
import CreateHall from "./pages/meeting/createHall";
import ChangePassword from "./pages/passwordModal";
import ProfileShow from "./pages/profile/profile";
import CreatePro from "./pages/project/CreateProject";
import JoblistPage from "./pages/project/Joplist/JoblistPage";
import CreateMaster from "./pages/project/projectMaster/CreateProjectMaster";
import ProjectMasterList from "./pages/project/projectMaster/ProjectMasterList";
import ReportPage from "./pages/report/reportPage";
import RevertApprove from "./pages/revert/RevertApprove";
import TaskRevert from "./pages/revert/TaskRevert";
import RoleAndPermissionMapping from "./pages/roleandpermission/RoleAndPermissionMapping";
import RoleAndUserMapping from "./pages/rolemapping/RoleAndUserMapping";
import CreateRole from "./pages/roles/CreateRole";
import RoleList from "./pages/roles/RoleList";
import ShowTask from "./pages/showTask/taskpage";
import TaskHistory from "./pages/taskHistory/taskHistory";
import TaskMapping from "./pages/taskMapping/taskMapping";
import TaskReassignPage from "./pages/taskReassignPage/TaskReassignPage";
import TaskSplit from "./pages/taskSplit/TaskSplit";
import CreateUser from "./pages/user/CreateUser";
import Dashboard from "./pages/userProfile/userDashboard";
import {
  getAssignedTaskActionGet,
  getNotificationListAction,
  notificationAction,
} from "./redux/action/action";
import { updatePresistStates } from "./redux/reducers/PresistReducer";
import { updateMasterStates } from "./redux/reducers/masterReducer";
import store from "./redux/store";
import { getAuthToken, getAuthToken1 } from "./service/authService";

import { UpdateTask } from "./service/taskService";
import { allowedPages } from "./utils/permissions";
import SplitConfirmationPopUp from "./components/popup/splitConfirmation";
import { getTimeline } from "./service/masterService";
import CreateQuery from "./components/helpdesk/queries/createQuery";
import QueryList from "./components/helpdesk/queries/overallQueryList";
import UserGeneralTaskList from "./pages/generaltaskHistory/UserGeneralTaskList";
import SelfQueryList from "./components/helpdesk/queries/selfQuerList";
import Forum from "./components/helpdesk/queries/forum";
import SLAMaster from "./components/helpdesk/SLAMaster";
import OverallAnnouncements from "./components/helpdesk/announcements/allAnnouncements";
import SupportPage from "./components/helpdesk/support";
import { QueryColor, QueryColorBack } from "./utils/CommonColors";
import { getNotificationCountAction } from "./service/action/action";
import CompletedTaskList from "./pages/completedTask/CompletedTaskList";
export const hideRoleScreens = false;
function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [notification, setNotification] = useState({
    title: "",
    body: "",
    image: "",
  });
  const { authToken } = useSelector((state) => state.presist);
  const { organizationList } = useSelector((state) => state.master);
  const { globapOrganization, changePass, runingTask } = useSelector(
    (state) => state.presist
  );
  const { timerData, userprofile } = useSelector((state) => state.presist);
  const [jobCode1, setjobCode1] = useState("");
  const increment = useRef(null);
  const effectCalled = useRef(false);
  const handleStart = () => {
    // setIsActive(true);
    // setIsPaused(true);
    if (!increment.current) {
      console.log(timerData, "123start");
      increment.current = setInterval(() => {
        store.dispatch(
          updatePresistStates({
            name: "timerData",
            value: {
              timestate: store.getState().presist.timerData.timestate + 1,
            },
          })
        );
      }, 1000);
    }
  };
  useEffect(() => {
    if (authToken) {
      getNotificationCountAction();
    }
  }, [authToken]);
  const handlePause = () => {
    clearInterval(increment.current);
    increment.current = null;
    // setIsPaused(false);
  };

  let passedData;
  const handleResume = () => {
    // setIsPaused(true);
    if (!increment.current) {
      console.log(timerData, "123resume");
      increment.current = setInterval(() => {
        //   store.getState().presist.timerData.timestate,
        //   "App.js resume"
        // );
        store.dispatch(
          updatePresistStates({
            name: "timerData",
            value: {
              timestate: store.getState().presist.timerData.timestate + 1,
            },
          })
        );
      }, 1000);
    }
  };

  const handleReset = () => {
    clearInterval(increment.current);
    increment.current = null;
    // setIsActive(false);
    // setIsPaused(false);
    store.dispatch(
      updatePresistStates({
        name: "timerData",
        value: {
          timestate: 0,
        },
      })
    );
  };

  const [isTokenFound, setTokenFound] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState("");

  const notify = (title, body, data) => {
    console.log(data?.type, "typpee");
    // if(type === "ANNOUNCEMENT" && userprofile?.userInfo?.userInfo?.username === )
    if (data?.createdBy !== userprofile?.userInfo?.userInfo?.username) {
      toast.info(
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {data?.type === "ANNOUNCEMENT" || data?.type === "QUERY" ? (
              <Box
                sx={{
                  fontSize: 14,
                  mb: 0.5,
                  fontWeight: "bold",
                  color:
                    data?.type === "ANNOUNCEMENT" || data?.type === "QUERY"
                      ? data?.priority === "LOW"
                        ? QueryColor.low
                        : data?.priority === "HIGH"
                          ? QueryColor.high
                          : data?.criticality === "medium"
                            ? QueryColor.mediam
                            : data?.criticality === "low"
                              ? QueryColor.low
                              : data?.criticality === "high"
                                ? QueryColor.high
                                : "#3498db"
                      : "#3498db",
                }}
              >
                {data?.type}
              </Box>
            ) : (
              <Box sx={{ fontSize: 16, mb: 1 }}>{title}</Box>
            )}
            {data?.type === "ANNOUNCEMENT" || data?.type === "QUERY" ? (
              <>
                {/* <p className="tag" style={{ padding: 0, margin: '0', '.tag p': { padding: 0, margin: 0 } }} dangerouslySetInnerHTML={{ __html: body }} /> */}
                {data?.type === "ANNOUNCEMENT" ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{}}> </Box>
                    <Typography
                      sx={{
                        fontSize: 11,
                        color: "black",
                        display: "flex",
                        justifyContent: "left",
                        gap: 1,
                        pb: 1,
                      }}
                    >
                      {data?.projectCode && (
                        <Box
                          sx={{
                            flex: 0.5,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <b>PC:</b>
                          <span title={data?.projectCode}>
                            {data?.projectCode}
                          </span>
                        </Box>
                      )}
                      {data?.jobCode && (
                        <Box
                          sx={{
                            flex: 0.5,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <b>JC:</b>
                          <span title={data?.jobCode}>{data?.jobCode}</span>
                        </Box>
                      )}
                    </Typography>
                  </Box>
                ) : null}
                {data?.type === "ANNOUNCEMENT" ? (
                  <Box sx={{ fontSize: 12, mb: 1 }}>{title}</Box>
                ) : (
                  <Box
                    sx={{ fontSize: 12, mb: 1 }}
                  >{`Project: ${data.projectCode} - Job: ${data.jobCode}`}</Box>
                )}
              </>
            ) : (
              <Box sx={{ fontSize: 13 }}>{body}</Box>
            )}
          </Box>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          style: {
            backgroundColor:
              data?.type === "ANNOUNCEMENT" || data?.type === "QUERY"
                ? data?.priority === "LOW"
                  ? QueryColorBack.low
                  : data?.priority === "HIGH"
                    ? QueryColorBack.high
                    : data?.criticality === "medium"
                      ? QueryColorBack.mediam
                      : data?.criticality === "low"
                        ? QueryColorBack.low
                        : data?.criticality === "high"
                          ? QueryColorBack.high
                          : "#3498db"
                : "#3498db",
            border: `4px solid ${data?.type === "ANNOUNCEMENT" || data?.type === "QUERY"
              ? "white"
              : "#3498db"
              }`,
            color:
              data?.type === "ANNOUNCEMENT" || data?.type === "QUERY"
                ? data?.priority === "LOW"
                  ? QueryColorBack.colorlow
                  : data?.priority === "HIGH"
                    ? QueryColorBack.colorhigh
                    : data?.criticality === "medium"
                      ? QueryColorBack.colormediam
                      : data?.criticality === "low"
                        ? QueryColorBack.colorlow
                        : data?.criticality === "high"
                          ? QueryColorBack.colorhigh
                          : "white"
                : "white",
            borderLeftColor:
              data?.type === "ANNOUNCEMENT" || data?.type === "QUERY"
                ? data?.priority === "LOW"
                  ? QueryColor.low
                  : data?.priority === "HIGH"
                    ? QueryColor.high
                    : data?.criticality === "medium"
                      ? QueryColor.mediam
                      : data?.criticality === "low"
                        ? QueryColor.low
                        : data?.criticality === "high"
                          ? QueryColor.high
                          : "#3498db"
                : "#3498db",
          },
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
  const broadcast = new BroadcastChannel("background-listener");
  useEffect(() => {
    getToken(setTokenFound, setFirebaseToken);
    broadcast.onmessage = (event) => {
      console.log(event.data?.data?.["gcm.notification.type"], "payload123");
      if (
        event.data?.data?.["gcm.notification.type"] === "TASK_SPLIT_REQUEST"
      ) {
        return showCompleteTaskRequestPopup(event.data?.data);
      } else if (
        event.data?.data?.["gcm.notification.type"] ===
        "TASK_SPLIT_REQUEST_CONFIRMED"
      ) {
        return splitCompletedCountUpdated(event.data?.data);
      }
    };
  }, []);
  //  useEffect(()=>{
  //     dispatch(getNotification())
  //       },[firebaseToken])
  onMessageListener()
    .then((payload) => {
      console.log(payload, "payload123");
      if (payload?.data?.["gcm.notification.type"] === "TASK_SPLIT_REQUEST") {
        console.log(payload, "payload1234");
        return showCompleteTaskRequestPopup(payload?.data);
      } else if (
        payload?.data?.["gcm.notification.type"] ===
        "TASK_SPLIT_REQUEST_CONFIRMED"
      ) {
        return splitCompletedCountUpdated(payload?.data);
      }
      if (payload?.data?.type === "PAGE_UPDATED") {
        console.log(payload, "PAGE_UPDATED");
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: {
              isAutoLoad: true,
            },
          })
        );
      } else {
        setShow(true);
        notify(
          payload.notification.title,
          payload.notification.body,
          payload.data
        );
        dispatch(
          updateMasterStates({
            name: "push",
            value: 1,
          })
        );
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
          image: payload.notification.image,
        });
      }

      // if (payload.data !== undefined) {
      //   popupModalPayment
      // }
    })
    .catch();

  let close;

  const formatTimeData = () => {
    const getSeconds = `0${timerData?.timestate % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData?.timestate / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData?.timestate / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };
  const pauseTask = async () => {
    const temp = {
      jobCode: runingTask?.jobCode,
      remarks: "Page reloaded",
      state: "Paused",
      status: runingTask.status,
      taskId: runingTask.taskId,
      workHours: formatTimeData().H,
      workMinutes: formatTimeData().M,
      workSeconds: formatTimeData().S,
    };
    await UpdateTask(temp);
    dispatch(
      updatePresistStates({
        name: "runingTask",
        value: {},
      })
    );
    //  await dispatch(getAssignedTaskActionGet('')).then((response) => {
    //     if (response?.data?.data.assignedTaskList?.taskInfo) {
    //       const res = response?.data?.data.assignedTaskList?.taskInfo
    //       const filterthedata = res.find((val) => val.id === runingTask.id)
    //       // dispatch(
    //       //   updatePresistStates({
    //       //     name: "runingTask",
    //       //     value: filterthedata,
    //       //   })
    //       // );
    //     }
    //   })
  };
  const resumeTask = async (t) => {
    const temp = {
      jobCode: t?.jobCode || runingTask?.jobCode,
      remarks: "Task resumed after reload",
      state: "Resumed",
      status: t?.status || runingTask.status,
      taskId: t?.taskId || runingTask.taskId,
      workHours: formatTimeData().H,
      workMinutes: formatTimeData().M,
      workSeconds: formatTimeData().S,
    };
    await UpdateTask(temp);
    await dispatch(getAssignedTaskActionGet("")).then((response) => {
      if (response?.data?.data.assignedTaskList?.taskInfo) {
        const res = response?.data?.data.assignedTaskList?.taskInfo;
        const filterthedata = res.find(
          (val) => val.id === (t?.id || runingTask.id)
        );
        dispatch(
          updatePresistStates({
            name: "runingTask",
            value: filterthedata,
          })
        );
      }
    });
  };
  let isPageBeingRefreshed;
  useEffect(() => {
    if (runingTask?.state) {
      if (
        (runingTask.state === "Started" || runingTask.state === "Resumed") &&
        !effectCalled.current &&
        !increment.current
      ) {
        dispatch(getAssignedTaskActionGet());
        handleStart();
        effectCalled.current = true;
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener("unload", function (event) {
      // If the page is being refreshed, the "beforeunload" event will be triggered first,
      // and we set the flag to true to indicate that the page is being refreshed.
      isPageBeingRefreshed = true;
    });
  }, []);

  useEffect(() => {
    if (
      firebaseToken &&
      userprofile?.userInfo?.userInfo?.username &&
      authToken &&
      getAuthToken()
    )
      dispatch(
        notificationAction({
          username: userprofile?.userInfo?.userInfo?.username,
          deviceToken: firebaseToken,
        })
      );
  }, [userprofile, firebaseToken]);
  const saveData = (runingTask) => {
    //saving username to session storage
    sessionStorage.setItem("task", JSON.stringify(runingTask));
  };
  const getSessionData = () => {
    //fetching username from sesstion storage
    return sessionStorage.getItem("task");
  };
  const clearSessionData = () => {
    //Removing username from sesstion storage
    sessionStorage.removeItem("task");
  };
  const closeSplitComplete = () => {
    dispatch(
      updatePresistStates({
        name: "splitUpdate",
        value: null,
      })
    );
  };
  const showCompleteTaskRequestPopup = (data) => {
    const { splitUpdate } = store.getState().presist;
    console.log(data, "ghcghcvhg");
    if (data?.taskId && data?.joCode && !splitUpdate) {
      getTimeline({
        jobCode: data.joCode,
        taskId: data.taskId,
      }).then((res) => {
        if (res?.data) {
          dispatch(
            updatePresistStates({
              name: "splitUpdate",
              value: {
                ...res?.data?.TaskInfo[0],
                projectCode: data?.projectCode,
                type: data?.type,
                requestedBy: data?.requestedBy,
                requestTo: data?.requestTo,
              },
            })
          );
        }
      });
    }
  };
  const splitCompletedCountUpdated = (data) => {
    if (data?.taskId && data?.joCode) {
      dispatch(
        updatePresistStates({
          name: "splitCompletedCountUpdated",
          value: data,
        })
      );
    }
  };
  useEffect(() => {
    const t = JSON.parse(getSessionData());
    if (t) {
      resumeTask(t);
      clearSessionData();
    }
  }, []);
  // useEffect(() => {
  //   dispatch(getNotificationListAction(10, 1, '', ''))
  // }, [])

  return (
    <Beforeunload
      onBeforeunload={(event) => {
        close =
          runingTask?.state === "Started" || runingTask?.state === "Resumed";
        if (close) {
          saveData(runingTask);
          pauseTask();
          setTimeout(() => setTimeout(() => resumeTask(), 1000), 1000);
          event.preventDefault();
        }
      }}
    >
      <Box
        sx={{
          // background: 'red',
          height: "100vh",
          overflowY: "hidden",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          // pb:10
        }}
      >
        <Router>
          {/* <Navbar /> */}

          {getAuthToken() && authToken && !changePass ? (
            <MiniDrawer
              increment={increment}
              handleStart={handleStart}
              handlePause={handlePause}
              handleReset={handleReset}
              handleResume={handleResume}
              jobCode1={jobCode1}
            >
              <SplitConfirmationPopUp onClose={closeSplitComplete} />
              <Routes>
                {allowedPages().support.announcements.create && (
                  <Route
                    path="/createAnnouncement"
                    element={<CreateAnnouncement />}
                  />
                )}
                {allowedPages().support.queries.create && (
                  <Route path="/createQuery" element={<CreateQuery />} />
                )}
                <Route path="/forum/:id" element={<Forum />} />
                {/* <Route path="/editQuery" element={<CreateQuery />} />
                <Route path="/announcementList" element={<AnnouncementList />} />
                <Route path="/allAnnouncements" element={<OverallAnnouncements />} />
                <Route path="/queryList" element={<QueryList />} />
                <Route path="/myQueryList" element={<SelfQueryList />} />*/}
                <Route path="/createSLA" element={<SLAMaster />} />
                {allowedPages().support.showMenu() && (
                  <Route path="/helpdesk" element={<SupportPage />} />
                )}

                {allowedPages().adminDashboard?.showMenu() ? (
                  <Route path="/" element={<Home />} />
                ) : null}
                {allowedPages().settings?.organization?.showPage() ? (
                  <Route path="/org" element={<Organization />} />
                ) : null}
                {allowedPages().TeamManagement?.teamList?.showPage() ? (
                  <Route path="/team" element={<Team />} />
                ) : null}
                {allowedPages().TeamManagement?.creatTeam?.showPage() ||
                  allowedPages().TeamManagement?.teamList?.edit ? (
                  <Route path="/createTeam" element={<Team />} />
                ) : null}
                {allowedPages().TeamManagement?.teamAllocation?.showPage() ? (
                  <Route path="/team/mapping" element={<MangeTeam />} />
                ) : null}
                {allowedPages().settings?.grade?.showPage() ? (
                  <Route path="/grade" element={<Grade />} />
                ) : null}
                {allowedPages().settings?.branch?.showPage() ? (
                  <Route path="/branch" element={<Branch />} />
                ) : null}
                {allowedPages().settings?.department?.showPage() ? (
                  <Route path="/department" element={<Department />} />
                ) : null}
                {allowedPages().settings?.designation?.showPage() ? (
                  <Route path="/designation" element={<Designation />} />
                ) : null}
                {allowedPages().settings?.unitType?.showPage() ? (
                  <Route
                    path="/projectMasterList"
                    element={<ProjectMasterList />}
                  />
                ) : null}
                {allowedPages().ProjectManagement?.ProjectList?.showPage() ? (
                  <Route path="/project" element={<CreatePro />} />
                ) : null}

                {allowedPages().settings.unitType?.showPage() ? (
                  <Route path="/CreateMaster" element={<CreateMaster />} />
                ) : null}
                {allowedPages().ProjectManagement.createProject?.showPage() ||
                  allowedPages().ProjectManagement.ProjectList.edit ||
                  allowedPages().ProjectManagement.ProjectList.view ? (
                  <Route path="/createProject" element={<CreatePro />} />
                ) : null}
                {/* {allowedPages().ProjectManagement.jobList?.showPage() ? <Route path="/project_joblist" element={<JoblistPage />} /> : null} */}
                {allowedPages().UserManagement.userList?.showPage() ? (
                  <Route path="/user" element={<CreateUser />} />
                ) : null}
                {allowedPages().UserManagement.createUser?.showPage() ||
                  allowedPages().UserManagement.userList.edit ? (
                  <Route path="/createUser" element={<CreateUser />} />
                ) : null}
                {allowedPages().Jobs.createJob?.showPage() ? (
                  <Route path="/createjob" element={<CreateJob />} />
                ) : null}
                {allowedPages().Jobs.jobList?.showPage() ? (
                  <Route path="/jobList" element={<CreateJob />} />
                ) : null}
                {/* {allowedPages().Jobs.taskList?.showPage() ? <Route path="/taskList" element={<ReportPage />} /> : null} */}
                {allowedPages().WorkStation.taskRevert?.showPage() ? (
                  <Route path="/taskRevert" element={<TaskRevert />} />
                ) : null}
                {allowedPages().userDashboard.showMenu() ? (
                  <Route
                    path="/dashboard"
                    element={
                      <Dashboard
                        handleStart={handleStart}
                        handleResume={handleResume}
                      />
                    }
                  />
                ) : null}
                {allowedPages().WorkStation.taskAssignment?.showPage() ? (
                  <Route path="/taskMapping" element={<TaskMapping />} />
                ) : null}
                {allowedPages().WorkStation.taskReassignment?.showPage() ? (
                  <Route path="/taskreassign" element={<TaskReassignPage />} />
                ) : null}

                {/* general Task */}
                <Route path="generalTask">
                  {allowedPages().Generaltask?.createGeneralTask?.showPage() ? (
                    <Route
                      path="create"
                      element={<CreateGeneralTaskScreen />}
                    />
                  ) : null}{" "}
                  {/*A nested route!*/}
                  {allowedPages().Generaltask?.GeneralTaskList?.showPage() ? (
                    <Route path="edit" element={<CreateGeneralTaskScreen />} />
                  ) : null}
                  {/*A nested route!*/}
                  {allowedPages().Generaltask?.GeneralTaskList?.showPage() ? (
                    <Route path="list" element={<GeneralTaskListScreen />} />
                  ) : null}{" "}
                  {/*A nested route!*/}
                  {allowedPages().Generaltask?.generalTaskAllocation?.showPage() ? (
                    <Route path="map" element={<GeneralTaskMapScreen />} />
                  ) : null}{" "}
                  {/*A nested route!*/}
                  {/* {allowedPages().WorkStation.generalTask ? <Route path='user' element={<UserGeneralTaskScreen />} /> : null}  */}
                </Route>
                {/* general Task */}

                {/* <Route path="/taskList" element={<TaskList />} /> */}
                {allowedPages().report.taskReport?.showPage() ? (
                  <Route path="/taskReport" element={<ReportPage />} />
                ) : null}
                {allowedPages().report.taskStatusReport?.showPage() ? (
                  <Route path="/taskStatusReport" element={<ReportPage />} />
                ) : null}
                {allowedPages().report.projectReport?.showPage() ? (
                  <Route path="/project_report" element={<ReportPage />} />
                ) : null}
                {allowedPages().report.jobReport?.showPage() ? (
                  <Route path="/job_report" element={<ReportPage />} />
                ) : null}
                {/* <Route path="/taskReport" element={<TaskReport />} /> */}
                {/* <Route path="/report" element={<ReportPage />} /> */}
                {/* <Route path="/dashboard/:id" element={<TaskShow increment={increment}
                  handleStart={handleStart}
                  handlePause={handlePause}
                  handleReset={handleReset}
                  handleResume={handleResume} />} /> */}
                {allowedPages().WorkStation?.tasks?.showPage() ? (
                  <Route
                    path="/tasks"
                    element={
                      <ShowTask
                        increment={increment}
                        handleStart={handleStart}
                        handlePause={handlePause}
                        handleReset={handleReset}
                        handleResume={handleResume}
                        jobCode1={jobCode1}
                        setjobCode1={setjobCode1}
                      />
                    }
                  />
                ) : null}
                {allowedPages().WorkStation?.tasks?.showPage() ? (
                  <Route
                    path="/completedTask"
                    element={
                      <CompletedTaskList />
                    }
                  />
                ) : null}
                {allowedPages().role?.creatRole?.showPage() &&
                  hideRoleScreens ? (
                  <Route path="/CreateRole" element={<CreateRole />} />
                ) : null}
                {allowedPages().role?.roleList?.showPage() &&
                  hideRoleScreens ? (
                  <Route path="/RoleList" element={<RoleList />} />
                ) : null}
                {allowedPages().role?.roleList?.showPage() &&
                  hideRoleScreens ? (
                  <Route path="/EditRole" element={<CreateRole />} />
                ) : null}
                {allowedPages().role?.roleAllocation?.showPage() &&
                  hideRoleScreens ? (
                  <Route
                    path="/Role/userMapping"
                    element={<RoleAndUserMapping />}
                  />
                ) : null}
                {allowedPages().role?.rolePermission?.showPage() ? (
                  <Route
                    path="/Role/Permission"
                    element={<RoleAndPermissionMapping />}
                  />
                ) : null}

                {allowedPages().WorkStation?.taskSplit?.showPage() ? (
                  <Route path="/tasks/split" element={<TaskSplit />} />
                ) : null}
                {allowedPages().WorkStation?.revertedTask?.showPage() ? (
                  <Route path="/tasks/revert" element={<RevertApprove />} />
                ) : null}
                {allowedPages().WorkStation?.tasks?.showPage() ? (
                  <Route
                    path="/generalTask/history"
                    element={<UserGeneralTaskList />}
                  />
                ) : null}
                <Route
                  path="/taskHistory/:name"
                  element={
                    <TaskHistory
                      increment={increment}
                      handleStart={handleStart}
                      handlePause={handlePause}
                      handleReset={handleReset}
                      handleResume={handleResume}
                    />
                  }
                />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/profile" element={<ProfileShow />} />
                {allowedPages().meeting?.schedule ? (
                  <Route path="/request" element={<CalendarTab />} />
                ) : null}
                {/* <Route path="/hallRequest" element={<HallRequest />} /> */}
                {allowedPages().meeting?.createHall ? (
                  <Route path="/createHall" element={<CreateHall />} />
                ) : null}
                {/* <Route path="/splitTask" element={<SplitTask />} /> */}

                {/* <Route path="/update-task" element={<UpdateTask />} />
            <Route path="/task" element={<TaskPage />} />
            <Route path="/assgin/task" element={<AssginTask />} /> */}
                {/* <Route path="*" element={<Navigate to={"/"} replace />} /> */}
                {/* <Route path="*" element={<RestrictedAccess />} /> */}
                <Route
                  path="/generalTaskHistory"
                  element={<GeneralHistory />}
                />
              </Routes>
            </MiniDrawer>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/ForgetPassword" element={<ForgetPassword />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              {/* <Route path="*" element={<Navigate to={"/"} replace />} /> */}
            </Routes>
          )}
          <CustomizedSnackbars />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            transition={Flip}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Router>

        {/* Same as */}

        <OverlayLoader />
      </Box>
    </Beforeunload>
  );
}

export default App;
