import StopIcon from "@mui/icons-material/Stop";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Button, Card, Chip, Grid, Tooltip, Typography, colors } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { createTimeModel, useTimeModel } from "react-compound-timer";
import { useSelector } from "react-redux";
import gif from "../../pics/clock.gif";
import arrow from "../../pics/fast-forward.gif";
import store from "../../redux/store";
import { endGeneralTaskServiceAction, getUsersRunningGeneralTaskAction, onLogoutAction, startGeneralTaskServiceAction, updateGeneralTaskServiceAction } from "../../service/action/action";
import { conformPopup } from "../popup/ConformPopup";
import { useNavigate } from "react-router-dom";
import { allpageFontStyle } from "../../utils/FontStyle";


let initialState = { initialTime: 0, startImmediately: false }
const stopwatch = createTimeModel(initialState);
const cardbuttons = {
    fontSize: 11,
    color: "white",
    height: 30,
    width: 80,
    borderRadius: 5,
    ml: 2,
};
const GeneralTaskTimer = ({ open, drawerWidth, isCardStyle, setSelectedTask }) => {
    const { value, reset, changeTime, start, stop } = useTimeModel(stopwatch);
    const { runningGeneralTask, endGeneralTask } = useSelector(state => state.common);
    const navigate = useNavigate();
    // convert to seconds
    function toSeconds(hours, minutes, seconds) {
        return hours * 3600 + minutes * 60 + seconds;
    }
    // start the timer
    const onStartTimer = (seconds) => {
        // convert seconds to milliseconds 1(s) * 1000
        changeTime(seconds * 1000);
        start();
    }
    // on end
    const onEnd = (endGeneralTask) => {
        endGeneralTaskServiceAction(runningGeneralTask, value.h, value.m, value.s, endGeneralTask?.upComingTask).then(res => {
            setSelectedTask && setSelectedTask(null)
            if (res && endGeneralTask?.upComingTask) startGeneralTaskServiceAction(endGeneralTask?.upComingTask);
            if (res && endGeneralTask?.logout) onLogoutAction(navigate);
        });
    }
    // get running general task details
    const getUpdateTime = () => {
        console.log(value.h, value.m, value.s, 'value.h, value.m, value.s')
    }
    useEffect(() => {
        getUsersRunningGeneralTaskAction();
        return () => {
            const { runningGeneralTask } = store.getState().common;
            // getUpdateTime();
            runningGeneralTask && updateGeneralTaskServiceAction(runningGeneralTask, stopwatch.value.h, stopwatch.value.m, stopwatch.value.s, true)
        }
    }, [])
    // if running general task exist then start the timer
    useEffect(() => {
        if (runningGeneralTask) {
            const convertedToSeconds = toSeconds(+runningGeneralTask?.workHours, +runningGeneralTask?.workMinutes, +runningGeneralTask?.workSeconds);
            onStartTimer(convertedToSeconds)
        } else {
            reset();
            stop();
        }
    }, [runningGeneralTask])
    // update general task running time for every 1 min
    useEffect(() => {
        if (+value.s === 59 && +value.s !== 0 && runningGeneralTask) {
            console.log(value, 'value.h, value.m, value.s')
            updateGeneralTaskServiceAction(runningGeneralTask, value.h, value.m, value.s, true)
        }
    }, [stopwatch.value.s])
    // after end general task state updates when start and resume the main task in TimerMain
    useEffect(() => {
        if (runningGeneralTask && endGeneralTask) onEnd(endGeneralTask);
    }, [endGeneralTask])
    return (
        <>
            {runningGeneralTask ?
                isCardStyle ?
                    <Grid item xs={6} md={6} lg={4} sx={{ p: 1 }}>
                        <Box>
                            <Card
                                sx={{
                                    // mx:2,
                                    height: 280,
                                    minHeight: 280,
                                    borderRadius: 2,
                                    // p: 1,
                                    opacity: 1,
                                    backgroundColor: green[50],
                                    width: "100%",
                                    border: "2px solid green",
                                    boxShadow: "none",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: "relative",
                                    ":hover": {
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: 30,
                                        minHeight: 30,
                                        backgroundColor: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        // pl: 1,
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography textAlign={'center'} sx={{ color: '#fff', ...allpageFontStyle.title }} mt={1} variant="subtitle1" >General Task</Typography>
                                </Box>
                                <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography ><b style={{ ...allpageFontStyle.title }}>Task Name :</b>  {runningGeneralTask?.genTaskName}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: 30,
                                        minHeight: 30,
                                        backgroundColor: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: "center",
                                            color: "white",
                                        }}
                                    >
                                        <img
                                            src={gif}
                                            alt=""
                                            style={{ height: 30, width: 30 }}
                                        />
                                        <Typography sx={{
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            backgroundColor: colors.green[400],
                                            borderRadius: 5,
                                            px: 1
                                        }}>{value.h >= 10 ? '' : 0}{value.h}: {value.m >= 10 ? '' : 0}{value.m} : {value.s >= 10 ? '' : 0}{value.s}</Typography>
                                    </Box>
                                    <Tooltip title="End" placement="bottom">
                                        <Button
                                            onClick={() => {
                                                conformPopup('Confirm', `Do you want to end ${runningGeneralTask?.genTaskName}  task?`, () => onEnd())
                                            }}
                                            sx={{
                                                ml: 1,
                                                // backgroundColor: colors.red[300],
                                                px: 0,
                                                py: 0,
                                                height: 30,
                                                minHeight: 30,
                                                minWidth: 40,
                                                width: 40,
                                                borderRadius: "50%",
                                                // borderStyle: "solid",
                                                // borderWidth: 0.5,
                                                // borderColor: "#656965",
                                                display: "flex",
                                                // ":hover": {
                                                // backgroundColor: colors.red[400],
                                                // },
                                            }}
                                            variant="filled"
                                        // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StopIcon sx={{}} /><Box >END</Box></Box>}
                                        >
                                            <StopIcon
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    height: 30,
                                                    width: 30,
                                                    color: colors.red[300],
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    :
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            left: open ? drawerWidth : 65,
                            zIndex: 100,
                            width: "100%",
                            background: 'white',
                            p: 1.5,
                        }}
                    >

                        <Box
                            sx={{
                                width: open ? '70%' : "90%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                                <Box sx={{ fontWeight: "bold", fontSize: 14, pr: 2 }}>Task Inprogress:</Box>
                            </Box>

                            <Chip
                                variant="outlined"
                                label={
                                    <Box sx={{ fontWeight: "bold", fontSize: 15, color: "#801780" }}>
                                        #General Task
                                    </Box>
                                }
                                sx={{ mr: 2 }}
                            />
                            <img alt="" src={arrow} style={{ height: 30, width: 30 }} />
                            <Chip
                                sx={{ mx: 2, maxWidth: '60%', }}
                                variant="outlined"
                                label={
                                    <Tooltip title={runningGeneralTask?.genTaskName}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                color: "black",
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <WorkIcon sx={{ color: "rosybrown", height: 20, width: 20 }} />
                                            {runningGeneralTask?.genTaskName}
                                        </Box>
                                    </Tooltip>

                                }
                            />
                            <img alt="" src={arrow} style={{ height: 30, width: 30 }} />

                            <Box
                                sx={{
                                    fontSize: 17,
                                    p: 0.5,
                                    // pl: 3,
                                    // pr: 3,
                                    minWidth: 150,
                                    ml: 2,

                                    fontWeight: "bold",
                                    borderStyle: "solid",
                                    backgroundColor: colors.green[400],
                                    color: "white",
                                    borderWidth: 1,
                                    display: "flex",
                                    borderRadius: 5,
                                }}
                            >
                                <Box
                                    sx={{ display: "flex", alignItems: "center", fontSize: 13, p: 0 }}
                                >
                                    <img
                                        alt=""
                                        src={gif}
                                        style={{ height: 30, width: 30, paddingRight: 2 }}
                                    />{" "}
                                    {value.h >= 10 ? '' : 0}{value.h}: {value.m >= 10 ? '' : 0}{value.m} : {value.s >= 10 ? '' : 0}{value.s}
                                </Box>
                            </Box>
                            {/* <Box title={'Preview'}>
                            <EyeIcon taskId={runingTask.taskId} jobcode={runingTask.jobCode} clickOpen={true} clickclose={false} />
                        </Box> */}
                            <Tooltip title="End" placement="bottom">
                                <Button
                                    onClick={() => {
                                        conformPopup('Confirm', `Do you want to end ${runningGeneralTask?.genTaskName}  task?`, () => onEnd())
                                    }}
                                    sx={{
                                        ml: 1,
                                        // backgroundColor: colors.red[300],
                                        px: 0,
                                        py: 0,
                                        height: 30,
                                        minHeight: 30,
                                        minWidth: 40,
                                        // width: 40,
                                        borderRadius: "50%",
                                        // borderStyle: "solid",
                                        // borderWidth: 0.5,
                                        // borderColor: "#656965",
                                        display: "flex",
                                        // ":hover": {
                                        // backgroundColor: colors.red[400],
                                        // },
                                    }}
                                    variant="filled"
                                // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StopIcon sx={{}} /><Box >END</Box></Box>}
                                >
                                    <StopIcon
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            height: 30,
                                            width: 30,
                                            color: colors.red[300],
                                        }}
                                    />End Task
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>

                : null}
        </>
    )
}

export default GeneralTaskTimer;