import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
// import { formatTime } from "../../utils/CommenData";
// import useTimer from "./TimerCustom";
import { Box, colors } from "@mui/material";
import {
  UpdateTaskAction,
  getAssignedTaskActionGet,
  getUserTaskAction2,
} from "../../redux/action/action";
import store from "../../redux/store";
import { checkAndEndRunningGeneralTaskAction } from "../../service/action/action";
import "./timer.css";

const Timer = ({
  initialState,
  selectedStart,
  selectedResume,
  selectedReset,
  selectedPause,
  setselectedStart,
  setselectedResume,
  setselectedReset,
  setselectedPause,
  taskValue,
  indexValue,
  remarks,
  selectedtask,
  increment,
  handleStart,
  setPageInfo,
  setAssigedTaskList,
  handlePause,
  handleReset,
  handleResume,
  pageNumber,
  refreshFunc,
  jobCode1,
}) => {
  const { timerData } = useSelector((state) => state.presist);
  // const [isActive, setIsActive] = useState(false);
  // const [isPaused, setIsPaused] = useState(false);
  // const effectCalled = useRef(false);
  // const increment = useRef(null);
  const dispatch = useDispatch();

  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  // const handleStart = () => {
  //   setIsActive(true);
  //   setIsPaused(true);
  //   increment.current = setInterval(() => {
  //     setTimer((timer) => {
  //       return timer + 1;
  //     });
  //   }, 1000);
  // };
  // const payloadData = (taskValue) => {
  //   const res = {
  //     taskId: taskValue?.taskId,
  //     jobCode: taskValue?.jobCode,
  //     status: taskValue?.status,
  //     state: taskValue?.state,
  //     remarks: taskValue?.remarks,
  //     workHours: formatTimeData().H,
  //     workMinutes: formatTimeData().M,
  //     workSeconds: formatTimeData().S,
  //   };
  //   return res;
  // };
  // const handlePause = () => {
  //   clearInterval(increment.current);
  //   setIsPaused(false);
  // };

  // const handleResume = () => {
  //   setIsPaused(true);
  //   increment.current = setInterval(() => {
  //     setTimer((timer) => timer + 1);
  //   }, 1000);
  // };

  // const handleReset = () => {
  //   clearInterval(increment.current);
  //   setIsActive(false);
  //   setIsPaused(false);
  //   setTimer(0);
  // };

  // Interval option for the task //

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(UpdateTaskAction(payloadData(taskValue)));
  //   }, 1800000);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   if (selectedStart === indexValue) {

  //   }
  //   if (selectedResume === indexValue) {

  //   }
  //   if (selectedReset === indexValue) {
  //   }
  //   if (selectedPause === indexValue) {

  //   }
  // }, [selectedStart, selectedResume, selectedReset, selectedPause]);
  // useEffect(() => {
  //   if (runingTask?.state) {
  //

  //     if (
  //       (runingTask.state === "Started" || runingTask.state === "Resumed") &&
  //       !effectCalled.current &&
  //       !increment.current
  //     ) {
  //       dispatch(getAssignedTaskAction());
  //       handleStart();

  //       effectCalled.current = true;
  //     }
  //   }
  // }, []);
  useEffect(() => {
    const start = async () => {
      if (selectedStart === indexValue) {
        await checkAndEndRunningGeneralTaskAction();

        const res = {
          taskId: taskValue?.taskId,
          jobCode: taskValue?.jobCode,
          status: " In-Progress",
          state: "Started",
          remarks: "Task started",
          workHours: "00",
          workMinutes: "00",
          workSeconds: "00",
        };
        const pay = await dispatch(UpdateTaskAction(res));
        if (pay) {
          // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }))
          if (jobCode1) {
            setAssigedTaskList([]);
            dispatch(
              getUserTaskAction2(
                jobCode1,
                taskValue.id,
                "",
                pageNumber,
                setPageInfo
              )
            );
          } else {
            setAssigedTaskList([]);
            dispatch(getAssignedTaskActionGet("", pageNumber)).then(
              (response) => {
                if (response?.data?.data.assignedTaskList?.taskInfo) {
                  const res = response?.data?.data.assignedTaskList?.taskInfo;
                  const filterthedata = res.find(
                    (val) => val.id === taskValue.id
                  );
                  dispatch(
                    updatePresistStates({
                      name: "runingTask",
                      value: filterthedata,
                    })
                  );
                }
              }
            );
          }

          // dispatch(
          //   updatePresistStates({
          //     name: "runingTask",
          //     value: taskValue,
          //   })
          // );
          dispatch(
            updatePresistStates({
              name: "timerData",
              value: {
                timestate: 0,
              },
            })
          );
          handleStart();

          setselectedPause("");
          setselectedStart("");
        }
        // dispatch(getAssignedTaskAction());

        // setselectedStart("");
      }
    };
    start();
  }, [selectedStart]);
  useEffect(() => {
    const resume = async () => {
      if (selectedResume === indexValue) {
        await checkAndEndRunningGeneralTaskAction();
        dispatch(
          updatePresistStates({
            name: "timerData",

            value: {
              timestate: toSeconds(
                Number(taskValue.workHours),
                Number(taskValue.workMinutes),
                Number(taskValue.workSeconds)
              ),
            },
          })
        );
        const res = {
          taskId: taskValue?.taskId,
          jobCode: taskValue?.jobCode,
          status: "In-Progress",
          state: "Resumed",
          remarks: remarks ? remarks : taskValue?.remarks,
          workHours: formatTimeData().H,
          workMinutes: formatTimeData().M,
          workSeconds: formatTimeData().S,
        };
        const pay = await dispatch(UpdateTaskAction(res));
        if (pay) {
          // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }))
          if (jobCode1) {
            setAssigedTaskList([]);
            dispatch(
              getUserTaskAction2(
                jobCode1,
                taskValue.id,
                "",
                pageNumber,
                setPageInfo
              )
            );
          } else {
            setAssigedTaskList([]);
            dispatch(getAssignedTaskActionGet("", pageNumber)).then(
              (response) => {
                if (response?.data?.data.assignedTaskList?.taskInfo) {
                  const res = response?.data?.data.assignedTaskList?.taskInfo;
                  const filterthedata = res.find(
                    (val) => val.id === taskValue.id
                  );
                  dispatch(
                    updatePresistStates({
                      name: "runingTask",
                      value: filterthedata,
                    })
                  );
                }
              }
            );
          }
          handleResume();
          dispatch(
            updatePresistStates({
              name: "runingTask",
              value: taskValue,
            })
          );
          setselectedPause("");
          setselectedResume("");
        }
      }
    };
    resume();
  }, [selectedResume]);
  useEffect(() => {
    const reset1 = async () => {
      if (selectedReset === indexValue) {
        const res = {
          taskId: taskValue?.taskId,
          jobCode: taskValue?.jobCode,
          status: "Resolved",
          state: "End",
          remarks: remarks ? remarks : taskValue?.remarks,
          workHours: formatTimeData().H,
          workMinutes: formatTimeData().M,
          workSeconds: formatTimeData().S,
        };
        const pay = await dispatch(UpdateTaskAction(res));
        if (pay) {
          // dispatch(getAssignedTaskAction('post', { jobCode: taskValue?.jobCode }));

          dispatch(
            updatePresistStates({
              name: "runingTask",
              value: {},
            })
          );
          if (jobCode1) {
            setAssigedTaskList([]);
            dispatch(
              getUserTaskAction2(
                jobCode1,
                taskValue.id,
                "",
                pageNumber,
                setPageInfo
              )
            );
          } else {
            setAssigedTaskList([]);
            dispatch(getAssignedTaskActionGet("", pageNumber));
          }
          handleReset();
          setselectedReset("");
        }
      }
    };
    reset1();
  }, [selectedReset]);
  useEffect(() => {
    const pause = async () => {
      if (
        selectedPause === indexValue ||
        (typeof selectedPause === "object" &&
          taskValue?.taskId === selectedPause?.taskId)
      ) {
        dispatch(
          updatePresistStates({
            name: "timerData",
            value: {
              timestate: toSeconds(
                Number(taskValue.workHours),
                Number(taskValue.workMinutes),
                Number(taskValue.workSeconds)
              ),
            },
          })
        );
        const res = {
          taskId: taskValue?.taskId,
          jobCode: taskValue?.jobCode,
          status: " In-Progress",
          state: "Paused",
          remarks: remarks ? remarks : taskValue?.remarks,
          workHours: formatTimeDataPause().H,
          workMinutes: formatTimeDataPause().M,
          workSeconds: formatTimeDataPause().S,
        };
        await dispatch(UpdateTaskAction(res));
        // startGeneralTaskServiceAction(selectedtask)
        if (jobCode1) {
          setAssigedTaskList([]);
          dispatch(
            getUserTaskAction2(
              jobCode1,
              taskValue.id,
              "",
              pageNumber,
              setPageInfo
            )
          );
        } else {
          setAssigedTaskList([]);
          dispatch(getAssignedTaskActionGet("", pageNumber)).then(
            (response) => {
              if (response?.data?.data?.assignedTaskList?.taskInfo) {
                const res = response?.data?.data?.assignedTaskList?.taskInfo;
                const filterthedata = res.find(
                  (val) => val?.id === taskValue?.id
                );
                dispatch(
                  updatePresistStates({
                    name: "runingTask",
                    value: filterthedata,
                  })
                );
              }
            }
          );
        }
        handlePause();
        setselectedPause("");
      }
    };
    pause();
  }, [selectedPause]);

  const formatTimeData = () => {
    const timerData = store.getState().presist?.timerData?.timestate;
    const getSeconds = `0${timerData % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };
  const formatTimeDataPause = () => {
    // const timerData = store.getState().presist.timerData.timestate
    const getSeconds = `0${timerData?.timestate % 60}`.slice(-2);
    const minutes = `${Math.floor(timerData?.timestate / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timerData?.timestate / 3600)}`.slice(-2);
    const res = { H: getHours, M: getMinutes, S: getSeconds };
    return res;
  };

  // const formatTime = () => {
  //   const getSeconds = `0${store.getState().presist.timerData?.timestate % 60
  //     }`.slice(-2);
  //   const minutes = `${Math.floor(
  //     store.getState().presist.timerData?.timestate / 60
  //   )}`;
  //   const getMinutes = `0${minutes % 60}`.slice(-2);
  //   const getHours = `0${Math.floor(
  //     store.getState().presist.timerData?.timestate / 3600
  //   )}`.slice(-2);
  //   // dispatch(
  //   //   updatePresistStates({
  //   //     name: "timeinHHMMSS",
  //   //     value: { H: getHours, M: getMinutes, S: getSeconds },
  //   //   })
  //   // );
  //   return taskValue.state === "Started" || taskValue.state === "Resumed"
  //     ? `${getHours} : ${getMinutes} : ${getSeconds}`
  //     : `${taskValue.workHours} : ${taskValue.workMinutes} : ${taskValue.workSeconds}`;
  // };
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} : ${minutes} : ${Math.round(seconds)}`;
  }
  // function toHoursAndMinutes(totalSeconds) {
  //   const totalMinutes = Math.floor(totalSeconds / 60);

  //   const seconds = totalSeconds % 60;
  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;
  //   return `${hours} : ${minutes} : ${Math.round(seconds)}`;
  // }
  // function toHours() {
  //   const totalMinutes = Math.floor(timerData?.timestate / 60);

  //   const seconds = timerData?.timestate % 60;
  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;
  //   const res = { H: hours, M: minutes, S: Math.round(seconds) }
  //   // return  `${hours} : ${minutes} : ${Math.round(seconds)}`;
  //   return res;
  // }
  return (
    <div>
      {/* <div className="stopwatch-card"> */}
      {/* {taskValue.state === "Paused" || taskValue.state === "Paused"} */}
      <Box
        sx={{
          fontSize: { sm: 12, md: 14, lg: 16 },
          px: 2,
          fontWeight: "bold",
          // borderStyle: 'solid',
          backgroundColor:
            taskValue.state === "Started" || taskValue.state === "Resumed"
              ? colors.green[400]
              : null,
          color:
            taskValue.state === "Started" || taskValue.state === "Resumed"
              ? "white"
              : "white",
          borderWidth: 1,
          display: "flex",
          borderRadius: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
        className="timefont"
      >
        {/* {formatTime()} */}
        {taskValue.state === "Started" || taskValue.state === "Resumed" ? (
          <> {toHoursAndMinutes(timerData?.timestate)}</>
        ) : (
          <>
            {toHoursAndMinutes(
              toSeconds(
                taskValue.workHours,
                taskValue.workMinutes,
                taskValue.workSeconds
              )
            )}
          </>
        )}
      </Box>
      {/* <div className="buttons">
          {!isActive && !isPaused ? (
            <button onClick={handleStart}>Start</button>
          ) : isPaused ? (
            <button onClick={handlePause}>Pause</button>
          ) : (
            <button onClick={handleResume}>Resume</button>
          )}
          <button onClick={handleReset} disabled={!isActive}>
            Reset
          </button>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Timer;
