import axios from "./axiosService";
import { updateMasterStates } from "../redux/reducers/masterReducer";
// import { TriggerAlert } from "../actions/action";

const baseURL = process.env.REACT_APP_BASE_URL;

export const CreateJobService = async (method, headerdata, data) => {
  let promies = new Promise(function (resolve, reject) {
    const reader = new FileReader();
    // reader.readAsDataURL(data);
    headerdata.filetype = data.type;
    reader.onloadend = async () => {
      const binaryData = reader.result;
      const blob = new Blob([binaryData], { type: data.type });
      try {
        return await axios({
          method: method,
          url: `${baseURL}/job/create/`,
          headers: headerdata,
          data: blob,
        }).then((response) => {
          // TriggerAlert("success", response.data.message);

          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(data);
  });
  return promies;
};
export const getCreateJobService = async (url, setLoading) => {
  setLoading(true);
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/job/create/${url}`,
    }).then((response) => {
      setLoading(false);
      return response;
    });
  } catch (error) {}
};
export const EditJobList = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

export const CreateJobServiceValid = async (method, headerdata, data) => {
  let promies = new Promise(function (resolve, reject) {
    const reader = new FileReader();
    // reader.readAsDataURL(data);
    headerdata.filetype = data.type;
    reader.onloadend = async () => {
      const binaryData = reader.result;
      const blob = new Blob([binaryData], { type: data.type });
      try {
        return await axios({
          method: method,
          url: `${baseURL}/job/validate_excel/`,
          headers: headerdata,
          data: blob,
        }).then((response) => {
          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(data);
  });
  return promies;
};
export const TaskAssignExcelUpload = async (method, headerdata, data, path) => {
  let promies = new Promise(function (resolve, reject) {
    const reader = new FileReader();
    // reader.readAsDataURL(data);
    headerdata.filetype = data.type;
    reader.onloadend = async () => {
      const binaryData = reader.result;
      const blob = new Blob([binaryData], { type: data.type });
      try {
        return await axios({
          method: method,
          url: `${baseURL}${path || ""}`,
          headers: headerdata,
          data: blob,
        }).then((response) => {
          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(data);
  });
  return promies;
};
export const ReassignTaskData = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const CompletedTaskData = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getTask = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/job/getTasks${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const revertTaskapproveandReject = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getRevertTaskList = async (method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const revertTaskPageProjectGETAPI = async (method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const projectTreeAPI = async (method, path, data) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getRevertTaskListAdmin = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path || ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getUserTask = async (
  method,
  data,
  itemperPage,
  pageNumber,
  
) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/job/project_job_load?pageSize=${itemperPage}&pageNumber=${pageNumber}`,
      data:  data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const getAssignedTask = async (itemPerPage, pageNumber) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/job/getTasks?pageSize=${itemPerPage}&pageNumber=${pageNumber}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
  }
};
export const getSplitTaskGet = async (data, path) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/getTaskSpilt${path}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "assignedtaskData", value: [] }));
  }
};
export const AssignTaskDownload = async (method, data, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data,
      responseType: "arraybuffer",
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const AssignTask = async (method, data) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/job/assign/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const ReAssignTask = (method, data) => async (dispatch, getState) => {
  try {
    await axios({
      method: method,
      url: `${baseURL}/job/assign/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
    });
  } catch (error) {
    dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const UpdateTask = async (data, call, callback) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/updateTask/`,
      data,
    }).then((response) => {
      // if (call) {
      //   callback();
      // }
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "tasklistData", value: [] }));
  }
};
export const UpdatejobTask = async (data, call, callback) => {
  try {
    return await axios({
      method: "patch",
      url: `${baseURL}/job/updateTask/`,
      data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const SplitTaskData = async (data, callBack) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/splitTask/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      callBack && callBack();
      return response;
    });
  } catch (error) {}
};
export const getTaskList = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/jobTask/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};

export const getExcel = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/jobTaskExcel/`,
      data,
      responseType: "arraybuffer",
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const requestRevert = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/task_revert_request/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const giveCount = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/job/updateCompleteCount`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const generalTaskHistory = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/generalTask/user_general_task_history_list/`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const sendSplitCompletedCountRequest = async (data) => {
  try {
    return await axios({
      method: "put",
      url: `${baseURL}/job/splitTask/`,
      data: data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const CreateSLAAction = async (data, id) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/query/sla/${id}`,
      data: data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
export const getSlaList = async (id) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/query/sla/${id}`,
      // data: data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response?.data?.data;
    });
  } catch (error) {
    // dispatch(updateMasterStates({ name: "taskList", value: [] }));
  }
};
