import { Box, Button, Card, TextField } from '@mui/material'
import React, { useState } from 'react'
import logo from "../../../pics/workboxLogo.png";
import { formFieldStyle } from '../../../utils/Style';
import { SendEmailPasswordAPI } from '../../../service/authService';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { TriggerAlert, loading } from '../../../utils/UIUtil';
import { useDispatch } from 'react-redux';

function ForgetPassword() {
    const [commenData, setCommenData] = useState()
    const nav = useNavigate();
    const dispatch = useDispatch()
    const handelChange = (e) => {
        if (e) {
            setCommenData(e.target.value.toUpperCase())
        } else {
            setCommenData(e)
        }
    }
    const SendEmail = async () => {
        const payload = { empId: commenData }
        dispatch(loading(true));
        const res = await SendEmailPasswordAPI(payload)
        dispatch(loading(false));
        if (res) {
            TriggerAlert("success", res?.data?.message)
            // console.log(res, "res")

        }
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100vh", background: grey[100] }}>
            <Card sx={{ p: 4, border: "1px solid #00a859" }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ p: 2 }}>
                        <img src={logo} style={{ height: 50 }} alt="logo" />
                    </Box>
                    <Box>
                        <TextField
                            label="Employee Id *"
                            size="small"
                            onChange={handelChange}
                            inputProps={{ "aria-label": "Code" }}
                            value={commenData}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                        />
                    </Box>
                    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', columnGap: 5 }}>
                        <Button variant='contained' color='success' disabled={!commenData} size='small' onClick={SendEmail}>
                            Submit
                        </Button>
                        <Button variant='contained' color='inherit' size='small' onClick={() => nav("/")}>
                            Cancel
                        </Button>
                    </Box>
                </Box>


            </Card>
        </Box>
    )
}

export default ForgetPassword