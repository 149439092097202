import { BorderColor, Done, Edit } from "@mui/icons-material";
import { Box, Card, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import DataGride from "../../../components/datagride/DataGride";
// import { mappingOption } from "../../utils/largedata";
import { useSelector } from "react-redux";
import { createEditGetUnitMaster } from "../../../service/masterService";
import { allowedPages } from "../../../utils/permissions";
import CreateMaster from "./CreateProjectMaster";
import { allpageFontStyle, sweetalertWidth } from "../../../utils/FontStyle";
import { checkBoxFilter } from "../../../utils/UIUtil";
import Swal from "sweetalert2";

function ProjectMasterList() {
  // const nav = useNavigate();
  const PAGENAME = "UNIT TYPE";
  // const PAGENAME_ACTION = "Create Unit Type"
  const { globapOrganization } = useSelector((state) => state.presist);
  const [masterArray, setMasterArray] = useState([]);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [selectdata, setSelectedData] = useState({});
  const [isActive, setIsActive] = React.useState(true);
  const handleEditClick = (row) => {
    setSelectedData(() => ({ ...row }));
    setEdit(true);
    // nav("/CreateMaster", { state: row });
  };
  const [edit, setEdit] = useState(false);
  const construct = (arr) => {
    const res = arr?.map((val, i) => ({
      ...val,
      SN: i + 1,
    }));
    return res;
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    const res = await createEditGetUnitMaster(
      "get",
      {},
      globapOrganization?.orgId,
      e ? "?isActive=True" : "?isActive=False"
    );
    if (res) {
      console.log(res, "res12");
      setMasterArray(construct(res?.data));
    } else {
      setMasterArray([]);
    }
  };
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        const res = await createEditGetUnitMaster(
          "put",
          { id: [row?.id] },
          globapOrganization?.orgId
        );
        if (res) {
          handelActiveInactive(false);
        }
      }
    });
  };
  const handleDeleteClick = async (row) => {
    console.log(row);
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        const res = await createEditGetUnitMaster(
          "delete",
          { id: row },
          globapOrganization?.orgId
        );
        if (res) {
          handelActiveInactive(true);
        }
      }
    });
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const columns = [
    {
      field: "SN",
      headerName: "S.No",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitName",
      headerName: "Unit Type",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 50,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {allowedPages().settings.unitType.edit && isActive && (
            <Box
              onClick={() => {
                handleEditClick(params.row);
              }}
            >
              <Tooltip title="Edit" placement="right">
                <Edit
                  sx={{ cursor: "pointer", color: "#358c4e" }}
                  color="primary"
                />
              </Tooltip>
            </Box>
          )}
          {!isActive ? (
            <Box sx={{ p: 0 }}>
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ),
    },
  ];
  const getMasterList = async () => {
    try {
      const res = await createEditGetUnitMaster(
        "get",
        {},
        globapOrganization?.orgId,
        isActive ? "?isActive=True" : "?isActive=False"
      );
      if (res) {
        console.log(res, "res");
        setMasterArray(construct(res?.data));
      } else {
        setMasterArray([]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getMasterList();
    return () => {};
  }, [globapOrganization]);
  return (
    <Box sx={{ height: "100%", minHeight: 600, background: grey[100] }} p={3}>
      <Card sx={{ p: 3, boxShadow: "none", borderRadius: 5 }}>
        <CreateMaster
          edit={edit}
          setEdit={setEdit}
          editValue={selectdata}
          reloadFun={getMasterList}
        />
        <Box
          sx={{
            fontFamily: "Lato",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {" "}
            <h4 style={allpageFontStyle.title}>LIST OF {PAGENAME} </h4>
          </Box>
          {/* {allowedPages().ProjectManagement.unitType.create &&
                        <Box sx={{ pt: 1.5 }}>
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    nav("/CreateMaster")
                                }}
                                sx={{
                                    height: 40,

                                    fontSize: 12,
                                    fontWeight: "bold",

                                    borderRadius: 5,
                                    color: "#358c4e",
                                    p: 0,
                                    px: 2,
                                    py: 0,
                                    ':hover': {
                                        backgroundColor: "#358c4e",
                                        color: "white", borderColor: '#358c4e'
                                    },
                                    textTransform: "capitalize",
                                    borderColor: '#358c4e',
                                }}
                            >
                                <Add sx={{ mr: 0.5 }} />
                                {PAGENAME_ACTION}
                            </Button>
                        </Box>} */}
        </Box>
        <DataGride
          columns={columns}
          customHeight={450}
          // hideDeletion={!isActive?true:false}
          deleteIcon={isActive ? true : false}
          tableData={masterArray}
          selectionIs={selectionIs}
          selectionState={selectionState}
          selectedFile={selectedFile}
          onSelectionModelChange={onSelectionModelChange}
          deleteFuntion={handleDeleteClick}
          InActiveFilterCheckBox={() =>
            checkBoxFilter(
              isActive,
              setIsActive,
              "Active",
              "Inactive",
              handelActiveInactive
            )
          }
        />
      </Card>
    </Box>
  );
}

export default ProjectMasterList;
