import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReplyIcon from "@mui/icons-material/Reply";
import EmojiObjectsRoundedIcon from "@mui/icons-material/EmojiObjectsRounded";
import moment from "moment";
import { grey } from "@mui/material/colors";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { formats, modules } from "./createQuery";
import ReactQuill from "react-quill";
import "../stylesheet.css";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getForumDataAction,
  postReplyAction,
} from "../../../redux/action/action";
import { conformPopup } from "../../popup/ConformPopup";
import pic from "../../../pics/check-mark.png";
import { updatePresistStates } from "../../../redux/reducers/PresistReducer";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { allpageFontStyle, sweetalertWidth } from "../../../utils/FontStyle";
import {
  deleteAnswerType,
  deleteFile,
  downloadFileService,
  sendAttachment,
} from "../../../service/helpdeskService";
import SunEditor from "suneditor-react";
import { sortedFontOptions } from "../announcements/createAnnouncement";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Dropzone from "react-dropzone";
import CloudUpload from "@mui/icons-material/CloudUpload";
import {
  CloseRounded,
  Delete,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import Swal from "sweetalert2";

export default function Forum() {
  const dispatch = useDispatch();
  const id = useParams();
  const { state } = useLocation();
  const [fileValue, setFileValue] = React.useState([]);
  const [open, setOpen] = useState(false);
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );
  const { forumData } = useSelector((state) => state.master);
  console.log(id, state, "path");
  console.log(forumData, "resData");
  useEffect(() => {
    dispatch(getForumDataAction(globapOrganization.orgId, id.id));
  }, []);
  useEffect(() => {
    dispatch(getForumDataAction(globapOrganization.orgId, id.id));
  }, [id?.id]);
  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      dispatch(getForumDataAction(globapOrganization.orgId, id.id));
      dispatch(
        updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false } })
      );
    }
  }, [autoLoad]);

  const { userprofile } = useSelector((state) => state.presist);
  const [showTextArea, setShowTextArea] = useState(false);
  const [queryAttach, setQueryAttach] = useState(false);
  const [postCondition, setPostCondition] = useState("");
  const [ansAttach, setAnsAttach] = useState({
    value: false,
    id: "",
  });
  // const [contents, setContent] = useState('')
  const [payload, setPayload] = useState({
    projectCode: forumData?.[0]?.queryInfo?.projectCode,
    jobCode: forumData?.[0]?.queryInfo?.jobCode,
    orgId: globapOrganization.orgId,
    attachmentLocations: {},
    targetId: id?.id,
    targetType: "QUERY",
    answerContent: "",
    isResolvedAnswers: false,
    userName: userprofile?.userInfo?.userInfo?.username,
    answerCode: null,
  });
  // useEffect(() => {
  //   if (state) setShowTextArea(true);
  // }, []);
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  const postAnswer = (pay) => {
    const t = Object.values(payload.attachmentLocations);
    postReplyAction("post", globapOrganization.orgId, {
      ...pay,
      attachmentLocations: t.length ? t : null,
      targetType: forumData?.[0]?.troubleShooterInfo?.find(
        (user) => user.userName === userprofile?.userInfo?.userInfo?.username
      )
        ? "ANSWER"
        : "QUERY",
    }).then((res) => {
      if (res?.data) {
        dispatch(getForumDataAction(globapOrganization.orgId, id.id));
      }
    });
    setPayload({
      projectCode: forumData?.[0]?.queryInfo?.projectCode,
      jobCode: forumData?.[0]?.queryInfo?.jobCode,
      orgId: globapOrganization.orgId,
      attachmentLocations: {},
      targetId: id?.id,
      targetType: forumData?.[0]?.troubleShooterInfo?.find(
        (user) => user.userName === userprofile?.userInfo?.userInfo?.username
      )
        ? "ANSWER"
        : "QUERY",
      answerContent: "",
      isResolvedAnswers: false,
      userName: userprofile?.userInfo?.userInfo?.username,
      answerCode: null,
    });
    setShowTextArea(false);
    setFileValue([]);
  };

  const markAnswer = (data, code) => {
    postAnswer({
      ...payload,
      targetType: "ANSWER",
      isResolvedAnswers: true,
      answerContent: data,
      answerCode: code,
    });
  };
  const fileDownload = (file, view) => {
    downloadFileService(file, view);
  };

  const clearField = (file, index) => {
    console.log("clearrr");
    const u = [...fileValue];
    u.splice(index, 1);
    console.log(file, "fileeee1");
    setFileValue(u);
    // console.log(getValues('attachmentLocations'), "attchhkh")
    // console.log(getValues('attachmentLocations')[file.path], file.path, "checkkk")
    if (payload.attachmentLocations[file?.path]) {
      deleteFile(payload.attachmentLocations[file?.path]);
      console.log(file, "fileeee2");
    }
    const v = { ...payload.attachmentLocations };
    delete v[file?.path];
    setPayload({ ...payload, attachmentLocations: v });
  };
  console.log(fileValue, payload.attachmentLocations, "attachmmmmm");
  const handelChangeFile = async (e) => {
    console.log(fileValue, "ghg");
    let v = fileValue.filter(
      (value) => value !== fileValue.map((file) => file)
    );
    let w = e.filter(
      (value) =>
        !(fileValue?.findIndex((file) => file.name === value.name) >= 0)
    );
    console.log(w, "vvvvvvvv");
    if (w.length !== e.length && fileValue.length) {
      alert("File already exists");
    }
    setFileValue([...fileValue, ...w]);
    sendAttachment(e).then((res) => {
      const t = {};
      res?.data.map((list) => {
        t[list.originalFileName] = list?.fileCode;
      });
      // setValue('attachmentLocations', [...getValues('attachmentLocations'),...res?.data.map((list)=>list?.fileCode)  ];);
      setPayload({
        ...payload,
        attachmentLocations: { ...payload.attachmentLocations, ...t },
      });

      console.log(
        {
          ...payload,
          attachmentLocations: { ...payload.attachmentLocations, ...t },
        },
        "respsdsfsonse"
      );
    });
  };
  console.log(payload.attachmentLocations, "attachmentsss");
  const deleteAnswer = (id, queryCode) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await deleteAnswerType(globapOrganization.orgId, id, queryCode);
        dispatch(getForumDataAction(globapOrganization.orgId, queryCode));
      }
    });
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        background: grey[50],
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: 5,
          flexDirection: "column",
          overflow: "auto",
          // flex: 1,
          mb: 3,
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            px: 3,
            py: 1,
            mb: 2,
            // background:'pink',
          }}
        >
          <Box sx={{ display: "flex" }}>
            {/* <Avatar
              {...stringAvatar(
                forumData?.[0]?.createdUserInfo?.firstname +
                " " +
                forumData?.[0]?.createdUserInfo?.lastname
              )}
            /> */}
            <Box
              sx={{
                height: 45,
                width: 45,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                border: "0.5px solid grey",
              }}
            >
              <Avatar
                sizes=""
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
                alt={
                  forumData?.[0]?.createdUserInfo?.firstname +
                  " " +
                  forumData?.[0]?.createdUserInfo?.lastname
                }
                src={`data:image/jpeg;base64,${forumData?.[0]?.createdUserImage?.userBase64Image}`}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mx: 1 }}>
              <Box
                className="queryCode"
                sx={{
                  ...allpageFontStyle.title1,
                  color:
                    forumData?.[0]?.queryInfo?.criticality === "low"
                      ? "#1971c2"
                      : forumData?.[0]?.queryInfo?.criticality === "medium"
                      ? "#e67700"
                      : forumData?.[0]?.queryInfo?.criticality === "high"
                      ? "#ff6b6b"
                      : null,
                }}
              >
                {forumData?.[0]?.queryInfo?.queryCode}
              </Box>
              <Box sx={{ ...allpageFontStyle.typography1 }}>
                Created by
                <span
                  style={{
                    color: "#258d4d",
                    fontWeight: "bold",
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                >
                  {forumData?.[0]?.createdUserInfo?.firstname +
                    " " +
                    forumData?.[0]?.createdUserInfo?.lastname}
                </span>
                on
                <span
                  style={{
                    color: "#258d4d",
                    fontWeight: "bold",
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                >
                  {moment(forumData?.[0]?.queryInfo?.createdOn).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </span>
              </Box>
              {/* <Box sx={{display:'flex', alignItems:'center'}}> */}

              <Typography
                sx={{
                  my: 0,
                  //  background:'red',
                  //  width:'0%',
                  p: 0,
                  pt: 3,
                  // fontSize: 14 ,
                  // fontWeight: "bold",
                  ".tag div": {
                    padding: 0,
                    margin: 0,
                  },
                  ".tag table tbody tr td": {
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  },
                }}
              >
                {/* {forumData?.[0]?.createdUserInfo?.firstname + " " + forumData?.[0]?.createdUserInfo?.lastname} */}
                <p
                  className="tag"
                  style={{ padding: 0, margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: forumData?.[0]?.queryInfo?.queryContent,
                  }}
                />
              </Typography>

              {/* </Box> */}
              <Box sx={{}}>
                {queryAttach && forumData?.[0]?.fileInfo?.length ? (
                  <Box
                    sx={{
                      ...allpageFontStyle.typography1,
                      p: 0,
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      mt: 0.5,
                    }}
                  >
                    {forumData?.[0]?.fileInfo?.map((files) => (
                      <Box
                        sx={{
                          border: "0.1px solid black",
                          borderRadius: 3,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.3,
                          cursor: "pointer",
                          px: 1,
                          ":hover": {
                            textDecoration: "underline",
                            color: "#1864ab",
                          },
                          ...allpageFontStyle.typography2,
                        }}
                      >
                        <Typography
                          onClick={() => fileDownload(files, true)}
                          sx={{
                            ...allpageFontStyle.typography2,
                            ":hover": {
                              textDecoration: "underline",
                              color: "#1864ab",
                            },
                          }}
                        >
                          {" "}
                          {files?.originalFileName}
                        </Typography>
                        <DownloadForOfflineIcon
                          onClick={(e) => {
                            fileDownload(files);
                            console.log(files);
                          }}
                          fontSize="small"
                          sx={{ color: "#1864ab", ml: 0.5 }}
                        />
                      </Box>
                    ))}
                    <Box
                      onClick={() => setQueryAttach(false)}
                      sx={{
                        ...allpageFontStyle.typography2,
                        color: "#1864ab",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <KeyboardDoubleArrowLeft fontSize="small" />{" "}
                      {forumData?.[0]?.fileInfo?.length} attachments
                    </Box>
                  </Box>
                ) : forumData?.[0]?.fileInfo?.length ? (
                  <Box
                    sx={{
                      ...allpageFontStyle.typography2,
                      p: 0,
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      mt: 0.5,
                    }}
                  >
                    {forumData?.[0]?.fileInfo?.length > 0 ? (
                      <Box
                        sx={{
                          border: "0.1px solid black",
                          borderRadius: 3,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.3,
                          cursor: "pointer",
                          px: 1,
                          ":hover": {
                            textDecoration: "underline",
                            color: "#1864ab",
                          },
                          ...allpageFontStyle.typography2,
                        }}
                      >
                        <Typography
                          onClick={() =>
                            fileDownload(forumData?.[0]?.fileInfo?.[0], true)
                          }
                          sx={{
                            ...allpageFontStyle.typography1,
                            ":hover": {
                              textDecoration: "underline",
                              color: "#1864ab",
                            },
                          }}
                        >
                          {" "}
                          {forumData?.[0]?.fileInfo?.[0]?.originalFileName}
                        </Typography>
                        <DownloadForOfflineIcon
                          onClick={(e) => {
                            fileDownload(forumData?.[0]?.fileInfo?.[0]);
                          }}
                          fontSize="small"
                          sx={{ color: "#1864ab", ml: 0.5 }}
                        />
                      </Box>
                    ) : null}
                    {forumData?.[0]?.fileInfo?.length > 1 ? (
                      <Box
                        sx={{
                          border: "0.1px solid black",
                          borderRadius: 3,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.3,
                          cursor: "pointer",
                          px: 1,
                          ":hover": {
                            textDecoration: "underline",
                            color: "#1864ab",
                          },
                          ...allpageFontStyle.typography2,
                        }}
                      >
                        <Typography
                          onClick={() =>
                            fileDownload(forumData?.[0]?.fileInfo?.[1], true)
                          }
                          sx={{
                            ":hover": {
                              textDecoration: "underline",
                              color: "#1864ab",
                            },
                            ...allpageFontStyle.typography2,
                          }}
                        >
                          {" "}
                          {forumData?.[0]?.fileInfo?.[1]?.originalFileName}
                        </Typography>
                        <DownloadForOfflineIcon
                          onClick={(e) => {
                            fileDownload(forumData?.[0]?.fileInfo?.[1]);
                          }}
                          fontSize="small"
                          sx={{ color: "#1864ab", ml: 0.5 }}
                        />
                      </Box>
                    ) : null}
                    {forumData?.[0]?.fileInfo?.length > 2 ? (
                      <Box
                        sx={{
                          cursor: "pointer",
                          ...allpageFontStyle.typography4,
                          ml: 1,
                          color: "#1864ab",
                        }}
                        onClick={() => setQueryAttach(true)}
                      >
                        +{forumData?.[0]?.fileInfo?.length - 2} Attachments
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
            {/* <Box sx={{ fontSize: 11, color: "grey" }}>
               
              </Box> */}
          </Box>

          <Divider sx={{ mt: 2 }} flexItem />
        </Box>
        {/* replies------------------------------------------------------------------------------------------------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "90%",
            my: 0.5,

            color: "grey",

            alignItems: "center",
            pl: 4,
            ...allpageFontStyle.buttonFontStyle2,
            // background:'yellow'
          }}
        >
          <QuestionAnswerIcon sx={{ mr: 1 }} />{" "}
          {forumData?.[0]?.ansListInfo?.length}
        </Box>
        {forumData?.[0]?.ansListInfo?.map((replies) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              justifyContent: "center",
              my: 0.5,
              //  background:'orange'
            }}
          >
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                //  border: "0.5px solid #cfd1d4",
                px: 3,
                py: 1,
                my: 0.2,
                position: "relative",
                background: replies?.ansInfo?.isActive ? "white" : "#fafafa",
                // background:
                //   replies?.ansInfo?.targetType === "ANSWER" ? "#d3f9d8" : null,
                // borderLeft:
                //   replies?.ansInfo?.targetType === "ANSWER"
                //     ? "3px solid green"
                //     : null,
                boxShadow: "none",
                // transition: "all 3s ease-out",
                ":hover": {
                  ".deleteIcon": {
                    visibility: "visible",
                    borderRadius: "200px 0px 0px 0px",
                    // transition: "all 3s ease-out",
                  },
                },
              }}
            >
              {replies?.ansInfo?.createdBy ===
                userprofile?.userInfo?.userInfo?.username &&
              replies?.ansInfo?.targetType === "ANSWER" &&
              replies?.ansInfo?.isActive ? (
                <Box
                  onClick={() =>
                    deleteAnswer(
                      replies?.ansInfo?.id,
                      forumData?.[0]?.queryInfo?.queryCode
                    )
                  }
                  className="deleteIcon"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    visibility: "hidden",
                    background: "#ffe3e3",
                    p: 2,
                    pl: 2.5,
                    // borderRadius: "0px 0px 0px 0px",
                    display: "grid",
                    placeItems: "center",
                    cursor: "pointer",
                    // transition: "background 3s ease-out",
                  }}
                >
                  <Delete sx={{ color: "#ff8787" }} fontSize="inherit" />
                </Box>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: 0.5,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Avatar
                    {...stringAvatar(
                      replies?.userInfo?.firstname +
                      " " +
                      replies?.userInfo?.lastname
                    )}
                  /> */}
                  <Box
                    sx={{
                      height: 45,
                      width: 45,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      border: "0.5px solid grey",
                    }}
                  >
                    <Avatar
                      sizes=""
                      sx={{
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                      alt={
                        replies?.userInfo?.firstname +
                        " " +
                        replies?.userInfo?.lastname
                      }
                      src={`data:image/jpeg;base64,${replies?.ansImage?.userBase64Image}`}
                    />
                  </Box>
                  <Box
                    sx={{
                      mx: 1,
                      ...allpageFontStyle.title1,
                      display: "flex",
                      flexDirection: "column",
                     
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{textDecoration: replies?.ansInfo?.isActive
                        ? "none"
                        : "line-through",}}>
                        {replies?.userInfo?.firstname +
                          " " +
                          replies?.userInfo?.lastname}
                      </Box>
                      {!replies?.ansInfo?.isActive ? (
                        <span style={{ textDecoration: "none", paddingLeft:5, color: '#ff8787'}}>
                          (deleted)
                        </span>
                      ) : (
                        null
                      )}
                    </Box>
                    <Box
                      sx={{ ...allpageFontStyle.typography2, color: "grey",textDecoration: replies?.ansInfo?.isActive
                      ? "none"
                      : "line-through", }}
                    >
                      {moment(replies?.ansInfo?.createdOn).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ml: 6 }}>
                <Typography
                  sx={{
                    // color: replies?.ansInfo?.isActive ? "black" : "#868e96",
                    textDecoration: replies?.ansInfo?.isActive
                        ? "none"
                        : "line-through",
                    my: 0,
                    //  background:'red',
                    //  width:'0%',
                    px: 1,
                    // fontSize: 14 ,
                    // fontWeight: "bold",
                    ".tag div": {
                      padding: 0,
                      margin: 0,
                    },
                    ".tag table tbody tr td": {
                      borderCollapse: "collapse",
                      border: "1px solid black",
                    },
                  }}
                >
                  {/* {forumData?.[0]?.createdUserInfo?.firstname + " " + forumData?.[0]?.createdUserInfo?.lastname} */}
                  <p
                    className="tag"
                    style={{ padding: 0, margin: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: replies?.ansInfo?.answerContent,
                    }}
                  />
                </Typography>
              </Box>

              {ansAttach.value &&
              ansAttach.id === replies?.ansInfo?.answerCode &&
              replies?.fileInfo?.length ? (
                <Box
                  sx={{
                    display: "flex",
                    ml: 5,
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {replies?.fileInfo?.length
                    ? replies?.fileInfo.map((files) => (
                        <Box
                          sx={{
                            border: "0.1px solid black",
                            borderRadius: 3,
                            display: "flex",
                            alignItems: "center",
                            m: 0.3,
                            cursor: "pointer",
                            textDecoration: "underline",
                            px: 1,
                            //  background:'pink',
                            ":hover": {
                              textDecoration: "underline",
                              color: "#1864ab",
                            },
                            ...allpageFontStyle.typography2,
                          }}
                        >
                          <Typography
                            onClick={() => fileDownload(files, true)}
                            sx={{
                              ...allpageFontStyle.typography2,
                              // fontWeight: "bold",
                              ":hover": {
                                textDecoration: "underline",
                                color: "#1864ab",
                              },
                            }}
                          >
                            {" "}
                            {files?.originalFileName}
                          </Typography>
                          <DownloadForOfflineIcon
                            onClick={(e) => {
                              fileDownload(files);
                              console.log(files);
                            }}
                            fontSize="small"
                            sx={{ color: "#1864ab", ml: 0.5 }}
                          />
                        </Box>
                      ))
                    : null}
                  <Box
                    sx={{
                      color: "#1864ab",
                      cursor: "pointer",
                      ml: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ...allpageFontStyle.typography2,
                    }}
                    onClick={() => setAnsAttach({ value: false, id: "" })}
                  >
                    <KeyboardDoubleArrowLeft fontSize="small" />
                    {replies?.fileInfo?.length} Attachments
                  </Box>
                </Box>
              ) : replies?.fileInfo?.length ? (
                <Box
                  sx={{
                    display: "flex",
                    ml: 5,
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "0.1px solid black",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      m: 0.3,
                      cursor: "pointer",
                      px: 1,
                      textDecoration: "underline",
                      ":hover": {
                        textDecoration: "underline",
                        color: "#1864ab",
                      },
                      ...allpageFontStyle.typography2,
                    }}
                  >
                    <Typography
                      onClick={() => fileDownload(replies?.fileInfo?.[0], true)}
                      sx={{
                        ...allpageFontStyle.typography2,
                        ":hover": {
                          textDecoration: "underline",
                          color: "#1864ab",
                        },
                      }}
                    >
                      {" "}
                      {replies?.fileInfo?.[0]?.originalFileName}
                    </Typography>
                    <DownloadForOfflineIcon
                      onClick={(e) => {
                        fileDownload(replies?.fileInfo?.[0]);
                      }}
                      fontSize="small"
                      sx={{ color: "#1864ab", ml: 0.5 }}
                    />
                  </Box>
                  {replies?.fileInfo?.length > 1 ? (
                    <Box
                      sx={{
                        ...allpageFontStyle.typography2,
                        border: "0.1px solid black",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        m: 0.3,
                        cursor: "pointer",
                        textDecoration: "underline",
                        px: 1,
                        ":hover": {
                          textDecoration: "underline",
                          color: "#1864ab",
                        },
                      }}
                    >
                      <Typography
                        onClick={() =>
                          fileDownload(replies?.fileInfo?.[1], true)
                        }
                        sx={{
                          ...allpageFontStyle.typography2,
                          ":hover": {
                            textDecoration: "underline",
                            color: "#1864ab",
                          },
                        }}
                      >
                        {" "}
                        {replies?.fileInfo?.[1]?.originalFileName}
                      </Typography>
                      <DownloadForOfflineIcon
                        onClick={(e) => {
                          fileDownload(replies?.fileInfo?.[1]);
                        }}
                        fontSize="small"
                        sx={{ color: "#1864ab", ml: 0.5 }}
                      />
                    </Box>
                  ) : null}
                  {replies?.fileInfo?.length > 2 ? (
                    <Box
                      onClick={() =>
                        setAnsAttach({
                          value: true,
                          id: replies?.ansInfo?.answerCode,
                        })
                      }
                      sx={{
                        ...allpageFontStyle.typography2,
                        cursor: "pointer",
                        ml: 1,
                        color: "#1864ab",
                      }}
                    >
                      +{replies?.fileInfo?.length - 2} Attachments
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Paper>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          // position: "absolute",
          // bottom: 20,
          // height: "5vh",
          width: "100%",
          // left: showTextArea ? 0 : 50,
          display: "flex",
          pb: 2,
          // background:'pink',
          flex: 1,
        }}
      >
        {/* <Button variant="contained" sx={{ ml: 10, textTransform: 'capitalize', display:'flex', alignItems:'center' }}>
                    <ReplyIcon sx={{mr:0.5}}/>Reply
                   
                </Button> */}

        {showTextArea ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "90%" }}>
              <SunEditor
                setContents={payload.answerContent}
                onChange={(e) => {
                  console.log(payload, "hghugfhjg");
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = e;
                  const plainTextContent =
                    tempDiv.textContent || tempDiv.innerText;
                  setPostCondition(plainTextContent);
                  // setPayload({ ...payload, answerContent: e })
                  setPayload((prev) => ({ ...prev, answerContent: e }));
                  console.log(
                    e.length,
                    e.toString().replace(/<br>/, "").includes("<div></div>"),
                    e,
                    "contentssss"
                  );
                  console.log(payload, "hghugfhjg2");
                }}
                height="350"
                // width="200"
                setDefaultStyle="font-family: Arial; font-size: 14px;"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],

                    ["table", "horizontalRule", "link"],
                  ],
                  defaultTag: "div",
                  minHeight: "100px",
                  maxHeight: 300,

                  showPathLabel: false,
                  font: sortedFontOptions,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                // justifyContent: "space-between",
                width: "90%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-end", flex: 1 }}>
                <Dropzone
                  maxFiles={10}
                  multiple
                  noClick
                  onDrop={(acceptedFiles) => handelChangeFile(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps, open }) => (
                    <div {...getRootProps()}>
                      <input multiple={false} {...getInputProps()} />
                      <Button
                        size="small"
                        onClick={open}
                        sx={{
                          background: "#1971c2",
                          color: "white",
                          mx: 0.5,
                          mb: 0.3,
                          borderRadius: 3,
                          ...allpageFontStyle.typography1,
                          textTransform: "capitalize",
                          ":hover": { background: "#1971c2" },
                        }}
                        variant="contained"
                      >
                        {" "}
                        <AttachFileIcon fontSize="small" sx={{ ml: 0.3 }} />
                        Attach
                      </Button>
                    </div>
                  )}
                </Dropzone>

                {/* </label> */}
                {fileValue.length >= 1 ? (
                  <Box
                    sx={{
                      ...allpageFontStyle.typography1,
                      mr: 1,
                      pl: 2,
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "90%",
                      // background:'red'
                    }}
                  >
                    {fileValue?.map((list, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mx: 1,
                          border: "0.3px solid grey",
                          px: 0.5,
                          mb: 0.3,
                          borderRadius: 3,
                        }}
                      >
                        {list?.name}

                        {!payload.attachmentLocations[list?.name] ? (
                          <CircularProgress
                            sx={{ ml: 0.5 }}
                            size={20}
                            color="success"
                          />
                        ) : (
                          <Delete
                            sx={{ fontSize: 15, cursor: "pointer", ml: 0.5 }}
                            color="error"
                            onClick={() => {
                              clearField(list, index);
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Box>
              <Box sx={{ width: "10%", display: "flex", minWidth: 180 }}>
                <Button
                  onClick={() => postAnswer(payload)}
                  disabled={postCondition.length <= 1}
                  size="small"
                  sx={{
                    background: "#339af0",
                    color: "white",
                    m: 0.5,
                    borderRadius: 5,
                    ...allpageFontStyle.buttonFontStyle2,
                    textTransform: "capitalize",
                    ":hover": { background: "#339af0" },
                  }}
                >
                  Post
                </Button>

                <Button
                  size="small"
                  sx={{
                    background: "#ff8787",
                    color: "white",
                    m: 0.5,
                    borderRadius: 5,
                    ...allpageFontStyle.buttonFontStyle2,
                    textTransform: "capitalize",
                    ":hover": { background: "#ff8787" },
                  }}
                  onClick={() => {
                    setShowTextArea(false);
                    setPayload({ ...payload, answerContent: "" });
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        ) : forumData?.[0]?.ansListInfo.length >= 1 &&
          forumData?.[0]?.queryInfo?.createdBy ===
            userprofile?.userInfo?.userInfo?.username ? (
          <Button
            onClick={() => setShowTextArea(true)}
            variant="contained"
            sx={{
              ml: "6vw",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              height: 30,
            }}
          >
            <ReplyIcon />
            Ask a query
          </Button>
        ) : forumData?.[0]?.createdUserInfo?.username !==
            userprofile?.userInfo?.userInfo?.username &&
          forumData?.[0]?.troubleShooterInfo?.find(
            (user) =>
              user.userName === userprofile?.userInfo?.userInfo?.username
          ) &&
          (forumData?.[0]?.ansListInfo?.length === 0 ||
            forumData?.[0]?.queryInfo?.createdBy ===
              forumData?.[0]?.ansListInfo?.[
                forumData?.[0]?.ansListInfo?.length - 1
              ]?.ansInfo?.createdBy ||
            forumData?.[0]?.ansListInfo?.[
              forumData?.[0]?.ansListInfo?.length - 1
            ]?.ansInfo?.createdBy ===
              userprofile?.userInfo?.userInfo?.username) ? (
          <Button
            onClick={() => setShowTextArea(true)}
            color="success"
            variant="contained"
            sx={{
              ml: "6vw",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              height: 30,
            }}
          >
            <EmojiObjectsRoundedIcon />
            Answer
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}
