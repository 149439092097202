import { createSlice } from "@reduxjs/toolkit";
const reducers = {
  test: 0,
  employeeList: [],
  teamUserData: {},
  departmentTeamList: [],
  splitField: [],
  selectedRow: {},
  upcomingTaskData: {},
  errIndex: {
    index: [],
    msg: ''
  },
  isError:false
}
export const profileSlice = createSlice({
  name: "profile",
  initialState: reducers,
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updateProfileStates: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    resetProfileReducer: (state, action) => {
      return { ...reducers }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateProfileStates, resetProfileReducer } = profileSlice.actions;

export default profileSlice.reducer;
