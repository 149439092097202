import axios from "./axiosService";
// import { TriggerAlert } from "../actions/action";
import store from "../redux/store";
// import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
export const getAuthToken = () => {
  const token = sessionStorage.getItem("authToken");
  return token;
};
export const getAuthToken1 = () => {
  const token = store.getState().presist.authToken;
  return token;
};
export const employeeProfile = () => {
  return localStorage.getItem("profile");
};

export const errorMsgAlert = (error) => { };
export const LoginUser = async (data, nav) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/login`,
      data,
    }).then((response) => {
      // TriggerAlert("success", "Login");
      // if (response.data && response.data) {
      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
    // dispatch(updateCommonStates({ name: "authToken", value: "" }));
    // dispatch(updateCommonStates({ name: "userProfile", value: {} }));
  }
};
export const ChangeUserPassword = async (data, nav) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/changepassword`,
      data,
    }).then((response) => {
      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
  }
};
export const ResetUserPassword = async (data) => {
  try {
    return await axios({
      method: "patch",
      url: `${baseURL}/users/changepassword`,
      data,
    }).then((response) => {

      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
  }
};
export const SendEmailPasswordAPI = async (data) => {
  try {
    return await axios({
      method: "put",
      url: `${baseURL}/users/resetpassword?empId=${data.empId}`,
      // data,
    }).then((response) => {

      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
  }
};
export const getTokenAPI = async (data) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/users/resetpassword?uid=${data}`,
      // data,
    }).then((response) => {

      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
  }
};
export const logout = (nav) => {
  //   try {
  //     await axios({
  //       method: "get",
  //       url: `${baseURL}master/region`,
  //     }).then((response) => {
  // TriggerAlert("success", response.data.message);
  //   if (response.data?.data && response.data?.data?.length > 0) {

  //   }
  //     });
  //   } catch (error) {
  //   }
};
export const userLogout = async () => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/users/logout`,

    }).then((response) => {
      return response
    });
  } catch (error) {
    // localStorage.setItem("authToken", "");
  }
};