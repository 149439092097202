import axios from "./axiosService";
import { updateMasterStates } from "../redux/reducers/masterReducer";

// import { TriggerAlert } from "../actions/action";
import axios2 from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const baseChatURL = process.env.REACT_APP_CHAT_URL;

export const getAutoService = (path, page) => async (dispatch, getState) => {
  try {
    await axios({
      method: "get",
      url: `${baseURL}${path}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      dispatch(
        updateMasterStates({
          name: page,
          value: response.data,
        })
      );
    });
  } catch (error) {}
};
export const codeCheckService =
  (data, path, page) => async (dispatch, getState) => {
    let promies = new Promise(async (resolve, reject) => {
      await axios({
        method: "post",
        url: `${baseURL}${path}`,
        data,
      })
        .then((response) => {
          // TriggerAlert("success", response.data.message);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
    return promies;
  };
export const createBranchMaster =
  (data, method, path) => async (dispatch, getState) => {
    let promies = new Promise(async (resolve, reject) => {
      await axios({
        method: method,
        url: `${baseURL}${path}`,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
    return promies;
  };
export const createProjectMaster = async (data, method, path, isEdit) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const createDepartmentMaster = async (data, method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const createDesignationMaster = async (data, method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const createGradeMaster = async (data, method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const createTeamMaster = async (data, method, path) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}${path}`,
      data: data,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getProjectFilter = async (
  id,
  state,
  pageNumber,
  itemPerPage,
  active
) => {
  try {
    return await axios({
      method: "post",
      data: state,
      url: `${baseURL}/project/view/project_depart/${id}/?pageSize=${itemPerPage}&pageNumber=${pageNumber}${
        active ? active : ""
      }`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};

export const editProjectDetails = async (method, id, projCode) => {
  try {
    return await axios({
      method: method,
      data: projCode,
      url: `${baseURL}/project/view/project_detail/${id}/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response.data;
    });
  } catch (error) {}
};
export const getProjectCodeList = async (id, projCode) => {
  try {
    return await axios({
      method: "get",
      data: projCode,
      url: `${baseURL}/job/load/projects/${id}/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};
export const getUserDataBydepartement = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/team/depart_users`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};
export const getJobList = async (projCode) => {
  try {
    return await axios({
      method: "post",
      data: projCode,
      url: `${baseURL}/job/job_list_view/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};

export const passswordService = async (data, path, page) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}${path}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};

export const getProjectList = async (id) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/job/load/projects/${id}/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};
export const getProjectDashboard = async (id) => {
  try {
    return await axios({
      method: "post",
      url: `${baseURL}/report/projectInfo/${id}/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};
export const getJobByProject = async (payload) => {
  try {
    return await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/job/load_project_job?task_assign=True`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getPojectReleatedList = async (payload) => {
  try {
    return await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/report/loadProjectDetail/`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getPojectTeamUsersList = async (payload, orgId) => {
  try {
    return await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/project/project_users/${orgId}/`,
    }).then((response) => {
      return response?.data?.data;
    });
  } catch (error) {}
};
export const sendAttachmentProject = async (fileValue) => {
  try {
    console.log(fileValue, "yhghfhfghf");
    const formData = new FormData();

    formData.append("file", fileValue);

    console.log(formData, "formdata");
    return await axios2({
      method: "post",
      url: `${baseChatURL}/file/upload`,
      data: formData,
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log(error, "eooo");
  }
};
export const getProjectReportService = async (payload) => {
  try {
    return await axios({
      method: "patch",
      data: payload,
      url: `${baseURL}/report/project_detailed_report/`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getJobReportService = async (payload) => {
  try {
    return await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/report/job_detailed_report/`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};
export const getDashBoarddCount = async (payload, id) => {
  try {
    return await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/report/dashboard/${id ? id + "/" : ""}`,
    }).then((response) => {
      return response;
    });
  } catch (error) {}
};

export const getTimeline = async (state) => {
  try {
    return await axios({
      method: "post",
      data: state,
      url: `${baseURL}/job/task_history/`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};
export const getProjectAndJobInfo = async (id) => {
  try {
    return await axios({
      method: "get",

      url: `${baseURL}/job/load_project_job?jobCode=${id}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};

export const getJobData = async (
  id,
  projCode,
  pageSize,
  pageNumber,
  active
) => {
  // console.log(active,"active")
  try {
    return await axios({
      method: "post",

      data: projCode,

      url: `${baseURL}/job/load/projects/${id}/?pageSize=${pageSize}&pageNumber=${pageNumber}${
        active ? active : ""
      }`,
    }).then(async (response) => {
      // TriggerAlert("success", response.data.message);

      return response;
    });
  } catch (error) {}
};
export const getjobcode = async (id) => {
  try {
    return await axios({
      method: "get",
      url: `${baseURL}/job/load_job_code?projectCode=${id}`,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};
export const createEditGetUnitMaster = async (method, data, id, active) => {
  try {
    return await axios({
      method: method,
      url: `${baseURL}/project/project_unit/${id}/${active ? active : ""}`,
      data,
    }).then((response) => {
      // TriggerAlert("success", response.data.message);
      return response;
    });
  } catch (error) {}
};
