import store from "../redux/store";

export const allowedPages = (con) => {
    const userData = store.getState().presist.userprofile;
    const userPermission = userData?.userAccess?.permissions || [];
    const isSuperAdmin = userData?.userAccess?.superUser;
    const permissionCheck = (permission, page) => {
        const isFind = userPermission?.find(i => i.page === page && i.userAccess?.includes(permission))
        const checkUserHavePermission = isFind || isSuperAdmin;
        return checkUserHavePermission;
    };
    const t = {
        adminDashboard: {
            view: permissionCheck("View", "Module_Admin_Dashboard"),
            showMenu: function () {
                // console.log(this.view, 'this.viewthis.view')
                return this.view
            }
        },
        userDashboard: {
            view: permissionCheck("View", "Module_User_Dashboard"),
            showMenu: function () {
                return this.view
            }
        },
        settings: {
            organization: {
                create: permissionCheck("Create", "Module_Organization"),
                view: permissionCheck("View", "Module_Organization"),
                edit: permissionCheck("Edit", "Module_Organization"),
                delete: permissionCheck("Delete", "Module_Organization"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            branch: {
                create: permissionCheck("Create", "Module_Branch"),
                view: permissionCheck("View", "Module_Branch"),
                edit: permissionCheck("Edit", "Module_Branch"),
                delete: permissionCheck("Delete", "Module_Branch"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            department: {
                create: permissionCheck("Create", "Module_Department"),
                view: permissionCheck("View", "Module_Department"),
                edit: permissionCheck("Edit", "Module_Department"),
                delete: permissionCheck("Delete", "Module_Department"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            designation: {
                create: permissionCheck("Create", "Module_Designation"),
                view: permissionCheck("View", "Module_Designation"),
                edit: permissionCheck("Edit", "Module_Designation"),
                delete: permissionCheck("Delete", "Module_Designation"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            grade: {
                create: permissionCheck("Create", "Module_Grade"),
                view: permissionCheck("View", "Module_Grade"),
                edit: permissionCheck("Edit", "Module_Grade"),
                delete: permissionCheck("Delete", "Module_Grade"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            unitType: {
                create: permissionCheck("Create", "Module_Unit_Type"),
                view: permissionCheck("View", "Module_Unit_Type"),
                edit: permissionCheck("Edit", "Module_Unit_Type"),
                showPage: function () {
                    return this.create || this.view || this.edit
                }
            },
            showMenu: function () {
                return this.branch?.showPage() || this.department?.showPage() || this.designation?.showPage() || this.grade?.showPage() || this.organization?.showPage() || this.unitType?.showPage()
            }
        },

        UserManagement: {
            createUser: {
                create: permissionCheck("Create", "Module_Create_User"),
                showPage: function () {
                    return this.create
                }
            },
            userList: {
                view: permissionCheck("View", "Module_User_List"),
                edit: permissionCheck("Edit", "Module_User_List"),
                delete: permissionCheck("Delete", "Module_User_List"),
                resetPassword: permissionCheck("Reset_Password", "Module_User_List"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            showMenu: function () {
                return this.createUser?.showPage() || this.userList?.showPage()
            }
        },
        TeamManagement: {
            creatTeam: {
                create: permissionCheck("Create", "Module_Create_Team"),
                showPage: function () {
                    return this.create
                }
            },
            teamAllocation: {
                teamAllocation: permissionCheck("Allocate", "Module_Team_Allocation"),
                showPage: function () {
                    return this.teamAllocation
                }
            },
            teamList: {
                view: permissionCheck("View", "Module_Team_List"),
                edit: permissionCheck("Edit", "Module_Team_List"),
                delete: permissionCheck("Delete", "Module_Team_List"),
                showPage: function () {
                    return this.view || this.edit || this.delete
                }
            },
            showMenu: function () {
                return this.creatTeam?.showPage() || this.teamAllocation?.showPage() || this.teamList?.showPage()
            }
        },
        role: {
            creatRole: {
                create: permissionCheck("Create", "Module_Create_Role"),
                showPage: function () {
                    return this.create
                }
            },
            roleAllocation: {
                roleAllocation: permissionCheck("Allocate", "Module_Role_Allocation"),
                showPage: function () {
                    return this.roleAllocation
                }
            },
            roleList: {
                view: permissionCheck("View", "Module_Role_List"),
                edit: permissionCheck("Edit", "Module_Role_List"),
                showPage: function () {
                    return this.view || this.edit
                }
            },
            rolePermission: {
                permissionAllocation: permissionCheck("Allocate", "Module_Role_Permission"),
                showPage: function () {
                    return this.permissionAllocation
                }
            },
            showMenu: function () {
                return this.creatRole?.showPage() || this.roleAllocation?.showPage() || this.roleList?.showPage() || this.rolePermission?.showPage()
            }
        },
        ProjectManagement: {
            // unitType: {
            //     create: permissionCheck("Create", "Module_Unit_Type"),
            //     edit: permissionCheck("Edit", "Module_Unit_Type"),
            //     view: permissionCheck("View", "Module_Unit_Type"),
            //     showPage: function () {
            //         return this.view || this.edit || this.create
            //     }
            // },
            ProjectList: {
                view: permissionCheck("View", "Module_Project_List"),
                edit: permissionCheck("Edit", "Module_Project_List"),
                showPage: function () {
                    return this.view || this.edit
                }
            },
            createProject: {
                create: permissionCheck("Create", "Module_Create_Project"),
                showPage: function () {
                    return this.create
                }
            },
            // jobList: {
            //     view: permissionCheck("View", "Module_Job_List"),
            //     showPage: function () {
            //         return this.view
            //     }
            // },
            showMenu: function () {
                return this.ProjectList?.showPage() || this.createProject?.showPage()
                // || this.jobList?.showPage() || this.unitType?.showPage() 
            }
        },
        Generaltask: {
            generalTaskAllocation: {
                allocatetask: permissionCheck("Allocate", "Module_General_Task_Allocation"),
                showPage: function () {
                    return this.allocatetask
                }
            },
            createGeneralTask: {
                create: permissionCheck("Create", "Module_Create_General_Task"),
                showPage: function () {
                    return this.create
                }
            },
            GeneralTaskList: {
                view: permissionCheck("View", "Module_General_Task_List"),
                edit: permissionCheck("Edit", "Module_General_Task_List"),
                showPage: function () {
                    return this.view || this.edit
                }
            },
            showMenu: function () {
                return this.generalTaskAllocation?.showPage() || this.createGeneralTask?.showPage() || this.GeneralTaskList?.showPage()
            }
        },
        Jobs: {
            createJob: {
                create: permissionCheck("Create", "Module_Create_Job"),
                showPage: function () {
                    return this.create
                }
            },
            jobList: {
                view: permissionCheck("View", "Module_Job_List"),
                showPage: function () {
                    return this.view
                }
            },
            // taskList: {
            //     view: permissionCheck("View", "Module_Task_List"),
            //     showPage: function () {
            //         return this.view
            //     }
            // },

            showMenu: function () {
                return this.createJob?.showPage() || this.jobList?.showPage()
                //  || this.taskList?.showPage() || this.taskRevert?.showPage()
            }
        },

        WorkStation: {
            revertedTask: {
                view: permissionCheck("View", "Module_Reverted_Tasks"),
                showPage: function () {
                    return this.view
                }
            },
            taskAssignment: {
                assign: permissionCheck("Assign", "Module_Task_Assignment"),
                view: permissionCheck("View", "Module_Task_Assignment"),
                showPage: function () {
                    return this.view || this.assign
                }

            },
            taskReassignment: {
                reassign: permissionCheck("Reassignment", "Module_Task_Reassignment"),
                view: permissionCheck("View", "Module_Task_Reassignment"),
                showPage: function () {
                    return this.view || this.reassign
                }
            },
            taskSplit: {
                split: permissionCheck("Split", "Module_Task_Split"),
                view: permissionCheck("View", "Module_Task_Split"),
                showPage: function () {
                    return this.view || this.split
                }
            },
            tasks: {
                pageAccess: permissionCheck("All", "Module_Tasks") || permissionCheck("Tasks_Page_Access", "Module_Tasks"),
                showPage: function () {
                    return this.pageAccess
                }
            },
            taskRevert: {
                approve: permissionCheck("Approve", "Module_Task_Revert"),
                reject: permissionCheck("Reject", "Module_Task_Revert"),
                view: permissionCheck("View", "Module_Task_Revert"),
                showPage: function () {
                    return this.approve || this.reject || this.view
                }
            },
            showMenu: function () {
                return this.revertedTask?.showPage() || this.taskAssignment?.showPage() || this.taskReassignment?.showPage() || this.taskSplit?.showPage() || this.tasks?.showPage() || this.taskRevert?.showPage()
            }

        },
        report: {
            taskReport: {
                view: permissionCheck("View", "Module_Task_Report"),
                download: permissionCheck("Download", "Module_Task_Report"),
                showPage: function () {
                    return this.view || this.download
                }
            },
            taskStatusReport: {
                view: permissionCheck("View", "Module_Task_Status_Report"),
                showPage: function () {
                    return this.view
                }
            },
            jobReport: {
                view: permissionCheck("View", "Module_Job_Report"),
                download: permissionCheck("Download", "Module_Job_Report"),
                showPage: function () {
                    return this.view || this.download
                }
            },
            projectReport: {
                view: permissionCheck("View", "Module_Project_Report"),
                download: permissionCheck("Download", "Module_Project_Report"),
                showPage: function () {
                    return this.view || this.download
                }
            },
            showMenu: function () {
                return this.taskReport?.showPage() || this.jobReport?.showPage() || this.projectReport?.showPage()
            }
        },
        meeting: {
            schedule: {
                schedule: permissionCheck("Schedule", "Module_Schedule_Meeting"),
                view: permissionCheck("View", "Module_Schedule_Meeting"),
                showPage: function () {
                    return this.view || this.schedule
                }
            },
            createHall: {
                create: permissionCheck("Create", "Module_Create_Hall"),
                view: permissionCheck("View", "Module_Create_Hall"),
                edit: permissionCheck("Edit", "Module_Create_Hall"),
                delete: permissionCheck("Delete", "Module_Create_Hall"),
                showPage: function () {
                    return this.create || this.view || this.edit || this.delete
                }
            },
            showMenu: function () {
                return this.schedule?.showPage() || this.createHall?.showPage()
            }
        },
        support:{
            announcements:{
                create: permissionCheck("Create", "Module_Announcement"),
                view: permissionCheck("View", "Module_Announcement"),
                
                showPage: function () {
                    return this.create || this.view 
                }
            },
            queries:{
                create: permissionCheck("Create", "Module_Queries"),
                view: permissionCheck("View", "Module_Queries"),
                showPage: function () {
                    return this.create || this.view 
                }
            },
            showMenu: function () {
                return this.announcements?.showPage() || this.queries?.showPage()
            }
        }

    };
    //   con &&  console.log(t,'jdhfcj')
    return t;
}
export const createUserPermissionData = (userData) => {
    // const userData = store.getState().presist.userprofile


};