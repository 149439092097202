import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React from "react";
import { allpageFontStyle } from "../../utils/FontStyle";
function SelectComponent(props) {
  const {
    isOptionEqualToValue,
    limitTags,
    handelChange,
    multiple,
    disabled,
    filterSelectedOptions,
    DataOptions,
    labelName,
    label,
    autoStyle,
    sx,
    errors,
    renderInput,
    clearIcon,
    value,
    defaultValue,
    renderOption,
    renderOptionData,
    disableCloseOnSelect,
    variant,
    ListboxProps,
    fullWidth,
    onBlur,
    heightSmall,
    zeroInEmpty,
  } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        fullWidth={fullWidth}
        id="size-small-outlined"
        // ref={autoCteam}
        size="small"
        sx={{
          ...autoStyle,
          padding: 0,

          "& .MuiChip-root": {
            ...allpageFontStyle.typography1,

            // backgroundColor: 'lightgray', // Adjust the background color as needed
            color: "black", // Adjust the text color as needed
            // borderRadius: '5px', // Adjust the border radius as needed
            marginRight: "4px", // Adjust the spacing as needed
            padding: 0,
            height: 15,
          },
        }}
        options={DataOptions || []}
        getOptionLabel={labelName}
        value={zeroInEmpty ? 0 : value || null}
        clearIcon={clearIcon}
        defaultValue={defaultValue}
        limitTags={limitTags}
        onBlurCapture={onBlur}
        ListboxProps={ListboxProps}
        disableCloseOnSelect={disableCloseOnSelect}
        multiple={multiple || false}
        isOptionEqualToValue={isOptionEqualToValue}
        filterSelectedOptions={filterSelectedOptions || false}
        disabled={disabled}
        onChange={(e, value) => {
          handelChange(value);
        }}
        renderOption={
          renderOptionData
            ? renderOptionData
            : (e, option) => (
                <Typography
                  {...e}
                  sx={{ ...renderOption, ...allpageFontStyle.typography1 }}
                >
                  {labelName(option)}
                </Typography>
              )
        }
        InputLabelProps={{ shrink: true }}
        renderInput={
          renderInput
            ? renderInput
            : (params) => (
                <TextField
                  {...params}
                  variant={variant || "outlined"}
                  label={label}
                  helperText={errors}
                  error={errors}
                  sx={{
                    ...sx,

                    ".MuiInputBase-input": !heightSmall
                      ? {
                          height: 13,
                          // ...heightSmall && { fontSize: 10 }
                          fontSize: 13,
                        }
                      : {
                          ...heightSmall,
                        },
                    // ".MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                    //   ... heightSmall && {padding: 0}
                    // },
                  }}
                  InputLabelProps={{ shrink: true }}
                  // placeholder="Select Team"
                />
              )
        }
      />
      {/* <Box> <Select
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            onChange={handelChange}
            isDisabled={isDisabled ? isDisabled : false}
            // isLoading={isLoading}
            isMulti={isMulti ? isMulti : false}
            isClearable={isClearable ? isClearable : false}
            isSearchable={isSearchable ? isSearchable : false}
            options={DataOptions ? DataOptions : []}
        />
        </Box>; */}
    </Box>
  );
}

export default SelectComponent;
