import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EyeIcon from "../../components/commonComponents/eyeicon";
import DataGride from "../../components/datagride/DataGride";
import {
  getRevertTaskListAction,
  revertTaskapproveandRejectAction,
} from "../../redux/action/action";
import { formContainerCardStyle, formContainerStyle } from "../../utils/Style";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";

function RevertApprove() {
  const { revertTask } = useSelector((state) => state.master);
  const { autoLoad } = useSelector((state) => state.presist);
  const dispatch = useDispatch();
  const [payload, setpayload] = useState({
    type: "",
    remarks: "",
    rowData: "",
  });
  const [open, setOpen] = useState(false);
  const [revertList, setRevertList] = useState([]);
  // const handleClick = (row, type) => {
  //   setpayload({ ...payload, type: type, rowData: row });
  //   setOpen(true);
  // };
  const constructRevertTask = (arr) => {
    const res =
      arr &&
      arr?.length > 0 &&
      arr.map((val) => ({
        ...val?.revertRequested,
        ...val?.revertedData,
        assignedUserInfo: val.assignedUserInfo,
        createUserInfo: val.createUserInfo,
        updatedUserInfo: val.updatedUserInfo,
      }));
    return res;
  };
  useEffect(() => {
    dispatch(getRevertTaskListAction("get", "/job/task_revert_request/"));
  }, []);
  useEffect(() => {
    if (revertTask) {
      const construct = constructRevertTask(revertTask?.data);
      setRevertList(construct);
    }
  }, [revertTask]);
  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      dispatch(getRevertTaskListAction("get", "/job/task_revert_request/"));
      dispatch(
        updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false } })
      );
    }
  }, [autoLoad]);

  const handleSubmit = async (type) => {
    const paylaodData = {
      taskId: payload?.rowData?.taskId,
      jobCode: payload?.rowData?.jobCode,
      remarks: type === "Approve" ? "Task Revert Approved" : payload?.remarks,
      revertStatus: type === "Approve" ? "Approved" : "Rejected",
    };
    const res = await dispatch(
      revertTaskapproveandRejectAction(
        "patch",
        paylaodData,
        "/job/task_revert_request/"
      )
    );
    if (res) {
      setOpen(false);
      setpayload({ ...payload, remarks: "" });
      dispatch(getRevertTaskListAction("get", "/job/task_revert_request/"));
    }
  };
  const columns = [
    { field: "jobCode", headerName: "Job Id", width: 150 },
    {
      field: "taskId",
      headerName: "Task Id",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taskType",
      headerName: "Task Type",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taskInfo",
      headerName: "Category",
      width: 100,
      align: "left",
      valueGetter: (params) => {
        return `${params.row?.category || ""}`;
      },
      headerAlign: "left",
    },
    {
      field: "measureCount",
      headerName: "Per Unit Count",
      width: 180,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "completedCount",
      headerName: "Completed Count",
      width: 180,
      align: "left",
      headerAlign: "left",
    },
    // { field: "assignedTo", headerName: "Assigned To", width: 150, align: "left", headerAlign: "left", renderCell: (params) => <Box sx={{ overflowWrap: 'break-word', fontFamily: "Lato" }} >{params.row.assignedUserInfo.firstname + " " + params.row.assignedUserInfo.lastname}</Box> },
    {
      field: "workedHours",
      headerName: "Activity Time",
      width: 200,
      valueGetter: (params) =>
        params.row.workHours +
        " :" +
        " " +
        params.row.workMinutes +
        " :" +
        " " +
        params.row.workSeconds,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 270,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    // {  field: "productionHours",headerName: "Est Hours",width: 150,align: "left",headerAlign: "left",valueGetter: (params) => secondsToHMS(Number(params.row.productionHours)),},
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ p: 0 }}
          label="View"
          color="primary"
          icon={
            <Box
              sx={{
                p: 0,
                display: "flex",
                justifyContent: "center",
                width: 10,
              }}
            >
              <EyeIcon
                taskId={params.row.taskId}
                jobcode={params.row.jobCode}
                clickOpen={true}
                clickclose={false}
              />{" "}
            </Box>
          }
        />,
      ],
    },
  ];

  return (
    <Box sx={{ ...formContainerStyle, px: 5, pl: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Card sx={{ ...formContainerCardStyle, p: 4, pl: 2 }}>
          <Box sx={{ m: 1, width: "100%" }}>
            <DataGride
              columns={columns}
              tableData={revertList || []}
              disableCheckbox={true}
              hideDeletion={true}
              customHeight={450} // getRowHeight={() => 'auto'}
            />
          </Box>
        </Card>
      </Box>
      <Dialog sx={{}} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {/* {payload.type === "Approve" ? <Box>Approve</Box> : <Box>Reject</Box>} */}
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          {payload.type === "Approve" ? (
            <Box>{"Are your sure, do you want to approve ?"}</Box>
          ) : (
            <Box sx={{ pt: 1 }}>
              {" "}
              <TextField
                value={payload.remarks}
                label={"Remarks"}
                multiline
                fullWidth
                rows={2}
                onChange={(e) => {
                  setpayload({ ...payload, remarks: e.target.value });
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex" }}>
            {payload.type === "Approve" ? (
              <Box sx={{ pr: 2 }}>
                <Button
                  sx={{ borderRadius: 5 }}
                  // disabled={!payload?.remarks}
                  onClick={() => handleSubmit("Approve")}
                  variant="contained"
                  color="success"
                  size="small"
                >
                  yes
                </Button>
              </Box>
            ) : (
              <Box sx={{ pr: 2 }}>
                <Button
                  sx={{ borderRadius: 5 }}
                  disabled={!payload?.remarks}
                  onClick={() => handleSubmit("Reject")}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Reject
                </Button>
              </Box>
            )}
            <Box>
              <Button
                onClick={() => {
                  setOpen(false);
                  setpayload({ ...payload, remarks: "" });
                }}
                variant="contained"
                color="error"
                size="small"
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RevertApprove;
