import { yupResolver } from "@hookform/resolvers/yup";
import { BorderColor, Done, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";
import {
  designationMasterAction,
  gradeMasterAction,
} from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import {
  formContainerCardStyle,
  formContainerStyle,
  formFieldStyle,
} from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { PopupMessage, checkBoxFilter } from "../../utils/UIUtil";
import PopupComponent from "../../components/commonComponents/PopupComponent";
const schema = yup.object().shape({
  gradeId: yup.string(),
  gradeName: yup.string().required("Name Required"),
  // codeName: yup.string().required("Code Required"),
  desigId: yup.string().required("Designation Required"),
  description: yup.string().nullable(),
});
export default function Grade() {
  const [edit, setEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [employee, setEmployee] = React.useState([]);
  const { gradesaveData, globapOrganization, userProfile } = useSelector(
    (state) => state.presist
  );
  const [isActive, setIsActive] = React.useState(true);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [editpage, setEditPage] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const { designationList, gradeList, gradeAutoId } = useSelector(
    (state) => state.master
  );
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
      reset();
      setEditPage(false);
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClose();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
      setEditPage(false);
      setEdit(false);
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelActiveInactive = async (e) => {
    console.log(e, "isactive");
    dispatch(
      gradeMasterAction(
        "",
        "get",
        `/grade/info/${globapOrganization?.orgId}/${
          e ? "?isActive=True" : "?isActive=False"
        }`
      )
    );
  };
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const constructOrderData = (arr) => {
    const result =
      arr &&
      arr.length > 0 &&
      arr.map((val, i) => ({
        ...val.gradeInfo,
        designationName: val.desigInfo?.desigName,
      }));
    return result;
  };
  React.useEffect(() => {
    // dispatch(getPatientPharmacyNotPaidOrderAction());
    if (gradeList) {
      const employeedata = constructOrderData(gradeList);
      setEmployee(employeedata);
    }
  }, [gradeList]);

  const branchListConstruct = (arr) => {
    const res = arr.map((val) => ({
      createUserInfo: val.createUserInfo,
      updatedUserInfo: val.updatedUserInfo,
      designationName: val.designationInfo?.desigName,
      ...val.gradeInfo,
    }));
    return res;
  };

  React.useEffect(() => {
    if (gradeList && gradeList.length > 0) {
      const constructDatavalue = branchListConstruct(gradeList);
      setEmployee(constructDatavalue);
    }
  }, [gradeList]);
  React.useEffect(() => {
    handleEditClick(gradesaveData, "no");
  }, []);
  React.useEffect(() => {
    handleEditClick(gradesaveData, "no");
  }, [gradeAutoId]);
  React.useEffect(() => {
    return () => {
      dispatch(
        updatePresistStates({ name: "gradesaveData", value: getValues() })
      );
      // dispatch(
      //   updateMasterStates({
      //     name: "gradecodeCheck",
      //     value: "",
      //   })
      // );
    };
  }, []);
  const handelActivetingUser = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          gradeMasterAction(
            { id: [row?.id] },
            "put",
            `/grade/info/${globapOrganization?.orgId}/`
          )
        );
        handelActiveInactive(false);
      }
    });
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          gradeMasterAction(
            { id: row },
            "delete",
            `/grade/info/${globapOrganization?.orgId}/`
          )
        );
        setSelectionState([]);
        dispatch(
          gradeMasterAction(
            "",
            "get",
            `/grade/info/${globapOrganization?.orgId}/${
              isActive ? "?isActive=True" : "?isActive=False"
            }`
          )
        );
      }
    });
  };
  const handleEditClick = (row, data) => {
    if (data === "edit") {
      setEditPage(true);
      setSelectedRow(row);
      setEdit(true);
    }
    setValue(
      "gradeId",
      data === "edit" && row?.gradeI ? row.gradeId : gradeAutoId?.id
    );
    setValue("desigName", row?.desigName);
    setValue("desigId", row?.desigId);
    setValue("gradeName", row?.gradeName);
    // setValue("codeName", row?.codeName);
    setValue("description", row?.description);
  };
  const handelClose = () => {
    // setEditPage(false)
    dispatch(updatePresistStates({ name: "gradesaveData", value: {} }));

    // dispatch(
    //   updateMasterStates({
    //     name: "gradecodeCheck",
    //     value: "",
    //   })
    // );
    setSelectedFile([]);
    setEdit(false);
    reset();
    setValue("desigId", "");
  };
  const columns = [
    // {
    //   field: "designationName",
    //   headerName: "DESIGNATION NAME",
    //   width: 180,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "gradeName",
      headerName: "Grade",
      width: 180,
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "codeName",
    //   headerName: "GRADE CODE",
    //   width: 200,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "designationName",
      headerName: "Designation",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 200,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        allowedPages().settings.grade.edit && isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        !isActive ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Restore" placement="top">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: 10,
                    ml: 2,
                  }}
                >
                  <Done
                    onClick={() => handelActivetingUser(params.row)}
                    sx={{ color: "#358c4e" }}
                  />
                </Box>
              </Tooltip>
            }
            color="primary"
          />
        ) : (
          <></>
        ),
        // <GridActionsCellItem
        //   icon={<Delete />}
        //   label="Delete"
        //   onClick={() => {
        //     handleDeleteClick(params.row);
        //   }}
        //   color="error"
        // />,
      ],
    },
    // { field: "date", headerName: "CREATED DATE", width: 200 },
    // { field: "status", headerName: "STATUS", width: 300, flex: 1 },
  ];

  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      gradeId: "",
      gradeName: "",
      // codeName: "",
      orgId: "ORG00001",
      description: "",
      desigId: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;

  const onSubmit = async (data) => {
    delete data.gradeId;
    // delete data.graCode;
    data.orgId = globapOrganization?.orgId;

    // if (!gradecodeCheck) {
    if (edit) {
      const editData = {
        id: selectedRow.id,
        gradeId: selectedRow.gradeId,
        gradeName: data.gradeName,
        orgId: data.orgId,
        // codename: data.codeName,
        desigName: data.desigName,
        description: data.description,
        createdBy: selectedRow.createdBy,
        updatedBy: userProfile?.useAccess?.userName,
        createdOn: selectedRow.createdOn,
        updatedOn: selectedRow.updatedOn,
      };
      // setEditPage(false)
      await dispatch(
        gradeMasterAction(
          editData,
          "patch",
          `/grade/info/${globapOrganization?.orgId}/`
        )
      );
      dispatch(
        gradeMasterAction(
          "",
          "get",
          `/grade/info/${globapOrganization?.orgId}/${
            isActive ? "?isActive=True" : "?isActive=False"
          }`
        )
      );
      setValue("desigId", "");
      reset();
      dispatch(updatePresistStates({ name: "gradesaveData ", value: {} }));

      setEdit(false);
    } else {
      await dispatch(
        gradeMasterAction(
          data,
          "post",
          `/grade/info/${globapOrganization?.orgId}/`
        )
      );
      dispatch(
        gradeMasterAction(
          "",
          "get",
          `/grade/info/${globapOrganization?.orgId}/${
            isActive ? "?isActive=True" : "?isActive=False"
          }`
        )
      );
      setValue("desigId", "");
      reset();
      dispatch(updatePresistStates({ name: "gradesaveData ", value: {} }));
      // dispatch(
      //   getAutoService(
      //     `/OgaiApp/max/grade/id/${globapOrganization?.orgId}/`,
      //     "gradeAutoId"
      //   )
      // );
    }
  };
  React.useEffect(() => {
    dispatch(
      designationMasterAction(
        "",
        "get",
        `/designation/info/${globapOrganization?.orgId}/?isActive=True`
      )
    );
    dispatch(
      gradeMasterAction(
        "",
        "get",
        `/grade/info/${globapOrganization?.orgId}/${
          isActive ? "?isActive=True" : "?isActive=False"
        }`
      )
    );
  }, [globapOrganization]);
  // const handelCodeChange = async (e) => {
  //   setValue("codeName", e.target.value.toUpperCase());

  //   if (e.target.value.length > 2) {
  //     try {
  //       // dispatch(loading(true))
  //       await dispatch(
  //         codeCheckService(
  //           { codeName: e.target.value },
  //           `/grade/code/name/${globapOrganization?.orgId}/`,
  //           "gradecodeCheck"
  //         )
  //       )
  //         .then((response) => {
  //           // dispatch(loading(false))

  //           if (response.data) {
  //             dispatch(
  //               updateMasterStates({
  //                 name: "gradecodeCheck",
  //                 value: response.data,
  //               })
  //             );
  //           } else {
  //             dispatch(
  //               updateMasterStates({
  //                 name: "gradecodeCheck",
  //                 value: "",
  //               })
  //             );
  //           }
  //         })
  //     } catch (error) {
  //       // dispatch(loading(false))
  //     }

  //   }
  // };

  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formContainerStyle}>
          <Box sx={{ width: "100%" }}>
            <Card sx={formContainerCardStyle}>
              {/* <Box>
              <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                <Save
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={saveData}
                />
              </Box>
            </Box> */}
              {!editpage ? (
                <Box
                  sx={{
                    pt: 5,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h4 style={allpageFontStyle.title}>LIST OF GRADE</h4>
                    {allowedPages().settings.grade.create && (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          setEditPage(true);
                          reset();
                        }}
                        sx={{
                          ...allpageFontStyle.addButtonFont.buttonHeight,

                          ...allpageFontStyle.buttonFontStyle1,
                          fontWeight: "bold",
                          // backgroundColor: "#43a5fc",
                          borderRadius: 5,
                          color: "#358c4e",
                          p: 0,
                          px: 2,
                          py: 0,
                          ":hover": {
                            backgroundColor: "#358c4e",
                            color: "white",
                            borderColor: "#358c4e",
                          },
                          textTransform: "capitalize",
                          borderColor: "#358c4e",
                        }}
                      >
                        <AddIcon
                          sx={{
                            mr: 0.5,
                            ...allpageFontStyle.addButtonFont.font,
                            p: 0,
                          }}
                        />
                        <Typography
                          sx={{ ...allpageFontStyle.addButtonFont.font }}
                        >
                          Create Grade
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      pl: 5,
                      pr: 5,
                      mb: 5,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <DataGride
                      columns={columns}
                      customHeight={450}
                      selectionIs={selectionIs}
                      selectionState={selectionState}
                      selectedFile={selectedFile}
                      deleteIcon={isActive ? true : false}
                      onSelectionModelChange={onSelectionModelChange}
                      deleteFuntion={handleDeleteClick}
                      tableData={employee || []}
                      hideDeletion={!allowedPages().settings.grade.delete}
                      InActiveFilterCheckBox={() =>
                        checkBoxFilter(
                          isActive,
                          setIsActive,
                          "Active",
                          "Inactive",
                          handelActiveInactive
                        )
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // px: 5,
                    }}
                  >
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            fontSize: 20,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Edit
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            fontFamily: "lato",
                            fontWeight: "bold",
                            m: 2,
                            fontSize: 20,
                          }}
                        >
                          <Typography
                            sx={{ ...allpageFontStyle.title }}
                            textAlign={"center"}
                          >
                            Add
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ flex: 0.1 }}>
                      {edit ? (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.editType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            setAlertPopup((per) => ({
                              ...per,
                              open: true,
                              type: PopupMessage.listType,
                              title: PopupMessage.back,
                            }));
                          }}
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#3378e8",
                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          List
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{ pt: 5, display: "flex", justifyContent: "center" }}
                    >
                      <Grid container spacing={2} sx={{ width: "70%" }}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="gradeName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "70%",
                                }}
                                label="Name*"
                                size="small"
                                helperText={
                                  errors.gradeName
                                    ? errors?.gradeName?.message
                                    : null
                                }
                                error={errors?.gradeName?.message}
                                onChange={(e) => {
                                  trigger("gradeName");
                                  onChange(e);
                                }}
                                onBlur={() => trigger("gradeName")}
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Name" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                          <Controller
                            control={control}
                            name="codeName"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                label='Code*'
                                size="small"
                                helperText={errors?.codeName ? errors.codeName.message : gradecodeCheck ? "Code Already In Use" : null}
                                error={errors?.codeName || gradecodeCheck}
                                sx={{
                                  width: '70%'
                                }}
                                onChange={(e) => {
                                  onChange(e);
                                  trigger('codeName');
                                  handelCodeChange(e);
                                }}
                                onBlur={() => trigger('codeName')}
                                value={value}
                                inputRef={ref}
                                inputProps={{ "aria-label": "Code" }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />


                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Controller
                            control={control}
                            name="desigId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "70%",
                                }}
                              >
                                <FormControl
                                  error={errors.desigId}
                                  size="small"
                                  fullWidth
                                >
                                  <InputLabel shrink>Designation*</InputLabel>
                                  <Select
                                    notched
                                    labelId="demo-simple-select-error-label"
                                    id="demo-simple-select-error"
                                    MenuProps={{
                                      PaperProps: { sx: { maxHeight: 150 } },
                                    }}
                                    sx={{
                                      width: "100%",
                                      borderRadius: 2,
                                    }}
                                    label="Description*"
                                    onChange={(e) => {
                                      trigger("desigId");
                                      onChange(e);
                                    }}
                                    onBlur={() => trigger("desigId")}
                                    value={value}
                                    inputRef={ref}
                                    inputProps={{
                                      "aria-label": "Project Code",
                                    }}
                                  >
                                    {/* <MenuItem
                                      sx={{
                                        borderBottom: "1px solid white",
                                        fontFamily: "Lato",
                                        fontSize: 13,
                                      }}
                                      value={""}
                                    >
                                      Select Designation
                                    </MenuItem> */}
                                    {designationList.map((val) => (
                                      <MenuItem
                                        sx={{
                                          borderBottom: "1px solid white",
                                          fontFamily: "Lato",
                                          fontSize: 13,
                                        }}
                                        value={val.designationInfo?.desigId}
                                      >
                                        {val.designationInfo?.desigName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText sx={{ color: "red" }}>
                                    {errors.desigId
                                      ? errors.desigId.message
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Controller
                            control={control}
                            name="description"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{
                                  width: "70%",
                                }}
                                multiline
                                label="Description"
                                helperText={
                                  <Box sx={{ color: "gray", fontSize: 10 }}>
                                    {value === ""
                                      ? "Max 500 characters"
                                      : 500 -
                                        value.length +
                                        " " +
                                        "Characters remaining"}
                                  </Box>
                                }
                                rows={3}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                inputProps={{
                                  "aria-label": "description",
                                  maxLength: 500,
                                }}
                                InputProps={formFieldStyle.InputProps}
                                InputLabelProps={formFieldStyle.InputLabelProps}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      sx={{
                        color: "white",
                        width: 90,
                        m: 5,
                        backgroundColor: "#8bb679",
                        borderRadius: 5,
                      }}
                    >
                      ADD
                    </Button>
                  </Box> */}
                    </Box>
                  </Grid>
                  {edit ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        type="submit"
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          backgroundColor: "#258d4d",

                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        UPDATE
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 3,
                        mt: 3,
                      }}
                    >
                      {allowedPages().settings.grade.create ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          type="submit"
                          sx={{
                            color: "white",
                            width: 90,
                            m: { xs: 1, md: 1, lg: 2 },
                            ...allpageFontStyle.buttonFontStyle2,
                            backgroundColor: "#258d4d",

                            borderRadius: 5,
                            ":hover": {
                              boxShadow:
                                "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setAlertPopup((per) => ({
                            ...per,
                            open: true,
                            type: PopupMessage.resetType,
                            title: PopupMessage.reset,
                          }));
                        }}
                        sx={{
                          color: "white",
                          width: 90,
                          m: { xs: 1, md: 1, lg: 2 },
                          ...allpageFontStyle.buttonFontStyle2,
                          borderRadius: 5,
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                        }}
                      >
                        {RESET_LABEL}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {/* <Box sx={{ pt: 4, display: "flex" }}>
                {" "}
                <Divider
                  style={{ backgroundColor: "black" }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              </Box> */}
            </Card>
          </Box>
        </Box>
      </form>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </FormProvider>
  );
}
