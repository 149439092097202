export const box = {
  width: "100%",
  display: "flex",
  pb: 1.5,
  alignItems: 'center'
};
export const headings = {
  width: "20%",
  fontSize: "0.8rem",
  fontWeight: "bold",
  fontFamily: "Lato",
  display: 'flex', justifyContent: 'center'
};
export const contents = {
  width: "80%",
  fontFamily: "Lato",
  display: 'flex',
  justifyContent: 'center'

};