import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { createandEditRole } from '../../service/roleService';
import { formContainerCardStyle, formContainerStyle, formFieldStyle } from '../../utils/Style';
import { TriggerAlert } from '../../utils/UIUtil';
import { allowedPages } from '../../utils/permissions';
import "./role.css";
const schema = yup.object().shape({
    roleName: yup.string().required("Name Required"),
    // roleDes: yup.string().required("Role Description Required"),
});
function CreateRole() {
    const { state, pathname } = useLocation();
    const nav = useNavigate();
    const path = (pathname === "/EditRole")
    const [routData, setroutData] = useState(state || null)
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = useState()
    const form = useForm({
        defaultValues: {
            roleName: "",
            projectCode: "",
            roleDes: "",
        },
        resolver: yupResolver(schema),
    });
    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        reset,
        trigger,
        formState: { errors },
    } = form;
    useEffect(() => {
        const setEditValue = () => {
            if (routData) {
                setEdit(true)
                setValue("roleName", routData.name)
                // setValue("roleDes", routData.roleDes)
            } else {
                handelclose()
            }
        }
        setEditValue()
        return () => path && handelclose()
    }, [pathname])

    const onSubmit = async (data) => {
        if (edit) {
            const payload = {
                id: state?.id,
                name: data.roleName
            }
            const res = await createandEditRole("patch", payload)
            if (res) {
                TriggerAlert("success", res.data.message)
                handelclose()
            }
        } else {
            const payload = {
                name: data.roleName
            }
            const res = await createandEditRole("post", payload)
            if (res) {
                TriggerAlert("success", res.data.message)
                handelclose()
            }
        }
    }
    const handelclose = () => {

        reset()
        if (edit && allowedPages().role.creatRole.create) {
            nav('/CreateRole')
        }
        setroutData(null)
        setEdit(false)

    }

    return (
        <Box flex={1} sx={{ background: grey[100], minWidth: 800 }} p={2}>
            <FormProvider {...form}>
                <form style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={formContainerStyle}>
                        <Box sx={{ width: '50%' }}>
                            <Card sx={formContainerCardStyle} >
                                <Box sx={{ display: "flex", alignContent: "center", textAlign: 'center', justifyContent: 'center', gap: 2 }} p={1}>
                                    <Box sx={{ mt: 4, width: "100%" }} >
                                        <Controller
                                            control={control}
                                            name="roleName"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <TextField
                                                    sx={{ textTransform: 'capitalize', width: '70%' }}
                                                    fullWidth
                                                    size="small"
                                                    label="Role Name*"
                                                    helperText={
                                                        errors.roleName
                                                            ? errors?.roleName?.message
                                                            : null
                                                    }
                                                    error={errors?.roleName?.message}
                                                    onChange={(e) => {

                                                        trigger("roleName");
                                                        onChange(e.target.value.toUpperCase());
                                                    }}
                                                    onBlur={() => trigger("roleName")}
                                                    value={value}
                                                    inputRef={ref}
                                                    // InputLabelProps={{ shrink: true }}
                                                    inputProps={{ "aria-label": "Name" }}
                                                    InputProps={formFieldStyle.InputProps}
                                                    InputLabelProps={formFieldStyle.InputLabelProps}
                                                />
                                            )}
                                        />
                                    </Box>
                                    {/* <Box >
                                    <Controller
                                        control={control}
                                        name="roleDes"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextField
                                                multiline
                                                sx={{ width: { lg: "40%", md: "40%", sm: "60%", xs: '100%' } }}
                                                rows={3}
                                                size="small"
                                                label="Role Description*"
                                                helperText={
                                                    errors.roleDes
                                                        ? errors?.roleDes?.message
                                                        : "Max 500 characters"
                                                }
                                                error={errors?.roleDes?.message}
                                                onChange={(e) => {
                                                    trigger("roleDes");
                                                    onChange(e);
                                                }}
                                                onBlur={() => trigger("roleDes")}
                                                value={value}
                                                inputRef={ref}
                                                // InputLabelProps={{ shrink: true }}
                                                inputProps={{ "aria-label": "Name", maxLength: 500 }}
                                            />
                                        )}
                                    />
                                </Box> */}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, gap: 2, mb: 5 }}>
                                    {/* {edit && */}
                                    {/* } */}
                                    <Box>
                                        {edit ?
                                            <Button size="small" sx={{ borderRadius: 5, fontSize: 12, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }} variant='contained' color='success' type='submit'>{"Update"}</Button>
                                            :
                                            allowedPages().role.creatRole.create ?
                                                <Button size="small" sx={{ borderRadius: 5, fontSize: 12, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }} variant='contained' color='success' type='submit'>{"Submit"}</Button>
                                                : null
                                        }
                                    </Box>
                                    <Box><Button size="small" sx={{ borderRadius: 5, fontSize: 12, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }} variant='contained' color='error' onClick={handelclose}>Reset</Button></Box>
                                    <Box><Button size="small" sx={{ borderRadius: 5, fontSize: 12, ':hover': { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 25px 50px -12px' } }} variant='contained' onClick={() => nav("/RoleList")}>View List</Button></Box>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </form>
            </FormProvider>
        </Box>
    )
}

export default CreateRole