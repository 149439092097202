import React, { useState } from "react";
// import { updateProfileStates } from "../redux/reducers/profileReducer";
import { Avatar, Badge, Box, Button, Card, Grid, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { profileInfoAction } from "../../redux/action/action";
import { CameraAlt, Delete } from "@mui/icons-material";
import { allpageFontStyle, sweetalertWidth } from "../../utils/FontStyle";
import { ProfileUploadService } from "../../service/employeeService";
import { profilePageColors } from "../../utils/CommonColors";
import Swal from "sweetalert2";
const title = { fontFamily: 'lato', fontSize: allpageFontStyle.typography3, pt: 1, color: 'grey' }
const content = { fontFamily: 'lato', fontSize: allpageFontStyle.typography3, pt: 1, }

export default function ProfileShow() {
    const { profileData } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [imagedelet, setImagedelet] = useState(false)
    const profileUpload = async (e, type) => {
        const formData = new FormData();
        if (type === "delete") {
            formData.append('userImage', "")
        } else {
            const file = e.target.files[0];
            formData.append('userImage', file)
        }
        const res = await ProfileUploadService(formData)
        if (res) {
            console.log(res, "res")
            dispatch(
                profileInfoAction()
            );
        }
    }
    const handeledeletImage = (e, type) => {
        if (type === 'out') {
            setImagedelet(false)
        } else {
            if (profileData?.userDetails?.userImage?.fileName === "default.png") {
                setImagedelet(false)
            } else {
                setImagedelet(true)
            }
        }
    }
    const alertpopup = () => {
        Swal.fire({
            text: "Are you sure ?",
            icon: "warning",
            width: sweetalertWidth,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then(async (res) => {
            if (res.value) {
                profileUpload("h", "delete")
            }
        });
    }
    // React.useEffect =() => {
    // dispatch(
    //     profileInfo(globapOrganization?.orgId)
    //   ) }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const data = profileData?.userDetails?.userImage?.userBase64Image
    React.useEffect(() => {
        dispatch(
            profileInfoAction()
        );
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ width: { lg: '50%', md: "70%", sm: "80%", xs: '100%' }, p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box onMouseEnter={(e) => handeledeletImage(e)} onMouseLeave={(e) => handeledeletImage(e, "out")} sx={{
                        display: 'flex', p: 2, width: { xs: "90%", sm: "90%", md: '90%', lg: "50%" }, justifyContent: 'center',
                    }}>
                        <Box sx={{ pr: 3 }}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Button component="label" sx={{
                                        color: profilePageColors.iconColor
                                    }} startIcon={
                                        <Box sx={{ backgroundColor: profilePageColors.iconBack, m: 0, p: 0.5, lineHeight: 0, borderRadius: "50%" }}>
                                            <CameraAlt />
                                        </Box>
                                    }>
                                        <VisuallyHiddenInput onChange={profileUpload} accept="image/*" type="file" />
                                    </Button>
                                }
                            >
                                <Avatar sx={{ height: 120, width: 120 }} alt={profileData?.userDetails?.userInfo?.firstname} src={`data:image/jpeg;base64,${data}`} />
                            </Badge>
                        </Box>
                        <Box sx={{ width: 10 }}> {imagedelet ? <Delete onClick={() => alertpopup()} sx={{ cursor: 'pointer' }} /> : null}</Box>
                    </Box>
                </Box >
                <Box sx={{ display: 'flex', justifyContent: 'center', pr: 3 }}>
                    <h2 style={{ ...allpageFontStyle.title1 }}>Personal & Professional Details</h2>
                </Box>
                <Box sx={{}}>
                    <Box sx={{}}>
                        <Box container spacing={2} sx={{ display: 'flex', justifyContent: 'center', gap: 6 }}>
                            <Box item xs={4} sm={4} md={2.5} lg={3} >
                                <Box sx={title}>Employee ID</Box>
                                <Box sx={title}>Name</Box>
                                <Box sx={title}>Email</Box>
                                <Box sx={title}>Mobile</Box>
                                <Box sx={title}>Extension No</Box>
                                <Box sx={title}>Gender</Box>
                                <Box sx={title}>DOB</Box>
                                <Box sx={title}>Organization</Box>
                                <Box sx={title}>Department</Box>
                                <Box sx={title}>Designation</Box>
                                <Box sx={title}>Grade</Box>
                            </Box>
                            <Box item xs={2} sm={2.5} md={1.5} lg={1}>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                                <Box sx={title}>:</Box>
                            </Box>
                            <Box item xs={5} sm={3} md={6} lg={8} >
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.empId || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.firstname + "  " + profileData?.userDetails?.userInfo?.lastname}</Box>
                                <Box sx={{ ...content }}>{profileData?.userDetails?.userInfo?.emailAddress || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.mobileNumber || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.extensionNo || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.gender || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.userInfo?.DOB || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.orgInfo?.orgName || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.departmentInfo?.depName || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.designationInfo?.desigName || "NA"}</Box>
                                <Box sx={content}>{profileData?.userDetails?.gradeInfo?.gradeName || "NA"}</Box>
                            </Box>
                        </Box>
                        {/* <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={2.5} lg={4}>
                        </Grid>
                        <Grid item xs={2} sm={2.5} md={1.5} lg={1}>
                            <Box sx={title}>:</Box>
                            <Box sx={title}>:</Box>
                            <Box sx={title}>:</Box>
                            <Box sx={title}>:</Box>
                            <Box sx={title}>:</Box>
                            <Box sx={title}>:</Box>
                        </Grid>
                        <Grid item xs={6} sm={3} md={4} lg={7} >

                        </Grid>
                    </Grid> */}
                    </Box>
                </Box>

            </Card >
        </Box>

    )
}