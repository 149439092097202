export const mappeduserConsrtucdata = (arr) => {
    const res =
        arr &&
        arr.length > 0 &&
        arr.map((val) => ({
            emailAddress: val?.emailAddress,
            empId: val?.empId,
            firstname: val?.firstname,
            id: val?.id,
            lastname: val?.lastname,
            username: val?.username,
        }));
    return res;
};
export const mappedPermissionConsrtucdata = (arr) => {
    const res =
        arr &&
        arr.length > 0 &&
        arr.map((val) => ({
            codename: val?.codename,
            content_type: val?.content_type,
            id: val?.permissionId,
            name: val?.permissionName,
        }));
    return res;
};
