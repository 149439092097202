import { Add, Edit, Link } from "@mui/icons-material";
import { Box, Button, Card, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataGride from "../../components/datagride/DataGride";
import { createandEditRole, getRoleMappingDetails } from "../../service/roleService";
import { allowedPages } from "../../utils/permissions";
import { mappeduserConsrtucdata } from "../rolemapping/Mappingdata";

function RoleList() {
  const nav = useNavigate();
  const { globapOrganization } = useSelector((state) => state.presist);
  const [roleArray, setRoleArray] = useState([])
  const handleEditClick = (row) => {
    nav("/EditRole", { state: row });
  };
  const handelredirect = async (row) => {
    try {
      const res = await getRoleMappingDetails("post", { groupId: row?.id, orgId: globapOrganization?.orgId })
      if (res) {
        const mappedUsers = res?.data?.data
        const userList = mappeduserConsrtucdata(mappedUsers)

        nav("/Role/userMapping", {
          state: {
            name: row,
            user: userList || []
          }
        });
      }
    } catch (error) {

    }
  };
  const columns = [
    {
      field: "id",
      headerName: "Role Id",
      width: 130,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Role Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      align: "left",
      headerAlign: "left",
      width: 50,
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {allowedPages().role.roleList.edit && <Box onClick={() => { handleEditClick(params.row); }}>
            <Tooltip title="Edit" placement="right">
              <Edit sx={{ cursor: "pointer", color: "#358c4e" }} color="primary" />
            </Tooltip>
          </Box>}
          {allowedPages().role.roleAllocation.roleAllocation && <Box onClick={() => { handelredirect(params.row); }}>
            <Tooltip title="Mapping Edit" placement="right">
              <Link sx={{ cursor: "pointer" }} color="secondary" />
            </Tooltip>
          </Box>}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const getroleList = async () => {
      try {
        const res = await createandEditRole("get", {})
        if (res) {
          setRoleArray(res?.data?.data)
        } else {
          setRoleArray([])
        }
      } catch (error) {
      }
    }
    getroleList()
    return () => {

    }
  }, [])

  return (
    <Box sx={{ height: "100%", minHeight: 600, background: grey[100] }} p={3}>
      <Card sx={{ p: 3, boxShadow: 'none', borderRadius: 5 }}>
        <Box sx={{ fontFamily: "Lato", display: "flex", justifyContent: "space-between", }}>
          <Box>   <h4>LIST OF ROLE</h4></Box>
          {allowedPages().role.creatRole.create && <Box sx={{ pt: 1.5 }}>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                nav("/CreateRole")
              }}
              sx={{
                height: 40,

                fontSize: 12,
                fontWeight: "bold",
                // backgroundColor: "#43a5fc",
                borderRadius: 5,
                color: "#358c4e",
                p: 0,
                px: 2,
                py: 0,
                ':hover': {
                  backgroundColor: "#358c4e",
                  color: "white", borderColor: '#358c4e'
                },
                textTransform: "capitalize",
                borderColor: '#358c4e',
                fontWeight: 'bold'
              }}
            >
              <Add sx={{ mr: 0.5 }} />
              Create Role
            </Button>
          </Box>}


        </Box>
        <DataGride
          columns={columns}
          customHeight={450}
          hideDeletion={true}
          disableCheckbox={true}
          tableData={roleArray}
        />
      </Card>
    </Box>
  );
}

export default RoleList;
