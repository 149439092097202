import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import { Box, TextField, Typography } from '@mui/material';
import { giveCount, sendSplitCompletedCountRequest } from '../../service/taskService';
import { TriggerAlert } from '../../utils/UIUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SplitConfirmationPopUp({ onClick, onClose }) {
    const [open, setOpen] = React.useState(false);
    const { splitUpdate } = useSelector(state => state.presist)
    const [count, setCount] = React.useState('')
    const [error, setError] = React.useState(false)
    const handleConfirm = async () => {
        try {
            if ((count && count < splitUpdate?.measureCount) || count === 0) {
                const payload = {
                    taskId: splitUpdate?.taskId,
                    jobCode: splitUpdate?.jobCode,
                    completeCount: count,
                    status: splitUpdate?.status,
                    state: splitUpdate?.state,
                    workHours: splitUpdate?.workHours,
                    workMinutes: splitUpdate?.workMinutes,
                    workSeconds: splitUpdate?.workSeconds,
                }
                giveCount(payload).then(res => {
                    handleClose()
                    if (res?.data) {
                        TriggerAlert('success', res?.data?.message)
                        const payload = {
                            projectCode: splitUpdate?.projectCode,
                            jobCode: splitUpdate?.jobCode,
                            taskId: splitUpdate?.taskId,
                            assignedTo: splitUpdate?.requestedBy,
                            request_type: 'TASK_SPLIT_REQUEST_CONFIRMED'
                        }
                        sendSplitCompletedCountRequest(payload).then(res => {
                        })
                    }
                });
            } else setError(true)
        } catch (error) {
            handleClose()

        }


    }
    const handleClose = () => {
        setOpen(false);
        onClose();
        setCount('');
        setError(false)
    };
    React.useEffect(() => {
        if (splitUpdate) setCount(splitUpdate?.completedCount)
    }, [splitUpdate])
    return (
        <div>
            <Dialog
                open={Boolean(splitUpdate?.type === 'TASK_SPLIT_REQUEST')}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ fontWeight: 'bold', fontSize: 13, display: 'flex', justifyContent: 'center' }}>Confirm Completed Count</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 11, mb: 2 }}><b>Task Id</b> : {splitUpdate?.taskId}</Typography>
                        <Typography sx={{ fontSize: 11, mb: 2 }}><b>Project Code</b> : {splitUpdate?.projectCode}</Typography>
                        <Typography sx={{ fontSize: 11, mb: 2 }}><b>Job Code</b> : {splitUpdate?.jobCode}</Typography>
                        <Typography sx={{ fontSize: 11, mb: 2 }}><b>Total {splitUpdate?.unitType}</b> : {splitUpdate?.measureCount}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <TextField
                            type='number'
                            error={error}
                            size='small'
                            label={`Completed ${splitUpdate?.unitType || ''}`}
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            helperText={error ? `Total ${splitUpdate?.unitType} mismatch` : ''}
                            mb={2}
                            value={count}
                            onChange={(e) => {
                                setCount(e.target?.value)
                                if (e.target?.value < splitUpdate?.measureCount) setError(false)
                            }}
                        />
                        <Box mt={2} sx={{ width: '100%' }}>
                            <Button disabled={count === ''} fullWidth variant='contained' onClick={handleConfirm}>Update</Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}