import { Box, Card, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleChart from "../../components/commonComponents/GoogleChart";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import {
  getDashBoarddCountAction,
  getProjectListinDashboardAction,
} from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { getDashboardGraphdata } from "../../service/dashboardService";
import { DashboardCard } from "../../utils/Imageimport";
import { LabelData, selectOptions } from "./CardcountDetaile";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { ADMIN_DASHBOARD_COLORS } from "../../utils/CommonColors";
import { allowedPages } from "../../utils/permissions";
import { allpageFontStyle } from "../../utils/FontStyle";
// import ChartDownload from "../../components/commonComponents/chartDownload";

function Dashboard(props) {
  const ref = useRef(false);
  const ref1 = useRef(false);
  // const projectRef = useRef()
  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );
  const [filterData, setFilterData] = useState({
    projectInfo: "",
    jobInfo: "",
    categoryInfo: "",
    tasktypeInfo: "",
    userInfo: [],
    state: "",
    status: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [selectOptionData, setselectOptionData] = useState();
  const [chartFilter, setChartFilter] = useState({
    job: "",
    task: "",
    taskRevert: "",
    project: "",
  });
  const [chartData, setChartData] = useState({
    job: "",
    task: "",
    taskRevert: "",
    project: "",
  });
  const { projectListDashboard, dashboardData } = useSelector(
    (state) => state.master
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const handelClearAll = () => {};
  const container = { p: 1 };
  const cardStyle = {
    fontFamily: "Lato",
    cursor: "pointer",
    color: "whitesmoke",
    height: 130,
  };
  const contentStyle = { float: null };
  const labelcardinsideContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor:'red',
    alignContent: "center",
    px: 1,
    py: 1,
  };
  const iconStyle = { fontSize: 20, mx: 1, my: 0.2 };
  const handelFilter = async (e, type) => {
    try {
      const orgId = globapOrganization?.orgId;
      if (type === "job") {
        if (e) {
          const payload = {
            projectCode: filterData.projectInfo,
            selectType: "viewJob",
            viewRange: e?.value,
          };
          setChartFilter({ ...chartFilter, job: e });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, job: res });
        } else {
          const payload = {
            projectCode: filterData.projectInfo,
            selectType: "viewJob",
            viewRange: selectOptions[0]?.value,
          };
          setChartFilter({ ...chartFilter, job: selectOptions[0] });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, job: res });
        }
      }
      if (type === "project") {
        if (e) {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: e?.value,
            selectType: "viewProject",
          };
          setChartFilter({ ...chartFilter, project: e });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, project: res });
        } else {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: selectOptions[0]?.value,
            selectType: "viewProject",
          };
          setChartFilter({ ...chartFilter, project: selectOptions[0] });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, project: res });
        }
      }
      if (type === "task") {
        if (e) {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: e?.value,
            selectType: "viewTasks",
          };
          setChartFilter({ ...chartFilter, task: e });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, task: res });
        } else {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: selectOptions[0]?.value,
            selectType: "viewTasks",
          };
          setChartFilter({ ...chartFilter, task: selectOptions[0] });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, task: res });
        }
      }
      if (type === "revert") {
        if (e) {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: e?.value,
            selectType: "viewTaskRevertRequest",
          };
          setChartFilter({ ...chartFilter, taskRevert: e });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, taskRevert: res });
        } else {
          const payload = {
            projectCode: filterData.projectInfo,
            viewRange: selectOptions[0]?.value,
            selectType: "viewTaskRevertRequest",
          };
          setChartFilter({ ...chartFilter, taskRevert: selectOptions[0] });
          const res = await getDashboardGraphdata(orgId, payload);
          setChartData({ ...chartData, taskRevert: res });
        }
      }
    } catch (error) {}
  };
  const handelSelect = (e, type) => {
    if (type === "Project") {
      if (e) {
        setFilterData({ ...filterData, projectInfo: e });
        dispatch(updateMasterStates({ name: "dashboardData", value: {} }));
        dispatch(
          getDashBoarddCountAction(
            { projectCode: e, jobChart: "1 Year" },
            globapOrganization?.orgId
          )
        );
        loadData(e);
      } else {
        setFilterData({ ...filterData, projectInfo: "" });
      }
    }
  };
  const construtProjectdata = (arr) => {
    const res = arr.map((val) => val?.projectCode);
    return ["All", ...res];
  };
  useEffect(() => {
    if (projectListDashboard && projectListDashboard.length > 0) {
      const project = construtProjectdata(projectListDashboard);
      setselectOptionData(project);
    } else {
      setselectOptionData(["All"]);
      dispatch(updateMasterStates({ name: "dashboardData", value: {} }));
      // dispatch(getDashBoarddCountAction({ projectCode: "All", jobChart: "1 Year" }, globapOrganization?.orgId))
    }
  }, [projectListDashboard]);
  useEffect(() => {
    if (
      selectOptionData &&
      selectOptionData.length > 0 &&
      globapOrganization?.orgId
    ) {
      setFilterData({ ...filterData, projectInfo: selectOptionData[0] });
      if (!ref.current) {
        dispatch(
          getDashBoarddCountAction(
            { projectCode: "All", jobChart: "1 Year" },
            globapOrganization?.orgId
          )
        );
        ref.current = true;
      }
    }
  }, [selectOptionData]);
  const loadData = async (e) => {
    try {
      const orgId = globapOrganization?.orgId;

      const payloadTask = {
        projectCode: e ? e : "All",
        viewRange: selectOptions[0]?.value,
        selectType: "viewTasks",
      };
      const payloadTaskRevert = {
        projectCode: e ? e : "All",
        viewRange: selectOptions[0]?.value,
        selectType: "viewTaskRevertRequest",
      };
      const payloadJob = {
        projectCode: e ? e : "All",
        viewRange: selectOptions[0]?.value,
        selectType: "viewJob",
      };
      const payloadProject = {
        projectCode: e ? e : "All",
        viewRange: selectOptions[0]?.value,
        selectType: "viewProject",
      };
      setChartFilter({
        ...chartFilter,
        job: selectOptions[0],
        task: selectOptions[0],
        taskRevert: selectOptions[0],
        project: selectOptions[0],
      });
      const project = await getDashboardGraphdata(orgId, payloadProject);
      const job = await getDashboardGraphdata(orgId, payloadJob);
      const task = await getDashboardGraphdata(orgId, payloadTask);
      const taskrevert = await getDashboardGraphdata(orgId, payloadTaskRevert);
      setChartData({
        ...chartData,
        job: job,
        task: task,
        taskRevert: taskrevert,
        project: project,
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (globapOrganization?.orgId) {
      dispatch(getProjectListinDashboardAction(globapOrganization?.orgId));
    }
    handelClearAll();
  }, [globapOrganization]);

  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      if (globapOrganization?.orgId) {
        dispatch(
          getDashBoarddCountAction(
            {
              projectCode: filterData.projectInfo
                ? filterData.projectInfo
                : "All",
              jobChart: "1 Year",
            },
            globapOrganization?.orgId
          )
        );
        loadData(filterData.projectInfo);
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: { isAutoLoad: false },
          })
        );
      }
    }
  }, [autoLoad]);
  const data1 = [
    ["Month", "Total Project", "Open Project", "Closed Project"],
    ["1-Week", 0, 0, 0],
    ["2-Week", 0, 0, 0],
    ["3-Week", 0, 0, 0],
    ["4-Week", 0, 0, 0],
  ];
  const data2 = [
    ["Month", "Total Job", "Open Job", "Closed Job"],
    ["1-Week", 0, 0, 0],
    ["2-Week", 0, 0, 0],
    ["3-Week", 0, 0, 0],
    ["4-Week", 0, 0, 0],
  ];
  const data3 = [
    ["Month", "Total Un-Assigned", "Total Assigned", "Total Pending"],
    ["1-Week", 0, 0, 0],
    ["2-Week", 0, 0, 0],
    ["3-Week", 0, 0, 0],
    ["4-Week", 0, 0, 0],
  ];
  const data4 = [
    ["Month", "Total Request", "Total Approval", "Total Rejected"],
    ["1-Week", 0, 0, 0],
    ["2-Week", 0, 0, 0],
    ["3-Week", 0, 0, 0],
    ["4-Week", 0, 0, 0],
  ];
  const optiontaskChart = {
    // title: "Project",
    curveType: "function",
    backgroundColor: grey[100],
    vAxis: { viewWindow: { min: 0 } },
    series: [{ color: "#E7711B" }, { color: "#00E9D0" }, { color: "#6284FF" }],
    legend: { position: "bottom" },
  };
  const optionsJopchart = {
    // title: "Job",
    curveType: "function",
    backgroundColor: grey[100],
    vAxis: { viewWindow: { min: 0 } },
    series: [{ color: "#E7711B" }, { color: "#00E9D0" }, { color: "#6284FF" }],
    legend: { position: "bottom" },
  };
  const optionstaskAssChart = {
    // title: "Task",
    curveType: "function",
    backgroundColor: grey[100],
    vAxis: { viewWindow: { min: 0 } },
    series: [{ color: "#E7711B" }, { color: "#00E9D0" }, { color: "#6284FF" }],
    legend: { position: "bottom" },
  };
  const optionstaskWorkChart = {
    // title: "Task Revert",
    curveType: "function",
    backgroundColor: grey[100],
    vAxis: { viewWindow: { min: 0 } },
    series: [{ color: "#E7711B" }, { color: "#00E9D0" }, { color: "#6284FF" }],
    legend: { position: "bottom" },
  };
  const tittleStyle = {
    textAlign: "center",
    flex: 1,
  };
  const tittleFontStyle = {
    ...allpageFontStyle.title,
  };
  const ChartEmtyBoxstyle = {
    flex: { lg: 1, sm: 1, md: 1 },
  };
  const titleOption = {
    project: (
      <Box sx={tittleStyle}>
        <Typography sx={tittleFontStyle}>Project</Typography>
      </Box>
    ),
    job: (
      <Box sx={tittleStyle}>
        <Typography sx={tittleFontStyle}>Job</Typography>
      </Box>
    ),
    task: (
      <Box sx={tittleStyle}>
        <Typography sx={tittleFontStyle}>Task</Typography>
      </Box>
    ),
    revert: (
      <Box sx={tittleStyle}>
        <Typography sx={tittleFontStyle}>Task Revert</Typography>
      </Box>
    ),
  };
  useEffect(() => {
    if (!ref1.current && globapOrganization?.orgId) {
      loadData();
      ref1.current = true;
    }
  }, [globapOrganization?.orgId]);

  return (
    <Box flex={1} sx={{ minHeight: 600 }}>
      <Box>
        <Box>
          <Grid container spacing={2} sx={{ pl: 6, pb: 2, pt: 2 }}>
            <Grid item xs={6} md={4} lg={3}>
              <SelectComponent
                // variant="standard"
                DataOptions={selectOptionData || []}
                labelName={(option) => option}
                defaultValue={filterData?.projectInfo}
                // disabled={!treeList.length > 0 }
                clearIcon={true}
                value={filterData?.projectInfo}
                sx={{ pr: 1 }}
                label={"Project"}
                handelChange={(e) => handelSelect(e, "Project")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2, ml: 6, mr: 3 }}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={4} lg={2}>
              <DashboardCard
                containerStyle={container}
                onclick={
                  allowedPages().ProjectManagement?.ProjectList?.showPage()
                    ? () => nav("/project")
                    : () => {}
                }
                cardStyle={{
                  ...cardStyle,
                  backgroundColor: ADMIN_DASHBOARD_COLORS.project,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"job1"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={2}>
              <Box></Box>
              <DashboardCard
                onclick={
                  allowedPages().Jobs.jobList?.showPage()
                    ? () => nav("/jobList")
                    : () => {}
                }
                containerStyle={container}
                cardStyle={{
                  ...cardStyle,
                  backgroundColor: ADMIN_DASHBOARD_COLORS.job,
                  py: 0,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"job"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>

            <Grid xs={12} sm={12} md={4} lg={2}>
              <DashboardCard
                containerStyle={container}
                onclick={
                  allowedPages().report.taskReport?.showPage()
                    ? () => nav("/taskReport")
                    : () => {}
                }
                cardStyle={{
                  backgroundColor: ADMIN_DASHBOARD_COLORS.task,
                  ...cardStyle,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"task"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={2}>
              <DashboardCard
                containerStyle={container}
                onclick={
                  allowedPages().WorkStation.taskRevert?.showPage()
                    ? () => nav("/taskRevert")
                    : () => {}
                }
                cardStyle={{
                  // backgroundColor: "#28aa2d",
                  ...cardStyle,
                  backgroundColor: ADMIN_DASHBOARD_COLORS.revert,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"job2"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={2}>
              <DashboardCard
                containerStyle={container}
                onclick={
                  allowedPages().Jobs.jobList?.showPage()
                    ? () => nav("/jobList")
                    : () => {}
                }
                cardStyle={{
                  backgroundColor: ADMIN_DASHBOARD_COLORS.dueDate,
                  ...cardStyle,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"task1"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={2}>
              <DashboardCard
                containerStyle={container}
                onclick={() => nav("/helpdesk", { state: { tab: 1 } })}
                cardStyle={{
                  backgroundColor: ADMIN_DASHBOARD_COLORS.query,
                  ...cardStyle,
                }}
                contentStyle={contentStyle}
                content={
                  <LabelData
                    type={"task2"}
                    dashboardData={dashboardData}
                    labelcardinsideContainer={labelcardinsideContainer}
                    iconStyle={iconStyle}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} sx={{ px: 5, pb: 2, pt: 5 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              sx={{
                boxShadow: "none",
                m: 1,
                border: "1px solid",
                borderColor: "#6647ed",
                backgroundColor: grey[100],
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={ChartEmtyBoxstyle}></Box>
                {titleOption.project}
                <Box sx={{ textAlign: "start", flex: 1.1, mb: 1 }}>
                  <SelectComponent
                    // variant="standard"
                    DataOptions={selectOptions || []}
                    labelName={(option) => option.label}
                    defaultValue={chartFilter?.project}
                    // disabled={!treeList.length > 0 }
                    sx={{ width: "100%", pr: 1 }}
                    value={chartFilter?.project}
                    label={"Select"}
                    handelChange={(e) => handelFilter(e, "project")}
                  />
                </Box>
              </Box>
              <Box sx={{}}>
                <GoogleChart
                  // ref={projectRef}
                  data={
                    chartData?.project?.length > 0 ? chartData?.project : data1
                  }
                  chartType={"LineChart"}
                  options={optiontaskChart}
                  width={"100%"}
                  height={300}
                />
              </Box>
              {/* <Box><ChartDownload pngref={projectRef} /></Box> */}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              sx={{
                boxShadow: "none",
                m: 1,
                border: "1px solid",
                borderColor: "#17aed1",
                backgroundColor: grey[100],
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={ChartEmtyBoxstyle}></Box>
                {titleOption.job}
                <Box sx={{ textAlign: "start", flex: 1.1, mb: 1 }}>
                  <SelectComponent
                    // variant="standard"
                    DataOptions={selectOptions || []}
                    labelName={(option) => option.label}
                    defaultValue={chartFilter?.job}
                    // disabled={!treeList.length > 0 }
                    sx={{ width: "100%", pr: 1 }}
                    value={chartFilter?.job}
                    label={"Select"}
                    handelChange={(e) => handelFilter(e, "job")}
                  />
                </Box>
              </Box>
              <GoogleChart
                data={chartData?.job?.length > 0 ? chartData.job : data2}
                chartType={"LineChart"}
                options={optionsJopchart}
                height={300}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              sx={{
                boxShadow: "none",
                m: 1,
                border: "1px solid",
                borderColor: "#f18d2d",
                backgroundColor: grey[100],
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={ChartEmtyBoxstyle}></Box>
                {titleOption.task}
                <Box sx={{ textAlign: "start", flex: 1.1, mb: 1 }}>
                  <SelectComponent
                    // variant="standard"
                    DataOptions={selectOptions || []}
                    labelName={(option) => option.label}
                    defaultValue={chartFilter?.task}
                    // disabled={!treeList.length > 0 }
                    sx={{ width: "100%", pr: 1 }}
                    value={chartFilter?.task}
                    label={"Select"}
                    handelChange={(e) => handelFilter(e, "task")}
                  />
                </Box>
              </Box>
              <GoogleChart
                data={chartData?.task?.length > 0 ? chartData.task : data3}
                chartType={"LineChart"}
                options={optionstaskAssChart}
                height={300}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              sx={{
                boxShadow: "none",
                m: 1,
                border: "1px solid",
                borderColor: "#fa6c6c",
                backgroundColor: grey[100],
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={ChartEmtyBoxstyle}></Box>
                {titleOption.revert}
                <Box sx={{ textAlign: "start", flex: 1.1, mb: 1 }}>
                  <SelectComponent
                    // variant="standard"
                    DataOptions={selectOptions || []}
                    labelName={(option) => option.label}
                    defaultValue={chartFilter?.taskRevert}
                    // disabled={!treeList.length > 0 }
                    sx={{ width: "100%", pr: 1 }}
                    value={chartFilter?.taskRevert}
                    label={"Select"}
                    handelChange={(e) => handelFilter(e, "revert")}
                  />
                </Box>
              </Box>
              <GoogleChart
                data={
                  chartData?.taskRevert?.length > 0
                    ? chartData.taskRevert
                    : data4
                }
                chartType={"LineChart"}
                options={optionstaskWorkChart}
                height={300}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
