import React from 'react';
import CountUp from 'react-countup';

// import Odometer from 'react-odometerjs';

function CountUpNumber({ end, duration }) {
    return (
    <CountUp delay={0} duration={duration || 3.25} end={end} />
    );
    // return (
    //   <div>
    //     <Odometer format={'d'} animation={'count'} duration={3000} value={end} />
    //   </div>
    // );
}

export default CountUpNumber;
