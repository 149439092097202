import { yupResolver } from "@hookform/resolvers/yup";
import { BorderColor, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";
import { grey } from "@mui/material/colors";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import DataGride from "../../components/datagride/DataGride";
import { createLocationAction } from "../../redux/action/action";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { createBranchMaster } from "../../service/masterService";
import { formContainerCardStyle, formFieldStyle } from "../../utils/Style";
import { allowedPages } from "../../utils/permissions";
import { RESET_LABEL } from "../../utils/textName";
import { sweetalertWidth } from "../../utils/FontStyle";

const schema = yup.object().shape({
  locationCode: yup.string().nullable(),
  locationName: yup.string().required("Name Required"),
  branchId: yup.string().required("Branch Required"),
  capacity: yup.string().required("Capacity Required"),
  description: yup.string().required("Description Required"),
  // isActive: yup.string().nullable(),
});

export default function CreateHall() {
  const { globapOrganization } = useSelector((state) => state.presist);
  const { branchList, hallList } = useSelector((state) => state.master);
  const [edit, setEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    // dispatch(getPatientPharmacyNotPaidOrderAction());
    dispatch(
      createLocationAction(
        "",
        "get",
        `/conference/location/${globapOrganization?.orgId}/`
      )
    );
  }, []);
  React.useEffect(() => {
    dispatch(
      createBranchMaster(
        "",
        "get",
        `/branch/info/${globapOrganization?.orgId}/`
      )
    ).then((response) => {
      if (response?.data && response.data?.data?.length > 0) {
        dispatch(
          updateMasterStates({
            name: "branchList",
            value: response.data?.data,
          })
        );
      } else {
        dispatch(updateMasterStates({ name: "branchList", value: [] }));
      }
    });
  }, [globapOrganization]);
  const form = useForm({
    defaultValues: {
      locationName: "",
      locationCode: "",
      capacity: "",
      description: "",
      branchId: "",
    },
    resolver: yupResolver(schema),
  });
  const onSelectionModelChange = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
    }
  };
  const handleEditClick = async (row, data) => {
    if (data === "edit") {
      //   setEditPage(true);
      //   navigate("/createTeam");
      setSelectedRow(row);
      setEdit(true);
    }

    setValue("branchId", row?.branchId);
    setValue("locationName", row?.locationName);
    setValue("locationCode", row?.locationCode);
    setValue("capacity", row?.capacity);
    setValue("description", row?.description);
  };
  const {
    handleSubmit,
    setValue,
    control,
    // getValues,
    trigger,
    reset,
    formState: { errors },
  } = form;
  const onSubmit = async (data) => {
    data.orgId = globapOrganization?.orgId;

    if (edit) {
      const editData = {
        id: selectedRow.id,
        branchId: selectedRow.branchId,
        locationName: data?.locationName,
        locationCode: data?.locationCode,
        orgId: data.orgId,
        capacity: data?.capacity,
        description: data?.description,
      };
      // setEditPage(false)
      await dispatch(
        createLocationAction(
          editData,
          "patch",
          `/conference/location/${globapOrganization?.orgId}/`
        )
      );
      await dispatch(
        createLocationAction(
          "",
          "get",
          `/conference/location/${globapOrganization?.orgId}/`
        )
      );

      reset();
      dispatch(updatePresistStates({ name: "hallsaveData", value: {} }));

      setEdit(false);
    } else {
      await dispatch(
        createLocationAction(
          data,
          "post",
          `/conference/location/${globapOrganization?.orgId}/`
        )
      );
      await dispatch(
        createLocationAction(
          "",
          "get",
          `/conference/location/${globapOrganization?.orgId}/`
        )
      );
      // await setValue("bra", "");
      // await setValue("depName", "");
      reset();
      dispatch(updatePresistStates({ name: "hallsaveData", value: {} }));
    }
  };
  const handleDeleteClick = async (row) => {
    Swal.fire({
      text: "Are you sure ?",
      icon: "warning",
      width: sweetalertWidth,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.value) {
        await dispatch(
          createLocationAction(
            "",
            "delete",
            `/conference/location/${globapOrganization?.orgId}/?locationId=${row}`
          )
        );
        setSelectionState([]);
        dispatch(
          createLocationAction(
            "",
            "get",
            `/conference/location/${globapOrganization?.orgId}/`
          )
        );
      }
    });
  };
  const handelClose = () => {
    // setEditPage(false)
    // navigate('/team')
    dispatch(updatePresistStates({ name: "hallsaveData", value: {} }));

    setSelectedFile([]);
    setEdit(false);
    setValue("branchId", "");
    setValue("locationName", "");
    setValue("locationCode", "");
    setValue("capacity", "");
    setValue("description", "");
    reset();
  };
  const columns = [
    // { field: "S.No", headerName: "S.No", width: 90 },
    {
      field: "locationName",
      headerName: "HALL NAME",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "capacity",
      headerName: "CAPACITY",
      width: 250,
      editable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "branchId",
      headerName: "BRANCH",
      type: "number",
      width: 300,
      editable: true,
      align: "left",
      headerAlign: "left",
    },
    // {
    //     field: "locationCode",
    //     headerName: "Code Name",
    //     type: "number",
    //     width: 300,
    //     editable: true,
    //     align: "left",
    //     headerAlign: "left",
    // },
    {
      field: "actions",
      cellClassName: "actions",
      type: "actions",
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: "ACTION",
      width: 100,
      minWidth: 100,
      getActions: (params) => [
        allowedPages().meeting.createHall.edit ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Edit sx={{ color: "#358c4e" }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleEditClick(params.row, "edit");
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
        allowedPages().meeting.createHall.delete ? (
          <GridActionsCellItem
            sx={{ p: 0 }}
            icon={
              <Tooltip title="Edit" placement="right">
                <Delete sx={{ color: "#ed5c7e", ml: 3 }} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              handleDeleteClick(params?.row?.id);
            }}
            color="primary"
          />
        ) : (
          <></>
        ),
      ],
    },
  ];
  return (
    <FormProvider {...form}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
            height: "100%",
            width: "100%",
            p: 3,
            background: grey[100],
            pt: 1,
          }}
        >
          <Card sx={{ ...formContainerCardStyle, p: 3, minHeight: 450 }}>
            {(allowedPages().meeting.createHall.create ||
              (allowedPages().meeting.createHall.edit && edit)) && (
                <>
                  <Grid container spacing={2} sx={{ pt: 3 }}>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Controller
                        control={control}
                        name="branchId"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <FormControl
                            error={!value ? errors.branchId : null}
                            size="small"
                            sx={{ width: "100%" }}
                          >
                            <InputLabel shrink id="demo-simple-select-label">
                              Branch*
                            </InputLabel>
                            <Select
                              notched
                              sx={{
                                width: "100%",
                                borderRadius: 3,
                              }}
                              disabled={edit}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 150 } },
                              }}
                              label="Branch*"
                              onChange={(e) => {
                                trigger("branchId");
                                onChange(e);
                              }}
                              onBlur={() => trigger("branchId")}
                              value={value}
                              inputRef={ref}
                              inputProps={{
                                "aria-label": "Project Code",
                              }}
                            >
                              {branchList.map((val) => (
                                <MenuItem
                                  sx={{
                                    borderBottom: "1px solid white",
                                    fontFamily: "Lato",
                                    fontSize: 13,
                                  }}
                                  value={val.BranchInfo?.branchId}
                                >
                                  {val.BranchInfo?.branchName}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>
                              {!value
                                ? errors.branchId
                                  ? errors?.branchId?.message
                                  : null
                                : null}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Controller
                        control={control}
                        name="locationName"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <TextField
                            label="Hall Name*"
                            sx={{ width: "100%" }}
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            helperText={
                              !value
                                ? errors.locationName
                                  ? errors.locationName.message
                                  : null
                                : null
                            }
                            error={!value ? errors.locationName?.message : null}
                            onChange={(e) => {
                              trigger("locationName");
                              onChange(e);
                            }}
                            onBlur={() => trigger("locationName")}
                            value={value}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid
    item
    xs={6}
    md={6}
    lg={4}
    sx={{ display: "flex", justifyContent: "center" }}
>
    <Controller
        control={control}
        name="locationCode"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
                label="Hall Code"
                sx={{ width: "70%" }}
                size="small"
                id="outlined-basic"
                variant="outlined"
                helperText={
                    errors?.locationCode ? errors.locationCode.message : null
                }
                error={errors?.locationCode?.message}
                onChange={(e) => {
                    trigger("locationCode");
                    onChange(e);
                }}
                onBlur={() => trigger("locationCode")}
                value={value}
            />
        )}
    />
</Grid> */}
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Controller
                        control={control}
                        name="capacity"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <TextField
                            label="Hall Capacity*"
                            sx={{ width: "100%" }}
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            helperText={
                              !value
                                ? errors?.capacity
                                  ? errors.capacity.message
                                  : null
                                : null
                            }
                            error={!value ? errors?.capacity?.message : null}
                            onChange={(e) => {
                              trigger("capacity");
                              var numberRegex = /^\d+$/;
                              const u = numberRegex.test(e.target.value);
                              if (u || e.target.value === "") {
                                setValue("capacity", e.target.value || null);
                              }
                            }}
                            onBlur={() => trigger("capacity")}
                            value={value}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid
item
xs={6}
md={6}
lg={3}
sx={{ display: "flex", justifyContent: "center" }}
>
<FormControl size="small" sx={{ width: '70%' }}>
<InputLabel id="demo-simple-select-label">Floor</InputLabel>
<Select

    labelId="demo-simple-select-label"
    id="demo-simple-select"
    // value={age}
    label="Floor"
// onChange={handleChange}
>
    <MenuItem value={1}>Floor1</MenuItem>
    <MenuItem value={2}>Floor2</MenuItem>
    <MenuItem value={3}>Floor3</MenuItem>
</Select>
</FormControl>
</Grid> */}
                    {/* <Grid
item
xs={6}
md={6}
lg={3}
sx={{ display: "flex", justifyContent: "center" }}
>
<Autocomplete
multiple
sx={{ width: '70%' }}
size="small"
id="combo-box-demo"
options={amenities}
// value={ data.department}
// onChange={(e, v) => setData({ ...data, department: v })}
// sx={{ width: 300 }}
renderInput={(params) => (
    <TextField fullWidth {...params} label="Amenities" />
)}
/>
</Grid> */}

                    {/* <Grid
item
xs={6}
md={6}
lg={4}
sx={{ display: "flex", justifyContent: "center" }}
>
<FormControl size="small" sx={{ width: '70%' }}>
<InputLabel id="demo-simple-select-label">Hall Status</InputLabel>
<Select

                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="Hall Status"
                        // onChange={handleChange}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={2}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <TextField
                            label="Description"
                            sx={{ width: "100%" }}
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            multiline
                            rows={3}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            helperText={
                              value === ""
                                ? "Max 500 characters"
                                : 500 -
                                value.length +
                                " " +
                                "Characters remaining"
                            }
                            inputProps={{
                              "aria-label": "description",
                              maxLength: 500,
                            }}
                            InputProps={formFieldStyle.InputProps}
                            InputLabelProps={formFieldStyle.InputLabelProps}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    {edit ? (
                      <Button
                        color="success"
                        sx={{
                          m: 2,
                          color: "white",
                          backgroundColor: "#258d4d",
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                          borderRadius: 5,
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    ) : allowedPages().meeting.createHall.create ? (
                      <Button
                        color="success"
                        sx={{
                          m: 2,
                          color: "white",
                          backgroundColor: "#258d4d",
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                          },
                          borderRadius: 5,
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </Button>
                    ) : null}
                    <Button
                      color="error"
                      onClick={handelClose}
                      sx={{
                        m: 2,
                        color: "white",
                        borderRadius: 5,
                        ":hover": {
                          boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                        },
                      }}
                      variant="contained"
                    >
                      {RESET_LABEL}
                    </Button>
                  </Box>
                </>
              )}
            <Box
              sx={{
                height: "50%",
                width: "100%",
                mt: 2,
                flex: 1,
                minHeight: 450,
              }}
            >
              <DataGride
                customHeight={450}
                columns={columns}
                tableData={hallList?.data || []}
                selectionIs={selectionIs}
                selectionState={selectionState}
                selectedFile={selectedFile}
                onSelectionModelChange={onSelectionModelChange}
                // deleteFuntion={handleDeleteClick}
                hideDeletion
              />
            </Box>
          </Card>
        </Box>
      </form>
    </FormProvider>
  );
}
