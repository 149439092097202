import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Close, Visibility } from "@mui/icons-material";
import { Box } from "@mui/material";
import PopUpData from "./popUpData";
import moment from "moment";

export default function ViewPopUp({ 
 value,
  org,
  user,
  team,
  project,
  extraData,
  job,
  slaDetails,
}) {
  console.log(team, "teammm");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const orgData = {
    "Organization Name": org?.orgName,
    Type: org?.orgType,
    "Address (line 1)": org?.address1,
    "Address (line 2)": org?.address2,
    City: org?.city,
    State: org?.state,
    Pincode: org?.pincode,
    "Email Address": org?.emailAddress,
    Website: org?.website,
    "Mobile No": org?.mobileNumber,
    "Contact No": org?.contactNo,
    "Extension No": org?.extensionNo,
    Description: org?.orgDesc,
  };
  const userData = {
    "First Name": user?.firstname,
    "Last Name": user?.lastname,
    "Employee Id": user?.empId,
    "Email Address": user?.emailAddress,
    "Contact No": user?.landLineNo,
    "Mobile No": user?.mobileNumber,
    Gender: user?.gender,
    DOB: moment(user?.DOB).format("DD-MM-YYYY"),
    Department: user?.depName,
    Designation: user?.desigName,
    Grade: user?.gradeName,
    // "user Type": user?.superUser === true ? "Admin" : "Employee",
  };
  const teamData = {
    Name: team?.teamName,
    "Short Name": team?.shortName,
    Branch: team?.branchName,
    Department: team?.depName,
    "Team Manager": team?.teamOwner.join(",  "),
    "Team Lead": team?.teamLead.map((lead) => lead),
    Description: team?.description,
    "Team Members": (
      <ol style={{ padding: 0, margin: 0, paddingLeft: 10, paddingTop: 0 }}>
        {team?.teamMembers?.map((names) => (
          <li style={{ padding: 0, margin: 0 }}>
            {names?.firstname + " " + names?.lastname}
          </li>
        ))}
        
      </ol>
    ),
  };
  const projectData = {
    Name: project?.projectName,
    "Project Code": project?.projectCode,
    Department: project?.depName,
    Department: project?.depName,
    Manager: project?.mangerInfo?.join(","),
    Teams: project?.teamInfo.join(", "),
    "Trouble-Shooters": extraData?.troubleShooters
      ?.map((name) => name?.firstname + " " + name?.lastname)
      .join(",  "),
    Scope: project?.scopeOfProject,
    Notes: project?.notes,
  };
  console.log(project?.mangerInfo, project);
  const jobDetails = {
    "Job Code": job?.jobCode,
    "Project Code": job?.projectCode,
    "Project Name": job?.projectName,
    "received Date": moment(job?.receivedDate).format("DD-MM-YYYY"),
    "Due Date": moment(job?.dueDate).format("DD-MM-YYYY"),
    "Excel Selected Fields": slaDetails?.fields.join(", "),
  };
  return (
    <React.Fragment>
      <Visibility
        fontSize="small"
        sx={{ color: "#358c4e" }}
        onClick={handleClickOpen}
      />

      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ fontFamily: "Lato", width: "100%", p: 0 }}>
          <Box
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              color: "black",
              width: "100%",
              py: 1,
            }}
          >
            {org
              ? "Organization Details"
              : user
              ? "User Details"
              : team
              ? "Team Details"
              : project
              ? "Project Details"
              : job
              ? "Job Details"
              : null}
          </Box>

          {org ? (
            <PopUpData data={orgData} />
          ) : user ? (
            <PopUpData data={userData} />
          ) : team ? (
            <PopUpData data={teamData} />
          ) : project ? (
            <PopUpData
              data={projectData}
              table={extraData?.task}
              attachment={project?.fileInfo && project?.fileInfo[0]}
            />
          ) : job ? (
            <PopUpData data={jobDetails} sla={slaDetails} />
          ) : null}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={handleClose}
            sx={{
              mr: 3,
              mb: 1,
              borderRadius: 3,
              background: "#ff6b6b",
              ":hover": { background: "#ff6b6b" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
